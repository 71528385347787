<div appDragResize
    #engagementVideoContainer
    id="engagementVideoContainer"
    class="d-block video-container"
    (ondockchange)="onDockChange($event)"
    [docked]="!undocked"
    [portrait]="portrait"
    [dragResizeEnabled]="dragResizeEnabled"
    >
    <div id="mainVideoContainer" class="mainVideoContainer d-flex w-100 h-100">
    </div>

    <div id="peersContainer" class="peersContainer mb-0 position-absolute"></div>

    <div *ngIf="dragResizeEnabled" class="dock-button"
      [ngClass]="undocked ? 'dock-button-undocked' : 'dock-button-docked'"
      [ngStyle]="{'display' : mouseover ? 'inline-block' : 'none' }"
      (click)="undocked ? dockVideo() : unDockVideo()">
      <fa-icon [icon]="faExpand" *ngIf="!undocked"></fa-icon>
      <fa-icon [icon]="faCompress" *ngIf="undocked"></fa-icon>
    </div>

    <div *ngIf="virtualBackground" class="virtualbackground-button"
      [ngClass]="virtualBackgroundOn ? 'virtualbackground-button-on' : 'virtualbackground-button-off'"
      (click)="virtualBackgroundOn ? turnVirtualBackgroundOff() : turnVirtualBackgroundOn()">
    </div>

  </div>
