<div class="d-flex flex-column w-100 h-100 position-relative overflow-hidden">
  <p-tabView [(activeIndex)]="activeIndex" [ngClass]="{ 'active-engagement': currentState.type !== EngagementState.Post }" class="d-flex flex-column flex-grow-1" (onChange)="onTabChange($event)">
    <p-tabPanel header='{{ "ENGAGEMENT_HEADER_COBROWSING"  | Translate : "Co-Browsing & Sharing" }}'>

      <div [class.disabled]="(engagement?.backgrounded | async)"
           [ngClass]="{ 'd-none': (!showBrowserBar || appViewOn) }"
           class="d-flex w-100 browser-bar border-0 align-items-center justify-content-start">
        <p-button (onClick)="onToggleCobrowseBrowser()"
                *ngIf="displayBrowseToggle && showBrowserBarControls && !inBackground"
                [severity]="cobrowseAdded ? 'primary' : 'secondary'"
                styleClass="btn-square center-icon">
          {{ "ENGAGEMENT_ADD_COBROWSE_TO_SDK" | Translate: "Cobrowse" }}
        </p-button>

        <p-button (onClick)="onBack()" *ngIf="showBrowserBarControls" id="back-btn"
                [disabled]="useSecureSharing || !backEnabled || !isPresentingAgent"
                  [text]="true"  [plain]="true">
            <fa-icon size="lg" [icon]="faChevronLeft"></fa-icon>
        </p-button>

        <p-button (onClick)="onForward()" *ngIf="showBrowserBarControls" id="forward-btn"
                [disabled]="useSecureSharing || !forwardEnabled|| !isPresentingAgent"
                  [text]="true"   [plain]="true">
            <fa-icon size="lg" [icon]="faChevronRight"></fa-icon>
        </p-button>

        <app-engagement-cobrowse-window (pageChanged)="onAddressBarChange($event)"
                                        *ngIf="showBrowserBarControls && isPresentingAgent && !(engagement?.visitor?.isMultichat)"
                                        [class.disabled]="useSecureSharing"
                                        [currentUrl]="currentUrl?.toString()"
                                        class="extra-window">
        </app-engagement-cobrowse-window>

        <app-engagement-browser-bar
          class="flex-grow-1 p-1"
          (pageChanged)="onAddressBarChange($event)"
          (switchAppView)="onSwitchAppView($event)"
          (switchCoBrowse)="onSwitchCoBrowse($event)"
          (switchSecureSharing)="onSwitchSecureSharing($event)"
          (switchSharing)="onSwitchSharing($event)"
          (toggleSecureShareUserInteraction)="onToggleSecureShareUserInteraction($event)"
          [secureSharePreventUserInteraction] ="secureSharePreventUserInteraction"
          [canAppView]="canAppView"
          [canCoBrowse]="canCoBrowse"
          [canSecureShare]="canSecureShare"
          [canShare]="hasSharingAvailable()"
          [class.disabled]="engagement?.isInBackground | async"
          [currentUrl]="(browser?.currentPage | async)"
          [isPresenter]="isPresentingAgent"
          [useAppView]="appViewOn"
          [useCoBrowse]="useCoBrowse"
          [useSecureSharing]="useSecureSharing"
          [useSharing]="useSharing"
        >
        </app-engagement-browser-bar>

      </div>

      <app-engagement-mode-selection
        (changeEngagementMode)="engagement?.changeEngagementMode($event)"
        *ngIf="(engagement?.panelFullSize | async)"
        [chatOnly]="(engagement?.chatOnly | async)"
        [class.disabled]="(engagement?.backgrounded | async)"
        [engagementMode]="(engagement?.engagementMode | async)"
        [hasVideo]="(hasVideo | async)">
      </app-engagement-mode-selection>

      <div *ngIf="hideBrowserWhenSharing && isPrimaryAgent && !callPaused" class=" h-100 d-flex flex-column justify-content-center align-items-center"
           id="sharing-overlay">
        <h1>{{ "SHARING_HEADER_YOUARESHARING" | Translate : 'You are Sharing' }}</h1>
        <p class="lead mb-0">{{ "SHARING_BODY_MESSAGE" | Translate : 'While sharing you cannot browse this component' }}</p>
        <fa-icon class="mt-4" size="8x" [icon]="faCircleUp"></fa-icon>
      </div>

      <div *ngIf="hideBrowserWhenSharing && isPrimaryAgent && callPaused" class="h-100 d-flex flex-column justify-content-center align-items-center"
           id="sharingOnHold">
        <h1>{{ "SHARING_HEADER_PAUSE" | Translate : 'Sharing Paused' }}</h1>
        <fa-layers class="fa-fw mt-4" size="8x">
          <fa-icon [icon]="faCircleUp"></fa-icon>
          <fa-icon [icon]="faCirclePause" transform="shrink-8 right-7 down-7" ></fa-icon>
        </fa-layers>
      </div>

      <div *ngIf="!isPrimaryAgent && domSyncActive" class="h-100 d-flex flex-column justify-content-center align-items-center"
           id="domSyncActive">
        <h1>{{ "SHARINGMULTIPARTY_HEADER_PRIMARYAGENTSHARING" | Translate : 'Primary Agent is Secure Sharing' }}</h1>
      </div>

      <!-- DO NOT REMOVE THIS COMPONENT IF ON ELECTRON
           Removing this component when you are in Electron removes the electron-browser component.
           This removes some other proxy that calls into Electron and ends up breaking VNC sharing.
           Instead the CSS class .hide has been applied to remove the electron browser from view.
           -->
      <app-engagement-browser *ngIf="currentState.type !== EngagementState.Post && currentState.type !== EngagementState.Ended" class="d-flex flex-column w-100"
                              [class.hide]="domSyncOn || appViewOn || hideBrowserWhenSharing || (!isPrimaryAgent && isSharing)"
                              [class.fullsize] = "engagement.panelFullSize | async"
                              [currentPanelPosition]="(currentPanelPosition | async)"
                              [engagement]="engagement"
                              [isPresenter]="isPresentingAgent"
                              [isSharing]="isSharing"
                              [showBrowser]="activeIndex === 0 && !showingModal && !(domSyncOn || appViewOn || hideBrowserWhenSharing || (!isPrimaryAgent && isSharing))"
                              [showUserPanel]="(showUserPanel | async)"

      >
      </app-engagement-browser>

      <app-engagement-domsync-coviewer
        (onDomSyncCommandMessage)="onDomSyncToCustomerCommand($event)"
        (onReady)="onDomSyncAgentReady()"
        *ngIf="currentState.type === EngagementState.Engaged && (domSyncOn && isPrimaryAgent && !isSharing)"
        [class.d-none]="(engagement?.backgrounded | async)"
        [currentPanelPosition]="currentPanelPosition"
        [fromCustomerCommand]="fromCustomerCommand"
        [isElectron]="isElectron"
        [showBrowser]="activeIndex === 0 && !showingModal && !appViewOn && !(engagement?.backgrounded | async)"
        [useOverlayPanel]="useOverlayPanel"
        [username]="username"
        class="d-flex flex-column w-100 h-100"
        style="overflow-x: auto;">
        >
      </app-engagement-domsync-coviewer>

      <app-engagement-app-view *ngIf="appViewOn"
                               [customerSize]="appViewSize"
                               [engagementId]="engagementId"
                               class="d-flex flex-column w-100 h-100 app-view">
      </app-engagement-app-view>

      <div *ngIf="!isPrimaryAgent" [ngClass]="{ 'd-none': !isSharing }" class="d-flex flex-column w-100 h-100 mr-3"
           id="shareContainer"></div>

      <!-- Uncomment the div below if you want the agent browser view to be replaced with what the agent is sharing
               also add [ngClass]="{ 'hide': (isSharing | async) }"  to the app-engagement-browser element above -->

      <!-- <div id="shareContainer" class="d-flex flex-column w-100 h-50 mr-3" [ngClass]="{ 'hide': !isSharing }"></div> -->

    </p-tabPanel>
    <p-tabPanel header='{{ "ENGAGEMENT_HEADER_CUSTOMERDETAILS" | Translate : "Customer &amp; Engagement Details" }}'>

      <app-visitor-information
        [emailState]="emailState"
        (loadOldCustomer)="onLoadCustomer($event)"
        (saveOpp)="onSaveOpportunity($event)"
        (updatedData)="onSaveCrmData()"
        [(currentSection)]="currentSection"
        [checklistfields]="checklistfields"
        [currentState]="(currentState)" [currentUrl]="currentUrl?.toString()"
        [engagement]="engagement"
        [userGuid]="userGuid" [visitorBrowsingHistory]="visitorBrowsingHistory"
        [visitorCrmData]="(visitorCrmData)"
        [visitor]="visitor"
        [visitorSessionHistory]="visitorSessionHistory"
      >
      </app-visitor-information>
    </p-tabPanel>
  </p-tabView>
</div>
