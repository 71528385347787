import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CheckListField, ChecklistFieldType} from '../../classes/checklist/CheckListField';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
  faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  @Input() checklistfields: CheckListField[] = [];

  @Output() focusField = new EventEmitter<string>();

  ChecklistFieldType = ChecklistFieldType;

  constructor() {
  }

  ngOnInit() {
  }

  public crmFieldCheckList(): CheckListField[] {
    return this.checklistfields?.map((item) => {
      item.IsCustomTask = item.CRMField.startsWith('Custom Task:');
      return item;
    })
  }

  public setFocusOnField(checkListField: CheckListField) {
    if(checkListField.IsCustomTask === false && checkListField.IsRulePassed === false) {
      this.focusField.emit(checkListField.CRMField.split(':')[1]);
    }
  }

  protected readonly faCheckCircle = faCheckCircle;
  protected readonly faExclamationCircle = faExclamationCircle;
  protected readonly faTimesCircle = faTimesCircle;
}
