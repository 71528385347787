import WebGL2Pipeline from "./webgl/Webgl2Pipeline";
import BackgroundConfig from "./webgl/BackgroundConfig";
import PostProcessingConfig from "./webgl/PostProcessingConfig";
import Segmenter from "./Segmenter";

export default class ProcessingPipe {
    constructor() {
        this.trace = false;

        this.segmenter = null;
        this.pipeline = null;

        this.track = null;
        this.stream = null;
                
        this.canvas = null;

        this.cameraCanvas = null;
        this.cameraCanvasCtx = null;
        this.cameraVideo = null;
        this.frameInterval = null;

        this.statsTimer = null;
        this.startTime = performance.now();
        this.startSegmentTime = performance.now();
        this.framesThisSecond = 0;
        this.segmentationTotal = 0;
        this.renderTotal = 0;

        this.backgroundConfig = null;
        this.postProcessingConfig = null;   

        this.outputStream = null;
        this.segmenting = false;
        this.abortController = null;
        this.newFrame = null;
        
        this.segListener = (results) => this.onResults(results);  
        
        this.insertableStream = (track) => {
            const trackProcessor = new MediaStreamTrackProcessor({track: track});
            const trackGenerator = new MediaStreamTrackGenerator({kind: 'video'});
    
            const transformer = new TransformStream({
                start: () => {                    
                    if (this.newFrame) {                        
                        this.newFrame.close();
                        this.newFrame = null;
                    }
                },
                flush: () => {                   
                    if (this.newFrame) {                        
                        this.newFrame.close();
                        this.newFrame = null;
                    }
                },
                transform: async (videoFrame, controller) => {

                    if (this.newFrame) {
                        this.newFrame.close();
                        this.newFrame = null;
                    }                    

                    if (!this.segmenter || this.segmenter.countListeners() < 1 || videoFrame.displayWidth <= 0 || videoFrame.displayHeight <= 0 ) {                    
                        videoFrame.close();
                        return;
                    }

                    if (!this.segmenting) {                        
                        this.startTime = performance.now();
                        // we send the video frame to MediaPipe
                        videoFrame.width = videoFrame.displayWidth;
                        videoFrame.height = videoFrame.displayHeight;                               
                        this.startSegmentTime = performance.now();
                        this.segmenting = true;
                        await this.segmenter.send({image: videoFrame});                            
                    }
                    
                    // // we create a new videoFrame
                    const timestamp = videoFrame.timestamp;
                    this.newFrame = new VideoFrame(this.canvas, {timestamp});
    
                    // we close the current videoFrame and queue the new one                    
                    videoFrame.close();
                    controller.enqueue(this.newFrame);

                }                
            });
            
    
            this.abortController = new AbortController();
            const signal = this.abortController.signal;
            
            signal.onabort = async (signal, ev) => {                
                if (this.newFrame) {                    
                    this.newFrame.close();
                    this.newFrame = null;
                }
            };
            
            // we pipe the stream through the transform function
            trackProcessor.readable
                .pipeThrough(transformer, { signal })
                .pipeTo(trackGenerator.writable)
                .catch(e => {
                    if (signal.aborted) {                        
                        if (this.newFrame) {                            
                            this.newFrame.close();
                            this.newFrame = null;
                        }
                    }                                                                               
                });

            return this.outputStream = new MediaStream([trackGenerator]);
        };


        this.canvasStream = (stream) => {
            this.cameraVideo = document.createElement('video');
            this.cameraVideo.muted = true;
            this.cameraVideo.srcObject = stream;
            this.cameraVideo.onloadedmetadata = () => {
                if (!this.cameraVideo) return;
    
                this.cameraVideo.play();
    
                this.frameInterval = setInterval(async () => {
                    if (!this.cameraCanvasCtx || !this.cameraVideo || !this.cameraCanvas || !this.segmenter) {
                        return;
                    }                
    
                    try {
                        this.startTime = performance.now();
                        this.cameraCanvasCtx.drawImage(this.cameraVideo, 0, 0, this.cameraCanvas.width, this.cameraCanvas.height);
                        let imageData = this.cameraCanvasCtx.getImageData(0, 0, this.cameraCanvas.width, this.cameraCanvas.height);
                        if (imageData.data && imageData.width > 0 && imageData.height > 0) {
                            this.startSegmentTime = performance.now();
                            await this.segmenter.send({image: imageData});
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }, 33);
            }
    
            return this.outputStream = this.canvas ? this.canvas.captureStream() : null;
        };
    }

    get supportsInsertableStreams() {
        return (
            typeof OffscreenCanvas !== "undefined" 
            && typeof TransformStream !== "undefined" 
            && typeof MediaStreamTrackProcessor !== "undefined"
            && typeof MediaStreamTrackGenerator !== "undefined"
        );
    };

    initialise({
        width = 352,
        height = 198,
        backgroundConfig = new BackgroundConfig({ resourcesPath : '', type : 'none', url : '' }),
        postProcessingConfig = new PostProcessingConfig({ sigmaSpace : 1, sigmaColor : 1, coverageMin : 0.5, coverageMax : 0.75, lightWrapping : 0.3, blendMode : 'screen' })
    } = {}) {

        this.backgroundConfig = backgroundConfig;
        this.postProcessingConfig = postProcessingConfig;

        this.statsTimer = setInterval(() => {
            if (this.trace) {
                console.log(`statsTimer ${this.statsTimer} frameInterval ${this.frameInterval} FPS ${this.framesThisSecond} grabAvg:${(this.grabTotal / this.framesThisSecond).toFixed(2)} segmAvg:${(this.segmentationTotal / this.framesThisSecond).toFixed(2)} rendAvg:${(this.renderTotal / this.framesThisSecond).toFixed(2)}`);
            }

            // reset the stats
            this.framesThisSecond = 0;
            this.segmentationTotal = 0;
            this.grabTotal = 0;
            this.renderTotal = 0;
        }, 1000);
        
        const segmenterPromise = Segmenter.getInstance(backgroundConfig.resourcesPath);
        return segmenterPromise.then((s) => {
            this.segmenter = s;

            if (!this.segmenter) return;

            this.segmenter.addListener(this.segListener);

            if (this.supportsInsertableStreams) {
                this.canvas = new OffscreenCanvas(width, height);            
                this.canvas.width = width;
                this.canvas.height = height;
            }
            else {
                this.canvas = document.createElement('canvas');
                this.canvas.width = width;
                this.canvas.height = height;

                this.cameraCanvas = document.createElement('canvas');                
                this.cameraCanvas.width = width;
                this.cameraCanvas.height = height;
                
                this.cameraCanvasCtx = this.cameraCanvas.getContext("2d", { willReadFrequently: true });
            }

            this.pipeline = new WebGL2Pipeline(this.backgroundConfig, this.canvas);            
            this.pipeline.updatePostProcessingConfig(this.postProcessingConfig);  
        }).then(() => this); 
    }

    dispose() {
        if (this.newFrame) {
            this.newFrame.close();
            this.newFrame = null;
        }

        if (this.pipeline) {
            this.pipeline.cleanUp();
            this.pipeline = null;
        }

        if (this.segmenter) {
            this.segmenter.removeListener(this.segListener);
            this.segmenter = null;
        }

        this.cameraCanvas = null;
        this.cameraCanvasCtx = null;

        if (this.trace) {
            console.log(`clearInternal statsTimer ${this.statsTimer}`);
        }

        clearInterval(this.statsTimer);
        this.statsTimer = null;

        if (this.trace) {
            console.log(`clearInternal frameInterval ${this.frameInterval}`);
        }

        clearInterval(this.frameInterval);
        this.frameInterval = null;

        if (this.cameraVideo) {
            this.cameraVideo.srcObject = null;
            this.cameraVideo = null;
        }

        if (this.track) {
            this.track.stop();
            this.track = null; 
        }

        if (this.outputStream && this.outputStream.getVideoTracks().length > 0) {
            let track = this.outputStream.getVideoTracks()[0];
            track.stop();
            this.outputStream = null;
        }

        if (this.abortController) {
            this.abortController.abort("Disposing stream");
            this.abortController = null;
        } 
        
    }
    
    processVideoStream(stream) { 
        if (this.abortController) {
            this.abortController.abort("Disposing stream");
        }  

        if (this.track) {
            this.track.stop();
            this.track = null; 
        }

        const track = stream.getVideoTracks()[0];
        if (track) {                      
            this.track = track;
        }
                
        return this.supportsInsertableStreams ? this.insertableStream(track) : this.canvasStream(stream);        
    }

    updateBackgroundConfig(backgroundConfig) {
        if (this.pipeline) {
            this.pipeline.cleanUp();
            this.pipeline = null;
            this.backgroundConfig = backgroundConfig;

            this.pipeline = new WebGL2Pipeline(this.backgroundConfig, this.canvas);            
            this.pipeline.updatePostProcessingConfig(this.postProcessingConfig);
        }
    }

    updatePostProcessing(postProcessingConfig) {
        if (this.pipeline) {
            this.pipeline.updatePostProcessingConfig(postProcessingConfig);
        }
    }

    onResults(results) {
        const startRenderTime = performance.now();

        this.pipeline.render(results.image, results.segmentationMask);

        this.segmenting = false;

        const endRenderTime = performance.now();        
        const grabTime = this.startSegmentTime - this.startTime;
        const segmTime = startRenderTime - this.startSegmentTime;
        const rendTime = endRenderTime - startRenderTime;
        this.segmentationTotal += segmTime;
        this.renderTotal += rendTime;
        this.grabTotal += grabTime;
        ++this.framesThisSecond;

        if (this.trace) {        
             console.log(`Time to grabFrame:${grabTime.toFixed(2)} segment:${segmTime.toFixed(2)} render:${rendTime.toFixed(2)}`);
        }
    }

    setTrace(on) {
        this.trace = on;
    }
}
