import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { FeatureService, Features } from '../../../services/feature-service/feature.service';
import { AuthService } from '../../../services/auth-service/auth.service';
import { Router } from '@angular/router';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {faAddressBook, faUserTie} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-call-list-bottom-controls',
  templateUrl: './call-list-bottom-controls.component.html',
  styleUrls: ['./call-list-bottom-controls.component.scss']
})
export class CallListBottomControlsComponent implements OnInit, OnDestroy {
  @Output() endBreak = new EventEmitter();

  public isScheduledAppointmentsAvailable: boolean;
  public crmAvailable: boolean;
  public supervisorModeAvailable: boolean;

  public OnlineState = OnlineState;
  public currentState$: BehaviorSubject<OnlineState> = new BehaviorSubject<OnlineState>(OnlineState.OnBreak);
  private availableState: OnlineState = OnlineState.Available;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private featureService: FeatureService,
    private authService: AuthService,
    private router: Router,
    private onlineService: OnlineService) { }

  ngOnInit() {
    this.crmAvailable = this.featureService.has(Features.CRM);
    this.isScheduledAppointmentsAvailable = this.featureService.has(Features.SCHEDULED_APPOINTMENT);
    this.supervisorModeAvailable = this.featureService.has(Features.SUPERVISOR_MODE)
      && this.authService.currentAgent.value.isSupervisor;
    const stateSub = this.onlineService.currentState.pipe(distinctUntilChanged())
      .subscribe(([state, _]) => this.currentState$.next(state));
    this.subscriptions.push(stateSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  goToCrm() {
    this.onlineService.setCurrentState(OnlineState.CrmOpportunity);

    if (this.router.url.startsWith('/veestudio')) {
      this.router.navigateByUrl('/veestudio/crm');
    } else if (this.router.url.startsWith('/veechat')) {
      this.router.navigateByUrl('/veechat/crm');
    } else if (this.router.url.startsWith('/supervisor')) {
      this.router.navigateByUrl('/supervisor/crm');
    }
  }

  // toggle between on and off break
  onOffBreakToggle() {
    const currState = this.currentState$.getValue();

    if (currState === OnlineState.Available) {
      return this.onlineService.setCurrentState(OnlineState.OnBreak);
    } else if (currState === OnlineState.OnBreak) {
      return this.onlineService.setCurrentState(this.availableState);
    }
  }

  supervisorMode() {
    this.onlineService.setCurrentState(OnlineState.Supervisor);
    this.router.navigateByUrl(`/supervisor`);
  }

  protected readonly faAddressBook = faAddressBook;
  protected readonly faUserTie = faUserTie;
}
