import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {CannedChatCategory} from '../../classes/cannedchats';
import { InvitationRejectionReason } from '../../classes/transfer/invitationRejectionReason';
import { InvitationReason } from '../../classes/transfer/invitationReason';
import {EngagementAssetPlaylist} from '../../classes/EngagementAssets';

export interface Resources {
  [key: string]: string;
}

export interface Statuses {
  [key: string]: string[];
}

export interface SiteSettings {
  [key: string]: string;
}

export interface ISettingsBackendService {
  loadResources(authToken: string): Observable<Resources>;
  loadSiteSettings(authToken: string): Observable<SiteSettings>;
  loadStatuses(authToken: string): Observable<Statuses>;
  loadCannedChats(authToken: string): Observable<CannedChatCategory[]>;
  loadRejectionReasons(authToken: string): Observable<InvitationRejectionReason[]>;
  loadTransferReasons(authToken: string): Observable<InvitationReason[]>;
  loadEngagementAssets(authToken: string): Observable<EngagementAssetPlaylist[]>;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsBackendService implements ISettingsBackendService {
  constructor(
    private http: HttpClient
  ) {
  }

  // { applicationId:applicationId, culture:culture, sitename:sitename },
  // public loadResources(applicationId:string, culture:string, sitename: string, authToken:string) : Observable<Map<string, string>> {
  public loadResources(authToken: string): Observable<Resources> {
    const url = `${environment.configApiRoot}/resources`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<Resources>(
      url,
      httpOptions
    );
  }

  public loadSiteSettings(authToken: string) {
    const url = `${environment.configApiRoot}/sitesettings`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<SiteSettings>(
      url,
      httpOptions
    );
  }

  public loadStatuses(authToken: string): Observable<Statuses> {
    const url = `${environment.configApiRoot}/statuses`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<Statuses>(
      url,
      httpOptions
    );
  }

  public loadCannedChats(authToken: string): Observable<CannedChatCategory[]> {
    const url = `${environment.configApiRoot}/cannedtext`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      }),
      withCredentials: true
    };

    return this.http.get<CannedChatCategory[]>(
      url,
      httpOptions
    );
  }

  public loadRejectionReasons(authToken: string): Observable<InvitationRejectionReason[]> {
    const url = `${environment.configApiRoot}/rejectionreasons`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      }),
      withCredentials: true
    };

    return this.http.get<InvitationRejectionReason[]>(
      url,
      httpOptions
    );
  }

  public loadTransferReasons(authToken: string): Observable<InvitationReason[]> {
    const url = `${environment.configApiRoot}/transferreasons`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      }),
      withCredentials: true
    };

    return this.http.get<InvitationReason[]>(
      url,
      httpOptions
    );
  }

  public loadEngagementAssets(authToken: string): Observable<EngagementAssetPlaylist[]> {
    const url = `${environment.configApiRoot}/assets`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      withCredentials: true,
    };

    return this.http.get<EngagementAssetPlaylist[]>(
      url,
      httpOptions
    );
  }
}

