import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ElectronService } from '../electron-service/electron.service';

export class MockVersionService {
  public version        :string = "";
  public fullVersion    :string = "";
  public elecVersion = "";

  public isElectron     :boolean = false;

  parseVersionInformation(version :string) {}

  get electronVersion(): string {
    return this.elecVersion;
  }

  getPlatform(): string {
    return "";
  }
}

export const PlatformTypes = {
    BROWSER: "b",
    ELECTRON: "e",
    SALESFORCE: "s"
};

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public version        :string = "";
  public fullVersion    :string = "";

  public isElectron     :boolean = false;

  constructor(public electronService: ElectronService) {     
     this.isElectron = navigator.userAgent.toLowerCase().indexOf("electron") > -1;
     this.parseVersionInformation(environment.version);
  }

  get electronVersion() : string {
    return this.electronService.getVersion();
  }

  parseVersionInformation(version :string) {
    const releaseInfo = version.substring(0, version.indexOf("-"));
    const releaseInfoArr = releaseInfo.split("+");
    const releaseNumber = releaseInfoArr[0];
    const buildNumber = releaseInfoArr[1] || "0";
    const buildTime =  version.substring(version.indexOf("-") + 1);[1];
    const platform = this.getPlatform();

    this.version = `${releaseNumber}.${platform}`;
    this.fullVersion = `${this.version}+${buildNumber.trim()} - ${buildTime.trim()}`;
  }

  getPlatform(): string {

      if(this.isElectron) {
          return PlatformTypes.ELECTRON;
      }
      return PlatformTypes.BROWSER;
  }

}
