
<div class="login">

  <div class="login-wrapper">
    <img class="logo" src="./assets/images/logo.png" alt="Vee24"/>
    <h2>{{ "LOGIN_LABEL" | translate }}</h2>
    <form [formGroup]="loginForm" (submit)="submit()" novalidate>
      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-at"></p-inputIcon>
          <input pInputText type="email" id="email-input"
                 placeholder="{{ 'LOGIN_PLACEHOLDER_EMAIL' | translate }}" formControlName="email">
        </p-iconField>
      </div>
      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-lock"></p-inputIcon>
          <input pInputText type="password" id="password-input"
                 placeholder="{{ 'LOGIN_PLACEHOLDER_PASSWORD' | translate }}" formControlName="password">
        </p-iconField>
      </div>
      <p class="text-center text-danger"
         *ngIf="showAutoLogoutMessage">{{ 'LOGIN_AUTOLOGOUT_MESSAGE' | translate }}</p>
      <div class="buttons">
        <p-button severity="secondary" [text]="true"
                  [routerLink]="['/password-reset']">{{ 'LOGIN_LINK_FORGOTPASSWORD' | translate }}
        </p-button>
        <p-button type="submit" severity="success" [loading]="loggingIn">{{ "LOGIN_BTN_LABEL" | translate }}
        </p-button>
      </div>

    </form>
  </div>
  <app-info-bar></app-info-bar>
</div>
