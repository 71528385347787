<app-vee24-progress-spinner [loading]="loading"></app-vee24-progress-spinner>
<p-toast position="top-center" styleClass="text-center">
  <ng-template let-message pTemplate="message">
    <div class="w-100">
      <div class="text-center">{{message.detail}}</div>
    </div>
  </ng-template>
</p-toast>

<div class="overflow-hidden h-100 w-100">
  <router-outlet></router-outlet>
</div>
