<div class="d-flex flex-row w-100 container px-0 py-2 text-center border-bottom" role="button">
  <ng-container [ngSwitch]="engagementState">
    <div *ngSwitchCase="SupervisorEngagementState.SNOOPING" class="d-flex flex-row align-items-center flex-nowrap w-100 row no-gutters">
      <div class="col-3">
        <div class="circle surface-blue">
          <fa-icon [icon]="faUserSecret"/>
        </div>
      </div>
      <div class="col-2">
        <p-badge *ngIf="assistTime > 0" severity="info" [value]="assistTime"/>
      </div>
      <div class="col-7">{{ currentOperator }}</div>
    </div>
    <div *ngSwitchCase="SupervisorEngagementState.PRIVATE_CHAT" class="d-flex flex-row align-items-center flex-nowrap w-100 row no-gutters">
      <div class="col-3">
        <div class="circle p-overlay-badge surface-purple">
          <fa-layers class="fa-fw">
            <fa-icon size="lg" [icon]="faComment"></fa-icon>
            <fa-icon size="2xs" [icon]="faLock" transform="up-2" class="surface-purple-text"></fa-icon>
          </fa-layers>
          <p-badge *ngIf="unreadPrivateMessages > 0" [value]="unreadPrivateMessages" severity="danger"/>
        </div>
      </div>

      <div class="col-2">
        <p-badge severity="info"  [value]="calcTimeSince(lastContactTime)"/>
      </div>
      <div class="col-7">{{ currentOperator }}</div>
    </div>
    <div *ngSwitchCase="SupervisorEngagementState.PUBLIC_CHAT" class="d-flex flex-row align-items-center flex-nowrap w-100 row no-gutters ">
      <div class="col-3">
        <div class="circle surface-red  p-overlay-badge">
          <fa-icon [icon]="faComments"/>
          <p-badge *ngIf="unreadMessages > 0" [value]="unreadMessages" severity="danger"/>
        </div>
      </div>
      <div class="col-2">
        <p-badge severity="info" [value]="calcTimeSince(lastContactTime)"/>
      </div>
      <div class="col-7">{{ currentOperator }}</div>
    </div>
    <div *ngSwitchCase="SupervisorEngagementState.HELP_REQUEST"
         class="d-flex flex-row flex-nowrap align-items-center w-100 align-items-center row no-gutters">
      <div class="col-3">
        <div class="circle surface-red">
          <fa-icon [icon]="faHand"/>
        </div>
      </div>
      <div class="col-2">
        <p-badge severity="info" [value]="calcTimeSince(assistTime)"/>
      </div>
      <div class="col-5"><p>{{ currentOperator }}</p></div>
      <div class="col-2">
        <p-button  styleClass=" p-button-success p-button-sm" appDebounceClick
                  (clicked)="helpAgent($event)">
            <fa-icon [icon]="faPhone"></fa-icon>
        </p-button>
      </div>
    </div>
  </ng-container>
</div>



