<div class="d-flex flex-column h-100 border-0">
  <div class="w-auto p-2">
    <table class="table table-borderless  table-responsive-md mb-0 w-100">
      <tr>
        <td class="w-25 align-top">To</td>
        <td>
          <input type="text" class="w-100" placeholder="To" pInputText
            [disabled]="!currentEmail"
            [ngModel]="currentEmail?.emailTo" (ngModelChange)="currentEmail.emailTo = $event"
            [class.error]="currentEmail?.addressError" />
          <ng-container [ngSwitch]="currentEmail?.addressError">
            <p *ngSwitchCase="EmailState.Empty">{{ "EMAIL_MANDATORY" | Translate : 'This is mandatory'}}</p>
            <p *ngSwitchCase="EmailState.Incorrect">{{ "EMAIL_INVALID" | Translate : 'Invalid Email-Id'}}</p>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td class="w-25 align-top">Subject</td>
        <td>
          <input type="text" class="w-100" placeholder="Subject" pInputText
            [disabled]="!currentEmail"
            [ngModel]="currentEmail?.subject" (ngModelChange)="currentEmail.subject = $event"
            [class.error]="currentEmail?.subjectError" />
          <ng-container [ngSwitch]="currentEmail?.subjectError">
            <p *ngSwitchCase="EmailState.Empty">{{ "EMAIL_MANDATORY" | Translate : 'This is mandatory'}}</p>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td class="w-25 align-top">Message</td>
        <td>

          <app-message-template-viewer #templateViewer (contentChanged)="onTemplateContentChanged($event)"></app-message-template-viewer>

          <ng-container [ngSwitch]="currentEmail?.messageError">
            <p *ngSwitchCase="EmailState.Empty">{{ "EMAIL_MANDATORY" | Translate : 'This is mandatory'}}</p>
          </ng-container>
          <div class="attachment" *ngFor="let ff of attachedffMrkMat">
            <div class="attachment-select">{{ff.Title}}</div>
            <div class="attachment-add">
              <fa-icon [icon]="faMinusCircle" class="minus-icon" (click)="removeAttachment(ff)">
              </fa-icon>
            </div>
          </div>
          <div class="attachment">
            <div class="attachment-select">
              <p-dropdown class="drop-up"
                          placeholder="Select Attachment"
                          optionLabel="Title"
                          optionValue="Id"
                          [resetFilterOnHide]="true" [showClear]="true"
                          [options]="(fulfillmentMrkMat | async)"
                          [(ngModel)]="selectedFFMrkMatId">
              </p-dropdown>
            </div>
            <div class="attachment-add">
              <img src="../../../assets/images/add_file.png" (click)="addAttachment()"
                [hidden]="!addAttachmentVisible">
            </div>
          </div>

        </td>
      </tr>
    </table>
  </div>
  <div class="d-flex flex-column p-2 pt-0 w-auto">
    <div class="d-flex flex-row">
      <h5 class="w-25">{{ "EMAIL_TEMPLATES" | Translate : 'Email Templates'}}</h5>
      <section class="emailTemplateListContainer">
        <table class="table table-borderless table-responsive-md table-fixed email-template-table">
          <div *ngFor="let emailTemplate of (emailTemplates | async)">
            <tr *ngFor="let section of emailTemplate.Sections"
              [class.selected]="emailTemplate.Title === currentEmail?.emailTemplateTitle && section.Title === currentEmail?.emailSectionTitle">
              <td (click)="selectTemplate(emailTemplate, section)">{{section.Title}}</td>
              <td class="faActive">
                <fa-icon class="pull-right float-right mr-2" role="button"
                  [class.d-none]="!(emailTemplate.Title === currentEmail?.emailTemplateTitle && section.Title === currentEmail?.emailSectionTitle)"
                  [icon]="faPlusCircle" (click)="addEmail()"></fa-icon>
              </td>
            </tr>
          </div>
        </table>
      </section>
    </div>

    <div class="d-flex flex-row mt-3">
      <h5 class="w-25">{{ "EMAIL_INQUEUE" | Translate : 'Emails in Queue'}}</h5>
      <section class="emailTemplateListContainer">
        <table class="table table-borderless  table-responsive-md table-fixed email-template-table">
          <tr *ngFor="let email of emails?.emails">
            <td>{{email.emailSectionTitle}}</td>
            <td>
              <fa-icon [icon]="faMinusCircle" (click)="removeEmail(email)" role="button"
                class="pull-right float-right mr-2 faActive">
              </fa-icon>
            </td>
          </tr>
        </table>
      </section>
    </div>
  </div>

</div>
