import {Component, OnInit, Input, Output, EventEmitter, forwardRef} from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {ControlValueAccessorBase} from "../control-value-accessor.base";

@Component({
  selector: 'app-crm-field-number',
  templateUrl: './crm-field-number.component.html',
  styleUrls: ['./crm-field-number.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CrmFieldNumberComponent),
    multi: true
  }]
})
export class CrmFieldNumberComponent  extends ControlValueAccessorBase<string> implements OnInit {
  private _field: CrmField;

  @Input()
  set field(field: CrmField) {
    this._field = field;
    if (field) {
      this.writeValue(field.value);
    }
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public onFieldValueChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.onInputChange(value);
    if (this._field) {
      this._field.value = value;
      this.saveField.emit();
    }
  }
}
