import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth-service/auth.service';
import { AlertService, AlertType } from '../services/alert-service/alert.service';
import { map, take, tap } from 'rxjs/operators';
import { TranslatePipe } from '../filters/Translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class HomeAuthGuard  {

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private translate: TranslatePipe
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      return this.authService.currentAgent.pipe(
        take(1),
        map((currentAgent) => !!currentAgent),
        tap((loggedIn) => {
          if (!loggedIn) {
            this.alertService.addAlert(this.translate.transform("AUTHGUARD_HOME_NOT_LOGGED_IN", 'You must be logged into access that page.'), AlertType.Danger);
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          }
        })
      );
  }
  
}
