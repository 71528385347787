export class PostEngagementStatus {
  public status: string = '';
  public substatus: string = '';
  public notes: string = '';

  public static Equals(first: PostEngagementStatus, second: PostEngagementStatus): boolean {
    return first.status === second.status &&
      first.substatus === second.substatus &&
      first.notes === second.notes;
  }
}
