<div class="d-flex flex-column gap-2 sidebar-panel-inner-border-radius overflow-hidden">
  <app-video-preview *ngIf="cameraAllowed; else noCamera" [device]="(camera$ | async)"
                     [showHeadPositionOverlay]="true" [cameraAllowed]="cameraAllowed" [micAllowed]="micAllowed"
                     class="video-preview d-flex flex-column w-100"></app-video-preview>
  <ng-template #noCamera>
    <div class="w-100 h-100">
      <img class="w-100 h-100"
           src="./assets/images/audio_mode.png"/>
    </div>
  </ng-template>
  <app-media-permission (cameraAllowedChange)="onCameraAllowed($event)" (micAllowedChange)="onMicAllowed($event)">

    <div class="d-flex flex-column w-100 h-100  align-items-center">
      <app-avselection (selectCamera)="onSelectCamera($event)" (selectMicrophone)="onSelectMic($event)"
                       (selectSpeaker)="onSelectSpeaker($event)"
                       [selectedCamera]="(camera$ | async)" [selectedMicrophone]="(mic$ | async)"
                       [selectedSpeakerOutput]="(speaker$ | async)" [showMicSelection]="micAllowed"
                       [showSpeakerSelection]="micAllowed" [videoUnavailable]="!cameraAllowed"
                       class="w-100">
        <app-camera-controls class="d-flex flex-column w-100"></app-camera-controls>
      </app-avselection>
    </div>
  </app-media-permission>
</div>
