<table class="table table-borderless ">
  <thead>
  <tr class="d-flex text-center">
    <th class="col-3">{{ "SUPERVISOR_HEADER_AGENT"  | Translate : 'Agent' }} </th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_STATUS" | Translate : 'Status' }}</th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_TIMEAVAILABLE" | Translate : 'Time Unavailable' }}</th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_TOTALCALLS"  | Translate : 'Total Calls for Today' }}</th>
  </tr>
  </thead>
  <tbody *ngIf="show">
  <ng-container *ngFor="let group of selectedGroups | keyvalue">
    <tr  *ngIf="(selectedAgents | agentDashboardFilter: 'On Break' : group.key).size > 0"  class="op-group-header">
      <td>
        <h3 class="op-group-name">{{group.value}}</h3>
      </td>
    </tr>

    <tr *ngFor="let agent of (selectedAgents | agentDashboardFilter: 'On Break' : group.key | keyvalue)"
        class="d-flex text-center ml-3">
      <td class="col-3"><span class="text-truncate">{{agent.value.firstname}} {{agent.value.lastname}}</span>
      </td>
      <td class="col-3">{{agent.value.status | titlecase}}</td>
      <td class="col-3">{{TimeUtil.CreateTimeDifferenceForDisplay(agent.value.timestamp)}}</td>
      <td class="col-3">{{agent.value.totalSessionNumber}}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
