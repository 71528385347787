<p-table [value]="crmFieldCheckList()">
  <ng-template let-field pTemplate="body">
    <tr (click)="setFocusOnField(field)" role="button">
      <td>

          <p-checkbox *ngIf="field.IsCustomTask" [(ngModel)]="field.IsRulePassed" binary="true"></p-checkbox>

          <div *ngIf="field.IsCustomTask === false && field.CheckType === ChecklistFieldType.MANDATORY && !field.IsRulePassed">
            <fa-icon
              size="xl"
              [icon]="faTimesCircle"
              class="text-danger">
            </fa-icon>
          </div>
          <div *ngIf="field.IsCustomTask === false && field.CheckType === ChecklistFieldType.RECOMMENDED && !field.IsRulePassed" >
            <fa-icon
              size="xl"
              [icon]="faExclamationCircle"
              class="text-warning">
            </fa-icon>
          </div>
          <div *ngIf="field.IsCustomTask === false && field.IsRulePassed">
            <fa-icon
              size="xl"
              [icon]="faCheckCircle"
              class="text-success">
            </fa-icon>
          </div>

      </td>
      <td>
        <span>{{ field.Title }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3"> {{ "CHECKLIST_LABLE_CHECKLISTNOTAVAILABLE" | Translate : 'Checklist not available.' }}</td>
    </tr>
  </ng-template>
</p-table>
