import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {InvitationReason} from '../../../classes/transfer/invitationReason';
import Quill from 'quill';
import {map} from 'rxjs/operators';
import {SettingsService} from '../../../services/settings-service/settings.service';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "primeng/dropdown";


@Component({
  selector: 'app-engagement-transfer-message',
  templateUrl: './engagement-transfer-message.component.html',
  styleUrls: ['./engagement-transfer-message.component.scss']
})
export class EngagementTransferMessageComponent implements OnInit {
  @ViewChild('invitereasons', {static: true}) inviteReasonSelector: Dropdown;

  @Input() enableSend: boolean;
  @Input() transfer: boolean;

  @Output() cancelInvite = new EventEmitter<void>();
  @Output() sendInviteRequest = new EventEmitter<string>();

  public quillEditor: Quill;
  public quillPlaceholder = '';

  public inviteReasonText = '';

  private readonly bindings = {
    findReason: {
      key: 'm',
      ctrlKey: true,
      handler: (range, context) => {
        if (this.inviteReasonSelector) {
          this.inviteReasonSelector.show();
        }
      }
    }
  };

  public quillModules = {
    toolbar: false,
    keyboard: { bindings: this.bindings }
  };

  public inviteReasons: Observable<InvitationReason[]>;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly translate: TranslatePipe) {
  }

  ngOnInit() {
    if (!this.transfer) {
      this.quillPlaceholder = this.translate.transform("ENGAGEMENTTRANSFER_PLACEHOLDER_JOINREASON",'Join Reason');
      this.inviteReasons = this.settingsService.invitationReasons.pipe(map(reasonsList => reasonsList.filter(reason => reason.Multiparty)));
    } else {
      this.quillPlaceholder = this.translate.transform("ENGAGEMENTTRANSFER_PLACEHOLDER_TRANSFERREASON",'Transfer Reason');
      this.inviteReasons = this.settingsService.invitationReasons.pipe(map(reasonsList => reasonsList.filter(reason => reason.Transfer)));
    }
  }

  /**
   * On Select Invite reason
   * @param inviteReason Reference of selected invite reason
   */
  public onInviteReasonSelected($event) {
    this.inviteReasonText = $event.value;
  }

  public onEditorCreated({editor}) {
    this.quillEditor = editor;
  }

  sendInvite() {
    const message = this.quillEditor.getText().trim();
    this.sendInviteRequest.emit(message);
  }

  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
