import { WebGLHelper, glsl } from '../WebglHelper'

export default class BackgroundNoneStage {

    constructor(
        gl,
        positionBuffer,
        texCoordBuffer,
        canvas
    ) {

        this.buildBackgroundPass = (
            gl, 
            positionBuffer, 
            texCoordBuffer, 
            canvas
        ) => {

            const vertexShaderSource = glsl`#version 300 es    
                in vec2 a_position;
                in vec2 a_texCoord;
            
                out vec2 v_texCoord;
            
                void main() {
                    // Flipping Y is required when rendering to canvas
                    gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
                    v_texCoord = a_texCoord;
                }
            `;

            const fragmentShaderSource = glsl`#version 300 es
                precision highp float;

                in vec2 v_texCoord;

                uniform sampler2D u_inputFrame;

                out vec4 outColor;

                void main() {
                    outColor = texture(u_inputFrame, v_texCoord);
                }
            `;

            const outputWidth = canvas.width;
            const outputHeight = canvas.height;

            const vertexShader = WebGLHelper.compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
      
            const fragmentShader = WebGLHelper.compileShader(
                gl,
                gl.FRAGMENT_SHADER,
                fragmentShaderSource
            );
            const program = WebGLHelper.createPiplelineStageProgram(
                gl,
                vertexShader,
                fragmentShader,
                positionBuffer,
                texCoordBuffer
            );
            const inputFrameLocation = gl.getUniformLocation(program, 'u_inputFrame');

            gl.useProgram(program);
            gl.uniform1i(inputFrameLocation, 0);

            function render() {
                gl.viewport(0, 0, outputWidth, outputHeight);
                gl.useProgram(program);
                gl.activeTexture(gl.TEXTURE0);
                gl.uniform1i(inputFrameLocation, 0);
                gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
            }
            function cleanUp() {
                gl.deleteProgram(program);
                gl.deleteShader(fragmentShader);
            }
            return {
                render,
                cleanUp,
            };
        };

        this.backgroundPass = this.buildBackgroundPass(
            gl,
            positionBuffer,
            texCoordBuffer,           
            canvas
        );
    }

    render() {
        this.backgroundPass.render();
    }

    updateCoverage(coverage) {}

    cleanUp() {
        this.backgroundPass.cleanUp();
    }  

}
