<div>

<ng-container [ngSwitch]="emailState.type">
  <div *ngSwitchCase="CrmEmailState.Incorrect" class="w-100">
    <p-toolbar styleClass="mb-2 flex-nowrap px-2">
      <div class="p-toolbar-group-start gap-4 align-items-center">
        <p-button (onClick)="backToOpportunitySearch()"
                  styleClass="btn-rectangle  center-icon">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </p-button>
      </div>
    </p-toolbar>

    <p class="lead text-danger text-center">{{ "ENGAGEMENTOPPORTUNITY_ERROR_EMAIL" | Translate : 'Email format not valid' }}</p>
  </div>
  <div *ngSwitchCase="CrmEmailState.Empty" class="w-100">
    <p-toolbar styleClass="mb-2 flex-nowrap px-2">
      <div class="p-toolbar-group-start gap-4 align-items-center">
        <p-button (onClick)="backToOpportunitySearch()"
                  styleClass="btn-rectangle  center-icon">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </p-button>
      </div>
    </p-toolbar>

    <p class="lead text-danger text-center">{{ "ENGAGEMENTOPPORTUNITY_EMAIL_MANDATORY" | Translate : 'Email is mandatory' }}</p>
  </div>

  <div *ngSwitchCase="CrmEmailState.Valid" class="w-100">
    <p-toolbar styleClass="mb-2 flex-nowrap gap-1 px-2">
      <div class="p-toolbar-group-start gap-4 align-items-center">
        <p-button (onClick)="backToOpportunitySearch()"
                  styleClass="btn-rectangle  center-icon">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </p-button>
        <p-button (onClick)="saveOpportunityData()" *ngIf="!isReadOnly" styleClass="btn-rectangle center-icon p-button-success">
            <fa-icon [icon]="faSave"></fa-icon>
        </p-button>
      </div>
    </p-toolbar>

    <div class="form-group ">
      <table class="table table-borderless w-100">
        <tr>
          <td style="width: 15%;">
            <label class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_LEADID" | Translate : 'Lead ID' }} </label>
          </td>
          <td style="width: 35%;">
            <label>{{ leadId }}</label>
          </td>
          <td style="width: 15%;">
            <label
              class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_CUSTOMERNAME" | Translate : 'Customer Name' }}
            </label>
          </td>
          <td style="width: 35%;">
            <label>{{ customerName }}</label>
          </td>
        </tr>
        <tr>
          <td>
            <label
              class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_EXISTINGOPPORTUNITY" | Translate : 'Existing Opportunity' }}
            </label>
          </td>
          <td>
            <p-dropdown (onChange)="opportunityChange()" [(ngModel)]="opportunityId"
                                [showClear]="false" [resetFilterOnHide]="true"
                                [disabled]="isReadOnly || crmStateInput==CrmState.EditOpportunity" [options]="opportunities"
                                class="dropdown"
                                optionLabel="opportunityTitle"
                                optionValue="opportunityId" placeholder='{{ "ENGAGEMENTOPPORTUNITY_PLACEHOLDER_CREATENEW" | Translate : "Create New" }}'>
            </p-dropdown>
          </td>
          <td>
            <label class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_STATUS" | Translate : 'Status' }} </label>
          </td>
          <td>
            <p-dropdown [(ngModel)]="opportunityStatusId" [showClear]="false" [resetFilterOnHide]="true"
                                [disabled]="isReadOnly"
                                [options]="(opportunitystatus)" class="dropdown"
                                optionLabel="StatusTitle"
                                optionValue="StatusId" placeholder='{{ "ENGAGEMENTOPPORTUNITY_PLACEHOLDER_SELECTSTATUS" | Translate : "Select Opportunity Status"}}'>
            </p-dropdown>
          </td>
        </tr>
        <tr>
          <td>
            <label
              class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_TITLE" | Translate : 'Opportunity Title' }}</label>
          </td>
          <td>
            <input [(ngModel)]="opportunityTitle"  type="text" [disabled]="isReadOnly" pInputText>
          </td>
          <td>
            <label class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_VALUE" | Translate : 'Value' }}</label>
          </td>
          <td>
            <input [(ngModel)]="opportunityValue"  type="text" [disabled]="isReadOnly" pInputText>
          </td>
        </tr>
        <tr>
          <td>
            <label class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_PRODUCT" | Translate : 'Product' }}</label>
          </td>
          <td>
            <input [(ngModel)]="opportunityProduct"  type="text" value="opportunityProduct" [disabled]="isReadOnly" pInputText>
          </td>
          <td>
            <label class="control-label">{{ "ENGAGEMENTOPPORTUNITY_LABEL_NOTES" | Translate : 'Notes' }} </label>
          </td>
          <td rowspan="2">
            <textarea pInputTextarea [disabled]="isReadOnly" [(ngModel)]="opportunityNotes"  cols="100" rows="4"></textarea>
          </td>
        </tr>
        <tr>
          <td>

          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>

</div>
