<ng-container [ngSwitch]="crmState.type">
  <div *ngSwitchCase="CrmState.Search" class="h-100">
    <app-crm-search (addCustomer)="onAddCustomer()"
                    (selectedUser)="onSelectedUser($event)"
                    (selectedOportunity)="onSelectedOpportunity($event)"
                    [searchCustomer] = "true"
    ></app-crm-search>
  </div>
  <div *ngSwitchCase="CrmState.OpportunitySearch" class="h-100">
    <app-crm-search (addCustomer)="onAddCustomer()"
                    (selectedUser)="onSelectedUser($event)"
                    (selectedOportunity)="onSelectedOpportunity($event)"
                    [searchCustomer] = "false"
    ></app-crm-search>
  </div>

  <div *ngSwitchCase="CrmState.AddCustomer" class="d-flex flex-column h-100 overflow-auto">
    <p-toolbar styleClass="mb-2 flex-nowrap px-2">
      <div class="p-toolbar-group-start gap-4 align-items-center">
        <p-button id="back-button" styleClass="btn-rectangle  center-icon" (click)="backToSearch()">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </p-button>
        <p-button id="save-button" styleClass="p-button-success center-icon btn-rectangle"
                  (click)="saveCrmData()">
            <fa-icon [icon]="faSave"></fa-icon>

        </p-button>
      </div>
    </p-toolbar>
    <app-crm-data [canEditCrm]="true"
                  [visitorCrmData]="(contactData | async)"
                  (saveData)="saveCrmData()"
    ></app-crm-data>
  </div>

  <div *ngSwitchCase="CrmState.EditCustomer" class="d-flex flex-column h-100 overflow-hidden">
    <p-tabView styleClass="justify-end">
      <p-tabPanel header='{{ "CRMTAB_HEADER_CUSTOMERDATA" | Translate : "Customer Data" }}' *ngIf="true">
        <p-toolbar styleClass="flex-nowrap">
          <div class="p-toolbar-group-start gap-4 align-items-center">
            <p-button id="back-button" styleClass="btn-rectangle  center-icon" (click)="backToSearch()">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </p-button>
            <p-button id="save-button" styleClass="p-button-success center-icon btn-rectangle"
                      (click)="saveCrmData()">
                <fa-icon [icon]="faSave"></fa-icon>
            </p-button>
          </div>
        </p-toolbar>
        <app-crm-data [canEditCrm]="true"
                      [visitorCrmData]="(contactData | async)"
                      (saveData)="saveCrmData()"
        ></app-crm-data>
      </p-tabPanel>
      <p-tabPanel header='{{ "CRMTAB_HEADER_OPPORTUNITY" | Translate : "Opportunity" }}'  *ngIf="IsCRMOpportunityAvailable()">
        <app-opportunity
          (backToSearch)="backToSearch()"
          [visitorCrmData]="(contactData | async)"
          [emailState]="(emailState | async)"
          [opportunities]="(opportunities | async)"
          [userGuid]="getUserGuid()"
          [crmStateInput]="CrmState.EditCustomer"
        ></app-opportunity>
      </p-tabPanel>
      <p-tabPanel header='{{ "CRMTAB_HEADER_SESSIONHISTORY" | Translate : "Session History" }}' *ngIf="true">

        <p-toolbar styleClass="flex-nowrap">
          <div class="p-toolbar-group-start gap-4 align-items-center">
            <p-button id="back-button" styleClass="btn-rectangle  center-icon" (click)="backToSearch()">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </p-button>
            <p-button id="save-button" styleClass="p-button-success center-icon btn-rectangle"
                      (click)="saveCrmData()">
                <fa-icon [icon]="faSave"></fa-icon>
            </p-button>
          </div>
        </p-toolbar>
        <app-session-history [rows]="30" [visitorSessionHistory]="(contactSessionHistory | async)"
        ></app-session-history>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngSwitchCase="CrmState.AppointmentSearch" class="d-flex flex-column h-100 overflow-hidden">
    <p-tabView class="justified " [activeIndex]="selectSessionHistoryTab ? 2 : 0">
      <p-tabPanel header='{{ "CRMTAB_HEADER_CUSTOMERDATA" | Translate : "Customer Data" }}'>
        <p-toolbar styleClass="flex-nowrap">
          <div class="p-toolbar-group-start gap-4 align-items-center">
            <p-button id="back-button" styleClass="btn-rectangle  center-icon" (click)="backToAppointmentSearch()">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </p-button>
            <p-button id="save-button" styleClass="p-button-success center-icon btn-rectangle"
                      (click)="saveCrmData()">
                <fa-icon [icon]="faSave"></fa-icon>
            </p-button>
          </div>
        </p-toolbar>
<!--        TODO: Refactor this, lots of repeated code-->
        <app-crm-data [canEditCrm]="true"
                      [visitorCrmData]="(contactData | async)"
                      (saveData)="saveCrmData()"
        ></app-crm-data>
      </p-tabPanel>
      <p-tabPanel header='{{ "CRMTAB_HEADER_OPPORTUNITY" | Translate : "Opportunity" }}'>
        <app-opportunity
          (backToSearch)="backToAppointmentSearch()"
          [visitorCrmData]="(contactData | async)"
          [emailState]="(emailState | async)"
          [opportunities]="(opportunities | async)"
          [userGuid]="getUserGuid()"
          [crmStateInput]="CrmState.EditCustomer"
        ></app-opportunity>
      </p-tabPanel>
      <p-tabPanel header='{{ "CRMTAB_HEADER_SESSIONHISTORY" | Translate : "Session History" }}'>
        <p-toolbar styleClass="flex-nowrap">
          <div class="p-toolbar-group-start gap-4 align-items-center">
            <p-button id="back-button" styleClass="btn-rectangle  center-icon" (click)="backToAppointmentSearch()">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </p-button>
            <p-button id="save-button" styleClass="p-button-success center-icon btn-rectangle"
                      (click)="saveCrmData()">
                <fa-icon [icon]="faSave"></fa-icon>
            </p-button>
          </div>
        </p-toolbar>
        <app-session-history [rows]="30" [visitorSessionHistory]="(contactSessionHistory | async)"
        ></app-session-history>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngSwitchCase="CrmState.EditOpportunity" class="d-flex flex-column h-100 overflow-auto">
    <app-opportunity
      [visitorCrmData]="(contactData | async)"
      [emailState]="(emailState | async)"
      [selectedOpportunity]="selectedOpportunity.value"
      [userGuid]="selected.vee24Guid"
      [crmStateInput]="CrmState.EditOpportunity"
      (backToSearch)="backToOpportunitySearch()"
    ></app-opportunity>
  </div>
</ng-container>
