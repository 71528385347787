import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {InvitationRejectionReason} from '../../classes/transfer/invitationRejectionReason';
import Quill from 'quill';
import {SettingsService} from '../../services/settings-service/settings.service';
import {InvitationRequest} from '../../classes/transfer/invitationRequest';
import {map} from 'rxjs/operators';
import {TextMessage} from '../../classes/TextMessage';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {faCheck, faChevronLeft, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "primeng/dropdown";

export interface InvitationResources {
  transferRequestTitle: string;
  from: string;
  reason: string;
  transcriptTitle: string;
  rejectTitle: string;
  rejectionReasonTitle: string;
}

export interface InvitationInitialState {
  acceptCallback: () => void;
  cancelCallback: () => void;
  sendMessage: (message: string) => void;
  rejectCallback: (reason: string) => void;
  invitationRequest: InvitationRequest;
  textResources: InvitationResources;
}

enum ModalViewState {
  Accepting,
  Rejecting,
}

@Component({
  selector: 'app-invitation-in-modal',
  templateUrl: './invitation-in-modal.component.html',
  styleUrls: ['./invitation-in-modal.component.scss']
})
export class InvitationInModalComponent implements OnInit, OnDestroy {
  @ViewChild('rejectionReasonSelector', {static: true}) rejectionReasonSelector: Dropdown;

  public acceptCallback: () => void;
  public rejectCallback: (reason: string) => void;
  public sendMessage: (message: string) => void;

  public textResources: InvitationResources;

  public invitationRequest: InvitationRequest;

  hasTransferChat: boolean = false;

  public get engagementId(): string {
    return this.invitationRequest.roomId;
  }

  public get transferringReason(): string {
    return this.invitationRequest.reason;
  }

  public get transferringOperator(): string {
    return this.invitationRequest.sourceAgentFullname;
  }

  public get textMessages(): TextMessage[] {
    return this.invitationRequest.chatHistory ? this.invitationRequest.chatHistory : [];
  }

  public get data(): InvitationInitialState {
    return this.dialogConfig.data;
  }

  ModalViewState = ModalViewState; // export state to the view
  transferViewState: ModalViewState = ModalViewState.Accepting;

  public rejectionText = '';

  public quillEditor: Quill;
  private readonly bindings = {
    findReason: {
      key: 'm',
      ctrlKey: true,
      handler: (range, context) => {
        if (this.rejectionReasonSelector) {
          this.rejectionReasonSelector.show();
        }
      }
    }
  };

  public quillModules = {
    toolbar: false,
    keyboard: { bindings: this.bindings }
  };

  public rejectionReasons: Observable<InvitationRejectionReason[]>;

  constructor(
    private settingsService: SettingsService,
    private featureService: FeatureService,
    private dialogConfig: DynamicDialogConfig<InvitationInitialState>
  ) {
    this.textResources = this.data.textResources;
    this.invitationRequest = this.data.invitationRequest;
    this.acceptCallback = this.data.acceptCallback;
    this.rejectCallback = this.data.rejectCallback;
  }

  cancelCallback() {
    if (this.transferViewState === ModalViewState.Rejecting) {
      this.transferViewState = ModalViewState.Accepting;
    } else {
      this.data.cancelCallback();
    }
  }

  ngOnInit() {
    this.rejectionReasons = this.settingsService.rejectionReasons.pipe(map(reasonList => reasonList.filter(reason => reason.Transfer)));
    this.hasTransferChat = false; //TODO: remove underlying logic.  Not working and nobody using it.  Inter-agent chat while transferring
  }

  ngOnDestroy() {
  }

  public onEditorCreated({editor}) {
    this.quillEditor = editor;
  }

  public onRejectionReasonSelected(rejectionReason, runTimeout: boolean = true) {
    this.rejectionText = rejectionReason;

    if (runTimeout) {
      // Need to run after the handle returns otherwise focus snaps back to the select box
      setTimeout(() => {
        this.quillEditor.focus();
        const contentLength = this.quillEditor.getLength();
        this.quillEditor.setSelection(contentLength, 0);
      }, 0);
    }
  }

  rejectTransferringInConfirmation() {
    this.transferViewState = ModalViewState.Rejecting;
  }

  public onReject() {
    const rejectionMessage = this.quillEditor.getText().trim();
    this.rejectCallback(rejectionMessage);
  }

  protected readonly faTimes = faTimes;
  protected readonly faCheck = faCheck;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
