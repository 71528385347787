import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VisitorService} from '../../services/visitor-service/visitor.service';
import {Router} from '@angular/router';
import {VeechatCallListComponent, VeechatSelection, VeechatSelectionType} from './veechat-call-list/veechat-call-list.component';
import {OnlineState} from '../../enums/online-state.enum';
import {OnlineService} from '../../services/online.service';
import {EngagementService} from '../../services/engagement.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {AuthService} from '../../services/auth-service/auth.service';
import {Agent} from '../../classes/agent';
import {CrmService} from '../../services/crm-service/crm.service';
import {CustomerSizes, Engagement, EngagementEventTypes, EngagementState, PanelSize} from '../../services/engagement';
import {VeechatEngagementComponent} from './veechat-engagement/veechat-engagement.component';
import {EmailService} from '../../services/email-service/email.service';
import {FulfillmentService} from '../../services/fulfillment-service/fulfillment.service';
import {Title} from '@angular/platform-browser';
import {LicenceType} from '../../enums/licence-type.enum';
import {WorkStatus} from '../../classes/work-status';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {faComment, faComments} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-veechat',
  templateUrl: './veechat.component.html',
  styleUrls: ['./veechat.component.scss'],
  providers: [ VisitorService ]
})
export class VeechatComponent implements OnInit, OnDestroy {

  public static USE_WINDOW_BEFOREUNLOAD = true;
  @ViewChild(VeechatEngagementComponent, {static: false}) engagementComponent: VeechatEngagementComponent;

  public OnlineState = OnlineState;
  public VeechatSelection = VeechatSelection;

  public currentState$: BehaviorSubject<OnlineState> = new BehaviorSubject<OnlineState>(OnlineState.MultiChat);
  public currentAgent: BehaviorSubject<Agent | null> = new BehaviorSubject<Agent | null>(null);
  public username = '';

  public selected: VeechatSelectionType = VeechatCallListComponent.NO_SELECTION;

  public readonly isAgentEngaged: Observable<boolean> = this.engagementService.activeEngagements.pipe(map(n => n >= 1));

  public panelPositionAndSize$:Observable<CustomerSizes> = of({
    browserWidth: 0,
    browserHeight: 0,
    size: PanelSize.Normal,
    panelDimensions: "0,0,0,0"
  });

  public isPrimaryAgent$:Observable<boolean> = of(false);
  public isPresenter$:Observable<boolean> = of(false);
  public isEngaged$:Observable<boolean>= of(false);
  public isMobileOrTablet$:Observable<boolean>= of(false);
  public backgrounded: Observable<boolean> = of(false);

  private subscriptions: Subscription[] = [];
  public noInternet: BehaviorSubject<boolean>;
  private readonly unloadMethod = (e: Event) => this.onBeforeUnload(e);

  // Used to guard updating the agent's status in the ngInit call in case
  // veechat is closed before the webservices return
  private veechatDestroyed: boolean = false;

  workingStatus: Observable<WorkStatus>;


  constructor(
    private visitorService: VisitorService,
    private engagementService: EngagementService,
    private onlineService: OnlineService,
    private settingsService: SettingsService,
    private router: Router,
    private authService: AuthService,
    private crmService: CrmService,
    private emailService: EmailService,
    private fulfillmentService: FulfillmentService,
    private titleService: Title,
    private readonly featureService: FeatureService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('VeeChat');

    const showPreviousEngagements = this.featureService.has(Features.REJOIN_ENGAGEMENT);

    this.visitorService.startConnection(LicenceType.VeeChat, showPreviousEngagements).subscribe(success => {
      if (!success) {
        this.router.navigateByUrl('/login');
      } else {
        if (VeechatComponent.USE_WINDOW_BEFOREUNLOAD) {
          // Prevent page reloading if use has interacted with it
          window.addEventListener('beforeunload', this.unloadMethod);
        }

        forkJoin([
          this.settingsService.loadAll(),
          this.crmService.loadAll(),
          this.emailService.loadEmailTemplateList('Chat'),
          this.emailService.loadEmailTemplateList(EngagementEventTypes.VeeChat),
          this.fulfillmentService.getMarketingMaterial()
        ]).subscribe(() => {
          if (!this.veechatDestroyed) {
            this.onlineService.setCurrentState(OnlineState.OnBreak, WorkStatus.Async | WorkStatus.Multichat);
            this.visitorService.getAsyncChats();
          }
        });

        const stateSub = this.onlineService.currentState.pipe(distinctUntilChanged())
                            .subscribe(([state, _]) => this.currentState$.next(state));
        this.subscriptions.push(stateSub);

        this.currentAgent = this.authService.currentAgent;
        if (this.currentAgent.value) {
          this.username = this.currentAgent.value.username;
        }

      }

    });

    this.noInternet = this.visitorService.noInternet;
  }

  ngOnDestroy() {
    this.veechatDestroyed = true;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSelection(selection: VeechatSelectionType) {

    this.selected = selection;

    if (selection.type !== VeechatSelection.engagement) {
      this.resetSelectedEngagement();
    }
  }

  private handleSelectedEngagement(engagement: Engagement) {
    this.isPrimaryAgent$ = engagement.isPrimary;
    this.isPresenter$ = engagement.isPresenter;
    this.isEngaged$ = engagement.currentState.pipe(map(v => v.type === EngagementState.Engaged));
    this.panelPositionAndSize$ = engagement.panelPositionAndSize;
    this.isMobileOrTablet$ = engagement.visitor.details.pipe(map(v => v.isMobile === "1" || v.isTablet === "1"));
    this.backgrounded = engagement.backgrounded.asObservable();
  }

  private resetSelectedEngagement() {
    this.isPrimaryAgent$ = of(false);
    this.isPresenter$ = of(false);
    this.isEngaged$ = of(false);
    this.panelPositionAndSize$ = of({
      browserWidth: 0,
      browserHeight: 0,
      size: PanelSize.Normal,
      panelDimensions: "0,0,0,0"
    });
    this.isMobileOrTablet$ = of(false);
    this.backgrounded = of(false);
  }

  onEngagementEnded() {
    this.selected = VeechatCallListComponent.NO_SELECTION;
  }

  onEngagementChange(engagement: Engagement) {
    this.handleSelectedEngagement(engagement);
  }

  private onBeforeUnload(e: Event) {

    if (this.engagementService.activeEngagements.value === 0) {
      return;
    }

    e.preventDefault();
    e.returnValue = true;
  }

  onEndBreak() {
    this.onlineService.setCurrentState(OnlineState.MultiChat);
    this.router.navigateByUrl('/veechat');
  }

  onPanelPositionChanged($event) {
    if (!this.engagementComponent) {
      return;
    }
    this.engagementComponent.setPanelPosition($event);
  }

  onSelectAsset($event) {
    if (!this.engagementComponent) {
      return;
    }
    this.engagementComponent.selectAsset($event);
  }

  onWorkingStatus($event: WorkStatus) {
    this.onlineService.setCurrentState(this.onlineService.currentState.value[0], $event);
  }

  protected readonly faComments = faComments;
  protected readonly faComment = faComment;
}
