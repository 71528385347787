import {Injectable, OnDestroy} from '@angular/core';
import {OnlineState} from '../enums/online-state.enum';
import {BehaviorSubject} from 'rxjs';
import {VirtualBackground} from '../classes/virtualBackground';
import {WorkStatus} from '../classes/work-status';

@Injectable({
  providedIn: 'root'
})
export class OnlineService implements OnDestroy {

  public selectedCamera = new BehaviorSubject<MediaDeviceInfo>({deviceId: '', label: '', groupId: '', kind: 'videoinput'} as MediaDeviceInfo);
  public selectedMic = new BehaviorSubject<MediaDeviceInfo>({deviceId: '', label: '', groupId: '', kind: 'audioinput'} as MediaDeviceInfo);
  public selectedSpeaker = new BehaviorSubject<MediaDeviceInfo>({deviceId: '', label: '', groupId: '', kind: 'audiooutput'} as MediaDeviceInfo);

  public virtualBackground = new BehaviorSubject<VirtualBackground>(null);
  public virtualBackgroundOn = new BehaviorSubject<boolean>(false);

  private onlineState = OnlineState.OffLine;
  private workingMode = WorkStatus.None;
  public currentState: BehaviorSubject<[OnlineState, WorkStatus]> = new BehaviorSubject([OnlineState.OffLine, WorkStatus.None]);
  public deskLicenceKey: string = '';

  constructor() {
  }

  ngOnDestroy(): void {
  }

  public setCurrentState(newState: OnlineState, workingMode?: WorkStatus) {
    this.onlineState = newState;
    if (workingMode !== undefined) {
      this.workingMode = workingMode;
    }
    this.currentState.next([this.onlineState, this.workingMode]);
  }

}
