import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faCalendar, faCalendarPlus, faClockFour, faList, faSave} from '@fortawesome/free-solid-svg-icons';
import {Location} from '@angular/common';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, first, map, tap} from 'rxjs/operators';
import {Appointment, AppointmentFilterParameter, AppointmentType, CreateAppointmentRequest} from '../../services/crm-service/appointment';
import {CrmContact} from '../../services/crm-service/crm-contact';
import {StatusEnum} from '../../enums/appointment-status';
import {OnlineState} from '../../enums/online-state.enum';
import {Table} from 'primeng/table';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {CrmService} from '../../services/crm-service/crm.service';
import {OnlineService} from '../../services/online.service';
import {AuthService} from '../../services/auth-service/auth.service';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {ISortObject, SortFactoryService} from '../../services/sort-factory-service/sort-factory.service';
import {AppointmentService} from '../../services/appointment/appointment.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {SessionTypes} from '../../services/crm-service/sessionTypes';
import {MicrosoftTimeZone, Timezones} from '../../utils/timezones';
import * as moment from 'moment';
import {CrmTabs} from '../../enums/CrmTabs';
import {CrmComponent} from '../../components/crm/crm.component';
import {Agent} from '../../classes/agent';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {AppointmentSearchResponseResult} from '../../services/appointment/appointment.backend.service';

interface CustomMenuItem extends MenuItem {
  faIcon: IconDefinition;
}

interface StatusMappingType {
  value: StatusEnum;
  status: string;
  severity: 'danger' | 'info' | 'warning' | 'success' | 'default';
}

const STATUS_MAPPING: StatusMappingType[] = [
  {value: StatusEnum.ALL, status: 'ALL', severity: 'default'},
  {value: StatusEnum.OPEN, status: 'OPEN', severity: 'success'},
  {value: StatusEnum.CLOSED, status: 'CLOSED', severity: 'info'},
  {value: StatusEnum.CANCELLED, status: 'CANCELLED', severity: 'warning'},
  {value: StatusEnum.UNAVAILABLE, status: 'UNAVAILABLE', severity: 'danger'},
  {value: StatusEnum.NOSHOW, status: 'NOSHOW', severity: 'warning'},
  {value: StatusEnum.CLOSED_AUTO, status: 'CLOSED (AUTO)', severity: 'info'},
  {value: StatusEnum.NOSHOW_AUTO, status: 'NOSHOW (AUTO)', severity: 'warning'},
];

@Component({
  selector: 'app-appointment-search',
  templateUrl: './appointment-search.component.html',
  styleUrls: ['./appointment-search.component.scss'],
  providers: [TranslatePipe],
})
export class AppointmentSearchComponent implements OnInit, OnDestroy {

  constructor(
    private onlineService: OnlineService,
    private authService: AuthService,
    private crmService: CrmService,
    private translate: TranslatePipe,
    private alertService: AlertService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private sortFactory: SortFactoryService,
    private settingsService: SettingsService,
    private appointmentService: AppointmentService,
    private featureService: FeatureService,
  ) {
    this.appointmentSort = this.sortFactory.getSortObject<Appointment>();
  }
  @ViewChild('appointmentTable', {static: false}) appointmentTable: Table;

  public submitting = false;
  public crmInformationView = false;
  public selectedContact: CrmContact;
  public selectedRow: Appointment;
  public selectSessionHistoryTab = false;
  public appointmentFilterParameters: AppointmentFilterParameter = new AppointmentFilterParameter();
  public sessionTypes: SessionTypes[] = [];
  public agentLists: Agent[] = [];
  public appointmentTypes: AppointmentType[] = [];
  public agentsCalendarView = false;
  public menu: CustomMenuItem[] = [
    {
      label: this.translate.transform('APPOINTMENTSEARCH_LABEL_APPOINTMENTS', 'Appointments'),
      faIcon: faList,
      command: () => {
        this.agentsCalendarView = false;
      },
    },
    {
      label: this.translate.transform('APPOINTMENTSEARCH_LABEL_AGENTCALENDAR', 'Agent Calendar'),
      faIcon: faCalendar,
      command: () => {
        this.agentsCalendarView = true;
      },
    },
  ];
  public activeItem: MenuItem = this.menu[0];
  public timezoneAbrv: string;
  public timezone: MicrosoftTimeZone;
  public loading = false;
  public count = new BehaviorSubject<number>(1);
  public rows = new BehaviorSubject<Appointment[]>([]);
  public appointments: Appointment[] = [];
  public columns = [];
  public currentState$: Observable<OnlineState>;
  public OnlineState = OnlineState;
  public showAppointmentSidebar = false;
  public canCreateAppointment = false;

  protected readonly faSave = faSave;
  protected readonly faCalendarPlus = faCalendarPlus;
  protected readonly statusMapping = STATUS_MAPPING;
  protected readonly faClockFour = faClockFour;

  private allLabel = 'All';
  private subscriptions: Subscription[] = [];
  private appointmentSort: ISortObject<Appointment>;

  public selectedAppointment$ = new BehaviorSubject<Appointment>(null);

  protected readonly Appointment = Appointment;

  ngOnInit() {
    this.canCreateAppointment = this.featureService.has(Features.CREATE_SCHEDULED_APPOINTMENT);
    STATUS_MAPPING.forEach(mapping => {
      mapping.status = this.translate.transform(`APPOINTMENTSEARCH_STATUS_${mapping.value.toString()}`, mapping.status);
    });

    this.selectedContact = new CrmContact(CrmComponent.EMPTY_SELECTION);
    this.authService.currentAgent.pipe(first(), catchError(() => of(null))).subscribe(agent => {
      this.timezoneAbrv = Timezones.getAbbreviation(agent.timezone as MicrosoftTimeZone);
      this.timezone = agent.timezone as MicrosoftTimeZone;
      this.columns = this.getColumns();
      this.appointmentFilterParameters.agents = this.featureService.has(Features.SUPERVISOR_TRANSFER_APPOINTMENT) ? [] : [agent.username];
    });

    this.setDateFilterParameters();
    this.allLabel = this.translate.transform('APPOINTMENTSEARCH_LABEL_ALL', 'All');
    this.currentState$ = this.onlineService.currentState.pipe(map(([state, _]) => state));
    this.onlineService.setCurrentState(OnlineState.Appointment);

    this.loadFilterParameters();
    this.search();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  onEndBreak() {
    this.onlineService.setCurrentState(OnlineState.Appointment);
  }

  loadFilterParameters() {
    this.subscriptions.push(
      this.appointmentService.loadAppointmentTypes().subscribe(types => {
        this.appointmentTypes = [this.createAllLabelType(), ...(types ?? [])];
        this.appointmentFilterParameters.appointmentTypeId = 0;
      }),
      this.crmService.loadSessionTypes().subscribe(types => {
        this.sessionTypes = [this.createAllLabelSession(), ...(types ? Array.from(this.crmService.sessionTypes.value) : [])];
        this.appointmentFilterParameters.callType = this.allLabel;
      }),
      this.crmService.loadAgents().subscribe(types => {
        this.agentLists = types ? Array.from(types) : [];
      })
    );

    this.cdr.detectChanges();
  }

  inSupervisorMode(): boolean {
    return this.location.path().includes('supervisor');
  }

  onChangeAppointmentType(event) {
    this.appointmentTable.filter(event.value, 'appointmentType', 'in');
    this.appointmentFilterParameters.appointmentTypeId = event.value;
  }

  onCallTypeChange(event) {
    this.appointmentTable.filter(event.value, 'callType', 'in');
    this.appointmentFilterParameters.callType = event.value;
  }

  onStatusChange(event) {
    this.appointmentTable.filter(event.value, 'status', 'in');
    this.appointmentFilterParameters.status = event.value;
  }

  onChangeAgent(event) {
    const agentValue = event?.value || [];
    this.appointmentTable.filter(agentValue, 'agent', 'in');
    this.appointmentFilterParameters.agents = agentValue;
  }

  onChangeStartDate(event) {
    this.appointmentFilterParameters.from = event.target.value;
    this.appointmentTable.filter(event.target.value, 'startDate', 'gte');
  }

  onChangeEndDate(event) {
    this.appointmentFilterParameters.to = event.target.value;
    this.appointmentTable.filter(event.target.value, 'endDate', 'lte');
  }

  onLazyLoad(event: LazyLoadEvent) {
    const page = (event.first / event.rows) + 1;
    this.search(page);
  }

  onTabChange(activeTab) {
    this.activeItem = activeTab;
  }

  showCRMInformation(selectedCrmTab: CrmTabs) {
    this.selectSessionHistoryTab = selectedCrmTab === CrmTabs.SESSIONHISTORY;
    this.crmInformationView = true;
  }

  onBackToForm() {
    this.crmInformationView = false;
  }

  onSort(event: any) {
    this.appointmentSort.setSortField(event.field);
  }

  createAppointment(appointment: CreateAppointmentRequest) {
    this.submitting = true;
    this.subscriptions.push(
      this.appointmentService.createAppointment(appointment).subscribe(data => {
        this.submitting = false;
        this.handleAppointmentCreationResponse(data);
      })
    );
  }

  selectRow(row: Appointment) {
    this.selectedAppointment$.next(row);
    if (!this.isUnavailableRow(row)) {
      this.selectedContact.vee24Guid = row.userGuid;
    }
  }

  search(pageNumber = 0) {
    this.loading = true;
    this.selectedAppointment$.next(null);
    this.appointmentService.filterAppointmentData(
      this.appointmentFilterParameters.appointmentTypeId,
      this.isAllLabelSelected(this.appointmentFilterParameters.callType) ? '' : this.appointmentFilterParameters.callType,
      this.appointmentFilterParameters.status,
      this.appointmentFilterParameters.agents ?? [],
      this.appointmentFilterParameters.from,
      this.appointmentFilterParameters.to,
      30,
      pageNumber
    )
      .pipe(first(), catchError(() => of(null)))
      .subscribe(pagedData => this.handleSearchResults(pagedData));
  }

  getSeverityForRow(row: Appointment): string {
    switch (row.status) {
      case StatusEnum.OPEN:
        return 'success';
      case StatusEnum.UNAVAILABLE:
        return 'danger';
      default:
        return 'warning';
    }
  }

  getColorForRow(row: Appointment): string {
    return this.getColorFromStatus(row.status);
  }

  isOpen(row: Appointment): boolean {
    return row.status === StatusEnum.OPEN;
  }

  getCallTypeIconForRow(row: Appointment): string {
    return this.isUnavailableRow(row) ? 'pi pi-fw pi-calendar-times' : this.getCallTypeIcon(row.callType);
  }

  getCallTypeStyleForRow(row: Appointment): string {
    return this.isUnavailableRow(row) ? 'surface-red' : 'surface-blue';
  }

  getCallTypeLabelForRow(row: Appointment): string {
    return this.isUnavailableRow(row) ? this.getTranslatedStatus(row.status) : this.getSessionTypeTranslation(row.callType);
  }

  getSessionTypeTranslation(callType: string): string {
    const resourceKey = `SESSIONTYPE_${callType.toUpperCase()}_PRODUCT`;
    return this.translate.transform(resourceKey, callType);
  }

  getTranslatedStatus(status: StatusEnum): string {
    return this.statusMapping[status].status?.toUpperCase();
  }

  isUnavailableRow(row: Appointment): boolean {
    return row?.status === StatusEnum.UNAVAILABLE;
  }

  private getColumns() {
    return [
      {
        name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_CALLTYPE', 'Call Type'),
        prop: 'callType',
        class: 'text-truncate'
      },
      {name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_DATE', 'Date'), prop: 'appointmentDate'},
      {name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_STARTTIME', 'Start Time') + ` (${this.timezoneAbrv})`, prop: 'startTime'},
      {name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_ENDTIME', 'End Time') + ` (${this.timezoneAbrv})`, prop: 'endTime'},
      {
        name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_DESCRIPTION', 'Description'),
        prop: 'description'
      },
      {
        name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_APPOINTMENTTYPE', 'Appointment Type'),
        prop: 'appointmentType'
      },
      {
        name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_STATUS', 'Status'),
        prop: 'status',
        class: 'text-truncate'
      },
      {
        name: this.translate.transform('APPOINTMENTSEARCH_RESULT_COLUMN_ASSIGNEDAGENT', 'Assigned Agent'),
        prop: 'agent'
      },
    ];
  }

  private createAllLabelType(): AppointmentType {
    const apt = new AppointmentType();
    apt.name = this.allLabel;
    apt.id = 0;
    return apt;
  }

  private createAllLabelSession(): SessionTypes {
    const session = new SessionTypes();
    session.Label = this.allLabel;
    return session;
  }

  private setDateFilterParameters() {
    const fromWeeks = this.settingsService.getResourceOrDefault('APPOINTMENTSEARCH_FILTER_FROM_NUMBER_OF_WEEKS', '0');
    const toWeeks = this.settingsService.getResourceOrDefault('APPOINTMENTSEARCH_FILTER_TO_NUMBER_OF_WEEKS', '2');
    this.appointmentFilterParameters.from = moment().add(Number(-fromWeeks), 'week').format('YYYY-MM-DD');
    this.appointmentFilterParameters.to = moment().add(Number(toWeeks), 'week').format('YYYY-MM-DD');
  }

  private isAllLabelSelected(value: string): boolean {
    return value === this.allLabel;
  }

  private getColorFromStatus(status: StatusEnum): string {
    switch (status) {
      case StatusEnum.OPEN:
        return 'surface-green';
      case StatusEnum.UNAVAILABLE:
        return 'surface-red';
      default:
        return 'surface-orange';
    }
  }

  private getCallTypeIcon(callType: string): string {
    switch (callType?.toLowerCase()) {
      case 'chat':
        return 'pi pi-fw pi-comments';
      case 'veecall':
        return 'pi pi-fw pi-video';
      case 'telephone':
        return 'pi pi-fw pi-phone';
      default:
        return '';
    }
  }

  private handleAppointmentCreationResponse(data: any) {
    const successMessage = this.translate.transform('APPOINTMENTSEARCH_APPOINTMENT_CREATED', 'Appointment Created.');
    const errorMessage = this.translate.transform('APPOINTMENTSEARCH_APPOINTMENT_NOT_CREATED', 'Appointment Not Created.');
    const alertMessage = data ? successMessage : errorMessage;
    const alertType = data ? AlertType.Success : AlertType.Danger;

    this.alertService.addAlert(alertMessage, alertType);
    if (data) {
      this.search();
      this.showAppointmentSidebar = false;
    }
  }

  private handleSearchResults(pagedData: AppointmentSearchResponseResult) {

    if (!pagedData) {
      this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_ERROR_RETRIEVING', 'An error occurred while retrieving appointments.'), AlertType.Danger);
      this.rows.next([]);
      this.count.next(0);
    } else {
      const sortedData = this.appointmentSort.sortData(pagedData?.appointments);
      this.rows.next(sortedData);
      this.count.next(pagedData?.totalAppointments ?? 0);
    }

    this.loading = false;
  }

  public getAppointmentDateForRow(row: Appointment) {

    const startDate = Timezones.convertTimeMoment(row.startDate, this.timezone as MicrosoftTimeZone);
    const endDate = Timezones.convertTimeMoment(row.endDate, this.timezone as MicrosoftTimeZone);

    let formattedRange = '';

    if (startDate.isSame(endDate, 'day')) {
      formattedRange = startDate.format('dddd, MMMM D, YYYY');
    } else {
      formattedRange = `${startDate.format('dddd, MMMM D, YYYY')} until ${endDate.format('dddd, MMMM D, YYYY')}`;
    }
    return formattedRange;
  }

  public newAvailabilityBlock() {
    const unavailableBlockAppointment = new Appointment();
    unavailableBlockAppointment.status = StatusEnum.UNAVAILABLE;
    unavailableBlockAppointment.description = '';
    this.selectedAppointment$.next(unavailableBlockAppointment);
  }

  public hideAvailabilitySidebar() {
    this.selectedAppointment$.next(null);
  }

}
