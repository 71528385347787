export class StringUtils {
  constructor() {
    throw Error('Not for instantiation');
  }

  /**
   * Convert ASCII string's first letter to capital.
   * @param str {string} string to captialise first letter
   */
  public static capitalise(str: string): string {
    // TODO: 2020-12-01 Fix me, does not support unicode.
    if (str && str.length > 0) {
      const fc = str.charAt(0).toLocaleUpperCase();
      return fc + str.slice(1);
    } else {
      return str;
    }
  }

  public static capitalizeUnicode([x = '', ...xs]: string): string {
    return [ x.toUpperCase(), ...xs ].join('');
  }

  /*
   * Compare two strings as urls. They are equal is they are the same
   * or if one only had a '/' appended.
   */
  public static urlEqual(url1: string, url2: string): boolean {
    if (url1 == url2) {
      return true;
    } else if (url1 == (url2 + "/")) {
      return true;
    } else if (url2 == (url1 + "/")) {
      return true;
    } else {
      return false;
    }
  }
  public static getInitials(name: string): string {
    if (!name) {
      return '';
    }
    const names = name.split(' ');
    let initials = names[0].substring(0, 1);
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1);
    }
    return initials;
  }
}
