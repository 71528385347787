import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {faComment, faComments, faHand, faLock, faPhone, faUserSecret} from '@fortawesome/free-solid-svg-icons';
import { SupervisorEngagementState } from '../supervisor-chat-list/supervisor-chat-list.component';

@Component({
  selector: 'app-supervisor-chat-row',
  templateUrl: './supervisor-chat-row.component.html',
  styleUrls: ['./supervisor-chat-row.component.scss']
})
export class SupervisorChatRowComponent implements OnInit {
  faPhone = faPhone;

  SupervisorEngagementState = SupervisorEngagementState;

  @Input() currentOperator: string;
  @Input() engagementState: SupervisorEngagementState;
  @Input() assistTime: number;
  @Input() unreadMessages: number;
  @Input() unreadPrivateMessages: number;
  @Input() lastContactTime: Date;

  timeSinceLastMessage = '0';

  @Output() joinPrivateChat: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  public calcTimeSince(time: Date) {
    return Math.max(0, (new Date().getTime() - time.getTime()) / 1000).toFixed(0);
  }

  public helpAgent($event:Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.joinPrivateChat.emit();
  }

  protected readonly faUserSecret = faUserSecret;
  protected readonly faComment = faComment;
  protected readonly faLock = faLock;
  protected readonly faComments = faComments;
  protected readonly faHand = faHand;
}
