import { Directive, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { VersionService } from '../services/version-service/version.service';

@Directive({
  selector: '[appHeadPositionOverlay]'
})
export class HeadPositionOverlayDirective implements OnInit, OnDestroy {

  private _showOverlay: boolean;
  private observer: any;


  @Input() set showOverlay(v: boolean) {
    if (this.versionService.isElectron) {
      this._showOverlay = v;
      this.toggleVisibility();
    }
  }

  private toggleVisibility() {
    if (this.headPositionCanvas) {
      this.headPositionCanvas.style.visibility = this._showOverlay ? "visible" : "hidden";
    }
  }

  get showOverlay(): boolean {
    return this._showOverlay;
  }

  get headPositionCanvas(): HTMLCanvasElement {
    return document.getElementById("head-position") as HTMLCanvasElement;
  }

  constructor(private el: ElementRef, private versionService:VersionService) {}

  ngOnInit(): void {
    if (this.versionService.isElectron) {
      this.createHeadPositionCanvas(this.el.nativeElement);
      this.toggleVisibility();
    }
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private dispose() {
    if (this.observer) {
      this.observer.unobserve(this.el.nativeElement);
    }
    this.removeHeadPositionOverlay();
  }

  private removeHeadPositionOverlay() {
    if (this.headPositionCanvas && this.headPositionCanvas.parentElement) {
      this.headPositionCanvas.parentElement.removeChild(this.headPositionCanvas);
    }
  }

  private createHeadPositionCanvas(target) {
    if (!this.headPositionCanvas) {
      let canvas = document.createElement("canvas");
      canvas.id = "head-position";
      canvas.style.position = `absolute`;
      canvas.style.top = `0`;
      canvas.style.bottom = `0`;
      canvas.style.right = `0`;
      canvas.style.left = `0`;
      canvas.style.inset = `auto`;
      canvas.style.pointerEvents = `none`;
      target.appendChild(canvas);

      // @ts-ignore
      this.observer = new ResizeObserver(() => {
          this.drawHeadPositionOverlay(this.el.nativeElement);
      });

      this.observer.observe(this.el.nativeElement);
    }
  }

  private drawHeadPositionOverlay(target) {

    if (!this.headPositionCanvas) {
      console.warn("canvas not found");
      return;
    }

    const bounds = target.getBoundingClientRect();
    const width = bounds.width;
    const height = bounds.height;
    const x = bounds.x;
    const y = bounds.y - height;

    this.headPositionCanvas.style.width = "100%";
    this.headPositionCanvas.style.height = "100%";
    this.headPositionCanvas.width = this.headPositionCanvas.offsetWidth;
    this.headPositionCanvas.height = this.headPositionCanvas.offsetHeight;

    const ctx = this.headPositionCanvas.getContext("2d");

    ctx.setLineDash([5, 3]);
    ctx.beginPath();

    ctx.fillStyle = "#FFFFFF";
    ctx.strokeStyle = "#FFFFFF";

    const firstHorizontalY = height * 0.08;
    const secondHorizontalY = height * 0.75;

    this.drawLine(ctx, x, firstHorizontalY, width, firstHorizontalY);
    this.drawLine(ctx, x, secondHorizontalY, width, secondHorizontalY);
    this.drawTriangle(0, firstHorizontalY - 8, firstHorizontalY, firstHorizontalY, 0, firstHorizontalY + 8);
    this.drawTriangle(0, secondHorizontalY - 8, 16, secondHorizontalY, 0, secondHorizontalY + 8);

    const firstVerticalX = width * 0.22;
    const secondVerticalX = width * 0.59;

    this.drawLine(ctx, firstVerticalX, y, firstVerticalX, height);
    this.drawLine(ctx, secondVerticalX, y, secondVerticalX, height);
    this.drawTriangle(firstVerticalX - 8, 0, firstVerticalX, 16, firstVerticalX + 8, 0);
    this.drawTriangle(secondVerticalX - 8, 0, secondVerticalX, 16, secondVerticalX + 8, 0);
  }

  private drawLine(ctx, x1, y1, x2, y2) {
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  private drawTriangle(x1, y1, x2, y2, x3, y3) {
    const canvas: HTMLCanvasElement = document.getElementById('head-position') as HTMLCanvasElement;
    if (canvas.getContext) {
      var ctx = canvas.getContext('2d');

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x3, y3);
      ctx.fill();
    }

  }

}
