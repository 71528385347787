import * as moment from 'moment';

export enum OpportunityState {
  New,
  Existing
}

export class Opportunity{
    opportunityId:number;
	opportunityTitle:string;
	opportunityProduct:string;
	opportunityStatus:number;
	opportunityValue:string;
	opportunityNotes:string;
	opportunityStatusText:string;
	customerName:string;
    vee24Guid:string;
	leadId:string;
	createdDate:string;
	createdBy:string;
	modifiedDate:string;
  modifiedBy:string;
  state: OpportunityState;
	constructor(){
    this.state = OpportunityState.New;
		this.opportunityId = 0;
		this.opportunityTitle = "Create New";
		this.opportunityProduct= "";
		this.opportunityStatus = 0;
		this.opportunityValue = "";
		this.opportunityNotes = "";
		this.createdDate = moment().format("DD/MM/YYYY");
		this.modifiedDate = moment().format("DD/MM/YYYY");
	}
}

export class OpportunityStatus{
	statusId:number;
	statusTitle:string;
}
