import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, InjectionToken, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modules
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {MessagesModule} from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';

// localization module import
import { TranslateModule, TranslateLoader, TranslateService } from  '@ngx-translate/core';
import { TranslateHttpLoader } from  '@ngx-translate/http-loader';

// Services
import { AlertService } from './services/alert-service/alert.service';
import { NotificationService } from './services/notification.service';
import { AuthService } from './services/auth-service/auth.service';
import { AuthBackendService } from './services/auth-service/auth.backend.service';
import { LoadingService } from './services/loading.service';
import {LoggingService, rollbarFactory, RollbarService} from './services/logging.service';
import {CrmService} from './services/crm-service/crm.service';
import {CrmBackendService} from './services/crm-service/crm.backend.service';
import {SettingsBackendService} from './services/settings-service/settings.backend.service';
import {EngagementService} from './services/engagement.service';
import {FeatureService} from './services/feature-service/feature.service';
import {WebrtRTCMessagingService} from './services/webrtc-messaging-service/webrtcmessaging.service';
import {ModalService} from './services/modal.service';

// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordChangeComponent } from './pages/password-change/password-change.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { VeestudioComponent } from './pages/veestudio/veestudio.component';
import { CallListComponent } from './components/call-list/call-list.component';
import { CallListRowComponent } from './components/call-list/call-list-row/call-list-row.component';
import { PasswordChangeFromLinkComponent } from './pages/password-change-from-link/password-change-from-link.component';
import { ControlBarComponent } from './pages/veestudio/control-bar/control-bar.component';
import {EngagementComponent} from './pages/veestudio/engagement/engagement.component';
import { VideoPreviewComponent } from './pages/veestudio/control-bar/video-preview/video-preview.component';
import { AudioPreviewComponent } from './pages/veestudio/control-bar/audio-preview/audio-preview.component';
import { BarMeterComponent } from './pages/veestudio/control-bar/audio-preview/bar-meter/bar-meter.component';
import { EngagementVideoComponent } from './pages/veestudio/engagement/engagement-video/engagement-video.component';
import { EngagementControlsComponent } from './pages/veestudio/engagement/engagement-controls/engagement-controls.component';
import { EngagementPostComponent } from './components/engagement-post/engagement-post.component';

import { EngagementControlsDirectionButtonsComponent } from './components/engagement-controls-direction-buttons/engagement-controls-direction-buttons.component';
import { MissingVisitorModalContentComponent } from './pages/veestudio/engagement/engagement-missing-visitor-modal-content/engagement-missing-visitor-modal-content.component';
import { EngagementTextChatComponent } from './components/engagement-text-chat/engagement-text-chat.component';
import { TextChatComponent } from './components/engagement-text-chat/text-chat/text-chat.component';
import { CustomerTypingPreviewComponent } from './components/engagement-text-chat/customer-typing-preview/customer-typing-preview.component';
import { EngagementToolbarComponent } from './pages/veestudio/engagement/engagement-video/engagement-toolbar/engagement-toolbar.component';
import {AgentFilterPipe, OrderByPipe, EngagementTransferComponent} from './components/engagement-transfer/engagement-transfer.component';
import { InvitationInModalComponent } from './components/invitation-modal/invitation-in-modal.component';
import { EngagementBottomControlsComponent } from './pages/veestudio/engagement/engagement-controls/engagement-bottom-controls/engagement-bottom-controls.component';
import { VeechatComponent } from './pages/veechat/veechat.component';
import { VeechatCallListComponent } from './pages/veechat/veechat-call-list/veechat-call-list.component';
import { VeechatCallListRowComponent } from './pages/veechat/veechat-call-list/veechat-call-list-row/veechat-call-list-row.component';
import { VeechatEngagementComponent } from './pages/veechat/veechat-engagement/veechat-engagement.component';
import { VeechatEngagementControlsComponent } from './pages/veechat/veechat-engagement/veechat-engagement-controls/veechat-engagement-controls.component';
import { VisitorDetailsComponent } from './components/visitor-details/visitor-details.component';
import { VeechatVisitorComponent } from './pages/veechat/veechat-visitor/veechat-visitor.component';
import { VeechatCallListEngagementRowComponent } from './pages/veechat/veechat-call-list/veechat-call-list-engagement-row/veechat-call-list-engagement-row.component';
import { OnbreakComponent } from './pages/onbreak/onbreak.component';
import { EngagementCustomerAvControlsComponent } from './pages/veestudio/engagement/engagement-video/engagement-customer-av-controls/engagement-customer-av-controls.component';
import { FileTransferComponent } from './components/file-transfer/file-transfer.component';
import { EndChatModalComponent } from './components/end-chat-modal/end-chat-modal.component';
import { ClickDebounceDirective } from './directives/click-debounce.directive';
import { AboutModalComponent } from './components/about-modal/about-modal.component';
import { EngagementJoinComponent } from './components/engagement-join/engagement-join.component';
import { EmailComponent } from './components/email/email.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { EngagementControlBarComponent } from './pages/veestudio/engagement/engagement-control-bar/engagement-control-bar.component';
import { AVSelectionComponent } from './components/avselection/avselection.component';
import { VisitorFilterPipe } from './filters/VisitorsFilter';
import {CrmDataComponent} from './components/crm-data/crm-data.component';
import { CrmFieldTextComponent } from './components/crm-data/crm-field-text/crm-field-text.component';
import { CrmFieldNoteComponent } from './components/crm-data/crm-field-note/crm-field-note.component';
import { CrmFieldNumberComponent } from './components/crm-data/crm-field-number/crm-field-number.component';
import { CrmFieldDatetimeComponent } from './components/crm-data/crm-field-datetime/crm-field-datetime.component';
import { CrmFieldExternallinkComponent } from './components/crm-data/crm-field-externallink/crm-field-externallink.component';
import { CrmFieldLocalcommandComponent } from './components/crm-data/crm-field-localcommand/crm-field-localcommand.component';
import { CrmFieldChoiceComponent } from './components/crm-data/crm-field-choice/crm-field-choice.component';
import { SessionHistoryComponent } from './components/session-history/session-history.component';
import { BrowsingHistoryComponent } from './components/browsing-history/browsing-history.component';
import { ChatTranscriptComponent } from './components/engagement-text-chat/chat-transcript/chat-transcript.component';
import { InvitesFilterPipe } from './filters/InvitesFilter';
import { EngagementFilterPipe } from './filters/EngagementFilter';
import { CrmOpportunityComponent } from './pages/crm-opportunity/crm-opportunity.component';
import { CrmSearchComponent } from './components/crm/crm-search/crm-search.component';
import { CrmFieldEmailComponent } from './components/crm-data/crm-field-email/crm-field-email.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { AppointmentSearchComponent } from './pages/appointment-search/appointment-search.component';
import { EngagementBrowserBarComponent } from './components/engagement-browser-bar/engagement-browser-bar.component';
import { DashPanelComponent } from './pages/supervisor/supervisor-dashboard-view/dash-panel/dash-panel.component';
import { EngagementDomSyncCoViewerComponent } from './components/engagement-domsync-coviewer/engagement-domsync-coviewer.component';
import { OpportunityComponent } from './components/opportunity/opportunity.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { VeestudioEngagementOpportunityComponent } from './pages/veestudio/engagement/veestudio-engagement-opportunity/veestudio-engagement-opportunity.component';
import { EngagementCustomerSearchComponent } from './components/engagement-customer-search/engagement-customer-search.component';
import { EngagementCoBrowseWindowComponent } from './components/engagement-cobrowse-window/engagement-cobrowse-window/engagement-cobrowse-window.component';
import { SupervisorComponent } from './pages/supervisor/supervisor.component';
import { SupervisorChatRowComponent } from './pages/supervisor/supervisor-chat-row/supervisor-chat-row.component';
import { SupervisorChatListComponent } from './pages/supervisor/supervisor-chat-list/supervisor-chat-list.component';
import { SupervisorEngagementComponent } from './pages/supervisor/supervisor-engagement/supervisor-engagement.component';
import { SupervisorSnoopingComponent } from './pages/supervisor/supervisor-snooping/supervisor-snooping.component';
import { SupervisorControlsComponent } from './pages/supervisor/supervisor-controls/supervisor-controls.component';
import { SupervisorEngagementControlsComponent } from './pages/supervisor/supervisor-engagement/supervisor-engagement-controls/supervisor-engagement-controls.component';
import { EngagementAssetsComponent } from './components/engagement-assets/engagement-assets.component';
import { SupervisorTableViewComponent } from './pages/supervisor/supervisor-table-view/supervisor-table-view.component';
import { SupervisorDashboardViewComponent, AgentDashboardFilterPipe } from './pages/supervisor/supervisor-dashboard-view/supervisor-dashboard-view.component';
import { Window_Injection } from './classes/window-injection';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MobilePresenterComponent } from './pages/mobile-presenter/mobile-presenter.component';
import { MobilePresenterCallComponent } from './pages/mobile-presenter/mobile-presenter-call/mobile-presenter-call.component';
import { MobilePresenterWaitingComponent } from './pages/mobile-presenter/mobile-presenter-waiting/mobile-presenter-waiting.component';
import { MobilePresenterNavbarComponent } from './pages/mobile-presenter/mobile-presenter-navbar/mobile-presenter-navbar.component';
import { DisplayMediaModalComponent } from './components/display-media-modal/display-media-modal.component';
import { VisitorService } from './services/visitor-service/visitor.service';
import { EngagementJoinCustomersComponent } from './components/engagement-join-customers/engagement-join-customers.component';
import { TranslatePipe } from './filters/Translate.pipe';

import { EngagementCenterConsoleComponent } from './components/engagement-center-console/engagement-center-console.component';
import { EngagementBrowserComponent } from './components/engagement-browser/engagement-browser.component';
import { LocalDateTimePipe } from './utils/local-date-time.pipe';
import { LocalTimePipe } from './utils/local-time.pipe';
import { VisitorInformationComponent } from './components/visitor-information/visitor-information.component';
import { CallListTableComponent } from './components/call-list/call-list-table/call-list-table.component';
import { CallListBottomControlsComponent } from './components/call-list/call-list-bottom-controls/call-list-bottom-controls.component';
import { RingerService } from './services/ringer-service/ringer.service';
import { LinkPreviewComponent } from './components/engagement-text-chat/link-preview/link-preview.component';
import { CrmComponent } from './components/crm/crm.component';
import { CrmFieldPhoneNumberComponent } from './components/crm-data/crm-field-phone-number/crm-field-phone-number.component';
import { HomeComponent } from './pages/home/home.component';
import { AgentCalendarComponent } from './pages/agent-calendar/agent-calendar.component';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { SupervisorEngagementGroupPipe } from './pages/supervisor/supervisor-engagement-group-pipe/supervisor-engagement-group.pipe';
import { SupervisorVisitorFilterPipe } from './pages/supervisor/supervisor-visitor-filter-pipe/supervisor-visitor-filter.pipe';
import { CameraControlsComponent } from './components/camera-controls/camera-controls.component';
import { CameraControlComponent } from './components/camera-controls/camera-control/camera-control.component';
import { PanTiltControlComponent } from './components/camera-controls/pan-tilt-control/pan-tilt-control.component';
import { HeadPositionOverlayDirective } from './directives/head-position-overlay.directive';
import { IntlTelInputDirective} from "./directives/intl-tel-input.directive";
import { EngagementAppViewComponent } from './components/engagement-app-view/engagement-app-view.component';
import { CrmFieldTypeaheadComponent } from './components/crm-data/crm-field-typeahead/crm-field-typeahead.component';
import { EngagementElectronDomsyncCoviewerComponent } from './components/engagement-domsync-coviewer/electron-domsync-coviewer/electron-domsync-coviewer.component';
import { IframeDomsyncCoviewerComponent } from './components/engagement-domsync-coviewer/iframe-domsync-coviewer/iframe-domsync-coviewer.component';
import { PanelSizeSliderComponent } from './pages/veestudio/engagement/engagement-controls/panel-size-slider/panel-size-slider.component';
import { AppointmentRowDetailComponent } from './pages/appointment-search/appointment-row-detail/appointment-row-detail.component';
import { AppointmentTransferModalComponent } from './components/appointment-transfer-modal/appointment-transfer-modal.component';
import { MessageTemplateViewerComponent } from './components/email/message-template-viewer/message-template-viewer.component';
import { DragResizeDirective } from './directives/drag-resize/drag-resize.directive';
import { SharingNotificationComponent } from './pages/veestudio/engagement/sharing-notification/sharing-notification.component';
import { EngagementVideoBrowserComponent } from './pages/veestudio/engagement/engagement-video/engagement-video-browser/engagement-video-browser.component';
import { EngagementVideoAnchorDirective } from './directives/engagement-video-anchor.directive';
import { EngagementVideoElectronComponent } from './pages/veestudio/engagement/engagement-video/engagement-video-electron/engagement-video-electron.component';
import { VideoWindowComponent } from './pages/veestudio/engagement/engagement-video/engagement-video-electron/video-window/video-window.component';
import { EngagementModeSelectionComponent } from './components/engagement-mode-selection/engagement-mode-selection.component';
import { VeeschedulerComponent } from './pages/veescheduler/veescheduler.component';
import { BlockCustomerModalComponent } from './components/block-user-modal/block-user-modal.component';
import { EngagementChatTabsetComponent } from './components/enagagement-chat-tabset/engagement-chat-tabset.component';
import { EngagementPrivateChatTabComponent } from './components/engagement-private-chat-tab/engagement-private-chat-tab.component';
import { VeechatConversationComponent } from './pages/veechat/veechat-conversation/veechat-conversation.component';
import { VeechatConversationControlsComponent } from './pages/veechat/veechat-conversation/veechat-conversation-controls/veechat-conversation-controls.component';
import { VeechatCallListConversationRowComponent } from './pages/veechat/veechat-call-list/veechat-call-list-conversation-row/veechat-call-list-conversation-row.component';
import { CallListRenameChatComponent } from './components/call-list-rename-chat/call-list-rename-chat.component';
import { TextMessageDatePipe } from './filters/text-message-date.pipe';
import { DeliveryStatusComponent } from './components/engagement-text-chat/delivery-status/delivery-status.component';
import { SupervisorAsyncChatComponent } from './pages/supervisor/supervisor-async-chat/supervisor-async-chat.component';
import { SupervisorAsyncConversationGroupPipe } from './pages/supervisor/supervisor-asyncconversation-group-pipe/supervisor-async-conversation-group.pipe';
import { DashboardCardVeeStudioOperatorsComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veestudio/dashboard-card-vee-studio-operators/dashboard-card-vee-studio-operators.component';
import { DashboardCardVeeStudioOperatorsAvailableComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veestudio/dashboard-card-vee-studio-operators-available/dashboard-card-vee-studio-operators-available.component';
import { DashboardCardVeeStudioOperatorsNotAvailableComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veestudio/dashboard-card-vee-studio-operators-not-available/dashboard-card-vee-studio-operators-not-available.component';
import { DashboardCardVeeStudioOperatorsBusyComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veestudio/dashboard-card-vee-studio-operators-busy/dashboard-card-vee-studio-operators-busy.component';
import { DashboardCardVeeChatOperatorsComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veechat/dashboard-card-vee-chat-operators/dashboard-card-vee-chat-operators.component';
import { DashboardCardVeeChatSessionsPossibleComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veechat/dashboard-card-vee-chat-sessions-possible/dashboard-card-vee-chat-sessions-possible.component';
import { DashboardCardVeeChatAdditionalCapacityComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veechat/dashboard-card-vee-chat-additional-capacity/dashboard-card-vee-chat-additional-capacity.component';
import { DashboardCardVeeChatEngagedComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/veechat/dashboard-card-vee-chat-engaged/dashboard-card-vee-chat-engaged.component';
import { DashboardCardOperatorsOnBreakComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/dashboard-card-operators-on-break/dashboard-card-operators-on-break.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DashboardCardVisitorsComponent } from './pages/supervisor/supervisor-dashboard-view/dashboard-cards/dashboard-card-visitors/dashboard-card-visitors.component';
import { ConversationTransferComponent } from './components/conversation-transfer/conversation-transfer.component';
import { EngagementTransferRejectedComponent } from './components/engagement-transfer-rejected/engagement-transfer-rejected.component';
import { EngagementTransferWaitingComponent } from './components/engagement-transfer-waiting/engagement-transfer-waiting.component';
import { EngagementTransferSelectComponent } from './components/engagement-transfer-select/engagement-transfer-select.component';
import { EngagementTransferMessageComponent } from './components/engagement-transfer-select/engagement-transfer-message/engagement-transfer-message.component';
import { EngagementTransferAgentSelectComponent } from './components/engagement-transfer-select/engagement-transfer-agent-select/engagement-transfer-agent-select.component';
import { EngagementTransferChatComponent } from './components/engagement-transfer-chat/engagement-transfer-chat.component';
import { EngagementTransferChatBoxComponent } from './components/engagement-transfer-chat/engagement-transfer-chat-box/engagement-transfer-chat-box.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { EngagementStatusComponent } from './components/engagement-status/engagement-status.component';
import { Vee24ProgressSpinnerComponent } from './components/vee24-progress-spinner/vee24-progress-spinner.component';
import { MessageService } from 'primeng/api';
import { CrmFieldErrorComponent } from './components/crm-data/crm-field-error/crm-field-error.component';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {ChipModule} from 'primeng/chip';
import {CheckboxModule} from 'primeng/checkbox';
import {EditorModule} from 'primeng/editor';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TabViewModule} from 'primeng/tabview';
import {MenuModule} from 'primeng/menu';
import {ChipsModule} from 'primeng/chips';
import {StyleClassModule} from 'primeng/styleclass';
import {ListboxModule} from 'primeng/listbox';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TabMenuModule} from 'primeng/tabmenu';
import {ToolbarModule} from 'primeng/toolbar';
import {BadgeModule} from 'primeng/badge';
import {SpeedDialModule} from 'primeng/speeddial';
import {InputSwitchModule} from 'primeng/inputswitch';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AvatarModule} from 'primeng/avatar';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import {IconFieldModule} from 'primeng/iconfield';
import {InputIconModule} from 'primeng/inputicon';
import {PanelModule} from 'primeng/panel';
import {TagModule} from 'primeng/tag';
import {EngagementInviteLinkComponent} from './components/engagement-invite-link/engagement-invite-link.component';
import { EngagementJoinRowComponent } from './components/engagement-join/engagement-join-row/engagement-join-row.component';
import {UserHeartbeatComponent} from './components/user-heartbeat/user-heartbeat.component';
import { EngagementJoinVisitorRowComponent } from './components/engagement-join/engagement-join-visitor-row/engagement-join-visitor-row.component';
import { VerifyCustomerButtonComponent } from './components/verify-customer-button/verify-customer-button.component';
import { VerifyCustomerModalComponent } from './components/verify-customer-modal/verify-customer-modal.component';
import {EngagementJoinRowStatusComponent} from './components/engagement-join/engagement-join-row-status/engagement-join-row-status.component';
import {SidebarModule} from "primeng/sidebar";
import {
  CrmFieldCheckboxComponent
} from "./components/crm-data/crm-field-checkbox/crm-field-checkbox/crm-field-checkbox.component";
import { AvailabilityBlockComponent } from './pages/appointment-search/availability-block/availability-block.component';
import { AppointmentTypeFormComponent } from './components/appointments/appointment-type-form/appointment-type-form.component';
import {MediaPermissionService} from './services/media-permission.service';
import { MediaPermissionComponent } from './components/media-permission/media-permission.component';


// loader module
export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AgentDashboardFilterPipe,
        AgentFilterPipe,
        OrderByPipe,
        VisitorFilterPipe,
        InvitesFilterPipe,
        EngagementFilterPipe,
        AppComponent,
        NavbarComponent,
        LoginComponent,
        PasswordChangeComponent,
        PasswordResetComponent,
        VeestudioComponent,
        CallListComponent,
        PasswordChangeFromLinkComponent,
        ControlBarComponent,
        EngagementComponent,
        CallListRowComponent,
        EngagementComponent,
        VideoPreviewComponent,
        AudioPreviewComponent,
        BarMeterComponent,
        EngagementVideoComponent,
        EngagementControlsComponent,
        EngagementPostComponent,
        EngagementControlsDirectionButtonsComponent,
        EngagementBottomControlsComponent,
        MissingVisitorModalContentComponent,
        EngagementTextChatComponent,
        TextChatComponent,
        EngagementPrivateChatTabComponent,
        EngagementChatTabsetComponent,
        CustomerTypingPreviewComponent,
        EngagementToolbarComponent,
        EngagementTransferComponent,
        InvitationInModalComponent,
        EngagementBottomControlsComponent,
        VeechatComponent,
        VeechatCallListComponent,
        VeechatCallListRowComponent,
        VeechatEngagementComponent,
        VeechatEngagementControlsComponent,
        VeechatCallListEngagementRowComponent,
        VisitorDetailsComponent,
        VeechatVisitorComponent,
        OnbreakComponent,
        EngagementCustomerAvControlsComponent,
        FileTransferComponent,
        EndChatModalComponent,
        ClickDebounceDirective,
        AboutModalComponent,
        EngagementJoinComponent,
        EmailComponent,
        ToolboxComponent,
        EngagementControlBarComponent,
        AVSelectionComponent,
        CrmDataComponent,
        CrmFieldTextComponent,
        CrmFieldNoteComponent,
        CrmFieldNumberComponent,
        CrmFieldDatetimeComponent,
        CrmFieldExternallinkComponent,
        CrmFieldLocalcommandComponent,
        CrmFieldChoiceComponent,
        SessionHistoryComponent,
        BrowsingHistoryComponent,
        ChatTranscriptComponent,
        CrmOpportunityComponent,
        CrmSearchComponent,
        CrmFieldEmailComponent,
        AppointmentsComponent,
        AppointmentSearchComponent,
        EngagementBrowserBarComponent,
        DashPanelComponent,
        EngagementDomSyncCoViewerComponent,
        OpportunityComponent,
        ChecklistComponent,
        VeestudioEngagementOpportunityComponent,
        EngagementCoBrowseWindowComponent,
        SupervisorComponent,
        SupervisorChatRowComponent,
        SupervisorChatListComponent,
        SupervisorEngagementComponent,
        SupervisorSnoopingComponent,
        SupervisorControlsComponent,
        SupervisorEngagementControlsComponent,
        EngagementCustomerSearchComponent,
        EngagementCoBrowseWindowComponent,
        EngagementAssetsComponent,
        SupervisorTableViewComponent,
        SupervisorDashboardViewComponent,
        MobilePresenterComponent,
        MobilePresenterCallComponent,
        MobilePresenterWaitingComponent,
        MobilePresenterNavbarComponent,
        DisplayMediaModalComponent,
        EngagementJoinCustomersComponent,
        TranslatePipe,
        EngagementCenterConsoleComponent,
        EngagementBrowserComponent,
        LocalDateTimePipe,
        LocalTimePipe,
        VisitorInformationComponent,
        CallListTableComponent,
        CallListBottomControlsComponent,
        LinkPreviewComponent,
        CrmComponent,
        CrmFieldPhoneNumberComponent,
        HomeComponent,
        AgentCalendarComponent,
        NoInternetComponent,
        SupervisorEngagementGroupPipe,
        SupervisorVisitorFilterPipe,
        CameraControlsComponent,
        CameraControlComponent,
        PanTiltControlComponent,
        HeadPositionOverlayDirective,
        IntlTelInputDirective,
        EngagementAppViewComponent,
        CrmFieldTypeaheadComponent,
        EngagementElectronDomsyncCoviewerComponent,
        IframeDomsyncCoviewerComponent,
        PanelSizeSliderComponent,
        AppointmentRowDetailComponent,
        AppointmentTransferModalComponent,
        MessageTemplateViewerComponent,
        DragResizeDirective,
        SharingNotificationComponent,
        EngagementVideoBrowserComponent,
        EngagementVideoAnchorDirective,
        EngagementVideoElectronComponent,
        VideoWindowComponent,
        EngagementModeSelectionComponent,
        VeeschedulerComponent,
        BlockCustomerModalComponent,
        VeechatConversationComponent,
        VeechatConversationControlsComponent,
        VeechatCallListConversationRowComponent,
        CallListRenameChatComponent,
        TextMessageDatePipe,
        DeliveryStatusComponent,
        SupervisorAsyncChatComponent,
        SupervisorAsyncConversationGroupPipe,
        DashboardCardVeeStudioOperatorsComponent,
        DashboardCardVeeStudioOperatorsAvailableComponent,
        DashboardCardVeeStudioOperatorsNotAvailableComponent,
        DashboardCardVeeStudioOperatorsBusyComponent,
        DashboardCardVeeChatOperatorsComponent,
        DashboardCardVeeChatSessionsPossibleComponent,
        DashboardCardVeeChatAdditionalCapacityComponent,
        DashboardCardVeeChatEngagedComponent,
        DashboardCardOperatorsOnBreakComponent,
        DashboardCardVisitorsComponent,
        ConversationTransferComponent,
        EngagementTransferRejectedComponent,
        EngagementTransferWaitingComponent,
        EngagementTransferSelectComponent,
        EngagementTransferMessageComponent,
        EngagementTransferAgentSelectComponent,
        EngagementTransferChatComponent,
        EngagementTransferChatBoxComponent,
        InfoBarComponent,
        EngagementStatusComponent,
        CrmFieldErrorComponent,
        Vee24ProgressSpinnerComponent,
        CrmFieldCheckboxComponent,
        EngagementInviteLinkComponent,
        EngagementJoinRowComponent,
        EngagementJoinRowStatusComponent,
        UserHeartbeatComponent,
        EngagementJoinVisitorRowComponent,
        VerifyCustomerButtonComponent,
        VerifyCustomerModalComponent,
        AvailabilityBlockComponent,
        AppointmentTypeFormComponent,
        MediaPermissionComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    MultiSelectModule,
    FontAwesomeModule,
    DynamicDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AccordionModule,
    TableModule,
    ButtonModule,
    RippleModule,
    ChipModule,
    CheckboxModule,
    EditorModule,
    InputTextModule,
    InputTextareaModule,
    AutoCompleteModule,
    TabViewModule,
    MenuModule,
    ChipsModule,
    StyleClassModule,
    ListboxModule,
    SplitButtonModule,
    TabMenuModule,
    ToolbarModule,
    BadgeModule,
    SpeedDialModule,
    InputSwitchModule,
    OverlayPanelModule,
    AvatarModule,
    DividerModule,
    FieldsetModule,
    IconFieldModule,
    InputIconModule,
    PanelModule,
    TagModule,
    SidebarModule,

  ],
    exports: [TranslateModule],
    providers: [
        AlertService,
        MessageService,
        NotificationService,
        Title,
        AuthBackendService,
        AuthService,
        LoadingService,
        LoggingService,
        VisitorService,
        EngagementService,
        SettingsBackendService,
        FeatureService,
        WebrtRTCMessagingService,
        CrmService,
        CrmBackendService,
        RingerService,
        { provide: ErrorHandler, useClass: LoggingService },
        { provide: EngagementService, useClass: EngagementService },
        { provide: RollbarService, useFactory: rollbarFactory },
        { provide: Window_Injection, useValue: window },
        TranslateService,
        TranslatePipe,
        ModalService,
        MediaPermissionService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
