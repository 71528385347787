import { Renderer2 } from '@angular/core';

export class Overlay {
  protected _helper: Element;
  private _added = false;

  constructor(
    protected renderer: Renderer2
  ) {
    // generate helper div
    let helper = renderer.createElement('div');
    renderer.setStyle(helper, 'position', 'absolute');
    renderer.setStyle(helper, 'width', '100%');
    renderer.setStyle(helper, 'height', '100%');
    renderer.setStyle(helper, 'background-color', 'transparent');
    renderer.setStyle(helper, 'top', '0');
    renderer.setStyle(helper, 'left', '0');
    renderer.setStyle(helper, 'z-index', '9999');
    renderer.addClass(helper, 'dragResizeOverlay');

    // done
    this._helper = helper;
  }

  add() {
    if (!this._added) {
      document.body.appendChild(this._helper);
      this._added = true;
    }
  }

  remove() {
    if (this._added) {
      document.body.removeChild(this._helper);
      this._added = false;
    }
  }

  dispose() {
    this._helper = null;
    this._added = false;
  }
  
}
