<table class="table table-borderless ">
  <thead>
  <tr class="d-flex text-center">
    <th class="col-3">{{ "SUPERVISOR_HEADER_AGENT"  | Translate : 'Agent' }}</th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_STATUS" | Translate : 'Status' }}</th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_CURRENTSESSION"  | Translate : 'Current Sessions' }}</th>
    <th class="col-3">{{ "SUPERVISOR_HEADER_SESSIONAVAILABLE"  | Translate : 'Sessions Available' }}</th>
  </tr>
  </thead>
  <tbody *ngIf="show">
  <ng-container *ngFor="let group of selectedGroups | keyvalue">
    <tr *ngIf="(selectedAgents | agentDashboardFilter: 'Multichat Available' : group.key).size > 0" class="op-group-header">
      <td>
        <h3 class="op-group-name">{{group.value}}</h3>
      </td>
    </tr>

    <tr
      *ngFor="let agent of (selectedAgents | agentDashboardFilter: 'Multichat Available' : group.key | keyvalue)"
      class="d-flex text-center ml-3">
      <td class="col-3"><span class="text-truncate">{{agent.value.firstname}} {{agent.value.lastname}}</span>
      </td>
      <td class="col-3">{{agent.value.status | titlecase}}</td>
      <td class="col-3">{{agent.value.currentSessionNumber}}</td>
      <td class="col-3">{{agent.value.multichatLimit - agent.value.currentSessionNumber}}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
