import {Component, ViewChild, Input} from '@angular/core';
import { PostEngagementMessages, EmailService } from '../../services/email-service/email.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EmailTemplate, EmailTemplateSection } from '../../classes/email/emailtemplate';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FulfillmentService } from '../../services/fulfillment-service/fulfillment.service';
import { FulfillmentMarketingMaterial } from '../../classes/fulfillment/FulfillmentMarketingMaterial';
import { LoadingService } from '../../services/loading.service';
import { MessageTemplateViewerComponent } from './message-template-viewer/message-template-viewer.component';
import { Engagement } from '../../services/engagement';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EmailState, PostEngagementEmail } from '../../services/email-service/PostEngagementEmail';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent {
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  EmailState = EmailState;

  public emailTemplates: Observable<EmailTemplate[]> = this.emailService.emailTemplates;
  public get emails(): PostEngagementMessages {
    if (this.engagement?.engagementId) {
      const id = this.engagement.engagementId;
      return this.emailService.getPostEngagementMessages(id.toString());
    } else {
      return null;
    }
  }

  public fulfillmentMrkMat: BehaviorSubject<FulfillmentMarketingMaterial[]> = this.fulfillmentService.fulfillmentMarketingMaterial;
  public get attachedffMrkMat() {
    const emails = this.emails;
    return emails ? emails.markettingMaterial : null;
  }

  public currentEmail: PostEngagementEmail = null;

  private _engagement: Engagement;
  @Input() public set engagement(engagement: Engagement) {
    this._engagement = engagement;
    this.resetSelectedTemplate();
    this.selectedFFMrkMatId = null;
  }
  public get engagement(): Engagement {
    return this._engagement;
  }

  public selectedFFMrkMatId: string;

  @ViewChild("templateViewer", {static: false}) templateViewer:MessageTemplateViewerComponent;

  constructor(
    private readonly emailService: EmailService,
    private readonly fulfillmentService: FulfillmentService,
    private readonly loadingService: LoadingService
  ) {
  }

  onTemplateContentChanged($event) {
    if (this.currentEmail != null) {
      this.currentEmail.emailMessage = $event;
    }
  }

  selectTemplate(emailTemplate: EmailTemplate, emailSection: EmailTemplateSection) {
    this.currentEmail = null;
    this.loadingService.isLoading.next(true);

    this.emailService.generateEmailText(emailTemplate, emailSection, this.engagement.engagementId.toString(), this.engagement.visitor.userGuid)
                     .pipe(
                        catchError(() => of(null)),
                        tap(email => this.setGeneratedEmail(email)),
                        finalize(() => this.loadingService.isLoading.next(false)))
                      .subscribe();
  }

  private setGeneratedEmail(email: PostEngagementEmail): void {
    this.currentEmail = email;
    if (email) {
      this.templateViewer.setContent(this.currentEmail.emailMessage);
    }
  }

  addEmail() {
    if (this.currentEmail.isValid) {
      this.emails.addEmail(this.currentEmail);

      this.resetSelectedTemplate();
    } else {
      // TODO: probably should alert the user!
    }
  }

  private resetSelectedTemplate() {
    this.currentEmail = null;
    // The null conditional here is because the setter for `engagement` may be called
    // before this view has initialised. In that case we don't need to remove any content
    // in the view, since it doesn't exist yet. Everywhere else this view should be
    // constructed first.
    this.templateViewer?.setContent("");
  }

  removeEmail(email: PostEngagementEmail) {
    this.emails.removeEmail(email);
  }

  get addAttachmentVisible(): boolean {
    return !(this.selectedFFMrkMatId == null || this.attachedffMrkMat.some(item => item.Id == this.selectedFFMrkMatId));
  }

  addAttachment() {
    const attachment = this.fulfillmentMrkMat.value.find(m => m.Id == this.selectedFFMrkMatId);
    this.emails.addFulfillment(attachment);
  }

  removeAttachment(selectedFFMrkMat: FulfillmentMarketingMaterial) {
    this.emails.removeFulfillment(selectedFFMrkMat);
  }
}
