<div class="d-flex flex-row justify-content-start bottom-ctrls gap-1">
  <p-button appDebounceClick
    id="supervisor-button"
    styleClass="btn-bottom-control center-icon"
    [severity]="isSupervisorModeOn ? 'primary' : 'secondary'"
    pTooltip='{{ "CHAT_TOOLTIP_MODE"  | Translate : "Supervisor Mode" }}'
    (clicked)="toggleSupervisorMode()">
      <fa-icon [icon]="faUserTie"/>
  </p-button>
  <p-button
    appDebounceClick
    styleClass="btn-bottom-control center-icon" severity="secondary" id="crm-button"
    pTooltip='{{ "CHAT_TOOLTIP_CRM"  | Translate : "CRM" }}'
    [ngClass]="{'d-none': !crmAvailable}"
    (clicked)="onGoToCrm()">
      <fa-icon  [icon]="faAddressBook"/>
  </p-button>
  <p-button
    appDebounceClick
    styleClass="btn-bottom-control center-icon" id="dashboard-button"
    [severity]="dashboardIsVisible ? 'primary' : 'secondary'"
    [class.d-none]="!isSupervisorModeOn"
    pTooltip='{{ "CHAT_TOOLTIP_DASHBOARD"  | Translate : "Dashboard" }}'
    (clicked)="showDashboard()">
      <fa-icon  [icon]="faTableColumns"/>
  </p-button>
</div>
