import CallStreaming from "./CallStreaming";
import Constants from "./Constants";
import { Janus } from 'janus-gateway';

export default class CallSFU extends CallStreaming {
    constructor({ 
        engagementId = null,
        peerId ='itsthetaste',
        iceServer = null,
        gatewayServer = null,
        gatewayRoomId = 0,
        peerType = Constants.PeerType.Agent,
        mainPeerId = 'itsthetaste',
        speakerVolume = 1.0,
        mainVideoContainer = null,
        connectingOverlay = null,
        reConnectingOverlay = null,
        onHoldOverlay = null,
        peersContainer = null
    } = {}) {

        super({ 
            engagementId,
            peerId,
            iceServer,           
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        this.currentGatewayServer = gatewayServer;
        this.gatewayRoomId = parseInt(gatewayRoomId);
        this.publishingHandle = null;
        this.joinedVideoRoom = false;

        this.remoteFeeds = [];

        this.sendingAudio = false;
        this.sendingVideo = false;
        this.sendingVideoOptions = null;

        this.reconnectingMediaState = null;

        this.mediaState = {
            publisherId: 0,
            video: false,
            audio: false
        };

        this.funQueue = [];

        this.getRemoteFeed = (peerId, publisherId) => {
            var remoteFeed = null;
            for (var i = 0; i < this.remoteFeeds.length; ++i) {
                if (this.remoteFeeds[i].peerId === peerId && this.remoteFeeds[i].publisherId === publisherId) {
                    remoteFeed = this.remoteFeeds[i];
                    break;
                }
            }
            return remoteFeed;
        };

        this.deleteRemoteFeed = (peerId, publisherId) => {
            for (var i = 0; i < this.remoteFeeds.length; ++i) {
                if (this.remoteFeeds[i].peerId === peerId && this.remoteFeeds[i].publisherId === publisherId) {
                    this.remoteFeeds.splice(i,1);
                    break;
                }
            }
        };

        this.publishAV = (
            sendAudio, 
            sendVideo,
            videoOptions            
            ) => {

            console.log(`+++++++++ publishAV: ${sendAudio} ${sendVideo} ${videoOptions}`);

            if (!this.joinedVideoRoom) {
                setTimeout(() => {
                    this.publishAV(sendAudio, sendVideo, videoOptions);
                },500);
            }

            if (videoOptions) {
                this.sendingVideoOptions = videoOptions;
            }

            var media = {};

            media.audio = this.devices.getAudioSettings();
            media.audioRecv = false;
            media.audioSend = sendAudio;
            if (sendAudio) {
                if (!this.sendingAudio && this.sendingVideo) {
                    media.addAudio = true;        
                }
                else {
                    media.replaceAudio = this.sendingAudio ? true : false;
                }
            }
            else {
                media.removeAudio = true;
            }

            media.video = this.devices.getVideoSettings(videoOptions);
            media.videoRecv = false;
            media.videoSend = sendVideo;
            if (sendVideo) {
                if (!this.sendingVideo && this.sendingAudio) {
                    media.addVideo = true;        
                }
                else {
                    media.replaceVideo = this.sendingVideo ? true : false;
                }
            }
            else {
                media.removeVideo = true;
            }    
            
            console.log(`+++++++++ publishAV: ${media}`);

            if (this.publishingHandle) {
                this.publishingHandle.createOffer({
                    media: media,
                    success: (jsep) => {
                        Janus.debug("Got publisher SDP!");
                        Janus.debug(jsep);
                        var publish = { 
                            "request": "configure", 
                            "audio": sendAudio, 
                            "video": sendVideo 
                        };
                        this.publishingHandle.send({ 
                            "message": publish, 
                            "jsep": jsep
                        });
                        // store these away
                        this.sendingAudio = sendAudio;
                        this.sendingVideo = sendVideo;
                    },
                    error: (error) => {
                        Janus.error("WebRTC error:", error);
                        if (sendAudio || sendVideo) {
                            this.publishAV(false, false);
                        }
                    }
                });
            }
        }

        this.attachAsPublisher = (reconnecting) => {
            this.janusServer.attach({
                plugin : "janus.plugin.videoroom",
                success: (pluginHandle) => {
                    this.publishingHandle = pluginHandle;
                    var register = { 
                        "request": "join", 
                        "room": this.gatewayRoomId,
                        "pin": this.engagementId, 
                        "ptype": "publisher" 
                    };
                    this.publishingHandle.send({"message": register});
                },
                error: (error) => {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                consentDialog: (on) => {
                    Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                },
                mediaState: (medium, on) => {
                    Janus.log("Gateway " + (on ? "started" : "stopped") + " receiving our " + medium);
                    this.mediaState[medium] = on;
                    this.emit("CallSFU:mediaStateChanged", this.mediaState);
                },
                webrtcState: (on) => {
                    Janus.log("Gateway says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                },
                onmessage: (msg, jsep) => {
                    Janus.debug(" ::: Got a message (publisher) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (event != undefined && event != null) {
                        if (event === "joined") {
                            this.joinedVideoRoom = true;
                            // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                            var mypvtid = msg["private_id"];
                            this.mediaState.publisherId = msg["id"];
                            Janus.log("Successfully joined room " + msg["room"] + " with ID " + this.mediaState.publisherId + " privateId " + mypvtid);
                            this.emit("CallSFU:mediaStateChanged", this.mediaState);
                            
                            if (reconnecting) {
                                // re publish whatever my av was previously
                                this.publishAV(this.reconnectingMediaState.audio, this.reconnectingMediaState.video, this.sendingVideoOptions);
                            }
                        } 
                        else if (event === "destroyed") {
                            this.joinedVideoRoom = false;
                            // The room has been destroyed
                            Janus.warn("The room has been destroyed!");
                        } 
                        else if (event === "event") {
                            // Any new feed to attach to?
                            if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                                var list = msg["publishers"];
                                Janus.debug("Got a list of available publishers/feeds:");
                                Janus.debug(list);
                            }
                        }
                        else if (msg["leaving"] !== undefined && msg["leaving"] !== null) {
                            // One of the publishers has gone away?
                            var leaving = msg["leaving"];
                            Janus.log("Publisher left: " + leaving);
                        }
                        else if (msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                            // One of the publishers has unpublished?
                            var unpublished = msg["unpublished"];
                            Janus.log("Publisher left: " + unpublished);
                        }
                        else if (msg["error"] !== undefined && msg["error"] !== null) {
                            Janus.log(msg["error"]);                            
                        }
                    }
                    
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);

                        this.publishingHandle.handleRemoteJsep({jsep: jsep});
                    }
                },

                onlocalstream: (stream) => {
                    Janus.debug(" ::: Got a local stream :::");
                    //Janus.debug(stream);

                    if (this.devices) {
                        this.devices.devicesAllowed()
                        .then(() => {
    
                            this.localVideoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                            this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
    
                            if (this.localVideoTrack != null) {
                                //console.log(this.localVideoTrack.label);
                                this.devices.selectCameraByName(this.localVideoTrack.label);
                            }
                            if (this.localAudioTrack != null) {
                                //console.log(this.localAudioTrack.label);
                                this.devices.selectMicrophoneByName(this.localAudioTrack.label);
                                this.devices.audioMeter.attach(this.localAudioTrack);
                            }
                            if (this.ui) {
                                this.ui.addLocalTrack(stream);
                            }
                            if (this.devices) {
                                this.devices.devicesAllowed();
                            }
                        });    
                    }
                    
                },

                onremotestream: (stream) => {
                    // The publisher stream is sendonly, we don't expect anything here
                },

                oncleanup: () => {
                    Janus.log(" ::: Got a cleanup notification: we are unpublished now :::");
                }

            });
        }

        this.detachAsPublisher = () => {
            var unpublish = {
                "request" : "unpublish"
            };
            if (this.publishingHandle) {
                this.publishingHandle.send({
                    "message" : unpublish
                });
            }
        }

        this.detachRemoteFeed = (peerId, publisherId) => {
            console.log(`Detach Remote Feed ${peerId}:${publisherId}`);
            var remoteFeed = this.getRemoteFeed(peerId, publisherId);
            if (!remoteFeed) {
                return;
            }
            var leave = {
                "request": "leave"
            };
            remoteFeed.send({
                "message" : leave
            });
            remoteFeed.detach({
                success: () => {
                    console.log(`Remote Feed Detached ${peerId}:${publisherId}`);
                    this.deleteRemoteFeed(peerId, publisherId);
                },
                error: () => {
                    console.log(`Error detaching from Remote Feed ${peerId}:${publisherId}`);
                    this.deleteRemoteFeed(peerId, publisherId);
                }
            });
        }

        this.attachToRemoteFeed = (peerId, publisherId) => {
            // A new feed has been published, create a new plugin handle and attach to it as a subscriber
            var remoteFeed = null;
            this.janusServer.attach({
                plugin: "janus.plugin.videoroom",
                success: (pluginHandle) => {
                    remoteFeed = pluginHandle;
                    remoteFeed.simulcastStarted = false;
                    Janus.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
                    Janus.log("  -- This is a subscriber");
                    // We wait for the plugin to send us an offer
                    var subscribe = { 
                        "request": "join", 
                        "room": this.gatewayRoomId, 
                        "ptype": "subscriber", 
                        "feed": publisherId, 
                        "pin":this.engagementId
                    };                    
                    remoteFeed.send({
                        "message": subscribe
                    });
                },
                error: (error) => {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                onmessage: (msg, jsep) => {
                    Janus.debug(" ::: Got a message (subscriber) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (msg["error"] !== undefined && msg["error"] !== null) {
                        Janus.error(msg["error"]);
                    } 
                    else if (event != undefined && event != null) {
                        if (event === "attached") {
                            // Subscriber created and attached
                            for (var i = 0; i <= this.remoteFeeds.length; i++) {
                                if(this.remoteFeeds[i] === undefined || this.remoteFeeds[i] === null) {
                                    this.remoteFeeds[i] = remoteFeed;
                                    remoteFeed.rfindex = i;
                                    break;
                                }
                            }
                            remoteFeed.peerId = peerId;
                            remoteFeed.publisherId = msg["id"];
                            remoteFeed.rfdisplay = msg["display"];                          
                            Janus.log("Successfully attached to feed " + remoteFeed.publisherId + " for " + remoteFeed.peerId + " (" + remoteFeed.rfdisplay + ") in room " + msg["room"]);
                        } 
                        else if(event === "event") {
                        } 
                        else {
                        }
                    }
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);
                        // Answer and attach
                        remoteFeed.createAnswer(
                            {
                                jsep: jsep,
                                // Add data:true here if you want to subscribe to datachannels as well
                                // (obviously only works if the publisher offered them in the first place)
                                media: { audioSend: false, videoSend: false },	// We want recvonly audio/video
                                success: (jsep) => {
                                    Janus.debug("Got SDP!");
                                    Janus.debug(jsep);
                                    var body = { 
                                        "request": "start", 
                                        "room": this.gatewayRoomId 
                                    };
                                    remoteFeed.send({
                                        "message": body, 
                                        "jsep": jsep
                                    });
                                },
                                error: (error) => {
                                    Janus.error("WebRTC error:", error);
                                }
                            });
                    }
                },
                webrtcState: (on) => {
                    Janus.log("Gateway says this WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                },
                onlocalstream: (stream) => {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotestream: (stream) => {
                    Janus.debug("Remote feed #");
                    // Janus.debug("Remote feed #" + remoteFeed.rfindex);                    
                    var tracks = stream.getTracks();
                    for(var track of tracks) {
                        this.ui.addPeerTracks({
                            peerId : `${remoteFeed.peerId}:${remoteFeed.publisherId}`,
                            track  : track,
                            speakerId: this.devices.selectedSpeaker ? this.devices.selectedSpeaker.deviceId : null,
                            peerType : Constants.PeerType.Agent // CURRENTLY ONLY AGENTS HAVE REMOTE STREAMS, this will need to change if and when customers start sending via SFU
                        });
                    }
                },
                oncleanup: function() {
                    Janus.log(" ::: Got a cleanup notification (remote feed " + remoteFeed.peerId + ") :::");
                    remoteFeed.simulcastStarted = false;
                }
            });
        }

        this.on("Gateway:disconnected", () => {
            console.log("DISCONNECTED FROM GATEWAY");

            this.reconnectingMediaState = Object.assign({}, this.mediaState);
            //console.log(`Before falsing ${JSON.stringify(this.reconnectingMediaState)}`)
            this.mediaState.audio = false;
            this.mediaState.video = false;
            this.publisherId = 0;
            //console.log(`After falsing ${JSON.stringify(this.reconnectingMediaState)}`)
            this.emit("CallSFU:mediaStateChanged", this.mediaState);
        });

        this.on("Gateway:reconnected", () => {
            for (var i = this.remoteFeeds.length - 1; i >= 0; i -= 1) {
                let remoteFeed = this.remoteFeeds[i];
                if (remoteFeed && remoteFeed.peerId && remoteFeed.publisherId) {
                    this.remoteFeeds.splice(i, 1);
                    this.attachToRemoteFeed(remoteFeed.peerId, remoteFeed.publisherId);
                }                
            }

            // now attach my publishing
            if (this.publishingHandle) {
                this.attachAsPublisher(true);
            }
        });
        
    }

    dispose() {
        console.log("Dispose of the CallSFU");
        
        this.reconnectingMediaState = null;

        this.mediaState = {
            publisherId: 0,
            video: false,
            audio: false
        };
        for (var i = 0; i < this.remoteFeeds.length; ++i) {
            this.removePeer(this.remoteFeeds[i].peerId, this.remoteFeeds[i].publisherId);
        }
        this.remoteFeeds = null;

        this.joinedVideoRoom = false;

        if (this.publishingHandle) {
            this.publishingHandle.detach();
        }

        super.dispose();    
    }

    addPeer(peerId, publisherId) {
        //console.log('*************** addPeer', candidateFilter);
        var remoteFeed = this.getRemoteFeed(peerId, publisherId);
        if (remoteFeed) {
            return;
        }

        super.addPeer(`${peerId}:${publisherId}`);

        if (publisherId !== 0) {
            if (this.janusServer != null && this.janusServer.isConnected()) {
                this.attachToRemoteFeed(peerId, publisherId);
            }
            else {
                console.log(`Adding ${peerId} ${publisherId} to queue until connected`);
                const queuedFeed = this.queuedItem(this.attachToRemoteFeed, this, [peerId, publisherId]);
                this.funQueue.push(queuedFeed);

                if (!this.connecting) {
                    this.connectToJanus(() => { 
                        //console.log(`Connected to Gateway`);
                        this.processQueue();
                    });
                }
            }
        }        
    }

    queuedItem(fn, context, params) {
        return () => {
            fn.apply(context, params);
        }    
    }
    
    processQueue() {
        while (this.funQueue.length > 0) {
            console.log("Dequeuing remote feed");
            (this.funQueue.shift())();
        }
    }

    removePeer(peerId, publisherId) {
        this.detachRemoteFeed(peerId, publisherId);

        super.removePeer(`${peerId}:${publisherId}`);
    }

    switchMainPeer(peerId, publisherId) {
        this.ui.switchMainPeer(`${peerId}:${publisherId}`);
    }

    startAudioVideo(options) {
        console.log("startAudioVideo()");
    
        this.publishAV(true, true, options);
    }

    stopVideo(negotiate) {
        console.log("stopVideo() negotiate:" + negotiate);
        this.publishAV(this.sendingAudio, false);
    }

    stopAudio(negotiate) {
        console.log("stopAudio() negotiate:" + negotiate);
        this.publishAV(false, this.sendingVideo);
    }

    stopAudioVideo() {
        console.log("stopAudioVideo()");
        this.publishAV(false, false);
    }

    selectCamera(deviceId) {
        // check the settings are not the same as the previous settings
        var newCameraDevice = this.devices.getCameraByDeviceId(deviceId);
        if (newCameraDevice != null) {
            if (this.devices.selectedCamera && newCameraDevice.deviceId === this.devices.selectedCamera.deviceId) {
                console.log("Camera:" + newCameraDevice.label + " is the same as what we have set so leave alone");
                return;
            }
        }

        this.devices.selectCamera(deviceId);

        this.publishAV(this.sendingAudio, true);
    }

    selectMicrophone(deviceId) {
        // check the settings are not the same as the previous settings
        var newMicrophoneDevice = this.devices.getMicrophoneByDeviceId(deviceId);
        if (newMicrophoneDevice != null) {
            if (this.devices.selectedMicrophone && newMicrophoneDevice.deviceId === this.devices.selectedMicrophone.deviceId) {
                console.log("Microphone:" + newMicrophoneDevice.label + " is the same as what we have set so leave alone");
                return;
            }
        }

        this.devices.audioMeter.detach();
        this.devices.selectMicrophone(deviceId);

        this.publishAV(true, this.sendingVideo);
    }

    selectSpeaker(deviceId) {
        this.devices.selectSpeaker(deviceId);
        if (this.devices.selectedSpeaker != null) {
            this.ui.setAudioOutput(this.devices.selectedSpeaker.deviceId);
        }
        else {
            console.log("cannot selectSpeaker: " + deviceId);
        }
    }

}