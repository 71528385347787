<ng-container [ngSwitch]="endChatType">
  <div *ngSwitchCase="EndType.END_CHAT" class="flex-grow-1">

    <ng-container *ngIf="endChatView === EndChatViewType.END; then end else select"></ng-container>

    <ng-template #end>
      <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">

        <p-button (onClick)="cancelEndChat()" id='cancel-btn'   [text]="true" severity="secondary" styleClass="mr-2"
                  type="button">{{ "ENDCHATMODAL_BUTTON_KEEPCHAT" | Translate : 'Keep Chatting' }}
        </p-button>

        <p-button (onClick)="selectNewHost()" *ngIf="agents.length > 0" id="transfer-host"
                  type="button">{{ "ENDCHATMODAL_BUTTON_TRANSFERHOST" | Translate : 'Leave chat and transfer host' }}
        </p-button>

        <p-button (onClick)="onEndChat('')" id="ok-btn"  severity="danger"
                  type="button">{{ "ENDCHATMODAL_BUTTON_YESENDCHAT" | Translate : 'End Chat' }}
        </p-button>

      </div>
    </ng-template>

    <ng-template #select>
      <p-dropdown (onChange)="onPrimaryAgentChange($event)" [options]="agents" id="new-host-dd" optionLabel="nickname" [filter]="agents?.length > 10"
                  placeholder="{{ ('ENDCHATMODAL_BUTTON_NEWHOST' | Translate : 'Select New Host' )}}">
      </p-dropdown>

      <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center" id="give-control">

        <p-button (onClick)="cancelSelectNewHost()" id="cancel-btn" [text]="true" severity="secondary" styleClass="mr-2"
                  type="button">{{ "LABEL_CANCEL" | Translate : 'Cancel' }}
        </p-button>

        <p-button (onClick)="onEndChat(newPrimaryUsername)" [disabled]="newPrimaryUsername === ''" id="ok-btn"
                   severity="danger"
                  type="button">{{ "ENDCHATMODAL_BUTTON_CONFIRM" | Translate : 'Confirm' }}
        </p-button>
      </div>

    </ng-template>
  </div>

  <div *ngSwitchCase="EndType.LEAVE_CHAT">
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{ "ENDCHATMODAL_BUTTON_KEEPCHAT" | Translate : 'No, keep chatting' }}
      </p-button>
      <p-button (onClick)="onEndChat('')" id="ok-btn"  severity="danger">{{ "ENDCHATMODAL_BUTTON_YESLEAVECHAT" | Translate : 'Yes, leave chat' }}
      </p-button>
    </div>
  </div>

  <div *ngSwitchCase="EndType.END_ON_CHECKLIST_FAIL">
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{
          "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
        }}
      </p-button>
      <p-button (onClick)="onEndChat('')" id="ok-btn"  >{{
          "MODAL_BUTTON_OK" |
            Translate : "OK"
        }}
      </p-button>
    </div>

  </div>

  <div *ngSwitchCase="EndType.END_MONITORING">
    <p>
      {{ "ENDCHATMODAL_FINISHEDLOOKING" | Translate : 'This will remove this call from your active call list; only choose this option if you are certain you are finished looking.' }}
    </p>

    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{ "ENDCHATMODAL_BUTTON_CONTINUELOOKING" | Translate : 'No, Continue Looking' }}
      </p-button>
      <p-button (onClick)="onEndMonitoring()" id="ok-btn"  severity="danger" >{{ "ENDCHATMODAL_BUTTON_ENDMONITORING" | Translate : 'End Monitoring' }}
      </p-button>
    </div>

  </div>

  <div *ngSwitchCase="EndType.JOIN_PRIVATE_CHAT">
    <p>
      {{ "ENDCHATMODAL_ENTERRINGCHATMESSAGE" | Translate : 'The agent is not aware you are viewing their session. Consider sending them a private message to let them know you will be entering the chat.' }}
    </p>

    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary" styleClass="mr-2"
                type="button">{{ "MODAL_BUTTON_CANCEL" | Translate : 'Cancel' }}
      </p-button>
      <p-button (onClick)="onJoinPrivateChat()"
                type="button">{{ "ENDCHATMODAL_BUTTON_OPENCHAT" | Translate : 'No, Open a Private Chat with the Agent' }}
      </p-button>
      <p-button (onClick)="onJoinPublicChat()"  severity="danger"
                type="button">{{ "ENDCHATMODAL_BUTTON_ENTERCHAT" | Translate : 'Yes, Enter the Chat' }}
      </p-button>
    </div>
  </div>

  <div *ngSwitchCase="EndType.JOIN_PUBLIC_CHAT">
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{
          "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
        }}
      </p-button>
      <p-button (onClick)="onJoinPublicChat()" id="ok-btn"  severity="danger" >{{ "ENDCHATMODAL_BUTTON_ENTERCHAT" | Translate : 'Yes, Enter the Chat' }}
      </p-button>
    </div>
  </div>

  <div *ngSwitchCase="EndType.LEAVE_PUBLIC_CHAT">
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{
          "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
        }}
      </p-button>
      <p-button (onClick)="onLeavePublicChat()" id="ok-btn" severity="success"  >{{ "ENDCHATMODAL_BUTTON_EXITCHAT" | Translate : 'Yes, Exit the Chat' }}
      </p-button>
    </div>
  </div>

  <div *ngSwitchCase="EndType.LEAVE_SUP_MODE">
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary" styleClass="mr-2">{{
          "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
        }}
      </p-button>
      <p-button (onClick)="goToVeestudio()" *ngIf="veestudioAvailable"
                type="button">{{ "ENDCHATMODAL_BUTTON_GOTOVEESTUDIO" | Translate : 'Go to VeeStudio' }}
      </p-button>
      <p-button (onClick)="goToVeechat()" *ngIf="veechatAvailable"
                type="button">{{ "ENDCHATMODAL_BUTTON_GOTOVEECHAT" | Translate : 'Go to VeeChat' }}
      </p-button>
    </div>
  </div>

  <div *ngSwitchCase="EndType.LEAVE_SUP_SESSIONS">

    <p>{{ "ENDCHATMODAL_BODY_ENDALLSESSIONS" | Translate : 'This will end all your supervising sessions.' }}</p>

    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button (onClick)="cancelEndChat()" id='cancel-btn' [text]="true" severity="secondary">{{
          "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
        }}
      </p-button>
      <p-button (onClick)="proceedToNewMode()" id="ok-btn" severity="danger" >{{
          "MODAL_BUTTON_OK" |
            Translate : "OK"
        }}
      </p-button>
    </div>

  </div>
</ng-container>
