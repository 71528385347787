import { Injectable, OnDestroy } from "@angular/core";
import Guid from "../../classes/Guid";
import { AuthService } from "../auth-service/auth.service";
import { Agent } from "../../classes/agent";
import { Observable, Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IFileUpload } from "./file-upload-interface";
import { FileUpload } from "./file-upload";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../../environments/environment";
import { SettingsService } from "../settings-service/settings.service";

type Bytes = number;
export type TransferId = Guid;

export enum ValidateFileType {
  Valid,
  FileTooLarge,
  FileExtensionInvalid,
  InvalidOther,
}

interface ValidFile {
  type: ValidateFileType.Valid;
}

interface FileTooLarge {
  type: ValidateFileType.FileTooLarge;
}

interface FileExtensionInvalid {
  type: ValidateFileType.FileExtensionInvalid;
}

interface FileInvalidOther {
  type: ValidateFileType.InvalidOther;
}

export type ValidationResult =
  | ValidFile
  | FileTooLarge
  | FileExtensionInvalid
  | FileInvalidOther;

@Injectable({
  providedIn: "root",
})
export class FileTransferService implements OnDestroy {
  private static DEFAULT_MAX_FILE_TRANSFER_SIZE: Bytes = 20 * 1024 * 1024; // 20M
  private static readonly DEFAULT_FILE_TRANSFER_ENDPOINT =
    "https://vee24fileupload.azurewebsites.net/api/storage";

  private fileTransfers: Map<TransferId, FileUpload> = new Map([]);

  private agent: Agent;

  private endpointUrl = FileTransferService.DEFAULT_FILE_TRANSFER_ENDPOINT;

  private maxFileSize: Bytes =
    FileTransferService.DEFAULT_MAX_FILE_TRANSFER_SIZE; // BYTES
  private allowAllFileExt = false;
  private allowedFileExts: string[] = [];

  private resourcesSub: Subscription;

  public get transferCount(): number {
    return this.fileTransfers.size;
  }

  public maxFileSizeLimit(): string {
    let kbSize = this.maxFileSize / 1024;
    if (kbSize < 1000) {
      return `${kbSize} KB`;
    }
    let mbSize = kbSize / 1024;
    return `${mbSize.toFixed(2)} MB`;
  }

  private authSub: Subscription;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly settingsService: SettingsService
  ) {
    this.authSub = this.authService.currentAgent.subscribe((agent) => {
      this.agent = agent;

      if (agent && agent.servers.FILEUPLOADURL) {
        this.endpointUrl = this.agent.servers.FILEUPLOADURL;
      } else {
        this.endpointUrl = FileTransferService.DEFAULT_FILE_TRANSFER_ENDPOINT;
      }
    });

    this.resourcesSub = settingsService.resources.subscribe((_) =>
      this.updateSettings()
    );
  }

  private updateSettings() {
    // Two settings to update, the max file size and the allowed extensions
    const maxFileSizeSetting = this.settingsService.getResourceOrDefault(
      "AGENT_FILEUPLOAD_MAX_KB",
      "5000"
    );
    const maxFileSize = parseInt(maxFileSizeSetting, 10);

    if (isNaN(maxFileSize) || maxFileSize <= 0) {
      this.maxFileSize = FileTransferService.DEFAULT_MAX_FILE_TRANSFER_SIZE;
    } else {
      this.maxFileSize = maxFileSize * 1024; // Setting is in KB.
    }

    const extensionsSetting = this.settingsService.getResourceOrDefault(
      "AGENT_FILEUPLOAD_EXTENSION",
      "*"
    );
    if (extensionsSetting === "*") {
      this.allowedFileExts = [];
      this.allowAllFileExt = true;
    } else {
      this.allowedFileExts = [];
      this.allowAllFileExt = false;

      const extensions = extensionsSetting.split(";");
      for (const ext of extensions) {
        const match = ext.toUpperCase().match(/\*\.([A-Z]+)/);
        if (match) {
          this.allowedFileExts.push(match[1]);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.resourcesSub.unsubscribe();
    for (const [_, upload] of this.fileTransfers) {
      this.stopFileUpload(upload);
    }
  }

  createFileTransfer(
    file: File,
    userGuid: string,
    sessionGuid: string,
    engagementGuid: string
  ): IFileUpload {
    switch (this.validateFile(file).type) {
      default:
      case ValidateFileType.FileExtensionInvalid:
      case ValidateFileType.InvalidOther:
      case ValidateFileType.FileTooLarge:
        return null;
      case ValidateFileType.Valid:
        break;
    }

    const transferId: TransferId = new Guid();
    const upload = FileUpload.CreateEngagementFileUpload(
      transferId,
      this.http,
      this.endpointUrl,
      file,
      this.agent.sitename,
      userGuid,
      sessionGuid,
      engagementGuid
    );
    this.fileTransfers.set(transferId, upload);
    return upload;
  }

  createFileTransferUsingToken(
    file: File,
    callId: string
  ): Promise<IFileUpload> {
    return this.getFileUploadToken(callId)
      .toPromise()
      .then((uploadToken) => {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(uploadToken);

        const tokenEndpoint = decodedToken.endpoint;

        const transferId: TransferId = new Guid();
        const upload = FileUpload.CreateTokenFileUpload(
          transferId,
          this.http,
          tokenEndpoint,
          file,
          uploadToken,
          this.agent.sitename,
          callId
        );
        this.fileTransfers.set(transferId, upload);

        return Promise.resolve(upload);
      });
  }

  getFileUploadToken(callId: string): Observable<string> {
    const url = `${environment.uploadRoot}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "auth-token": this.agent.authToken,
      }),
      params: {
        callid: callId,
      },
    };

    return this.http.get<string>(url, httpOptions);
  }

  removeFileTransfer(file: IFileUpload): boolean {
    const upload = this.fileTransfers.get(file.transferId);

    if (upload) {
      this.stopFileUpload(upload);
    }

    return this.fileTransfers.delete(file.transferId);
  }

  public start(file: IFileUpload): boolean {
    const upload = this.fileTransfers.get(file.transferId);

    return upload ? this.startFileUpload(upload) : false;
  }

  public cancel(file: IFileUpload): boolean {
    const upload = this.fileTransfers.get(file.transferId);
    return upload ? this.stopFileUpload(upload) : false;
  }

  private startFileUpload(upload: FileUpload): boolean {
    return upload.start();
  }

  private stopFileUpload(upload: FileUpload): boolean {
    return upload.cancel();
  }

  public validateFile(file: File): ValidationResult {
    if (file) {
      if (file.size > this.maxFileSize) {
        return { type: ValidateFileType.FileTooLarge };
      }

      if (!this.validateFileExtension(file.name)) {
        return { type: ValidateFileType.FileExtensionInvalid };
      }

      return { type: ValidateFileType.Valid };
    }

    return { type: ValidateFileType.InvalidOther };
  }

  private validateFileExtension(filename: string): boolean {
    if (this.allowAllFileExt) {
      return true;
    }

    filename = filename.toUpperCase();
    const extPos = filename.lastIndexOf(".");
    let ext = "";
    if (extPos > -1) {
      ext = filename.substr(extPos + 1);
    }

    return this.allowedFileExts.some((testExt) => testExt === ext);
  }
}
