import Constants from "./Constants";
import CallSFU from "./CallSFU";

export default class CallSFUAgent extends CallSFU {
    constructor({ 
        engagementId = null,
        peerId ='itsthetaste',
        iceServer = null,
        gatewayServer = null,
        gatewayRoomId = 0,
        peerType = Constants.PeerType.Agent,
        mainPeerId = 'itsthetaste',
        speakerVolume = 1.0,
        mainVideoContainer = null,
        connectingOverlay = null,
        reConnectingOverlay = null,
        onHoldOverlay = null,
        peersContainer = null
    } = {}) {        

        super({ 
            engagementId,
            peerId,
            iceServer,
            gatewayServer,
            gatewayRoomId,
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        this.previousSendingAudio = false;
        this.previousSendingVideo = false;
        this.previousSendingVideoOptions = null;

        if (this.janusServer != null && this.janusServer.isConnected()) {
            this.attachAsPublisher();
        }
        else {
            this.connectToJanus(this.attachAsPublisher);
        }

    }

    dispose() {
        this.detachAsPublisher();
        super.dispose();
    }

    pauseCall() {
        this.previousSendingAudio = this.sendingAudio;
        this.previousSendingVideo = this.sendingVideo;
        this.previousSendingVideoOptions = this.sendingVideoOptions;

        this.publishAV(false, false);
    }

    resumeCall() {
        this.publishAV(this.previousSendingAudio, this.previousSendingVideo, this.previousSendingVideoOptions);
    }

    textChat() {
        this.stopAudioVideo();
    }

    audioChat() {
        this.publishAV(true, false);
    }

    videoChat() {
        this.startAudioVideo();
    }

    changeCamera(newCamera, newCameraWidth, newCameraHeight) {
        
        // check the settings are not the same as the previous settings
        var newCameraDevice = this.devices.getCamera(newCamera);
        if (newCameraDevice != null) {
            var newCameraDeviceId = newCameraDevice.deviceId;
            if (this.devices.selectedCamera 
                && newCameraDeviceId === this.devices.selectedCamera.deviceId 
                && (newCameraWidth-1) === this.devices.cameraWidth 
                && (newCameraHeight-1) === this.devices.cameraHeight) {
                console.log("Camera:" + newCamera + " width:" + newCameraWidth + " height:" + newCameraHeight + " is the same as what we have set so leave alone");
                return;
            }    
        }        

        this.devices.changeCamera(newCamera, newCameraWidth, newCameraHeight);

        this.publishAV(this.sendingAudio, true);
    }

    flipCamera(options) {
        this.publishAV(options.sendAudio ? options.sendAudio : this.sendingAudio, true, options);
    }


}
