import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CrmStructure, CrmData } from './crm-category';
import { BrowsingHistory } from './browsing-history';
import { EngagementEvent } from './engagement-event';
import { ContactFilterData } from './crm-contact';
import { CheckListField } from '../../classes/checklist/CheckListField';
import { OpportunityStatus, Opportunity } from './opportunity';
import { SessionTypes } from './sessionTypes';
import { MatchedCustomer } from './matched-customer';
import {EngagementChatHistory} from './EngagementChatHistory';
import { Agent } from '../../classes/agent';
import { EngagementPurchaseHistory } from './engagement-purchase-history';
import Guid from '../../classes/Guid';
import {map} from 'rxjs/operators';
import {ChatHistory} from './ChatHistory';

@Injectable({
  providedIn: 'root'
})
export class CrmBackendService {

  constructor(
    private http: HttpClient
  ) { }

  public loadCRMDataStructure(authToken: string): Observable<CrmStructure> {
    const url = `${environment.crmRoot}/crmstructure`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<CrmStructure>(
      url,
      httpOptions
    );
  }

  public loadCustomerData(authToken: string, userGuid: string): Observable<CrmData> {
    const url = `${environment.crmRoot}/crmdata`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      params: new HttpParams({
        fromObject: {
          userGuid: userGuid
        }
      })
    };

    return this.http.get<CrmData>(
      url,
      httpOptions
    );
  }

  public getBrowsingHistory(authToken: string, userGuid: string, sessionGuid: string): Observable<BrowsingHistory[]> {
    const url = `${environment.crmRoot}/browsinghistory`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      userGuid: userGuid,
      sessionGuid: sessionGuid
    };

    return this.http.post<BrowsingHistory[]>(
      url,
      body,
      httpOptions
    );
  }

  public getSessionHistory(authToken: string, userGuid: string): Observable<EngagementEvent[]> {
    const url = `${environment.crmRoot}/sessionhistory`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      userGuid: userGuid
    };

    return this.http.post<EngagementEvent[]>(
      url,
      body,
      httpOptions
    );
  }

  public getEngagementChatHistory(authToken: string, engagmentGuid: string): Observable<EngagementChatHistory[]> {
    const url = `${environment.crmRoot}/transcript`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      engagementGuid: engagmentGuid
    };

    return this.http.post<EngagementChatHistory[]>(
      url,
      body,
      httpOptions
    );
  }

  public getChatHistory(authToken: string, vee24guid: Guid, engagementGuid: Guid, count: number, watermark: number): Observable<ChatHistory[]> {
    const url = `${environment.crmRoot}/transcript`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      params: new HttpParams({
        fromObject: {
          userGuid: vee24guid.toString(),
          currentEngagement: engagementGuid.toString(),
          count,
          watermark,
        }
      })
    };

    return this.http.get<ChatHistory[]>(
      url,
      httpOptions
    ).pipe(map(items => {
      // The date's coming from the server are in strings, convert them to dates
      items.forEach(item => item.timestamp = new Date(item.timestamp));
      return items;
    }));
  }

  public getPurchaseHistory(authToken: string, engagmentGuid: string): Observable<EngagementPurchaseHistory[]> {
    const url = `${environment.crmRoot}/purchases`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      engagementGuid: engagmentGuid
    };

    return this.http.post<EngagementPurchaseHistory[]>(
      url,
      body,
      httpOptions
    );
  }

  public filterContactData(authToken: string, customerName: string, customerEmail: string, customerPhone: string,
    pagesize: number, pagenumber: number, startDate: string, endDate: string): Observable<ContactFilterData> {
    const url = `${environment.crmRoot}/contactdata`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      customerName: customerName,
      customerEmail: customerEmail,
      customerPhone: customerPhone,
      pagesize: pagesize,
      pagenumber: pagenumber,
      startDate: startDate,
      endDate: endDate
    };

    return this.http.post<ContactFilterData>(
      url,
      body,
      httpOptions
    );
  }

  public checkEmailDuplication(authToken: string, email: string, userGuid: string): Observable<CrmData> {
    const url = `${environment.crmRoot}/checkemail`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      email: email,
      userGuid: userGuid
    };

    return this.http.post<CrmData>(
      url,
      body,
      httpOptions
    );
  }

  public setCrmData(authToken: string, userGuid: string, crmData: CrmData): Observable<boolean> {
    const url = `${environment.crmRoot}/crmdata`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      userGuid: userGuid,
      crmData: crmData
    };

    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }

  public findCustomer(authToken: string, searchTerm: string): Observable<MatchedCustomer[]> {
    const url = `${environment.crmRoot}/findcustomer`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      params: new HttpParams({
        fromObject: {
          searchTerm: searchTerm
        }
      })
    };

    return this.http.get<MatchedCustomer[]>(
      url,
      httpOptions
    );
  }

  public getChecklistFields(authToken: string, checklistName: string): Observable<CheckListField[]> {
    const url = `${environment.crmRoot}/checklistfields`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      'checklistName': checklistName
    };

    return this.http.post<CheckListField[]>(
      url,
      body,
      httpOptions
    );
  }

  public loadOpportunityStatus(authToken: string): Observable<OpportunityStatus[]> {
    const url = `${environment.crmRoot}/opportunitystatus`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<OpportunityStatus[]>(
      url,
      httpOptions
    );
  }

  public getOpportunitiesForContact(authToken: string, vee24Guid: string): Observable<Opportunity[]> {
    const url = `${environment.crmRoot}/customeropportunities`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      vee24Guid: vee24Guid
    };

    return this.http.post<Opportunity[]>(
      url,
      body,
      httpOptions
    );
  }
  public getOpportunityById(authToken: string, opportunityId: string): Observable<Opportunity> {
    const url = `${environment.crmRoot}/opportunityById`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      opportunityId: opportunityId
    };

    return this.http.post<Opportunity>(
      url,
      body,
      httpOptions
    );
  }

  public blockCustomer(
    authToken: string,
    vee24guid: string,
    ip: string,
    reason: string
  ): Observable<boolean> {
    const url = `${environment.crmRoot}/blockcustomer`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken,
      }),
    };
    const body = {
      ip: ip,
      vee24guid: vee24guid,
      reason: reason,
    };

    return this.http.post<boolean>(url, body, httpOptions);
  }

  filterOpportunityData(authToken: string, vee24Guid: string, customerName: string, opportunityTitle: string, opportunityStatus: number): Observable<Opportunity[]> {
    const url = `${environment.crmRoot}/filteropportunitiesdata`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      vee24Guid: vee24Guid,
      customerName: customerName,
      opportunityTitle: opportunityTitle,
      opportunityStatus: opportunityStatus
    };

    return this.http.post<Opportunity[]>(
      url,
      body,
      httpOptions
    );
  }
  public insertUpdateOpportunity(authToken: string, vee24Guid: string, opportunityTitle: string, opprtunityProduct: string, opportunityNotes: string, opportunityStatus: string, opportunityValue: string, opportunityId: string): Observable<boolean> {
    const url = `${environment.crmRoot}/opportunityinsertupdate`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {
      opportunityId: opportunityId,
      vee24Guid: vee24Guid,
      opportunityTitle: opportunityTitle,
      opportunityProduct: opprtunityProduct,
      opportunityNotes: opportunityNotes,
      opportunityStatus: opportunityStatus,
      opportunityValue: opportunityValue
    };

    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }



  public loadSiteSections(authToken: string): Observable<string[]> {
    const url = `${environment.crmRoot}/sitesections`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<string[]>(
      url,
      httpOptions
    );
  }
  public loadSessionTypes(authToken: string): Observable<SessionTypes[]> {
    const url = `${environment.crmRoot}/sessionTypes`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<SessionTypes[]>(
      url,
      httpOptions
    );
  }


  public loadAgents(authToken: string): Observable<Agent[]> {
    const url = `${environment.agentsRoot}/getAllAgents`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<Agent[]>(
      url,
      httpOptions
    );
  }

}
