export enum StatusEnum {
    ALL = 0,
    OPEN = 1,
    CLOSED = 2,
    CANCELLED = 3,
    UNAVAILABLE = 4,
    NOSHOW = 5,
    CLOSED_AUTO = 6,
    NOSHOW_AUTO = 7,
  }
