import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogService, DynamicDialogRef, DynamicDialogConfig, DynamicDialogComponent} from 'primeng/dynamicdialog';
import {distinctUntilChanged} from "rxjs/operators";

const DEFAULT_MODAL_TRANSITION_OPTIONS:string =  '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Injectable({
  providedIn: 'root',
})
export class ModalService extends DialogService {

  private modalShownSource = new BehaviorSubject<boolean>(false);

  showingModal$ = this.modalShownSource.asObservable().pipe(distinctUntilChanged());

  isModalShowing() {
    return this.dialogComponentRefMap.size > 0;
  }

  openModal(componentType: any, config: DynamicDialogConfig<any>): DynamicDialogRef  {
    let ref: DynamicDialogRef = null;

    if (this.dialogComponentRefMap.size === 0) {
      ref = this.open(componentType, {
        ...config,
        transitionOptions: DEFAULT_MODAL_TRANSITION_OPTIONS
      });
      this.modalShownSource.next(this.isModalShowing());
    }

    return ref;
  }

  closeModal(componentType: DynamicDialogRef) {
    if (this.dialogComponentRefMap.size > 0) {
      componentType.close();
      componentType.destroy();
      this.modalShownSource.next(false);
    }
  }

  closeAllOpenModals() {
    if (this.dialogComponentRefMap.size > 0) {
      const [dialogRef] = this.dialogComponentRefMap.keys();
      this.closeModal(dialogRef);
    }
  }
}
