import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Opportunity } from '../../services/crm-service/opportunity';
import {faChevronLeft, faSave, faSearch} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CrmService } from '../../services/crm-service/crm.service';
import { CrmStructure, CrmData } from '../../services/crm-service/crm-category';
import { CrmEmailState, CrmEmailStateType } from '../../services/crm-service/crm-email-state';
import { AlertService, AlertType } from '../../services/alert-service/alert.service';
import { OnlineService } from '../../services/online.service';
import { OnlineState } from '../../enums/online-state.enum';
import { TranslatePipe } from '../../filters/Translate.pipe';
import { CrmState } from '../crm/crm.component';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  providers:[TranslatePipe]
})

export class OpportunityComponent implements OnInit {

  faSave=faSave;
  OnlineState = OnlineState;
  faSearch = faSearch;
  CrmState = CrmState;
  private subscriptions: Subscription[] = [];

  @Input() set visitorCrmData(data: CrmStructure) {
    if (data != null && data.list && data.list.length > 0) {
      this.crmData  = this.crmService.extractCustomerData(data);
      const firstName = this.crmService.getCrmFieldValue(this.crmData, 'Customer Information', 'Firstname');
      const lastName = this.crmService.getCrmFieldValue(this.crmData, 'Customer Information', 'Lastname');
      const fullName = (firstName === undefined ? '' : firstName) + ' ' + (lastName === undefined ? '' : lastName);
      this.customerName = fullName;
      this.leadId = this.crmService.getCrmFieldValue(this.crmData, 'Customer Information', 'ID');
      if (this.opportunityState !== OnlineState.CrmOpportunity) {
        this.populateData(new Opportunity());
      }
    }
  }
  @Input() userGuid: string;
  @Input() emailState: CrmEmailStateType;
  @Input() opportunities: Opportunity[];
  @Input() crmStateInput: CrmState;

  @Input() set selectedOpportunity(data: Opportunity) {
    this.opportunityState = this.onlineService.currentState.value[0];
    if (this.opportunityState === OnlineState.CrmOpportunity && data.opportunityId !== 0) {
      this.populateData(data);
    }
  }
  @Input() set isPrimaryAgent(data: boolean) {
    this.isReadOnly = !data;
  }

  populateData(data: Opportunity) {
    this.opportunityId = data.opportunityId;
    this.opportunityTitle = data.opportunityTitle === 'Create New' ? '' : data.opportunityTitle;
    this.opportunityStatusId = data.opportunityStatus;
    this.opportunityProduct = data.opportunityProduct;
    this.opportunityValue = data.opportunityValue;
    this.opportunityNotes = data.opportunityNotes;
  }

  @Output() backToSearch : EventEmitter<Boolean> = new EventEmitter<Boolean>();

  CrmEmailState = CrmEmailState;

  public opportunityState:OnlineState;
  public opportunitystatus;
  public opportunity:Opportunity;
  public crmData:CrmData;

  public opportunityTitle:string = "";
  public opportunityId = 0;
  public opportunityStatusId = 0;
  public opportunityProduct = "";
  public opportunityValue = "";
  public opportunityNotes = "";

  public customerName:string = "";
  public leadId:string = "";
  public isReadOnly :Boolean = false;

  constructor(private crmService: CrmService,
              private alertService: AlertService,
              private onlineService: OnlineService,
              private translate: TranslatePipe) {
  }
  ngOnInit() {
    const statuses = this.crmService.opportunityStatus.subscribe(status => {
      if(status!= null){
        this.opportunitystatus = Array.from(status)
      }
    });
    this.subscriptions.push(statuses);
    this.opportunityState = this.onlineService.currentState.value[0];
  }
  opportunityChange(){
    if(this.opportunityId > 0){
           const opp = this.crmService.getOpportunityById(this.opportunityId.toString()).subscribe(op => {
             this.populateData(op);
           });
           this.subscriptions.push(opp);
    }else{
      this.populateData(new Opportunity());
    }
  }
  validateOpportunity():Boolean {
    if(this.opportunityId < 0){
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_VALID", 'Please select Valid Opportunity'), AlertType.Danger);
      return false;
    }
    if(this.opportunityTitle == ""){
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_TITLE", 'Please Enter Opportunity Title'), AlertType.Danger);
      return false;
    }
    if(this.opportunityStatusId <= 0){
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_STATUS", 'Please select Opportunity Status'),AlertType.Danger);
      return false;
    }
    return true;
  }

  saveOpportunityData(){
    if(this.validateOpportunity()){
     this.crmService.insertUpdateOpportunity(this.userGuid,this.opportunityTitle,this.opportunityProduct,this.opportunityNotes,this.opportunityStatusId.toString(),this.opportunityValue,this.opportunityId.toString()).subscribe(
       res=> {
         if(res){
          this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_SUCCESS", 'Opportunity Saved!!'),AlertType.Success);
          const opp = this.crmService.getOpportunitiesForContact(this.userGuid).subscribe(op => {
            this.opportunities = Array.from(op);
            this.opportunities.unshift(new Opportunity());
          });
          this.subscriptions.push(opp);
          if(this.opportunityState == OnlineState.CrmOpportunity && this.crmStateInput == CrmState.EditOpportunity){
            this.backToOpportunitySearch();
          }else{
            this.populateData(new Opportunity());
          }
        }
     },
     err=> {

     });
    }
  }

  backToOpportunitySearch(){
    this.populateData(new Opportunity());
    this.backToSearch.emit(true);
  }

  protected readonly faChevronLeft = faChevronLeft;
}
