import {Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
  selector: '[appDebounceClick]'
})
export class ClickDebounceDirective implements OnInit, OnDestroy {
  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.clicks.next(event);
  }

  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  private clicks = new Subject<MouseEvent>();
  private click$: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    this.click$ = this.clicks.pipe(throttleTime(100)).subscribe(event => this.clicked.emit(event));
  }

  ngOnDestroy(): void {
    if (this.click$) {
      this.click$.unsubscribe();
    }    
  }
}
