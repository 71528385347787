import {Component, Input, OnInit} from '@angular/core';
import {AgentStatus} from '../../../../../../classes/visitor/AgentStatus';
import { TimeUtil } from 'app/utils/time-util';

@Component({
  selector: 'app-dashboard-card-vee-studio-operators-not-available',
  templateUrl: './dashboard-card-vee-studio-operators-not-available.component.html',
  styleUrls: [
    './dashboard-card-vee-studio-operators-not-available.component.scss',
    '../../../supervisor-dashboard-view.component.scss'
  ]
})
export class DashboardCardVeeStudioOperatorsNotAvailableComponent implements OnInit {
  TimeUtil = TimeUtil;

  // selectedGroups is not the selected groups in the hovis UI (that drop down in veesupervisor)
  // but the groups the supervisor belongs to (i.e. select in my.vee24.com)
  @Input() selectedGroups: Map<number, string>;

  @Input() selectedAgents: Map<string, AgentStatus>;

  @Input() show: boolean = true;

  constructor() { }

  ngOnInit() {
  }
}
