import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faMicrophoneSlash,
  faMicrophone,
  faVideo,
  faVideoSlash,
  faCog,
  faCaretDown,
  faCaretUp,
  faVolumeHigh,
  faVolumeMute,
  faCommentSlash, faComment, faVolumeUp, faCircleCheck, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {Features, FeatureService} from '../../../../../services/feature-service/feature.service';
import {OnlineService} from '../../../../../services/online.service';
import { CameraDirection } from 'app/classes/camera-direction';
import {CommunicationMode} from "../../../../../services/engagement";
import {CallType} from "../../../../../enums/call-type.enum";

@Component({
  selector: 'app-engagement-customer-av-controls',
  templateUrl: './engagement-customer-av-controls.component.html',
  styleUrls: ['./engagement-customer-av-controls.component.scss']
})
export class EngagementCustomerAvControlsComponent implements OnInit {
  private lastSpeakerVolume = 50;
  @Input() videoUnavailable: boolean;
  @Input() speakerVolume = 50;

  CameraDirection = CameraDirection;
  @Input() cameraDirection: CameraDirection = CameraDirection.FRONT;
  @Output() changeCameraDirection: EventEmitter<CameraDirection> = new EventEmitter<CameraDirection>();

  @Input() customerVideoOn: boolean;
  @Input() customerMicOn: boolean;
  @Input() customerCogOn: boolean;
  @Input() cameraAccessGranted: boolean;
  @Input() isMobile: boolean;
  @Input() isMobileSdk: boolean;
  @Input() cameraAlreadyInUse: boolean;
  @Input() micAccessGranted: boolean;
  @Input() micAlreadyInUse: boolean;
  @Input() micActivityLevel: number;
  @Input() disabled: boolean = false;
  @Output() changeCustomerVideo = new EventEmitter<boolean>();
  @Output() changeCustomerMic = new EventEmitter<boolean>();
  @Output() changeSpeakerVolume = new EventEmitter<number>();
  @Output() changeCustomerCog = new EventEmitter<boolean>();

  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faCog = faCog;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faSpeakerOn = faVolumeHigh
  faSpeakerOff = faVolumeMute;

  private hasCustomerMediaPanel: boolean = false;
  public get avControlAvailable(): boolean {
    return !this.isMobileSdk && this.hasCustomerMediaPanel;
  }

  constructor(private readonly featureService: FeatureService) {
  }

  ngOnInit() {
    this.hasCustomerMediaPanel = true;
  }

  toggleCustomerVideo() {
    if (this.customerVideoOn) {
      this.changeCustomerVideo.emit(false);
    } else {
      this.changeCustomerVideo.emit(true);
    }
  }

  toggleCustomerMic() {
    if (this.customerMicOn) {
      this.changeCustomerMic.emit(false);
    } else {
      this.changeCustomerMic.emit(true);
    }
  }

  toggleCustomerCog()
  {
    if(this.customerCogOn) {
      this.changeCustomerCog.emit(false);
    } else {
      this.changeCustomerCog.emit(true);
    }
  }
  setVolume($event: Event) {
  // @ts-ignore
    const newVolume = parseInt($event.target.value, 10);

    if (!isNaN(newVolume)) { // volume changed to an obs
        this.changeSpeakerVolume.emit(newVolume / 100);
      }
    }

  toggleSpeakerMute() { // make a mute event
    if (this.speakerVolume > 0) {
   // We are muting the customer here
      this.lastSpeakerVolume = this.speakerVolume;
      this.changeSpeakerVolume.emit(0);
    } else {
        this.changeSpeakerVolume.emit(this.lastSpeakerVolume / 100);
      }
  }


  protected readonly CommunicationMode = CommunicationMode;
  protected readonly faCommentSlash = faCommentSlash;
  protected readonly faComment = faComment;
  protected readonly CallType = CallType;
  protected readonly faVolumeUp = faVolumeUp;
  protected readonly faVolumeMute = faVolumeMute;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faTimesCircle = faTimesCircle;
}
