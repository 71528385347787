import { Subscription, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ELECTRON_BROWSER_EVENTS } from "./electron-constants";
import { WindowMessageService } from '../window-message-service/window-message.service';
import { EventEmitter } from '@angular/core';
import { BrowserConfiguration } from "../browser-service/browser-configuration";
import { BrowserIPCMessage } from './browser-ipc-message';
import { BrowserChannelIPCMessage } from './browser-channel-ipc-message';
import { BrowserTypes } from '../browser-service/browser-types';
import Guid from '../../classes/Guid';
import { BrowserServiceStatus, IBrowserService } from '../browser-service/IBrowserService';
import { IBrowser } from '../browser-service/IBrowser';
import { AddressStack } from '../browser-service/address-stack';
import { StringUtils } from '../../utils/string-utils';

export class ElectronBrowserProxy implements IBrowser {
  public readonly currentPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public get guid():string {
    return this.config.guid;
  }

  private get type(): BrowserTypes {
    if (this.config) {
      return this.config.type;
    }
  }

  public readonly onMessage: EventEmitter<any> = new EventEmitter<any>();
  public readonly onResize: EventEmitter<void> = new EventEmitter<void>();
  public readonly onBrowserCreated: ReplaySubject<void> = new ReplaySubject<void>();

  private subs: Subscription[] = [];

  private clientWidth: number;
  private clientHeight: number;

  private panelDimensions:any;
  private filteredIpcMessage$:Observable<BrowserChannelIPCMessage>;

  private config: BrowserConfiguration;
  private page: string = "";

  private disposed: boolean = false;

  private readonly addresses: AddressStack = new AddressStack();
  public get backEnabled() {
      return this.addresses.backEnabled;
  }

  public get forwardEnabled(): boolean {
      return this.addresses.forwardEnabled;
  }

  private addressPageSub: Subscription;

  constructor(
    private windowMessagingService: WindowMessageService,
    private readonly browserService: IBrowserService,
    private readonly useLegacyApi: boolean) {
      this.addressPageSub = this.addresses.changePage.subscribe(url => this.navigateToUrl(url));
  }

  private browserCreated() {
    this.onBrowserCreated.next();

    this.browserService.setStatus(BrowserServiceStatus.CREATED);

    this.subs.push(
      this.filteredIpcMessage$.subscribe(message => {

        const channel = message.channel;
        const incomingMessage = message.message || { data: "", type: BrowserTypes.none };
        const data = incomingMessage.data;

        switch (channel) {
          case ELECTRON_BROWSER_EVENTS.BROWSER_URL_LOADED:
            this.browserService.setStatus(BrowserServiceStatus.LOADED);
            this.handleIncomingUrl(data);
            break;

          case ELECTRON_BROWSER_EVENTS.BROWSER_URL_LOADING:
            this.browserService.setStatus(BrowserServiceStatus.LOADING);
            break;

          case ELECTRON_BROWSER_EVENTS.ONMESSAGE:
            this.onMessage.emit(data);
            break;

          case ELECTRON_BROWSER_EVENTS.BROWSER_GET_BOUNDS:
            this.onResize.emit();
            break;
        }

      }));

    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_SET_URL, this.page);
    this.currentPage.next(this.page);
  }

  private parseMessage(data) {
    if (this.useLegacyApi) {
      return new BrowserIPCMessage(data, BrowserTypes.cobrowse, BrowserConfiguration.GUID_UNDEFINED);
    } else {
      return BrowserIPCMessage.deserialize(data);
    }
  }

  create(options:BrowserConfiguration) {
    options.guid = this.useLegacyApi ? BrowserConfiguration.GUID_UNDEFINED : new Guid().toString();
    this.config = options;

    this.currentPage.next(options.initialUrl);

    if (options.initialUrl) {
      this.addresses.addPage(options.initialUrl);
    }

    const electronBrowserActions = Object.values(ELECTRON_BROWSER_EVENTS);

    this.filteredIpcMessage$ = this.windowMessagingService.ipcMessage$.pipe(
      filter(v => electronBrowserActions.indexOf(v.action) > -1),
      map(v => {
        try {
          return new BrowserChannelIPCMessage(v.action, this.parseMessage(v.data));
        } catch {
          return null;
        }
      }),
      filter(v => {
        return v && (v.message.guid === BrowserConfiguration.GUID_UNDEFINED || v.message.guid === this.guid);
      })
    );

    this.subs.push(
      this.filteredIpcMessage$
          .pipe(filter(v => v.channel === ELECTRON_BROWSER_EVENTS.BROWSER_CREATED), take(1))
          .subscribe(_ => this.browserCreated())
    );

    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_CREATE, options);

    //BROWSER_CREATED only exists on electron >= 1.0.12
    if (this.useLegacyApi) {
      this.browserCreated();

      //since we dont have a BROWSER_CREATED event on electron versions < 1.0.12 we need to wait a bit to make sure
      //the browser is initialized
      setTimeout(() => {
          this.changeUrl(this.page);
      }, 1000);
    }
  }

  dispose() {
    const message = this.browserMessage({});
    setTimeout(() => this.sendToElectron(ELECTRON_BROWSER_EVENTS.BROWSER_DESTROY, message), 150);
    this.disposed = true;

    this.subs.forEach(s => s.unsubscribe());
    this.onBrowserCreated.complete();
    this.onResize.complete();
    this.onMessage.complete();
    this.currentPage.complete();

    this.addressPageSub.unsubscribe();
  }

  changeUrl(url: string) {
    if (url && !StringUtils.urlEqual(this.page, url)) {
      this.addresses.addPage(url);
      this.navigateToUrl(url);
    }
  }

  private navigateToUrl(url: string) {
    if (url && !StringUtils.urlEqual(this.page, url)) {
      this.send(ELECTRON_BROWSER_EVENTS.BROWSER_SET_URL, url);
      this.page = url;
      this.currentPage.next(this.page);
    }
  }

  show() {
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_SHOW);
  }

  hide() {
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_HIDE);
  }

  enable() {
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_ENABLE);
  }

  disable() {
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_DISABLE);
  }

  enableEmulation(width: number, height: number, deviceScaleFactor: number) {
    this.send(ELECTRON_BROWSER_EVENTS.ENABLE_EMULATION, {
      width,
      height,
      deviceScaleFactor
    });
  }

  disableEmulation() {
    this.send(ELECTRON_BROWSER_EVENTS.DISABLE_EMULATION);
  }

  setClientDimensions(width: number, height: number) {
    if (this.config && this.config.enableCustomerResize) {
      this.clientWidth = width;
      this.clientHeight = height;
      this.send(ELECTRON_BROWSER_EVENTS.CLIENT_DIMENSIONS, {width, height});
    }
  }

  reload() {
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_RELOAD);
  }

  setPanelDimensions(top:number, left:number, width: number, height: number) {
    this.panelDimensions = {top, left, width, height};
    this.sendPanelDimensions();
  }

  postMessage(msg: string, origin:string = "*") {
    this.send(ELECTRON_BROWSER_EVENTS.POST_MESSAGE, {msg, origin});
  }

  private sendPanelDimensions(){
    if(this.panelDimensions) {
      this.send(ELECTRON_BROWSER_EVENTS.PANEL_DIMENSIONS, this.panelDimensions);
    }
  }

  public sendBrowserDimensions(boundingRect) {
    const width = this.clampBounds(boundingRect.width, this.clientWidth);
    const height = this.clampBounds(boundingRect.height, this.clientHeight);

    const dimensions = {
      x: boundingRect.x,
      y: boundingRect.y,
      width: width,
      height: height
    };

    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_SET_MAXBOUNDS, boundingRect);
    this.send(ELECTRON_BROWSER_EVENTS.BROWSER_SET_BOUNDS, dimensions);

    setTimeout(() => this.sendPanelDimensions(), 100);
  }

  public back(): void {
    this.addresses.back();
  }

  public forward(): void {
      this.addresses.forward();
  }

  private clampBounds(frameBounds: number, clientBounds: number) {
    if (clientBounds === 0) {
      return Math.floor(frameBounds);
    } else {
      return Math.floor(Math.min(frameBounds, clientBounds));
    }
  }

  private send(action, data = {}) {

    if (!this.config) {
      return;
    }

    if (this.disposed) {
      return;
    }

    const message = this.browserMessage(data);
    this.sendToElectron(action, message);
  }

  private sendToElectron(action: string, message: any) {
    this.windowMessagingService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action,
      data: message,
    }, "*");
  }

  private browserMessage(data: any) {
    return this.config.guid === BrowserConfiguration.GUID_UNDEFINED ? data : new BrowserIPCMessage(data, this.type, this.config.guid).serialize();
  }

  private handleIncomingUrl(url: string) {
    this.page = url;
    this.addresses.addPage(this.page);
    this.currentPage.next(this.page);
  }
}
