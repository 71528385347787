export class CheckListField {
    Title: string;
    CRMField: string;
    CheckAction: string;
    CheckType: ChecklistFieldType;
    CheckValue: string;
    Message: string;
    IsRulePassed: boolean;
    IsCustomTask: boolean;

}

export enum ChecklistFieldType {
  MANDATORY = 'Mandatory Field',
  RECOMMENDED = 'Recommended Field'
}
