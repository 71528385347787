import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Input, EventEmitter, Output, Renderer2 } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CustomerSizes } from '../../../services/engagement';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'app-iframe-domsync-coviewer',
  templateUrl: './iframe-domsync-coviewer.component.html',
  styleUrls: ['./iframe-domsync-coviewer.component.scss']
})
export class IframeDomsyncCoviewerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('iframeContainer', {static: true}) iframeContainer: ElementRef;

  // These are CSS styles
  private customerWidth: string = "100%";
  private customerWidthEx: string = "100%";
  private customerHeight: string = "100%";

  @Input() set currentPanelPosition(currentPanelPosition: CustomerSizes) {
    this.customerWidth = `${currentPanelPosition.browserWidth}px`;
    // Add an extra 50px to remove any horizontal scroll bar
    this.customerWidthEx = `${currentPanelPosition.browserWidth + 50}px`;
    this.customerHeight = `${currentPanelPosition.browserHeight}px`;
    this.updateBrowserSizes();
  }

  private sub: Subscription;
  private subs: Subscription[] = [];

  private engagementFrame: any;
  private recvFunction = (ev: any) => this.onMessage(ev);
  private loadedFunc = (ev: any) => this.iframeLoaded();

  @Input() url: string;

  @Input() set fromCustomerCommand(obs: Observable<string>) {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
    if (obs) {
      this.sub = obs.subscribe(n => this.handleCustomerCommand(n));
    }
  }

  private handleCustomerCommand(msg: string) {

    if (msg === 'restart' && this.engagementFrame) {
      this.engagementFrame.src = this.url;
    } else if (this.engagementFrame) {
      this.engagementFrame.contentWindow.postMessage(msg, '*');
    }
    else {
      this.logging.warn("fromCustomerCommand not called, engagement frame not loaded");
    }

  }

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDomSyncCommandMessage = new EventEmitter<string>();

  constructor(
    private renderer: Renderer2,
    private logging: LoggingService,
  ) {
  }

  ngOnInit() {
    addEventListener("message", this.recvFunction);
  }

  onMessage(event) {
    if (event.origin !== `${environment.staticRoot}`) {
      return;
    }

    const message = event.data;
    if (message === "") {
      return;
    }

    this.onDomSyncCommandMessage.emit(message);
  }

  ngAfterViewInit() {
    this.addDomSync();
  }

  addDomSync() {

    if (!this.iframeContainer) {
      this.logging.warn("No iframe container");
      return;
    }

    this.engagementFrame = this.renderer.createElement('iframe');
    this.engagementFrame.id = "vee24AgentDomSyncFrame";
    // this.engagementFrame.style.maxWidth = "100%";
    this.engagementFrame.style.maxHeight = "100%";
    this.engagementFrame.style.frameborder = "0";
    this.engagementFrame.src = this.url;
    this.updateBrowserSizes();

    this.renderer.appendChild(this.iframeContainer.nativeElement, this.engagementFrame);
    this.engagementFrame.addEventListener("load", this.loadedFunc);

  }

  iframeLoaded() {
    this.onReady.emit(true);
  }

  ngOnDestroy() {
    this.removeDomSync();
  }

  removeDomSync() {

    if (this.engagementFrame) {
      this.engagementFrame.removeEventListener("load", this.loadedFunc);
      this.engagementFrame.src = "about:blank";
      this.renderer.removeChild(this.iframeContainer.nativeElement, this.engagementFrame);
      this.engagementFrame = null;
    }

    removeEventListener("message", this.recvFunction);

    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }

    if (this.subs) {
      this.subs.forEach(s => s.unsubscribe());
      this.subs = [];
    }

  }

  private updateBrowserSizes() {
    if (this.engagementFrame) {
      this.engagementFrame.style.width = this.customerWidthEx;
      this.engagementFrame.style.height = this.customerHeight;
    }
  }
}
