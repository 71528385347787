import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { OnlineService } from '../online.service';
import { RecordingProxy } from './recording-proxy';
import { ElectronRingerProxy } from './electron-ringer-proxy';
import { ElectronBrowserProxy } from './electron-browser-proxy';
import { ElectronWebrtcSharingProxy } from "./electron-webrtc-sharing-proxy";
import { LocalVariable } from './local-variable';
import { WindowMessageService } from '../window-message-service/window-message.service';
import { ELECTRON_APP_EVENTS } from './electron-constants';
import { ElectronCameraProxy } from './electron-camera-proxy';
import { IBrowserService } from '../browser-service/IBrowserService';
import { IBrowser } from '../browser-service/IBrowser';
import { LoggingService } from '../logging.service';
import { ModalService } from '../modal.service';
import {TranslatePipe} from "../../filters/Translate.pipe";

export type ELECTRON_ALERT_CODE = {
  ALERT_CODE:string;
  EXTRA: string;
};
@Injectable({
  providedIn: 'root'
})
export class ElectronService implements OnDestroy {
  private subs: Subscription[] = [];
  public focused$: Subject<boolean> = new Subject();
  public localVariable$: Subject<LocalVariable> = new Subject();
  public licenceKey$: Subject<string> = new Subject();

  private _appVersion: string = "";

  public getVersion():string {
    return this._appVersion;
  }


  public getUseLegacyAPI():boolean {
    return this.getVersion() === "";
  }

  public readonly alertCode$:Subject<ELECTRON_ALERT_CODE> = new Subject();

  constructor(
    private windowMessagingService: WindowMessageService,
    private onlineService: OnlineService,
    private modalService: ModalService,
    private loggingService: LoggingService,
    private translate: TranslatePipe) {}

  init() {

    this.subs.push(this.windowMessagingService.ipcMessage$.subscribe(message => {
      switch (message.action) {

        case ELECTRON_APP_EVENTS.FOCUS:
          this.focused$.next(message.data);
          break;

        case ELECTRON_APP_EVENTS.SET_LOCAL_VARIABLE:
          this.localVariable$.next(message.data);
          break;

        case ELECTRON_APP_EVENTS.ALERT_MESSAGE:
          this.alertCode$.next(message.data);
          break;

        case ELECTRON_APP_EVENTS.SET_LICENCE_KEY:
          this.licenceKey$.next(message.data);
          break;

        case ELECTRON_APP_EVENTS.SET_APP_VERSION:
          this._appVersion = message.data;
          break;

      }
    }));
    this.subs.push(this.onlineService.currentState.subscribe(([state, _]) => {
      this.updateAgentState(state);
    }));
  }



  createElectronBrowserProxy(browserService: IBrowserService): IBrowser {
    return new ElectronBrowserProxy(this.windowMessagingService, browserService, this.getUseLegacyAPI());
  }

  createElectronRinger() {
    return new ElectronRingerProxy(this.windowMessagingService);
  }

  createWebrtcSharingProxy() {
    return new ElectronWebrtcSharingProxy(this.windowMessagingService, this.modalService, this.loggingService, this.translate);
  }

  createCameraControllerProxy() {
    return new ElectronCameraProxy(this.windowMessagingService);
  }

  createRecordingProxy() {
    return new RecordingProxy(this.windowMessagingService);
  }

  minimize() {
    this.send(
      ELECTRON_APP_EVENTS.MINIMIZE
    );
  }

  close() {
    this.send(
      ELECTRON_APP_EVENTS.CLOSE
    );
  }

  updateAgentState(state: string) {
    this.send(
      ELECTRON_APP_EVENTS.AGENT_STATUS, state
    );
  }

  // Local variable should only be used when customer data is deleted after an engagement
  // otherwise the data stored may be incorrect
  getLocalVariable(variable: string) {
    this.send(
      ELECTRON_APP_EVENTS.GET_LOCAL_VARIABLE,
      variable
    );
  }

  openExternalLink(url: string) {
    this.send(
      ELECTRON_APP_EVENTS.EXTERNAL_URL,
      url
    );
  }

  sendCulture(culture: string) {
    this.send(
      ELECTRON_APP_EVENTS.CULTURE_LOADED,
      culture
    );
  }

  getLicenceKey() {
    this.send(
      ELECTRON_APP_EVENTS.GET_LICENCE_KEY
    );
  }

  getAppVersion() {
    this.send(
      ELECTRON_APP_EVENTS.GET_APP_VERSION
    );
  }

  private send(action, data?) {
    this.windowMessagingService.postMessage(
      {
        type: "vee24-ipc",
        command: "send",
        action,
        data
      }, "*");
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }

}
