import {Component, OnInit, Input, Output, EventEmitter, forwardRef} from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { CheckListField } from '../../../classes/checklist/CheckListField';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {ControlValueAccessorBase} from "../control-value-accessor.base";

@Component({
  selector: 'app-crm-field-email',
  templateUrl: './crm-field-email.component.html',
  styleUrls: ['./crm-field-email.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CrmFieldEmailComponent),
    multi: true
  }]
})
export class CrmFieldEmailComponent extends ControlValueAccessorBase<string> implements OnInit {
  private _field: CrmField;


  @Input()
  set field(field: CrmField) {
    this._field = field;
    if (field) {
      this.writeValue(field.value);
    }
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<void> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public onFieldValueChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.onInputChange(value);
    if (this._field) {
      this._field.value = value;
      this.saveField.emit();
    }
  }
}
