import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vee24-progress-spinner',
  templateUrl: './vee24-progress-spinner.component.html',
  styleUrls: ['./vee24-progress-spinner.component.scss']
})
export class Vee24ProgressSpinnerComponent {
  @Input() loading = false;
}
