import {IFileUpload} from './file-upload-interface';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FileTransferState, TransferFile} from '../../classes/fileupload/transfer-file';
import {HttpClient} from '@angular/common/http';
import {TransferId} from './file-transfer.service';
import {EngagementTransferFile} from '../../classes/fileupload/engagement-transfer-file';
import {TokenTransferFile} from '../../classes/fileupload/token-transfer-file';

export enum TransferState {
  IDLE = 'IDLE',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  ERRORED = 'ERRORED',
  DONE = 'DONE',
}

export class FileUpload implements IFileUpload {
  private _id: TransferId;
  public get transferId(): TransferId {
    return this._id;
  }

  public get uploadUrl(): string {
    return this.fileUpload.uploadUrl;
  }

  public get filename(): string {
    return this.fileUpload.filename;
  }

  public state: BehaviorSubject<TransferState> = new BehaviorSubject<TransferState>(TransferState.IDLE);
  public progress: BehaviorSubject<number>;

  private fileUploadSub: Subscription;

  public static CreateEngagementFileUpload(transferId: TransferId,
                                           http: HttpClient,
                                           endpointUrl: string,
                                           file: File,
                                           site: string,
                                           userGuid: string,
                                           sessionGuid: string,
                                           engagementId: string) {
    const fileUpload = new EngagementTransferFile(http, endpointUrl, file, site, userGuid, sessionGuid, engagementId);
    return new FileUpload(transferId, fileUpload);
  }

  public static CreateTokenFileUpload(transferId: TransferId,
                                      http: HttpClient,
                                      endpointUrl: string,
                                      file: File,
                                      token: string,
                                      site: string,
                                      callId: string) {
    const fileUpload = new TokenTransferFile(http, endpointUrl, file, token, site, callId);
    return new FileUpload(transferId, fileUpload);
  }

  private constructor(transferId: TransferId, private fileUpload: TransferFile) {
    this._id = transferId;
    this.fileUploadSub = this.fileUpload.state$.subscribe(newState => this.handleStateChange(newState));
    this.progress = this.fileUpload.progress$;
  }

  public start() {
    if (this.state.value === TransferState.IDLE) {
      return this.fileUpload.start();
    }
    return false;
  }

  public cancel() {
    if (this.state.value === TransferState.INPROGRESS) {
      return this.fileUpload.abort();
    }
    return false;
  }

  private handleStateChange(newState: FileTransferState) {
    switch (newState) {
      case FileTransferState.IDLE:
        this.state.next(TransferState.IDLE);
        break;
      case FileTransferState.DONE:
        this.state.next(TransferState.DONE);
        break;
      case FileTransferState.CANCELLED:
        this.state.next(TransferState.CANCELLED);
        this.fileUploadSub.unsubscribe();
        this.state.complete();
        break;
      case FileTransferState.STARTING:
      case FileTransferState.UPLOADING:
        this.state.next(TransferState.INPROGRESS);
        break;
      case FileTransferState.ERRORED:
        this.state.next(TransferState.ERRORED);
        this.fileUploadSub.unsubscribe();
        this.state.complete();
        break;
    }
  }
}
