export default class Guid {
  private guidString = '00000000-0000-0000-0000-000000000000';

  // Neat guid generator from
  // http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  private static generateUUID(): string { // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public static isValidUUID(uuid: any): boolean {
    if (uuid == null || !Guid.isString(uuid)) {
      return false;
    }

    const validRegex = /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/i;
    const matches = uuid.match(validRegex);

    if (matches == null || matches.length !== 1) {
      return false;
    }

    const version = parseInt(uuid[19], 16);
    return !(isNaN(version) || ((version & ~0x3) !== 0x8));
  }

  private static isString(val: any): boolean {
    return typeof val === 'string'
      || ((!!val && typeof val === 'object')
        && Object.prototype.toString.call(val) === '[object String]');
  }

  public static get Empty(): Guid {
    return new Guid();
  }

  constructor(guid?: string) {
    if (guid) {
      if (Guid.isValidUUID(guid)) {
        this.guidString = guid.toLowerCase();
      } else {
        throw new Error('Invalid guid');
      }
    } else {
      this.guidString = Guid.generateUUID();
    }
  }

  public toString() {
    return this.guidString;
  }
}
