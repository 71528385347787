import { Component, OnInit, OnDestroy } from '@angular/core';
import { FeatureService, Features } from '../../services/feature-service/feature.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../services/auth-service/auth.service';
import { Agent } from '../../classes/agent';
import { VisitorService } from '../../services/visitor-service/visitor.service';
import { OnlineService } from '../../services/online.service';
import { LicenceType } from '../../enums/licence-type.enum';
import { Router } from '@angular/router';
import { OnlineState } from '../../enums/online-state.enum';
import { LicencedFeature } from '../../classes/licencedFeature';
import {WorkStatus} from '../../classes/work-status';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ VisitorService ]
})
export class HomeComponent implements OnInit, OnDestroy {

  public currentAgent: Agent = null;
  public enabledFeatures: LicencedFeature[] = [];

  private licencedFeatures: LicencedFeature[] = [
    {
      feature: Features.VEECHAT,
      image: 'veechat.png',
      route: '/veechat',
      label: 'VeeChat'
    },
    {
      feature: Features.VEESTUDIO,
      image: 'veestudio.png',
      route: '/veestudio',
      label: 'VeeStudio'
    },
    {
      feature: Features.SUPERVISOR_MODE,
      image: 'veesupervisor.png',
      route: '/supervisor',
      label: 'VeeSupervisor'
    },
    {
      feature: Features.SCHEDULED_APPOINTMENT,
      image: 'veescheduler.png',
      route: '/veescheduler',
      label: 'VeeScheduler'
    },
  ];

  constructor(
    private featureService: FeatureService,
    private titleService: Title,
    private auth: AuthService,
    private visitorService: VisitorService,
    private onlineService: OnlineService,
    private router: Router,
  ) {

    this.auth.currentAgent.subscribe(agent => {
      this.currentAgent = agent;
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Vee24");

    this.visitorService.startConnection(LicenceType.None).subscribe(success => {
      if (!success) {
        this.router.navigateByUrl('/login');
      } else {
        this.onlineService.setCurrentState(OnlineState.Home, WorkStatus.None);

        // loop of the licencedFeatures adding to enabledFeatures if they are enabled for this user
        this.licencedFeatures.forEach(feature => {
          if (this.featureService.has(feature.feature)) {
            this.enabledFeatures.push(feature);
          }
        });

        // if we only have one licenced feature then try to navigate to it
        if (this.enabledFeatures.length == 1) {
          this.router.navigateByUrl(this.enabledFeatures[0].route);
        }
      }
    }, error => {
      this.router.navigateByUrl('/login');
    });
  }

  ngOnDestroy() {
  }

}
