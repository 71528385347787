<div class="d-flex flex-row gap-1">
  <p-button
    styleClass="btn-square center-icon "
    [severity]="currentMode === AppViewPointerMode.POINT ? 'primary' : 'secondary'"
    [pTooltip]="('ENGAGEMENT_APP_VIEW_POINT' | Translate : 'Point')" id="point-btn"
    (onClick)="togglePointerMode(AppViewPointerMode.POINT)">
    <img src="../../../assets/app-view-icons/point.svg" alt="" width="30">
  </p-button>

  <p-button [severity]="currentMode === AppViewPointerMode.TOUCH ? 'primary' : 'secondary'"
            styleClass="btn-square center-icon "
            [pTooltip]="('ENGAGEMENT_APP_VIEW_TOUCH' | Translate : 'Touch')" id="touch-btn"
            (onClick)="togglePointerMode(AppViewPointerMode.TOUCH)">
    <img src="../../../assets/app-view-icons/touch.svg" alt="" width="30">
  </p-button>

  <p-button styleClass="btn-square center-icon "
            [severity]="currentMode === AppViewPointerMode.DRAW ? 'primary' : 'secondary'"
            [pTooltip]="('ENGAGEMENT_APP_VIEW_DRAW' | Translate : 'Draw')" id="draw-btn"
            (onClick)="togglePointerMode(AppViewPointerMode.DRAW)">
    <img src="../../../assets/app-view-icons/draw.svg" alt="" width="30">
  </p-button>
</div>

<iframe [src]="frameLocation" [width]="customerSize.width" [height]="customerSize.height"></iframe>
