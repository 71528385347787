import Constants from "./Constants";
import CallSFU from "./CallSFU";

export default class CallSFUVisitor extends CallSFU {
    constructor({ 
        engagementId = null,
        peerId ='itsthetaste',
        iceServer = null,
        gatewayServer = null,
        gatewayRoomId = 0,
        peerType = Constants.PeerType.Visitor,
        mainPeerId = 'itsthetaste',
        speakerVolume = 1.0,
        mainVideoContainer = null,
        connectingOverlay = null,
        reConnectingOverlay = null,
        onHoldOverlay = null,
        peersContainer = null
    } = {}) {        

        super({ 
            engagementId,
            peerId,
            iceServer,
            peerType,
            gatewayServer,
            gatewayRoomId,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

    }

}
