import { Component, Input, OnInit } from '@angular/core';
import { faHeart, faEye, faVolumeUp, faEyeSlash, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { Engagement } from '../../../../../services/engagement';
import { EngagementVisitor, UserHeartbeatStates } from '../../../../../services/engagement-visitor';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-engagement-toolbar',
  templateUrl: './engagement-toolbar.component.html',
  styleUrls: ['./engagement-toolbar.component.scss']
})
export class EngagementToolbarComponent implements OnInit {
  public readonly faHeart = faHeart;
  public readonly faEye = faEye;
  public readonly faVolumeUp = faVolumeUp;
  public readonly faEyeSlash = faEyeSlash;
  public readonly faVolumeMute = faVolumeMute;
  public readonly UserHeartbeatStates = UserHeartbeatStates;

  public userCanSeeMe$: Observable<boolean>;
  public userCanHearMe$: Observable<boolean>;
  public iCanHearUser$: Observable<boolean>;

  protected primaryVisitor$: Observable<EngagementVisitor>;

  private _engagement: Engagement;
  @Input() set engagement(value: Engagement) {
    this._engagement = value;
    this.primaryVisitor$ = this._engagement.primaryVisitor$;
  }

  get engagement(): Engagement {
    return this._engagement;
  }

  get currentPage() {
    return this._engagement.currentPage;
  }

  ngOnInit(): void {
    this.primaryVisitor$.subscribe(visitor => {
      if (visitor) {
        this.userCanSeeMe$ = visitor.userCanSeeMe$;
        this.userCanHearMe$ = visitor.userCanHearMe$;
        this.iCanHearUser$ = visitor.iCanHearUser$;
      }
    });
  }
}
