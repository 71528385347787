import { EventEmitter } from "@angular/core";
import { VirtualBackground } from "../../../../classes/virtualBackground";
import { Orientation } from "../../../../enums/multipeer/Orientation";

export interface IVideoComponent {
  undocked:boolean;
  dragResizeEnabled:boolean;

  oncreated: EventEmitter<any>;
  oninitialised: EventEmitter<any>;
  onerror: EventEmitter<any>;

  ondockchange: EventEmitter<boolean>;
  onsignallingmessage: EventEmitter<any>;

  init(engagementId: string, iceServer: string, connectTracks:boolean, myUsername: string, cameraId: string, virtualBackground: VirtualBackground, virtualBackgroundOn: boolean);
  tearDown();

  processMessage(message: any);
  addStream(streamName: string, peerType: any);
  removeStream(streamName: string);
  setPrimaryVisitor(visitor: string);
  audioChat();
  videoChat();
  textChat();
  changeCamera(cameraId: string, width:number, height:number)
  changeMic(micId: string);
  changeSpeaker(speakerId: string);
  pauseCall();
  resumeCall();
}

// These commands form the public interface of the video window for hovis to use.
// There is a copy of these definitions in electron and needs to be kept up to date with here.

export const enum VIDEO_WINDOW_COMMANDS {
  CREATE_WINDOW = 'create-video-window',
  DESTROY_WINDOW = 'destroy-video-window',
  POSITION_WINDOW = 'position-video-window', // NEW
  DRAGRESIZE_ENABLED = 'drag-resize-enabled',
  WINDOW_UNDOCKED = 'window-undocked',
  INITIALISE_CALL = "initialise-call",
  TEARDOWN_CALL = "teardown-call", // do we need if we have destroy
  SIGNALLING_MESSAGE = "signalling-message",
  ADD_PEER = "add-peer",
  REMOVE_PEER = "remove-peer",
  SET_PRIMARY_VISITOR = "set-primary-visitor",
  AUDIO_CHAT = "audio-chat",
  VIDEO_CHAT = "video-chat",
  TEXT_CHAT = "text-chat",
  PAUSE_CALL = "pause-call",
  RESUME_CALL = "resume-call",
  CHANGE_CAMERA = "change-camera",
  CHANGE_MICROPHONE = "change-microphone",
  CHANGE_SPEAKER = "change-speaker",
}

export const VIDEO_WINDOW_COMMAND: string = "video-window-command";
export const VIDEO_WINDOW_RENDERER_COMMAND: string = "video-window-renderer-command";

export interface CreateWindowCommand {
  command: VIDEO_WINDOW_COMMANDS.CREATE_WINDOW;
  x: number;
  y: number;
  width: number;
  height: number;
  url: string;
}

export interface DestroyWindowCommand {
  command: VIDEO_WINDOW_COMMANDS.DESTROY_WINDOW;
}

export interface PositionWindowCommand {
  command: VIDEO_WINDOW_COMMANDS.POSITION_WINDOW;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface DragResizeEnabledCommand {
  command: VIDEO_WINDOW_COMMANDS.DRAGRESIZE_ENABLED;
  enabled: boolean;
}

export interface WindowUndockedCommand {
  command: VIDEO_WINDOW_COMMANDS.WINDOW_UNDOCKED;
  undocked: boolean;
}

export interface InitialiseCallCommand {
  command: VIDEO_WINDOW_COMMANDS.INITIALISE_CALL;
  engagementId: string;
  iceServer: string;
  connectTracks: boolean;
  peerId: string;
  cameraId: string;
  // maybe more here in the future like
  // micId, speakerId, callType (P2P, SFU)
  virtualBackground: VirtualBackground;
  virtualBackgroundOn: boolean;
}

export interface TeardownCallCommand {
  command: VIDEO_WINDOW_COMMANDS.TEARDOWN_CALL;
}

export interface SignallingMessageCommand {
  command: VIDEO_WINDOW_COMMANDS.SIGNALLING_MESSAGE;
  message: any;
}

export interface AddPeerCommand {
  command: VIDEO_WINDOW_COMMANDS.ADD_PEER;
  peerId: string;
  peerType: string;
}

export interface RemovePeerCommand {
  command: VIDEO_WINDOW_COMMANDS.REMOVE_PEER;
  peerId: string;
}

export interface SetPrimaryVisitorCommand {
  command: VIDEO_WINDOW_COMMANDS.SET_PRIMARY_VISITOR;
  visitor: string;
}

export interface AudioChatCommand {
  command: VIDEO_WINDOW_COMMANDS.AUDIO_CHAT;
}

export interface VideoChatCommand {
  command: VIDEO_WINDOW_COMMANDS.VIDEO_CHAT;
}

export interface TextChatCommand {
  command: VIDEO_WINDOW_COMMANDS.TEXT_CHAT;
}

export interface PauseCallCommand {
  command: VIDEO_WINDOW_COMMANDS.PAUSE_CALL;
}

export interface ResumeCallCommand {
  command: VIDEO_WINDOW_COMMANDS.RESUME_CALL;
}

export interface ChangeCameraCommand {
  command: VIDEO_WINDOW_COMMANDS.CHANGE_CAMERA;
  cameraId: string;
  width: number;
  height: number;
}

export interface ChangeMicrophoneCommand {
  command: VIDEO_WINDOW_COMMANDS.CHANGE_MICROPHONE;
  micId: string;
}

export interface ChangeSpeakerCommand {
  command: VIDEO_WINDOW_COMMANDS.CHANGE_SPEAKER;
  speakerId: string;
}

export type VideoWindowCommand = CreateWindowCommand
                        | DestroyWindowCommand
                        | PositionWindowCommand
                        | DragResizeEnabledCommand
                        | WindowUndockedCommand
                        | InitialiseCallCommand
                        | TeardownCallCommand
                        | SignallingMessageCommand
                        | AddPeerCommand
                        | RemovePeerCommand
                        | SetPrimaryVisitorCommand
                        | AudioChatCommand
                        | VideoChatCommand
                        | TextChatCommand
                        | PauseCallCommand
                        | ResumeCallCommand
                        | ChangeCameraCommand
                        | ChangeMicrophoneCommand
                        | ChangeSpeakerCommand;

export type VideoWindowRendererCommand = DragResizeEnabledCommand
                        | WindowUndockedCommand
                        | InitialiseCallCommand
                        | TeardownCallCommand
                        | SignallingMessageCommand
                        | AddPeerCommand
                        | RemovePeerCommand
                        | SetPrimaryVisitorCommand
                        | AudioChatCommand
                        | VideoChatCommand
                        | TextChatCommand
                        | PauseCallCommand
                        | ResumeCallCommand
                        | ChangeCameraCommand
                        | ChangeMicrophoneCommand
                        | ChangeSpeakerCommand;

export const VIDEO_WINDOW_EVENT: string = "video-window-event";
export const VIDEO_WINDOW_RENDERER_EVENT: string = "video-window-renderer-event";

// Output events coming from this module
export const enum VIDEO_WINDOW_EVENTS {
  ONWINDOWCREATED = "onwindowcreated",
  ONWINDOWERRORED = "onwindowerrored",
  ONCALLINITIALISED = "oncallinitialised",
  ONCALLERRORED = "oncallerrored",
  ONDOCKCHANGED = "ondockchanged",
  ONSIGNALLINGMESSAGE = "onsignallingmessage",
  ONWINDOWDRAGGED = "onwindowdragged",
  ONORIENTATIONCHANGED = "onorientationchanged"
}
export interface WindowCreatedEvent {
  type: VIDEO_WINDOW_EVENTS.ONWINDOWCREATED
}
export interface WindowErroredEvent {
  type: VIDEO_WINDOW_EVENTS.ONWINDOWERRORED,
  error: any
}
export interface CallInitialisedEvent {
  type: VIDEO_WINDOW_EVENTS.ONCALLINITIALISED
}
export interface CallErroredEvent {
  type: VIDEO_WINDOW_EVENTS.ONCALLERRORED,
  error: any
}
export interface DockChangeEvent {
  type: VIDEO_WINDOW_EVENTS.ONDOCKCHANGED,
  undocked: boolean
}
export interface SignallingMessageEvent {
  type: VIDEO_WINDOW_EVENTS.ONSIGNALLINGMESSAGE,
  message : any
}
export interface WindowDraggedEvent {
  type: VIDEO_WINDOW_EVENTS.ONWINDOWDRAGGED,
  x: number,
  y: number
}
export interface OrientationChangedEvent {
  type: VIDEO_WINDOW_EVENTS.ONORIENTATIONCHANGED,
  orientation : Orientation
}


export type VideoWindowEvent = WindowCreatedEvent | WindowErroredEvent | CallInitialisedEvent | CallErroredEvent | DockChangeEvent | SignallingMessageEvent | WindowDraggedEvent | OrientationChangedEvent;
