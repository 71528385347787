import EventEmitter from "../EventEmitter";

const ButtonState = {
    None: 0, Down: 1, Lifted: 2
};

const LEFT_BUTTON_SHIFT = 0;
const RIGHT_BUTTON_SHIFT = 2;
const SCROLL_WHEEL_SHIFT = 4;
    
const MOUSE_EVENT_BUTTON_LEFT = 1;
const MOUSE_EVENT_BUTTON_RIGHT = 2;

const TOUCH_EVENT = 'TOUCH_EVENT';


export default class TouchCapturer extends EventEmitter {
    constructor(captureElement) {
        super();

        if (captureElement == null) {
            throw new Error("Capture element given is falsy");
        }

        if (typeof captureElement.addEventListener != 'function') {
            throw new Error("Capture element can not have event listeners added.");
        }

        this.element = captureElement;

        this.moveListenerHandler = (ev) => this.handleMove(ev);
        this.touchDownHandler = (ev) => this.handleTouchDown(ev);
        this.touchUpHandler = (ev) => this.handleTouchUp(ev);

        this.element.addEventListener('touchmove', this.moveListenerHandler);
        this.element.addEventListener('touchstart', this.touchDownHandler);
        this.element.addEventListener('touchend', this.touchUpHandler);
        this.element.addEventListener('touchcancel', this.touchUpHandler);

        this.enabled = false;
        this.debug = false;
        this.disposed = false;

        this.leftButtonState = ButtonState.None;
        this.leftMousePressed = false;

        this.handleMove = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }

            const x = ev.changedTouches[0].pageX;
            const y = ev.changedTouches[0].pageY;
    
            this.emitTouchEvent(x, y);
        };

        this.handleTouchDown = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }
    
            this.leftButtonState = ButtonState.Down;
            this.leftMousePressed = true;

            const x = ev.changedTouches[0].pageX;
            const y = ev.changedTouches[0].pageY;
    
            this.emitTouchEvent(x, y);
    
            this.leftButtonState = ButtonState.None;
        };
    
        this.handleTouchUp = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }
    
            if (this.leftMousePressed) {
                this.leftButtonState = ButtonState.Lifted;
                this.leftMousePressed = false;
            }
            
            const x = ev.changedTouches[0].pageX;
            const y = ev.changedTouches[0].pageY;
    
            this.emitTouchEvent(x, y);
    
            this.leftButtonState = ButtonState.None;
        };

        this.emitTouchEvent = (positionX, positionY) => {
            if (!this.enabled) return;

            let flags = 0;
    
            flags |= this.leftButtonState << LEFT_BUTTON_SHIFT;

            const boundingRect = this.element.getBoundingClientRect();
    
            const event = { x: (positionX - boundingRect.left) / this.element.offsetWidth, y: (positionY - boundingRect.top - window.scrollY)/ this.element.offsetHeight, flags: flags };
    
            this.emit(TOUCH_EVENT, event);
        };
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    focus() {
    }

    blur() {
    }

    dispose() {
        this.off(TOUCH_EVENT);

        this.element.removeEventListener('touchmove', this.moveListenerHandler);
        this.element.removeEventListener('touchstart', this.touchDownHandler);
        this.element.removeEventListener('touchend', this.touchUpHandler);
        this.element.removeEventListener('touchcancel', this.touchUpHandler);

        this.disposed = true;
    }

    setDebug(enabled) {
        this.debug = enabled;
    }
};
