import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {LoggingService} from '../../services/logging.service';
import {Subscription} from 'rxjs';
import Quill from 'quill';
import {SettingsService} from '../../services/settings-service/settings.service';
import {CannedChatCategory} from '../../classes/cannedchats';
import {AuthService} from '../../services/auth-service/auth.service';
import {IFileUpload} from '../../services/file-transfer-service/file-upload-interface';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {TextMessage, TextMessages} from '../../classes/TextMessage';
import {LinkPreviewService} from '../../services/open-graph-service/link-preview.service';
import {Nothing} from '../../classes/maybe';
import {OnlineService} from '../../services/online.service';
import {PlainClipboard} from '../../utils/plainclipboard';
import {faChevronUp, faChevronDown, faPaperPlane, faFileUpload, faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {Dropdown} from "primeng/dropdown";
import {FormControl, UntypedFormControl} from "@angular/forms";
import {Engagement} from "../../services/engagement";

export interface CannedChatDropDownEntry {
  id: number;
  text: string;
  chatText: string;
}

export interface CannedChatDropDownCategory {
  id: number;
  text: string;
  items: CannedChatDropDownEntry[];
}

export interface OutboundMessage {
  plainText: string;
  formattedMessage: any;
}

export const CONVERSATION_MAXIMISED = 'conversationMaximised';

@Component({
  selector: 'app-engagement-text-chat',
  templateUrl: './engagement-text-chat.component.html',
  styleUrls: ['./engagement-text-chat.component.scss']
})
export class EngagementTextChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('textMessagesDiv') textMessagesDiv: ElementRef;
  @ViewChild('cannedchats', {static: false}) cannedChatSelector: Dropdown;

  @Input() previewMessage: TextMessage;

  @Output() loadMoreHistory = new EventEmitter<boolean>;

  private _messages: TextMessages;
  @Input() set messages(messages: TextMessages) {
    this._messages = messages;
    this.scrollToBottom = true;
    this._messages.onNewMessage.subscribe(textMessage => {
      if (this.textMessagesDiv.nativeElement.scrollTop === this.textMessagesDiv.nativeElement.scrollHeight) {
        this.scrollToBottom = true;
      }

      if (textMessage.currentEngagement) {
        this.scrollToBottom = true;
      }
    });
  }

  get messages(): TextMessages {
    return this._messages;
  }

  private scrollToBottom = false;

  @Input() engagement: Engagement;
  @Input() files: IFileUpload[] = [];

  @Output() sendMessage = new EventEmitter<OutboundMessage>();
  @Output() uploadFiles = new EventEmitter<FileList>();
  @Output() cancelFilesTransfer = new EventEmitter<IFileUpload>();

  @Input() translationOn: boolean;
  @Output() setTranslation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() getSuggestionOn: boolean;
  @Output() setGetSuggestion: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() autoReplyOn: boolean;
  @Output() setAutoReply: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() agentText: string;
  @Output() agentTextChange = new EventEmitter();

  @Input() textEntryDisabled: boolean = false;
  @Input() sendButtonDisabled: boolean = false;

  @Input() maxMessageLength: number = 9999999;

  @Input() set richTextContent(richTextContent: any) {
    if (this.quillEditor && richTextContent) {
      this.quillEditor.setContents(richTextContent);
    }
  }

  @Input() useLinkUnfurling: boolean = false;

  public quillEditor: Quill;
  public linkPreview: any;

  public maximiseConversation = false;

  // Quill keybind docs https://quilljs.com/docs/modules/keyboard/
  // These are done here as they are passed in through ngx-quill to the
  // Quill ctor. If you try and use addBindings after the Quill editor
  // has been created then it doesn't let you rebind certain keys
  // such as enter and tab.
  // This is why it is all done in the quillModules.
  private readonly bindings = {
    enter: {
      key: 'enter',
      handler: (range, context) => {
        this.sendTextMessage();
      }
    },
    findChat: {
      key: 'm',
      ctrlKey: true,
      handler: (range, context) => {
        if (this.cannedChatSelector) {
          this.cannedChatSelector.show();
        }
      }
    }
  };

  public quillModules = {
    toolbar: false,
    keyboard: {bindings: this.bindings}
  };

  private subscriptions: Subscription[] = [];

  private cannedChats: CannedChatCategory[] = [];

  public filteredChats: CannedChatDropDownCategory[] = [];

  public fileUploadAvailable: boolean;

  public translationAvailable: boolean;
  public autoReplyAvailable: boolean;
  public getSuggestionAvailable: boolean;

  public messageTooLong: boolean = false;
  public messageTooShort: boolean = true;

  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faPaperPlane = faPaperPlane;
  protected readonly faFileTransfer = faFileUpload;

  public get sendInputDisabled(): boolean {
    return this.sendButtonDisabled || this.messageTooLong || this.messageTooShort;
  }

  private currentlyDisplayedMessage?: number = undefined;

  dropdownControl = new  UntypedFormControl();


  constructor(
    public logger: LoggingService,
    private settings: SettingsService,
    private authService: AuthService,
    private linkPreviewService: LinkPreviewService,
    private featureService: FeatureService
  ) {
    this.translationAvailable = this.featureService.has(Features.TRANSLATOR);
    this.getSuggestionAvailable = this.featureService.has(Features.AGENT_ASSIST);
    this.autoReplyAvailable = this.getSuggestionAvailable && this.settings.getSiteSettingOrDefault("DisplayAutosendButtons", "false").toLowerCase() == "true";
    this.maximiseConversation = this.getMaximisedFromStore();
    // We are overriding the default clipboard behaviour one that removes all formatting
    Quill.register('modules/clipboard', PlainClipboard);
  }

  ngOnInit() {

    // this.subscriptions.push(this.dropdownControl.valueChanges.subscribe((value) => {
    //   // Optionally do something with the value
    //   console.log(value);
    //
    //   // Reset the dropdown value
    //   this.dropdownControl.setValue('', {emitEvent: false}); // Prevents infinite loop
    // }));

    this.fileUploadAvailable = this.featureService.has(Features.FILE_UPLOAD);

    // Set up the sub and filter, then go and load the canned chats
    this.subscriptions.push(this.settings.cannedTexts.subscribe(cannedChats => {
      this.cannedChats = cannedChats;

      const filterCulture = this.authService.currentAgent.value.culture.toLowerCase();

      // Filter returned chats to just the ones for this agent's culture
      this.filteredChats = this.cannedChats.map((category, idx) => {
        const items: CannedChatDropDownEntry[] = category.chats.filter(chat => {
          const translation = chat.translation[filterCulture];
          return translation && translation.length > 0;
        }).map((chat, childIdx) => {
          return {
            id: (idx << 8) + childIdx, // Bit of a bodge, only can have 256 chats in a category
            text: chat.title,
            chatText: chat.translation[filterCulture]
          };
        });
        // Group being set to true makes the following logic apply:
        // if (this.group) { this.selectedOption = this.optionsToDisplay[0].items[0]; }
        // our child items were called children and the value expected is items which is
        // hard coded in the mjs of primeNG
        // Bug here: https://github.com/primefaces/primeng/issues/12637
        // Create the category that will be shown in the selection drop down
        const selectCat: CannedChatDropDownCategory = {
          id: idx,
          text: category.title,
          items
        };

        return selectCat;
      }).filter(cat => cat.items.length > 0); // Remove empty categories
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public ngAfterViewChecked() {
    // When the messages content changes it causes the view to check.
    // After the children have been updated then scroll to the bottom
    // if we have a new message from a customer.
    // Or, if we have loaded previous messages, then keep the previously viewed
    // "top" message before the previous transcript has been added.
    if (this.scrollToBottom) {
      this.scrollToBottom = false;
      this.textMessagesDiv.nativeElement.scrollTop = this.textMessagesDiv.nativeElement.scrollHeight;
    }

    if (this.currentlyDisplayedMessage) {
      const index = this.messages.messages.findIndex(x => x.id === this.currentlyDisplayedMessage);
      const scrollToElement = this.textMessagesDiv.nativeElement.children[index];
      if (scrollToElement) {
        scrollToElement.scrollIntoView();
      }
      this.currentlyDisplayedMessage = undefined;
    }
  }

  public sendTextMessage() {
    if (this.sendButtonDisabled) {
      return;
    }

    const plainText: string = this.quillEditor.getText().trim();
    const message: string = this.quillEditor.root.innerHTML;

    // Reason for this being <12 is if the plainText is empty (so it's html only) then
    // the 'empty' html that quill outputs is 11 characters. If an agent has typed then
    // the plain text is going to be >0 and similarly if it's got no typing e.g.
    // a <a> tag link then it's going to be > 11 anyway.
    if (plainText.length === 0 && message.length < 12) {
      this.logger.debug('Trying to send a 0 length agent message, ignoring.');
      return;
    } else if (this.messageTooLong) {
      // Message length is only really required for Twilio, which limits to 1000 characters.
      this.logger.debug(`Message is too long, over ${this.maxMessageLength}, ignoring.`);
      return;
    }

    // Resetting the text chat to empty needs to be done here.
    // Otherwise there is a data race with the onContentChanged
    // getting triggered before this.agentText is set.
    const preview = this.linkPreview;
    this.quillEditor.setText('');
    this.agentText = '';
    this.linkPreview = undefined;

    this.sendMessage.emit({
      plainText: plainText,
      formattedMessage: {
        message,
        attachments: preview ? [{'link': preview[0]}] : []
      }
    });
  }

  public onEditorCreated({editor}) {
    this.quillEditor = editor;
    this.quillEditor?.on('text-change', ($event) => this.onContentChanged($event));
  }

  public onContentChanged($event) {
    const delta = $event;
    this.agentTextChange.emit(this.agentText);

    if (this.useLinkUnfurling) {
      const links = this.getLinksFromQuillContent(delta);
      this.generatePreviewForLinks(links);
    } else {
      this.linkPreview = undefined;
    }

    this.validateMessageLength();
  }

  private validateMessageLength() {
    const plainText: string = this.quillEditor.getText().trim();
    this.messageTooLong = plainText.length > this.maxMessageLength;
    this.messageTooShort = plainText.length === 0;
  }

  public onCannedChatSelected(chatIdx: { value: number }, runTimeoutHandler: boolean = true) {
    const parentId = chatIdx.value >> 8;
    const childId = chatIdx.value & 0xff;

    const parent = this.filteredChats.find(item => item.id === parentId);
    const child = parent.items[childId];

    this.agentText = child.chatText;
    this.dropdownControl.setValue('', {emitEvent: false}); //reset dropdown and prevent event loop

    if (runTimeoutHandler) {
      // Need to run after the handle returns otherwise focus snaps back to the select box
      setTimeout(() => {
        // this.cannedChatSelector.clear(new Event('clear'));
        this.quillEditor.focus();
        const contentLength = this.quillEditor.getLength();
        this.quillEditor.setSelection(contentLength, 0);
      }, 0);
    }
  }

  public uploadFile(files: FileList) {
    this.uploadFiles.emit(files);
  }

  public cancelFileTransfer(file: IFileUpload) {
    this.cancelFilesTransfer.emit(file);
  }

  public handleSetTranslation(enabled: boolean) {
    this.setTranslation.emit(enabled);
  }

  public handleSetGetSuggestion(enabled: boolean) {
    this.setGetSuggestion.emit(enabled);
  }

  public handleSetAutoReply(enabled: boolean) {
    this.setAutoReply.emit(enabled);
  }

  public onToggleConversationSize() {
    this.toggleMaximised();
  }

  private toggleMaximised() {
    this.maximiseConversation = !this.maximiseConversation;
    this.logger.debug(`Toggle Maximise to: ${this.maximiseConversation}`);
    this.storeMaximised();
  }

  private storeMaximised() {
    this.logger.debug(`Setting local storage maximised: ${this.maximiseConversation}`);
    localStorage.setItem(CONVERSATION_MAXIMISED, this.maximiseConversation.toString());
  }

  private getMaximisedFromStore(): boolean {
    const maximised = localStorage.getItem(CONVERSATION_MAXIMISED) === 'true';
    this.logger.debug(`Getting maximised from local storage: ${maximised}`);
    return maximised;
  }

  private getLinksFromQuillContent(content: any): string[] {
    const links: string[] = [];
    for (const op of content.ops) {
      if (this.isQuillLinkOperation(op)) {
        const url = this.getQuillLinkUrl(op);
        this.logger.debug(`Found link ${url}`);
        links.push(url);
      }
    }
    return links;
  }

  private isQuillLinkOperation(op: any): boolean {
    return op && op.attributes && op.attributes.link;
  }

  private getQuillLinkUrl(op): string {
    return op.attributes.link;
  }

  private generatePreviewForLinks(links: string[]) {
    if (links.length === 0) {
      this.linkPreview = undefined;
    } else {
      this.linkPreviewService
        .getDetails(links[0])
        .subscribe(details => {
          switch (details) {
            case Nothing:
              this.linkPreview = undefined;
              break;
            default:
              this.maybeAddLinkPreview(links[0], details);
          }
        });
    }
  }

  private maybeAddLinkPreview(link: string, linkPreviewData: any) {
    if (this.quillEditor) {
      const contents = this.quillEditor.getContents();
      const links = this.getLinksFromQuillContent(contents);
      if (links.filter(l => l === link).length > 0) {
        this.linkPreview = [linkPreviewData];
      } else {
        this.linkPreview = undefined;
      }
    } else {
      this.linkPreview = undefined;
    }
  }

  onScrollEnd($event: any) {
    if (this.textMessagesDiv.nativeElement.scrollTop === 0 && this.currentlyDisplayedMessage === undefined) {
      this.currentlyDisplayedMessage = this.messages.messages[1].id;
      this.loadMoreHistory.emit();
    }
  }

  protected readonly faPaperclip = faPaperclip;
}
