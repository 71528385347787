import {EventEmitter, Injectable} from '@angular/core';
import {AuthService} from '../auth-service/auth.service';
import {VersionService} from '../version-service/version.service';


export enum Features {
  TRANSLATOR,
  VEESTUDIO,
  VEECHAT,
  FILE_UPLOAD,
  COBROWSE,
  SECURE_SHARE,
  NO_VIDEO,
  CRM,
  SCHEDULED_APPOINTMENT,
  AUDIO_MODE,
  PRIVATE_CHAT,
  DESKTOP_RECORDING_TOGGLE,
  DESKTOP_RECORDING_ALWAYS,
  SUPERVISOR_MODE,
  AGENT_ASSIST,
  ENHANCED_AV_CONTROLS,
  SUPERVISOR_TRANSFER_APPOINTMENT,
  BIG_CUSTOMER_VIDEO,
  SHARE_WEBPAGE_FULLSCREEN,
  SHARE_WEBPAGE_CROPPED,
  DOMSYNC_TOOLBAR,
  DISPLAY_ASYNC_CHATS,
  REJOIN_ENGAGEMENT,
  VERIFY_CUSTOMER,
  VERIFY_ASYNC_CUSTOMER,
  CREATE_SCHEDULED_APPOINTMENT,
  SECURE_SHARE_TOGGLE_USER_INTERACTION
}

export interface IFeatureService {
  disable(feature: Features): void;
  enable(feature: Features): void;
  has(feature: Features, isElectronFeature?:boolean): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureService implements IFeatureService {
  private static readonly FEATURE_LOOKUP: ReadonlyMap<string, Features> = new Map([
    [ 'Translator', Features.TRANSLATOR ],
    [ 'VeeStudio', Features.VEESTUDIO ],
    [ 'VeeChat', Features.VEECHAT ],
    [ 'File Upload', Features.FILE_UPLOAD ],
    [ 'Co-Browse', Features.COBROWSE ],
    [ 'Secure Share', Features.SECURE_SHARE ],
    [ 'No Video', Features.NO_VIDEO ],
    [ 'Share Webpage Fullscreen', Features.SHARE_WEBPAGE_FULLSCREEN],
    [ 'Share Webpage Cropped', Features.SHARE_WEBPAGE_CROPPED ],
    [ 'CRM', Features.CRM ],
    [ 'Scheduled Appointment', Features.SCHEDULED_APPOINTMENT ],
    [ 'Audio Mode', Features.AUDIO_MODE ],
    [ 'Private Chat', Features.PRIVATE_CHAT ],
    [ 'Desktop Recording Toggle', Features.DESKTOP_RECORDING_TOGGLE ],
    [ 'Desktop Recording Always', Features.DESKTOP_RECORDING_ALWAYS ],
    [ 'Supervisor Mode', Features.SUPERVISOR_MODE ],
    [ 'Agent Assist Bot', Features.AGENT_ASSIST],
    [ 'Enhanced AV Controls', Features.ENHANCED_AV_CONTROLS],
    [ 'Supervisor Transfer Appointment', Features.SUPERVISOR_TRANSFER_APPOINTMENT],
    [ 'Big Customer Video', Features.BIG_CUSTOMER_VIDEO],
    [ 'Domsync Toolbar', Features.DOMSYNC_TOOLBAR],
    [ 'Display Async Chat', Features.DISPLAY_ASYNC_CHATS ],
    [ 'Rejoin Engagement', Features.REJOIN_ENGAGEMENT],
    [ 'Verify Customer', Features.VERIFY_CUSTOMER],
    [ 'Verify Async Customer', Features.VERIFY_ASYNC_CUSTOMER],
    ['Create Scheduled Appointment', Features.CREATE_SCHEDULED_APPOINTMENT],
    [ 'Secure Share Toggle User Interaction', Features.SECURE_SHARE_TOGGLE_USER_INTERACTION]
  ]);

  private readonly ELECTRON_ONLY_FEATURES: string[] = [
    Features[Features.DESKTOP_RECORDING_ALWAYS],
    Features[Features.DESKTOP_RECORDING_TOGGLE],
    Features[Features.ENHANCED_AV_CONTROLS],
  ];

  private readonly features: Map<Features, boolean> = new Map();
  private readonly isElectron: boolean = false;

  public readonly featuresChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(private authService: AuthService, private versionService: VersionService) {

    this.isElectron = this.versionService.isElectron;

    this.authService.currentAgent.subscribe(agent => {
      this.features.clear();
      if (agent) {
        this.setup(agent.features);
      }
      this.featuresChanged.emit();
    });
  }

  private setup(features: string[]) {
    for (const possibleFeature of features) {
      const trimmedFeature = possibleFeature.trim();
      const feature = FeatureService.FEATURE_LOOKUP.get(trimmedFeature);
      if (this.canAddFeature(feature)) {
        this.features.set(feature, true);
      }
    }
  }

  private canAddFeature(feature: Features): boolean {
    return feature !== undefined && (this.isElectron || !this.ELECTRON_ONLY_FEATURES.includes(Features[feature]));
  }

  public disable(feature: Features) {
    this.features.set(feature, false);
  }

  public enable(feature: Features) {
    this.features.set(feature, true);
  }

  public has(feature: Features) {
    const status = this.features.get(feature);
    return status === undefined ? false : status;
  }

}
