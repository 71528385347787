import { Pipe, PipeTransform } from "@angular/core";
import { Engagement } from "../services/engagement";
import {DefaultGroup} from "../pages/veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
    name: 'engagementFilter',
    pure: false
})
export class EngagementFilterPipe implements PipeTransform {
    transform(engagements: Engagement[], selectedDept: string): Engagement[] {
        const filtered: Engagement[] = [];

        if (selectedDept != DefaultGroup.NoSuperVisor) {
            for (const engagement of engagements) {
                if (engagement.visitor.agentGroup.some(group => group.Name === selectedDept)) {
                    filtered.push(engagement);
                }
            }
        }
        else {
            return engagements;
        }

        return filtered;
    }
}
