<div class="login">
  <div class="login-wrapper">
    <img class="logo" src="./assets/images/logo.png" alt="Vee24"/>
    <h2>{{ "PASSWORDRESET_HEADER_RESETPASSWORD" | translate }}</h2>
    <form [formGroup]="passwordResetForm" (submit)="submit()" novalidate>
      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-at"></p-inputIcon>
          <input pInputText type="email" id="email-input"
                 placeholder="{{ 'LOGIN_PLACEHOLDER_EMAIL' | translate }}" formControlName="email">
        </p-iconField>
      </div>

      <div class="buttons">
        <p-button severity="secondary" [text]="true"
                  [routerLink]="['/login']">{{ 'PASSWORD_LINK_LOGINREMEMBER' | translate }}
        </p-button>
        <p-button type="submit" severity="success">{{ "PASSWORD_BUTTON_SUBMIT" | translate }}
        </p-button>
      </div>

    </form>
  </div>
  <app-info-bar></app-info-bar>
</div>
