import {Component, EventEmitter, Output} from '@angular/core';
import {SettingsService} from '../../services/settings-service/settings.service';
import {Observable} from 'rxjs';
import {CrmService} from '../../services/crm-service/crm.service';

@Component({
  selector: 'app-conversation-transfer',
  templateUrl: './conversation-transfer.component.html',
  styleUrls: ['./conversation-transfer.component.scss']
})
export class ConversationTransferComponent {
  @Output() transferRequest = new EventEmitter<TransferRequest>();
  @Output() transferClose = new EventEmitter<void>();

  protected selectedSection: string = '';
  protected sections: Observable<string[]>;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly crmService: CrmService) {
    this.sections = this.crmService.siteSections.asObservable();
  }

  public sendTransferRequest(message: string) {
    const request: TransferRequest = {
      section: this.selectedSection,
      message
    };
    this.transferRequest.emit(request);
  }
}

export interface TransferRequest {
  section: string;
  message: string;
}
