import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrmStructure } from '../../../../services/crm-service/crm-category';
import { CrmService } from '../../../../services/crm-service/crm.service';
import { AlertService, AlertType } from '../../../../services/alert-service/alert.service';
import { CrmEmailStateType, CrmEmailState } from '../../../../services/crm-service/crm-email-state';
import { Opportunity, OpportunityState, OpportunityStatus } from '../../../../services/crm-service/opportunity';
import {faCaretDown, faTrash, faSave, faCaretRight, faPlus, faBullseye} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '../../../../filters/Translate.pipe';

@Component({
  selector: 'app-veestudio-engagement-opportunity',
  templateUrl: './veestudio-engagement-opportunity.component.html',
  styleUrls: ['./veestudio-engagement-opportunity.component.scss'],
  providers: [TranslatePipe]
})
export class VeestudioEngagementOpportunityComponent implements OnInit {
  @Input() set visitorCrmData(data: CrmStructure) {
    if (data && data.list && data.list.length > 0) {
      this.leadId = this.crmService.getCrmFieldValue(this.crmService.extractCustomerData(data), 'Customer Information', 'ID');
    }
  }

  @Input() userGuid: string;
  @Input() emailState: CrmEmailStateType;

  @Input() set opportunities(opportunities: Opportunity[]) {
    if (opportunities) {
      const ops: Opportunity[] = [];
      opportunities.map(opp => this.crmService.getOpportunityById(opp.opportunityId.toString()).subscribe(op => {
        ops.push(op);
      }));

      this.opps = ops;
    }
  }

  @Input() isEditable: boolean;

  @Output() saveOpp: EventEmitter<Opportunity> = new EventEmitter<Opportunity>();

  public opps: Opportunity[] = [];
  public opportunitystatus: OpportunityStatus[];
  public leadId: string;

  faCaretDown = faCaretDown;
  faTrash = faTrash;
  faSave = faSave;

  OpportunityState = OpportunityState;
  CrmEmailState = CrmEmailState;

  constructor(private crmService: CrmService,
    private alertService: AlertService,
    private translate: TranslatePipe) { }

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    const statuses = this.crmService.opportunityStatus.subscribe(s => {
      if (s !== null) {
        this.opportunitystatus = Array.from(s);
      }
    });

    this.subscriptions.push(statuses);
  }

  addOpportunity() {
    this.opps.unshift(new Opportunity());
  }

  clearOppForm(opp: Opportunity) {
    this.opps = this.opps.filter(op => op !== opp );
  }

  saveOpportunity(opp: Opportunity) {
    if (this.validateOpportunity(opp)) {
      this.saveOpp.emit(opp);
    }
   }

  validateOpportunity(opp: Opportunity): boolean {
    if (opp.opportunityTitle === '') {
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_TITLE", 'Please Enter Opportunity Title'), AlertType.Danger);
      return false;
    }
    if (opp.opportunityStatus <= 0) {
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_STATUS", 'Please select Opportunity Status'), AlertType.Danger);
      return false;
    }
    return true;
  }

  isNewOpportunity(opp: Opportunity) {
    if (opp === new Opportunity()) {
      return true;
    } else { return false; }
  }

  protected readonly faPlus = faPlus;
  protected readonly faBullseye = faBullseye;
}
