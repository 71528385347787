
<ng-container *ngIf="isPrimaryAgent; else secondaryAgentNotification">
  <div class="d-flex flex-1 text-center align-items-center justify-content-between main-layout-section surface-red-border overflow-hidden py-1 px-4 border-radius-2 border">
   <p>{{ "ENGAGEMENT_SHARINGCONTROL_TEXT" | Translate : 'You are currently sharing' }}</p>
    <p-button id="stop-sharing-btn"  styleClass=" p-button-sm p-button-danger" (onClick)="onStopSharingClick()">{{ "ENGAGEMENT_SHARINGCONTROL_STOP_SHARING_LABEL" | Translate : 'Stop Sharing' }}</p-button>
  </div>
</ng-container>
<ng-template #secondaryAgentNotification>
  <div class="d-flex flex-1 text-center align-items-center justify-content-between main-layout-section surface-red-border overflow-hidden py-1 px-4 border-radius-2 border">>
    <p>{{ "ENGAGEMENT_SHARINGCONTROL_PRIMARY_AGENT_IS_SHARING_TEXT" | Translate : 'Primary agent is sharing' }}</p>
  </div>
</ng-template>


