import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService, AlertType} from './services/alert-service/alert.service';
import {LoadingService} from './services/loading.service';
import {LoggingService} from './services/logging.service';
import {OnlineService} from './services/online.service';
import { NotificationService } from './services/notification.service';
import {Subscription} from 'rxjs';
import { VersionService } from './services/version-service/version.service';
import { ElectronService } from './services/electron-service/electron.service';
import {LogoutService} from './services/logout.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ELECTRON_ALERT_CODES } from './services/electron-service/electron-constants';
import { TranslatePipe } from './filters/Translate.pipe';
import {PrimeNGConfig} from "primeng/api";
import {ThemeService} from "./services/theme/theme.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public alertId = 0;
  public loading = false;

  faTimes = faTimes;

  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    private logging: LoggingService,
    private primengConfig: PrimeNGConfig,
    private notificationService: NotificationService,
    public readonly electronService: ElectronService,
    private versionService: VersionService,
    private logoutService: LogoutService,
    private translateService: TranslatePipe,
    private themeService: ThemeService
  ) { }

  ngOnInit() {
    this.notificationService.requestPermission();

    if (this.versionService.isElectron) {
      this.electronService.init();

      this.subscriptions.push(
        this.electronService.alertCode$.subscribe(data => {
            switch (data.ALERT_CODE) {
              case ELECTRON_ALERT_CODES.SPELLCHECK_DICTIONARY_DOWNLOAD_FAIL:
                this.alertService.addAlert(this.translateService.transform("SPELLCHECK_DICTIONARY_DOWNLOAD_FAIL", "Spell check not available. Contact your system administrator for help."), AlertType.Danger);
                break;

              case ELECTRON_ALERT_CODES.UNCAUGHT_ERROR:
                if (data.EXTRA) {
                  this.logging.handleError(data.EXTRA);
                }
                this.alertService.addAlert(this.translateService.transform("UNCAUGHT_ERROR", "A fatal error has occured, please restart the app as soon as possible"), AlertType.Danger, 9e9);
                break;
            }
        })
      )

    }

    this.subscriptions.push(
      this.loadingService.isLoading.subscribe(isLoading => {
        this.loading = isLoading;
      })
    );

    this.themeService.init();
    this.primengConfig.ripple = true;
    this.logoutService.autologoutListener();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
