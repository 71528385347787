import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class MockLoadingService {
  public isLoading: Subject<boolean> = new Subject();
}
@Injectable()
export class LoadingService {

  public isLoading: Subject<boolean> = new Subject();

  constructor() { }

}
