<ng-template #tolTemplate>{{titleContent}}</ng-template>

<div class="d-flex flex-column h-100">
  <div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="d-flex flex-column flex-grow-1 overflow-auto">
    <ng-container *ngFor="let message of messages">
      <app-text-chat [message]="message" class="text-message"></app-text-chat>
    </ng-container>
  </div>
  <div class="d-flex flex-row justify-content-end align-items-center p-1">
    <p-button type="button" id="btn-copy-chat"
            styleClass="btn-square center-icon p-button-secondary"
            [pTooltip]="'CHAT_TRANSCRIPT_TOOLTIP_COPY' | Translate : 'Copy'"
            [disabled]="!messages.length"
            (onClick)="copyToClipboard();">
        <fa-icon size="lg" [icon]="faCopy"></fa-icon>
    </p-button>
  </div>
</div>
