    <p-accordion [activeIndex]="[0]" [multiple]="true">

      <ng-container *ngFor="let category of visitorCrmData?.list">
          <p-accordionTab #group
                           [ngStyle]="{'display' : isVisible(category.name) ? 'block' : 'none' }">

            <ng-template pTemplate="header">
              <div class="d-flex flex-row gap-2">
                <div *ngIf="checklistAvailable" [ngSwitch]="getChecklistErrorType(category.name)" class="">
                  <fa-icon *ngSwitchCase="ChecklistFieldType.MANDATORY" size="lg"
                           class="text-danger"
                           [icon]="faTimesCircle">
                  </fa-icon>
                  <fa-icon *ngSwitchCase="ChecklistFieldType.RECOMMENDED" size="lg"
                           class="text-warning"
                           [icon]="faExclamationCircle">
                  </fa-icon>
                  <fa-icon *ngSwitchDefault size="lg"
                           class="text-success"
                           [icon]="faCheckCircle">
                  </fa-icon>
                </div>
                <div class="flex-grow-1">{{category.translatedName}}</div>
              </div>

            </ng-template>
            <ng-container *ngFor="let field of category.fields">
              <app-crm-field-error class="container-fluid"
                                   [ngStyle]="{'display' : isVisible(field.name) ? 'block' : 'none' }"
                                   [crmCheckField]="getCheckField(field)"
                                   [canEditCrm]="canEditCrm"
                                   [field]="field"
                                   [ngSwitch]="field.type">
                <div *ngSwitchCase="CrmField.TYPE_TEXT">

                  <app-crm-field-email *ngIf="field.name === 'Email'"
                                       [field]="field"
                                       [disableCrm]="disableCrm"
                                       (saveField)="saveCrmData()"
                  ></app-crm-field-email>

                  <app-crm-field-phone-number *ngIf="field.name === 'Phone Number'"
                                       [field]="field"
                                       [disableCrm]="disableCrm"
                                       (saveField)="saveCrmData()"
                  ></app-crm-field-phone-number>

                  <app-crm-field-text *ngIf="field.name !== 'Email' && field.name !== 'Phone Number'"
                                       [field]="field"
                                       [disableCrm]="disableCrm"
                                       (saveField)="saveCrmData()"
                  ></app-crm-field-text>
                </div>

                <app-crm-field-note *ngSwitchCase="CrmField.TYPE_NOTE"
                                    [field]="field"
                                    [disableCrm]="disableCrm"
                                    (saveField)="saveCrmData()">
                </app-crm-field-note>
                <app-crm-field-number *ngSwitchCase="CrmField.TYPE_NUMBER"
                                      [field]="field"
                                      [disableCrm]="disableCrm"
                                      (saveField)="saveCrmData()">
                </app-crm-field-number>
                <app-crm-field-datetime *ngSwitchCase="CrmField.TYPE_DATETIME"
                                        [field]="field"
                                        [disableCrm]="disableCrm"
                                        (saveField)="saveCrmData()">
                </app-crm-field-datetime>
                <app-crm-field-externallink *ngSwitchCase="CrmField.TYPE_EXTERNAL_LINK"
                                            [field]="field"
                                            [disableCrm]="disableCrm"
                                            [crmData]="visitorCrmData">
                </app-crm-field-externallink>

                <ng-container *ngIf="this.isElectron">
                    <app-crm-field-localcommand *ngSwitchCase="CrmField.TYPE_LOCAL_COMMAND"
                                                [field]="field"
                                                [disableCrm]="disableCrm"
                                                (saveField)="saveCrmData()">
                    </app-crm-field-localcommand>
                </ng-container>

                <app-crm-field-choice *ngSwitchCase="CrmField.TYPE_CHOICE"
                                      [field]="field"
                                      [disableCrm]="disableCrm"
                                      (saveField)="saveCrmData()">
                </app-crm-field-choice>

                <app-crm-field-typeahead *ngSwitchCase="CrmField.TYPE_TYPEAHEAD"
                                      [field]="field"
                                      [disableCrm]="disableCrm"
                                      (saveField)="saveCrmData()">
                </app-crm-field-typeahead>


              </app-crm-field-error>
            </ng-container>

          </p-accordionTab>
      </ng-container>

    </p-accordion>

