import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {StringUtils} from '../../../utils/string-utils';
import {MenuItem, PrimeIcons} from 'primeng/api';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {Engagement, EngagementAgent} from '../../../services/engagement';
import {EngagementVisitor, UserHeartbeatStates} from "../../../services/engagement-visitor";


@Component({
  selector: 'app-engagement-join-row',
  templateUrl: './engagement-join-row.component.html',
  styleUrl: './engagement-join-row.component.scss'
})
export class EngagementJoinRowComponent implements OnInit, OnChanges {


  @Input() engagement: Engagement;
  @Input() agent: EngagementAgent;
  @Output() kick = new EventEmitter<string>();
  @Output() changePresenter = new EventEmitter<string>();

  get username() {
    return this.agent.username;
  }

  get name() {
    return this.agent.nickname;
  }

  get photo() {
    return this.agent.photo;
  }

  get onlineState() {
     return this.agent.lastUpdatedMS > 15000 ? UserHeartbeatStates.OFFLINE : UserHeartbeatStates.ONLINE;
  }


  public menuItems: MenuItem[] = [];

  protected readonly StringUtils = StringUtils;
  protected readonly faEllipsisV = faEllipsisV;

  public presenterLbl: string;
  public removeLbl: string;

  constructor(private translate: TranslatePipe) {
  }


  ngOnInit() {
    this.presenterLbl = this.translate.transform('ENGAGEMENT_JOIN_ROW_MAKE_PRESENTER', 'Make Presenter');
    this.removeLbl = this.translate.transform('ENGAGEMENT_JOIN_ROW_MAKE_REMOVE', 'Remove from call');
    this.updateMenuItems();
  }

  ngOnChanges() {
      this.updateMenuItems();
  }

  hasVisibleMenuItems(): boolean {
    return this.menuItems.some(item => item.visible);
  }

  getStyleClassForOnlineState() {
    if (this.onlineState === UserHeartbeatStates.ONLINE) {
      return 'surface-green';
    }
    return 'surface-red';
  }

  isPresentingAgent() {
    return this.engagement?.presentingAgent.getValue() === this.username;
  }

  isPrimaryAgent() {
    return this.engagement?.primaryAgent.value === this.username;
  }

  updateMenuItems(): MenuItem[] {

    this.menuItems = [
      {
        id: 'make-presenter-btn',
        label: this.presenterLbl,
        icon: PrimeIcons.DESKTOP,
        command: () => this.changePresenter.emit(this.username),
        visible: this.engagement?.isPrimaryAgent(),
        disabled: this.isPresentingAgent(),
      }
    ];

    const showRemoveFromCall = this.username !== this.engagement?.primaryAgent.getValue() && this.username !== this.engagement?.currentAgent.username;
    if (this.hasVisibleMenuItems() && showRemoveFromCall) {
      this.menuItems.push({separator: true});
    }

    this.menuItems.push({
      id: 'remove-btn',
      label: this.removeLbl,
      icon: PrimeIcons.MINUS_CIRCLE,
      command: () => this.kick.emit(this.username),
      visible: showRemoveFromCall
    });

   return this.menuItems;
  }

}
