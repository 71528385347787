<nav class="navbar navbar-expand">
    <span class="navbar-brand">vee|24</span>

    <div class="d-flex flex-row align-items-center mr-3">
      <span class="active-engagement-indicator rounded-circle ml-2 mr-2"
            [class.red]="!isActiveEngagement">
      </span>
      <span *ngIf="isActiveEngagement; else noEngagement">
        Live
      </span>
      <ng-template #noEngagement>
        <span>Not Live</span>
      </ng-template>
    </div>

    <nav class="menu-btn" *ngIf="currentAgent">
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <p-button (onClick)="menu.toggle($event)" size="small" styleClass="agent-menu-toggle p-button-secondary ml-auto" [text]="true"
                type="button">
        <fa-icon [icon]="faBars"></fa-icon>
      </p-button>
    </nav>
</nav>

