import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

export interface IClipboard {
  copy(text: string);
}

@Injectable({
  providedIn: 'root'
})
export class ClipboardService implements IClipboard {

  constructor(private logging:LoggingService) { }

  public copy(text: string) {

    // Copy using the clipboard API if we have it
    // @ts-ignore
    if (navigator.clipboard) {
      // @ts-ignore
      navigator.clipboard.writeText(text)
        .then(() => {
          this.logging.debug('Successfully copied to clipboard');
        })
        .catch((err) => {
          this.logging.error('Failed to copy to clipboard', err);
        });
    } else {
      // Add new text area and copy the content through that
      const textarea = document.createElement('textarea');

      textarea.textContent = text;
      textarea.style.position = 'fixed';

      document.body.appendChild(textarea);

      textarea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        this.logging.error('Failed to copy to clipboard', err);
      } finally {
        document.body.removeChild(textarea);
      }
    }

  }
}
