import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {EngagementMode} from '../../enums/engagement-mode';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-engagement-mode-selection',
  templateUrl: './engagement-mode-selection.component.html',
  styleUrls: ['./engagement-mode-selection.component.scss'],
  providers: [TranslatePipe]
})
export class EngagementModeSelectionComponent implements OnInit {

  private _engagementMode: EngagementMode = EngagementMode.UNKNOWN;
  private _hasVideo: boolean = false;
  private _chatOnly: boolean = true;

  get engagementMode(): EngagementMode {
    return this._engagementMode;
  }

  @Input() set engagementMode(value: EngagementMode) {
    this._engagementMode = value;
    this.updateActiveMode();
  }

  @Input() set hasVideo(value: boolean) {
    this._hasVideo = value;
    this.updateModes();
  }
  get hasVideo(): boolean {
    return this._hasVideo;
  }

  @Input() set chatOnly(value: boolean) {
    this._chatOnly = value;
    this.updateModes();
  }
  get chatOnly(): boolean {
    return this._chatOnly;
  }

  @Output() changeEngagementMode: EventEmitter<EngagementMode> = new EventEmitter<EngagementMode>();
  public readonly EngagementMode = EngagementMode;

  public modes: MenuItem[] = [];
  public activeMode: MenuItem;

  constructor(private translate: TranslatePipe, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  private updateModes() {
    this.modes = [
      {
        id: 'video-mode-button',
        label: this.translate.transform('ENGAGEMENTMODE_VIDEO_MODE', 'Video Mode'),
        icon: 'pi pi-video',
        command: () => {
          this.changeEngagementMode.emit(EngagementMode.AV);
        },
        disabled: this.chatOnly === true,
        visible: this.hasVideo
      },
      {
        id: 'browser-mode-button',
        label: this.translate.transform('ENGAGEMENTMODE_BROWSER_MODE', 'Browser Mode'),
        icon: 'pi pi-globe',
        command: () => {
          this.changeEngagementMode.emit(EngagementMode.BROWSER);
        },
      },
      {
        id: 'text-mode-button',
        label: this.translate.transform('ENGAGEMENTMODE_TEXT_MODE', 'Text Mode'),
        icon: 'pi pi-comments',
        command: () => {
          this.changeEngagementMode.emit(EngagementMode.TEXT);
        },
      }];

    this.updateActiveMode();
  }

  private updateActiveMode() {
    this.activeMode = this.engagementMode === EngagementMode.AV ? this.modes[0] : this.engagementMode === EngagementMode.BROWSER ? this.modes[1] : this.modes[2];
    this.cdr.markForCheck();
  }

  itemChanged(event: MenuItem) {
    this.activeMode?.command(null);
  }
}
