import {Component, Input, OnInit} from '@angular/core';
import {DeliveryStatus} from '../../../classes/TextMessage';
import {faCheck, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-delivery-status',
  templateUrl: './delivery-status.component.html',
  styleUrls: ['./delivery-status.component.scss']
})
export class DeliveryStatusComponent implements OnInit {
  @Input()
  public deliveryStatus: DeliveryStatus = DeliveryStatus.Unknown;

  @Input()
  public deliveryTimestamp?: Date;

  DeliveryStatus = DeliveryStatus;
  faCheck = faCheck;
  faExclamationCircle = faExclamationCircle;

  constructor() { }

  ngOnInit() {
  }
}

