import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ElectronService } from '../electron-service/electron.service';
import { ElectronCameraProxy, ICameraController } from '../electron-service/electron-camera-proxy';
import { Subscription, Subject, Observable } from 'rxjs';
import { VersionService } from '../version-service/version.service';
import { CameraProperties } from './camera-properties';
import { LoggingService } from '../logging.service';
import { FeatureService, Features } from '../feature-service/feature.service';

@Injectable({
  providedIn: 'root'
})
export class CameraControlsService implements ICameraController, OnDestroy {

  getCameraProxy():ElectronCameraProxy {
    return this.cameraProxy;
  }

  private subs: Subscription[] = [];
  private cameraProxy: ElectronCameraProxy;

  public properties$: Subject<CameraProperties> = new Subject();
  public onQuit$: Subject<boolean> = new Subject();

  constructor(
    private electronService: ElectronService,
    private versionService: VersionService,
    private featureService: FeatureService,
    private logging: LoggingService) {

  }

  get useEnhancedAVControls(): boolean {
    return this.versionService.isElectron && this.featureService.has(Features.ENHANCED_AV_CONTROLS);
  }

  setup(): Observable<boolean> {
    return new Observable<boolean>(observer => {

      if (!this.useEnhancedAVControls) {
        observer.next(false);
        observer.complete();
        return;
      }

      this.cameraProxy = this.electronService.createCameraControllerProxy();
      this.subs.push(
        this.cameraProxy.properties$.subscribe(v => this.handleOnGetProperties(v))
      );

      observer.next(true);
      observer.complete();
    });



  }

  private handleOnGetProperties(propertiesJsonStr: string) {
    try {
      this.logging.debug(`cameraControlService - handleGetProperties: ${propertiesJsonStr}`);
      const propertiesJson = this.getJSON(propertiesJsonStr);

      if (propertiesJson && propertiesJson.result.type === "initialise" && propertiesJson.result.controls) {
        this.buildProperties(propertiesJson.result.controls);
      }
      else {
        throw new Error("Failed to get properties");
      }

    }
    catch (err) {
      this.logging.error(err);
    }
  }

  private getJSON(propertiesJsonStr) {

    let returnJson = null;

    try {

      returnJson = JSON.parse(propertiesJsonStr);

    } catch (err) {

    }
    return returnJson;
  }


  private buildProperties(propertiesJSON) {
    const cameraProperties = new CameraProperties();

    propertiesJSON.forEach(control => {
      cameraProperties.add(control);
    });

    this.properties$.next(cameraProperties);
  }

  init(cameraName: string): void {
    this.cameraProxy.init(cameraName);
  }

  quit(): void {
    this.cameraProxy.quit();
  }

  reset(): void {
    this.cameraProxy.reset();
  }

  getProperties(): void {
    this.cameraProxy.getProperties();
  }

  setProperty(property: string, value: string, flag: string): void {
    this.cameraProxy.setProperty(property, value, flag);
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  public dispose():void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
    this.cameraProxy = null;
  }

}
