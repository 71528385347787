import Quill from 'quill';
import Delta from 'quill-delta';

const Clipboard = Quill.import('modules/clipboard');

export class PlainClipboard extends Clipboard {
    quill: any;

    // Clear out any formatting when the clipboard module is used for pasting.
    // Code is take from
    // https://github.com/quilljs/quill/issues/1184 and the ngx-quill-paste module
    // This overrides the default onPaste browser event. It gets the clipboard
    // data using the plain text formatting then creates a new quill delta
    // consisting of only the plain text.
    //
    // N.B. You can _not_ use the clipboard matcher for this. ngx-quill is (ab)using the
    // clipboard to support angular's binding from the looks of things and the matchers
    // get triggered when using the ngmodel is provides. In our case this causes it to 
    // get applied to the canned chat as well as pasted text, hence doing lower down
    // directly in the onPaste browser event instead.

    onPaste(e: ClipboardEvent): void {
        e.preventDefault();
        
        const range = this.quill.getSelection();
        const text = e.clipboardData.getData('text/plain');

        const delta = new Delta()
                        .retain(range.index)
                        .delete(range.length)
                        .insert(text);

        const index = text.length + range.index;
        const length = 0;

        this.quill.updateContents(delta);
        this.quill.setSelection(index, length, 'silent');
        this.quill.scrollIntoView();
    }
}
