import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bar-meter',
  templateUrl: './bar-meter.component.html',
  styleUrls: ['./bar-meter.component.scss']
})
export class BarMeterComponent implements OnInit {
  @Input() public maxValue = 1.0;
  @Input() public minValue = 0.0;

  @Input() public currentValue = 0.5;

  private static clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  constructor() {

  }

  ngOnInit() {
  }

  createLinearGradientStyle() {
    const style = 'linear-gradient(90deg, #89C64F 0%, ';

    const clamp = BarMeterComponent.clamp(this.currentValue, this.minValue, this.maxValue);
    const percentage = (clamp / (this.maxValue - this.minValue) * 100).toFixed(0);

    return style + '#89C64F ' + percentage + '%, grey ' + percentage + '%)';
  }
}
