import KeyboardCapturer from "./KeyboardCapture";
import MouseCapturer from "./MouseCapture";
import TouchCapturer from "./TouchCapturer";
import MobileKeyboardCapturer from "./MobileKeyboardCapturer";
import EventEmitter from "../EventEmitter";
import Browser from "../Browser";

const INPUT_EVENT = "INPUT_EVENT";

export class InputCapturer extends EventEmitter {
    constructor(captureElement, mobileKeyboardCaptureElement) {
        super();

        if (captureElement == null) {
            throw new Error("Capture element given is falsy");
        }

        if (typeof captureElement.addEventListener != 'function') {
            throw new Error("Capture element can not have event listeners added.");
        }

        this.captureElement = captureElement;

        this.forwardEvent = (event) => this.emit(INPUT_EVENT, event);

        const browser = Browser.details();

        const isTouchDevice = () => !!('ontouchstart' in window);

        // If we are on desktop safari then video elements aren't focused without
        // manually calling focus() even if tabIndex is set (the work around for canvas elements).
        const requiresFocusForwarding = browser.browser === 'safari';

        this.previousState = false;
        this.enabled = false;

        this.captureSystems = [];

        if (isTouchDevice()) {
            // If we are on a touch device we are going to add the touch listeners instead
            // of click listeners and the keypresses are entered using a hidden text input.
            this.touchCapturer = new TouchCapturer(captureElement);
            this.captureSystems.push(this.touchCapturer);
            this.mobileKeyboardCapturer = new MobileKeyboardCapturer(mobileKeyboardCaptureElement, browser);
            this.captureSystems.push(this.mobileKeyboardCapturer);

            this.touchCapturer.on("TOUCH_EVENT", (ev) => this.forwardEvent({type: 'mouse', event: ev}));
            this.touchCapturer.on("TOUCH_EVENT", () => this.mobileKeyboardCapturer.touched());
            this.mobileKeyboardCapturer.on("MOBILE_KEY", (ev) => this.forwardEvent({type: 'key', event: ev}));
            this.mobileKeyboardCapturer.on("MOBILE_TEXT", (text) => this.forwardEvent({type: 'text', event: text}));
        } else {
            if (requiresFocusForwarding) {
                this.captureElement.addEventListener('click', () => {
                    if (document.activeElement != this.captureElement) {
                        this.captureElement.focus();
                    }
                });
            }

            this.mouseCapturer = new MouseCapturer(captureElement);
            this.captureSystems.push(this.mouseCapturer);
            this.keyboardCapturer = new KeyboardCapturer(captureElement);
            this.captureSystems.push(this.keyboardCapturer);

            this.mouseCapturer.on("MOUSE_EVENT", (ev) => this.forwardEvent({type: 'mouse', event: ev}));
            this.keyboardCapturer.on("KEY_EVENT", (ev) => this.forwardEvent({type: 'key', event: ev}));
        }

        const requiresViewportModification = browser.browser == 'chrome android';

        this.viewportHasBeenModified = false;

        this.originalViewport = null;
        try {
            const viewportTag = document.querySelector("meta[name=viewport]");
            this.originalViewport = viewportTag.getAttribute("content");
        } catch (err) {
        }

        this.maybeSetNewViewport = () => {
            if (requiresViewportModification) {
                const height = window.innerHeight;
                const width = window.innerWidth;
                this.setViewport(this.computeNewViewport(height, width));
                this.viewportHasBeenModified = true;
            }
        };

        this.resetViewport = () => {
            if (this.viewportHasBeenModified) {
                this.setViewport(this.originalViewport);
            }
            this.viewportHasBeenModified = false;
        };

        this.setViewport = (newViewport) => {
            const viewportTag = document.querySelector("meta[name=viewport]");
            if (viewportTag && newViewport) {
                viewportTag.setAttribute("content", newViewport);
            }
        };

        this.computeNewViewport = (height, width) => `${this.originalViewport},width=${width},height=${height}`;

        this.onOrientationChangeHandler = () => {
            const resizeListener = () => {
                this.maybeSetNewViewport();
                window.removeEventListener("resize", resizeListener);
            };
            window.addEventListener("resize", resizeListener);
        };
        window.addEventListener("orientationchange", this.onOrientationChangeHandler);
        
        this.maybeSetNewViewport();
    }
    
    onIncomingMessage(message) {
        try {
            switch (message.type) {
                case 'enable':
                    this.enable();
                    break;
                case 'disable':
                    this.disable();
                    break;
                case 'pageChanged':
                    this.pageChanged(message.url);
                    break;
                case 'focusSharing':
                    this.focus();
                    break;
                case 'blurSharing':
                    this.blur();
                    break;
                default:
                    console.log("Unknown message type:" + message.type);
            }
        } catch (err) {
            console.log("Error executing incoming screen share related message");
            console.log(err);
        }
    }

    enable() {
        for (const capturer of this.captureSystems) {
            capturer.enable();
        }
        this.enabled = true;

        this.maybeSetNewViewport();
    }

    disable() {
        for (const capturer of this.captureSystems) {
            capturer.disable();
        }
        this.enabled = false;

        this.resetViewport();
    }

    focus() {
        for (const capturer of this.captureSystems) {
            capturer.focus();
        }
    }

    blur() {
        for (const capturer of this.captureSystems) {
            capturer.blur();
        }
    }

    pause() {
        this.previousState = this.enabled;
        this.disable();
    }

    resume() {
        if (this.previousState) {
            this.enable();
        }
    }

    pageChanged(newUrl) {
        try {
            if (newUrl.indexOf("http") == 0) {
                vee24.api.setCurrentPage(newUrl);
            }
        } catch (err) {
            console.log("Unable to inform of new page url");
            console.log(err);
        }
    }

    dispose() {
        for (const capturer of this.captureSystems) {
            capturer.dispose();
        }
        this.captureSystems = [];

        window.removeEventListener("orientationchange", this.onOrientationChangeHandler);
        this.resetViewport();

        this.off(INPUT_EVENT);
    }
};
