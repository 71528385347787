import { ElectronService } from '../electron-service/electron.service';
import { RecordingProxy } from '../electron-service/recording-proxy';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../settings-service/settings.service';

export interface IRecordingService {
  recordingState$:BehaviorSubject<boolean> ;
  setupRecording(engagementId: string, agentUsername: string);
  startRecording();
  stopRecording();
  pauseRecording(paused: boolean);
  toggle();
  dispose();
}

export class DesktopRecorderConfig {
  public recordingPath: string;
  public recordingTempPath: string;
  public logPath: string;
  public transcodeOnDesktop: boolean;
  public transcodeOnServer: boolean;
  public recordingFrameRate: number;
}

export class DesktopRecordingService implements IRecordingService {

  private engagementId: string;
  private agentUsername: string;
  private recordingProxy: RecordingProxy;

  public recordingState$:BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private electronService: ElectronService, private settingsService: SettingsService) {
  }

  public setupRecording(engagementId: string, agentUsername: string) {

    const machineStoragePath: string = this.settingsService.getResourceOrDefault("MACHINE_STORAGE_PATH", "C:\\vee24\\veestudio");

    const desktopRecordingConfig: DesktopRecorderConfig =  new DesktopRecorderConfig();
    desktopRecordingConfig.recordingPath = this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_RECORDING_PATH", `${machineStoragePath}\\recordings`);
    desktopRecordingConfig.recordingTempPath = this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_TEMP_PATH", "");
    desktopRecordingConfig.logPath = this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_LOG_PATH", `${machineStoragePath}\\log`);
    desktopRecordingConfig.recordingFrameRate = parseInt(this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_FRAMERATE", "15"));
    desktopRecordingConfig.transcodeOnDesktop = this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_TRANSCODE_ON_DESKTOP", "0") === "1";
    desktopRecordingConfig.transcodeOnServer = this.settingsService.getResourceOrDefault("DESKTOP_RECORDING_TRANSCODE_ON_SERVER", "0") === "1";

    this.recordingProxy = this.electronService.createRecordingProxy();
    this.recordingProxy.setup(desktopRecordingConfig);
    this.engagementId = engagementId;
    this.agentUsername = agentUsername;
  }

  public startRecording() {
    this.recordingProxy.start(this.engagementId, this.agentUsername);
    this.recordingState$.next(true);
  }

  public stopRecording() {
    this.recordingProxy.stop();
    this.recordingState$.next(false);
  }

  public toggle() {
    if (this.recordingState$.getValue()) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  public pauseRecording(paused: boolean) {
    if (paused) {
      this.recordingProxy.pause();
    } else {
      this.recordingProxy.resume();
    }
  }

  public dispose() {
    if (this.recordingProxy) {
      this.recordingState$.next(false);
      this.recordingProxy = null;
    }
  }
}
