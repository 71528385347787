import {Component, Input} from '@angular/core';
import {TextMessage} from '../../../classes/TextMessage';
import { SettingsService } from '../../../services/settings-service/settings.service';

@Component({
  selector: 'app-customer-typing-preview',
  templateUrl: './customer-typing-preview.component.html',
  styleUrls: ['./customer-typing-preview.component.scss']
})
export class CustomerTypingPreviewComponent {
  public _message = '';
  public customerName;
  
  @Input() public set preview(message: TextMessage | null) {
    if (message && message.message.length > 0 ) {
      this._message = message.message;
      this.customerName = message.senderName.length > 0 ? message.senderName :  this.settingService.getResourceOrDefault('BROWSER_TYPING_PREVIEW_DEFAULT_CUSTOMER_TITLE', 'Customer');
    } else {
      this._message = '';
    }
  }

  constructor(private readonly settingService: SettingsService) {}
}
