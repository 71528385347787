<div class="d-flex flex-row align-items-center gap-3">
  <ng-container *ngIf="!isAgent">
    <fa-icon tooltipPosition="top" [pTooltip]=" isPrimary ? ('LABEL_PRIMARY_CUSTOMER' | Translate : 'Primary Customer') : ('LABEL_PARTICIPANT' | Translate : 'Participant')"  size="sm" [ngClass]="getIconClass(isPrimary)" [icon]="faStar"></fa-icon>
    <ng-container *ngIf="canAV">
      <fa-icon tooltipPosition="top" [pTooltip]=" userCanSeeMe ? ('LABEL_CAN_SEE_ME' | Translate : 'User Can See Me') : ('LABEL_CANNOT_SEE_ME' | Translate : 'User Cannot See Me')" size="sm" [ngClass]="getIconClass(userCanSeeMe)" [icon]="faEye"></fa-icon>
      <fa-icon tooltipPosition="top" [pTooltip]=" userCanHearMe ? ('LABEL_CAN_HEAR_ME' | Translate : 'User Can Hear Me') : ('LABEL_CANNOT_HEAR_ME' | Translate : 'User Cannot Hear Me')" size="sm" [ngClass]="getIconClass(userCanHearMe)" [icon]="faVolumeUp"></fa-icon>
      <fa-icon tooltipPosition="top" [pTooltip]=" iCanHearUser ? ('LABEL_CAN_HEAR_USER' | Translate : 'I Can Hear User') : ('LABEL_CANNOT_HEAR_USER' | Translate : 'I Cannot Hear User')" size="sm" [ngClass]="getIconClass(iCanHearUser)" [icon]="faMicrophone"></fa-icon>
    </ng-container>
  </ng-container>
  <fa-icon tooltipPosition="top" [pTooltip]=" isPresenter ? ('LABEL_PRESENTING' | Translate : 'Presenting') : ('LABEL_NOT_PRESENTING' | Translate : 'Not Presenting')" size="sm" *ngIf="isAgent" [ngClass]="getIconClass(isPresenter)" [icon]="faShield"></fa-icon>
</div>
