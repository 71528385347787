import { CrmEmail } from "../../utils/crm-email";

export enum EmailState {
  Valid = "",
  Empty = "Empty",
  Incorrect = "Incorrect"
}

export class PostEngagementEmail {
  private _subject: string = '';
  public set subject(subject: string) {
    this._subject = subject;
    this.validateEmailConfiguration();
  }
  public get subject() {
    return this._subject;
  }

  private _subjectError = EmailState.Empty;
  public get subjectError() {
    return this._subjectError;
  }

  private _emailTo: string = '';
  public set emailTo(emailTo: string) {
    this._emailTo = emailTo;
    this.validateEmailConfiguration(); 
  }
  public get emailTo() {
    return this._emailTo;
  }

  private _addressError = EmailState.Empty;
  public get addressError() {
    return this._addressError;
  }

  private _emailMessage: string = '';
  public set emailMessage(emailMessage: string) {
    this._emailMessage = emailMessage;
    this.validateEmailConfiguration();
  }
  public get emailMessage() {
    return this._emailMessage;
  }

  private _messageError = EmailState.Empty;
  public get messageError() {
    return this._messageError;
  }

  public get isValid(): boolean {
    return (this.addressError === EmailState.Valid) 
        && (this.subjectError === EmailState.Valid)
        && (this.messageError === EmailState.Valid);
  }

  constructor(public readonly emailTemplateTitle: string,
              public readonly emailSectionTitle: string,
              public readonly messageDataType: string) {
  }

  private validateEmailConfiguration(): void {
    if (!this.emailTo) {
      this._addressError = EmailState.Empty;
    } else {
      const emailAddresses = this.emailTo.split(',');
      if (emailAddresses.some(address => !CrmEmail.isEmailAddressValid(address))) {
        this._addressError = EmailState.Incorrect;
      } else {
        this._addressError = EmailState.Valid;
      }
    }
    
    if (!this.subject) {
      this._subjectError = EmailState.Empty;
    } else {
      this._subjectError = EmailState.Valid;
    }

    if (!this.emailMessage) {
      this._messageError = EmailState.Empty;
    } else {
      this._messageError = EmailState.Valid;
    }
  }
}