<div class="row pb-1">
  <div class="col-sm">
    <p>
      <ng-container *ngIf="isCheckListError()">
        <fa-icon class="text-danger mr-2"
                 *ngIf="crmCheckField.CheckType === ChecklistFieldType.MANDATORY"
                 [icon]="faTimesCircle">
        </fa-icon>
        <fa-icon class="text-warning mr-2"
                 *ngIf="crmCheckField.CheckType === ChecklistFieldType.RECOMMENDED"
                 [icon]="faExclamationCircle">
        </fa-icon>
      </ng-container>
      {{field.translatedTitle}} <fa-icon size="2xs" class="text-danger" *ngIf="canShowRequired && field.required === '1'" [icon]="faAsterisk"></fa-icon>
    </p>

  </div>

  <div class="col-sm">
    <ng-content></ng-content>
    <ng-container *ngIf="isCheckListError()">
      <p [class.text-danger]="crmCheckField.CheckType === ChecklistFieldType.MANDATORY"
         [class.text-warning]="crmCheckField.CheckType === ChecklistFieldType.RECOMMENDED">
        {{crmCheckField.Message}}
      </p>
    </ng-container>
  </div>
</div>
