import { EventEmitter } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subscription } from "rxjs";
import { StringUtils } from "../../utils/string-utils";
import { AddressStack } from "./address-stack";
import { BrowserConfiguration } from "./browser-configuration";
import { IBrowser } from "./IBrowser";
import { BrowserServiceStatus, IBrowserService } from "./IBrowserService";


export class IFrameBrowserProxy implements IBrowser {
    public onBrowserCreated: ReplaySubject<void> = new ReplaySubject<void>();

    public onResize: EventEmitter<void> = new EventEmitter<void>();

    public onMessage: EventEmitter<any> = new EventEmitter<any>();

    private iframe: HTMLIFrameElement;

    private page: string = "";

    public readonly currentPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private config: BrowserConfiguration;

    private boundingRect: any = {
        top: 100,
        left: 100,
        right: 0,
        bottom: 0,
        height: 0,
        width: 0,
    };
    private clientWidth: number = 0;
    private clientHeight: number = 0;

    private readonly messageListener = (msg) => this.handleMessage(msg);

    private isPresenter: boolean = true;

    private readonly addresses: AddressStack = new AddressStack();

    public get backEnabled() {
        return this.addresses.backEnabled;
    }

    public get forwardEnabled(): boolean {
        return this.addresses.forwardEnabled;
    }

    private addressPageSub: Subscription;

    constructor(identifier: string, private readonly browserService: IBrowserService) {
        this.iframe = document.createElement('iframe');
        this.iframe.id = `ifb-${identifier}` ;
        this.iframe.name = 'vee24AgentFrame';
        this.iframe.style.position = 'absolute';
        this.iframe.style.display = 'none';
        this.iframe.style.zIndex = '1000';

        document.body.appendChild(this.iframe);

        window.addEventListener('message', this.messageListener);

        this.addressPageSub = this.addresses.changePage.subscribe(url => this.navigateToUrl(url));
    }

    create(options: BrowserConfiguration) {
        this.config = options;
        this.onBrowserCreated.next();

        if (this.page == "")
            this.changeUrl(options.initialUrl);
    }

    dispose() {
        window.removeEventListener('message', this.messageListener);

        this.onBrowserCreated.complete();
        this.onResize.complete();
        this.onMessage.complete();
        this.currentPage.complete();

        this.addressPageSub.unsubscribe();

        if (this.iframe) {
            const parent = this.iframe.parentElement;
            if (parent != null) {
                parent.removeChild(this.iframe);
            }
        }

        this.iframe = null;

        this.browserService.setStatus(BrowserServiceStatus.NONE);
    }

    public changeUrl(url: string) {
        if (this.iframe && url && !StringUtils.urlEqual(this.page, url)) {
            this.addresses.addPage(url);
            this.navigateToUrl(url);
        }
    }

    private navigateToUrl(url: string) {
        if (this.iframe && !StringUtils.urlEqual(this.page, url)) {
            this.iframe.src = url;
            this.page = url;

            this.currentPage.next(this.page);
        }
    }

    show() {
        if (this.iframe)
            this.iframe.style.display = "block";
    }

    hide() {
        if (this.iframe)
            this.iframe.style.display = "none";
    }

    setClientDimensions(width: number, height: number) {
        if (this.config && this.config.enableCustomerResize) {
            this.clientWidth = width;
            this.clientHeight = height;
            this.updateIFrameDimensions();
        }
    }

    setPanelDimensions(top: number, left: number, width: number, height: number) {
        // Not implemented;
    }

    enableEmulation(width: number, height: number, deviceScaleFactor: number) {
        // Not implemented
    }

    disableEmulation() {
        // Not implemented
    }

    enable() {
        this.isPresenter = true;
        this.updateCanCoBrowse(this.isPresenter);
    }

    disable() {
        this.isPresenter = false;
        this.updateCanCoBrowse(this.isPresenter);
    }

    postMessage(msg: any, origin: string) {
        if (this.iframe && this.iframe.contentWindow) {
            try {
                this.iframe.contentWindow.postMessage(msg, origin);
            } catch (e) {
                // todo: error logging
            }
        }
    }

    sendBrowserDimensions(boundingRect: ClientRect) {
        this.boundingRect = boundingRect;
        this.updateIFrameDimensions();
    }

    private updateIFrameDimensions() {
        const width = this.clampBounds(this.boundingRect.width, this.clientWidth);
        const height = this.clampBounds(this.boundingRect.height, this.clientHeight);

        if (this.iframe) {
            this.iframe.style.top = `${this.boundingRect.top}px`;
            this.iframe.style.left = `${this.boundingRect.left}px`;
            this.iframe.style.width = `${width}px`;
            this.iframe.style.height = `${height}px`;
        }
    }

    private clampBounds(frameBounds, clientBounds) {
        if (clientBounds === 0) {
          return Math.floor(frameBounds);
        } else {
          return Math.floor(Math.min(frameBounds, clientBounds));
        }
      }

    reload() {
        if (this.iframe)
            this.iframe.src = this.page;
    }

    public back(): void {
        this.addresses.back();
    }

    public forward(): void {
        this.addresses.forward();
    }

    public getIFrame() {
        // Exposed for testing
        return this.iframe;
    }

    private updateCanCoBrowse(presenter: boolean) {
        this.postMessage( {type:"v24AllowedToBrowse", message: presenter} ,"*");
    }

    private handleMessage(e: MessageEvent) {
        if (e.source == this.iframe.contentWindow) {
            if (e.data && e.data.type) {
                switch (e.data.type) {
                    case "v24page":
                        const url = e.data.message;
                        if(url !== this.page) {
                            this.page = url;
                            this.addresses.addPage(this.page);
                            this.currentPage.next(this.page);
                        }
                        break;
                    case "v24Init":
                        this.updateCanCoBrowse(this.isPresenter);
                    break;
                }
            }
        }
    }
}
