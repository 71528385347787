import CallP2P from "./CallP2P";
import Constants from "./Constants";

export default class CallP2PVisitor extends CallP2P {
    constructor({ 
            engagementId = null,
            peerId ='itsthetaste',
            iceServer = null,
            connectPeers = false,
            connectTracks = false,
            peerType = Constants.PeerType.Visitor,
            mainPeerId = 'itsthetaste',
            speakerVolume = 1.0,
            mainVideoContainer = null,
            connectingOverlay = null,
            reConnectingOverlay = null,
            onHoldOverlay = null,
            peersContainer = null
        } = {}) { 

        super({ 
            engagementId,
            peerId,
            iceServer,
            connectPeers,
            connectTracks,
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        this.removeLocalVideoTrack = () => {
            if (this.localVideoTrack != null) {
                this.localVideoTrack.stop();
                this.ui.hideMyPeer();
                this.localStream.removeTrack(this.localVideoTrack);
                this.localVideoTrack = null;                        
            }
        };

        this.addLocalVideoTrack = (options) => {
            if (!this.devices) return;
            this.devices.getUserMedia({ video : this.devices.getVideoSettings(options) })
            .then(stream => {                    
                
                this.devices.devicesAllowed()
                .then(() => {
    
                    this.localStream.getVideoTracks().forEach(track => {                        
                        this.localStream.removeTrack(track);
                        track.stop();
                    });
    
                    this.localVideoTrack = stream && stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                    if (this.localVideoTrack != null) {
                        this.devices.selectCameraByName(this.localVideoTrack.label);
                        this.localStream.addTrack(this.localVideoTrack);
                        if (this.connectTracks) {
                            this.peers.addTrack(this.localVideoTrack,this.localStream);   
                        }                        
                    }                
                    this.ui.addLocalTrack(this.localStream);
                    this.devices.devicesAllowed();
                    this.updateMediaState();
                });
            })
            .catch(this.handleUserMediaError);
        };

        console.log("Created CallP2PVisitor for:" + peerId + " of Type:" + peerType);

    }   

    // visitor specific calls
    startVideo(negotiate, options) {
        console.log("startVideo() negotiate:" + negotiate);
        if (!this.devices) return;
        this.stopVideo(false);
        this.devices.getUserMedia({ video : this.devices.getVideoSettings(options) })
        .then(stream => {                    
            
            this.devices.devicesAllowed()
            .then(() => {

                this.localStream.getVideoTracks().forEach(track => {
                    this.peers.removeTrack(track);
                    this.localStream.removeTrack(track);
                    track.stop();
                });

                this.localVideoTrack = stream && stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                if (this.localVideoTrack != null) {
                    //console.log("LocalTrackCamera is: " + this.localVideoTrack.getSettings().deviceId);
                    this.devices.selectCamera(this.localVideoTrack.getSettings().deviceId);
                    console.log("Selected Camera is: " + this.devices.selectedCamera.deviceId);
                    this.localStream.addTrack(this.localVideoTrack);
                    if (this.connectTracks) {
                        this.peers.addTrack(this.localVideoTrack,this.localStream);   
                    }
                    if (negotiate) {
                        this.peers.negotiate();
                    }
                }
                
                this.ui.addLocalTrack(this.localStream);
                this.devices.devicesAllowed();
                this.updateMediaState();
            });
        })
        .catch(this.handleUserMediaError);        
    }

    startAudio(negotiate) {
        console.log("startAudio() negotiate:" + negotiate);

        const connectPeers = () => {
            if (this.connectTracks) {
                this.peers.addTrack(this.localAudioTrack, this.localStream);
            }
            if (negotiate) {
                this.peers.negotiate();
            }
        };

        if (this.localAudioTrack) {
            connectPeers();
            return;
        }

        this.stopAudio(false);
        
        this.devices.getUserMedia({ audio : this.devices.getAudioSettings() })
        .then(stream => {                    

            this.devices.devicesAllowed()
            .then(() => {

                this.localStream.getAudioTracks().forEach(track => {
                    this.peers.removeTrack(track);
                    this.localStream.removeTrack(track);
                    track.stop();
                });

                this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
                if (this.localAudioTrack != null) {
                    this.devices.selectMicrophoneByName(this.localAudioTrack.label);
                    this.localStream.addTrack(this.localAudioTrack);
                    this.devices.audioMeter.attach(this.localAudioTrack);
                    connectPeers();
                }
                this.devices.devicesAllowed();
                this.updateMediaState();
            });
        })
        .catch(this.handleUserMediaError);
    }

    startAudioVideo(options) {
        // Start the video up if we already have an audio track playing
        // otherwise start the audio and the video
        if (this.localAudioTrack) {
            this.startVideo(true, options);
        } else {
            super.startAudioVideo(options);
        }
    }

    flipCamera(options) {      
        this.removeLocalVideoTrack();
        this.addLocalVideoTrack(options);        
    }

    sendBigVideo(sendBig) {
        var alreadySendingBig = this.devices.bigCamera;

        //console.log(`sendBigVideo ${sendBig} currentState ${alreadySendingBig} same ${alreadySendingBig !== sendBig}`);        
        // if its changed then save into devices
        if (alreadySendingBig !== sendBig && this.localVideoTrack) {
            this.devices.setBigCamera(sendBig);
            this.removeLocalVideoTrack();
            this.addLocalVideoTrack();
        }        
    }

}