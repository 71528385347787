<div class="d-flex flex-row flex-grow-1 h-100">
  <div class="d-flex flex-row flex-grow-1 gap-1">
    <app-visitor-information
      class="w-100 h-100"
      [(currentSection)]="currentSection"
      [engagement]="null"
      [currentUrl]=""
      [currentState]="conversation?.isEnded ? 2 : 0"
      [visitorCrmData]="(conversation?.visitor?.crmData | async)"
      [emailState]="CrmEmailState.Valid"
      [visitor]="conversation?.visitor"
      [checklistfields]="[]"
      [visitorSessionHistory]="(conversation?.visitor?.sessionHistory | async)"
      [visitorBrowsingHistory]="[]"
      [userGuid]="conversation?.userGuid"
      (saveOpp)="doNothing($event)"
      (updatedData)="doNothing($event)"
      (loadOldCustomer)="doNothing($event)">
    </app-visitor-information>

    <div *ngIf="conversation?.Messages?.length" #scrollPublic1 [scrollTop]="scrollPublic1.scrollHeight" class="d-flex flex-column h-100 sidebar-panel overflow-auto">
      <ng-container *ngFor="let message of conversation?.Messages">
        <app-text-chat [message]="message"></app-text-chat>
      </ng-container>
    </div>
  </div>

  <app-supervisor-engagement-controls class="bottom-ctrls"
                                      [privateChatEnabled]="false"
                                      [publicChatEnabled]="false"
                                      [privateChatAvailable]="false"
                                      [publicChatAvailable]="false"
                                      (stopSnooping)="endMonitoring()"
                                      (startPrivateChat)="doNothing($event)"
                                      (startPublicChat)="doNothing($event)">
  </app-supervisor-engagement-controls>
</div>
