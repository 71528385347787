import { Component, OnDestroy, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { OnlineService } from '../../../services/online.service';
import { Features, FeatureService } from '../../../services/feature-service/feature.service';
import {PanelSize} from '../../../services/engagement';

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
})
class ControlBarComponent implements OnInit, OnDestroy {

  public panelSize: PanelSize = PanelSize.Normal;
  public camera$: Observable<MediaDeviceInfo>;
  public mic$: Observable<MediaDeviceInfo>;
  public speaker$: Observable<MediaDeviceInfo>;

  public videoUnavailable: boolean;
  public cameraAllowed: boolean;
  public micAllowed: boolean;

  constructor(
    private onlineService: OnlineService,
    private featureService: FeatureService
  ) {
    this.camera$ = this.onlineService.selectedCamera.asObservable();
    this.mic$ = this.onlineService.selectedMic.asObservable();
    this.speaker$ = this.onlineService.selectedSpeaker.asObservable();
  }

  ngOnInit() {
    this.videoUnavailable = this.featureService.has(Features.NO_VIDEO);
  }

  ngOnDestroy(): void {
  }

  onSelectCamera(newCamera: MediaDeviceInfo) {
    this.onlineService.selectedCamera.next(newCamera);
  }

  onSelectMic(newMic: MediaDeviceInfo) {
    this.onlineService.selectedMic.next(newMic);
  }

  onSelectSpeaker(newSpeaker: MediaDeviceInfo) {
    this.onlineService.selectedSpeaker.next(newSpeaker);
  }

  onCameraAllowed($event: boolean) {
    this.cameraAllowed = !this.videoUnavailable && $event;
  }

  onMicAllowed($event: boolean) {
    this.micAllowed = $event;
  }
}

export { ControlBarComponent };
