<app-engagement-electron-domsync-coviewer *ngIf="useDomSyncElectronWindow"
    class="d-flex flex-column w-100 h-100"
    [useOverlayPanel]="useOverlayPanel"
    [currentPanelPosition]="currentPanelPosition"
    [showBrowser]="showBrowser"
    [url]="domSyncUrl"
    [fromCustomerCommand]="fromCustomerCommand"
    (onReady)="onDomSyncAgentReady($event)"
    (onDomSyncCommandMessage)="onDomSyncToCustomerCommand($event)">
</app-engagement-electron-domsync-coviewer>

<app-iframe-domsync-coviewer
    *ngIf="!useDomSyncElectronWindow"
    class="d-flex flex-column h-100"
    style="width: fit-content;"
    [currentPanelPosition]="(currentPanelPosition | async)"
    [url]="domSyncUrl"
    [fromCustomerCommand]="fromCustomerCommand"
    (onReady)="onDomSyncAgentReady($event)"
    (onDomSyncCommandMessage)="onDomSyncToCustomerCommand($event)">
</app-iframe-domsync-coviewer>
