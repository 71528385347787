import {Injectable} from '@angular/core';
import {Engagement, EngagementConfig, EngagementStartState} from './engagement';
import {LoggingService} from './logging.service';
import {SiteVisitor} from './visitor-service/SiteVisitor';
import {AuthService} from './auth-service/auth.service';
import {BehaviorSubject} from 'rxjs';
import {EngagementHubService} from './engagementhub.service';
import {FileTransferService} from './file-transfer-service/file-transfer.service';
import {TranslationService} from './translation-service/translation.service';
import {FeatureService} from './feature-service/feature.service';
import {AlertService} from './alert-service/alert.service';
import {CrmService} from './crm-service/crm.service';
import {BrowserService} from './browser-service/browser.service';
import {HubVisitor} from './visitor-service/HubVisitor';
import {InviteService} from './invite-service/invite.service';
import {SettingsService} from './settings-service/settings.service';
import {LicenceType} from '../enums/licence-type.enum';
import {CallType} from '../enums/call-type.enum';
import {distinctUntilChanged, filter} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EngagementService {

  private engagementList: Map<string, Engagement> = new Map<string, Engagement>();

  public engagements: BehaviorSubject<Engagement[]>;
  public readonly activeEngagements = new BehaviorSubject<number>(0);

  constructor(
    private fileTransferService: FileTransferService,
    private logging: LoggingService,
    private authService: AuthService,
    private translationService: TranslationService,
    private featureService: FeatureService,
    private alertService: AlertService,
    private crmService: CrmService,
    private browserService: BrowserService,
    private inviteService: InviteService,
    private settingsService: SettingsService,
  ) {
    this.engagements = new BehaviorSubject([]);
    this.authService.currentAgent.pipe(filter(v => v === null)).subscribe(() => {
      this.engagementList.forEach(engagement => {
        this.endEngagement(engagement.engagementId.toString());
        engagement?.agentDisconnected();
      })
    });

  }


  public createSupervisorEngagement(visitor: SiteVisitor, licenceType:LicenceType): Engagement {
    const engagementId = visitor.engagementGuid;

    if (!engagementId || this.engagementList.has(engagementId)) {
      return null;
    }


    const agent = this.authService.currentAgent.value;

    const config: EngagementConfig = {
      visitor: visitor,
      id: engagementId,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(''),
      callType: visitor.callType,
      startState: EngagementStartState.Supervisor,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    return engagement;
  }

  public createTransferEngagement(engagementId: string, newCallType: CallType, visitor: SiteVisitor, turnUri: string, licenceType:LicenceType): Engagement {
    if (this.engagementList.has(engagementId)) {
      return null;
    }

    const agent = this.authService.currentAgent.value;
    const config: EngagementConfig = {
      visitor: visitor,
      id: engagementId,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(turnUri),
      startState: EngagementStartState.Transfer,
      callType: newCallType,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    this.createBrowser(engagement, licenceType);

    return engagement;
  }

  public createJoinEngagement(engagementId: string, visitor: SiteVisitor, licenceType:LicenceType): Engagement {
    if (this.engagementList.has(engagementId)) {
      return null;
    }

    const turnUri = ''; // todo: implement this if we are going to do multiparty webrtc

    const agent = this.authService.currentAgent.value;
    const config: EngagementConfig = {
      visitor: visitor,
      id: engagementId,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(turnUri),
      callType: visitor.callType,
      startState: EngagementStartState.Join,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    this.createBrowser(engagement, licenceType);

    return engagement;
  }

  public createEngagement(engagementId: string, visitor: SiteVisitor, turnUri: string, licenceType:LicenceType): Engagement {
    if (this.engagementList.has(engagementId)) {
      return null;
    }

    const agent = this.authService.currentAgent.value;
    const callType = visitor.callType;

    const config: EngagementConfig = {
      id: engagementId,
      visitor: visitor,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(turnUri),
      callType: callType,
      startState: EngagementStartState.Normal,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    this.createBrowser(engagement, licenceType);

    return engagement;
  }

  public createExistingEngagement(engagementId: string, visitor: SiteVisitor, licenceType:LicenceType): Engagement {
    if (this.engagementList.has(engagementId)) {
      return null;
    }

    const agent = this.authService.currentAgent.value;
    const callType = visitor.callType;

    const config: EngagementConfig = {
      id: engagementId,
      visitor: visitor,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(''),
      callType: callType,
      startState: EngagementStartState.Rejoin,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    this.createBrowser(engagement, licenceType);

    return engagement;
  }

  public createMobilePresenterEngagement(engagementId: string, licenceType:LicenceType) : Engagement {
    if (this.engagementList.has(engagementId)) {
      return null;
    }

    const agent = this.authService.currentAgent.value;
    const callType = CallType.WebRTC;
    const visitor = new SiteVisitor(new HubVisitor()); // just testing here
    const turnUri = '';

    const config: EngagementConfig = {
      id: engagementId,
      visitor: visitor,
      username: agent.username,
      sitename: agent.sitename,
      nickname: agent.nickname,
      photo: agent.photo,
      widephoto: agent.widePhoto,
      textChat: false,
      webrtcIceServer: btoa(turnUri),
      callType: callType,
      startState: EngagementStartState.Presenter,
      feedLocation: agent.feedLocation,
      isPresentationDevice: agent.isPresentationDevice,
      licenceType: licenceType
    };

    const hub = new EngagementHubService(this.logging);
    const engagement = new Engagement(this.fileTransferService, hub, config, this.authService, this.logging, this.translationService, this.featureService, this.alertService, this.crmService, this.inviteService, this.settingsService);
    this.engagementList.set(engagementId, engagement);

    this.engagements.next(Array.from(this.engagementList.values()));
    this.activeEngagements.next(this.engagementList.size);

    return engagement;
  }

  public getEngagement(engagementId: string): Engagement {
    return this.engagementList.get(engagementId);
  }

  public endEngagement(engagementId: string): Promise<string> {
    const engagement = this.engagementList.get(engagementId);

    if (engagement) {
      // remove engagement from the list
      this.engagementList.delete(engagementId);
      this.engagements.next(Array.from(this.engagementList.values()));
      this.activeEngagements.next(this.engagementList.size);
      return Promise.resolve(engagementId);
    } else {
      return Promise.reject('Engagement does not exist.');
    }
  }

  public isEngagedByMe(visitor: SiteVisitor): boolean {
    for (const [_, engagement] of this.engagementList) {
      if (engagement.hasVisitor(visitor.userGuid) && engagement.visitor.sessionGuid === visitor.sessionGuid) {
        return true;
      }
    }

    return false;
  }

  private createBrowser(engagement: Engagement, licenseType: LicenceType): void {
    const engagementId = engagement.engagementId.toString();
    const browser = this.browserService.createBrowser(engagementId, engagement.visitor, licenseType == LicenceType.VeeStudio);
    if (browser) {
      engagement.engagementEnded.subscribe((ended) => ended && this.browserService.destroyBrowser(engagementId) );
      engagement.currentPage.pipe(distinctUntilChanged()).subscribe(page => {
        if (engagement.allowBrowsing.value)
          browser.changeUrl(page);
      });
      browser.currentPage.pipe(distinctUntilChanged()).subscribe(page => engagement.showPage(page));
    }
  }
}
