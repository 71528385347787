import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {CrmField} from '../../../services/crm-service/crm-field';
import {NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {TranslatePipe} from '../../../filters/Translate.pipe'

import {ControlValueAccessorBase} from "../control-value-accessor.base";

@Component({
  selector: 'app-crm-field-choice',
  templateUrl: './crm-field-choice.component.html',
  styleUrls: ['./crm-field-choice.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CrmFieldChoiceComponent),
    multi: true
  }]
})
export class CrmFieldChoiceComponent extends ControlValueAccessorBase<string> implements OnInit {
  private _field: CrmField;

  @Input() set field(field: CrmField) {

    this._field = field;

    const choices = [ this.createFirstChoice()];
    field.choices.forEach(text => choices.push({id: text, text}))
    this.choices = choices;
    if (field && field.value) {
      this.selectControl.setValue(this.field.value, {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: false
      });
    }
  }

  get field(): CrmField {
    return this._field;
  }

  public choices: Array<{id: string, text: string}> = [];

  @Input()
  public selectControl = new UntypedFormControl();

  private disableCrm_: boolean = false;
  @Input() set disableCrm(disableCrm: boolean) {
    this.disableCrm_ = disableCrm;
    if (disableCrm) {
      this.selectControl.disable({ emitEvent: false });
    } else {
      this.selectControl.enable({ emitEvent: false });
    }
  }
  get disableCrm(): boolean {
    return this.disableCrm_;
  }

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  constructor(private readonly translate: TranslatePipe) {
    super();
    this.selectControl.valueChanges.subscribe(value => {
      this.field.value = value;
      this.saveField.emit();
    });
    this.choices = [ this.createFirstChoice() ];
  }

  private createFirstChoice() {
    const placeholder = this.translate.transform("CRMSEARCH_PLACEHOLDER_SELECT", 'Select...');
    return {
      id: '',
      text: placeholder
    };
  }

  ngOnInit() {
  }
}
