import { CrmField, CrmDataField } from './crm-field';

export class CrmStructure {
  list: CrmCategory[];
}

export class CrmCategory {
  name: string;
  translatedName?: string;
  fields: CrmField[];
}

export class CrmData {
  list: CrmDataCategory[];
}

export class CrmDataCategory {
  name: string;
  fields: CrmDataField[];
}
