import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCaretDown, faExclamationCircle, faCheckCircle, faTimesCircle, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CrmField } from '../../services/crm-service/crm-field';
import { CheckListField, ChecklistFieldType } from '../../classes/checklist/CheckListField';
import { EngagementStateType, EngagementState } from '../../services/engagement';
import { Router } from '@angular/router';
import { VersionService } from '../../services/version-service/version.service';
import {CrmStructure} from '../../services/crm-service/crm-category';

@Component({
  selector: 'app-crm-data',
  templateUrl: './crm-data.component.html',
  styleUrls: ['./crm-data.component.scss']
})
export class CrmDataComponent implements OnInit {
  faCaretDown = faCaretDown;
  faCaretRight = faCaretRight;
  faTimesCircle = faTimesCircle;
  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;

  CrmField = CrmField;
  ChecklistFieldType = ChecklistFieldType;

  @Input() currentState: EngagementStateType;
  @Input() visitorCrmData: CrmStructure;
  @Input() canEditCrm: boolean;
  @Input() checklistfields: CheckListField[] = [];

  @Output() saveData: EventEmitter<void> = new EventEmitter<void>();
  public isElectron = false;

  constructor(
    private router: Router,
    private versionService: VersionService
  ) { }

  ngOnInit() {
    this.isElectron = this.versionService.isElectron;
  }

  saveCrmData() {
    this.saveData.emit();
  }

  public isVisible(name: string) {
    if (name && name !== 'Vee24Guid' && name !== 'Appointed Store' && name !== 'Custom Task') {
      return true;
    }
  }

  public get disableCrm() {
    return !this.canEditCrm;
  }

  public getCheckField(field: CrmField) {
    return this.checklistfields?.find(item => item.CRMField === (field.category + ':' + field.name));
  }

  getChecklistErrorType(categoryName: string) {
    if (this.checklistfields?.some(field => !field.IsRulePassed &&
        field.CRMField.split(':')[0] === categoryName &&
        field.CheckType === ChecklistFieldType.MANDATORY)) {
      return ChecklistFieldType.MANDATORY;
    } else if (this.checklistfields?.some(field => !field.IsRulePassed &&
               field.CRMField.split(':')[0] === categoryName &&
               field.CheckType === ChecklistFieldType.RECOMMENDED)) {
      return ChecklistFieldType.RECOMMENDED;
    }
  }

  checklistAvailable() {
    if (this.canEditCrm) {
      if ((this.router.url.startsWith('/veestudio') && this.currentState) || (this.currentState && this.currentState.type === EngagementState.Post)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
