import Browser from "./Browser";
import CallP2PAgent from "./CallP2PAgent";
import CallP2PVisitor from "./CallP2PVisitor";
import EdgeCallP2PVisitor from "./edge/EdgeCallP2PVisitor";
import CallSFUAgent from "./CallSFUAgent";
import CallSFUVisitor from "./CallSFUVisitor";

const CallType = Object.freeze({
    P2P:        "p2p",
    SFU:        "sfu"
});

const ParticipantType = Object.freeze({
    AGENT:      "agent",
    VISITOR:    "visitor"
});

export default class CallFactory {
    constructor(callType, participantType, props) {
        switch (callType) {
            case CallType.P2P:
                switch (participantType) {
                    case ParticipantType.AGENT:
                        return new CallP2PAgent(props);
                    case ParticipantType.VISITOR:
                        return (Browser.details().browser === "edge") ? new EdgeCallP2PVisitor(props) : new CallP2PVisitor(props);
                }
                break;
            case CallType.SFU:
                switch (participantType) {
                    case ParticipantType.AGENT:
                        return new CallSFUAgent(props);
                    case ParticipantType.VISITOR:
                        return new CallSFUVisitor(props);
                }
                break;
        }
    }

    static get CallType() {
        return CallType;
    }

    static get ParticipantType() {
        return ParticipantType;
    }

};