<section class="p-1 d-flex flex-column h-100 gap-1">

  <p-fieldset>
    <div class="d-flex flex-column align-items-start justify-content-start gap-3">
      <div class="d-flex flex-row w-100 justify-content-between align-items-center">
        <p>{{'CUSTOMERAVCONTROL_LABEL_CONTROLS' | Translate : 'Controls'}}</p>
        <div class="p-buttonset">
          <p-button *ngIf="!videoUnavailable" [disabled]="disabled"  [severity]="customerVideoOn ? 'primary' : 'secondary'"   (onClick)="toggleCustomerVideo()">
            <fa-icon [fixedWidth]="true" class="fw-fa" *ngIf="customerVideoOn" size="lg" [icon]="faVideo"></fa-icon>
            <fa-icon [fixedWidth]="true" class="fw-fa" *ngIf="!customerVideoOn" size="lg"  [icon]="faVideoSlash"></fa-icon>
          </p-button>

          <p-button  [disabled]="disabled"  [severity]="customerMicOn ? 'primary' : 'secondary'" (onClick)="toggleCustomerMic()">
            <fa-icon [fixedWidth]="true" class="fw-fa" *ngIf="customerMicOn" size="lg" [icon]="faMicrophone"></fa-icon>
            <fa-icon [fixedWidth]="true" class="fw-fa" *ngIf="!customerMicOn" size="lg"  [icon]="faMicrophoneSlash"></fa-icon>
          </p-button>

          <p-button *ngIf="!isMobileSdk"  [disabled]="disabled || isMobile"  [severity]="customerCogOn ? 'primary' : 'secondary'"  (onClick)="toggleCustomerCog()">
            <fa-icon [fixedWidth]="true" class="fw-fa" size="lg" [icon]="faCog"></fa-icon>
          </p-button>

        </div>
      </div>
      <div class="d-flex flex-row w-100 justify-content-between align-items-center" *ngIf="isMobileSdk">
        <p>{{'CUSTOMERAVCONTROL_LABEL_SELECT_CAM' | Translate : 'Camera'}}</p>
        <div class="p-buttonset">
          <p-button (onClick)="changeCameraDirection.emit(CameraDirection.FRONT)" [severity]="cameraDirection === CameraDirection.FRONT? 'primary' : 'secondary'">
            {{ "CUSTOMERAVCONTROL_LABEL_FRONT_CAM" | Translate: "Front" }}
          </p-button>

          <p-button (onClick)="changeCameraDirection.emit(CameraDirection.REAR)" [severity]="cameraDirection === CameraDirection.REAR? 'primary' : 'secondary'">
            {{ "CUSTOMERAVCONTROL_LABEL_REAR_CAM" | Translate: "Rear" }}
          </p-button>

        </div>
      </div>

      <section class="d-flex align-items-center w-100">
        <p-button styleClass="p-button-text" [severity]="speakerVolume >  0 ? 'primary' : 'secondary'" [disabled]="disabled"  (onClick)="toggleSpeakerMute()">
            <fa-icon [fixedWidth]="true" size="lg" [icon]="speakerVolume > 0 ? faVolumeUp : faVolumeMute"></fa-icon>
        </p-button>
        <div class="d-flex flex-grow-1 h-100 ml-3">
          <input type="range" min="0" max="100" class="micSlider" id="myRange" [value]="speakerVolume"
                 (change)="setVolume($event)">
        </div>
      </section>
    </div>
  </p-fieldset>
  <p-fieldset *ngIf="avControlAvailable" [legend]="'CUSTOMERAVCONTROL_LABEL_AVSTATUS' | Translate : 'Customer A/V Status'" [toggleable]="true">
    <table class="table table-fixed table-borderless text-center">
      <thead>
      <tr>
        <th></th>
        <th><p>{{ "CUSTOMERAVCONTROL_LABEL_CAMERASTATUS"  | Translate : "Camera Status" }}</p></th>
        <th><p>{{ "CUSTOMERAVCONTROL_LABEL_MICROPHONESTATUS"  | Translate : "Customer Microphone" }}</p></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ "CUSTOMERAVCONTROL_LABEL_ACCESSGRANTED"  | Translate : "Access Granted" }}</td>
        <td><fa-icon [fixedWidth]="true" [class]="cameraAccessGranted ? 'surface-green-text' : 'surface-red-text'" size="xl" [icon]="cameraAccessGranted ? faCircleCheck : faTimesCircle"></fa-icon></td>
        <td><fa-icon [fixedWidth]="true" [class]="micAccessGranted ? 'surface-green-text' : 'surface-red-text'" size="xl" [icon]="micAccessGranted ? faCircleCheck : faTimesCircle"></fa-icon></td>
      </tr>
      <tr>
        <td>{{ "CUSTOMERAVCONTROL_LABEL_ALREADYINUSE"  | Translate : "Already In Use" }}</td>
        <td><fa-icon [fixedWidth]="true" [class]="cameraAlreadyInUse ? 'surface-green-text' : 'surface-red-text'" size="xl" [icon]="cameraAlreadyInUse ? faCircleCheck : faTimesCircle"></fa-icon></td>
        <td><fa-icon [fixedWidth]="true" [class]="micAlreadyInUse ? 'surface-green-text' : 'surface-red-text'" size="xl" [icon]="micAlreadyInUse ? faCircleCheck : faTimesCircle"></fa-icon></td>
      </tr>
      </tbody>
    </table>
  </p-fieldset>

</section>

<!-- Use icons instead of english -->
