import { PanelPositionMethod } from '../electron-service/panel-position-method';
import { BrowserTypes } from './browser-types';


export class BrowserConfiguration {

  public static readonly GUID_UNDEFINED: string = "00000000-0000-0000-0000-000000000000";

  guid: string = BrowserConfiguration.GUID_UNDEFINED;
  maxWidth: number = 0;
  maxHeight: number = 0;
  agentDpi: number = 1;
  emulateDevice: boolean = false;
  showPanelOverlay: boolean = false;
  useTouchEmulation: boolean = true;
  overlayPanelPositionMethod: PanelPositionMethod = PanelPositionMethod.OffsetFromCenter;
  deviceScaleFactor: number = 1;
  title: string = "cobrowse";
  type: BrowserTypes = BrowserTypes.none;
  initialUrl: string = "about:blank";
  initialBounds: any = {x: 0, y: 0, width: 0, height: 0};
  enableCustomerResize: boolean;
}
