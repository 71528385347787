import {Pipe, PipeTransform} from '@angular/core';
import {SettingsService} from '../services/settings-service/settings.service';


@Pipe({
  name: 'Translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(public settingsService: SettingsService) { }

  transform(value: string, defaultValue: string): string {
    return this.settingsService.getResourceOrDefault(value, defaultValue);
  }
}

