import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  faArrowRightToBracket,
  faBan, faCircle,
  faHand,
  faPause,
  faRightLeft,
  faTimesCircle,
  faUsers, faXmark
} from '@fortawesome/free-solid-svg-icons';
import {Features, FeatureService} from '../../../../../services/feature-service/feature.service';
import { TranslatePipe } from '../../../../../filters/Translate.pipe';
import {EngagementState} from '../../../../../services/engagement';
import {VerifyStatuses} from '../../../../../services/engagementhub.service';

@Component({
  selector: 'app-engagement-bottom-controls',
  templateUrl: './engagement-bottom-controls.component.html',
  styleUrls: ['./engagement-bottom-controls.component.scss'],
  providers: [TranslatePipe]
})
export class EngagementBottomControlsComponent implements OnInit {

  public get isPostEngagement() {
    return this.currentState === EngagementState.Post;
  }

  public get isJoin() {
    return this.currentState === EngagementState.Join;
  }

  public get isTransfer() {
    return this.currentState === EngagementState.Transfer;
  }
  public get multipartyAvailable(): boolean {
    return !this.isMobileSDK && this.hasMultiparty;
  }

  constructor(private readonly featureService: FeatureService, private translate: TranslatePipe) {
  }
  @Input() isPrimaryAgent: boolean;
  @Input() roomAgentsSize: number;
  @Input() roomVisitorsSize: number;

  @Input() isMobileSDK = false;

  @Input() canPause = true;
  @Input() callPaused: boolean;
  @Input() isRecording: boolean;
  @Input() isBlockedOn: boolean;
  @Input() currentState: EngagementState;
  @Input() requestingHelp: boolean;
  @Input() privateChatAvailable: boolean;

  @Input() hasCustomerVerification: boolean;
  @Input() verifyStatus: string = VerifyStatuses.Pending;
  @Output() toggleVerifyCustomer = new EventEmitter();

  @Output() endChat = new EventEmitter();
  @Output() pauseCall = new EventEmitter();

  @Output() toggleJoinCall = new EventEmitter();
  @Output() toggleTransferCall = new EventEmitter();
  @Output() toggleBlockCustomer = new EventEmitter<any>();
  @Output() toggleHelpRequest = new EventEmitter<void>();
  @Output() toggleRecording = new EventEmitter();

  public faTimesCircle = faTimesCircle;

  private hasMultiparty = false;

  public transferAvailable: boolean;
  public desktopRecordingAvailable: boolean;
  public desktopRecordingAlways: boolean;
  public desktopRecordingToggle: boolean;

  protected readonly faHand = faHand;
  protected readonly faUsers = faUsers;
  protected readonly faRightLeft = faRightLeft;
  protected readonly faPause = faPause;
  protected readonly faBan = faBan;
  protected readonly faArrowRightToBracket = faArrowRightToBracket;
  protected readonly faXmark = faXmark;
  protected readonly faCircle = faCircle;

  ngOnInit() {
    this.transferAvailable = true;
    this.desktopRecordingAlways = this.featureService.has(Features.DESKTOP_RECORDING_ALWAYS);
    this.desktopRecordingToggle = this.featureService.has(Features.DESKTOP_RECORDING_TOGGLE);
    this.hasMultiparty = true;
    this.desktopRecordingAvailable = this.isPrimaryAgent && (this.desktopRecordingAlways || this.desktopRecordingToggle);
    this.hasCustomerVerification = this.isPrimaryAgent && this.featureService.has(Features.VERIFY_CUSTOMER);
  }

  toggleDesktopRecording() {
    if (this.isPrimaryAgent) {
      this.toggleRecording.emit();
    }
  }

  togglePauseCall() {
    if (this.isPrimaryAgent) {
      this.pauseCall.emit();
    }
  }

  joinEngagement() {
    this.toggleJoinCall.emit();
  }

  transferEngagement() {
    if (!this.roomIsFull()) {
      this.toggleTransferCall.emit();
    }
  }

  roomIsFull() {
    return this.roomAgentsSize >= 2 || this.roomVisitorsSize >= 2;
  }

  endEngagement() {
    this.endChat.emit();
  }

  blockCustomer() {
    this.toggleBlockCustomer.emit();
  }

  helpRequest() {
    this.toggleHelpRequest.emit();
  }

  verifyCustomer() {
    this.toggleVerifyCustomer.emit();
  }
}
