import {Injectable, OnInit} from '@angular/core';

const STORAGE_KEY = 'v24-theme'

export enum Themes {
  DARK = 'dark',
  LIGHT = 'light'
};

type Theme = Themes.DARK | Themes.LIGHT;

@Injectable({
  providedIn: 'root'
})
export class ThemeService  {


  get activeTheme() {
    return localStorage.getItem(STORAGE_KEY) ?? Themes.LIGHT;
  }

  init() {
    try {
      const storedTheme = localStorage.getItem(STORAGE_KEY) as Theme;
      if (storedTheme) {
        this.setTheme(storedTheme);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  getTheme() {
    return this.activeTheme;
  }

  setTheme(theme:Theme) {
    const themeLink = document.getElementById('app-theme') as HTMLLinkElement;
    themeLink.href = `${theme}.css`;
    localStorage.setItem(STORAGE_KEY, theme);
  }
}
