<div class="d-flex flex-column h-100 border-0 px-1">
  <section class="py-2">
    <div class="form-group mb-2">
      <p-dropdown [filter]="(statuses | async)?.length > 10" [ngModel]="postEngagementStatus.status" [options]="statuses | async" placeholder="{{'ENGAGEMENTPOST_HEADER_STATUS' | Translate:'Please select'}}" (onChange)="setStatus($event.value)" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group mr-auto mb-0">
      <p-dropdown [filter]="subStatuses?.length > 10" [ngModel]="postEngagementStatus.substatus" [options]="subStatuses" placeholder="{{'ENGAGEMENTPOST_HEADER_STATUS' | Translate:'Please select'}}" [disabled]="!postEngagementStatus.status" (onChange)="setSubStatus($event.value)" appendTo="body"></p-dropdown>
    </div>
  </section>

  <div class="form-group mb-0 flex-grow-1 position-relative">
    <textarea pInputTextarea class="w-100 session-comments-text-field d-block p-2 h-100" name="" id="" cols="30" rows="10"
              placeholder='{{ "ENGAGEMENTPOST_PLACEHOLDER_TYPESESSIONCOMMENT" | Translate : "Type Session Comments Here" }}'
              [(ngModel)]="postEngagementStatus.notes"
              onfocus="this.placeholder = ''"
              onblur="this.placeholder = 'Type Session Comments Here'"></textarea>
  </div>
  <div class="d-flex flex-row justify-content-end align-items-center pt-2">
    <p-button appDebounceClick type="submit" styleClass="btn-square p-button-success center-icon" (clicked)="submit()">
        <fa-icon [icon]="faSave" size="lg" />
    </p-button>
  </div>
</div>
