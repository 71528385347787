import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {faMobileAlt, faSearch, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CrmContact} from '../../../services/crm-service/crm-contact';
import {Opportunity, OpportunityStatus} from '../../../services/crm-service/opportunity';
import {CrmService} from '../../../services/crm-service/crm.service';
import {sortData} from "../../../utils/client-side-sorting";
import {ISortObject, SortFactoryService} from "../../../services/sort-factory-service/sort-factory.service";
import {CrmComponent} from "../crm.component";
import {MenuItem} from "primeng/api";
import {TranslatePipe} from "../../../filters/Translate.pipe";

@Component({
  selector: 'app-crm-search',
  templateUrl: './crm-search.component.html',
  styleUrls: ['./crm-search.component.scss']
})
export class CrmSearchComponent implements OnInit, OnDestroy {
  faMobileAlt = faMobileAlt;
  faUserPlus = faUserPlus;
  faSearch = faSearch;

  @Output() addCustomer: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedUser: EventEmitter<CrmContact> = new EventEmitter<CrmContact>();
  @Output() selectedOportunity: EventEmitter<Opportunity> = new EventEmitter<Opportunity>();

  @Input() searchCustomer: Boolean;

  public searchModes:MenuItem[] = [
    {
      label: this.translate.transform('CRMSEARCH_LABEL_CUSTOMER', 'Customer'),
      icon: 'pi pi-user',
      command: () => {
        this.searchCustomer = true;
        this.activeItem = this.searchModes[0];
      }
    },
    {
      label: this.translate.transform('CRMSEARCH_LABEL_OPPORTUNITY', 'Opportunity'),
      icon: 'pi pi-shopping-cart',
      command: () => {
        this.searchCustomer = false;
        this.activeItem = this.searchModes[1];
      }
    }
  ];


  public activeItem:MenuItem = this.searchModes[0];

  public cName = '';
  public cEmail = '';
  public cPhone = '';
  public startDate: string;
  public endDate: string;

  public cOpTitle = '';
  public cOpStatus: number = 0;

  public opportunitystatus: OpportunityStatus[];
  private subscriptions: Subscription[] = [];

  public loading: boolean;
  public count: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public pageNumber: number;
  public rows: BehaviorSubject<CrmContact[]> = new BehaviorSubject<CrmContact[]>([]);

  public opcount: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public oppageNumber: number;
  public oprows: BehaviorSubject<Opportunity[]> = new BehaviorSubject<Opportunity[]>([]);


  private crmSort: ISortObject<CrmContact>;
  private oppSort: ISortObject<Opportunity>;

  constructor(
    private crmService: CrmService,
    private sortFactory: SortFactoryService,
    private translate: TranslatePipe,
    ) { }

  ngOnInit() {

    this.crmSort = this.sortFactory.getSortObject<CrmContact>();
    this.oppSort = this.sortFactory.getSortObject<Opportunity>();

    const statuses = this.crmService.opportunityStatus.subscribe(status => {
      if (status != null) {
        this.opportunitystatus = Array.from(status)
      }
    });
    this.subscriptions.push(statuses);
    this.oppageNumber = 1;
    this.changeSelection(this.searchCustomer);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  public filterData() {
    this.setPage({offset: 0});
  }

  public setPage(pageInfo) {
    this.loading = true;
    this.pageNumber = pageInfo.offset;
    this.subscriptions.push(
    this.crmService.filterContactData(this.cName, this.cEmail, this.cPhone, 30, this.pageNumber + 1, this.startDate, this.endDate)
      .subscribe(pagedData => {
        const res = pagedData.contactlist.map(contact => new CrmContact(contact));
        const data =this.crmSort.sortData(res);
        this.rows.next(data);
        this.count.next(pagedData.total);
        this.loading = false;
      }));
  }

  onLazyLoad($event) {
    this.setPage({offset: $event.first / $event.rows});
  }


  onAddCustomer() {
    this.addCustomer.emit();
  }

  onSelectUser(event) {
    this.selectedUser.emit(event.data);
  }

  onOpActivate(event) {
    this.selectedOportunity.emit(event.data);
  }

  changeSelection(searchC: Boolean) {
    this.searchCustomer = searchC;
    if (searchC) {
      this.filterData();
    } else {
      this.filterOpportunityData();
    }
  }

  public filterOpportunityData() {
    this.loading = true;
    this.subscriptions.push(
    this.crmService.filterOpportunityData("",this.cName,this.cOpTitle,this.cOpStatus).subscribe(pagedData => {
      this.oprows.next(this.oppSort.sortData(pagedData));
      this.opcount.next(pagedData.length);
      this.loading = false;
    }));
  }

  public phoneCustomer($event: MouseEvent/*, rowIndex: number*/): void {
    $event.stopPropagation(); // Prevent navigation to the user contact screen
    //commenting it out for now, could be useful in the future but hasnt been hooked up since removal of veemessenger
    //
    // const contact = this.rows.value[rowIndex];
    //
    // this.crmService.engageWithUser({
    //   method: EngageUserMethod.Phone,
    //   contact: contact
    // }).pipe(catchError(err => {
    //     this.alertService.addAlert(err, AlertType.Danger);
    //     return of('');
    // })).subscribe();
  }

  public validPhoneNumber(customerPhone: string): boolean {
    return CrmService.isValidPhoneNumber(customerPhone);
  }


  onSort(event: any) {
    this.crmSort.setSortField(event.field);
   // this.filterData();
  }

  onSortOpportunities(event: any) {
    this.oppSort.setSortField(event.field);
    this.filterOpportunityData();
  }
}
