import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import {IFileUpload} from '../../services/file-transfer-service/file-upload-interface';
import {TransferState} from '../../services/file-transfer-service/file-upload';
import { FileTransferService } from '../../services/file-transfer-service/file-transfer.service';

@Component({
  selector: 'app-file-transfer',
  templateUrl: './file-transfer.component.html',
  styleUrls: ['./file-transfer.component.scss']
})
export class FileTransferComponent implements OnInit {
  faFile = faFile;
  faTrash = faTrash;
  TransferState = TransferState;
  
  readonly maxFileSizeLimit: string;

  @Input() files: IFileUpload[] = [];
  @Output() cancelFileTransfer = new EventEmitter<IFileUpload>();

  constructor(private fileTransferService: FileTransferService) { 
    this.maxFileSizeLimit = this.fileTransferService.maxFileSizeLimit();
  }

  ngOnInit() {
  }

  cancelTransfer(file: IFileUpload) {
    this.cancelFileTransfer.emit(file);
  }
}
