<div class="d-flex flex-row gap-2 w-100 h-100">
  <app-control-bar class="sidebar-panel p-0"></app-control-bar>
  <div [class.split-view]="selectedSessionGuid != null"
       class="d-flex flex-column call-list main-layout w-100 h-100">

    <app-onbreak (endBreak)="onEndBreak()"
                 *ngIf="(currentState$ | async) === OnlineState.OnBreak && !(noInternet | async)" class="h-100 w-100"></app-onbreak>

    <app-no-internet id="no-internet-container" *ngIf="(noInternet | async)" class="h-100"></app-no-internet>

    <div *ngIf="(currentState$ | async) !== OnlineState.OnBreak && !(noInternet | async)" class="d-flex flex-column justify-content-between h-100">

      <app-call-list-table
        (acceptCall)="accept($event)"
        (selectCall)="selectVisitor($event)"
        (selectJoinInvite)="showJoinAccept($event)"
        (selectTransferInvite)="showTransferAccept($event)"
        [invites]="(invites$ | async)"
        [selectedSessionGuid]="selectedSessionGuid"
        [transfers]="(transfers$ | async)"
        [visitors]="(visitors$ | async)"
      ></app-call-list-table>

      <app-visitor-information
        class="main-layout"
        (closeRecord)="closeRecord()"
        *ngIf="selectedSessionGuid != null"
        [hidden]="false"
        [userGuid]="(selectedUserGuid)"
        [visitorBrowsingHistory]="(visitorBrowsingHistory$ | async)"
        [visitorCrmData]="(visitorCRMData$ | async)"
        [visitor]="(selectedVisitor$ | async)"
        [visitorSessionHistory]="(visitorSessionHistory$ | async)">
      </app-visitor-information>

    </div>
  </div>
</div>


<app-call-list-bottom-controls></app-call-list-bottom-controls>

