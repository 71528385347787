import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {SettingsService} from '../../services/settings-service/settings.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PostEngagementStatus} from '../../classes/post-engagement.status';
import {faSave} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-status',
  templateUrl: './engagement-status.component.html',
  styleUrls: ['./engagement-status.component.scss'],
  providers: [TranslatePipe]
})
export class EngagementStatusComponent {
  private postEngagementStatus_: PostEngagementStatus;
  @Input() set postEngagementStatus(postEngagementStatus: PostEngagementStatus) {
    this.postEngagementStatus_ = postEngagementStatus;
    this.postEngagementStatusChange.emit(postEngagementStatus);

    if (this.postEngagementStatus_.status) {
      const allStatuses = this.settingsService.postEngagementStatuses;
      this.subStatuses = allStatuses.value.get(this.postEngagementStatus_.status);
    }

    this.checklistChange();
  }
  get postEngagementStatus(): PostEngagementStatus {
    return this.postEngagementStatus_;
  }
  @Output() public postEngagementStatusChange = new EventEmitter<PostEngagementStatus>();

  @Output() submitPost = new EventEmitter<PostEngagementStatus>();

  @Output() checkListName = new EventEmitter<string>();

  public statuses: Observable<string[]>;
  public subStatuses: string[] = [];

  constructor(
    private settingsService: SettingsService,
    private translate: TranslatePipe,
  ) {
    this.postEngagementStatus = {
      status: '',
      substatus: '',
      notes: '',
    };
  }

  ngOnInit() {
    const allStatuses = this.settingsService.postEngagementStatuses;
    this.statuses = allStatuses.pipe(map(s => Array.from(s.keys())));
  }

  submit() {
    this.submitPost.emit(this.postEngagementStatus);
  }

  setStatus(status: string) {
    this.postEngagementStatus.status = status;
    this.postEngagementStatus.substatus = '';

    const allStatuses = this.settingsService.postEngagementStatuses;
    this.subStatuses = allStatuses.value.get(status);

    this.postEngagementStatusChange.emit(this.postEngagementStatus);
    this.checklistChange();
  }

  setSubStatus(subStatus: string) {
    this.postEngagementStatus.substatus = subStatus;
    this.postEngagementStatusChange.emit(this.postEngagementStatus);
    this.checklistChange();
  }

  private checklistChange() {
    this.checkListName.emit(this.postEngagementStatus.status + ' ' + this.postEngagementStatus.substatus);
  }

  protected readonly faSave = faSave;
}
