import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FulfillmentMarketingMaterial } from '../../classes/fulfillment/FulfillmentMarketingMaterial';
import { Observable } from 'rxjs';
import { InsertFulfillmentRequest } from '../../classes/fulfillment/InsertFulfillmentRequest';

export interface IFulfillmentBackendService {
  getMarketingMaterial(authToken: string): Observable<FulfillmentMarketingMaterial[]>;
  insertFulfillment(authToken: string, InsertFulfillmentRequest:InsertFulfillmentRequest): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class FulfillmentBackendService implements IFulfillmentBackendService {

  constructor(
    private http: HttpClient
  ) { }

  getMarketingMaterial(authToken: string): Observable<FulfillmentMarketingMaterial[]> {
    const url = `${environment.fulfillmentRoot}/getmarketingmaterial`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      withCredentials: true,
    };

    return this.http.get<FulfillmentMarketingMaterial[]>(
      url,
      httpOptions
    );
  }

  insertFulfillment(authToken: string, InsertFulfillmentRequest:InsertFulfillmentRequest): Observable<boolean>  {
    const url = `${environment.fulfillmentRoot}/insertfulfillment`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<boolean>(
      url,
      InsertFulfillmentRequest,
      httpOptions
    );
  }
}
