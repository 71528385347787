import { Component, OnInit } from '@angular/core';
import {faExclamation, faSlash, faTriangleExclamation, faWarning, faWifi} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  protected readonly faWifi = faWifi;
  protected readonly faTriangleExclamation = faTriangleExclamation;
}
