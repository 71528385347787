<div class="d-flex flex-column justify-content-center text-center align-items-center w-100 h-100 gap-2 user-select-none main-layout">
  <h1>{{ "NOINTERNET_HEADER_NOTCONNECTED"  | Translate : 'You are not connected' }}</h1>
  <p class="lead mb-0"> {{ "NOINTERNET_HEADER_CANTTAKECALLS"  | Translate : 'You cannot take calls when disconnected' }}</p>

  <fa-layers class="mt-4 fa-fw" size="8x">
    <fa-icon [icon]="faWifi"></fa-icon>
    <fa-icon [icon]="faTriangleExclamation" transform="shrink-8 down-4 right-9" class="text-danger"></fa-icon>
  </fa-layers>



</div>
