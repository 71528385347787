import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth-service/auth.service';
import {Subscription} from 'rxjs';
import {VersionService} from '../../services/version-service/version.service';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss']
})
export class InfoBarComponent implements OnInit, OnDestroy {
  private title_: string = '';
  @Input() set title(title: string) {
    this.title_ = title;
    this.updateInfoString();
  }
  get title(): string {
    return this.title_;
  }

  public info: string = '';

  private sub: Subscription;

  private sitename: string = '';
  private username: string = '';
  private version: string = '';
  private date: string = '';

  private dateChangeTimeout = -1;
  private evTimeout = -1;

  constructor(
    private readonly authService: AuthService,
    versionService: VersionService
  ) {
    if (versionService.isElectron) {
      this.electronVersion(versionService);
    } else {
      this.version = versionService.version;
    }
  }

  ngOnInit() {
    this.handleDateChange();

    this.sub = this.authService.currentAgent.subscribe(next => {
      if (next) {
        this.sitename = next.sitename;
        this.username = next.username;
      } else {
        this.sitename = '';
        this.username = '';
      }
      this.updateInfoString();
    });

    this.updateInfoString();
  }

  ngOnDestroy() {
    window.clearTimeout(this.dateChangeTimeout);
    window.clearTimeout(this.evTimeout);
    this.dateChangeTimeout = -1;
    this.evTimeout = -1;
    this.sub?.unsubscribe();
  }

  private electronVersion(versionService: VersionService) {
    // Electron version loads asynchronously so we have to poll until it is available
    this.evTimeout = -1;
    const electronVersion = versionService.electronVersion;
    if (electronVersion == '') {
      this.evTimeout = window.setTimeout(() => this.electronVersion(versionService), 500);
    }
    this.version = `${versionService.version} (${electronVersion})`;
    this.updateInfoString();
  }

  private handleDateChange() {
    // Write out UTC date as YYYY-MM-dd HH:mm
    const now = new Date();
    const date = now.toISOString().replace('T', ' ');
    this.date = date.substring(0, date.lastIndexOf(':')) + "  UTC";
    this.updateInfoString();

    // Queue up the next update 2 seconds after the minute changes.
    const timeout = (62 - now.getUTCSeconds()) * 1000;
    this.dateChangeTimeout = window.setTimeout(() => this.handleDateChange(), timeout);
  }

  private updateInfoString() {
    this.info = `${this.title} ${this.version} - ${this.sitename} - ${this.username} - ${this.date}`;
  }
}
