<div class="h-100 position-relative">
  <div class="textmode-container" [ngStyle]="{'display' : videoUnavailable && commMode === CommunicationMode.OFF ? 'inline-block' : 'none' }">
    <img src="../../../../../assets/images/text_mode.png" class="w-100 h-100"/>
  </div>

  <div class="audiomode-container" [ngStyle]="{'display' : videoUnavailable && commMode === CommunicationMode.TWOWAYAUDIO_NOVIDEO ? 'inline-block' : 'none' }">
    <img src="../../../../../assets/images/audio_mode.png" class="w-100 h-100"/>
  </div>

  <div class="undocked-container w-100 h-100 text-center" [ngStyle]="{'display' : undocked ? 'inline-block' : 'none' }" (click)="onDockChange(false)">
    <fa-icon size="10x" [icon]="faCompress" class="w-100 h-100"></fa-icon>
  </div>

  <ng-template engagementVideoAnchor></ng-template>
</div>


