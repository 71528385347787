import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth-service/auth.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Size} from '../../classes/Size';

export enum AppViewPointerMode {
  NONE,
  TOUCH,
  DRAW,
  POINT,
}

@Component({
  selector: 'app-engagement-app-view',
  templateUrl: './engagement-app-view.component.html',
  styleUrls: ['./engagement-app-view.component.scss']
})
export class EngagementAppViewComponent implements OnInit {
  private static readonly POINTER_MODE_URL_PARAM_LOOKUP: ReadonlyMap<AppViewPointerMode, number> = new Map([
    [AppViewPointerMode.NONE, 0],
    [AppViewPointerMode.TOUCH, 1],
    [AppViewPointerMode.DRAW, 2],
    [AppViewPointerMode.POINT, 3]
  ]);

  AppViewPointerMode = AppViewPointerMode;

  public frameLocation: SafeUrl;

  private _currentMode: AppViewPointerMode = AppViewPointerMode.NONE;
  public set currentMode(mode: AppViewPointerMode) {
    this._currentMode = mode;
    this.frameLocation = this.createFrameLocation();
  }
  public get currentMode(): AppViewPointerMode {
    return this._currentMode;
  }

  private _engagementId: string = '';
  @Input() set engagementId(engagementId: string) {
    this._engagementId = engagementId;
    this.frameLocation = this.createFrameLocation();
  }

  @Input() customerSize: Size;

  constructor(private readonly authService: AuthService,
              private readonly sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  private createFrameLocation(): SafeUrl {
    const staticRoot = environment.staticRoot;
    const viewerUrl = '/v24/viewApp/viewer.html';
    const wsURL = 'DEPRECATED';
    const modeNumber = EngagementAppViewComponent.POINTER_MODE_URL_PARAM_LOOKUP.get(this.currentMode);

    const queryString = `server=${wsURL}&stream=video${this._engagementId}screenout#:mode=${modeNumber}`;

    const url = staticRoot + viewerUrl + '?' + queryString;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public togglePointerMode(newMode: AppViewPointerMode) {
    if (newMode === this.currentMode) {
      this.currentMode = AppViewPointerMode.NONE;
    } else {
      this.currentMode = newMode;
    }
  }
}
