import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Opportunity} from '../../services/crm-service/opportunity';
import {EngagementEvent} from '../../services/crm-service/engagement-event';
import {CrmData, CrmStructure} from '../../services/crm-service/crm-category';
import {CrmContact} from '../../services/crm-service/crm-contact';
import {CrmEmailStateType, CrmEmailState} from '../../services/crm-service/crm-email-state';
import {CrmService} from '../../services/crm-service/crm.service';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {faTimes, faSearch, faSave, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {CrmEmail} from '../../utils/crm-email';
import Guid from '../../classes/Guid';
import {first} from "rxjs/operators";


export enum CrmState {
  Search,
  AddCustomer,
  EditCustomer,
  EditOpportunity,
  OpportunitySearch,
  AppointmentSearch
}

export interface CrmStateSearch {
  type: CrmState.Search;
}

export interface CrmStateAddCustomer {
  type: CrmState.AddCustomer;
}

export interface CrmStateEditCustomer {
  type: CrmState.EditCustomer;
}

export interface CrmStateEditOpportunity {
  type: CrmState.EditOpportunity;
}

export interface CrmStateOpportunitySearch {
  type: CrmState.OpportunitySearch;
}

export interface CrmStateAppointmentSearch {
  type: CrmState.AppointmentSearch;
}

export type CrmStateType = CrmStateSearch | CrmStateAddCustomer | CrmStateEditCustomer | CrmStateEditOpportunity | CrmStateOpportunitySearch | CrmStateAppointmentSearch;

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {
  private static readonly STATE_CRM_SEARCH: CrmStateSearch = { type: CrmState.Search };
  private static readonly STATE_CRM_ADD_CUSTOMER: CrmStateAddCustomer = { type: CrmState.AddCustomer };
  private static readonly STATE_CRM_EDIT_CUSTOMER: CrmStateEditCustomer = { type: CrmState.EditCustomer };
  private static readonly STATE_CRM_EDIT_OPPORTUNITY: CrmStateEditOpportunity = { type: CrmState.EditOpportunity };
  private static readonly STATE_OPPORTUNITY_SEARCH: CrmStateOpportunitySearch = { type: CrmState.OpportunitySearch };
  private static readonly STATE_APPOINTMENT_SEARCH: CrmStateAppointmentSearch = { type: CrmState.AppointmentSearch };

  public static readonly EMPTY_SELECTION: CrmContact = {
    customerId: null,
    vee24Guid: null,
    sitename: '',
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    customerNotes: '',
    customerOperator: '',
    createdBy: '',
    modifiedBy: ''
  };

  faTimes = faTimes;
  faSearch = faSearch;
  faSave = faSave;

  CrmState = CrmState;
  CrmEmailState = CrmEmailState;

  @Input() selected: CrmContact;
  @Input() selectSessionHistoryTab:boolean = false;
  @Output() backToForm = new EventEmitter();

  private crmStateType: CrmStateType = {type: CrmState.Search};
  public set crmState(crmState: CrmStateType) {
    this.crmStateType = crmState;
    this.crmStateChange.emit(this.crmStateType);
  }
  get crmState(): CrmStateType {
    return this.crmStateType;
  }
  @Output() crmStateChange = new EventEmitter<CrmStateType>();

  public contactData: BehaviorSubject<CrmStructure> = new BehaviorSubject<CrmStructure>(new CrmStructure());
  public contactSessionHistory: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);
  public selectedOpportunity: BehaviorSubject<Opportunity> = new BehaviorSubject<Opportunity>(new Opportunity());
  public opportunities: BehaviorSubject<Opportunity[]> = new BehaviorSubject<Opportunity[]>(null);


  public emailState: BehaviorSubject<CrmEmailStateType> = new BehaviorSubject<CrmEmailStateType>({type: CrmEmailState.Valid});

  constructor(
    private crmService: CrmService,
    private alertService: AlertService,
    private featureService: FeatureService
  ) { }

  ngOnInit() {
    this.crmService.loadAll().pipe(first()).subscribe(() => {
      if (this.selected == undefined) {
        this.crmState = CrmComponent.STATE_CRM_SEARCH;
      } else {
        this.onSelectedUser(this.selected);
        //fixme this should be refactored, same as crm search but when crm has a selected user
        this.crmState = CrmComponent.STATE_APPOINTMENT_SEARCH;
      }
    });
  }

  public backToSearch() {
    this.crmState = CrmComponent.STATE_CRM_SEARCH;
  }

  public backToOpportunitySearch() {
    this.crmState = CrmComponent.STATE_OPPORTUNITY_SEARCH;
  }

  public backToAppointmentSearch() {
    this.backToForm.emit();
  }

  onAddCustomer() {
    this.selected = new CrmContact(CrmComponent.EMPTY_SELECTION);
    this.contactData.next(this.crmService.createCustomerDataStructure(new CrmData()));
    this.crmState = CrmComponent.STATE_CRM_ADD_CUSTOMER;
  }

  onSelectedUser(selection: CrmContact) {
    this.selected = selection;
    this.getContactCrmData(selection.vee24Guid);
    this.getContactSessionHistory(selection.vee24Guid);
    if (this.IsCRMOpportunityAvailable()) {
      this.getOpportunitiesForContact(selection.vee24Guid);
    }

    this.crmState = CrmComponent.STATE_CRM_EDIT_CUSTOMER;

    this.contactData.subscribe(val => {
      if (val.list) {
        this.emailState.next(CrmEmail.isValidEmail(this.contactData.value));
      }
    });
  }

  onSelectedOpportunity(selection: Opportunity) {
    const contact = new CrmContact(CrmComponent.EMPTY_SELECTION);
    contact.vee24Guid = selection.vee24Guid;
    contact.customerName = selection.customerName;
    this.selected = contact;
    this.getContactCrmData(selection.vee24Guid);
    this.getOpportunityData(selection.opportunityId.toString());
  }

  getContactCrmData(userGuid: string) {
    this.crmService.loadCustomerData(userGuid).subscribe(data => {
      this.contactData.next(data);
    });
  }

  getOpportunityData(opportunityId: string) {
    this.crmService.getOpportunityById(opportunityId).subscribe(data => {
      this.selectedOpportunity.next(data);
      this.crmState = CrmComponent.STATE_CRM_EDIT_OPPORTUNITY;
    });
  }

  getOpportunitiesForContact(vee24Guid: string) {
    this.crmService.getOpportunitiesForContact(vee24Guid).subscribe(data => {
      if (data != null) {
        this.opportunities.next(data);
      }
    });
  }

  getContactSessionHistory(userGuid: string) {
    this.crmService.getSessionHistory(userGuid).subscribe(history => {
      this.contactSessionHistory.next(history);
    });
  }

  saveCrmData() {
    this.emailState.next(CrmEmail.isValidEmail(this.contactData.value));

    // if the user has no guid, create one
    if (!this.selected.vee24Guid || this.selected.vee24Guid === '') {
      this.selected.vee24Guid = new Guid().toString();
    }

    this.crmService.setCrmData(this.contactData.value, this.selected.vee24Guid).subscribe(res => {
      if (res) {
        this.alertService.addAlert('Field Saved', AlertType.Success);
      }
    });
  }
  IsCRMOpportunityAvailable(){
    return true;
  }
  getUserGuid():string{
    return this.selected == undefined? "":this.selected.vee24Guid
  }

  protected readonly faChevronLeft = faChevronLeft;
}
