<td>
  <div class="engagement-status-icon" [class]="CallStatuses.getStatusFontIcon(visitor?.visitorStatus).css"
       [title]="CallStatuses.getStatusText(visitor?.visitorStatus)"
       [pTooltip]="CallStatuses.getStatusText(visitor?.visitorStatus)"
  >
    <fa-icon [fixedWidth]="true" [icon]="CallStatuses.getStatusFontIcon(visitor?.visitorStatus).icon"></fa-icon>
  </div>
</td>
<td class="lastpage-cell">{{ visitor?.lastPage }}</td>
<td class="devicetype-cell">
  <img width="30" height="30"
       [src]="'/assets/call-list-icons/' + visitor?.browserInfo.deviceTypeIcon"
       [alt]="visitor?.browserInfo.deviceType"
       [title]="visitor?.browserInfo.deviceType">
</td>
<td class="browser-cell">
  <img width="30" height="30" [src]="'/assets/browser-icons/' + visitor?.browserInfo.browserIcon"
       [alt]="visitor?.browserInfo.browser" [title]="visitor?.browserInfo.browser">
</td>
<td class="calltype-cell">
  <figure class="camera-status-{{visitor?.canSupportWebrtc() ? 'on' : 'off'}} mb-0"></figure>
</td>
<td class="sitesection-cell"><p-tag [value]="visitor?.lastSection"></p-tag></td>
<td class="waitingtime-cell"><p-badge [value]="visitor?.getAssistTime()" severity="info"></p-badge></td>
<td>
  <p-button  severity="success" size="small" *ngIf="!visitor?.isInactive || visitor?.isEngaged" appDebounceClick
            (onClick)="accept()"
            [loading]="loading"
  >
    <fa-icon size="lg" [icon]="faPhone"></fa-icon>
  </p-button>
</td>
