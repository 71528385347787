import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";


export interface ISortObject<T> {
  setSortField(field: string): void;
  sortData(data: T[]): T[];
  get sortField(): Observable<string | null>;
  get sortOrder(): Observable<number>;
}

export class SortObject<T> implements ISortObject<T> {
  private _sortField = new BehaviorSubject<string | null>(null);
  private _sortOrder = new BehaviorSubject<number>(1); // 1 for ascending, -1 for descending

  setSortField(field: string) {
    if (this._sortField.value === field) {
      this.toggleSortOrder();
    } else {
      this._sortField.next(field);
      this._sortOrder.next(1);
    }
  }

  private toggleSortOrder() {
    this._sortOrder.next(this._sortOrder.value * -1);
  }

  get sortField() {
    return this._sortField.asObservable();
  }

  get sortOrder() {
    return this._sortOrder.asObservable();
  }

  sortData(data: T[]): T[] {
    if (!this._sortField.value) return data;

    return data.sort((a, b) => {
      const valueA = a[this._sortField.value];
      const valueB = b[this._sortField.value];

      let comparison = 0;

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparison = valueA.localeCompare(valueB);
      }
      else if (valueA instanceof Date && valueB instanceof Date) {
        comparison = valueA.getTime() - valueB.getTime();
      }
      else {
        if (valueA < valueB) comparison = -1;
        else if (valueA > valueB) comparison = 1;
      }

      return comparison * this._sortOrder.value;
    });
  }

}



@Injectable({
  providedIn: 'root'
})
export class SortFactoryService {

  constructor() { }

  getSortObject<T>(): ISortObject<T> {
    return new SortObject<T>();
  }
}
