import Browser from "./Browser";
import EventEmitter from "./EventEmitter";
import TrackMeter from "./TrackMeter";
import Constants from "./Constants";
import ProcessingPipe from "./virtual_background/ProcessingPipe";

// Taken from https://stackoverflow.com/questions/66575667/promise-allsettled-is-not-a-function
// Hovis (Vee24's angular project) changes Promise and it does not support Promise.allSettled
const PromiseHelperAllSettled = (promises) => {
    return Promise.all(promises.map(function (promise) {
        return promise.then(function (value) {
            return { state: 'fulfilled', value: value };
        }).catch(function (reason) {
            return { state: 'rejected', reason: reason };
        });
    }));
};

export default class DeviceManager extends EventEmitter {
    constructor(peerType) {

        super();

        this.peerType = peerType;

        this.selectedCamera = null;
        this.cameraWidth = 352;
        this.cameraHeight = 198;
        this.oldCameraWidth;
        this.oldCameraHeight;

        this.bigCamera = false;

        this.selectedMicrophone = null;
        this.selectedSpeaker = null;

        this.cameras = [];
        this.microphones = [];
        this.speakers = [];

        this.audioMeter = new TrackMeter(peerType);
        
        this.facingMode = null;

        this.backgroundConfig = null;
        this.processingPipe = null;
        this.getUserMediaPromise = Promise.resolve();

        navigator.mediaDevices.ondevicechange = () => {
            console.log("navigator.mediaDevices.ondevicechange");
            this.getDevices();
        };

        this.getDevices = () => {
            return navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                this.populateDevices(devices);
            });
        }

        this.populateDevices = (devices) => {
            this.cameras = [];
            this.microphones = [];
            this.speakers = [];

            var videoDevices = devices
                .filter(device => device.kind === 'videoinput');     // get the cams
            var micDevices = devices
                .filter(device => device.kind === 'audioinput');     // get the mics
            var speakerDevices = devices
                .filter(device => device.kind === 'audiooutput');    // get the speakers

            this.cameras = videoDevices.map(device => ({deviceId: device.deviceId, label: device.label}));
            this.microphones = micDevices.map(device => ({deviceId: device.deviceId, label: device.label}));
            this.speakers = speakerDevices.map(device => ({deviceId: device.deviceId, label: device.label}));

            //console.log("Populated Devices");
            this.emit("DeviceManager:change");
        };
    }

    initialise(cameraName, cameraWidth, cameraHeight, microphoneName, speakerName, backgroundConfig, postProcessingConfig) {
        return navigator.mediaDevices.enumerateDevices().then(devices => {
            this.populateDevices(devices);

            if (this.peerType === Constants.PeerType.Agent) {
                console.log("We are an agent so select the camera");
                this.selectedCamera = this.getCamera(cameraName);
                console.log("Selected Camera:" + this.selectedCamera.deviceId + " " + this.selectedCamera.label);
                // horendous hack to get around a long standing bug in safari
                // https://bugs.webkit.org/show_bug.cgi?id=82754
                // that is stopping the video from displaying if the video dimensions are the same as the poster image dimensions
                // we can't go making all the agent lanscape image 1 pixel smaller, so doing it here
                this.cameraWidth = --cameraWidth;
                this.cameraHeight = --cameraHeight;
                console.log("We are an agent so select the microphone");
                this.selectedMicrophone = this.getMicrophoneByName(microphoneName);
                console.log("Selected Microphone:" + this.selectedMicrophone.deviceId + " " + this.selectedMicrophone.label);
                console.log("We are an agent so select the speaker");
                this.selectedSpeaker = this.getSpeakerByName(speakerName);
                console.log("Selected Speaker:" + this.selectedSpeaker.deviceId + " " + this.selectedSpeaker.label);

                this.backgroundConfig = backgroundConfig; 
                this.postProcessingConfig = postProcessingConfig; 
            }
        });
    }

    createMediaPipeline(constraints) {
        return new Promise((resolve, reject) => {
            if (constraints && constraints.video && this.processingPipe) {
                this.processingPipe.dispose();
                this.processingPipe = null;
            }

            navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                this.devicesAllowed().then(() => {                
                    if (this.backgroundConfig && stream && stream.getVideoTracks().length > 0) {
                        if (!stream || stream.getVideoTracks().length < 1) return;
    
                        var videoTrackSettings = stream.getVideoTracks()[0].getSettings();

                        if (this.processingPipe) {
                            this.processingPipe.dispose();
                            this.processingPipe = null;
                        }

                        let processingPipe = new ProcessingPipe();
                        return processingPipe.initialise({
                            width : videoTrackSettings.width, // constraints.video.width.ideal || constraints.video.width.exact,
                            height : videoTrackSettings.height, // constraints.video.height.ideal || constraints.video.height.exact,
                            backgroundConfig : this.backgroundConfig, 
                            postProcessingConfig : this.postProcessingConfig
                        }).then((pipeline) => {
                            if (!pipeline || !stream) {
                                console.error("No pipeline or no stream");
                                return;
                            } 
                            const processedStream = pipeline.processVideoStream(stream);
                            const audioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
                            if (audioTrack && processedStream) {
                                processedStream.addTrack(audioTrack);
                            }                        

                            if (this.processingPipe) {
                                this.processingPipe.dispose();
                                this.processingPipe = null;
                            }

                            this.processingPipe = pipeline;

                            resolve(processedStream);
                        });
                    }
                    else {
                        resolve(stream);
                    }                    
                });                
            })
            .catch(e => {
                console.error(e);
                reject(e);
            });
        });
    }

    getUserMedia(constraints) {
        this.lastConstraints = constraints;
        // We chain this new getUserMedia request on top of the previous request.
        this.getUserMediaPromise = PromiseHelperAllSettled([this.getUserMediaPromise])
            .then(() => this.createMediaPipeline(constraints));
        return this.getUserMediaPromise;
    }

    updateBackgroundConfig(backgroundConfig) {
        if (this.processingPipe && backgroundConfig) {
            this.backgroundConfig = backgroundConfig;
            this.processingPipe.updateBackgroundConfig(backgroundConfig);
        }
    }

    updatePostProcessing(postProcessingConfig) {
        if (this.processingPipe && postProcessingConfig) {
            this.processingPipe.updatePostProcessing(postProcessingConfig);
        }
    }

    devicesAllowed() {
        return this.getDevices();
    }

    dispose() {
        this.selectedCamera = null;
        this.cameras = [];

        this.selectedMicrophone = null;
        this.microphones = [];

        this.selectedSpeaker = null;
        this.speakers = [];

        this.audioMeter.detach();
        this.audioMeter = null;

        if (this.processingPipe) {
            this.processingPipe.dispose();
            this.processingPipe = null;
        }
        
        this.backgroundConfig = null;
    }

    getVideoSettings(options) {

        const tryUpdateFacingMode = (vidSettings, facingMode) => {
            try {
                const supports = navigator.mediaDevices.getSupportedConstraints();
                if (supports["facingMode"]) {
                    if (facingMode) {
                        vidSettings.facingMode = facingMode;
                        vidSettings.deviceId = undefined;
                    }
                    else {
                        vidSettings.facingMode = "user";
                    }
                }
            }
            catch(err) {
                console.warn(err);
            }

            return vidSettings;
        };

        var vidSettings = {};

        if (this.selectedCamera != null && this.selectedCamera.deviceId !== -1) {
            vidSettings.deviceId = this.selectedCamera.deviceId ? {exact: this.selectedCamera.deviceId} : undefined;
        }

        if (Browser.details().browser === 'safari') {
            // this resolution seems to work on safari desktop for my logitech and the dummy input device
            // don't know if ios supports it yet
            vidSettings.width = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? 640 : 320};
            vidSettings.height = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? 360 : 240};
        }
        else if (Browser.details().browser === 'ipad' || Browser.details().browser === 'iphone') {
            vidSettings.width = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? 640 : 352};
            vidSettings.height = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? 360 : 288};
        }        
        else if (Browser.details().browser === 'chrome android') {
            vidSettings.width = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? this.cameraWidth+1 : 320};
            vidSettings.height = {exact: this.peerType === Constants.PeerType.Agent || this.bigCamera ? this.cameraHeight+1 : 240};

            if (this.peerType === Constants.PeerType.Agent) {
                vidSettings.frameRate = 15;
            }
        }
        else {
            vidSettings.width = {exact: this.cameraWidth};
            vidSettings.height = {exact: this.cameraHeight};

            vidSettings.aspectRatio = this.cameraWidth/this.cameraHeight;
        }

        if (this.facingMode && (options && options.facingMode && options.facingMode === this.facingMode)) {
            vidSettings.facingMode = this.facingMode;
        }
        else if (options && options.facingMode) {
            vidSettings = tryUpdateFacingMode(vidSettings, options.facingMode);
            this.facingMode = options.facingMode; // remember                
        }            

        return vidSettings;
    }

    setBigCamera(sendBig) {
        if (sendBig && !this.bigCamera) {
            // save away for later
            this.oldCameraWidth = this.cameraWidth;
            this.oldCameraHeight = this.cameraHeight;
            this.cameraWidth = 639;
            this.cameraHeight = 359;
            this.bigCamera = true;
        }
        else {
            this.cameraWidth = this.oldCameraWidth;
            this.cameraHeight = this.oldCameraHeight;
            this.bigCamera = false;
        }        
    }

    changeCamera(newCamera, newCameraWidth, newCameraHeight) {
        this.selectedCamera = this.getCamera(newCamera);
        console.log("Selected Camera:" + this.selectedCamera.deviceId + " " + this.selectedCamera.label);
        // horendous hack to get around a long standing bug in safari
        // https://bugs.webkit.org/show_bug.cgi?id=82754
        // that is stopping the video from displaying if the video dimensions are the same as the poster image dimensions
        // we can't go making all the agent landscape images 1 pixel smaller, so doing it here
        this.cameraWidth = --newCameraWidth;
        this.cameraHeight = --newCameraHeight;
    }

    getCamera(cameraName) {
        var camera = null;
        for (var i=0; i < this.cameras.length; i++) {
            // TODO: check if the camera is available , if not, move to the next
            if(this.cameras[i].label.toLowerCase().indexOf(cameraName.toLowerCase()) > -1) {
                camera = this.cameras[i];
                break;
            }
        }

        if (camera == null && this.cameras.length > 0) {
            camera = this.getCameraByDeviceId(cameraName);
        }

        return camera;
    }

    getCameraByName(cameraName) {
        var camera = null;
        for (var i=0; i < this.cameras.length; i++) {
            // TODO: check if the camera is available , if not, move to the next
            if (cameraName.toLowerCase().indexOf(this.cameras[i].label.toLowerCase()) > -1) {
                camera = this.cameras[i];
                break;
            }
        }

        if (camera == null && this.cameras.length > 0) {
            camera = this.getCameraByDeviceId(cameraName);
        }

        return camera;
    }

    selectCamera(deviceId) {
        this.selectedCamera = this.getCameraByDeviceId(deviceId);
        console.log("Selected Camera:" + this.selectedCamera.deviceId + " " + this.selectedCamera.label);
    }

    selectCameraByName(label) {
        this.selectedCamera = this.getCameraByName(label);
        console.log("Selected Camera:" + this.selectedCamera.deviceId + " " + this.selectedCamera.label);
    }

    getCameraByDeviceId(deviceId) {
        console.log("getCameraByDeviceId:" + deviceId);
        var camera = null;
        for (var i=0; i < this.cameras.length; i++) {
            //console.log("cam index:" + i + " deviceId:" + this.cameras[i].deviceId + " label:" + this.cameras[i].label);
            if (this.cameras[i].deviceId === deviceId) {
                //console.log("deviceId matches");
                camera = this.cameras[i];
                break;
            }
        }

        if (camera == null && this.cameras.length > 0) {
            //console.log("camera null");
            camera = this.cameras[0];
        }

        return camera;
    }

    getAudioSettings() {
        var audioSettings = {};

        if (this.selectedMicrophone != null && this.selectedMicrophone.deviceId !== -1) {
            audioSettings.deviceId = this.selectedMicrophone.deviceId ? {exact: this.selectedMicrophone.deviceId} : undefined;
        }

        audioSettings.echoCancellation = true;
        audioSettings.noiseSuppression = true;
        audioSettings.autoGainControl = true;

        return audioSettings;
    }

    selectMicrophone(deviceId) {
        this.selectedMicrophone = this.getMicrophoneByDeviceId(deviceId);
        console.log("Selected Microphone:" + this.selectedMicrophone.deviceId + " " + this.selectedMicrophone.label);
    }

    selectMicrophoneByName(label) {
        this.selectedMicrophone = this.getMicrophoneByName(label);
        console.log("Selected Microphone:" + this.selectedMicrophone.deviceId + " " + this.selectedMicrophone.label);
    }

    getMicrophoneByName(microphoneName) {
        var microphone = null;
        for (var i=0; i < this.microphones.length; i++) {
            // TODO: check if the microphone is available , if not, move to the next
            if (microphoneName.toLowerCase().indexOf(this.microphones[i].label.toLowerCase()) > -1) {
                microphone = this.microphones[i];
                break;
            }
        }

        if (microphone == null && this.microphones.length > 0){
            microphone = this.getMicrophoneByDeviceId(microphoneName);
        }

        return microphone;
    }

    getMicrophoneByDeviceId(deviceId) {
        var microphone = null;
        for (var i=0; i < this.microphones.length; i++) {
            // TODO: check if the microphone is available , if not, move to the next
            if (this.microphones[i].deviceId === deviceId) {
                microphone = this.microphones[i];
                break;
            }
        }

        if (microphone == null && this.microphones.length > 0){
            microphone = this.microphones[0];
        }

        return microphone;
    }

    selectSpeaker(deviceId) {
        this.selectedSpeaker = this.getSpeakerByDeviceId(deviceId);
        console.log("Selected Speaker:" + this.selectedSpeaker.deviceId + " " + this.selectedSpeaker.label);
    }

    getSpeakerByName(speakerName) {
        var speaker = null;
        for (var i=0; i < this.speakers.length; i++) {
            // TODO: check if the speaker is available , if not, move to the next
            if (speakerName.toLowerCase().indexOf(this.speakers[i].label.toLowerCase()) > -1) {
                speaker = this.speakers[i];
                break;
            }
        }

        if (speaker == null && this.speakers.length > 0){
            speaker = this.getSpeakerByDeviceId(speakerName);
        }

        return speaker;
    }

    getSpeakerByDeviceId(deviceId) {
        var speaker = null;
        for (var i=0; i < this.speakers.length; i++) {
            // TODO: check if the speaker is available , if not, move to the next
            if (this.speakers[i].deviceId == deviceId) {
                speaker = this.speakers[i];
                break;
            }
        }

        if (speaker == null && this.speakers.length > 0){
            speaker = this.speakers[0];
        }

        return speaker;
    }

    isCapableOfAudio() {
        return this.microphones.length > 0;
    }

    isCapableOfVideo(){
        return this.cameras.length > 0;
    }

    isCameraAllowed(options) {      
        return new Promise((resolve) => {
            navigator.mediaDevices.getUserMedia({video : this.getVideoSettings(options), audio:false})
            .then(function() {
                resolve(true);
            })
            .catch(function() {
                resolve(false);
            });
        });
    }

    isMicrophoneAllowed() {
        return new Promise((resolve) => {
            navigator.mediaDevices.getUserMedia({video:false, audio: this.getAudioSettings()})
            .then(function() {
                resolve(true);
            })
            .catch(function() {
                resolve(false);
            });
        });
    }

}

