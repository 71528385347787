import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertService, AlertType} from '../../../../services/alert-service/alert.service';
import {LoggingService} from '../../../../services/logging.service';
import {OnlineService} from '../../../../services/online.service';
import {PanelSize} from '../../../../services/engagement';
import {EngagementVideoComponent} from '../../engagement/engagement-video/engagement-video.component';
import * as MultiPeerWebRTC from 'multi-peer-webrtc';
import {environment} from '../../../../../environments/environment';
import {distinctUntilChanged, skip, Subscription} from 'rxjs';
import {VirtualBackground} from '../../../../classes/virtualBackground';
import {TranslatePipe} from '../../../../filters/Translate.pipe';
import {Browsers} from '../../../../utils/browsers';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss']
})
export class VideoPreviewComponent implements OnInit, OnDestroy {

  @Input() showHeadPositionOverlay: boolean;
  @Input() cameraAllowed: boolean;
  @Input() micAllowed: boolean;

  public source: MediaStream;
  private subs: Subscription[] = [];
  private _processingPipe: Promise<MultiPeerWebRTC.ProcessingPipe | null> = Promise.resolve(null);
  private _backgroundConfig: MultiPeerWebRTC.BackgroundConfig = null;
  private _postProcessingConfig: MultiPeerWebRTC.PostProcessingConfig = null;

  constructor(
    private readonly alertService: AlertService,
    private readonly loggingService: LoggingService,
    private readonly onlineService: OnlineService,
    private readonly translate: TranslatePipe
  ) {
  }

  private _device: MediaDeviceInfo;

  @Input() set device(newDevice: MediaDeviceInfo) {
    this.loggingService.info('Video Preview input device changed');

    this._device = newDevice;
    this.update();
  }

  ngOnInit() {
    this.onlineService.virtualBackground.next(null);
    this.subs.push(this.onlineService.virtualBackground.pipe(distinctUntilChanged(), skip(1)).subscribe(v => {
      this.setupVirtualBackground(v);
    }));
  }

  ngOnDestroy(): void {
    this.loggingService.info('Destroying Video Preview');
    this._processingPipe.then(pipe => this.disposePipe(pipe));
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  private update() {
    this._processingPipe = this._processingPipe.then((pipe) => {
      this.disposePipe(pipe);
      if (this._device) {
        return this.createPreview();
      } else {
        return Promise.resolve(null);
      }
    });
  }

  private setupVirtualBackground(virtualBackground: VirtualBackground) {
    const currentUrl = new URL(window.location.href);
    const resourcesUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port ? ':' + currentUrl.port : ''}/assets/mpw-vb-resources`;

    this._backgroundConfig = virtualBackground ? new MultiPeerWebRTC.BackgroundConfig({
      resourcesPath: resourcesUrl,
      type: VirtualBackground.convert(virtualBackground.type),
      url: `${environment.assetProxy}${virtualBackground.url}`
    }) : null;

    this._postProcessingConfig = virtualBackground ? new MultiPeerWebRTC.PostProcessingConfig({
      sigmaSpace: virtualBackground.jointBilateralFilterSigmaSpace,
      sigmaColor: virtualBackground.jointBilateralFilterSigmaColor,
      coverageMin: virtualBackground.backgroundCoverageMin,
      coverageMax: virtualBackground.backgroundCoverageMax,
      lightWrapping: virtualBackground.backgroundLightWrapping,
      blendMode: virtualBackground.backgroundBlendMode
    }) : null;

    this.update();

  }

  private async createPreview(): Promise<MultiPeerWebRTC.ProcessingPipe | null> {
    this.loggingService.info('Video Preview createPreview');

    const constraints = this.getConstraints();

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

      if (this._backgroundConfig) {
        const pipe = new MultiPeerWebRTC.ProcessingPipe();
        try {
          await pipe.initialise({
            width: constraints.video['width']['ideal'] || constraints.video['width'],
            height: constraints.video['height']['ideal'] || constraints.video['height'],
            backgroundConfig: this._backgroundConfig,
            postProcessingConfig: this._postProcessingConfig
          });
          this.source = pipe.processVideoStream(mediaStream);
          this.loggingService.info('Video Preview createPreview setting source');
          return pipe;
        } catch (err) {
          pipe.dispose();
          this.alertService.addAlert(
            this.translate.transform('PIPE_CREATE_ERROR', 'Unable to create processing pipeline for your camera: {0}')?.replace('{0}', this.getCamName()),
            AlertType.Danger
          );
          this.loggingService.error('Unable to create MultiPeerWebRTC.ProcessingPipe', err);
          return null;
        }
      } else {
        this.source = mediaStream;
        return null;
      }

    } catch (err) {
      let errorMessage: string;
      if (err instanceof DOMException) {
        switch (err.name) {
          case 'NotFoundError':
            errorMessage = this.translate.transform('CAMERA_NOT_FOUND_ERROR', 'No camera found or the camera is not accessible: {0}');
            break;
          case 'NotAllowedError':
            errorMessage = this.translate.transform('CAMERA_PERMISSION_ERROR', 'Permission to access the camera was denied: {0}');
            break;
          case 'NotReadableError':
            errorMessage = this.translate.transform('CAMERA_IN_USE_ERROR', 'The camera is already in use or not readable: {0}');
            break;
          case 'OverconstrainedError':
            errorMessage = this.translate.transform('CAMERA_CONSTRAINTS_ERROR', 'The camera does not meet the required constraints: {0}');
            break;
          case 'AbortError':
            errorMessage = this.translate.transform('CAMERA_ABORTED_ERROR', 'The camera access was aborted: {0}');
            break;
          default:
            errorMessage = this.translate.transform('CAMERA_UNKNOWN_ERROR', 'An unknown error occurred while accessing the camera: {0}');
        }
      } else {
        errorMessage = this.translate.transform('CAMERA_GENERAL_ERROR', 'Unable to get preview for your camera: {0}');
      }

      this.alertService.addAlert(errorMessage.replace('{0}', this.getCamName()), AlertType.Danger);
      this.loggingService.error('Unable to get camera preview', err);
      return null;
    }
  }

  private disposePipe(pipe: MultiPeerWebRTC.ProcessingPipe | null): void {
    this.loggingService.info('Removing processing pipe');
    pipe?.dispose();

    if (this.source) {
      for (const track of this.source.getTracks()) {
        track.stop();
      }
    }
    this.source = null;
  }

  private getCamName(): string {
    return this._device?.label?.length > 0 ? this._device?.label : this._device?.deviceId;
  }

  private getConstraints(): MediaStreamConstraints {

    const constraints: MediaStreamConstraints = {};

    if (this.cameraAllowed) {

      let width = 352;
      let height = 198;

      const size = EngagementVideoComponent.SIZE_LOOKUP.get(PanelSize.Normal);

      if (size && size.length === 2) {
        width = size[0];
        height = size[1];
      }

      constraints.video = Browsers.isSafari() ? {
        deviceId: {exact: this._device.deviceId},
        width: {ideal: 320},
        height: {ideal: 240}
      } : {
        deviceId: this._device.deviceId,
        width,
        height
      };
    }

    return constraints;

  }
}
