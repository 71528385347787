import {Pipe, PipeTransform} from '@angular/core';
import {Engagement} from '../../../services/engagement';
import {Joined, JoinedEngagement, SupervisingEngagementTypes} from '../supervisor.component';
import {DefaultGroup} from "../../veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
  name: 'supervisorEngagementGroup',
  pure: false
})
export class SupervisorEngagementGroupPipe implements PipeTransform {

  transform(joined: Joined[], selectedDept: string): Engagement[] {
    const joinedEngagements: JoinedEngagement[] = <JoinedEngagement[]>joined.filter(j => j.type === SupervisingEngagementTypes.Supervising);

    if (selectedDept !== DefaultGroup.NoSuperVisor) {
      const filtered: Engagement[] = [];

      for (const joinedEngagement of joinedEngagements) {
        if (joinedEngagement.visitor.agentGroup.some(group => group.Name === selectedDept)) {
          filtered.push(joinedEngagement.engagement);
        }
      }

      return filtered;
    } else {
      return joinedEngagements.map(j => j.engagement);
    }
  }
}
