import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-engagement-missing-visitor-modal-content',
  styleUrls: ['./engagement-missing-visitor-modal-content.component.scss'],
  template: `
    <div class="d-flex flex-column gap-4 h-100">
      <p>{{ "VISITORMODAL_TEXT_WAITCUSTOMER" | Translate : 'Do you want to wait for the customer?' }}</p>

      <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
        <p-button id="end-btn" [text]="true" severity="secondary" (click)="end()">{{
            "MODAL_BUTTON_NO" | Translate : "No" }} </p-button>
        <p-button id="wait-btn"   (click)="wait()">{{ "MODAL_BUTTON_YES" | Translate : 'Yes' }} </p-button>
      </div>
    </div>
  `
})
export class MissingVisitorModalContentComponent {
  public end: () => void = () => console.warn('End action not defined');
  public wait: () => void = () => console.warn('Wait action not defined');

  constructor(private dialogConfig: DynamicDialogConfig) {
    if (typeof this.dialogConfig.data?.end === 'function') {
      this.end = this.dialogConfig.data.end;
    }
    if (typeof this.dialogConfig.data?.wait === 'function') {
      this.wait = this.dialogConfig.data.wait;
    }
  }
}
