import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public permission: Permission;
  private timeout;

  constructor(
    private titleService: Title,
  ) {
      this.permission = this.isSupported() ? 'default' : 'denied';
  }

  public isSupported(): boolean {
      return 'Notification' in window;
  }

  requestPermission(): void {
      if ('Notification' in window) {
          Notification.requestPermission(status => this.permission = status);
      }
  }

  create(title: string, options?: PushNotification): any {
    if (('Notification' in window) && Notification.permission === 'granted') {

      if (!document.hasFocus()) {
        const _notify = new Notification(title, options);
        setTimeout(_notify.close.bind(_notify), 4000);

        _notify.onshow = function(e) {
          };
        _notify.onclick = function(e) {
          e.preventDefault();
          window.focus();
        };
        _notify.onerror = function(e) {
          };
        _notify.onclose = function() {
          };

        this.blink(title);
        }

      }
    }

  blink(msg: string, count: number = 10): void {
    const title = 'VeeStudio';

    const step = () => {
        const newTitle = this.titleService.getTitle() === title ? msg : title;
      this.titleService.setTitle(newTitle);

      if (--count && !document.hasFocus()) {
        this.timeout = setTimeout(step.bind(this), 1000);
      } else {
        this.titleService.setTitle(title);
      }
    };

    clearTimeout(this.timeout);
    step();
  }
}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  renotify?: boolean;
  silent?: boolean;
  requireInteraction?: boolean;
}
