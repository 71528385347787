import { Injectable } from '@angular/core';
import { LoggingService } from '../logging.service';
import { AuthService } from '../auth-service/auth.service';
import { EmailBackendService } from './email.backend.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { EmailTemplate, EmailTemplateSection } from '../../classes/email/emailtemplate';
import { GenerateEmailTextRequest } from '../../classes/email/EmailText';
import { MessageDetails, QueueMessageRequest } from '../../classes/email/emailQueue';
import { FulfillmentMarketingMaterial } from '../../classes/fulfillment/FulfillmentMarketingMaterial';
import { FulfillmentService } from '../fulfillment-service/fulfillment.service';
import { PostEngagementEmail } from './PostEngagementEmail';

export interface IEmailService {
    emailTemplates: BehaviorSubject<EmailTemplate[]>;
    loadEmailTemplateList(eventTitle: string): Observable<boolean>;
    generateEmailText(emailTemplate: EmailTemplate, emailSection: EmailTemplateSection, engagementId: string, vee24guid: string): Observable<PostEngagementEmail>;
    submitMessages(engagementId: string, userguid: string): Observable<boolean>;
    getPostEngagementMessages(engagementguid: string): PostEngagementMessages;
}

export class PostEngagementMessages {
  public emails: PostEngagementEmail[] = [];
  public markettingMaterial: FulfillmentMarketingMaterial[] = [];

  constructor () {
  }

  public addEmail(email: PostEngagementEmail) {
    this.emails.push(email);
  }

  public addFulfillment(material: FulfillmentMarketingMaterial) {
    this.markettingMaterial.push(material);
  }

  public removeEmail(email: PostEngagementEmail) {
    this.emails.splice(this.emails.indexOf(email), 1);
  }

  public removeFulfillment(material: FulfillmentMarketingMaterial) {
    this.markettingMaterial.splice(this.markettingMaterial.indexOf(material), 1);
  }

  public clear() {
    this.emails = [];
    this.markettingMaterial = [];
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmailService implements IEmailService {

  public emailTemplates: BehaviorSubject<EmailTemplate[]> = new BehaviorSubject([]);

  private readonly postEngagementMessages: Map<string, PostEngagementMessages> = new Map<string, PostEngagementMessages>();

  private eventTitle: string;

  constructor(
    private logger: LoggingService,
    private authService: AuthService,
    private fulfillmentService: FulfillmentService,
    private emailBackendService: EmailBackendService
  ) { }

  public loadEmailTemplateList(eventTitle: string): Observable<boolean> {
    this.eventTitle = eventTitle;

    return new Observable<boolean>(
      observer => {
        this.emailBackendService.loadEmailTemplate(this.authService.currentAgent.value.authToken, eventTitle).subscribe(
          template => {
            this.emailTemplates.next(template);
            observer.next(true);
            observer.complete();
          },
          (err) => {
            this.logger.error('Error getting email template list', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public getPostEngagementMessages(engagementguid: string) : PostEngagementMessages {
    const messages = this.postEngagementMessages.get(engagementguid);

    if (messages) {
      return messages;
    } else {
      const newMessages = new PostEngagementMessages();
      this.postEngagementMessages.set(engagementguid, newMessages);
      return newMessages;
    }
  }

  public generateEmailText(emailTemplate: EmailTemplate, emailSection: EmailTemplateSection, engagementId: string, vee24guid: string): Observable<PostEngagementEmail> {
    const xmlTemplate = `<templates><template name='${emailTemplate.Title}' subject='${emailTemplate.Subject}' type='${emailTemplate.MessageDataType}'><sections><section>${emailSection.Title}</section></sections></template></templates>`;

    const request: GenerateEmailTextRequest = {
      xmlTemplate,
      callType: this.eventTitle,
      engagementId: engagementId,
      vee24guid: vee24guid
    };

    return new Observable<PostEngagementEmail>(
      observer => {
        this.emailBackendService.generateEmailText(this.authService.currentAgent.value.authToken, request).subscribe(
          text => {
            observer.next(this.createPostEngagementEmail(emailTemplate, emailSection, text));
            observer.complete();
          },
          (err) => {
            this.logger.error('Error generate email text', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  private createPostEngagementEmail(emailTemplate: EmailTemplate, emailSection: EmailTemplateSection, text: EmailTemplate[]): PostEngagementEmail {
    const email: PostEngagementEmail = new PostEngagementEmail(emailTemplate.Title, emailSection.Title, emailTemplate.MessageDataType);

    if (text != null) {
      const emailText = Array.from(text);

      if (emailText.length > 0) {
        email.subject = emailText[0].Subject;
        email.emailTo = emailText[0].EmailAddress;

        if (emailText[0].Sections.length > 0) {
          email.emailMessage = emailText[0].Sections[0].Template;
        }
        else {
          email.emailMessage = emailText[0].Template;
        }
      }
    }

    return email;
  }

  public submitMessages(engagementId: string, userguid: string): Observable<boolean> {
    const messages = this.postEngagementMessages.get(engagementId);
    this.postEngagementMessages.delete(engagementId);

    if (messages) {
      this.fulfillmentService.insertFulfillment(messages.markettingMaterial, engagementId, userguid).subscribe();
      return this.queueMessage(messages.emails, engagementId, userguid, this.eventTitle);
    } else {
      return of(true);
    }
  }

  private queueMessage(emails: PostEngagementEmail[], engagementId: string, userguid: string, callType: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {

        const messagesToSend = [];
        for (const item of emails) {
          const messageDetails: MessageDetails = {
            name: item.emailTemplateTitle,
            subject: item.subject,
            type: item.messageDataType,
            address: item.emailTo,
            section: item.emailMessage
          }
          messagesToSend.push(messageDetails);
        }

        const queueMessageRequest: QueueMessageRequest = {
          messages: messagesToSend,
          engagementId: engagementId,
          vee24guid: userguid,
          callType: callType
        };

        this.emailBackendService.enqueueMessage(this.authService.currentAgent.value.authToken, queueMessageRequest).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (err) => {
            this.logger.error('Error queue message', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }
}
