import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

import {Agent} from '../../classes/agent';
import {AuthBackendService} from './auth.backend.service';
import {IAuthService} from './auth.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {

  public currentAgent: BehaviorSubject<Agent | null> = new BehaviorSubject(this.getAgentFromSession());

  constructor(private authBackendService: AuthBackendService) {
  }

  public login(email: string, password: string, licenceKey:string, version:string="", electronVersion:string=""): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.authBackendService.login(email, password, version, licenceKey, electronVersion).subscribe(
          agent => {
            this.setAgentIntoSession(agent);
            this.currentAgent.next(agent);
            observer.next(true);
            observer.complete();
          },
          err => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.authBackendService.changePassword(currentPassword, newPassword, this.currentAgent.value.authToken).subscribe(
          () => {
            this.deleteAgentFromSession();
            this.currentAgent.next(null);
            observer.next(true);
            observer.complete();
          },
          error => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public forgottenPassword(email: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.authBackendService.forgottenPassword(email).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          error => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public changePasswordFromLink(newPassword: string, resetToken: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.authBackendService.changePasswordFromLink(newPassword, resetToken).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          error => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public logout(ignoreError?: boolean): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.authBackendService.logout(this.currentAgent.value.authToken).subscribe(
          () => {
            this.deleteAgentFromSession();
            this.currentAgent.next(null);
            observer.next(true);
            observer.complete();
          },
          error => {
            observer.next(false);
            observer.complete();
          }, () => {
            if (ignoreError) {
              this.deleteAgentFromSession();
              this.currentAgent.next(null);
            }
          }
        );
      }
    );
  }

  public checkLicenceType(licenceType:number): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        if (this.currentAgent && this.currentAgent.value) {
          this.authBackendService.checkLicenceType(this.currentAgent.value.authToken, licenceType).subscribe(
            allowed => {
              observer.next(allowed);
              observer.complete();
            },
            error => {
              observer.next(false);
              observer.complete();
            }
          );
        }
        else {
          observer.next(false);
          observer.complete();
        }
      }
    );
  }

  private getAgentFromSession(): Agent | null {
    if (!!sessionStorage.getItem('currentAgent')) {
      const agent = JSON.parse(sessionStorage.getItem('currentAgent')) as Agent;
      return agent;
    } else {
      return null;
    }
  }

  public getAgentLoginTime(): number {
    const loginTime = sessionStorage.getItem('agentLoginTime');
    return parseInt(loginTime, 10);
  }

  private setAgentIntoSession(agent: Agent): void {
    sessionStorage.setItem('agentLoginTime', new Date().getTime().toString());
    sessionStorage.setItem('currentAgent', JSON.stringify(agent));
  }

  private deleteAgentFromSession(): void {
    sessionStorage.removeItem('agentLoginTime');
    sessionStorage.removeItem('currentAgent');
  }

}
