/**
 * More information about the Insertable Streams API
 * https://developer.mozilla.org/en-US/docs/Web/API/Insertable_Streams_for_MediaStreamTrack_API
 * https://developer.chrome.com/articles/mediastreamtrack-insertable-media-processing/
 */
class TrackCropper {
    constructor(region) {
        this.region = region;
        this.abortController = new AbortController();
    }

    dispose() {
        this.abortController.abort("Disposing sharing stream");
    }

    updateRegion(region) {
        this.region = region;
        if (this.canvas) {
            this.canvas.width = this.region.width;
            this.canvas.height = this.region.height;
        }
    }

    get supportsCropping() {
        return ('MediaStreamTrackProcessor' in window && 'MediaStreamTrackGenerator' in window);
    }

    init(stream) {
        return new Promise((resolve, reject) => {
            if (this.supportsCropping) {
                console.log("Creating a processed stream");

                this.canvas = new OffscreenCanvas(1, 1);
                const ctx = this.canvas.getContext("2d");

                const [ track ] = stream.getVideoTracks();
                const { trackWidth, trackHeight } = track.getSettings();
                const {x, y, width, height} = this.region;
                this.canvas.width = width;
                this.canvas.height = height;

                const trackProcessor = new MediaStreamTrackProcessor({ track: track });
                const trackGenerator = new MediaStreamTrackGenerator({ kind: 'video' });

                const transformer = new TransformStream({
                    transform: async (videoFrame, controller) => {
                      const {x, y, width, height} = this.region;
                      ctx.drawImage(videoFrame, x, y, width, height, 0, 0, width, height);
                      const newFrame = new VideoFrame(this.canvas, { timestamp: videoFrame.timestamp });
                      videoFrame.close();
                      controller.enqueue(newFrame);
                    },
                  });
          
                const signal = this.abortController.signal;
                const processorPromise = trackProcessor.readable.pipeThrough(transformer, { signal }).pipeTo(trackGenerator.writable);

                processorPromise.catch(err => {
                    if (signal.aborted) {
                        console.log("Shutting down due to abort");
                    } else {
                        console.error(err);
                    }
                    trackProcessor.readable.cancel(err);
                    trackGenerator.writable.abort(err);
                });

                this.streamAfter = new MediaStream([trackGenerator]);
                resolve(this.streamAfter);
            } else {
                console.log("Using old track");
                resolve(stream);
            }
        });
    }
}

export { TrackCropper };