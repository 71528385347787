import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {OnlineService} from '../../services/online.service';
import {OnlineState} from '../../enums/online-state.enum';
import {Location} from '@angular/common';
import {faT, faTimes} from '@fortawesome/free-solid-svg-icons';
import {map} from 'rxjs/operators';
import {CrmState, CrmStateType} from '../../components/crm/crm.component';

@Component({
  selector: 'app-crm-opportunity',
  templateUrl: './crm-opportunity.component.html',
  styleUrls: ['./crm-opportunity.component.scss']
})
export class CrmOpportunityComponent implements OnInit {
  public currentState$: Observable<OnlineState>;
  public OnlineState = OnlineState;
  public canCloseCrm = true;

  faTimes = faTimes;

  constructor(
    private onlineService: OnlineService,
    private location: Location,
    ) { }

  ngOnInit() {
    this.currentState$ = this.onlineService.currentState.pipe(map(([s, _]) => s));
    this.onlineService.setCurrentState(OnlineState.CrmOpportunity);
  }

  onEndBreak() {
    this.onlineService.setCurrentState(OnlineState.CrmOpportunity);
  }

  public closeCrm() {
    this.onlineService.setCurrentState(OnlineState.OnBreak);
    this.location.back();
  }

  onCrmStateChange($event: CrmStateType) {
    this.canCloseCrm = $event.type === CrmState.Search || $event.type === CrmState.OpportunitySearch;
  }

  protected readonly faT = faT;
}
