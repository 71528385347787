<div class="d-flex flex-column gap-2 pt-2 px-2">
  <div class="d-flex justify-content-between">
    <p-dropdown (onChange)="onCamSelect($event)" [disabled]="videoUnavailable" [options]="selectVidDevices"
                [(ngModel)]="selectedCameraItem" appendTo="body" [pTooltip]="selectedCameraItem?.text"
                optionLabel="text" tooltipStyleClass="v24-video-device-tooltip" tooltipPosition="bottom" positionLeft="50" positionTop="5" fitContent="false">
      <ng-template pTemplate="selectedItem" let-item>
        <fa-icon [icon]="faVideo" class="center-icon icon"></fa-icon>
      </ng-template>
    </p-dropdown>

    <p-dropdown [disabled]="!showSpeakerSelection" (onChange)="onSpeakerSelect($event)" [options]="selectSpeakerDevices" [(ngModel)]="selectedSpeakerItem" panelStyleClass="v24-dropdown-overlay"
                appendTo="body" [pTooltip]="selectedSpeakerItem?.text" tooltipPosition="bottom" positionTop="5" optionLabel="text">
      <ng-template pTemplate="selectedItem" let-item>
        <fa-icon [icon]="faVolumeUp" class="center-icon icon"></fa-icon>
      </ng-template>
    </p-dropdown>

    <p-dropdown [disabled]="!showMicSelection" (onChange)="onMicSelect($event)" [options]="selectMicDevices" panelStyleClass="v24-dropdown-overlay" [(ngModel)]="selectedMicItem"
                optionLabel="text" appendTo="body" [pTooltip]="selectedMicItem?.text" tooltipPosition="bottom" positionTop="5" positionLeft="-50" tooltipStyleClass="v24-mic-device-tooltip">
      <ng-template pTemplate="selectedItem" let-item>
        <fa-icon [icon]="faMicrophone" class="center-icon icon"></fa-icon>
      </ng-template>
    </p-dropdown>

  </div>

  <app-audio-preview *ngIf="showMicSelection" [deviceId]="selectedMicrophone?.deviceId"></app-audio-preview>

  <ng-content></ng-content>
</div>

