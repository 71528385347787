import EventEmitter from "../EventEmitter";

const KeyState = {
    KeyDown: 0,
    KeyUp: 1,
    Shift: 2,
    Alt: 4,
    Ctrl: 8
};

const KEYBOARD_EVENT = 'KEY_EVENT'; 

export default class KeyboardCapturer extends EventEmitter {
    constructor(captureElement) {
        super();

        if (captureElement == null) {
            throw new Error("Capture element given is falsy");
        }

        if (typeof captureElement.addEventListener != 'function') {
            throw new Error("Capture element can not have event listeners added.");
        }

        this.element = captureElement;

        this.debug = false;
        this.enabled = false;
        this.disposed = false;

        this.keyDownListener = (ev) => this.keyListener(ev, KeyState.KeyDown);
        this.keyUpListener = (ev) => this.keyListener(ev, KeyState.KeyUp);

        this.element.addEventListener('keydown', this.keyDownListener);
        this.element.addEventListener('keyup', this.keyUpListener);

        this.keyListener = (ev, flags) => {
            ev.stopPropagation();
            ev.preventDefault();
    
            if (this.debug) {
                console.dir(ev);
            }
    
            if (ev.isComposing)
                return;
    
            if (ev.altKey) {
                flags |= KeyState.Alt;
            }
    
            if (ev.shiftKey) {
                flags |= KeyState.Shift;
            }
    
            if (ev.ctrlKey) {
                flags |= KeyState.Ctrl;
            }
    
            if (ev.key.length == 1) {
                if (this.enabled) this.emit(KEYBOARD_EVENT, { specialKey: false, key: ev.key, flags: flags });
            } else {
                switch (ev.key)
                {
                    // todo: all of the special cases:
                    // enter, arrow keys, etc.
                    case "Control":
                    case "Shift":
                    case "Alt":
                    case "Shift":
                    case "Escape":
                    case "Backspace":
                    case "OS":
                        // ignore the OS key
                    default:
                        // some unicode key?
                        if (this.enabled) this.emit(KEYBOARD_EVENT, { specialKey: true, key: ev.key, flags: flags });
                }
            }
        };    
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    focus() {
        this.element.focus();
    }

    blur() {
    }

    dispose() {
        this.off(KEYBOARD_EVENT);

        this.element.removeEventListener('keydown', this.keyDownListener);
        this.element.removeEventListener('keyup', this.keyUpListener);

        this.disposed = true;
    }

    setDebug(enabled) {
        this.debug = enabled;
    }
};
