import { Component, OnInit, OnDestroy } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordValidation } from '../../validators/password-change.validator';
import {Subscription} from 'rxjs';
import {LoggingService} from '../../services/logging.service';
import { TranslatePipe } from '../../filters/Translate.pipe'

interface FormGroupAbstractControl {
  [key: string]: AbstractControl;
}

@Component({
  selector: 'app-password-change-from-link',
  templateUrl: './password-change-from-link.component.html',
  styleUrls: ['./password-change-from-link.component.scss'],
  providers: [TranslatePipe]
})
export class PasswordChangeFromLinkComponent implements OnInit, OnDestroy {
  private static readonly ERROR_LOOKUP: Map<string, string> = new Map<string, string>([
    [ 'minlength', 'Your password is too short.' ],
    [ 'MatchPassword', 'Your new passwords do not match.' ]
  ]);
  private static readonly TRYAGAIN_ERRORMESSAGE: string = 'An unknown error occurred when trying to change your passwords.';

  public passwordChangeFromLinkForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  private resetToken: string;

  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggingService,
    private translate: TranslatePipe
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.resetToken = this.route.snapshot.queryParams['resetToken'];
  }

  private createForm(): void {
    this.passwordChangeFromLinkForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  public submit(): void {
    if (this.passwordChangeFromLinkForm.valid) {
      this.loadingService.isLoading.next(true);

      const {newPassword, confirmPassword} = this.passwordChangeFromLinkForm.value;
      this.logger.debug(`newPassword: ${newPassword}, confirmPassword: ${confirmPassword}`);

      this.subscriptions.push(
        this.authService.changePasswordFromLink(newPassword, this.resetToken).subscribe(success => {
          if (success) {
            this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_PLEASELOGIN", 'Your password has been changed, please login.'), AlertType.Success);
            this.router.navigateByUrl('/login');
          } else {
            this.alertService.addAlert(this.translate.transform("PASSWORDCHANGELINK_ERROR", 'Error changing your password, please try again.'), AlertType.Danger);
          }
          this.loadingService.isLoading.next(false);
        })
      );
    } else {
      const errors: ValidationErrors[] = [];
      this.getAllErrors(this.passwordChangeFromLinkForm.controls, errors);
      this.displayErrors(errors);
    }
  }

  private displayErrors(errors: ValidationErrors | null) {
    if (errors === null) {
      this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_TRYAGAIN", PasswordChangeFromLinkComponent.TRYAGAIN_ERRORMESSAGE), AlertType.Danger);
    } else {
      for (let i = 0; i < errors.length; ++i) {
        const error = errors[i];
        const errValue = error['error_name'];
        const errorString = PasswordChangeFromLinkComponent.ERROR_LOOKUP.get(errValue);
        if (errorString) {
          this.alertService.addAlert(errorString, AlertType.Danger);
        } else {
          this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_TRYAGAIN", PasswordChangeFromLinkComponent.TRYAGAIN_ERRORMESSAGE), AlertType.Danger);
        }
      }
    }
  }

  private getAllErrors(controls: FormGroupAbstractControl, errors: ValidationErrors[]) {
    if (controls) {
      Object.keys(controls).forEach((key) => {
        const control = controls[key];
        if (control instanceof UntypedFormGroup) {
          const nestedControls: FormGroupAbstractControl = control.controls;
          this.getAllErrors(nestedControls, errors);
        }

        const controlErrors = control.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(errorKey => {
            const err = controlErrors[key];
            errors.push({
              control_name: key,
              error_name: errorKey,
              error_value: err
            });
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
