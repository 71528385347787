import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as MultiPeerWebRTC from 'multi-peer-webrtc';
import {PeerEvents} from '../../../../../enums/multipeer/PeerEvents';
import { UIEvents } from '../../../../../enums/multipeer/UIEvents';
import { Orientation } from '../../../../../enums/multipeer/Orientation';

import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { IVideoComponent } from '../IVideoComponent';
import { VirtualBackground } from '../../../../../classes/virtualBackground';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-engagement-video-browser',
  templateUrl: './engagement-video-browser.component.html',
  styleUrls: ['./engagement-video-browser.component.scss']
})
export class EngagementVideoBrowserComponent implements OnInit, IVideoComponent {

  faExpand = faExpand;
  faCompress = faCompress;

  private _undocked:boolean;
  @Input() set undocked(value:boolean) {
    this._undocked = value;
  }
  get undocked() : boolean {
    return this._undocked;
  }

  private _dragResizeEnabled:boolean;
  @Input() set dragResizeEnabled(value:boolean) {
    this._dragResizeEnabled = value;
  }
  get dragResizeEnabled() :boolean {
    return this._dragResizeEnabled;
  }

  private _virtualBackgroundOn:boolean;
  set virtualBackgroundOn(value:boolean) {
    this._virtualBackgroundOn = value;
  }
  get virtualBackgroundOn(): boolean {
    return this._virtualBackgroundOn;
  }

  private _virtualBackground:VirtualBackground;
  set virtualBackground(value:VirtualBackground) {
    this._virtualBackground = value;
  }
  get virtualBackground(): VirtualBackground {
    return this._virtualBackground;
  }

  @Output('oncreated') public readonly oncreated: EventEmitter<any> = new EventEmitter();
  @Output('oninitialised') public readonly oninitialised: EventEmitter<any> = new EventEmitter();
  @Output('onerror') public readonly onerror: EventEmitter<any> = new EventEmitter();
  @Output('ondockchange') public readonly ondockchange: EventEmitter<boolean> = new EventEmitter();
  @Output('onsignallingmessage') public readonly onsignallingmessage: EventEmitter<any> = new EventEmitter();

   // variables used to set and remove fullscreen video styles
  public isVideoFullScreen = false;
  public initialTransform = null;


  private _call: MultiPeerWebRTC.CallP2PAgent;
  public get call():MultiPeerWebRTC.CallP2PAgent {
    return this._call;
  }
  private _backgroundConfig:MultiPeerWebRTC.BackgroundConfig = null;
  private _postProcessingConfig:MultiPeerWebRTC.PostProcessingConfig = null;

  constructor() { }
  ngOnInit(): void {
    this._backgroundConfig = null;
    this.oncreated.emit();
  }

  init(engagementId: string, iceServer: string, connectTracks:boolean, myUsername: string, cameraId: string, virtualBackground:VirtualBackground, virtualBackgroundOn:boolean) {
    // This method is a bit of a bodge to emulate a delayed init and tear down
    const vidDiv = document.getElementById('mainVideoContainer') as HTMLDivElement;
    const peersContainer = document.getElementById('peersContainer') as HTMLDivElement;

    const options = {
      engagementId: engagementId,
      peerId: myUsername,
      iceServer: iceServer,
      connectPeers: true,
      connectTracks: connectTracks,
      peerType: MultiPeerWebRTC.Constants.PeerType.Agent,
      mainPeerId: myUsername,
      speakerVolume: 1.0,
      mainVideoContainer: vidDiv,
      peersContainer: peersContainer
    };

    this._call = new MultiPeerWebRTC.CallFactory(MultiPeerWebRTC.CallFactory.CallType.P2P, MultiPeerWebRTC.CallFactory.ParticipantType.AGENT, options);

    this.call.ui.on(UIEvents.Orientation, this.orientationChange.bind(this));
    this.call.peers.on(PeerEvents.Signal, this.sendMessage.bind(this));

    this.virtualBackground = virtualBackground;
    this.virtualBackgroundOn = virtualBackgroundOn;

    const currentUrl = new URL(window.location.href);
    const resourcesUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port?':'+currentUrl.port:''}/assets/mpw-vb-resources`;

    if (virtualBackground) {
      this._backgroundConfig = new MultiPeerWebRTC.BackgroundConfig({
        resourcesPath : resourcesUrl,
        type : virtualBackgroundOn ? VirtualBackground.convert(virtualBackground.type) : MultiPeerWebRTC.BackgroundConfig.Type.None,
        url : `${environment.assetProxy}${virtualBackground.url}`
      });

      this._postProcessingConfig = new MultiPeerWebRTC.PostProcessingConfig({
        sigmaSpace : virtualBackground.jointBilateralFilterSigmaSpace,
        sigmaColor : virtualBackground.jointBilateralFilterSigmaColor,
        coverageMin : virtualBackground.backgroundCoverageMin,
        coverageMax : virtualBackground.backgroundCoverageMax,
        lightWrapping : virtualBackground.backgroundLightWrapping,
        blendMode : virtualBackground.backgroundBlendMode
      });
    }

    this.call.initialise({cameraName: cameraId, cameraWidth: 352, cameraHeight: 198, backgroundConfig: this._backgroundConfig, postProcessingConfig: this._postProcessingConfig})
    .then(() => {
      // Check we still have an engagement (call) because it may have been torn down
      // if the engagement crashed during start up.
      if (this.call) {
        this.call.startAudioVideo();
        this.oninitialised.emit();
      }
    }).catch((err) => {
      this.onerror.emit(err);
    });

  }

  public tearDown() {
    if (this.call) {
      this.call.ui.off(UIEvents.Orientation, this.orientationChange.bind(this));
      this.call.peers.off(PeerEvents.Signal, this.sendMessage.bind(this));
      this.call.dispose();
    }

    this._call = undefined;
    this.mouseover = false;
    this.portrait = false;
  }

  public processMessage(message: any) {
    if (this.call) {
      this.call.peers.processMessage(message.from, message);
    }
  }

  public addStream(streamName: string, peerType: any) {
    if (this.call) {
      this.call.addPeer(streamName, peerType);
    }
  }

  public removeStream(streamName: string) {
    if (this.call) {
      this.call.removePeer(streamName);
    }
  }

  public setPrimaryVisitor(visitor: string) {
    if (this.call) {
      this.call.setPrimaryVisitor(visitor);
    }
  }

  private sendMessage(message: any) {
    this.onsignallingmessage.emit(message);
  }

  public audioChat() {
    if (this.call) {
      this.call.audioChat();
    }
  }

  public videoChat() {
    if (this.call) {
      this.call.videoChat();
    }
  }

  public textChat() {
    if (this.call) {
      this.call.textChat();
    }
  }

  public changeCamera(cameraId: string, width:number, height:number) {
    if (cameraId && this.call) {
      this.call.changeCamera(cameraId, width, height);
    }
  }

  public changeMic(micId: string) {
    if (micId && this.call) {
      this.call.selectMicrophone(micId);
    }
  }

  public changeSpeaker(speakerId: string) {
    if (speakerId && this.call) {
      this.call.selectSpeaker(speakerId);
    }
  }

  public pauseCall() {
    if (this.call) {
      this.call.pauseCall();
    }
  }

  public resumeCall() {
    if (this.call) {
      this.call.resumeCall();
    }
  }

  public onDockChange(event:boolean) : void {
    this.ondockchange.emit(event.valueOf());
  }

  public dockVideo() : void {
    this.mouseover = false;
    this.ondockchange.emit(false);
  }

  public unDockVideo() : void {
    this.mouseover = false;
    this.ondockchange.emit(true);
  }

  private _mouseover:boolean = false;
  set mouseover(value:boolean) {
    this._mouseover = value;
  }
  get mouseover():boolean {
    return this._mouseover;
  }
  @HostListener('mouseover', ['$event'])
  onMouseOver(event:MouseEvent) {
    if (!this.dragResizeEnabled) return;
    this.mouseover = true;
  }
  @HostListener('mouseout', ['$event'])
  onMouseOut(event:MouseEvent) {
    if (!this.dragResizeEnabled) return;
    this.mouseover = false;
  }

  public _portrait:boolean = false;
  set portrait(value:boolean) {
    this._portrait = value;
  }
  get portrait():boolean {
    return this._portrait;
  }
  private orientationChange(orientation:Orientation) {
    if (!this.dragResizeEnabled) return;
    this.portrait = (orientation === Orientation.Portrait);
  }

  public turnVirtualBackgroundOff() {
    if (this._backgroundConfig) {
      this._backgroundConfig.type = MultiPeerWebRTC.BackgroundConfig.Type.None;
      if (this.call) {
        this.call.updateBackgroundConfig(this._backgroundConfig);
      }
      this.virtualBackgroundOn = false;
    }
  }

  public turnVirtualBackgroundOn() {
    if (this._backgroundConfig) {
      if (this.virtualBackground) {
        this._backgroundConfig.type = VirtualBackground.convert(this.virtualBackground.type);
        if (this.call) {
          this.call.updateBackgroundConfig(this._backgroundConfig);
        }
      }
      this.virtualBackgroundOn = true;
    }
  }

}
