import Browser from "./Browser";
import PeerAudioManager from "./PeerAudioManager";
import EventEmitter from "./EventEmitter";
import Constants from "./Constants";

const userInputEventNames = [
    "click",
    "contextmenu",
    "auxclick",
    "dblclick",
    "mousedown",
    "mouseup",
    "pointerup",
    "touchend",
    "keydown",
    "keyup"
  ];

export default class UIManager extends EventEmitter  {
    constructor(peerId, peerType, mainPeerId, speakerVolume, mainVideoContainer, connectingOverlay, reConnectingOverlay, onHoldOverlay, peersContainer) {

        super();

        this.peerId = peerId;
        this.peerType = peerType;

        this.mainPeerId = mainPeerId;
        this.speakerVolume = speakerVolume;

        this.mainVideoContainer = mainVideoContainer;
        this.connectingOverlay = connectingOverlay;
        this.reConnectingOverlay = reConnectingOverlay;
        this.onHoldOverlay = onHoldOverlay;

        this.peersContainer = peersContainer;

        this.posters = []; // these are urls for poster images for the video streams
        this.videoTracks = [];

        this.mainVideo = this.createMainVideo(this.mainPeerId);

        this.audio = new PeerAudioManager();

        this.hidePeerVideos = false;
        this.hideLocalVideo = false;

        this.primaryVisitorPeerId = undefined;

        this.addRemoteVideoTrack = (remotePeerId, remotePeerType) => {

            //console.log(`addRemoteVideoTrack for remotePeerId: ${remotePeerId} remotePeerType: ${remotePeerType} this.mainPeerId: ${this.mainPeerId} this.primaryVisitorPeerId: ${this.primaryVisitorPeerId}`);
            
            // if im the agent then
            // - always show the customers if they have turned on their video
            // - favour the primary
            if (this.peerType === Constants.PeerType.Agent) {
                if (remotePeerType && remotePeerType === Constants.PeerType.Visitor) {
                    // we have a primary customer
                    // and they have turned their video 
                    // or its another customer and the main is not set to the primary
                    // i.e, its the secondary visitor but only swap if the primaryVisitor is not already the main
                    if (this.primaryVisitorPeerId && (remotePeerId === this.primaryVisitorPeerId || this.primaryVisitorPeerId !== this.mainPeerId)) {
                        this.mainPeerId = remotePeerId;
                    }
                }
            }


            // if the remote is the mainPeerId
            if (this.mainPeerId === remotePeerId) {
                this.hidePeerVideo(remotePeerId);

                setTimeout(() => {
                    // reshow the peer video that is the current main, so long as its not the video we have just received
                    if (this.mainVideo.peerId !== remotePeerId) {
                        this.showPeerVideo(this.mainVideo.peerId);
                    }                    

                    console.log("### MainVideo - adding remotePeerId:" + remotePeerId);
                    this.mainVideo.peerId = remotePeerId;
                    this.mainVideo.poster = this.getPosterURL(remotePeerId);
                    setTimeout(() => {
                        //console.log("### MainVideo - new MediaStream():" + this.videoTracks[remotePeerId]);
                        this.mainVideo.srcObject = new MediaStream([this.videoTracks[remotePeerId].track]);
                    },0);
                },0);
            }
            else {
                this.showPeerVideo(remotePeerId);
            }
        }

        this.removeRemoteVideoTrack = (remotePeerId) => {
            //console.log("video track ended for:" + remotePeerId);
            // if im the agent
            // and the main video has the src for the video that we want to remove
            // which means we have previously clicked it's thumb to make it go big
            // then switch the main back to our local
            if (this.peerType === Constants.PeerType.Agent) {
                if (this.mainVideo.peerId === remotePeerId) {
                    
                    let peerToShow = this.peerId; // default to me

                    // if i have a visitors video in my collection of videoTracks
                    // and its enabled (not muted)
                    // then use it
                    for (var peerId in this.videoTracks) {
                        //console.log(`track ${peerId} enabled ${this.videoTracks[peerId].track.enabled} readyState ${this.videoTracks[peerId].track.readyState} muted ${this.videoTracks[peerId].track.muted}`);
                        // check if we are an visitor
                        if (this.videoTracks[peerId].peerType === Constants.PeerType.Visitor) {
                            let track = this.videoTracks[peerId].track;
                            if (track && track.enabled && track.readyState === 'live' && !track.muted) {
                                peerToShow = peerId;
                                break;
                            }
                        }
                    }                  
                    
                    //console.log(`peerToShow ${peerToShow}`);

                    // hide the peer to show in the mainVideo
                    this.hidePeerVideo(peerToShow);
                    setTimeout(() => {
                        // put the peer to show into main
                        var localTrack = this.videoTracks[peerToShow] ? this.videoTracks[peerToShow].track : null;
                        if (localTrack != null) {
                            console.log("### MainVideo - removingVideoTrack:" + peerToShow);
                            this.mainVideo.srcObject = new MediaStream([localTrack]);
                        }
                        else {
                            this.mainVideo.srcObject = null;
                            setTimeout(() => {
                                this.mainVideo = this.createMainVideo(remotePeerId);
                            }, 0);
                        }
                        this.mainVideo.peerId = peerToShow;
                        this.mainPeerId = peerToShow;
                    }, 0);
                }
            }
            else if (this.peerType === Constants.PeerType.Visitor) { // if im the visitor
                //console.log("the mainVideo.peerId:" + this.mainVideo.peerId + " remotePeerId:" + remotePeerId);
                if (this.mainVideo.peerId === remotePeerId) { // if the main video is the one thats ending
                    // set its src to null so that we switch back to the poster
                    this.mainVideo.srcObject = null;
                    setTimeout(() => {
                        this.mainVideo = this.createMainVideo(remotePeerId);
                    }, 0);
                }
            }

            this.hidePeerVideo(remotePeerId);
        }

        this.addRemoteAudioTrack = (remotePeerId, track, speakerId) => {
            var peerAudio = this.getPeerAudio(remotePeerId);
            if (peerAudio) {
                peerAudio.addSource(new MediaStream([track]));
                peerAudio.setVolume(this.speakerVolume);
                peerAudio.on("PeerAudio:AutoPlayPrevented", this.autoPlayPrevented);
                peerAudio.on("PeerAudio:AudioMediaReady", this.audioMediaReady)
                peerAudio.setOutput(speakerId);
            }
        }

        this.removeRemoteAudioTrack = (remotePeerId) => {
            var peerAudio = this.getPeerAudio(remotePeerId);
            if (peerAudio) {
                peerAudio.removeSource();
                peerAudio.off("PeerAudio:AutoPlayPrevented", this.autoPlayPrevented);
                peerAudio.off("PeerAudio:AudioMediaReady", this.audioMediaReady)
            }
        }

        this.autoPlayPrevented = () => {
            this.createInteractionOverlayDiv();
        }

        this.audioMediaReady = (peerAudio) => {
            if (peerAudio.peerId === this.mainPeerId) {
                this.emit("UIManager:mainAudioMediaReady");
            }
        }

        this.resumeAudioAndVideo = () => {
            this.removeInteractionOverlayDiv();
            this.audio.resume();
            this.audio.setVolume(this.speakerVolume);
            this.resumeMainVideo();
        }

        this.orientationInterval = setInterval(() => {
            var mainVid = this.getMainVideo();
            if (mainVid) {
                if (mainVid.videoWidth < mainVid.videoHeight) {
                    this.emit("UIManager:orientation", Constants.Orientation.Portrait);
                }
                else {
                    this.emit("UIManager:orientation", Constants.Orientation.Landscape);
                }
            }
        },1000);

    }

    initialise() {
        if (this.mainVideo) {
            this.mainVideo.oncontextmenu = function () {
                return false;
            };
            this.mainVideo.muted = true;
            this.mainVideo.srcObject = null;
        }
        this.createLocalVideo();
    }

    dispose() {
        clearInterval(this.orientationInterval);
        this.posters = [];
        this.audio.dispose();
        this.removeAllStreams();
    }

    removeAllStreams() {
        console.log("remove all streams");

        this.mainVideo.pause();        
        this.mainVideo.srcObject = null;
        Array.from(this.peersContainer.querySelectorAll('.peerVideo')).forEach(peerVideo => {
            peerVideo.pause();
            peerVideo.srcObject = null;
        });
        this.audio.removeAllSources();
        Array.from(this.videoTracks).forEach(videoTrack => {
            videoTrack.track.stop();
        });
        this.videoTracks = [];
    }

    setVolume(value) {
        this.speakerVolume = value;
        this.audio.setVolume(this.speakerVolume);
    }

    setAudioOutput(speaker) {
        this.audio.setOutput(speaker);
    }

    getMainPeersVideoConsumer() {
        var consumer = null;
        // check the mainvideo, if thats the mainPeerId then check its srcObject
        if (this.mainVideo.peerId === this.mainPeerId) {
            consumer = this.mainVideo;
        }
        else {
            Array.from(this.peersContainer.querySelectorAll('.peerVideo')).forEach(peerVideo => {
                if (peerVideo.peerId === this.mainPeerId) {
                    consumer = peerVideo;
                }
            });
        }
        return consumer;
    }

    getMainPeersAudioConsumer() {
        var consumer = null;
        consumer = this.audio.get(this.mainPeerId);
        return consumer;
    }

    addPeer(peerId) {
        //console.log("### UIManager addPeer:" + peerId);
        // we need to create a video
        if (!this.hidePeerVideos) {
            this.createPeerVideo(peerId);
        }
        // and an audio for this peer
        this.createPeerAudio(peerId);
    }

    removePeer(peerId) {
        //console.log("### UIManager removePeer:" + peerId);

        // stop their video track

        var theirVideoTrack = this.videoTracks[peerId] ? this.videoTracks[peerId].track : null;
        if (theirVideoTrack) {
            theirVideoTrack.stop();
            delete this.videoTracks[peerId];
        }

        // remove the video
        this.removePeerVideo(peerId);
        // if we are the Visitor and this peer that we are removing is also the mainvideo
        // then we need to stop showing it
        if (this.peerType === Constants.PeerType.Visitor && this.mainVideo.peerId === peerId) {
            this.mainVideo.srcObject = null;
            this.mainVideo = this.createMainVideo(peerId);
        }
        this.removePeerAudio(peerId);
    }

    failed(peerId) {
        if (peerId === this.mainPeerId) {
            console.log("##### FAILED #####");
            this.emit("UIManager:stateChange", "failed");

            if (this.connectingOverlay != null) {
                this.connectingOverlay.classList.remove("display");
            }
            if (this.reConnectingOverlay != null) {
                this.reConnectingOverlay.classList.add("display");
            }
            if (this.onHoldOverlay != null) {
                this.onHoldOverlay.classList.remove("display");
            }
        }
    }

    connecting(peerId) {
        if (peerId === this.mainPeerId) {
            console.log("##### CONNECTING #####");
            this.emit("UIManager:stateChange", "connecting");

            if (this.connectingOverlay != null) {
                this.connectingOverlay.classList.add("display");
            }
            if (this.reConnectingOverlay != null) {
                this.reConnectingOverlay.classList.remove("display");
            }
            if (this.onHoldOverlay != null) {
                this.onHoldOverlay.classList.remove("display");
            }
        }
    }

    connected(peerId) {
        // if we get connected to anybody then hide the warnings
        // as it should eventually drop the other peer off
        // and switch anyway
        console.log("##### CONNECTED #####");
        this.emit("UIManager:stateChange", "connected");

        if (this.connectingOverlay != null) {
            this.connectingOverlay.classList.remove("display");
        }
        if (this.reConnectingOverlay != null) {
            this.reConnectingOverlay.classList.remove("display");
        }
        if (this.onHoldOverlay != null) {
            this.onHoldOverlay.classList.remove("display");
        }
    }

    onHold() {
        console.log("##### ONHOLD #####");
        this.emit("UIManager:stateChange", "onHold");

        if (this.connectingOverlay != null) {
            this.connectingOverlay.classList.remove("display");
        }
        if (this.reConnectingOverlay != null) {
            this.reConnectingOverlay.classList.remove("display");
        }
        if (this.onHoldOverlay != null) {
            this.onHoldOverlay.classList.add("display");
        }
    }

    // because the spec says that we should not rely upon track ids matching anymore
    // even though in some browsers they do
    // I've added a new mechanism, triggered in the Connection.addTrack/removeTrack of the
    // outgoingConnection of the other peer, that sends us an event to let us know
    // when a track is added/removed (enabled/disabled)
    // it also means we can get rid of the beautiful needsHelpEndingTracks :( code
    // we are ignoring enabled for now, might use in the future
    handleAVEvent(avEvent) {
        //console.log(JSON.stringify(avEvent));
        if (!Browser.supportsTransceivers) {
            if (avEvent.avType === 'video') {
                if (!avEvent.enabled) {
                    this.removeRemoteVideoTrack(avEvent.from);
                }
            }
            else if (avEvent.avType === 'audio') {
                if (!avEvent.enabled) {
                    this.removeRemoteAudioTrack(avEvent.from);
                }
            }
        }
    }

    addPeerTracks(trackEvent) {

        var track = trackEvent.track;

        //console.log("stream contains a :" + track.kind + " track and track is muted :" + track.muted + " is enabled : " + track.enabled);
        // and add the track to the appropriate MediaElement
        if (track.kind === 'video') {

            // add this into our array
            this.videoTracks[trackEvent.peerId] = {
                track : track,
                peerType : trackEvent.peerType
            };

            // edge and safari dont support onunmute yet so still need this
            if (!Browser.supportsTransceivers) {
                this.addRemoteVideoTrack(trackEvent.peerId, trackEvent.peerType);
            }

            track.onended = event => {
                console.log("### ONENDED PeerVideo - peerId:" + trackEvent.peerId);
                this.removeRemoteVideoTrack(trackEvent.peerId);
            };

            track.onmute = event => {
                console.log("### ONMUTE PeerVideo - peerId:" + trackEvent.peerId);
                this.removeRemoteVideoTrack(trackEvent.peerId);
            };

            track.onunmute = event => {
                console.log("### ONUNMUTE PeerVideo - peerId:" + trackEvent.peerId);
                if (Browser.supportsTransceivers) {
                    this.addRemoteVideoTrack(trackEvent.peerId, trackEvent.peerType);
                }
            };

        }
        else if (track.kind === 'audio') {

            // do we still need something here for edge and safari
            if (!Browser.supportsTransceivers) {
                this.addRemoteAudioTrack(trackEvent.peerId, track, trackEvent.speakerId);
            }

            track.onended = event => {
                console.log("@@@ ONENDED audio track for:" + trackEvent.peerId);
                this.removeRemoteAudioTrack(trackEvent.peerId);
            };

            track.onmute = event => {
                console.log("@@@ ONMUTE audio track for:" + trackEvent.peerId);
                this.removeRemoteAudioTrack(trackEvent.peerId);
            };

            track.onunmute = event => {
                console.log("@@@ ONUNMUTE audio track for:" + trackEvent.peerId);
                if (Browser.supportsTransceivers) {
                    this.addRemoteAudioTrack(trackEvent.peerId, track, trackEvent.speakerId);
                }
            };
        }

    }


    addLocalTrack(stream) {
        // and add the track to the appropriate MediaElement
        // we dont want to hear ourselves so we are not going to add any audio tracks
        stream.getVideoTracks().forEach(track => {
            //console.log("stream contains a :" + track.kind + " track");

            // add this into our array
            this.videoTracks[this.peerId] = {
                track : track,
                peerType : this.peerType
            };

            // if im the agent
            if (this.peerType === Constants.PeerType.Agent) {
                // if the mainVideo is me
                // then put me back in it
                // and hide my PeerVideo
                if (typeof this.mainVideo.peerId === 'undefined' || this.mainVideo.peerId === this.peerId) {
                    this.hidePeerVideo(this.peerId);
                    //console.log("### addLocalTrack: " + this.peerId);
                    this.mainVideo.srcObject = new MediaStream([track]);
                    this.mainVideo.peerId = this.peerId;
                }
                else {
                    this.showPeerVideo(this.peerId);
                }
            }
            else {
                //if im the customer and my peer id is the main one then make sure to add it to the main
                //video object, otherwise show the peer video
                if (this.mainVideo.peerId === this.peerId) {
                    let vt = this.videoTracks[this.peerId].track;
                    this.updateMainVideoSrc(vt);
                }
                else {
                    this.showPeerVideo(this.peerId);
                }

            }
        });
    }

    createLocalVideo() {
        if (!this.hideLocalVideo) {
            var peerVideo = this.createPeerVideo(this.peerId);
            if (peerVideo != null) {
                peerVideo.style.display = 'none';
                peerVideo.muted = true;
            }
        }
    }

    hideMyPeer(){
        this.hidePeerVideo(this.peerId);
    }

    removeLocalVideo() {
        this.hidePeerVideo(this.peerId);
        if (this.peerType === Constants.PeerType.Agent) {
            // if the mainVideo is me
            //  then set its source to null
            if (this.mainVideo.peerId === this.peerId) {
                this.mainVideo.srcObject = null;
                this.mainVideo = this.createMainVideo(this.peerId);
            }
        }
        else {
            // if the mainVideo is me
            //  then switch to the mainPeerId
            //  and hide their peer
            if (this.mainVideo.peerId === this.peerId) {
                this.switchToMainPeerVideo();
            }
        }
    }

    switchToMainPeerVideo() {
        this.hidePeerVideo(this.mainPeerId);
        this.mainVideo.peerId = this.mainPeerId;
        this.mainVideo.poster = this.getPosterURL(this.mainPeerId);
        // find if they are already sending us a track
        var mainPeerVideoTrack = this.videoTracks[this.mainPeerId] ? this.videoTracks[this.mainPeerId].track : null;
        this.updateMainVideoSrc(mainPeerVideoTrack);
    }

    updateMainVideoSrc(track){
        if (track != null) { // if they have a track, switch to it
            this.mainVideo.srcObject = new MediaStream([track]);
        }
        else { // otherwise, just show the poster
            this.mainVideo.srcObject = null;
        }
    }

    getMainVideo() {
        return this.mainVideoContainer.querySelector("#main_video");
    }

    getPeerVideo(forPeerId) {
        return this.peersContainer.querySelector(`[id="peerVideo:${forPeerId}"]`);
    }

    getMediaStream(forPeerId) {
        var peerVideoTrack = this.videoTracks[forPeerId] ? this.videoTracks[forPeerId].track : null;
        if (peerVideoTrack != null) {
            //console.log(peerVideoTrack);
            return new MediaStream([peerVideoTrack]);
        }
        else {
            return null;
        }
    }

    getPeerAudio(forPeerId) {
        return this.audio.get(forPeerId);
    }

    hasPeerVideo(forPeerId) {
        var video = this.getPeerVideo(forPeerId);
        return (video && video.srcObject);
    }

    showPeerVideo(forPeerId) {
        //console.log("### showPeerVideo: " + forPeerId);
        var peerVideoEl = this.getPeerVideo(forPeerId);
        if (peerVideoEl != null) {
            peerVideoEl.srcObject = this.getMediaStream(forPeerId);
            peerVideoEl.style.display = 'inline-block';
        }
    }

    hidePeerVideo(forPeerId) {
        //console.log("### hidePeerVideo: " + forPeerId);
        var peerVideoEl = this.getPeerVideo(forPeerId);
        if (peerVideoEl != null) {
            peerVideoEl.srcObject = null;
            peerVideoEl.style.display = 'none';
            //console.log("### hidePeerVideo: " + forPeerId + " HIDDEN");
        }
        return peerVideoEl;
    }

    createPeerAudio(forPeerId) {
        var peerAudio = this.getPeerAudio(forPeerId);
        if (peerAudio == null) {
            this.audio.add(forPeerId);
        }
        return peerAudio;
    }

    createMainVideo(forPeerId) {
        //console.log("### createMainVideo: " + forPeerId);

        // remove it if it already exists
        if (this.getMainVideo()) {
            this.removeMainVideo();
        }

        // create a new video
        var videoEl = document.createElement('video');
        videoEl.id = "main_video";
        videoEl.className = "main_video";
        videoEl.poster = this.getPosterURL(forPeerId);
        videoEl.peerId = forPeerId;

        // add as first element in mainVideoContainer
        this.mainVideoContainer.insertBefore(videoEl, this.mainVideoContainer.firstChild);

        videoEl.setAttribute("autoplay", true);
        videoEl.setAttribute("playsinline", true);
        videoEl.setAttribute("muted", true);

        videoEl.oncontextmenu = function () {
            return false;
        };        
        videoEl.onloadedmetadata = e => {
            console.log("Loaded metadata for main peer video");
            var playPromise = videoEl.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log("Main Video playing");
                    this.emit("UIManager:mainVideoMediaReady");
                }).catch(error => {
                    this.emit("UIManager:mainVideoMediaFailed");
                    console.log("Main Video unable to play, error: " + error);
                });
            }
        };

        return videoEl;
    }

    resumeMainVideo() {
        if (this.mainVideo) {
            this.mainVideo.pause();
            var playPromise = this.mainVideo.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log("Main Video playing");
                    this.emit("UIManager:mainVideoMediaReady");
                }).catch(error => {
                    console.log("Main Video unable to play, error: " + error);
                    this.emit("UIManager:mainVideoMediaFailed");
                });
            }
        }
    }

    createPeerVideo(forPeerId) {
        //console.log("### createPeerVideo: " + forPeerId);

        var peerVideoEl = this.getPeerVideo(forPeerId);
        if (peerVideoEl == null) {
            // create a video
            peerVideoEl = document.createElement('video');
            peerVideoEl.id = "peerVideo:" + forPeerId;
            peerVideoEl.className = "peerVideo";

            //set the default state for a peer video to show the poster image
            //only if not the main video peer or if im an agent as I only want the customer
            //to see the thumbnail/poster image
            if (this.mainVideo.peerId === forPeerId || this.peerType === Constants.PeerType.Agent){
                peerVideoEl.style.display = 'none';
            }

            peerVideoEl.peerId = forPeerId;
            peerVideoEl.poster = this.getPosterURL(forPeerId);

            // add to remote_video_container
            this.peersContainer.appendChild(peerVideoEl);

            peerVideoEl.setAttribute("autoplay", true);
            peerVideoEl.setAttribute("playsinline", true);
            peerVideoEl.oncontextmenu = function () {
                return false;
            };

            if (!this.hidePeerVideos && !this.hideLocalVideo) {
                peerVideoEl.onclick = e => {
                    // hide me
                    this.hidePeerVideo(forPeerId);
                    // show the peer that was the main video
                    this.showPeerVideo(this.mainVideo.peerId);
                    // make me the mainVideo source

                    this.mainVideo.peerId = forPeerId;
                    this.mainVideo.poster = this.getPosterURL(forPeerId);

                    var peerVideoTrack = this.videoTracks[forPeerId] ? this.videoTracks[forPeerId].track : null;
                    if (peerVideoTrack) {
                        this.updateMainVideoSrc(peerVideoTrack);
                    }
                };
            }            
            peerVideoEl.onloadedmetadata = e => {
                console.log("Loaded metadata for peer video:" + forPeerId);
                var playPromise = peerVideoEl.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        console.log("PeerVideo playing:" + forPeerId);
                    }).catch(error => {
                        console.log("PeerVideo unable to play: " + forPeerId + " error: " + error);
                    });
                }
            };
        }
        return peerVideoEl;
    }

    removeMainVideo() {
        //console.log("removeMainVideo");
        var videoEl = this.getMainVideo();
        if (videoEl != null) {
            videoEl.srcObject = null;
            //console.log("found so removing from main video container");
            this.mainVideoContainer.removeChild(videoEl);
        }
    }

    removePeerVideo(forPeerId) {
        //console.log("removePeerVideo:" + forPeerId);
        var peerVideoEl = this.getPeerVideo(forPeerId);
        if (peerVideoEl != null) {
            peerVideoEl.srcObject = null;
            //console.log("found so Video removing from peersContainer");
            this.peersContainer.removeChild(peerVideoEl);
        }
    }

    removePeerAudio(forPeerId) {
        //console.log("removePeerAudio:" + forPeerId);
        this.audio.remove(forPeerId);
    }

    addPosterURL(forPeerId, posterURL) {
        // only add if we dont already have it
        if (!this.posters[forPeerId]) {
            this.posters[forPeerId] = posterURL;
            // add to the main
            // on safari/ios this is actually replacing a prefectly good video feed with the poster, which is why im only letting it do it once
            if (typeof this.mainVideo.peerId === 'undefined' || this.mainVideo.peerId === forPeerId) {
                this.mainVideo.poster = posterURL;
            }
            // add to the peer
            var peerVideo = this.getPeerVideo(forPeerId);
            if (peerVideo != null) {
                peerVideo.poster = posterURL;
            }
        }
    }

    getPosterURL(forPeerId) {
        var posterURL = "";
        if (this.posters[forPeerId]) {
            posterURL = this.posters[forPeerId];
        }
        console.log('poster for ' + forPeerId + ' url:' + posterURL);
        return posterURL;
    }

    switchMainPeer(forPeerId) {
        //console.log("???? switchMainPeer:" + forPeerId + " current " + this.mainVideo.peerId);

        // save away the new mainPeerId
        this.mainPeerId = forPeerId;

        // hide peerVideo for the peer
        this.hidePeerVideo(this.mainPeerId);

        setTimeout(() => {
            // show the peer that was the main video
            this.showPeerVideo(this.mainVideo.peerId);

            this.mainVideo.peerId = this.mainPeerId;
            this.mainVideo.poster = this.getPosterURL(this.mainPeerId);

            var mainVideoTrack = this.videoTracks[this.mainPeerId] ? this.videoTracks[this.mainPeerId].track : null;
            if (mainVideoTrack) {
                this.mainVideo.srcObject = null;
                setTimeout(() => {
                    //console.log("???? switchMainPeer to: " + this.mainPeerId);
                    this.mainVideo.srcObject = new MediaStream([mainVideoTrack]);
                },0);
            }
            else {
                //console.log("???? switchMainPeer videoTracks[" + this.mainPeerId + "] is empty");
                this.mainVideo.srcObject = null;
            }
        },0);
    }

    createInteractionOverlayDiv() {
        //console.log("createInteractionOverlayDiv");
        // dont show the overlay if we are already have it showing
        let overlay = this.mainVideoContainer.querySelector(".interaction-overlay");
        if (!overlay) {
            overlay = document.createElement("div");
            overlay.innerHTML = `<span class="play-btn"></span>`;
            overlay.classList.add("interaction-overlay");

            this.mainVideoContainer.insertBefore(overlay, this.mainVideoContainer.firstChild);

            userInputEventNames.forEach(eventName => {
                overlay.addEventListener(eventName, this.resumeAudioAndVideo);
            });
        }
      }

    removeInteractionOverlayDiv() {
        //console.log("removeInteractionOverlayDiv");
        let overlay = this.mainVideoContainer.querySelector(".interaction-overlay");
        if (overlay && overlay.parentNode) {
            userInputEventNames.forEach(eventName => {
                overlay.removeEventListener(eventName, this.resumeAudioAndVideo);
            });
            overlay.parentNode.removeChild(overlay);
        }
    }

    hideRemotePeers() {
        this.hidePeerVideos = true;

        for (var peerId in this.videoTracks) {
            if (peerId != this.peerId) {
                this.hidePeerVideo(peerId);
            }
        }
    }

    showRemotePeers() {
        this.hidePeerVideos = false;

        for (var peerId in this.videoTracks) {
            if (peerId != this.peerId) {
                this.showPeerVideo(peerId);
            }
        }
    }

    hideLocalPeers() {
        this.hideLocalVideo = true;
        this.hidePeerVideo(this.peerId);
    }

    showLocalPeers() {
        this.hideLocalVideo - false;
        this.showPeerVideo(this.peerId);
    }


}