<div [formGroup]="dynamicFormGroup" class="form-container">
  <ng-container *ngFor="let field of formFields">
    <app-crm-field-error
      [canEditCrm]="true"
      [field]="field"
      [canShowRequired]="true"
      [ngSwitch]="StringUtils.capitalise(field.type)"
    >
      <app-crm-field-email *ngSwitchCase="CrmField.TYPE_EMAIL"
                           [field]="field" [formControlName]="field.name">
      </app-crm-field-email>

      <app-crm-field-phone-number *ngSwitchCase="CrmField.TYPE_PHONE"
                                  [field]="field" [formControlName]="field.name">
      </app-crm-field-phone-number>

      <app-crm-field-note *ngSwitchCase="CrmField.TYPE_NOTE"
                          [field]="field" [formControlName]="field.name">
      </app-crm-field-note>

      <app-crm-field-number *ngSwitchCase="CrmField.TYPE_NUMBER"
                            [field]="field" [formControlName]="field.name">
      </app-crm-field-number>

      <app-crm-field-datetime *ngSwitchCase="CrmField.TYPE_DATETIME"
                              [field]="field" [formControlName]="field.name">
      </app-crm-field-datetime>

      <app-crm-field-choice *ngSwitchCase="CrmField.TYPE_CHOICE"
                            [field]="field" [selectControl]="dynamicFormGroup.get(field.name)">
      </app-crm-field-choice>

      <app-crm-field-checkbox *ngSwitchCase="CrmField.TYPE_CHECKBOX"
                              [field]="field" [formControlName]="field.name">
      </app-crm-field-checkbox>

      <app-crm-field-text *ngSwitchDefault
                          [field]="field" [formControlName]="field.name">
      </app-crm-field-text>
    </app-crm-field-error>
  </ng-container>
  <p-messages severity="warn" *ngIf="formFields?.length > 0">
    <ng-template pTemplate>
      {{ "APPOINTMENT_TYPE_FORM_AGENT_DISCLAIMER" | Translate: "Please ensure you have consent from the customer to use their details to invite them to the appointment and contact them via email and/or SMS" }}
    </ng-template>
  </p-messages>

</div>
