<form [formGroup]="form" (ngSubmit)="onSubmit()" class="d-flex flex-column gap-4 h-100">
  <div class="flex-grow-1 d-flex flex-column gap-2">
    <p>{{ "VERIFYCUSTOMERMODAL_INSTRUCTIONS" | Translate: "Enter a valid phone number"}}</p>
    <div class="input-group">
      <input pInputText required type="tel" class="form-control" appIntlTelInput formControlName="inputTxt" name="inputTxt" [initialCountry]="getInitialCountry()" [countryOrder]="getCountryOrder()" />
    </div>
    <div class="d-flex flex-column gap-1" *ngIf="form.controls['inputTxt'].invalid && (form.controls['inputTxt'].dirty || form.controls['inputTxt'].touched)">
      <small class="surface-red-text" *ngIf="form.controls['inputTxt'].errors?.required">
        {{ "FIELD_REQUIRED_LABEL" | Translate: "Field is required" }}
      </small>
      <small class="surface-red-text" *ngIf="form.controls['inputTxt'].errors?.invalidPhoneNumber">
        {{ "INVALID_PHONE_LABEL" | Translate: "Phone is not valid" }}
      </small>
    </div>
  </div>
  <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
    <button pButton
      id="verify-btn"
      severity="primary" [disabled]="!form.valid"
      type="submit"
    >
      {{
        "SEND_LABEL" | Translate: "Send"
      }}
    </button>
  </div>
</form>
