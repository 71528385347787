<div class="d-flex flex-row flex-grow-1 h-100 gap-1 overflow-hidden">
  <app-visitor-information [(currentSection)]="currentSection"
                           [checklistfields]="[]"
                           [currentUrl]="visitor.lastPage"
                           [userGuid]="visitor.userGuid"
                           [visitorBrowsingHistory]="(visitorBrowsingHistory$ | async)"
                           [visitorCrmData]="(visitorCrmData$ | async)"
                           [visitor]="visitor"
                           [visitorSessionHistory]="(visitorSessionHistory$ | async)"
                           class="d-flex  ">
  </app-visitor-information>

  <div (click)="onJoinPrivateChat()"
       *ngIf="privateChatAvailable"
       class="d-flex flex-column sidebar-panel justify-content-center align-items-center text-center">
    <p>{{ "SUPERVISORENGAGEMENT_LABEL_PREVIEW" | Translate : 'You are Currently Previewing the Engagement' }}</p>
    <p-button
      styleClass="p-button-text primary-color-text">{{ "SUPERVISORENGAGEMENT_BUTTON_PRIVATECHAT" | Translate : 'Click here to begin a private chat with the agent' }}
    </p-button>
    <img class="mt-5" height="150" src="./../../../../assets/images/icon-open-private-chat.svg">
  </div>

  <div *ngIf="visitor?.transcript?.length > 0" #scrollMe [scrollTop]="scrollMe.scrollHeight" class="d-flex flex-column h-100 sidebar-panel  ">
    <ng-container *ngFor="let message of visitor?.transcript">
      <app-text-chat [message]="message"></app-text-chat>
    </ng-container>
  </div>

  <div class="bottom-ctrls">
    <div class="d-flex flex-row justify-content-end gap-1">
      <p-button (clicked)="endMonitoring()"
                appDebounceClick
                pTooltip='{{ "SUPERVISORSNOOPING_TOOLTIP_EXITMONITORING" | Translate : "Exit Monitoring" }}'
                styleClass="btn-bottom-control"
                severity="danger"
                type="button">
        <fa-icon [icon]="faUserSecret"></fa-icon>
      </p-button>
      <p-button (clicked)="onJoinPrivateChat()"
                *ngIf="privateChatAvailable"
                appDebounceClick
                pTooltip='{{ "SUPERVISORSNOOPING_LABEL_JOINPRIVATECHAT" | Translate : "Join Private Chat" }}'
                styleClass="btn-bottom-control" severity="success"
                type="button">
        <fa-layers>
          <fa-icon  [icon]="faComment"></fa-icon>
          <fa-icon size="2xs" [icon]="faLock" transform="right-5 up-1" class="surface-green-text"></fa-icon>
        </fa-layers>
      </p-button>
      <p-button (clicked)="onJoinPublicChat()"
                appDebounceClick
                pTooltip='{{ "SUPERVISORSNOOPING_LABEL_PUBLICCHAT" | Translate : "Join Public Chat" }}'
                styleClass="btn-bottom-control" severity="success"
                type="button">
          <fa-icon [icon]="faRightToBracket"/>
      </p-button>
    </div>
  </div>
</div>
