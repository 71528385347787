import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth-service/auth.service';
import { CustomerSizes } from '../../services/engagement';
import { ElectronService } from '../../services/electron-service/electron.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';


@Component({
  selector: 'app-engagement-domsync-coviewer',
  templateUrl: './engagement-domsync-coviewer.component.html',
  styleUrls: ['./engagement-domsync-coviewer.component.scss']
})
export class EngagementDomSyncCoViewerComponent implements OnInit {

  @Input() isElectron: boolean;
  @Input() showBrowser: boolean;
  @Input() username: string;

  private internalCommands: Subject<string> = new Subject<string>();
  private fromCustomerCommand_: Observable<string>;
  private secretKey?: string;

  @Input() set fromCustomerCommand(fromCustomerCommand: Observable<string>) {
    this.fromCustomerCommand_ = merge(fromCustomerCommand, this.internalCommands);
  }
  get fromCustomerCommand(): Observable<string> {
    return this.fromCustomerCommand_;
  }

  @Input() currentPanelPosition: Observable<CustomerSizes>;
  @Input() useOverlayPanel: boolean;

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDomSyncCommandMessage = new EventEmitter<string>();

  public sitename: string;

  public useDomSyncElectronWindow: boolean = false;

  private disableInputs: boolean = false;

  public get domSyncUrl(): string {
    if (this.disableInputs) {
      return `${environment.staticRoot}/v24/domsync/domsync_browser_agent.html?username=${this.username}&sitename=${this.sitename}&disableInputs`;
    } else {
      return `${environment.staticRoot}/v24/domsync/domsync_browser_agent.html?username=${this.username}&sitename=${this.sitename}`;
    }
  }

  constructor(
    private readonly authService: AuthService,
    private readonly electronService: ElectronService,
    private readonly settingsService: SettingsService,
    private readonly featureService: FeatureService,
  ) {
  }

  ngOnInit() {
    this.sitename = this.authService.currentAgent.value.sitename;
    this.useDomSyncElectronWindow = this.isElectron && !this.electronService.getUseLegacyAPI();
    this.disableInputs = this.settingsService.getSiteSettingOrDefault("DomSyncDisableInputs", "false") == "true";
  }

  onDomSyncAgentReady() {
    this.onReady.emit(true);
  }

  onDomSyncToCustomerCommand(event) {
    if (event && event[0] === '|') {
      this.secretKey = event.substring(1);
      this.sendConfiguration();
    } else {
      this.onDomSyncCommandMessage.emit(event);
    }
  }

  private sendConfiguration() {
    const enabled = this.featureService.has(Features.DOMSYNC_TOOLBAR);
    const highlightColor = this.settingsService.getResourceOrDefault('ENGAGEMENT_ANNOTATIONS_HIGHLIGHTER_DEFAULT_COLOR', undefined);
    const arrowColor = this.settingsService.getResourceOrDefault('ENGAGEMENT_ANNOTATIONS_ARROW_DEFAULT_COLOR', undefined);
    const selectStyle = this.settingsService.getResourceOrDefault('ENGAGEMENT_ANNOTATIONS_SELECT_DEFAULT_STYLE', undefined);

    const timeoutStr = this.settingsService.getResourceOrDefault('ENGAGEMENT_ANNOTATIONS_TIMEOUT', undefined);
    let timeout = parseInt(timeoutStr, 10);
    if (isNaN(timeout)) {
      timeout = undefined;
    }

    const msg = {
      type: 'configuration',
      toolbar: {
        type: 'toolbar',
        enabled,
        highlightColor,
        arrowColor,
        selectStyle,
        timeout,
      }
    };

    const data = JSON.stringify({
      from: this.secretKey,
      message: msg
    });
    this.internalCommands.next(data);
  }
}
