
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SettingsService} from './settings-service/settings.service';
import {StringUtils} from '../utils/string-utils';
import {CrmField} from './crm-service/crm-field';


@Injectable({
  providedIn: 'root'
})
export class CrmFieldFormService {

  constructor(
    private readonly fb: FormBuilder,
    private readonly settingsService: SettingsService
  ) { }

  createFormGroup(formFields: CrmField[]): FormGroup {
    const dynamicFormGroup = this.fb.group({});
    formFields.forEach((field) => {
      this.addFieldToFormGroup(dynamicFormGroup, field);
    });
    return dynamicFormGroup;
  }

  private addFieldToFormGroup(dynamicFormGroup: FormGroup, field: CrmField): void {
    field.value = '';
    this.addFieldTypeSpecificSettings(field);
    this.addTranslation(field);
    const validators = this.addValidators(field);
    const control = this.fb.control('', validators);
    dynamicFormGroup.addControl(field.name, control);
  }

  private addValidators(field: CrmField): any[] {
    const validators = [];
    const type = StringUtils.capitalise(field.type);
    if (field.required === '1') {
      validators.push(Validators.required);
      if (type === CrmField.TYPE_CHECKBOX) {
        validators.push(Validators.requiredTrue);
      }
    }

    if (type === CrmField.TYPE_EMAIL) {
      validators.push(Validators.email);
    }
    return validators;
  }

  private addFieldTypeSpecificSettings(field: CrmField): void {
    switch (field.name?.toLowerCase()) {
      case 'email':
        field.type = CrmField.TYPE_EMAIL;
        break;
      case 'phone number':
        field.type = CrmField.TYPE_PHONE;
        break;
    }
    if ((field as unknown as any)?.options) {
      field.choices = (field as unknown as any).options;
    }
  }

  private addTranslation(field: CrmField): void {
    const resourceKey = `CRM_FIELD:${field.name}`;
    const defaultTitle = field.name.replace(/([A-Z]+)/g, ' $1').trim();
    field.translatedTitle = this.settingsService.getResourceOrDefault(resourceKey, defaultTitle);
  }
}
