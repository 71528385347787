import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs';
import { TranslateService } from  '@ngx-translate/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  public passwordResetForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router,
    translate: TranslateService
  ) {
    this.createForm();
    //using angular library
    translate.addLangs(["en-US", "fr-FR","de-DE","ja"]);
    translate.setDefaultLang('en-US'); // this language will be used as a fallback when a translation isn't found in the current language

    let browserLang = navigator.language;//Get current Browser language;
    translate.use(browserLang.match(/en-US|fr-FR|de-DE|ja/) ? browserLang : 'en-US');
  }

  ngOnInit() {}

  private createForm(): void {
    this.passwordResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public submit(): void {
    if (this.passwordResetForm.valid) {

      this.loadingService.isLoading.next(true);

      // TODO call the auth service
      const {email} = this.passwordResetForm.value;

      this.subscriptions.push(
        this.authService.forgottenPassword(email).subscribe(success => {
          if (success) {
            this.alertService.addAlert('Please check your email for a reset link', AlertType.Success);
            this.router.navigateByUrl('/login');
          } else {
            this.alertService.addAlert('Error resetting your password, please try again.', AlertType.Danger);
          }
          this.loadingService.isLoading.next(false);
        })
      );
    } else {
      this.loadingService.isLoading.next(false);
      this.alertService.addAlert('Your email is invalid, try again.', AlertType.Danger);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
