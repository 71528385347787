import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteVisitor} from '../../../services/visitor-service/SiteVisitor';
import { BehaviorSubject } from 'rxjs';
import { VisitorDetails } from '../../../classes/visitor-details';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EndChatModalComponent, EndType } from '../../../components/end-chat-modal/end-chat-modal.component';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import { CrmService } from '../../../services/crm-service/crm.service';
import { Opportunity } from '../../../services/crm-service/opportunity';
import { CrmEmailState } from '../../../services/crm-service/crm-email-state';
import {ModalService} from '../../../services/modal.service';
import {Section} from "../../../components/visitor-information/visitor-information.component";
import {faComment, faLock, faRightToBracket, faUserSecret} from "@fortawesome/free-solid-svg-icons";
import {TranslatePipe} from "../../../filters/Translate.pipe";

@Component({
  selector: 'app-supervisor-snooping',
  templateUrl: './supervisor-snooping.component.html',
  styleUrls: ['./supervisor-snooping.component.scss']
})
export class SupervisorSnoopingComponent implements OnInit {
  private modalRef: DynamicDialogRef;

  private _visitor: SiteVisitor;
  @Input() set visitor(visitor: SiteVisitor) {
    if (!this._visitor || this._visitor.userGuid !== visitor.userGuid) {
      this._visitor = visitor;

      if (visitor) {

        visitor.crmData.subscribe(crmData => {
          if (crmData != null) {
            this.visitorCrmData$.next(crmData);
          }
        });

        visitor.sessionHistory.subscribe(history => {
          if (history != null) {
            this.visitorSessionHistory$.next(history);
          }
        });

        visitor.browsingHistory.subscribe(history => {
          if (history != null) {
            this.visitorBrowsingHistory$.next(history);
          }
        });

        if (this.IsCRMOpportunityAvailable()) {
          const opp = this.crmService.getOpportunitiesForContact(visitor.userGuid).subscribe(op => {
            if (op != null) {
              this.opportunities = Array.from(op);
            }
          });
        }

      } else {
        this._visitor = null;
      }
    }
  }
  get visitor(): SiteVisitor {
    return this._visitor;
  }

  @Input() privateChatAvailable: boolean;

  public visitorCrmData$: BehaviorSubject<CrmStructure> = new BehaviorSubject(new CrmStructure());
  public visitorSessionHistory$: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);
  public visitorBrowsingHistory$: BehaviorSubject<BrowsingHistory[]> = new BehaviorSubject<BrowsingHistory[]>([]);
  public opportunities: Opportunity[];

  public currentSection: Section = Section.Contact;
  CrmEmailState = CrmEmailState;

  @Output() joinPrivateChat: EventEmitter<void> = new EventEmitter<void>();
  @Output() joinPublicChat: EventEmitter<void> = new EventEmitter<void>();
  @Output() stopSnooping: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private crmService: CrmService,
    private translate: TranslatePipe
  ) {
  }

  ngOnInit() {
  }


  IsCRMOpportunityAvailable(): boolean {
    return true;
  }

  onJoinPrivateChat() {
    this.joinPrivateChat.emit();
  }

  onJoinPublicChat() {
    if (this.privateChatAvailable) {
      this.showJoinPublicChatModal();
    } else {
      this.joinPublicChat.emit();
    }
  }

  endMonitoring() {
    this.showEndMonitoringModal();
  }

  showEndMonitoringModal() {
    const data = {
      endChatType: EndType.END_MONITORING,
      onEndMonitoring: () => {
        this.stopSnooping.emit();
        this.closeModal();
      },
      cancelEndChat: () => {
        this.closeModal();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      header: this.translate.transform('ENDCHATMODAL_HEADER_FINISHMONITORING','Finished Monitoring?'),
      closable: false,
      contentStyle: { width: '500px' }
    });
  }

  showJoinPublicChatModal() {
    const data = {
      endChatType: EndType.JOIN_PRIVATE_CHAT,
      onJoinPublicChat: () => {
        this.closeModal();
        this.joinPublicChat.emit();
      },
      onJoinPrivateChat: () => {
        this.closeModal();
        this.joinPrivateChat.emit();
      },
      cancelEndChat: () => {
        this.closeModal();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_ENTERCHAT', 'Are you sure you want to enter the chat?'),
      contentStyle: { width: '500px' }
    });
  }

  private closeModal() {
    this.modalService.closeModal(this.modalRef);
    this.modalRef = null;
  }

  protected readonly faUserSecret = faUserSecret;
  protected readonly faRightToBracket = faRightToBracket;
  protected readonly faComment = faComment;
  protected readonly faLock = faLock;
}
