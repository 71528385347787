<div class="d-flex veechat-engagement w-100 h-100">
  <div class="d-flex flex-row flex-grow-1 engagementMainContainer gap-2"
       [class.post-engagement]="(currentState$ | async)?.type === EngagementState.Post">

    <app-engagement-center-console class="h-100 flex-grow-1 main-layout"
                                   [engagement]="engagement"
                                   [engagementId]="engagement?.engagementId.toString()"
                                   [currentState]="(currentState$ | async)"
                                   [currentUrl]="(currentUrl$ | async)"
                                   [isPresentingAgent]="isPresentingAgent | async"
                                   [visitorCrmData]="visitorCrmData$ | async"
                                   [isPrimaryAgent]="isPrimaryAgent | async"
                                   [emailState]="emailState | async"
                                   [visitor]="visitor$ | async"
                                   [checklistfields]="checklistfields | async"
                                   [visitorSessionHistory]="visitorSessionHistory$ | async"
                                   [visitorBrowsingHistory]="visitorBrowsingHistory$ | async"
                                   [currentPanelPosition]="currentPanelPositionAndSize$"
                                   [currentSection]="currentSection"
                                   [userGuid]="engagement?.visitor?.userGuid"
                                   [privateChatAvailable]="privateChatAvailable"
                                   [privateChatEnabled]="(privateChatEnabled | async)"
                                   [unreadPrivateMessages]="(unreadPrivateMessages | async)"
                                   [privateChatMessages]="(privateChatMessages | async)"
                                   [isElectron]="false"
                                   [isSharing]="false"
                                   [showingModal]="(showingModal$ | async)"
                                   [showBrowserBar]="true"
                                   [showBrowserBarControls]="useCoBrowsing"
                                   (saveOpportunity)="saveOpportunity($event)"
                                   (saveCrmData)="saveCrmData()"
                                   (loadCustomer)="loadCustomer($event)"
                                   (sendPrivateMessage)="sendPrivateChatMessage($event)"
                                   [useCoBrowse]="(this.engagement.allowBrowsing | async)"
                                   [canCoBrowse]="false">
    </app-engagement-center-console>


    <ng-container *ngIf="(currentState$ | async)?.type === EngagementState.Post; then post else engaged">
    </ng-container>
    <ng-template #engaged>
      <div [ngSwitch]="(currentState$ | async)?.type" class="sidebar-panel  ">

        <div class="d-flex flex-column w-100 h-100" *ngSwitchCase="EngagementState.Join">
          <app-engagement-join class="w-100 engagement-controls"
                               [engagement]="engagement"
                               [presentingAgent]="(presentingAgent$ | async)" [primaryAgent]="(primaryAgent$ | async)"
                               [currentState]="(currentState$ | async)"
                               (invitationRequest)="joinRequest($event)"
                               (customerInvitationRequest)="customerJoinRequest($event)"
                               (cancelJoinInvite)="cancelJoin()" (makePresenter)="changePresenter($event)"
                               (kickAgent)="kickAgent($event)">
          </app-engagement-join>
        </div>

        <div class="d-flex flex-column w-100 h-100" *ngSwitchCase="EngagementState.Transfer">
          <app-engagement-transfer
            class="h-100 w-100 engagement-controls flex-grow-1"
            [currentState]="(currentState$ | async)"
            [roomId]="engagementId"
            [textMessages]="engagement?.transferChatMessages"
            (inviteRequest)="transferRequest($event)"
            (cancelInvite)="cancelTransfer()">
          </app-engagement-transfer>
        </div>

        <div class="d-flex flex-column h-100 w-100 justify-content-center" *ngSwitchCase="EngagementState.Idle">
          <div class="text-center">{{ 'LOADING_LABEL' | Translate : 'Loading...'}}</div>
        </div>

        <app-engagement-chat-tabset *ngSwitchCase="EngagementState.Engaged"
                                    class="d-flex flex-shrink-0 flex-grow-1 h-100"
                                    [engagement]="engagement"
                                    [messages]="engagement.messages"
                                    [previewMessage]="(visitorMessage$ | async)"
                                    [files]="(fileTransfers$ | async)"
                                    (uploadFiles)="uploadFiles($event)"
                                    (cancelFilesTransfer)="cancelFileTransfer($event)"
                                    [translationOn]="(translationOn$ | async)"
                                    (setTranslation)="setTranslation($event)"
                                    [getSuggestionOn]="(getSuggestionOn$ | async)"
                                    (setGetSuggestion)="setGetSuggestion($event)"
                                    [autoReplyOn]="(autoReplyOn$ | async)"
                                    (setAutoReply)="setAutoReply($event)"
                                    [agentText]="engagement.agentText"
                                    (agentTextChange)="agentTextChange($event)"
                                    [privateChatMessages]="(privateChatMessages | async)"
                                    (sendPrivateMessage)="sendPrivateChatMessage($event)"
                                    [unreadPrivateMessages]="(unreadPrivateMessages | async)"
                                    [privateChatAvailable]="privateChatAvailable"
                                    [privateChatEnabled]="(privateChatEnabled | async)"
                                    [isClosed]="(currentState$ | async)?.type === EngagementState.Post"
                                    [unreadMessages]="(unreadMessages | async)"
                                    (sendMessage)="sendMessage($event)"
                                    [sendButtonDisabled]="!(engagement?.isConnected | async)"
                                    (checkListName)="getCheckListFields($event)"
                                    (savePost)="savePost($event)">
        </app-engagement-chat-tabset>
      </div>

    </ng-template>
    <ng-template #post>
      <app-engagement-post
        class="h-100 sidebar-panel  "
        [engagement]="engagement"
        (submitPost)="onPostEngagementSubmit($event)"
        (checkListName)="getCheckListFields($event)">
      </app-engagement-post>
    </ng-template>


  </div>

  <app-veechat-engagement-controls
    [verifyStatus]="(engagement.primaryVisitor$ | async)?.verifyStatus"
    [currentState]="(currentState$ | async)?.type"
    [isBlockedOn]="(isBlockedOn | async)"
    [isPrimaryAgent]="(isPrimaryAgent | async)"
    (toggleBlockCustomer)="showBlockCustomerModal()"
    [isMobileSDK]="engagement?.visitor.isMobileSdk"
    [roomAgentsSize]="agents.size"
    [roomVisitorsSize]="visitors.size"
    [requestingHelp]="(requestingHelp$ | async)"
    [privateChatAvailable]="privateChatAvailable"
    (toggleHelpRequest)="toggleHelpRequest()"
    (toggleJoinCall)="selectJoiningOperator()"
    (toggleTransferCall)="selectTransferringOperator()"
    (engagementEnded)="showEndChatModal()"
    (toggleVerifyCustomer)="showVerifyCustomerModal()">
  </app-veechat-engagement-controls>


</div>
