<div class="d-flex flex-row">
  <ng-container *ngIf="primaryVisitor$ | async as primaryVisitor">
    <app-user-heartbeat class="flex-grow-1 d-flex"
                        [currentPage]="currentPage"
                        [engagementVisitor]="primaryVisitor">
    </app-user-heartbeat>
  </ng-container>

  <!-- Can see me -->
  <div id="cansee" class="flex-grow-1 d-flex center-icon"
       [class.surface-green]="(userCanSeeMe$ | async)"
       [class.surface-red]="!(userCanSeeMe$ | async)"
       [pTooltip]="(userCanSeeMe$ | async) ? ('ENGAGEMENTTOOLBAR_TITLE_USERCANSEE' | Translate:  'User Can See Me') : ('ENGAGEMENTTOOLBAR_TITLE_USERCANNOTSEE' | Translate:  'User Can Not See Me') ">
    <fa-icon [fixedWidth]="true"  *ngIf="(userCanSeeMe$ | async)" [icon]="faEye"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="!(userCanSeeMe$ | async)" [icon]="faEyeSlash"></fa-icon>
  </div>

  <!-- Can hear me -->
  <div id="canhear" class="flex-grow-1 d-flex center-icon"
       [class.surface-green]="(userCanHearMe$ | async)"
       [class.surface-red]="!(userCanHearMe$ | async)"
       [pTooltip]="(userCanHearMe$ | async) ? ('ENGAGEMENTTOOLBAR_TITLE_USERCANHEAR' | Translate:  'User Can Hear Me')  : ('ENGAGEMENTTOOLBAR_TITLE_USERCANNOTHEAR' | Translate:  'User Can Not Hear Me')">
    <fa-icon [fixedWidth]="true"  *ngIf="(userCanHearMe$ | async)" [icon]="faVolumeUp"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="!(userCanHearMe$ | async)" [icon]="faVolumeMute"></fa-icon>
  </div>

</div>
