import {Component, Input} from '@angular/core';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import { Observable } from 'rxjs';
import {SiteVisitor} from '../../../services/visitor-service/SiteVisitor';
import {VisitorDetails} from '../../../classes/visitor-details';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import { map } from 'rxjs/operators';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import {SettingsService} from '../../../services/settings-service/settings.service';
import {LoggingService} from '../../../services/logging.service';
import {AsyncMessageHandler} from '../../../services/async-message-handler';

@Component({
  selector: 'app-veechat-visitor',
  templateUrl: './veechat-visitor.component.html',
  styleUrls: ['./veechat-visitor.component.scss']
})
export class VeechatVisitorComponent  {

  @Input() username: string;

  private _visitor: SiteVisitor;
  @Input() set visitor(visitor: SiteVisitor) {
    if (!this._visitor || this._visitor.userGuid !== visitor.userGuid) {
      this._visitor = visitor;

      if (visitor) {
        this.visitorBrowsingHistory$ = visitor.browsingHistory.asObservable();
        this.visitorSessionHistory$ = visitor.sessionHistory.asObservable();
        this.visitorSessionDetails$ = visitor.details.asObservable();
        this.visitorCRMData$ = visitor.crmData;
        this.selectedUserGuid = visitor.userGuid;

        if (visitor.isAsync) {
          this.visitorService.getAsyncMessages(
            { Id: visitor.sessionGuid },
            { Id: visitor.userGuid },
            false).subscribe(msgs => {
              const messageHandler = new AsyncMessageHandler(this.logging, this.settingService, this.username, visitor.sessionGuid);
              messageHandler.handleMessages(msgs);
              visitor.transcript = messageHandler.Messages.messages;
            });
        }
       }

    }
  }
  get visitor(): SiteVisitor {
    return this._visitor;
  }

  public visitorCRMData$: Observable<CrmStructure>;
  public visitorSessionDetails$: Observable<VisitorDetails> ;
  public visitorBrowsingHistory$: Observable<BrowsingHistory[]>;
  public visitorSessionHistory$: Observable<EngagementEvent[]>;

  public selectedUserGuid: string = null;


  constructor(
    private readonly visitorService: VisitorService,
    private readonly logging: LoggingService,
    private readonly settingService: SettingsService,
  ) {
  }


  public showTranscript(visitor:SiteVisitor): boolean {
    if (visitor && visitor?.transcript.length > 0 && (visitor.isEngaged || visitor.isAsync)) {
      if (visitor.beingTransferred && visitor.isAsync) {
        return true;
      }

      if (!visitor.beingTransferred && !visitor.isMultiparty) {
        return true;
      }
    }
    return false;
  }
}
