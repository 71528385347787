import Browser from "./Browser";
import Constants from "./Constants";
import { InputCapturer } from "./screensharing/InputCapturer";

export default class ShareUIManager {
    constructor(peerId, peerType, shareContainer, connectingOverlay, reConnectingOverlay, onHoldOverlay) {
        this.peerId = peerId;
        this.peerType = peerType;
        this.shareContainer = shareContainer;
        this.connectingOverlay = connectingOverlay;
        this.reConnectingOverlay = reConnectingOverlay;
        this.onHoldOverlay = onHoldOverlay;
        this.shareTracks = [];

        this.inputCapturer = undefined;

        this.createLocalVideo();
    }

    createLocalVideo() {
        var peerVideo = this.createSharePeerVideo(this.peerId);
        if (peerVideo != null) {
            peerVideo.muted = true;
        }
    }

    createSharePeerVideo(forPeerId) {
        if(this.shareContainer){

            var peerVideoEl = this.getShareVideo(forPeerId);
            if (peerVideoEl == null) {

                peerVideoEl = document.createElement('video');
                peerVideoEl.id = "shareVideo";
                peerVideoEl.className = "shareVideo";
                peerVideoEl.peerId = forPeerId;
                peerVideoEl.tabIndex = 1;
                
                // Construct the input capturer only for the visitor
                if (this.peerType === Constants.PeerType.Visitor) {
                    const mobileTextPanel = document.createElement('div');
                    this.shareContainer.insertBefore(mobileTextPanel, this.shareContainer.firstChild);
                    this.inputCapturer = new InputCapturer(peerVideoEl, mobileTextPanel);
                }

                this.shareContainer.insertBefore(peerVideoEl, this.shareContainer.firstChild);

                peerVideoEl.setAttribute("autoplay", true);
                peerVideoEl.setAttribute("playsinline", true);
                peerVideoEl.setAttribute("controls", true);
                peerVideoEl.oncontextmenu = function () {
                    return false;
                };

                setTimeout(() => peerVideoEl.removeAttribute("controls"));
            }

            this.updateVideoSrc(forPeerId);

            return peerVideoEl;
        }
    }

    dispose() {
        if(this.shareVideo){
            this.shareVideo.pause();
            this.shareVideo.srcObject = null;
            this.shareTracks = [];
        }

        if (this.inputCapturer) {
            this.inputCapturer.dispose();
            this.inputCapturer = undefined;
        }
    }

    failed() {
            console.log("##### FAILED #####");

            if (this.connectingOverlay != null) {
                this.connectingOverlay.classList.remove("display");
            }
            if (this.reConnectingOverlay != null) {
                this.reConnectingOverlay.classList.add("display");
            }
            if (this.onHoldOverlay != null) {
                this.onHoldOverlay.classList.remove("display");
            }
    }

    connecting() {
            console.log("##### CONNECTING #####");

            if (this.connectingOverlay != null) {
                this.connectingOverlay.classList.add("display");
            }
            if (this.reConnectingOverlay != null) {
                this.reConnectingOverlay.classList.remove("display");
            }
            if (this.onHoldOverlay != null) {
                this.onHoldOverlay.classList.remove("display");
            }
    }

    connected() {
        // if we get connected to anybody then hide the warnings
        // as it should eventually drop the other peer off
        // and switch anyway
        console.log("##### CONNECTED #####");

        if (this.connectingOverlay != null) {
            this.connectingOverlay.classList.remove("display");
        }
        if (this.reConnectingOverlay != null) {
            this.reConnectingOverlay.classList.remove("display");
        }
        if (this.onHoldOverlay != null) {
            this.onHoldOverlay.classList.remove("display");
        }

        if (this.inputCapturer) {
            this.inputCapturer.resume();
        }
    }

    onHold() {
        console.log("##### ONHOLD #####");

        if (this.connectingOverlay != null) {
            this.connectingOverlay.classList.remove("display");
        }
        if (this.reConnectingOverlay != null) {
            this.reConnectingOverlay.classList.remove("display");
        }
        if (this.onHoldOverlay != null) {
            this.onHoldOverlay.classList.add("display");
        }

        if (this.inputCapturer) {
            this.inputCapturer.pause();
        }
    }

    // because the spec says that we should not rely upon track ids matching anymore
    // even though in some browsers they do
    // I've added a new mechanism, triggered in the Connection.addTrack/removeTrack of the
    // outgoingConnection of the other peer, that sends us an event to let us know
    // when a track is added/removed (enabled/disabled)
    handleAVEvent(avEvent) {

        if (!Browser.supportsTransceivers) {
            if (avEvent.avType === 'video') {
                if (!avEvent.enabled) {
                    this.resetVideoSrc(this.peerId);
                }
            }
        }
    }

    addPeerTracks(trackEvent) {

        var track = trackEvent.track;

        // add this into our array
        this.shareTracks[trackEvent.peerId] = track;

        // edge and safari dont support onunmute yet so still need this
        if (!Browser.supportsTransceivers) {
            this.updateVideoSrc(trackEvent.peerId);
        }

        track.onended = () => this.resetVideoSrc(trackEvent.peerId);
        //Commented out due to RDM-4018
        //track.onmute = () => this.resetVideoSrc(trackEvent.peerId);
        track.onunmute = () => {
            if (Browser.supportsTransceivers) {
                this.updateVideoSrc(trackEvent.peerId);
            }
        };

    }

    addLocalTrack(track){
        this.shareTracks[this.peerId] = track;
        this.updateVideoSrc(this.peerId);
    }

    getShareVideo() {
        return this.shareContainer && this.shareContainer.querySelector("#shareVideo");
    }

    getShareMediaStream(forPeerId) {
        var peerVideoTrack = this.shareTracks[forPeerId];
        if (peerVideoTrack != null) {
            return new MediaStream([peerVideoTrack]);
        }
        return null;
    }

    updateVideoSrc(forPeerId){
        var peerVideoEl = this.getShareVideo();
        if (peerVideoEl != null && peerVideoEl.peerId != forPeerId) {
            var newStream = this.getShareMediaStream(forPeerId);
            if(newStream && newStream.active){
                peerVideoEl.srcObject = newStream;
                peerVideoEl.peerId = forPeerId;
            }

        }
    }

    resetVideoSrc(forPeerId){
        var peerVideoEl = this.getShareVideo();
        if (peerVideoEl != null && peerVideoEl.peerId === forPeerId) {
            peerVideoEl.srcObject = null;
        }
    }




}