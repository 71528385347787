import {VisitorStatus} from '../enums/EngagementStatus.enum';
import {
  faArrowRightArrowLeft,
  faBell,
  faComments, faHand,
  faPhoneVolume, faQuestion,
  faSms, faSquarePollVertical, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faFacebookMessenger, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Channel, Channels} from "../services/visitor-service/Channel";

export class CallStatuses {


  public static readonly STATUS_FONT_ICON_LOOKUP: Map<VisitorStatus, {icon: IconDefinition, css: string}> = new Map([
    [VisitorStatus.Waiting, { icon: faBell, css: 'circle-sm surface-orange'}],
    [VisitorStatus.Assist,  { icon: faBell, css: 'circle-sm surface-red'}],
    [VisitorStatus.Callback, { icon: faPhoneVolume, css: 'circle-sm surface-orange'}],
    [VisitorStatus.Engaged, { icon: faComments, css: 'circle-sm surface-orange'}],
    [VisitorStatus.Joining, { icon: faUserPlus, css: 'circle-sm surface-blue'}],
    [VisitorStatus.Transferring, { icon: faArrowRightArrowLeft, css: 'circle-sm surface-blue'}],
    [VisitorStatus.Ended, { icon: faSquarePollVertical, css: 'circle-sm surface-orange'}],
    [VisitorStatus.AgentRequestingHelp, { icon: faHand, css: 'circle-sm surface-red'}],
    [VisitorStatus.WhatsApp, { icon: faWhatsapp, css: 'circle-sm surface-green font-size-2'}],
    [VisitorStatus.Sms, { icon: faSms, css: 'circle-sm surface-grey font-size-2'}],
    [VisitorStatus.Messenger, { icon: faFacebookMessenger, css: 'circle-sm surface-purple'}],
    [VisitorStatus.IdleTransfer, { icon: faArrowRightArrowLeft, css: 'circle-sm surface-blue'}],
  ]);

  public static readonly STATUS_CHANNEL_FONT_ICON_LOOKUP: Map<Channels, {icon: IconDefinition, css: string}> = new Map([
    [Channels.WhatsApp, { icon: faWhatsapp, css: 'circle-sm surface-green font-size-2'}],
    [Channels.Messenger, { icon: faFacebookMessenger, css: 'circle-sm surface-purple'}],
    [Channels.Sms, { icon: faSms, css: 'circle-sm surface-grey font-size-2'}],
  ]);

  public static readonly STATUS_ICON_LOOKUP: Map<VisitorStatus, string> = new Map([
    [VisitorStatus.Waiting, '/assets/call-status-icons/Assist.svg'],
    [VisitorStatus.Assist, '/assets/call-status-icons/Assist.svg'],
    [VisitorStatus.Callback, '/assets/call-status-icons/C.svg'],
    [VisitorStatus.Engaged, '/assets/call-status-icons/Engaged.svg'],
    [VisitorStatus.Joining, '/assets/call-status-icons/Multiparty.svg'],
    [VisitorStatus.Transferring, '/assets/call-status-icons/Transfer.svg'],
    [VisitorStatus.Ended, '/assets/call-status-icons/Post.svg'],
    [VisitorStatus.AgentRequestingHelp, '/assets/call-status-icons/handraise_btn.svg'],
    [VisitorStatus.WhatsApp, '/assets/call-status-icons/whatsapp-logo.svg'],
    [VisitorStatus.Sms, '/assets/call-status-icons/sms-icon.svg'],
    [VisitorStatus.Messenger, '/assets/call-status-icons/facebook-messenger.svg'],
    [VisitorStatus.IdleTransfer, '/assets/call-status-icons/Transfer.svg'],
  ]);

  public static readonly STATUS_TEXT_LOOKUP: Map<VisitorStatus, string> = new Map([
    [VisitorStatus.Assist, 'Assist'],
    [VisitorStatus.Engaged, 'Engaged'],
    [VisitorStatus.Callback, 'Callback'],
    [VisitorStatus.Waiting, 'Waiting'],
    [VisitorStatus.Transferred, 'Transferred'],
    [VisitorStatus.MaybeLater, 'MaybeLater'],
    [VisitorStatus.Ended, 'Ended'],
    [VisitorStatus.Blocking, 'Blocking'],
    [VisitorStatus.Joining, 'Multiparty'],
    [VisitorStatus.Transferring, 'Transferring'],
    [VisitorStatus.Timeout, 'Timeout'],
    [VisitorStatus.AgentRequestingHelp, 'Agent Requesting Help'],
    [VisitorStatus.WhatsApp, 'WhatsApp'],
    [VisitorStatus.Sms, 'SMS'],
    [VisitorStatus.Messenger, 'Messenger'],
    [VisitorStatus.IdleTransfer, 'Transferring'],
  ]);

  public static readonly UNKNOWN_STATUS_ICON = '32x32_redcross.png';
  public static readonly UNKNOWN_STATUS_FONT_ICON =  faQuestion;

  public static getStatusIcon(status: VisitorStatus) {
    const result = CallStatuses.STATUS_ICON_LOOKUP.get(status);
    return result ? result : CallStatuses.UNKNOWN_STATUS_ICON;
  }

  public static getStatusText(status: VisitorStatus) {
    const result = CallStatuses.STATUS_TEXT_LOOKUP.get(status);
    return result ? result : 'Unknown';
  }

  public static getStatusFontIcon(status: VisitorStatus) {
    return CallStatuses.STATUS_FONT_ICON_LOOKUP.get(status) || {icon: CallStatuses.UNKNOWN_STATUS_FONT_ICON, css: 'circle-sm surface-grey'};
  }

  public static getStatusFontIconForChannel(channel: Channels) {
    return CallStatuses.STATUS_CHANNEL_FONT_ICON_LOOKUP.get(channel) || {icon: CallStatuses.UNKNOWN_STATUS_FONT_ICON, css: 'circle-sm surface-grey'};
  }
}
