import { DesktopRecorderConfig } from '../desktop-recording-service/desktop-recording.service';
import { ELECTRON_APP_EVENTS } from "./electron-constants";
import { WindowMessageService } from '../window-message-service/window-message.service';
export class RecordingProxy {
  constructor(private windowMessagingService: WindowMessageService) {
  }
  public setup(config: DesktopRecorderConfig) {
    this.send(ELECTRON_APP_EVENTS.RECORDING_SETUP, config);
  }
  public start(engagementId: string, operator: string) {
    this.send(ELECTRON_APP_EVENTS.RECORDING_START, {
      engagementId,
      operator
    });
  }
  public stop() {
    this.send(ELECTRON_APP_EVENTS.RECORDING_STOP);
  }
  public pause() {
    this.send(ELECTRON_APP_EVENTS.RECORDING_PAUSE);
  }
  public resume() {
    this.send(ELECTRON_APP_EVENTS.RECORDING_RESUME);
  }
  private send(action, data?) {
    this.windowMessagingService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action,
      data
    }, "*");
  }
}
