
const Type = Object.freeze({
    None: "none",
    Blur: "blur",
    Image: "image",
    Video: "video"
});
export default class BackgroundConfig {

    constructor({
        resourcesPath = '', // the location for the google selfie segmentation files.  e.g., https://fenton.vee24.com:8443/lib/resources
        type = Type.None,
        url = null
    } = {}) {

        this.resourcesPath = resourcesPath;
        this.type = type;
        this.url = url;
    }

    static get Type() {
        return Type;
    }
}
