<div class="h-100 overflow-hidden d-flex flex-column">
  <p-tabMenu [activeItem]="activeItem" [model]="searchModes" styleClass="justify-end"></p-tabMenu>

  <ng-container *ngIf="searchCustomer; then CustomerSearch else OpportunitySearch"></ng-container>

  <ng-template #CustomerSearch>
    <p-toolbar styleClass="mb-2 flex-nowrap px-2">
      <div class="p-toolbar-group-start gap-4 align-items-center">
        <label for="name">{{ "CRMSEARCH_LABEL_CUSTOMERNAME" | Translate : 'Customer Name' }}
          <input pInputText [(ngModel)]="cName"  id="name" name="search-field" type="text">
        </label>


        <label for="email">{{ "CRMSEARCH_LABEL_EMAIL"| Translate : 'Email' }}
          <input pInputText [(ngModel)]="cEmail"  id="email" name="search-field" type="text">
        </label>


        <label for="phone-number">{{ "CRMSEARCH_LABEL_PHONENUMBER"| Translate : 'Phone Number' }}
          <input pInputText [(ngModel)]="cPhone"  id="phone-number" name="search-field" type="text">
        </label>

        <label for="startDate">{{ "CRMSEARCH_LABEL_STARTDATE"| Translate : 'Start date' }}
          <input  pInputText [(ngModel)]="startDate"  id="startDate" type="date">
        </label>

        <label for="endDate">{{ "CRMSEARCH_LABEL_ENDDATE"| Translate : 'End date' }}
          <input pInputText [(ngModel)]="endDate"  id="endDate" type="date">
        </label>

      </div>
      <div class="p-toolbar-group-end gap-2 align-items-center">

        <p-button id="search-button" (click)="filterData()" [pTooltip]="'CRMSEARCH_LABEL_SEARCH' | Translate : 'Search'"
                  styleClass="btn-rectangle center-icon ">
            <fa-icon [icon]="faSearch"></fa-icon>
        </p-button>
        <p-button (click)="onAddCustomer()"
                  [pTooltip]="'CRMSEARCH_LABEL_ADDCUSTOMER' | Translate : 'Add New Customer'"
                  styleClass="btn-rectangle center-icon p-button-success">
            <fa-icon [icon]="faUserPlus"></fa-icon>
        </p-button>
      </div>
    </p-toolbar>

    <section class="overflow-hidden">
    <p-table (onLazyLoad)="onLazyLoad($event)" (onRowSelect)="onSelectUser($event)" (onSort)="onSort($event)"
             [lazy]="true" [loading]="loading"
             [paginator]="true" [rows]="30" [scrollable]="true" [showCurrentPageReport]="true"
             [totalRecords]="count | async"
             [value]="(rows | async)" scrollHeight="flex" selectionMode="single"
             styleClass="px-2">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="customerName">{{ "CRMSEARCH_LABEL_CUSTOMERNAME" | Translate : 'Customer Name' }}
            <p-sortIcon field="customerName"></p-sortIcon>
          </th>
          <th pSortableColumn="customerEmail">{{ "CRMSEARCH_LABEL_EMAIL"| Translate : 'Email' }}
            <p-sortIcon field="customerEmail"></p-sortIcon>
          </th>
          <th pSortableColumn="customerPhone">{{ "CRMSEARCH_LABEL_PHONENUMBER"| Translate : 'Phone Number' }}
            <p-sortIcon field="customerPhone"></p-sortIcon>
          </th>
          <th>{{ "CRMSEARCH_LABEL_NOTES"| Translate : 'Notes' }}</th>
          <th pSortableColumn="customerOperator">{{ "CRMSEARCH_LABEL_CREATEDBY"| Translate : 'Created By' }}
            <p-sortIcon field="customerOperator"></p-sortIcon>
          </th>
          <th pSortableColumn="created">{{ "CRMSEARCH_LABEL_CREATED"| Translate : 'Created' }}
            <p-sortIcon field="created"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template let-row let-rowIndex="rowIndex" pTemplate="body">
        <tr [pSelectableRow]="row">
          <td>{{ row.customerName }}</td>
          <td>{{ row.customerEmail }}</td>
          <td>
            <div class="d-flex flex-row align-items-center">
              <span class="mr-1">  {{ row.customerPhone }}</span>
              <!--              <fa-icon *ngIf="validPhoneNumber(row.customerPhone)"-->
              <!--                       [icon]="faMobileAlt"-->
              <!--                       class="align-self-center">-->
              <!--              </fa-icon>-->
            </div>
          </td>
          <td>{{ row.customerNotes }}</td>
          <td>{{ row.customerOperator }}</td>
          <td>{{ row.created | localDateTime }}</td>
        </tr>
      </ng-template>

    </p-table>

    </section>

  </ng-template>


  <ng-template #OpportunitySearch>
    <p-toolbar styleClass="mb-2 flex-nowrap">
      <div class="p-toolbar-group-start gap-2 align-items-center">
        <label for="name2">{{ "CRMSEARCH_LABEL_CUSTOMERNAME" | Translate : 'Customer Name' }}
          <input pInputText [(ngModel)]="cName"  id="name2" name="search-field" type="text">
        </label>

        <label for="email2">{{ "CRMSEARCH_LABEL_OPPORTUNITYTITLE" | Translate : 'Opportunity Title' }}
          <input pInputText
            [(ngModel)]="cOpTitle"  id="email2" name="search-field" type="text">
        </label>

        <p-dropdown [(ngModel)]="cOpStatus" [options]="(opportunitystatus)" [resetFilterOnHide]="true"
                    [showClear]="false"
                    id="opp-status" optionLabel="StatusTitle" optionValue="StatusId"
                    placeholder='{{ "CRMSEARCH_PLACEHOLDER_SELECTSTATUS" | Translate : "Select Opportunity Status" }}'
                    styleClass="dropdown mt-4">
        </p-dropdown>
      </div>
      <div class="p-toolbar-group-end gap-1 align-items-center">
        <p-button (click)="filterOpportunityData()" styleClass="btn-rectangle center-icon ">
            <fa-icon [icon]="faSearch"></fa-icon>
        </p-button>
      </div>

    </p-toolbar>

  <section class="overflow-hidden">
    <p-table (onRowSelect)="onOpActivate($event)"
             (onSort)="onSortOpportunities($event)"
             [lazy]="true" [loading]="loading"
             [paginator]="true" [rows]="opcount | async" [scrollable]="true" [showCurrentPageReport]="true"
             [totalRecords]="opcount | async"
             [value]="(oprows | async)" scrollHeight="flex" selectionMode="single"
             styleClass="px-2">
      <ng-template pTemplate="header">
        <tr>
          <th
            pSortableColumn="opportunityTitle">{{ "CRMSEARCH_LABEL_OPPORTUNITY_TITLE" | Translate : 'Opportunity Title' }}
            <p-sortIcon field="opportunityTitle"></p-sortIcon>
          </th>
          <th
            pSortableColumn="customerName">{{ "CRMSEARCH_LABEL_OPPORTUNITY_CUSTOMER_NAME" | Translate : 'Customer Name' }}
            <p-sortIcon field="customerName"></p-sortIcon>
          </th>
          <th pSortableColumn="opportunityProduct">{{ "CRMSEARCH_LABEL_OPPORTUNITY_PRODUCT" | Translate : 'Product' }}
            <p-sortIcon field="opportunityProduct"></p-sortIcon>
          </th>
          <th
            pSortableColumn="opportunityStatusText">{{ "CRMSEARCH_LABEL_OPPORTUNITY_STATUS" | Translate : 'Status' }}
            <p-sortIcon field="opportunityStatusText"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template let-row pTemplate="body">
        <tr [pSelectableRow]="row">
          <td>{{ row.opportunityTitle }}</td>
          <td>{{ row.customerName }}</td>
          <td>{{ row.opportunityProduct }}</td>
          <td>{{ row.opportunityStatusText }}</td>
        </tr>
      </ng-template>

    </p-table>
  </section>
  </ng-template>

</div>
