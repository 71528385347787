import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faShieldAlt, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {VerifyStatuses} from '../../services/engagementhub.service';


@Component({
  selector: 'app-verify-customer-button',
  templateUrl: './verify-customer-button.component.html',
  styleUrls: ['./verify-customer-button.component.scss']
})
export class VerifyCustomerButtonComponent {


  constructor(private translate: TranslatePipe) {
  }
  @Input() status: string = VerifyStatuses.Pending;
  @Output() verify = new EventEmitter<void>();

  protected readonly faShieldAlt: IconDefinition = faShieldAlt;

  public getSeverity()  {
    if (this.status === VerifyStatuses.Pending) {
      return 'secondary';
    } else if (this.status === VerifyStatuses.Approved) {
      return 'success';
    } else {
      return 'danger';
    }
  }

  public getToolTip(): string {
    if (this.status === VerifyStatuses.Pending) {
      return this.translate.transform('VERIFICATION_PENDING_LABEL', 'Verification Pending');
    } else if (this.status === VerifyStatuses.Approved) {
      return this.translate.transform('VERIFICATION_APPROVED', 'Verification Approved');
    } else {
      return this.translate.transform('VERIFICATION_FAILED_LABEL', 'Verification Failed');
    }
  }

  verifyCustomer() {
    if (this.status === VerifyStatuses.Approved) {
      return;
    }
    this.verify.emit();
  }
}
