<div class="d-flex h-100">
  <form [formGroup]="browserLocationForm"
      (submit)="submit()"
      novalidate
      class="d-flex flex-grow-1 form-browser-url justify-content-start align-items-center border-radius-3 border main-layout-card"
      [class.disabled]="useSecureSharing">

      <input type="url" id="browser-url" class="h-100 flex-grow-1" pInputText autocomplete="false"
             placeholder='{{ "ENGAGEMENT_BROWSERBAR_URL" |Translate : "Type a URL" }}'
             [readonly]="!isPresenter || useSecureSharing" [disabled]="useSecureSharing"
             [value]="currentUrl" formControlName="url">
      <p-button id="send-btn" [disabled]="useSecureSharing" class="h-100" [text]="true" severity="success" size="small"  type="submit" [ngClass]="!isPresenter ? 'd-none' : ''" >
          <fa-icon [icon]="faPaperPlane" size="xl"></fa-icon>
      </p-button>
  </form>
  <div class="d-flex flex-row justify-content-center p-buttonset">
    <p-button
      *ngIf="canCoBrowse && isPresenter" id="cobrowse-btn"
      pTooltip='{{ "SHARINGCONTROL_LABEL_COBROWSE" |Translate : "Co-Browse" }}'
      tooltipPosition="top"
      [text]="true"

      [severity]="useCoBrowse ? 'primary' : 'secondary'"
      (onClick)="onCoBrowseClicked()">
        <fa-icon [icon]="faRotate" size="xl" />
    </p-button>
    <p-button
      *ngIf="useSecureSharing && canShowUserInteractionButton" id="disable-user-share-input-btn"
      [severity]="secureSharePreventUserInteraction ? 'danger': 'primary'"
      pTooltip='{{ secureSharePreventUserInteraction ? ("SECURESHARE_ENABLE_USER_INTERACTION_TOOLTIP_TEXT" | Translate : "Enable Customer Interaction") : ("SECURESHARE_DISABLE_USER_INTERACTION_TOOLTIP_TEXT" | Translate: "Disable Customer Interaction")}}'
      tooltipPosition="top"
      [text]="true"
      (onClick)="onToggleSecureShareUserInteraction()">
      <fa-icon [icon]="secureSharePreventUserInteraction ? faPersonCircleMinus: faPersonCircleCheck" size="xl"></fa-icon>
    </p-button>
    <p-button
      *ngIf="canSecureShare && isPresenter" id="secure-share-btn"
      pTooltip='{{ "SHARINGCONTROL_LABEL_SECURESHARE" |Translate : "Secure Share" }}'
      tooltipPosition="top"
      [text]="true"

      [severity]="useSecureSharing ? 'primary' : 'secondary'"
      (onClick)="onSecureShareClicked()">
        <fa-icon [icon]="faCircleArrowDown" size="xl"></fa-icon>
    </p-button>
    <p-button
      *ngIf="canShare && isPresenter" id="share-btn"
      pTooltip='{{ "SHARINGCONTROL_LABEL_SHARE" |Translate : "Share" }}'
      tooltipPosition="top"
      [text]="true"

      [severity]="useSharing ? 'primary' : 'secondary'"
      (onClick)="onShareClicked()">
        <fa-icon [icon]="faCircleArrowUp" size="xl"></fa-icon>
    </p-button>

    <p-button
      *ngIf="canAppView && isPresenter"
      pTooltip='{{ "SHARINGCONTROL_LABEL_APPVIEW" |Translate : "App view" }}'
      tooltipPosition="top"
      [text]="true"

      [severity]="useAppView ? 'primary' : 'secondary'"
      (onClick)="toggleAppView()">
        <fa-icon [icon]="faMobileScreenButton" size="xl"></fa-icon>
    </p-button>
  </div>

</div>
