import {Injectable} from '@angular/core';

import {BehaviorSubject, forkJoin, Observable} from 'rxjs';

import {Resources, SettingsBackendService, SiteSettings} from './settings.backend.service';
import { CannedChatCategory } from '../../classes/cannedchats';
import { InvitationReason } from '../../classes/transfer/invitationReason';
import { InvitationRejectionReason } from '../../classes/transfer/invitationRejectionReason';
import { EngagementAssetPlaylist, EngagementAssetType } from '../../classes/EngagementAssets';
import { LoggingService } from '../logging.service';
import { AuthService } from '../auth-service/auth.service';
import { environment } from '../../../environments/environment';
import { Browsers } from '../../utils/browsers';

export interface ISettingsService {
  resources: BehaviorSubject<Resources>;
  cannedTexts: BehaviorSubject<CannedChatCategory[]>;
  invitationReasons: BehaviorSubject<InvitationReason[]>;
  rejectionReasons: BehaviorSubject<InvitationRejectionReason[]>;
  engagementAssets: BehaviorSubject<EngagementAssetPlaylist[]>;
  postEngagementStatuses: BehaviorSubject<Map<string, string[]>>;

  loadAll();
  loadResources(): Observable<boolean>;
  loadSiteSettings(): Observable<boolean>;
  loadCannedChats(): Observable<boolean>;
  loadTransferReasons(): Observable<boolean>;
  loadRejectionReasons(): Observable<boolean>;
  loadEngagementAssets(): Observable<boolean>;
  loadPostEngagementStatuses(): Observable<boolean>;

  getResource(resourceKey: string): string;
  getResourceOrDefault(resourceKey: string, defaultValue: string): string;

  getSiteSetting(siteSettingKey: string): string;
  getSiteSettingOrDefault(siteSettingKey: string, defaultValue: string): string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService  implements ISettingsService{

  public resources: BehaviorSubject<Resources> = new BehaviorSubject(this.getResourcesFromSession());
  public siteSettings: BehaviorSubject<SiteSettings> = new BehaviorSubject(null);
  public cannedTexts: BehaviorSubject<CannedChatCategory[]> = new BehaviorSubject([]);
  public invitationReasons: BehaviorSubject<InvitationReason[]> = new BehaviorSubject([]);
  public rejectionReasons: BehaviorSubject<InvitationRejectionReason[]> = new BehaviorSubject([]);
  public engagementAssets: BehaviorSubject<EngagementAssetPlaylist[]> = new BehaviorSubject([]);
  public postEngagementStatuses: BehaviorSubject<Map<string, string[]>> = new BehaviorSubject(new Map([]));

  private _resources: Resources;
  private _siteSettings: SiteSettings;

  constructor(
    private settingBackendService: SettingsBackendService,
    private logger: LoggingService,
    private authService: AuthService
  ) {
    this.resources.subscribe(resources => {
      Browsers.PopulateWebrtcBlockList(this.getResourceOrDefault(Browsers.WEBRTC_BLOCK_LIST_RESOURCE, ""));
    });
  }

  public getResource(resourceKey: string): string {
    if(this._resources === undefined) {
        this._resources = this.getResourcesFromSession(); }
    return this._resources ? this._resources[resourceKey] ? this._resources[resourceKey] : '' : '';
  }

  public getResourceOrDefault(resourceKey: string, defaultValue: string): string {
    const resource: string = this.getResource(resourceKey);
    return resource !== '' ? resource : defaultValue;
  }

  public getResourceSettingEnabledOrDefault(resourceKey: string, defaultValue: boolean): boolean {
    const defaultStr = defaultValue ? "1" : "0";
    const resource = this.getResourceOrDefault(resourceKey, defaultStr);
    return resource == "1";
  }

  public getSiteSetting(siteSettingKey: string): string {
    if(this._siteSettings) {
      return this._siteSettings[siteSettingKey] ? this._siteSettings[siteSettingKey] : '';
    }
    return '';
  }

  public getSiteSettingOrDefault(siteSettingKey: string, defaultValue: string): string {
    const siteSetting = this.getSiteSetting(siteSettingKey);
    return siteSetting !== '' ? siteSetting : defaultValue;
  }

  public getSectionAndSmsNumberForAgent(siteSections: string[]): Array<[string, string]> {
    const sectionAndNumbers = [];

    for (const siteSection of siteSections) {
      // If we have a phone number then do the call thing
      const setting = this.getSiteSetting(`SmsNumber:${siteSection}`);
      if (setting) {
        sectionAndNumbers.push([siteSection, setting]);
      }
    }

    return sectionAndNumbers;
  }

  public loadAll() :Observable<boolean[]> {
    const obs = forkJoin([
      this.loadSiteSettings(),
      this.loadCannedChats(),
      this.loadTransferReasons(),
      this.loadRejectionReasons(),
      this.loadEngagementAssets(),
      this.loadPostEngagementStatuses(),
    ]);

    obs.subscribe();
    return obs;
  }

  public loadResources(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
          this.settingBackendService.loadResources(this.authService.currentAgent.value.authToken).subscribe(
            resources => {

              this._resources = resources;
              this.setResourcesIntoSession(resources);
              this.resources.next(resources);
              observer.next(true);
              observer.complete();
            },
            err => {
              observer.next(false);
              observer.complete();
            }
          );

      }
    );
  }

  public loadSiteSettings() {
    return new Observable<boolean>(
      observer => {
        this.settingBackendService.loadSiteSettings(this.authService.currentAgent.value.authToken).subscribe(
          siteSettings => {
            this._siteSettings = siteSettings;
            this.siteSettings.next(siteSettings);
            observer.next(true);
            observer.complete();
          },
          err => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public loadCannedChats(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.settingBackendService.loadCannedChats(this.authService.currentAgent.value.authToken).subscribe(
          cannedTexts => {
            this.cannedTexts.next(cannedTexts);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting canned chats', err);
            observer.next(false);
            observer.complete();
          });
      }
    );
  }

  public loadRejectionReasons(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.settingBackendService.loadRejectionReasons(this.authService.currentAgent.value.authToken).subscribe(
          rejectionReasons => {
            this.rejectionReasons.next(rejectionReasons);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting rejection reasons', err);
            observer.next(false);
            observer.complete();
          });
      }
    );
  }

  public loadTransferReasons(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.settingBackendService.loadTransferReasons(this.authService.currentAgent.value.authToken).subscribe(
          invitationReasons => {
            this.invitationReasons.next(invitationReasons);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting Transfer reasons', err);
            observer.next(false);
            observer.complete();
          });
      }
    );
  }

  public loadEngagementAssets(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.settingBackendService.loadEngagementAssets(this.authService.currentAgent.value.authToken).subscribe(
          playlists => {

            // Replace static assets with their full url
            for (const playlist of playlists) {
              for (const asset of playlist.Assets) {
                if (asset.Type === EngagementAssetType.Static) {
                  asset.Url = `${environment.assetProxy}${asset.Url}`;
                }
              }
            }

            this.engagementAssets.next(playlists);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting assets', err);
            observer.next(false);
            observer.complete();
          });
      }
    );
  }

  public loadPostEngagementStatuses(): Observable<boolean> {
    return new Observable(obs => {
      this.settingBackendService.loadStatuses(this.authService.currentAgent.value.authToken).subscribe(
        statuses => {
          const keys = Object.keys(statuses);

          const allStatuses: Map<string, string[]> = new Map([]);

          for (const key of keys) {
            if (key === '') {
              this.logger.warn('Skipping a empty status category');
              continue;
            }

            // Just in case remove any '' statuses too
            const sarr: string[] = statuses[key];
            allStatuses.set(key, sarr.filter(s => s.length > 0));
          }

          this.postEngagementStatuses.next(allStatuses);

          obs.next(true);
          obs.complete();
        },
        error => {
          this.logger.error('Error getting statuses', error);
          obs.next(false);
          obs.complete();
        },
      );
    });
  }

  private getResourcesFromSession(): Resources {
    if (!!sessionStorage.getItem('resources')) {
      return JSON.parse(sessionStorage.getItem('resources')) as Resources;
    } else {
      return null;
    }
  }

  private setResourcesIntoSession(resources: Resources): void {
    sessionStorage.setItem('resources', JSON.stringify(resources));
  }

  private deleteResourcesFromSession(): void {
    sessionStorage.removeItem('resources');
  }
}
