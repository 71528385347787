import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VisitorDetails } from '../../classes/visitor-details';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitorBackendService {

  constructor(
    private http: HttpClient
  ) { }

  public loadUserSiteData(authToken: string, userGuid: string, sessionGuid: string): Observable<VisitorDetails> {
    const url = `${environment.visitorRoot}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      }),
      params: new HttpParams({
        fromObject: {
          userGuid: userGuid,
          sessionGuid: sessionGuid
        }
      })
    };

    return this.http.get<VisitorDetails>(
      url,
      httpOptions
    );
  }

}
