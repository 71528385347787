import { Injectable } from '@angular/core';
import { EmailTemplate } from '../../classes/email/emailtemplate';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GenerateEmailTextRequest } from '../../classes/email/EmailText';
import { QueueMessageRequest, QueueMessageResponse } from '../../classes/email/emailQueue';

export interface IEmailBackendService {
  loadEmailTemplate(authToken: string, eventTitle: string): Observable<EmailTemplate[]>;
  generateEmailText(authToken: string, generateEmailTextRequest: GenerateEmailTextRequest): Observable<EmailTemplate[]>;
  enqueueMessage(authToken: string, queueMessageRequest: QueueMessageRequest): Observable<QueueMessageResponse[]>;
}

@Injectable({
  providedIn: 'root'
})
export class EmailBackendService implements IEmailBackendService {

  constructor(
    private http: HttpClient
  ) { }

  loadEmailTemplate(authToken: string, eventTitle: string): Observable<EmailTemplate[]> {
    const url = `${environment.emailRoot}/getemailtemplatelist`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const emailTemplateRequest = {
      "eventTitle": eventTitle
    }

    return this.http.post<EmailTemplate[]>(
      url,
      emailTemplateRequest,
      httpOptions
    );
  }

  generateEmailText(authToken: string, generateEmailTextRequest: GenerateEmailTextRequest): Observable<EmailTemplate[]>  {
    const url = `${environment.emailRoot}/generateemailtext`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<EmailTemplate[]>(
      url,
      generateEmailTextRequest,
      httpOptions
    );
  }

  enqueueMessage(authToken: string, queueMessageRequest: QueueMessageRequest): Observable<QueueMessageResponse[]>  {
    const url = `${environment.emailRoot}/enqueuemessage`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<QueueMessageResponse[]>(
      url,
      queueMessageRequest,
      httpOptions
    );
  }
}
