<span class="message-template-outer">
  <div class="message-template-inner">
    <div #messageTemplateViewer
    class="message-template-viewer"
    contenteditable="true"
    [innerHTML]="htmlContent"
    (input)="onContentChanged($event.target.innerHTML)">
    </div>
  </div>
</span>
