import EventEmitter from "../EventEmitter";

const ButtonState = {
    None: 0, Down: 1, Lifted: 2
};

const ScrollState = {
    None: 0, Up: 1, Down: 2, Left:3, Right:4
};

const LEFT_BUTTON_SHIFT = 0;
const RIGHT_BUTTON_SHIFT = 2;
const SCROLL_WHEEL_SHIFT = 4;
    
const MOUSE_EVENT_BUTTON_LEFT = 1;
const MOUSE_EVENT_BUTTON_RIGHT = 2;

const MOUSE_EVENT = 'MOUSE_EVENT';

export default class MouseCapturer extends EventEmitter {
    constructor(captureElement) {
        super();

        if (captureElement == null) {
            throw new Error("Capture element given is falsy");
        }

        if (typeof captureElement.addEventListener != 'function') {
            throw new Error("Capture element can not have event listeners added.");
        }

        this.element = captureElement;

        this.moveListenerHandler = (ev) => this.handleMouseMove(ev);
        this.mouseDownHandler = (ev) => this.handleMouseDown(ev);
        this.mouseUpHandler = (ev) => this.handleMouseUp(ev);
        this.scrollHandler = (ev) => this.handleScroll(ev);

        this.element.addEventListener('mousemove', this.moveListenerHandler);
        this.element.addEventListener('mousedown', this.mouseDownHandler);
        this.element.addEventListener('mouseup', this.mouseUpHandler);

        this.element.addEventListener('wheel', this.scrollHandler);

        this.enabled = false;
        this.debug = false;
        this.disposed = false;

        this.leftButtonState = ButtonState.None;
        this.rightButtonState = ButtonState.None;
        this.scrollState = ScrollState.None;

        this.leftMousePressed = false;
        this.rightMousePressed = false;

        this.handleMouseMove = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }
    
            this.emitMouseEvent(ev.offsetX, ev.offsetY);
        };

        this.handleScroll = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }

            if (ev.deltaY > 0) {
                this.scrollState = ScrollState.Down;
            } else if (ev.deltaY < 0) {
                this.scrollState = ScrollState.Up;
            } else if(ev.deltaX > 0) {
                this.scrollState = ScrollState.Left;
            } else if(ev.deltaX < 0) {
                this.scrollState = ScrollState.Right;
            } else {
                this.scrollState = ScrollState.None;
            }
            // Not supported z scrolling

            if (this.scrollState != ScrollState.None) {
                this.emitMouseEvent(ev.offsetX, ev.offsetY);
                this.scrollState = ScrollState.None;
                ev.preventDefault();
            } else {
                if (this.debug) {
                    console.log("Wheel event with no scrolling?");
                }
            }
        };

        this.handleMouseDown = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }
            // todo: find out difference between ev.button and ev.buttons
    
            if (MOUSE_EVENT_BUTTON_LEFT & ev.buttons) {
                this.leftButtonState = ButtonState.Down;
                this.leftMousePressed = true;
            }
            
            if (MOUSE_EVENT_BUTTON_RIGHT & ev.buttons) {
                this.rightButtonState = ButtonState.Down;
                this.rightMousePressed = true;
            }
    
            this.emitMouseEvent(ev.offsetX, ev.offsetY);
    
            this.leftButtonState = this.rightButtonState = ButtonState.None;
        };
    
        this.handleMouseUp = (ev) => {
            if (this.debug) {
                console.dir(ev);
            }
    
            if (this.leftMousePressed) {
                if (0 == (MOUSE_EVENT_BUTTON_LEFT & ev.buttons)) {
                    this.leftButtonState = ButtonState.Lifted;
                    this.leftMousePressed = false;
                }
            }
    
            if (this.rightMousePressed) {
                if (0 == (MOUSE_EVENT_BUTTON_RIGHT & ev.buttons)) {
                    this.rightButtonState = ButtonState.Lifted;
                    this.rightMousePressed = false;
                }
            }
    
            this.emitMouseEvent(ev.offsetX, ev.offsetY);
    
            this.leftButtonState = this.rightButtonState = ButtonState.None;
        };

        this.emitMouseEvent = (positionX, positionY) => {
            if (!this.enabled) return;
            
            let flags = 0;
    
            flags |= this.leftButtonState << LEFT_BUTTON_SHIFT;
            flags |= this.rightButtonState << RIGHT_BUTTON_SHIFT;
            flags |= this.scrollState << SCROLL_WHEEL_SHIFT;
    
            const event = { x: positionX / this.element.offsetWidth, y: positionY / this.element.offsetHeight, flags: flags };
    
            this.emit(MOUSE_EVENT, event);
        };
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    focus() {
    }

    blur() {
    }

    dispose() {
        this.off(MOUSE_EVENT);

        this.element.removeEventListener('mousemove', this.moveListenerHandler);
        this.element.removeEventListener('mousedown', this.mouseDownHandler);
        this.element.removeEventListener('mouseup', this.mouseUpHandler);
        this.element.removeEventListener('wheel', this.scrollHandler);

        this.disposed = true;
    }

    setDebug(enabled) {
        this.debug = enabled;
    }
};
