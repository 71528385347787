import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FeatureService, Features } from '../../../services/feature-service/feature.service';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EndChatModalComponent, EndType } from '../../../components/end-chat-modal/end-chat-modal.component';
import { EngagementService } from '../../../services/engagement.service';
import {WorkStatus} from '../../../classes/work-status';
import {ModalService} from '../../../services/modal.service';
import {TranslatePipe} from "../../../filters/Translate.pipe";
import {faAddressBook, faDashboard, faTableColumns, faUser, faUserTie} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-supervisor-controls',
  templateUrl: './supervisor-controls.component.html',
  styleUrls: ['./supervisor-controls.component.scss']
})
export class SupervisorControlsComponent implements OnInit {
  @Input() dashboardIsVisible: boolean;

  @Output() goToDashboard: EventEmitter<void> = new EventEmitter<void>();
  @Output() endBreak: EventEmitter<void> = new EventEmitter<void>();

  public crmAvailable: boolean;
  public scheduledAppointmentsAvailable: boolean;
  public isSupervisorModeOn: boolean;
  public hasSupervisingEngagements: boolean;

  private modalRef: DynamicDialogRef;

  constructor(private featureService: FeatureService,
              private onlineService: OnlineService,
              private router: Router,
              private modalService: ModalService,
              private engagementService: EngagementService,
              private translate: TranslatePipe
              ) { }

  ngOnInit() {
    this.onlineService.currentState.subscribe(state => {
      this.isSupervisorModeOn = state[0] === OnlineState.Supervisor;
    });

    this.crmAvailable = this.featureService.has(Features.CRM);
    this.scheduledAppointmentsAvailable = this.featureService.has(Features.SCHEDULED_APPOINTMENT);

    this.engagementService.engagements.subscribe(engList => {
      this.hasSupervisingEngagements = engList.some(e => e.privateChatEnabled.value || e.supPublicChatEnabled.value);
    });
  }

  toggleSupervisorMode() {
    if (this.onlineService.currentState.value[0] === OnlineState.OnBreak) {
      this.endBreak.emit();
    } else {
      this.showEndSupervisorModeModal();
    }
  }

  private closeModal() {
    if (this.modalRef) {
      this.modalService.closeModal(this.modalRef);
      this.modalRef = null;
    }
  }

  showEndSupervisorModeModal() {
    const data = {
      veestudioAvailable: this.featureService.has(Features.VEESTUDIO),
      veechatAvailable: this.featureService.has(Features.VEECHAT),
      endChatType: EndType.LEAVE_SUP_MODE,
      goToVeestudio: () => {
        this.closeModal();
        this.router.navigateByUrl(`/veestudio`).catch(err => console.error(err));
      }, goToVeechat: () => {
        this.closeModal();
        this.router.navigateByUrl(`/veechat`).catch(err => console.error(err));
      }, cancelEndChat: () => {
        this.closeModal();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_LEAVEMODE', 'Leave Supervisor Mode?'),
      showHeader: true,
      contentStyle: { width: '500px'}
    });
  }

  showEndSessionsModal(goToMode: string) {
    const data = {
      endChatType: EndType.LEAVE_SUP_SESSIONS,
      proceedToNewMode: () => {
        this.closeModal();
        if (goToMode === 'crm') {
          this.goToCrm();
        }
      }, cancelEndChat: () => {
        this.closeModal();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_ENDSUPERVISE-SESSION', 'Leave Supervisor Mode?'),
      contentStyle: { width: '500px'}
    });
  }

  onGoToCrm() {
    if (this.hasSupervisingEngagements) {
      this.showEndSessionsModal('crm');
    } else {
      this.goToCrm();
    }
  }

  goToCrm() {
    this.onlineService.setCurrentState(OnlineState.CrmOpportunity, WorkStatus.None);
    this.router.navigateByUrl('/supervisor/crm');
  }

  showDashboard() {
    this.goToDashboard.emit();
  }

  protected readonly faUser = faUser;
  protected readonly faUserTie = faUserTie;
  protected readonly faAddressBook = faAddressBook;
  protected readonly faDashboard = faDashboard;
  protected readonly faTableColumns = faTableColumns;
}
