import CandidateFilter from "../CandidateFilter";
import Connection from "../Connection";
import Constants from "../Constants";

export default class ScreenSharingConnection extends Connection {
    constructor(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, theirPeerType, direction, candidateFilter = CandidateFilter.noFilter) {
        super(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, theirPeerType, direction, candidateFilter, Constants.SignalType.SCREENSHARE);

        this.SHARING_CHANNEL_OPENED = "ScreenSharingConnection:opened";
        this.SHARING_CHANNEL_MESSAGE = "ScreenSharingConnection:message";

        this.channelName = this.createDataChannelName(engagementId);

        // todo: rename this var
        this.dataChannel = undefined;

        if (direction == Constants.Direction.In) {
            this.rtc.ondatachannel = dc => {
                this.dataChannel = dc.channel;
                this.dataChannel.onmessage = (message) => {
                    const msg = JSON.parse(message.data);
                    this.emit(this.SHARING_CHANNEL_MESSAGE, msg, this);
                };

                const connectedMessage = { msg: { type: 'channel', event: { status: 'connected' }}};
                this.send(connectedMessage);
            }
        }
    }

    createDataChannelName(id) {
        return `ss!${id}`;
    }

    send(screenshareMessage) {
        try {
            this.dataChannel.send(JSON.stringify(screenshareMessage));
        } catch (e) {
            // todo: find out what the logging system is
            console.log(e);
        }
    }

    addTrack(track, stream) {
        this.dataChannel = this.rtc.createDataChannel(this.channelName);
        this.dataChannel.onopen = event => {
            this.emit(this.SHARING_CHANNEL_MESSAGE, { type: 'channel', event: { status: 'opened' }}, this);
        };

        this.dataChannel.onmessage = event => {
            try {
                const message = JSON.parse(event.data);
                this.emit(this.SHARING_CHANNEL_MESSAGE, message.msg, this);
            } catch (e) {
                // todo: find out what the logging system is
                console.log(e);
            }
        };

        super.addTrack(track, stream);
    }
}
