import {ControlValueAccessor} from "@angular/forms";

export class ControlValueAccessorBase<T> implements ControlValueAccessor {

  _value: T;
  onChange: (value: T) => void = () => {
  };
  onTouched: () => void = () => {
  };
  disabled: boolean = false;

  writeValue(value: T): void {
    this._value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange(value: T): void {
    this.writeValue(value);
    this.onTouched();
  }
}
