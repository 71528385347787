import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '../../filters/Translate.pipe';
import {Subscription } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {AppointmentService} from '../../services/appointment/appointment.service';
import {AvailableAgent} from '../../services/appointment/appointment.backend.service';

export interface AppointmentTransferModalInitialState {
  appointmentGuid: string;
  startDate: string;
  endDate: string;
  onAttemptTransfer: (selectedAgent: string) => void;
  onCancelTransfer: () => {};
}
@Component({
  selector: 'app-appointment-transfer-modal',
  templateUrl: './appointment-transfer-modal.component.html',
  styleUrls: ['./appointment-transfer-modal.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentTransferModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  public availableAgentsList: AvailableAgent[] = [];
  public selectedAgent: string;
  public appointmentGuid: string;
  public startDate: string;
  public endDate: string;
  public get data() {
    return this.dialogConfig.data;
  }


  constructor(
    private appointmentService: AppointmentService,
    private dialogConfig: DynamicDialogConfig<AppointmentTransferModalInitialState>
  ) {
    this.appointmentGuid = this.data.appointmentGuid;
    this.startDate = this.data.startDate;
    this.endDate = this.data.endDate;
  }

  ngOnInit() {
    this.loadAvailableAgents();
  }

  public onTransfer() {
    this.data?.onAttemptTransfer( this.selectedAgent );
  }
  public onCancelTransfer() {
    this.data?.onCancelTransfer();
  }

  public getName(data: AvailableAgent) {
    return data.firstname + ' ' + data.lastname;
  }

  private loadAvailableAgents() {
    const agents = this.appointmentService.getAvailableAgents(this.startDate, this.endDate).subscribe(types => {
      if (types && types.length > 0) {
        this.availableAgentsList = Array.from(types);
      }
    });
    this.subscriptions.push(agents);
  }

}
