import {Component, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {TranslatePipe} from '../../filters/Translate.pipe';
import { ModalService } from '../../services/modal.service';


export interface DisplayMediaInitialState {
  startShareCallback: (opts: ShareOptions) => void;
  sources: [];
}

export interface ShareOptions {
  source: any;
  viewOnly: boolean;
}


@Component({
  selector: 'app-display-media-modal',
  templateUrl: './display-media-modal.component.html',
  styleUrls: ['./display-media-modal.component.scss']
})
export class DisplayMediaModalComponent implements OnInit {

  isTwoWay: boolean = false;
  screenShareMode: string = '';
  screenShareDescription: string = '';
  startShareCallback;
  cancelCallback: () => void;
  sources = [];
  selectedSource: any;

  constructor(private modalService: ModalService, private modalRef: DynamicDialogRef, private translate: TranslatePipe, private dialogConfig: DynamicDialogConfig) {
    this.startShareCallback = this.dialogConfig.data.startShareCallback;
    this.sources = this.dialogConfig.data.sources;
  }

  ngOnInit() {
    this.updateScreenShareMode();
  }

  updateScreenShareMode() {
    if (this.isTwoWay) {
      this.screenShareMode = this.translate.transform("DISPLAYMEDIA_SCREENSHARETWOWAY_TITLE", 'Two-way Screen Share');
      this.screenShareDescription =this.translate.transform("DISPLAYMEDIA_SCREENSHARETWOWAY_DESCRIPTION_MESSAGE", 'Share your screen and collaborate. Allows the customer to control it.');
    } else {
      this.screenShareMode = this.translate.transform("DISPLAYMEDIA_SCREENSHAREONEWAY_TITLE", 'One-way Screen Share');
      this.screenShareDescription =this.translate.transform("DISPLAYMEDIA_SCREENSHAREONEWAY_DESCRIPTION_MESSAGE", 'Share your screen, one way. The customer can view your browser, but not interact with it.');
    }
  }

  public onConfirm(): void {
    const opts: ShareOptions = {
      source: this.selectedSource,
      viewOnly: !this.isTwoWay
    };
    this.startShareCallback(opts);
    this.modalService.closeModal(this.modalRef);
    this.modalRef = null;
  }

  public onCancel(): void {
    this.modalService.closeModal(this.modalRef);
    this.modalRef = null;
  }

  public selectSource(source: any) {
    this.selectedSource = source;
  }
}
