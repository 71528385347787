import {CrmContact} from './crm-contact';

export enum EngageUserMethod {
  Phone = 0
}

export class UserEngagementRequest {
  method: EngageUserMethod;
  contact: CrmContact;
}
