import EventEmitter from "./EventEmitter";
import Connection from "./Connection";
import EdgeConnection from "./edge/EdgeConnection";
import Browser from "./Browser";
import SafariConnection from "./safari/SafariConnection";
import CandidateFilter from "./CandidateFilter";
import Constants from "./Constants";
import ScreenSharingConnection from "./screensharing/ScreenSharingConnection";

export default class Peer extends EventEmitter {
    constructor(myPeerType, theirPeerId, theirPeerType, candidateFilter = CandidateFilter.noFilter, signalType = Constants.SignalType.AV) {
        super();

        this.id = theirPeerId;
        this.type = theirPeerType;
        this.myType = myPeerType;
        this.signalType = signalType;
        
        this.incomingConnection = null;
        this.outgoingConnection = null;

        this.theirIncomingConnectionState = null;

        this.audioNeedsToBeTwoWay = (Browser.details().browser === "iphone" || Browser.details().browser === "ipad" || Browser.details().browser === "safari") && Browser.details().version < 13;
        this.stashedIncomingAudioTrackCheckInterval = null;

        this.candidateFilter = candidateFilter;

        this.trackEvent = (e) => {
            if (this.audioNeedsToBeTwoWay && e.track.kind === 'audio') {
                var senders = (this.outgoingConnection != null && this.outgoingConnection.rtc != null) ? this.outgoingConnection.rtc.getSenders() : null;
                if (senders != null && senders.length > 0) {
                    this.emit("Peer:incomingTrack", e);            
                }
                else {
                    //console.log("$$$$$$$ No Senders Yet");
                    this.stashedIncomingAudioTrackCheckInterval = setInterval(() => {
                        var senders = (this.outgoingConnection != null && this.outgoingConnection.rtc != null) ? this.outgoingConnection.rtc.getSenders() : null;
                        if (senders != null && senders.length > 0) {
                            //console.log("$$$$$$$ We have a Sender");
                            clearInterval(this.stashedIncomingAudioTrackCheckInterval);
                            this.emit("Peer:incomingTrack", e);            
                        }
                    }, 1000);
                }
            } 
            else {
                console.log("------------------ TRACK EVENT", e);
                this.emit("Peer:incomingTrack", e);
            }
        };

    }

    connectIncoming(engagementId, myPeerId, iceServer) {
        if (this.signalType == Constants.SignalType.SCREENSHARE) {
            this.incomingConnection = new ScreenSharingConnection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.In, this.candidateFilter);
        } else {
            this.incomingConnection = new Connection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.In, this.candidateFilter, this.signalType);
        }

        this.incomingConnection.on("Connection:track", this.trackEvent);
    }

    connectOutgoing(engagementId, myPeerId, iceServer) {
        if (Browser.details().browser === "edge") {
            this.outgoingConnection = new EdgeConnection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.Out,  this.candidateFilter, this.signalType);
        } 
        else if ((Browser.details().browser === "safari" || Browser.details().browser === "ipad" || Browser.details().browser === "iphone") && Browser.details().version < 13) {
            this.outgoingConnection = new SafariConnection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.Out,  this.candidateFilter, this.signalType);
        }     
        else {
            if (this.signalType == Constants.SignalType.SCREENSHARE) {
                this.outgoingConnection = new ScreenSharingConnection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.Out,  this.candidateFilter);
            } else {
                this.outgoingConnection = new Connection(engagementId, myPeerId, this.myType, iceServer, this.id, this.type, Constants.Direction.Out,  this.candidateFilter, this.signalType);
            }
        }

        this.emit("Peer:outgoingConnectionReady", this.outgoingConnection);
    }

    processMessage(message) {
        // if the message is from the other peers In then deliver to our Out and vice versa
        if (message.direction === Constants.Direction.In) {
            if (this.outgoingConnection) {
                this.outgoingConnection.processMessage(message);
            }
        }
        else {
            if (this.incomingConnection) {
                this.incomingConnection.processMessage(message);
            }
        }
    }

}