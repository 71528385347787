<table class="table table-borderless ">
  <thead>
    <tr class="d-flex text-center">
      <th class="col-2">{{ "SUPERVISOR_HEADER_WAITTIME" | Translate : "Waiting Time" }}</th>
      <th class="col-1">{{ "SUPERVISOR_HEADER_DEVICE" | Translate : "Device" }}</th>
      <th class="col-1">{{ "SUPERVISOR_HEADER_BROWSER"   | Translate : "Browser" }}</th>
      <th class="col-1">{{ "SUPERVISOR_HEADER_SUPPORTS_AV" | Translate : "Supports A/V" }}</th>
      <th class="col-4">{{ "SUPERVISOR_HEADER_LASTPAGE" | Translate : "Last page" }}</th>
      <th class="col-3">{{ "SUPERVISOR_HEADER_SECTION" | Translate : "Site Section" }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let visitor of notEngagedVisitors" class="d-flex text-center" style="border: none;">
      <td class="col-2 assistTime">{{TimeUtil.PrettyRelativeTime(visitor?.getAssistTime() ?? 0)}}</td>

      <td class="col-1">
        <img width="30" height="30"
             [src]="'/assets/call-list-icons/' + visitor?.browserInfo.deviceTypeIcon"
             [alt]="visitor?.browserInfo.deviceType"
             [title]="visitor?.browserInfo.deviceType">
      </td>

      <td class="col-1">
        <img width="30" height="30" [src]="'/assets/browser-icons/' + visitor?.browserInfo.browserIcon"
             [alt]="visitor?.browserInfo.browser" [title]="visitor?.browserInfo.browser">
      </td>

      <td class="col-1">
        <figure class="camera-status-{{visitor?.canSupportWebrtc() ? 'on' : 'off'}} mb-0"></figure>
      </td>

      <td class="col-4">{{visitor?.lastPage}}</td>

      <td class="col-3">{{visitor?.lastSection}}</td>
    </tr>
  </tbody>
</table>
