export enum OnlineState {
  OffLine = 'off line',
  Available = 'available',
  Engaged = 'engaged',
  PostEngagement = 'post engagement',
  MultiChat = 'multichat available',
  OnBreak = 'on break',
  CrmOpportunity = 'crmopportunity',
  Appointment = 'appointment',
  Supervisor = 'supervisor',
  MobilePresenterWaiting = 'presenter waiting',
  MobilePresenterEngaged = 'presenter engaged',
  Home = 'home',
}
