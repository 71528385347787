<div class="d-flex flex-column h-100 p-0">

  <div #textMessagesDiv
       (scrollend)="onScrollEnd($event)"
       class="d-flex flex-column flex-grow-1 h-100 overflow-y-auto overflow-x-hidden">
    <ng-container *ngFor="let message of messages">
      <app-text-chat [message]="message" class="text-message"></app-text-chat>
    </ng-container>
  </div>

  <app-customer-typing-preview id="preview-container" [preview]="previewMessage">
  </app-customer-typing-preview>

  <app-file-transfer (cancelFileTransfer)="cancelFileTransfer($event)"
                     *ngIf="files.length > 0"
                     [files]="this.files">
  </app-file-transfer>

  <div id="editor-container" class="d-flex flex-column w-100 flex-grow-1 gap-0">
    <div role="button"
      (click)="onToggleConversationSize()"
      class="border-top border-bottom  center-icon w-100 p-0 m-0">
       <fa-icon size="sm" [icon]="this.maximiseConversation ? faChevronDown : faChevronUp"></fa-icon>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between py-1">
      <p-dropdown #cannedchats
                  appendTo="body"
                  [formControl]="dropdownControl"
                  (onChange)="onCannedChatSelected($event)"
                  [showClear]="false"
                  [resetFilterOnHide]="true"
                  [disabled]="textEntryDisabled"
                  [filter]="true"
                  [group]="true"
                  [options]="filteredChats"
                  panelStyleClass = "canned-chat-drop-up"
                  class="drop-up flex-grow-1"
                  optionGroupLabel="text"
                  optionGroupChildren="items"
                  optionLabel="text"
                  optionValue="id"
                  placeholder='{{ "ENGAGEMENTTEXTCHAT_PLACEHOLDER_CANNEDCHATS" | Translate : "Canned Chats" }}'>
      </p-dropdown>

      <app-toolbox
        (setAutoReply)="handleSetAutoReply($event)"
        (setGetSuggestion)="handleSetGetSuggestion($event)"
        (setTranslation)="handleSetTranslation($event)"
        [autoReplyAvailable]="autoReplyAvailable"
        [autoReplyOn]="autoReplyOn"
        [getSuggestionAvailable]="getSuggestionAvailable"
        [getSuggestionOn]="getSuggestionOn"
        [translationAvailable]="translationAvailable"
        [translationOn]="translationOn">
      </app-toolbox>
    </div>



    <p-editor (onInit)="onEditorCreated($event)"
              [(ngModel)]="agentText"
              [readonly]="textEntryDisabled"
              [formats]=""
              [modules]="quillModules"
              [ngClass]="this.maximiseConversation ? 'maximised' : 'minimised'"
              placeholder='{{ "ENGAGEMENTTEXTCHAT_PLACEHOLDER_TYPEMESSAGE" | Translate : "Type your message here..." }}'
              styleClass="flex-grow-1">


      <ng-template pTemplate="header">
        <span></span>
      </ng-template>

    </p-editor>


    <div class="d-flex flex-row align-items-center justify-content-between pt-2">
      <label [ngClass]="{'invisible': !fileUploadAvailable}" role="button"
             class="file-upload btn-file-upload btn-square center-icon"
             [class.disabled]="textEntryDisabled"
             for="file"
             title='{{ "ENGAGEMENTTEXTCHAT_TOOLTIP_UPLOADFILE" | Translate : "Upload File" }}'>
        <fa-icon size="lg" [icon]="faPaperclip"></fa-icon>
        <input (change)="uploadFile($event.target.files)" [disabled]="textEntryDisabled"
               id="file" multiple
               name="file"
               type="file"/>
      </label>
      <p-button (onClick)="sendTextMessage()" [disabled]="sendInputDisabled"
              severity="success"  [text]="true"
              type="button">
          <fa-icon size="lg" [icon]="faPaperPlane"></fa-icon>
      </p-button>

    </div>

  </div>


</div>

