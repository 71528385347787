import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CameraControlProperty } from '../../../services/camera-controls-service/camera-properties';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCompress
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pan-tilt-control',
  templateUrl: './pan-tilt-control.component.html',
  styleUrls: ['./pan-tilt-control.component.scss']
})
export class PanTiltControlComponent implements OnInit {

  public faCircle = faCircle;

  @Input() zoom:CameraControlProperty;
  @Input() tilt:CameraControlProperty;
  @Input() pan:CameraControlProperty;

  @Output() onPropertyChange = new EventEmitter<CameraControlProperty>();

  constructor() { }

  ngOnInit() {
  }

  tiltUp() {
    this.tilt.value += this.tilt.stepInterval;
    this.onPropertyChange.emit(this.tilt);
  }

  tiltDown() {
    this.tilt.value -= this.tilt.stepInterval;
    this.onPropertyChange.emit(this.tilt);
  }

  panLeft() {
    this.pan.value -= this.pan.stepInterval;
    this.onPropertyChange.emit(this.pan);
  }

  panRight() {
    this.pan.value += this.pan.stepInterval;
    this.onPropertyChange.emit(this.pan);
  }

  tiltUpLeft() {
    this.tiltUp();
    this.panLeft();
  }

  tiltUpRight() {
    this.tiltUp();
    this.panRight();
  }

  tiltDownLeft() {
    this.tiltDown();
    this.panLeft();
  }

  tiltDownRight() {
    this.tiltDown();
    this.panRight();
  }

  reset() {
    this.tilt.value = this.tilt.defaultValue;
    this.pan.value = this.pan.defaultValue;
    this.zoom.value = this.zoom.defaultValue;

    this.onPropertyChange.emit(this.tilt);
    this.onPropertyChange.emit(this.pan);
    this.onPropertyChange.emit(this.zoom);
  }

  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faCompress = faCompress;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronDown = faChevronDown;
}
