import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {IEngagementHubVisitorState, SDK, VerifyStatuses} from './engagementhub.service';
import {StatusFlag} from '../enums/status-flag.enum';
import {VisitorDetails} from '../classes/visitor-details';
import {CrmData} from './crm-service/crm-category';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

export enum UserDeviceType {
  UNKNOWN = 'unknown',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet'
}

export enum UserHeartbeatStates {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  BACKGROUND = 'BACKGROUND'
}

export interface EngagementHubVisitor {
  SiteName: string;
  UserGuid: string;
  SessionGuid: string;
  LastUpdateTime: string;
  State: IEngagementHubVisitorState;
  IsPrimary: boolean;
  StateInitialised: boolean;
  VerifyStatus: string;
}

export class EngagementVisitor {


  constructor(engagementHubVisitor: EngagementHubVisitor, index: number = 0) {
    this.index = index;
    this.sitename = engagementHubVisitor.SiteName;
    this.userGuid = engagementHubVisitor.UserGuid?.toString();
    this.sessionGuid = engagementHubVisitor.SessionGuid;
    this.lastUpdateTime = engagementHubVisitor.LastUpdateTime;
    this.state = this.initialiseState(engagementHubVisitor.State);
    this.isPrimary = engagementHubVisitor.IsPrimary;
    this.stateInitialised = engagementHubVisitor.StateInitialised;

    this.deviceType$ = this.visitorDetails$.pipe(filter(value => !!value), map(value => this.calculateDeviceType(value)));
  }

  public get isBackgrounded(): boolean {
    return StatusFlag.PageInvisible === (this.state?.flag & StatusFlag.PageInvisible);
  }

  public get isOnline(): boolean {
    return EngagementVisitor.IsOnline(this.lastRoomUpdateTime, this.lastUpdateTime);
  }

  public get chatOnly(): boolean {
    return this.state?.chatOnly;
  }
  public readonly index: number;
  public readonly sitename: string;
  public readonly userGuid: string;
  public readonly sessionGuid: string;
  public lastUpdateTime = '';
  public state: IEngagementHubVisitorState;
  public isPrimary: boolean;
  public stateInitialised: boolean;
  public verifyStatus: string;

  private lastRoomUpdateTime = '';
  public heartbeatState: UserHeartbeatStates = UserHeartbeatStates.ONLINE;

  public visitorDetails$: BehaviorSubject<VisitorDetails> = new BehaviorSubject<VisitorDetails>(null);
  public crmData$: BehaviorSubject<CrmData> = new BehaviorSubject<CrmData>(null);
  public customerName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _userCanSeeMe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _userCanHearMe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _iCanHearUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public deviceType$: Observable<UserDeviceType> = of(UserDeviceType.UNKNOWN);

  public userCanSeeMe$: Observable<boolean> = this._userCanSeeMe$.pipe(
    distinctUntilChanged()
  );
  public userCanHearMe$: Observable<boolean> = this._userCanHearMe$.pipe(
    distinctUntilChanged()
  );
  public iCanHearUser$: Observable<boolean> = this._iCanHearUser$.pipe(
    distinctUntilChanged()
  );

  public statusUpdated$: Observable<boolean> = combineLatest([
    this.userCanSeeMe$,
    this.userCanHearMe$,
    this.iCanHearUser$
  ]).pipe(
    map(([userCanSeeMe, userCanHearMe, iCanHearUser]) => {
      return userCanSeeMe && userCanHearMe && iCanHearUser;
    }),
    distinctUntilChanged()
  );

  static IsOnline(lastRoomUpdateTime: string, lastUpdateTime: string): boolean {
    const lastRoomUpdateTimeMS = Math.max(0, new Date(lastRoomUpdateTime).getTime());
    const lastUpdateTimeMS = Math.max(0, new Date(lastUpdateTime).getTime());
    const timeDifference = lastRoomUpdateTimeMS - lastUpdateTimeMS;
    return timeDifference < 15000;
  }

  private calculateDeviceType(value: VisitorDetails) {
    if (value?.isMobile === '1') {
      return UserDeviceType.MOBILE;
    } else if (value.isTablet === '1') {
      return UserDeviceType.TABLET;
    } else {
      return UserDeviceType.DESKTOP;
    }
  }

  private initialiseState(state: Partial<IEngagementHubVisitorState>): IEngagementHubVisitorState {
    return {
      panelSize: state?.panelSize ?? 0,
      videoSize: state?.videoSize ?? 0,
      clientSizeX: state?.clientSizeX ?? 0,
      clientSizeY: state?.clientSizeY ?? 0,
      textVisible: state?.textVisible ?? false,
      videoVisible: state?.videoVisible ?? false,
      cameraEnabled: state?.cameraEnabled ?? false,
      hasCameraCapabilities: state?.hasCameraCapabilities ?? false,
      micEnabled: state?.micEnabled ?? false,
      hasMicrophoneCapabilities: state?.hasMicrophoneCapabilities ?? false,
      viewingOperatorStream: state?.viewingOperatorStream ?? false,
      hearingOperatorStream: state?.hearingOperatorStream ?? false,
      micGain: state?.micGain ?? 0,
      speakerVolume: state?.speakerVolume ?? 0,
      devicesVisible: state?.devicesVisible ?? false,
      flag: state?.flag ?? 0,
      callPaused: state?.callPaused ?? false,
      isSharing: state?.isSharing ?? false,
      isPresenting: state?.isPresenting ?? false,
      cobrowsingEnabled: state?.cobrowsingEnabled ?? true,
      panelPositionAndSize: state?.panelPositionAndSize ?? '',
      chatOnly: state?.chatOnly ?? false,
      communicationMode: state?.communicationMode ?? 0,
      frontCameraOn: state?.frontCameraOn ?? false,
      mobileChatType: state?.mobileChatType ?? '',
      deviceCameraRotation: state?.deviceCameraRotation ?? 0,
      currentPage: state?.currentPage ?? '',
      upgradeInProgress: state?.upgradeInProgress ?? false,
      panelVisible: state?.panelVisible ?? true,
      cameraAccessGranted: state?.cameraAccessGranted ?? false,
      cameraAlreadyInUse: state?.cameraAlreadyInUse ?? false,
      micAccessGranted: state?.micAccessGranted ?? false,
      micAlreadyInUse: state?.micAlreadyInUse ?? false,
      micActivityLevel: state?.micActivityLevel ?? 0,
      panelFullSize: state?.panelFullSize ?? false,
      engagementMode: state?.engagementMode ?? '',
      SDK: state?.SDK ?? {} as SDK,
      secureSharePreventUserInteraction: state?.secureSharePreventUserInteraction ?? false
    };
  }

  public update(roomTime: string, hubVisitor: EngagementHubVisitor): void {
    this.lastRoomUpdateTime = roomTime;
    this.state = this.initialiseState(hubVisitor.State);
    this.isPrimary = hubVisitor.IsPrimary;
    this.lastUpdateTime = hubVisitor.LastUpdateTime;
    this.stateInitialised = hubVisitor.StateInitialised;
    this.heartbeatState = this.calculateHeartbeatState();
    this.verifyStatus = hubVisitor.VerifyStatus;
    this._userCanSeeMe$.next(this.heartbeatState === UserHeartbeatStates.ONLINE && !this.chatOnly && this.state?.viewingOperatorStream);
    this._userCanHearMe$.next(this.heartbeatState === UserHeartbeatStates.ONLINE && !this.chatOnly && this.state?.hearingOperatorStream);
    this._iCanHearUser$.next(this.heartbeatState === UserHeartbeatStates.ONLINE && this.state?.micEnabled && this.state?.micGain > 0);
  }


  private calculateHeartbeatState(): UserHeartbeatStates {
    if (!this.isOnline) { return UserHeartbeatStates.OFFLINE; }
    return this.isBackgrounded ? UserHeartbeatStates.BACKGROUND : UserHeartbeatStates.ONLINE;
  }
}
