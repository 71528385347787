<table class="table table-fixed table-borderless text-center">
  <thead>
  <tr>
    <th>{{ "CALLLIST_HEADER_STATUS" | Translate : "Status" }}</th>
    <th class="browser-header">{{ "CALLLIST_HEADER_BROWSER"   | Translate : "Browser" }}</th>
    <th class="lastpage-header">{{ "CALLLIST_HEADER_STATUS"   | Translate : "URL Source" }}</th>
    <th class="devicetype-header">{{ "CALLLIST_HEADER_DEVICE" | Translate : "Device" }}</th>
    <th class="calltype-header">{{ "CALLLIST_HEADER_SUPPORTS_AV" | Translate : "Supports A/V" }}</th>
    <th class="sitesection-header">{{ "CALLLIST_HEADER_SECTION" | Translate : "Site Section" }}</th>
    <th class="waitingtime-header">{{ "CALLLIST_HEADER_WAITTIME" | Translate : "Waiting Time" }}</th>
    <th> {{ "CALLLIST_HEADER_ACCEPT" | Translate : "Accept" }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let visitor of visitors" app-call-list-row [visitor]="visitor" role="button"  class="row-height"
      [class.warning]="visitor.getAssistTime() > 15"
      [class.danger]="visitor.getAssistTime() > 30"
      [class.selected]="visitor.sessionGuid == selectedSessionGuid"
      [class.inactive]="visitor.isInactive"
      (acceptCall)="accept(visitor)"
      (click)="selectVisitor(visitor)"
      [pTooltip]="visitor.isInactive ? ('CALLLIST_ROW_USER_INACTIVE' | Translate : 'User is no longer active') : ''"
      [title]="visitor.isInactive ? ('CALLLIST_ROW_USER_INACTIVE' | Translate : 'User is no longer active') : ''"
  >
  </tr>

  <tr *ngFor="let transfer of transfers" app-call-list-row [visitor]="transfer.visitor" role="button"  class="row-height"
      [class.selected]="transfer.visitor.sessionGuid == selectedSessionGuid"
      (click)="selectVisitor(transfer.visitor)" (acceptCall)="showTransferAccept(transfer)">
  </tr>

  <tr *ngFor="let invite of invites" app-call-list-row [visitor]="invite.visitor" role="button"  class="row-height"
      [class.selected]="invite.visitor.sessionGuid == selectedSessionGuid" (click)="selectVisitor(invite.visitor)"
      (acceptCall)="showJoinAccept(invite)">
  </tr>
  </tbody>
</table>
