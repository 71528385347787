import {EventEmitter, Injectable} from '@angular/core';
import {AgentCalendarBackendService} from './agent-calendar.backend.service';
import {LoggingService} from '../logging.service';
import {AuthService} from '../auth-service/auth.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { AgentCalendar } from './agent-calendar';

export interface IAgentCalendarService {
  getAgentCalendarData(): Observable<AgentCalendar[]>;
  getOtherAgentsCalendarData(): Observable<AgentCalendar[]>;
  submitCalendarSlots(calendarSlots: Array<AgentCalendar>): Observable<boolean>;
  deleteCalendarSlotsByDay(day): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class AgentCalendarService implements IAgentCalendarService {
  constructor(
    private agentCalendarBackendService: AgentCalendarBackendService,
    private logger: LoggingService,
    private authService: AuthService
  ) { }

  public getAgentCalendarData(): Observable<AgentCalendar[]> {
    return new Observable(
      observer => {
        this.agentCalendarBackendService.getAgentCalendarData(this.authService.currentAgent.value.authToken).subscribe(
          data => {
            const slots:AgentCalendar[] = this.mapAgentCalendarArray(data);
            observer.next(slots);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting calendar data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  private mapAgentCalendarArray(data): AgentCalendar[] {
    if (data != null) {
      var dataArr = JSON.parse(data);
      const res = dataArr.map(cal => new AgentCalendar(cal));
      return res;
    }
   return new Array<AgentCalendar>();
  }

  public getOtherAgentsCalendarData(): Observable<AgentCalendar[]> {
    return new Observable(
      observer => {
        this.agentCalendarBackendService.getOtherAgentsCalendarData(this.authService.currentAgent.value.authToken).subscribe(
          data => {
            const slots:AgentCalendar[] = this.mapAgentCalendarArray(data);
            observer.next(slots);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting calendar data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public submitCalendarSlots(calendarSlots: Array<AgentCalendar>): Observable<boolean> {
    return new Observable(
      observer => {
        this.agentCalendarBackendService.submitCalendarSlots(this.authService.currentAgent.value.authToken, calendarSlots).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error submitting calendar Slots', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public deleteCalendarSlotsByDay(day): Observable<boolean> {
    return new Observable(
      observer => {
        this.agentCalendarBackendService.deleteCalendarSlotsByDay(this.authService.currentAgent.value.authToken, day).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error deleting calendar slots by day', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }
}
