import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TurnBackendService {

  constructor(
    private http: HttpClient
  ) { }

  public addEngagement(engagementId: string, turnServer: string, authToken: string): Observable<string> {
    const url = `${environment.turnAuthRoot}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken,
      })
    };

    return this.http.post<string>(
      url,
      { engagementId: engagementId, turnServer: turnServer },
      httpOptions
    );
  }

  public removeEngagement(engagementId: string, authToken: string): Observable<{}> {
    const url = `${environment.turnAuthRoot}/?engagementId=${engagementId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.delete(
      url,
      httpOptions
    );
  }
}
