import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AlertService, AlertType} from '../../../../services/alert-service/alert.service';
import { TranslatePipe } from '../../../../filters/Translate.pipe'

export class AudioAverage {
  private static skipAmount = 12;
  private static skipVal = 0;

  public static init() {
    AudioAverage.skipVal = 0;
  }

  public static ProcessAudio(ev: AudioProcessingEvent): number {
    if (++AudioAverage.skipVal !== AudioAverage.skipAmount) {
      return -1;
    }
    AudioAverage.skipVal = 0;

    const buffer = ev.inputBuffer.getChannelData(0);
    const length = buffer.length;

    // Create sum of rms, not psychoacoustic
    // todo: compare this with just a peak detect

    let sum = 0;
    for (let i = 0; i < length; ++i) {
      sum += buffer[i] * buffer[i];
    }

    return Math.sqrt(sum / length);
  }

  constructor() {
    throw new Error('Not to be instantiated');
  }
}

export class AudioPeakDetect {
  private static skipAmount = 4;
  private static skipVal = 0;

  public static init() {
    AudioPeakDetect.skipVal = 0;
  }

  public static ProcessAudio(ev: AudioProcessingEvent): number {
    if (++AudioPeakDetect.skipVal !== AudioPeakDetect.skipAmount) {
      return -1;
    }
    AudioPeakDetect.skipVal = 0;

    const buffer = ev.inputBuffer.getChannelData(0);
    const length = buffer.length;

    let max = -1;
    for (let i = 0; i < length; ++i) {
      max = Math.max(max, buffer[i]);
    }

    return max;
  }

  constructor() {
    throw new Error('Not to be instantiated');
  }
}

@Component({
  selector: 'app-audio-preview',
  templateUrl: './audio-preview.component.html',
  styleUrls: ['./audio-preview.component.scss'],
  providers: [TranslatePipe]
})
export class AudioPreviewComponent implements OnInit, OnDestroy {
  private _deviceId: string;

  private audioStream: MediaStream;

  private context: AudioContext;
  private source: MediaStreamAudioSourceNode;
  private scriptNode: ScriptProcessorNode;

  public volume: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public subscription: Subscription;
  public vol = 0;

  constructor(private cf: ChangeDetectorRef,
              private alertService: AlertService,
              private translate: TranslatePipe) {
  }

  ngOnInit() {
    this.subscription = this.volume.subscribe((vol: number) => {
      this.vol = isNaN(vol) ? 0.0 : vol;
      this.cf.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyMedia();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private destroyMedia() {
    if (this.audioStream) {
      if (this.context) {
        this.context.close();
        this.scriptNode.onaudioprocess = null;
        this.scriptNode.disconnect(0);
        this.source.disconnect(0);
        this.context = null;
      }

      for (const track of this.audioStream.getAudioTracks()) {
        track.stop();
        track.enabled = false;
      }

      this.audioStream = null;
    }
  }

  public get deviceId(): string {
    return this._deviceId;
  }

  @Input()
  public set deviceId(id: string) {
    this._deviceId = id;

    this.destroyMedia();

    // todo: fix the race condition here by multiple device sets,
    // change to a obserable approach?
    if (id !== '') {
      this.createMediaStream();
    }
  }

  private createMediaStream() {
    navigator.mediaDevices.getUserMedia({audio: {deviceId: this._deviceId}})
      .then(stream => {
        this.audioStream = stream;
        this.createAudioContext();
      })
      .catch(() => {
        this.alertService.addAlert(this.translate.transform("AUDIOPREVIEW_ALERT_MICROPHONE",'Unable to get microphone input.'), AlertType.Danger);
      });
  }

  private createAudioContext() {
    // @ts-ignore
    if (window.AudioContext) {
      this.context = new AudioContext();
    } else {
      // @ts-ignore
      if (window.webkitAudioContext) {
        // @ts-ignore
        this.context = new webkitAudioContext();
      } else {
        // if we can't create an audio context then just ignore
        return;
      }
    }

    this.source = this.context.createMediaStreamSource(this.audioStream);

    this.scriptNode = this.context.createScriptProcessor(1024, 1, 1);

    AudioPeakDetect.init();

    this.scriptNode.onaudioprocess = (ev: AudioProcessingEvent) => {
      const result = AudioPeakDetect.ProcessAudio(ev);

      // Ignore return values of -1
      if (result === -1) {
        return;
      }

      this.volume.next(result);
    };

    this.source.connect(this.scriptNode);
    this.scriptNode.connect(this.context.destination);
  }
}
