import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OnlineState } from '../../enums/online-state.enum';
import { AlertService, AlertType } from '../../services/alert-service/alert.service';
import * as moment from 'moment';
import { TranslatePipe } from '../../filters/Translate.pipe'
import { AgentCalendar } from '../../services/agent-calendar-service/agent-calendar';
import { AgentCalendarColumn } from '../../enums/AgentCalendarColumn.enum';
import { AgentCalendarService } from '../../services/agent-calendar-service/agent-calendar.service';

enum LinkAction {
  ADDSLOT = 0,
  REMOVESLOT = 1,
  SAVESLOT = 2,
  EDITSLOT = 3,
  DELETEDAYSLOTS = 4
}

@Component({
  selector: 'app-agent-calendar',
  templateUrl: './agent-calendar.component.html',
  styleUrls: ['./agent-calendar.component.scss'],
  providers: [TranslatePipe]
})
export class AgentCalendarComponent implements OnInit {

  public agentCalendarColumns = [
    { value: AgentCalendarColumn.DAY, prop: 'day' },
    { value: AgentCalendarColumn.STARTTIME, prop: 'startTime' },
    { value: AgentCalendarColumn.ENDTIME, prop: 'endTime' },
    { value: AgentCalendarColumn.CLOSED, prop: 'off' },
    { value: AgentCalendarColumn.ACTION, prop: 'action' },
  ];

  public agentCalrows: BehaviorSubject<AgentCalendar[]> = new BehaviorSubject<AgentCalendar[]>([]);
  public otherAgentsCalendar: BehaviorSubject<Map<string, AgentCalendar[]>> = new BehaviorSubject<Map<string, AgentCalendar[]>>(null);
  public loading: boolean = false;


  public OnlineState = OnlineState;
  private subscriptions: Subscription[] = [];
  constructor(
    private agentCalendarService: AgentCalendarService,
    private translate: TranslatePipe,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getAgentCalendar();
    this.getOtherAgentsCalendar();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public createReadOnlyDayElementContent(day: string, lastrow: AgentCalendar) {
    if (lastrow != undefined) {
      if (lastrow.day == day) {
        return "";
      }
    }
    return day;
  }

  public createReadOnlyTimeElementContent(startTime: string, endTime: string, closed: boolean): string {
    if (closed) {
      return this.translate.transform("AGENTCAL_COLUMN_LABEL_UNAVAILABLE", 'Unavailable');
    }
    return this.formatTime(startTime) + "-" + this.formatTime(endTime);
  }

  private formatTime(timeValue: string): string {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    return moment(currentDate + " " + timeValue).format("HH:mm");
  }

  public getAgentCalendar(): void {
    this.loading = true;
    this.subscriptions.push(
      this.agentCalendarService.getAgentCalendarData().subscribe(data => {
        if (data != null) {
          this.agentCalrows.next(Array.from(data));
          this.createCalendarTable();
        }
      })
    );
    this.loading = false;
  }

  public createCalendarTable(): void {
    const table = document.getElementById("agentCalendar");
    let tbody;
    if (table != null) {
      tbody = table.getElementsByTagName('tbody')[0];
    }
    if (tbody == null) {
      return;
    }
    this.removeAllChildNodes(tbody);

    const calData = this.agentCalrows.value;
    for (let i = 0; i < calData.length; i++) {
      // creates a table row
      const rowClass = "row-" + calData[i].day.toLowerCase();
      const dayrow = document.getElementsByClassName(rowClass);
      const row = this.createslotRow(dayrow, calData[i]);
      row.classList.add(rowClass);
      tbody.appendChild(row);
    }
  }

  public createslotRow(dayrow: HTMLCollection, rowData: AgentCalendar) {
    const row = document.createElement("tr");
    let cell = document.createElement("td");
    this.agentCalendarColumns.forEach(element => {
      let cellText: Text;
      switch (element.value) {
        case AgentCalendarColumn.DAY:
          {
            if (dayrow.length === 0) {
              cellText = document.createTextNode(rowData.day);
              cell.classList.add('data-' + AgentCalendarColumn.DAY.toString().toLowerCase());
              cell.appendChild(cellText);
            } else {
              cell = null;
            }
            break;
          }
        case AgentCalendarColumn.STARTTIME:
          {
            if (!rowData.closed) {
              cell = this.createTimeTDElement(rowData.startTime, AgentCalendarColumn.STARTTIME, false, false);
            } else {
              cell = this.createEmptyTDElement(AgentCalendarColumn.STARTTIME);
            }
            break;
          }
        case AgentCalendarColumn.ENDTIME:
          {
            if (!rowData.closed) {
              cell = this.createTimeTDElement(rowData.endTime, AgentCalendarColumn.ENDTIME, false, false);
            } else {
              cell = this.createEmptyTDElement(AgentCalendarColumn.ENDTIME);
            }
            break;
          }
        case AgentCalendarColumn.CLOSED:
          {
            cell = this.createClosedTDElement(rowData.closed, rowData.id, AgentCalendarColumn.CLOSED, false);
            break;
          }
        case AgentCalendarColumn.ACTION:
          {
            if (dayrow.length === 0) {
              cell = this.createActionTDElement(rowData, AgentCalendarColumn.ACTION, false);
              cell.classList.add('data-' + AgentCalendarColumn.ACTION.toString().toLowerCase());
            } else {
              cell = null;
            }
            break;
          }
      }
      if (dayrow.length > 0) {
        const drow = (dayrow[0] as HTMLTableRowElement);
        drow.cells[AgentCalendarColumn.DAY].rowSpan = dayrow.length + 1;
        drow.cells[AgentCalendarColumn.ACTION].rowSpan = dayrow.length + 1;
      }
      if (cell != null) {
        row.appendChild(cell);
      }
    });
    return row;
  }


  public createEditingFields(tdElement: HTMLTableCellElement, rowData: AgentCalendar, trElement: HTMLTableRowElement = null) {
    this.agentCalendarColumns.forEach(element => {
      switch (element.value) {
        case AgentCalendarColumn.STARTTIME:
          {
            const tdStartElement = this.createTimeTDElement(rowData.startTime, AgentCalendarColumn.STARTTIME, true, rowData.closed);
            const datastartCol = tdElement.getElementsByClassName("data-" + AgentCalendarColumn.STARTTIME.toString().toLowerCase());
            if (datastartCol.length > 0) {
              datastartCol[0].replaceWith(tdStartElement);
            } else {
              trElement != null ? trElement.appendChild(tdStartElement) : null;
            }
          }
          break;
        case AgentCalendarColumn.ENDTIME:
          {
            const tdEndElement = this.createTimeTDElement(rowData.endTime, AgentCalendarColumn.ENDTIME, true, rowData.closed);
            const dataendCol = tdElement.getElementsByClassName("data-" + AgentCalendarColumn.ENDTIME.toString().toLowerCase());
            if (dataendCol.length > 0) {
              dataendCol[0].replaceWith(tdEndElement);
            } else {
              trElement != null ? trElement.appendChild(tdEndElement) : null;
            }
          }
          break;
        case AgentCalendarColumn.CLOSED:
          {
            const tdClosedElement = this.createClosedTDElement(rowData.closed, rowData.id, AgentCalendarColumn.CLOSED, true);
            const dataclosedCol = tdElement.getElementsByClassName("data-" + AgentCalendarColumn.CLOSED.toString().toLowerCase());
            if (dataclosedCol.length > 0) {
              dataclosedCol[0].replaceWith(tdClosedElement);
            } else {
              trElement != null ? trElement.appendChild(tdClosedElement) : null;
            }
          }
          break;
        case AgentCalendarColumn.ACTION:
          {
            if (rowData.id > -1) {
              const tdActionElement = this.createActionTDElement(rowData, AgentCalendarColumn.ACTION, true);
              const actionCol = tdElement.getElementsByClassName("data-" + AgentCalendarColumn.ACTION.toString().toLowerCase());
              if (actionCol.length > 0) {
                tdActionElement.rowSpan = (actionCol[0] as HTMLTableCellElement).rowSpan;
                actionCol[0].replaceWith(tdActionElement);
              } else {
                trElement != null ? trElement.appendChild(tdActionElement) : null;
              }
            }
          }
          break;
      }
    });
    return trElement;
  }

  private createClosedTDElement(closed: boolean, id: number, column: AgentCalendarColumn, editing: boolean): HTMLTableCellElement {
    const cell = document.createElement("td");
    if (editing) {
      const closedField = document.createElement("input");
      closedField.classList.add("data-close");
      closedField.setAttribute("type", "checkbox");
      closedField.checked = closed;
      closedField.addEventListener("click", this.onClosedDaySelection);
      cell.appendChild(closedField);

    } else {
      const cellText = document.createTextNode(closed === true ? "Yes" : "No");
      cell.appendChild(cellText);
    }
    const rowId = this.createRowIdElement(id);
    cell.appendChild(rowId);
    cell.classList.add('data-' + column.toString().toLowerCase());
    return cell;
  }

  private onClosedDaySelection(event) {
    const cellParent = event.target.parentNode;
    const rowParent = cellParent.parentNode as HTMLTableRowElement;
    if (rowParent != undefined) {
      const rowClass = rowParent.classList[0];
      const datarows = document.getElementsByClassName(rowClass);
      Array.from(datarows).forEach(element => {
        const timeFieldcells = element.getElementsByClassName("time-field");
        Array.from(timeFieldcells).forEach(element => {
          (element as HTMLInputElement).style.display = event.target.checked ? "none" : "block";
        });
        const closeFields = element.getElementsByClassName("data-close");
        if (closeFields.length > 0) {
          (closeFields[0] as HTMLInputElement).checked = event.target.checked;
        }
      });
    }
  }

  private createRowIdElement(id: number): HTMLInputElement {
    const rowId = document.createElement("input");
    rowId.classList.add("data-id");
    rowId.setAttribute("type", "hidden")
    rowId.value = id.toString();
    return rowId;
  }
  private removeAllChildNodes(parent: HTMLElement) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  private createActionLinkTDElement(innerText: string, currentRow: AgentCalendar, linkAction: LinkAction) {
    const self = this;
    const link = document.createElement("a");
    let cellText = document.createTextNode(innerText);
    link.classList.add("p-3");
    link.href = "#";
    link.appendChild(cellText);

    link.addEventListener("click", function (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      self.onActionLinkClick(currentRow, linkAction);
    }, false);

    return link;
  }

  private onActionLinkClick(currentRow: AgentCalendar, action: LinkAction) {
    switch (action) {
      case LinkAction.ADDSLOT: {
        this.onAddSlot(currentRow);
        break;
      }
      case LinkAction.REMOVESLOT: {
        this.onRemoveSlot(currentRow);
        break;
      }
      case LinkAction.SAVESLOT: {
        this.onSaveCalendar(currentRow);
        break;
      }
      case LinkAction.EDITSLOT: {
        this.onEditDayRow(currentRow);
        break;
      }
      case LinkAction.DELETEDAYSLOTS: {
        this.onDeleteDayRow(currentRow.day);
        break;
      }
    }
  }

  private createActionTDElementForEditingRow(currentRow: AgentCalendar): HTMLTableCellElement {
    const cell = document.createElement("td");
    cell.className = "text-right";
    const addSlotLink = this.createActionLinkTDElement(this.translate.transform("AGENTCALENDAR_LINK_ADDSLOT", 'Add Slot'), currentRow, LinkAction.ADDSLOT);
    const removeSlotLink = this.createActionLinkTDElement(this.translate.transform("AGENTCALENDAR_LINK_REMOVESLOT", 'Remove Slot'), currentRow, LinkAction.REMOVESLOT);
    const savelink = this.createActionLinkTDElement(this.translate.transform("AGENTCALENDAR_LINK_SAVE", 'Save'), currentRow, LinkAction.SAVESLOT);
    cell.appendChild(addSlotLink);
    cell.appendChild(removeSlotLink);
    cell.appendChild(savelink);
    return cell;
  }

  private createActionTDElementForReadOnlyRow(currentRow: AgentCalendar): HTMLTableCellElement {
    const cell = document.createElement("td");
    cell.className = "text-right";
    const editlink = this.createActionLinkTDElement(this.translate.transform("AGENTCALENDAR_LINK_EDIT", 'Edit'), currentRow, LinkAction.EDITSLOT);
    const deletelink = this.createActionLinkTDElement(this.translate.transform("AGENTCALENDAR_LINK_DELETE", 'Delete'), currentRow, LinkAction.DELETEDAYSLOTS);
    cell.appendChild(editlink);
    cell.appendChild(deletelink);
    return cell;
  }

  private createEmptyTDElement(column: AgentCalendarColumn): HTMLTableCellElement {
    const cell = document.createElement("td");
    const field = document.createTextNode("");
    cell.appendChild(field);
    cell.classList.add('data-' + column.toString().toLowerCase());
    return cell;
  }


  public addNewSlot(row: AgentCalendar, dayrows: HTMLCollection) {
    const drow = (dayrows[0] as HTMLTableRowElement);
    drow.cells[AgentCalendarColumn.DAY].rowSpan = dayrows.length + 1;
    drow.cells[AgentCalendarColumn.ACTION].rowSpan = dayrows.length + 1;
    let dayTR = document.createElement("tr");
    const tdElement = document.createElement("td");
    const closeField = drow.getElementsByClassName("data-close");
    let closed = false;
    if (closeField.length > 0) {
      closed = (closeField[0] as HTMLInputElement).checked;
    }
    row.id = -1;
    row.closed = closed;
    dayTR = this.createEditingFields(tdElement, row, dayTR);
    return dayTR;
  }

  public editSlot(listArr: Element[], row: AgentCalendar) {
    const self = this;
    listArr.forEach(function (element, i) {
      const dataIdCol = element.getElementsByClassName("data-id");
      if (dataIdCol.length > 0) {
        const dataId = (dataIdCol[0] as HTMLInputElement).value;
        let rowData = self.getCalendarDataById(dataId);
        if (dataId == "0") {
          rowData = row;
        }
        self.createEditingFields(element as HTMLTableCellElement, rowData, null);
      }
    });
  }

  private createActionTDElement(currentRow: AgentCalendar, column: AgentCalendarColumn, editing: boolean): HTMLTableCellElement {
    let cell;
    if (editing) {
      cell = this.createActionTDElementForEditingRow(currentRow);
      cell.classList.add('data-' + column.toString().toLowerCase());
    }
    else {
      cell = this.createActionTDElementForReadOnlyRow(currentRow);
    }
    return cell;
  }
  private createTimeTDElement(timeValue: string, column: AgentCalendarColumn, editing: boolean, closed: boolean): HTMLTableCellElement {
    const cell = document.createElement("td");
    const field = this.createTimeInputField();
    const slotDate = moment(new Date()).format("YYYY-MM-DD");
    if (timeValue == undefined) {
      timeValue = moment(new Date()).format("HH:mm:ss");
    }
    const dtStr: string = moment(slotDate + " " + timeValue).format("YYYY-MM-DD HH:mm").toString();
    const time = new Date(dtStr);
    if (editing) {
      field.value = moment(time).format('HH:mm');
      field.style.display = closed ? "none" : "block";
      cell.appendChild(field);
    } else {
      const cellText = document.createTextNode(moment(time).format("HH:mm"));
      cell.appendChild(cellText);
    }
    cell.classList.add('data-' + column.toString().toLowerCase());
    return cell;
  }

  private createTimeInputField(): HTMLInputElement {
    const field = document.createElement("input");
    field.setAttribute("type", "time");
    field.classList.add("time-field");
    return field;
  }

  public getOtherAgentsCalendar() {
    this.loading = true;
    let agentCalendarMap = new Map<string, AgentCalendar[]>();
    this.subscriptions.push(
      this.agentCalendarService.getOtherAgentsCalendarData().subscribe(data => {
        data.forEach(element => {
          let keyName = element.agent + " (" + element.userName + ")";
          const collection = agentCalendarMap.get(keyName);
          if (!collection) {
            agentCalendarMap.set(keyName, [element])
          } else {
            collection.push(element);
          }
        });
        this.otherAgentsCalendar.next(agentCalendarMap);
      })
    );
    this.loading = false;
  }

  public onDeleteDayRow(day: string) {
    this.subscriptions.push(
      this.agentCalendarService.deleteCalendarSlotsByDay(day).subscribe(data => {
        if (data) {
          this.getAgentCalendar();
          this.alertService.addAlert(this.translate.transform("AGENTCALENDAR_MESSAGE_DELETED", 'Slots Deleted.'), AlertType.Success);
        } else {
          this.alertService.addAlert(this.translate.transform("AGENTCALENDAR_MESSAGE_NOTDELETED", 'Slots Not Deleted.'), AlertType.Danger);
        }
      })
    );
  }

  public onRemoveSlot(row: AgentCalendar) {
    const rowClass = "row-" + row.day.toLowerCase();
    const dayrow = document.getElementsByClassName(rowClass);
    if (dayrow.length > 1) {
      const drow = (dayrow[0] as HTMLTableRowElement);
      (dayrow[dayrow.length - 1] as HTMLTableRowElement).remove();
      drow.cells[AgentCalendarColumn.DAY].rowSpan = dayrow.length;
      drow.cells[AgentCalendarColumn.ACTION].rowSpan = dayrow.length;
    } else {
      this.alertService.addAlert(this.translate.transform("AGENTCALENDAR_MESSAGE_REMOVESLOT", 'Cannot remove time slot. A minimum of one time slot is required.'), AlertType.Danger)
    }
  }

  public onAddSlot(row: AgentCalendar) {
    const rowClass = "row-" + row.day.toLowerCase();
    let dayrow = document.getElementsByClassName(rowClass);
    if (dayrow.length > 0) {
      let dayTR = this.addNewSlot(row, dayrow);
      dayTR.classList.add(rowClass);
      this.insertAfter(dayTR, dayrow[dayrow.length - 1]);
    }
  }

  public insertAfter(newNode: HTMLElement, existingNode: Element) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  private validateCalendarSlotTime(startDate: Date, endDate: Date, siteOpHours: Date, siteClosingHours: Date): string {
    let validationError = '';
    if (endDate < startDate) {
      validationError = this.translate.transform("AGENTCALENDAR_VALIDATIONMSG_TIME", 'End Time must be greater than Start Time.');
    } else if (startDate < siteOpHours || endDate > siteClosingHours) {
      validationError = this.translate.transform("AGENTCALENDAR_VALIDATIONMSG_SITEHOURS", 'Slot time should be between Site Opening and Closing Hours.');
    }


    return validationError;
  }

  public onSaveCalendar(row: AgentCalendar) {
    const rowClass = "row-" + row.day.toLowerCase();
    const dayrow = document.getElementsByClassName(rowClass);
    if (dayrow.length > 0) {
      const slotArray = new Array<AgentCalendar>();
      let validationResult = true;
      for (let element of Array.from(dayrow)) {
        const slot = this.validateAndCreateSlotData(element, row);
        if (slot != null) {
          slotArray.push(slot);
        } else {
          validationResult = false;
          break;
        }
      }
      if (validationResult && slotArray.length > 0) {
        this.submitSlots(slotArray);
      }
    }
  }

  private validateAndCreateSlotData(element: Element, row: AgentCalendar): AgentCalendar {
    const drow = (element as HTMLTableRowElement);
    const startTimeField = drow.getElementsByClassName("data-" + AgentCalendarColumn.STARTTIME);
    const endTimeField = drow.getElementsByClassName("data-" + AgentCalendarColumn.ENDTIME);
    const closeField = drow.getElementsByClassName("data-close");
    const idField = drow.getElementsByClassName("data-id");
    let closed = false;
    let dataId = 0;
    let validationError = "";
    if (closeField.length > 0) {
      closed = (closeField[0] as HTMLInputElement).checked;
    }
    if (idField.length > 0) {
      dataId = Number.parseInt((idField[0] as HTMLInputElement).value);
    }
    let startDate;
    let endDate;
    if (startTimeField.length > 0 && endTimeField.length > 0) {
      const startTimeCell = startTimeField[0].firstChild;
      const endTimeCell = endTimeField[0].firstChild;
      const slotDate = moment(new Date()).format("YYYY-MM-DD");
      const startDateStr: string = moment(slotDate + " " + (startTimeCell as HTMLInputElement).value).format("YYYY-MM-DD HH:mm").toString();
      startDate = new Date(startDateStr);
      const endDateStr: string = moment(slotDate + " " + (endTimeCell as HTMLInputElement).value).format("YYYY-MM-DD HH:mm").toString()
      endDate = new Date(endDateStr);
      const siteOpHoursStr: string = moment(slotDate + " " + row.siteOpHours).format("YYYY-MM-DD HH:mm").toString();
      const siteOpHours = new Date(siteOpHoursStr);
      const siteClosingHoursStr: string = moment(slotDate + " " + row.siteClosingHours).format("YYYY-MM-DD HH:mm").toString();
      const siteClosingHours = new Date(siteClosingHoursStr);
      if (!closed) {
        validationError = this.validateCalendarSlotTime(startDate, endDate, siteOpHours, siteClosingHours);
      }
    } else {
      validationError = this.translate.transform("AGENTCALENDAR_VALIDATIONMSG_INVALIDTIME", 'Invalid Time.');
    }
    let slot: AgentCalendar;
    if (validationError === '') {
      slot = this.createSlotData(closed, row.day, moment(startDate).format("HH:mm"), moment(endDate).format("HH:mm"), dataId);
    } else {
      slot = null;
      this.alertService.addAlert(validationError, AlertType.Danger);
    }
    return slot;
  }


  private createSlotData(closed: boolean, day: string, startTime: string, endTime: string, Id: number): AgentCalendar {
    let slot = new AgentCalendar();
    slot.closed = closed;
    slot.day = day;
    slot.endTime = endTime;
    slot.startTime = startTime;
    slot.id = Id;
    return slot;
  }

  private submitSlots(slotArray: AgentCalendar[]) {
    this.subscriptions.push(
      this.agentCalendarService.submitCalendarSlots(slotArray).subscribe(data => {
        if (data) {
          this.getAgentCalendar();
          this.alertService.addAlert(this.translate.transform("AGENTCALENDAR_VALIDATIONMSG_SAVED", 'Data Saved'), AlertType.Success);
        } else {
          this.alertService.addAlert(this.translate.transform("AGENTCALENDAR_VALIDATIONMSG_NOTSAVED", 'Something Went Wrong'), AlertType.Danger);
        }
      })
    );
  }

  private getRowsByDay(day: string): Element[] {
    const dayrows = document.getElementsByClassName("row-" + day.toLowerCase());
    let listArr = Array.from(dayrows);
    return listArr;
  }

  public getCalendarDataById(dataId: string): AgentCalendar {
    let data: AgentCalendar = null;
    this.agentCalrows.value.forEach(function (element: AgentCalendar) {
      if (element.id === Number.parseInt(dataId))
        data = element;
    });
    return data;
  }

  public onEditDayRow(row: AgentCalendar) {
    let listArr = this.getRowsByDay(row.day);
    this.editSlot(listArr, row);
  }

}
