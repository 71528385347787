import {Subscription, Subject, Observable, BehaviorSubject} from 'rxjs';
import {ELECTRON_WEBRTC_EVENTS, ELECTRON_BROWSER_EVENTS, ELECTRON_APP_EVENTS} from './electron-constants';
import { WindowMessageService } from '../window-message-service/window-message.service';
import {DisplayMediaInitialState, DisplayMediaModalComponent, ShareOptions} from '../../components/display-media-modal/display-media-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BrowserChannelIPCMessage } from './browser-channel-ipc-message';
import { BrowserIPCMessage } from './browser-ipc-message';
import { LoggingService } from '../logging.service';
import {Rectangle} from '../../classes/Rectangle';
import { ModalService } from '../modal.service';
import {TranslatePipe} from "../../filters/Translate.pipe";

// These commands form the public interface of the input injector for hovis to use.
// This is a copy of these definitions from Electron and needs to be kept up to date with Electron.

const enum INJECTOR_COMMANDS {
  CREATE_INJECTOR = 'create-injector',
  DESTORY_INJETOR = 'destroy-injector',
  MOUSE_INPUT = 'mouse-input',
  KEYBOARD_INPUT = 'keyboard-input',
  TEXT_INPUT = 'text-input',
  SUSPEND_INPUT = "suspend-input",
  RESUME_INPUT = "resume-input",
}

const INPUT_INJECTOR_COMMAND: string = "input-injector-command";

interface CreateInjectorCommand {
  command: INJECTOR_COMMANDS.CREATE_INJECTOR;
  type: string;
  handle: string;
  auraId: string;
}

interface DestoryInjectorCommand {
  command: INJECTOR_COMMANDS.DESTORY_INJETOR;
}

interface MouseInputCommand {
  command: INJECTOR_COMMANDS.MOUSE_INPUT;
  x: number;
  y: number;
  flags: number;
}

interface KeyboardInputCommand {
  command: INJECTOR_COMMANDS.KEYBOARD_INPUT;
  key: string;
  keyFlags: number;
}

interface TextInputCommand {
  command: INJECTOR_COMMANDS.TEXT_INPUT;
  text: string;
}

interface SuspendInputCommand {
  command: INJECTOR_COMMANDS.SUSPEND_INPUT;
}

interface ResumeInputCommand {
  command: INJECTOR_COMMANDS.RESUME_INPUT;
}

type InputInjectorCommand = CreateInjectorCommand
                        | DestoryInjectorCommand
                        | MouseInputCommand
                        | KeyboardInputCommand
                        | TextInputCommand
                        | SuspendInputCommand
                        | ResumeInputCommand;

const INPUT_INJECTOR_EVENT: string = "input-injector-event";

// Output events coming from this module
const enum INJECTOR_EVENTS {
  SUSPENDED = "suspended-input",
  RESUMED = "resumed-input",
}

interface SuspendedEvent {
  type: INJECTOR_EVENTS.SUSPENDED
}

interface ResumedEvent {
  type: INJECTOR_EVENTS.RESUMED
}

type InputInjectorEvent = SuspendedEvent | ResumedEvent;

export class ElectronWebrtcSharingProxy {
  private displayMediaModalRef: DynamicDialogRef;
  private sub: Subscription;

  public sourceSelected$: Subject<any> = new Subject();
  public urlLoaded$: Subject<string> = new Subject();
  public inputInjectorEvent$: Subject<InputInjectorEvent> = new Subject();
  public focused$: Subject<boolean> = new Subject<boolean>();

  public boundsUpdated: BehaviorSubject<Rectangle> = new BehaviorSubject<Rectangle>({x: 0, y: 0, width: 0, height: 0});
  public browserShowing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private windowMessageService: WindowMessageService, private modalService: ModalService,  private loggingService: LoggingService, private translate: TranslatePipe) {
    this.sub = this.windowMessageService.ipcMessage$.subscribe(message => {
      switch (message.action) {
        case ELECTRON_WEBRTC_EVENTS.POPULATE_SOURCES:
          this.showSourcesDialog(message.data);
          break;
        case ELECTRON_BROWSER_EVENTS.BROWSER_URL_LOADED:
          this.updatePage(message);
          break;
        case ELECTRON_BROWSER_EVENTS.INPUT_INJECTOR_EVENT:
          this.inputInjectorEvent$.next(message.data);
          break;
        case ELECTRON_BROWSER_EVENTS.BROWSER_INPUT_FOCUS:
          this.cobrowseFocus(message.data);
          break;
        case ELECTRON_BROWSER_EVENTS.BROWSER_BOUNDS_UPDATED:
          this.boundsUpdated.next(message.data);
          break;
        case ELECTRON_BROWSER_EVENTS.WEBRTC_SHARING_RESUME:
          this.browserShowing.next(true);
          break;
        case ELECTRON_BROWSER_EVENTS.WEBRTC_SHARING_PAUSE:
          this.browserShowing.next(false);
          break;
       }
    });
  }

  private updatePage(message: any) {
    try {
      const browserMessage = new BrowserChannelIPCMessage(message.action, BrowserIPCMessage.deserialize(message.data))
      this.urlLoaded$.next(browserMessage.message.data);
    } catch (err) {
    }
  }

  private cobrowseFocus(focused: boolean) {
    this.focused$.next(focused);
  }

  private showSourcesDialog(sources) {

    try {
      const parsedSources = Array.isArray(sources) ? sources : JSON.parse(sources);

      const data: DisplayMediaInitialState = {
        startShareCallback: (opts: ShareOptions) =>   this.sharingOptionsSelected(opts),
        sources: parsedSources
      };
      this.displayMediaModalRef = this.modalService.openModal(DisplayMediaModalComponent, {
        data,
        closeOnEscape: false,
        showHeader: true,
        closable: false,
        header: this.translate.transform('DISPLAYMEDIA_HEADER', 'Share your Screen?'),
        contentStyle: {width: '600px'}
      });
    } catch (error) {
      this.loggingService.error(error);
      this.dispose();
    }

  }

  private sharingOptionsSelected(opts: ShareOptions){
    this.sourceSelected$.next(opts.source);
    // Toggle on off coform fill
    opts.viewOnly ? this.suspendInput() : this.resumeInput();
  }

  public startSharing(types) {
    if (this.modalService.isModalShowing()) {
      return;
    }
    this.send(ELECTRON_WEBRTC_EVENTS.GET_SOURCES, types);
  }

  public createInjector(constraints) {
    const source = constraints.video.mandatory.chromeMediaSourceId;
    // Source id format
    // https://chromium.googlesource.com/external/webrtc/+/refs/heads/master/modules/desktop_capture/desktop_capture_types.h
    const splitSource = source.split(':');
    const type = splitSource[0];
    const handle = splitSource[1];
    const auraId = splitSource[2]; // auraId https://chromium.googlesource.com/chromium/src/+/master/content/public/browser/desktop_media_id.h
    // Keep everything as strings because JavaScript can't handle 64bit integers

    const command: CreateInjectorCommand = {
      command: INJECTOR_COMMANDS.CREATE_INJECTOR,
      handle,
      type,
      auraId
    };
    this.sendInputInjectorCommand(command);
  }

  public destroyInjector() {
    const command: DestoryInjectorCommand = {
      command: INJECTOR_COMMANDS.DESTORY_INJETOR
    };
    this.sendInputInjectorCommand(command);
  }

  public sendMouseEvent(x: number, y: number, flags: number) {
    const command: MouseInputCommand = {
      command: INJECTOR_COMMANDS.MOUSE_INPUT,
      x,
      y,
      flags
    };
    this.sendInputInjectorCommand(command);
  }

  public sendText(text: string) {
    const command: TextInputCommand = {
      command: INJECTOR_COMMANDS.TEXT_INPUT,
      text
    };
    this.sendInputInjectorCommand(command);
  }

  public sendKeyboardEvent(key: string, keyFlags: number) {
    const command: KeyboardInputCommand = {
      command: INJECTOR_COMMANDS.KEYBOARD_INPUT,
      key,
      keyFlags
    };
    this.sendInputInjectorCommand(command);
  }

  public suspendInput() {
    const command: SuspendInputCommand = {
      command: INJECTOR_COMMANDS.SUSPEND_INPUT
    };
    this.sendInputInjectorCommand(command);
  }

  public resumeInput() {
    const command: ResumeInputCommand = {
      command: INJECTOR_COMMANDS.RESUME_INPUT
    };
    this.sendInputInjectorCommand(command);
  }

  private sendInputInjectorCommand(command: InputInjectorCommand) {
    this.send(INPUT_INJECTOR_COMMAND, JSON.stringify(command));
  }

  private send(action, data?) {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action,
      data
    }, "*");
  }

  public dispose() {
    this.destroyInjector();

    this.sub.unsubscribe();

    if (this.displayMediaModalRef) {
      this.modalService.closeModal(this.displayMediaModalRef);
      this.displayMediaModalRef = null;
    }
  }
}
