import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faArrowRightToBracket, faBan, faHand, faRightLeft, faTimesCircle, faUsers, faXmark} from '@fortawesome/free-solid-svg-icons';
import {Features, FeatureService,} from '../../../../services/feature-service/feature.service';
import {TranslatePipe} from '../../../../filters/Translate.pipe';
import {EngagementState} from '../../../../services/engagement';
import {VerifyStatuses} from '../../../../services/engagementhub.service';

@Component({
  selector: 'app-veechat-engagement-controls',
  templateUrl: './veechat-engagement-controls.component.html',
  styleUrls: ['./veechat-engagement-controls.component.scss'],
  providers: [TranslatePipe],
})
export class VeechatEngagementControlsComponent implements OnInit {

  public get isPostEngagement() {
    return this.currentState === EngagementState.Post;
  }

  public get isJoin() {
    return this.currentState === EngagementState.Join;
  }

  public get isTransfer() {
    return this.currentState === EngagementState.Transfer;
  }

  public get multipartyAvailable(): boolean {
    return !this.isMobileSDK && this.hasMultiparty;
  }

  constructor(
    private readonly featureService: FeatureService,
  ) {
  }
  @Input() isPrimaryAgent: boolean;
  @Input() roomAgentsSize: number;
  @Input() isBlockedOn: boolean;
  @Input() currentState: EngagementState;
  @Input() roomVisitorsSize: number;
  @Input() requestingHelp: boolean;
  @Input() privateChatAvailable: boolean;
  @Output() toggleJoinCall = new EventEmitter<any>();
  @Output() toggleTransferCall = new EventEmitter<any>();
  @Output() engagementEnded = new EventEmitter<any>();
  @Output() toggleBlockCustomer = new EventEmitter<any>();
  @Output() toggleHelpRequest = new EventEmitter<void>();

  @Input() isMobileSDK: boolean;

  @Input() hasCustomerVerification: boolean;
  @Input() verifyStatus: string = VerifyStatuses.Pending;
  @Output() toggleVerifyCustomer = new EventEmitter();

  public faTimesCircle = faTimesCircle;

  public transferAvailable: boolean;
  private hasMultiparty: boolean;

  protected readonly faBan = faBan;
  protected readonly faXmark = faXmark;
  protected readonly faRightLeft = faRightLeft;
  protected readonly faUsers = faUsers;
  protected readonly faHand = faHand;
  protected readonly faArrowRightToBracket = faArrowRightToBracket;

  ngOnInit() {
    this.hasMultiparty = true;
    this.transferAvailable = true;
    this.hasCustomerVerification = this.isPrimaryAgent && this.featureService.has(Features.VERIFY_CUSTOMER);
  }

  joinEngagement() {
    this.toggleJoinCall.emit();
  }

  transferEngagement() {
    if (!this.roomIsFull()) {
      this.toggleTransferCall.emit();
    }
  }

  endEngagement() {
    this.engagementEnded.emit();
  }

  blockCustomer() {
    this.toggleBlockCustomer.emit();
  }

  roomIsFull() {
    if (this.roomAgentsSize >= 2 || this.roomVisitorsSize >= 2) {
      return true;
    } else {
      return false;
    }
  }

  helpRequest() {
    this.toggleHelpRequest.emit();
  }

  verifyCustomer() {
    this.toggleVerifyCustomer.emit();
  }
}
