<!-- code to be show on desktop start-->
<app-navbar (endBreak)="onEndBreak()"></app-navbar>
<div class="container-wrapper veechat" [class.engaged]="isEngaged$ | async">
  <div class="d-flex flex-row h-100" [ngSwitch]="(currentState$ | async)" *ngIf="!(noInternet | async)">
    <app-onbreak *ngSwitchCase="OnlineState.OnBreak"
                 (endBreak)="onEndBreak()"
                 class="w-100">
    </app-onbreak>

    <ng-container *ngSwitchCase="OnlineState.MultiChat">
      <div class="d-flex flex-column h-100 gap-2 sidebar-panel px-0 mr-2">
        <app-veechat-call-list
          class="overflow-hidden flex-grow-1"
          (selectionChange)="onSelection($event)"
          (workingStatusChange)="onWorkingStatus($event)"
        ></app-veechat-call-list>

        <app-engagement-controls-direction-buttons
          class="engagement-ctrls engagement-ctrls-direction-btns align-self-center w-100 px-2"
          *ngIf="isEngaged$ | async"
          [pTooltip]="(isMobileOrTablet$ | async) ? ('ENGAGEMENTCONTROL_TITLE_PANELPOSITION_DISABLED' | Translate: 'Control disabled because customer is on a mobile device')  : ('ENGAGEMENTCONTROL_TITLE_PANELPOSITION' | Translate: 'Chat Panel Position')"
          [panelPosition]="panelPositionAndSize$ | async" (panelPositionChanged)="onPanelPositionChanged($event)"
          [enabled]="(isPrimaryAgent$ | async) && !(isMobileOrTablet$ | async) && !(backgrounded | async)">
        </app-engagement-controls-direction-buttons>

        <app-engagement-assets
          class="engagement-ctrls px-2"
          [disabled]="(backgrounded | async) || !(isPresenter$ | async)"
          *ngIf="(isEngaged$ | async)"
          (selectedAsset)="onSelectAsset($event)">
        </app-engagement-assets>
      </div>

      <ng-container [ngSwitch]="selected.type">
        <app-veechat-engagement *ngSwitchCase="VeechatSelection.engagement"
                                class="chats-container overflow-x-hidden w-100"
                                [engagementId]="selected.id"
                                (engagementChange)="onEngagementChange($event)"
                                (engagementEnded)="onEngagementEnded()"
        ></app-veechat-engagement>

        <app-veechat-conversation
          *ngSwitchCase="VeechatSelection.conversation"
          [conversationId]="selected.id"
          (ended)="onEngagementEnded()"
          class="chats-container overflow-x-hidden w-100"
        ></app-veechat-conversation>

        <app-veechat-visitor
          *ngSwitchCase="VeechatSelection.visitor"
          [visitor]="selected.visitor"
          [username]="username"
          class="chats-container overflow-x-hidden w-100"
        ></app-veechat-visitor>

        <app-veechat-visitor
          *ngSwitchCase="VeechatSelection.transfer"
          [visitor]="selected.transferRequest.visitor"
          [username]="username"
          class="chats-container overflow-x-hidden w-100"
        ></app-veechat-visitor>

        <app-veechat-visitor
          *ngSwitchCase="VeechatSelection.invite"
          [visitor]="selected.joinRequest.visitor"
          [username]="username"
          class="chats-container overflow-x-hidden w-100"
        ></app-veechat-visitor>

        <div *ngSwitchCase="VeechatSelection.none"  class=" d-flex flex-column justify-content-center text-center align-items-center w-100 h-100 gap-2 user-select-none border-style border-radius-2">
          <h1 class="mb-4">{{ "CHAT_CONTAINER_NOCHAT" | Translate : "You have no active chats" }} </h1>
          <fa-icon class="mt-4" size="8x" [icon]="faComments"></fa-icon>
        </div>

      </ng-container>
    </ng-container>

    <app-crm-opportunity *ngSwitchCase="OnlineState.CrmOpportunity"></app-crm-opportunity>

    <app-appointment-search class="main-layout" *ngSwitchCase="OnlineState.Appointment"></app-appointment-search>
  </div>
  <app-no-internet id="no-internet-container" *ngIf="(noInternet | async)" class="d-flex flex-row h-100 p-2"></app-no-internet>
</div>

<app-call-list-bottom-controls [class.d-none]="(isAgentEngaged | async)"></app-call-list-bottom-controls>
