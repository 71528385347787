export class CountryFlags {
  private static ASSET_PATH = '/assets/country-flag-icons/';

  static getIcon(flag: string): string {
    if (!flag) {
      return CountryFlags.ASSET_PATH + 'blank.png';
    }

    const countryFlag: String = flag.toLowerCase();
    if (countryFlag.length > 0 && countryFlag !== 'zzunknown') {
      return CountryFlags.ASSET_PATH + countryFlag + '.png';
    }

    return CountryFlags.ASSET_PATH + 'blank.png';
  }
}
