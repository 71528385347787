import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import * as MultiPeerWebRTC from 'multi-peer-webrtc';

import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { VIDEO_WINDOW_COMMANDS, VIDEO_WINDOW_EVENTS, VIDEO_WINDOW_RENDERER_COMMAND, VIDEO_WINDOW_RENDERER_EVENT } from '../../IVideoComponent';
import { UIEvents } from '../../../../../../enums/multipeer/UIEvents';
import { PeerEvents } from '../../../../../../enums/multipeer/PeerEvents';
import { Orientation } from '../../../../../../enums/multipeer/Orientation';
import { VirtualBackground } from '../../../../../../classes/virtualBackground';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-video-window',
  templateUrl: './video-window.component.html',
  styleUrls: ['./video-window.component.scss']
})
export class VideoWindowComponent implements OnInit {
  @HostBinding('class.undockable') get undockable() {
    return this.dragResizeEnabled;
  }

  faExpand = faExpand;
  faCompress = faCompress;

  private _undocked:boolean = false;
  public set undocked(value: boolean) {
    this._undocked = value;
  }
  public get undocked(): boolean {
    return this._undocked;
  }

  private _dragResizeEnabled:boolean = false;
  public set dragResizeEnabled(value: boolean) {
    this._dragResizeEnabled = value;
  }
  public get dragResizeEnabled(): boolean {
    return this._dragResizeEnabled;
  }

  private _virtualBackgroundOn:boolean;
  set virtualBackgroundOn(value:boolean) {
    this._virtualBackgroundOn = value;
  }
  get virtualBackgroundOn(): boolean {
    return this._virtualBackgroundOn;
  }

  private _virtualBackground:VirtualBackground;
  set virtualBackground(value:VirtualBackground) {
    this._virtualBackground = value;
  }
  get virtualBackground(): VirtualBackground {
    return this._virtualBackground;
  } 

  private call: MultiPeerWebRTC.CallP2PAgent;
  private backgroundConfig:MultiPeerWebRTC.BackgroundConfig = null;
  private postProcessingConfig:MultiPeerWebRTC.PostProcessingConfig = null;

  constructor() { }

  ngOnInit() {
    if (!window['api'] || !window['api'].receive) return;
    // Called when message received from main process
    window['api'].receive(VIDEO_WINDOW_RENDERER_COMMAND, (command) => {
      switch (command.command) {
        case VIDEO_WINDOW_COMMANDS.DRAGRESIZE_ENABLED:
          this.dragResizeEnabled = command.enabled;
          break;
        case VIDEO_WINDOW_COMMANDS.WINDOW_UNDOCKED:
          this.undocked = command.undocked;
          break;
        case VIDEO_WINDOW_COMMANDS.INITIALISE_CALL:
          this.initialiseCall(command.engagementId, command.peerId, command.connectTracks, command.iceServer, command.cameraId, command.virtualBackground, command.virtualBackgroundOn);
          break;
        case VIDEO_WINDOW_COMMANDS.TEARDOWN_CALL:
          this.callTeardown();
          break;
        case VIDEO_WINDOW_COMMANDS.SIGNALLING_MESSAGE:
          this.processMessage(command.message);
          break;
        case VIDEO_WINDOW_COMMANDS.ADD_PEER:
          this.addPeer(command.peerId, command.peerType);
          break;
        case VIDEO_WINDOW_COMMANDS.REMOVE_PEER:
          this.removePeer(command.peerId);
          break;
        case VIDEO_WINDOW_COMMANDS.SET_PRIMARY_VISITOR:
          this.setPrimaryVisitor(command.visitor);
          break;
        case VIDEO_WINDOW_COMMANDS.AUDIO_CHAT:
          this.audioChat();
          break;
        case VIDEO_WINDOW_COMMANDS.VIDEO_CHAT:
          this.videoChat();
          break;
        case VIDEO_WINDOW_COMMANDS.TEXT_CHAT:
          this.textChat();
          break;
        case VIDEO_WINDOW_COMMANDS.PAUSE_CALL:
          this.pauseCall();
          break;
        case VIDEO_WINDOW_COMMANDS.RESUME_CALL:
          this.resumeCall();
          break;
        case VIDEO_WINDOW_COMMANDS.CHANGE_CAMERA:
          this.changeCamera(command.cameraId, command.width, command.height);
          break;
        case VIDEO_WINDOW_COMMANDS.CHANGE_MICROPHONE:
          this.changeMic(command.micId);
          break;
        case VIDEO_WINDOW_COMMANDS.CHANGE_SPEAKER:
          this.changeSpeaker(command.speakerId);
          break;
        default:
            console.log(`${command.command} not recognised as a valid ${VIDEO_WINDOW_RENDERER_COMMAND}`);
      }
    });
  }

  // Send a message to the main process
  sendEventToMain(data) {
    window['api'].send(VIDEO_WINDOW_RENDERER_EVENT, JSON.stringify(data));
  }   

  initialiseCall(engagementId, peerId, connectTracks, iceServer, cameraId, virtualBackground, virtualBackgroundOn) {
      
      var options = {
        engagementId: engagementId,
        peerId: peerId,
        iceServer : iceServer,
        connectPeers : true,
        connectTracks: connectTracks,
        peerType: MultiPeerWebRTC.Constants.PeerType.Agent,
        mainPeerId: peerId, // set to me for now, need to test, might make it switch to the presenter, flash doesnt have the other agents videos so need to define spec
        speakerVolume: 1.0,
        mainVideoContainer: document.getElementById('main_video_container'),
        peersContainer: document.getElementById('peers_container')
      };

      this.call = new MultiPeerWebRTC.CallFactory(MultiPeerWebRTC.CallFactory.CallType.P2P, MultiPeerWebRTC.CallFactory.ParticipantType.AGENT, options);

      this.call.ui.on(UIEvents.Orientation, this.orientationChange.bind(this));
      this.call.peers.on(PeerEvents.Signal, this.sendMessage.bind(this));     
      
      this.virtualBackground = virtualBackground;
      this.virtualBackgroundOn = virtualBackgroundOn;
  
      const currentUrl = new URL(window.location.href);
      const resourcesUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port?':'+currentUrl.port:''}/assets/mpw-vb-resources`;
  
      if (virtualBackground) {      
        this.backgroundConfig = new MultiPeerWebRTC.BackgroundConfig({ 
          resourcesPath : resourcesUrl,
          type : virtualBackgroundOn ? VirtualBackground.convert(virtualBackground.type) : MultiPeerWebRTC.BackgroundConfig.Type.None,
          url : `${environment.assetProxy}${virtualBackground.url}`
        });
  
        this.postProcessingConfig = new MultiPeerWebRTC.PostProcessingConfig({ 
          sigmaSpace : virtualBackground.jointBilateralFilterSigmaSpace, 
          sigmaColor : virtualBackground.jointBilateralFilterSigmaColor, 
          coverageMin : virtualBackground.backgroundCoverageMin, 
          coverageMax : virtualBackground.backgroundCoverageMax,
          lightWrapping : virtualBackground.backgroundLightWrapping, 
          blendMode : virtualBackground.backgroundBlendMode
        });
      }    

      var camera = {
        cameraName: cameraId, 
        cameraWidth: 352, 
        cameraHeight: 198,
        backgroundConfig: this.backgroundConfig,
        postProcessingConfig: this.postProcessingConfig
      };
      this.call.initialise(camera)
      .then(() => {
        console.log("Initialised Camera");
        if (this.call) {
          this.call.startAudioVideo();
          this.sendEventToMain({ 
            type: VIDEO_WINDOW_EVENTS.ONCALLINITIALISED
          });
        }                
      })
      .catch(err => {
        console.log("Initialised Camera Failed, Err:" + err);
        this.sendEventToMain({ 
          type: VIDEO_WINDOW_EVENTS.ONCALLERRORED,
          error: err.toString()
        });
      });
  }

  callTeardown() {
    if (this.call != null) {
      this.call.ui.off(UIEvents.Orientation, this.orientationChange.bind(this));
      this.call.peers.off(PeerEvents.Signal, this.sendMessage.bind(this));      
      this.call.dispose();
      this.call = null;
    }
  }

  sendMessage(message) {
    this.sendEventToMain({ 
      type: VIDEO_WINDOW_EVENTS.ONSIGNALLINGMESSAGE, 
      message: message
    });
  }

  processMessage(message) {        
    if (this.call != null) {
      this.call.peers.processMessage(message.from, message);
    }
  }

  addPeer(peerId, peerType) {
    if (this.call != null) {
      this.call.addPeer(peerId, peerType);
    }
  }

  removePeer(peerId) {
    if (this.call != null) {
      this.call.removePeer(peerId);
    }
  }

  setPrimaryVisitor(peerId) {
    if (this.call != null) {
      this.call.setPrimaryVisitor(peerId);
    }
  }

  textChat() {
    if (this.call != null) {
      this.call.textChat();
    }
  }

  audioChat(){
    if (this.call != null) {
      this.call.audioChat();
    }
  }

  videoChat() {
    if (this.call != null) {
      this.call.videoChat();
    }
  }

  pauseCall() {
    if (this.call != null) {
      this.call.pauseCall();
    }
  }

  resumeCall() {
    if (this.call != null) {
      this.call.resumeCall();
    }
  }

  changeCamera(cameraId, width, height) {
    if (this.call != null) {
      this.call.changeCamera(cameraId, width, height);
    }
  }

  changeMic(micId) {
    if (micId && this.call) {
      this.call.selectMicrophone(micId);
    }
  }

  changeSpeaker(speakerId) {
    if (speakerId && this.call) {
      this.call.selectSpeaker(speakerId);
    }
  }



  public onDockChange(event:boolean) : void {
    this.sendEventToMain({ 
      type: VIDEO_WINDOW_EVENTS.ONDOCKCHANGED,
      undocked: event
    });
  }

  public dockVideo() : void {
    this.mouseover = false;
    this.sendEventToMain({ 
      type: VIDEO_WINDOW_EVENTS.ONDOCKCHANGED,
      undocked: false
    });
  }

  public unDockVideo() : void {
    this.mouseover = false;
    this.sendEventToMain({ 
      type: VIDEO_WINDOW_EVENTS.ONDOCKCHANGED,
      undocked: true
    });
  }

  public mouseover:boolean = false;
  @HostListener('window:mouseover', ['$event'])
  onMouseOver(event:MouseEvent) {
    if (!this.dragResizeEnabled) return;
    this.mouseover = true;    
  }
  @HostListener('window:mouseout', ['$event'])
  onMouseOut(event:MouseEvent) {
    if (!this.dragResizeEnabled) return;
    this.mouseover = false;
  }

  private orientationChange(orientation:Orientation) {
    if (!this.dragResizeEnabled) return;    
    this.sendEventToMain({ 
      type: VIDEO_WINDOW_EVENTS.ONORIENTATIONCHANGED,
      orientation: orientation
    });
  }

  public turnVirtualBackgroundOff() {
    if (this.backgroundConfig) {
      this.backgroundConfig.type = MultiPeerWebRTC.BackgroundConfig.Type.None;
      if (this.call) {
        this.call.updateBackgroundConfig(this.backgroundConfig);
      }            
      this.virtualBackgroundOn = false;
    }
  }

  public turnVirtualBackgroundOn() {
    if (this.backgroundConfig) {            
      if (this.virtualBackground) {
        this.backgroundConfig.type = VirtualBackground.convert(this.virtualBackground.type);
        if (this.call) {
          this.call.updateBackgroundConfig(this.backgroundConfig);
        } 
      }
      this.virtualBackgroundOn = true;
    }
  }


}
