import { WebGLHelper, glsl } from '../WebglHelper'

export default class BackgroundVideoStage {

    constructor(
        gl,
        positionBuffer,
        texCoordBuffer,
        personMaskTexture,
        backgroundVideoUrl,
        canvas
    ) {

        this.buildBackgroundPass = (
            gl, 
            positionBuffer, 
            texCoordBuffer, 
            personMaskTexture, 
            backgroundVideoUrl, 
            canvas
        ) => {
            
            const vertexShaderSource = glsl`#version 300 es

                uniform vec2 u_backgroundScale;
                uniform vec2 u_backgroundOffset;

                in vec2 a_position;
                in vec2 a_texCoord;

                out vec2 v_texCoord;
                out vec2 v_backgroundCoord;

                void main() {
                    // Flipping Y is required when rendering to canvas
                    gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
                    v_texCoord = a_texCoord;
                    v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
                }
            `;

            const fragmentShaderSource = glsl`#version 300 es

                precision highp float;

                uniform sampler2D u_inputFrame;
                uniform sampler2D u_personMask;
                uniform sampler2D u_background;
                uniform vec2 u_coverage;
                uniform float u_lightWrapping;
                uniform float u_blendMode;

                in vec2 v_texCoord;
                in vec2 v_backgroundCoord;

                out vec4 outColor;

                vec3 screen(vec3 a, vec3 b) {
                    return 1.0 - (1.0 - a) * (1.0 - b);
                }

                vec3 linearDodge(vec3 a, vec3 b) {
                    return a + b;
                }

                void main() {
                    vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
                    vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
                    float personMask = texture(u_personMask, v_texCoord).a;
                    float lightWrapMask = 1.0 - max(0.0, personMask - u_coverage.y) / (1.0 - u_coverage.y);
                    vec3 lightWrap = u_lightWrapping * lightWrapMask * backgroundColor;
                    frameColor = u_blendMode * linearDodge(frameColor, lightWrap) + (1.0 - u_blendMode) * screen(frameColor, lightWrap);
                    personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
                    outColor = vec4(frameColor * personMask + backgroundColor * (1.0 - personMask), 1.0);
                }
            `;

            const { width: outputWidth, height: outputHeight } = canvas;
            const outputRatio = outputWidth / outputHeight;

            const vertexShader = WebGLHelper.compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
            const fragmentShader = WebGLHelper.compileShader(
                gl,
                gl.FRAGMENT_SHADER,
                fragmentShaderSource
            );
            const program = WebGLHelper.createPiplelineStageProgram(
                gl,
                vertexShader,
                fragmentShader,
                positionBuffer,
                texCoordBuffer
            );
            const backgroundScaleLocation = gl.getUniformLocation(program, 'u_backgroundScale');
            const backgroundOffsetLocation = gl.getUniformLocation(program, 'u_backgroundOffset');
            const inputFrameLocation = gl.getUniformLocation(program, 'u_inputFrame');
            const personMaskLocation = gl.getUniformLocation(program, 'u_personMask');
            const backgroundLocation = gl.getUniformLocation(program, 'u_background');
            const coverageLocation = gl.getUniformLocation(program, 'u_coverage');
            const lightWrappingLocation = gl.getUniformLocation(program, 'u_lightWrapping');
            const blendModeLocation = gl.getUniformLocation(program, 'u_blendMode');

            gl.useProgram(program);
            gl.uniform2f(backgroundScaleLocation, 1, 1);
            gl.uniform2f(backgroundOffsetLocation, 0, 0);
            gl.uniform1i(inputFrameLocation, 0);
            gl.uniform1i(personMaskLocation, 1);
            gl.uniform1i(backgroundLocation, 2);
            gl.uniform2f(coverageLocation, 0, 1);
            gl.uniform1f(lightWrappingLocation, 0);
            gl.uniform1f(blendModeLocation, 0);

            let backgroundTexture = null;

            function createBackgroundTexture() {
                backgroundTexture = WebGLHelper.createTexture(
                    gl,
                    gl.RGBA8,
                    backgroundVideo.videoWidth,
                    backgroundVideo.videoHeight,
                    gl.LINEAR,
                    gl.LINEAR
                );

                gl.texSubImage2D(
                    gl.TEXTURE_2D,
                    0,
                    0,
                    0,
                    backgroundVideo.videoWidth,
                    backgroundVideo.videoHeight,
                    gl.RGBA,
                    gl.UNSIGNED_BYTE,
                    backgroundVideo
                );

                let xOffset = 0;
                let yOffset = 0;
                let backgroundWidth = backgroundVideo.videoWidth;
                let backgroundHeight = backgroundVideo.videoHeight;
                const backgroundRatio = backgroundWidth / backgroundHeight;
                if (backgroundRatio < outputRatio) {
                    backgroundHeight = backgroundWidth / outputRatio;
                    yOffset = (backgroundVideo.videoHeight - backgroundHeight) / 2;
                } else {
                    backgroundWidth = backgroundHeight * outputRatio;
                    xOffset = (backgroundVideo.videoWidth - backgroundWidth) / 2;
                }

                const xScale = backgroundWidth / backgroundVideo.videoWidth;
                const yScale = backgroundHeight / backgroundVideo.videoHeight;
                xOffset /= backgroundVideo.videoWidth;
                yOffset /= backgroundVideo.videoHeight;

                //console.log(`updateBackgroundVideo xScale:${xScale} yScale:${yScale} xOffest:${xOffset} yOffest:${yOffset}`)

                gl.uniform2f(backgroundScaleLocation, xScale, yScale);
                gl.uniform2f(backgroundOffsetLocation, xOffset, yOffset);
            }

            function updateBackgroundVideo() {
                gl.texSubImage2D(
                    gl.TEXTURE_2D,
                    0,
                    0,
                    0,
                    backgroundVideo.videoWidth,
                    backgroundVideo.videoHeight,
                    gl.RGBA,
                    gl.UNSIGNED_BYTE,
                    backgroundVideo
                );
            }

            let backgroundVideo = document.createElement('video');      
            backgroundVideo.crossOrigin = "anonymous";      
            backgroundVideo.autoplay = true;
            backgroundVideo.playsInline = true;
            backgroundVideo.controls = false;
            backgroundVideo.muted = true;
            backgroundVideo.loop = true;

            backgroundVideo.onloadedmetadata = e => {            
                //console.log(`Loaded Video ${backgroundVideoUrl} videoWidth:${backgroundVideo.videoWidth} videoHeight:${backgroundVideo.videoHeight}`)
                var playPromise = backgroundVideo.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        //console.log("Background Video playing");
                        createBackgroundTexture();
                    }).catch(error => {
                        //console.log("Background Video unable to play, error: " + error);
                    });
                }
            };

            backgroundVideo.src = backgroundVideoUrl;            

            function render() {
                gl.viewport(0, 0, outputWidth, outputHeight);
                gl.useProgram(program);
                gl.activeTexture(gl.TEXTURE1);
                gl.bindTexture(gl.TEXTURE_2D, personMaskTexture);
                if (backgroundTexture !== null) {
                    gl.activeTexture(gl.TEXTURE2);
                    gl.bindTexture(gl.TEXTURE_2D, backgroundTexture);
                    updateBackgroundVideo();
                    gl.uniform1i(this.backgroundLocation, 2);
                    
                }
                gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
            }

            function updateCoverage(coverage) {
                gl.useProgram(program);
                gl.uniform2f(coverageLocation, coverage[0], coverage[1]);
            }

            function updateLightWrapping(lightWrapping) {
                gl.useProgram(program);
                gl.uniform1f(lightWrappingLocation, lightWrapping);
            }

            function updateBlendMode(blendMode) {
                gl.useProgram(program);
                gl.uniform1f(blendModeLocation, blendMode === 'screen' ? 0 : 1);
            }

            function cleanUp() {
                if (backgroundVideo) {
                    backgroundVideo.src = "";
                    backgroundVideo = null;
                }
                gl.deleteTexture(backgroundTexture);
                gl.deleteProgram(program);
                gl.deleteShader(fragmentShader);
                gl.deleteShader(vertexShader);
            }

            return {
                render,
                updateCoverage,
                updateLightWrapping,
                updateBlendMode,
                cleanUp,
            };
        };

        this.backgroundPass = this.buildBackgroundPass(
            gl, positionBuffer, texCoordBuffer, personMaskTexture, backgroundVideoUrl, canvas
        );
    }

    render() {
        this.backgroundPass.render();
    }

    updateCoverage(coverage) {
        this.backgroundPass.updateCoverage(coverage);
    }

    updateLightWrapping(lightWrapping) {
        this.backgroundPass.updateLightWrapping(lightWrapping);
    }

    updateBlendMode(blendMode) {
        this.backgroundPass.updateBlendMode(blendMode);
    }

    cleanUp() {
        this.backgroundPass.cleanUp();
    }
}
