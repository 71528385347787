<p-accordion class="h-100 browsing-history-container" >
  <p-table [value]="browsingHistory" styleClass="p-datatable-gridlines" [paginator]="true" [rows]="5">
    <ng-template pTemplate="header" let-columns>
          <tr>
            <th>{{ "BROWSINGHISTORY_HEADER_PAGE" | Translate : 'Page' }}</th>
            <th>{{ "BROWSINGHISTORY_HEADER_SECTION" | Translate : 'Section' }}</th>
            <th>{{ "BROWSINGHISTORY_HEADER_VIEWEDAT" | Translate : 'Viewed At' }}</th>
          </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entry let-columns="columns">
      <tr>
        <td class="text-break">{{entry.Page}}</td>
        <td class="text-break">{{entry.Section}}</td>
        <td class="text-break">{{entry.TimeStamp | localDateTime}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-accordion>
