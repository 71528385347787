import EventEmitter from "./EventEmitter";
import Peer from "./Peer";
import CandidateFilter from "./CandidateFilter";
import Constants from "./Constants";

export default class PeerManager extends EventEmitter {
    constructor(engagementId, myPeerId, myPeerType, iceServer, connectPeers) {
        super();

        this.engagementId = engagementId;
        this.myPeerId = myPeerId;
        this.myPeerType = myPeerType;
        this.iceServer = iceServer;
        this.connectPeers = connectPeers;

        // an array of peers to which we are going to be connected with
        this.peers = []; 

        this.get = (peerId) => {
            var peer = null;
            for (var i = 0; i < this.peers.length; ++i) {
                if (this.peers[i].id === peerId) {
                    peer = this.peers[i];
                    break;
                }
            }
            return peer;
        };
    
        this.delete = (peerId) => {
            for (var i = 0; i < this.peers.length; ++i) {
                if (this.peers[i].id === peerId) {
                    //console.log('########### Removing Peer Index:' + i); 
                    this.peers.splice(i,1);
                    break;
                }
            }
        };

        this.signalEvent = (e) => {
           this.emit("PeerManager:signal", e);
        };
        
        this.trackEvent = (e) => {
            this.emit("PeerManager:track", e);
        };

        this.stateChangeEvent = (e) => {
            // peerId : this.peerId,
            // direction : this.direction,
            // connectionState : this.rtc.iceConnectionState,
            // signalingState : this.rtc.signalingState
            if (e.direction === Constants.Direction.In) {
                if (e.connectionState === 'failed' || e.connectionState === 'disconnected') {
                    this.connectIncoming(e.peerId);
                }
            }
            this.emit("PeerManager:stateChange", e);
        };

        this.outgoingConnectionReadyEvent = (connection) => {
            this.emit("PeerManager:outgoingConnectionReady", connection);
        };               

        console.log("Created PeerManager for:" + this.myPeerId);
    }

    dispose() {
        console.log("Dispose of the PeerManager");
        this.removeAll();
        this.peers = [];
    }

    add(peerId, type, candidateFilter = CandidateFilter.noFilter) {
        if (this.connectPeers === false) {
            //console.log('connectPeers is false');
            return;
        }

        // check the peer doesnt already exist
        var peer = this.get(peerId);
        if (peer != null) {
            //console.log('Peer Already Exists');
            return;
        }
        
        try {
            // this goes and creates the peer connections
            peer = new Peer(this.myPeerType, peerId, type, candidateFilter);
            peer.on("Peer:outgoingConnectionReady", this.outgoingConnectionReadyEvent);
            
            // make a new connection
            peer.connectIncoming(this.engagementId, this.myPeerId, this.iceServer)
            // add the event listeners
            peer.on("Peer:incomingTrack", this.trackEvent);
            peer.incomingConnection.on("Connection:signal", this.signalEvent);
            peer.incomingConnection.on("Connection:stateChange", this.stateChangeEvent);

            // this is used to look it up later
            this.peers.push(peer);

            //console.log('Added Peer:' + peerId);
        } catch (e) {
            console.log('Failed to Add Peer, exception: ' + e.message);
            return;
        }
    }

    connectIncoming(peerId) {
        if (this.connectPeers === false) {
            //console.log('connectPeers is false');
            return;
        }

        var peer = this.get(peerId);
        if (peer) {
            //console.log('Peer Found in Peers:' + peerId);
            // detach the event listeners
            if (peer.incomingConnection) {
                peer.off("Peer:incomingTrack", this.trackEvent);
                peer.incomingConnection.off("Connection:signal", this.signalEvent);
                peer.incomingConnection.off("Connection:stateChange", this.stateChangeEvent);
                
                //console.log("connectIncoming close stuff signalingState:" + peer.incomingConnection.rtc.signalingState);
                // close the connection
                clearInterval(peer.incomingConnection.incomingConnectionStateCheckInterval);
                if (peer.incomingConnection.rtc.signalingState !== "closed") {
                    peer.incomingConnection.rtc.close();
                    peer.incomingConnection.rtc = null;
                }
                peer.incomingConnection = null;
            }
            // make a new connection
            peer.connectIncoming(this.engagementId, this.myPeerId, this.iceServer)
            // add the event listeners
            peer.on("Peer:incomingTrack", this.trackEvent);
            peer.incomingConnection.on("Connection:signal", this.signalEvent);
            peer.incomingConnection.on("Connection:stateChange", this.stateChangeEvent);
        }
        else {
            console.log('Peer NOT Found in Peers:' + peerId);
        }
    }

    connectOutgoing(peerId)  {
        if (this.connectPeers === false) {
            //console.log('connectPeers is false');
            return;
        }
        
        var peer = this.get(peerId);
        if (peer) {
            //console.log('Peer Found in Peers:' + peerId);
            // detach the event listeners
            if (peer.outgoingConnection) {
                peer.outgoingConnection.off("Connection:signal", this.signalEvent);
                peer.outgoingConnection.off("Connection:stateChange", this.stateChangeEvent);
            
                // close the connection
                clearInterval(peer.outgoingConnection.incomingConnectionStateCheckInterval);
                if (peer.outgoingConnection.rtc.signalingState !== "closed") {
                    peer.outgoingConnection.rtc.close();
                    peer.outgoingConnection.rtc = null;
                }
                peer.outgoingConnection = null;

            }
            // make a new connection
            peer.connectOutgoing(this.engagementId, this.myPeerId, this.iceServer);
            // add the event listeners
            peer.outgoingConnection.on("Connection:signal", this.signalEvent);
            peer.outgoingConnection.on("Connection:stateChange", this.stateChangeEvent);
        }
        else {
            console.log('Peer NOT Found in Peers:' + peerId);
        }   
    }

    remove(peerId) {
        if (this.connectPeers === false) {
            //console.log('connectPeers is false');
            return;
        }

        //console.log('############ removePeer:' + peerId);
        var peer = this.get(peerId);
        if (peer) {
            //console.log('Peer Found in Peers:' + peerId);
            
            //console.log('Detach Event Listeners');
            peer.off("Peer:outgoingConnectionReady", this.outgoingConnectionReadyEvent);
            peer.off("Peer:incomingTrack", this.trackEvent);

            if (peer.incomingConnection) {                
                peer.incomingConnection.off("Connection:signal", this.signalEvent);
                peer.incomingConnection.off("Connection:stateChange", this.stateChangeEvent);

                clearInterval(peer.incomingConnection.incomingConnectionStateCheckInterval);
                if (peer.incomingConnection.rtc.signalingState !== "closed") {
                    //console.log('Close the Incoming Connection');
                    peer.incomingConnection.rtc.close();
                    peer.incomingConnection.rtc = null;
                }
                peer.incomingConnection = null;
            }
            if (peer.outgoingConnection) {
                peer.outgoingConnection.off("Connection:signal", this.signalEvent);
                peer.outgoingConnection.off("Connection:stateChange", this.stateChangeEvent);

                clearInterval(peer.outgoingConnection.incomingConnectionStateCheckInterval);
                if (peer.outgoingConnection.rtc.signalingState !== "closed") {
                    //console.log('Close the Outgoing Connection');
                    peer.outgoingConnection.rtc.close();
                    peer.outgoingConnection.rtc = null;
                }
                peer.outgoingConnection = null;
            }
         
            this.delete(peerId);
            //console.log('########### Removed Peer:' + peerId);            
        }
        else {
            console.log('Peer NOT Found in Peers:' + peerId);
        }        
    }    

    removeAll() {
        for (let i = this.peers.length - 1; i >= 0; i--) {
            this.remove(this.peers[i].id);
        }        
    }

    processMessage(peerId, message) {
        if (this.connectPeers === false) {
            console.warn("connectPeers is false");
            return;
        }
        
        if (message.signalType && message.signalType === Constants.SignalType.AV) {
            var peer = this.get(peerId);
            if (peer) {
                if (message.type === 'state') { // then we should handle here
                    if (message.direction === Constants.Direction.In) {
                        if (peer.theirIncomingConnectionState !== message.connectionState) {
                            peer.theirIncomingConnectionState = message.connectionState;
                            //console.log('######### Their State has changed:' + message.connectionState);
                            if (message.connectionState === 'new') { // they are ready and we should create our outbound
                                this.connectOutgoing(peerId);
                            }
                        }
                    }
                }
                else if (message.type === 'avEvent') {
                    //console.log("####### avEvent ########");
                    if (message.direction === Constants.Direction.Out) {
                        this.emit("PeerManager:av", message);
                    }
                }
                else { // otherwise pass it through to the appropriate connection
                    peer.processMessage(message);
                }            
            }
            else {
                console.log("Peer not found to process message");
            }
        }
    }

    addTrack(track, stream, peerType) {
        for (var i = 0; i < this.peers.length; ++i) {
            if ((typeof peerType === 'undefined' || this.peers[i].type === peerType) // only add the track if the peer matches the type we have specified.  If we dont pass anything in this should add to all.
                && this.peers[i].outgoingConnection) { // the peer has to have an outgoingConnection
                this.peers[i].outgoingConnection.addTrack(track, stream);
            }
        }
    }

    removeTrack(track, peerType) {
        for (var i = 0; i < this.peers.length; ++i) {            
            if ((typeof peerType === 'undefined' || this.peers[i].type === peerType) // only remove the track if the peer matches the type we have specified.  If we dont pass anything in this should remove all.
                && this.peers[i].outgoingConnection) { // the peers has to have an outgoingConnection
                this.peers[i].outgoingConnection.removeTrack(track);            
            }            
        }
    }

    negotiate() {
        for (var i = 0; i < this.peers.length; ++i) {
            if (this.peers[i].outgoingConnection) {
                this.peers[i].outgoingConnection.negotiate();
            }
        }
    }
}
