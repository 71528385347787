import PeerManager from "./PeerManager";
import CandidateFilter from "./CandidateFilter";
import Constants from "./Constants";
import CallStreaming from "./CallStreaming";

export default class CallP2P extends CallStreaming {
    constructor({ 
            engagementId = null,
            peerId ='itsthetaste',
            iceServer = null,
            connectPeers = false,
            connectTracks = false,
            peerType = Constants.PeerType.Agent,
            mainPeerId = 'itsthetaste',
            speakerVolume = 1.0,
            mainVideoContainer = null,
            connectingOverlay = null,
            reConnectingOverlay = null,
            onHoldOverlay = null,
            peersContainer = null
        } = {}) {        

        super({ 
            engagementId,
            peerId,
            iceServer,           
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        this.connectTracks = connectTracks;

        this.audioTracksOnly    = false;        

        this.outgoingConnectionReadyEvent = connection => {
            // add all the current tracks to this connection
            if (this.connectTracks && this.localStream != null) {
                var localTracks = this.localStream.getTracks();
                //console.log("Local Tracks:" + localTracks.length);
                if (localTracks.length > 0) {
                    // remove any previous attached tracks
                    for (var i = 0; i < localTracks.length; ++i) {
                        connection.removeTrack(localTracks[i]);
                    }
                    // then readd them
                    for (var i = 0; i < localTracks.length; ++i) {
                        if(!this.audioTracksOnly || (this.audioTracksOnly && localTracks[i].kind === "audio")){
                            connection.addTrack(localTracks[i], this.localStream);
                        } 
                    }
                    connection.negotiate(); 
                }
            }
        };

        this.peerTrackEvent = message => {
            //console.log("trackHandler add peerId:" + message.peerId + " track:" + message.track);
            this.ui.addPeerTracks({
                peerId: message.peerId,
                track: message.track,
                speakerId: this.devices.selectedSpeaker ? this.devices.selectedSpeaker.deviceId : null,
                peerType: message.peerType
            });
        };

        this.peerStateChangeEvent = message => {
            //console.log("stateChangeHandler:" + JSON.stringify(message));
            if (message.connectionState === "failed" || message.connectionState === "disconnected" || message.connectionState === "closed") {
                if (message.direction === Constants.Direction.In) {
                    this.ui.failed(message.peerId);
                }
            }
            else if (message.connectionState === "new" || message.connectionState === "checking") {
                if (message.direction === Constants.Direction.In) {
                    this.ui.connecting(message.peerId);
                }
            }
            else if (message.connectionState === "completed" || message.connectionState === "connected") {
                if (message.direction === Constants.Direction.In) {
                    this.ui.connected(message.peerId);
                }
            }

        };

        this.peerAVEvent = message => {
            //console.log("avEventHandler:" + JSON.stringify(message));
            this.ui.handleAVEvent(message);
        };

        // TODO. look at removing this as its also in the Call
        this.handleUserMediaError = e => {
            console.log("getUserMedia error: " + e.name + " message: " + e.message);
            this.emit("Call:handleGetUserMediaError", e);
        };

        this.updateMediaState = () => {
            var state = {};
            state.micOn = this.localAudioTrack != null && this.localAudioTrack.enabled;
            state.cameraOn = this.localVideoTrack != null && this.localVideoTrack.enabled;
            this.emit("Call:updateMediaState", state);
        };

        this.peers = new PeerManager(engagementId, peerId, peerType, iceServer, connectPeers);
        this.peers.on('PeerManager:outgoingConnectionReady', this.outgoingConnectionReadyEvent);
        this.peers.on('PeerManager:track', this.peerTrackEvent); 
        this.peers.on('PeerManager:stateChange', this.peerStateChangeEvent);
        this.peers.on('PeerManager:av', this.peerAVEvent);

        console.log("Created CallP2P for:" + peerId + " of Type:" + peerType);
    }      
   

    dispose() {
        console.log("Dispose of the CallP2P");
        // this is a one time connection killer
        // probably wont be able to reuse this callManager afterwards because of the negotiation conflict       

        this.peers.off('PeerManager:outgoingConnectionReady', this.outgoingConnectionReadyEvent);
        this.peers.off('PeerManager:track', this.peerTrackEvent); 
        this.peers.off('PeerManager:stateChange', this.peerStateChangeEvent);
        this.peers.off('PeerManager:av', this.peerAVEvent);
        this.peers.dispose();
        this.stopAudio(false);
        this.stopVideo(false);
        this.peers = null;

        super.dispose();    
    }

    addPeer(peerId, peerType, candidateFilter = CandidateFilter.noFilter) {
        //console.log('*************** addPeer', candidateFilter);
        super.addPeer(peerId, peerType, candidateFilter);

        this.peers.add(peerId, peerType, candidateFilter);
    }

    removePeer(peerId) {
        this.peers.remove(peerId);
        
        super.removePeer(peerId);
    }

    startAudioVideo(options) {
        console.log("startAudioVideo() connectTracks:" + this.connectTracks);
        
        if (!this.devices) return;
        
        this.devices.getUserMedia({ video : this.devices.getVideoSettings(options), audio : this.devices.getAudioSettings() })
        .then( stream => {

            if (this.localStream && this.localStream.getTracks().length > 0) {
                this.localStream.getTracks().forEach(track => {
                    this.peers.removeTrack(track);
                    this.localStream.removeTrack(track);
                    track.stop();
                });
            }
            
            if (!stream) return;

            this.localVideoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
            this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;

            if (this.localVideoTrack != null) {
                //console.log(this.localVideoTrack.label);
                this.devices.selectCameraByName(this.localVideoTrack.label);
                this.localStream.addTrack(this.localVideoTrack);
                if (this.connectTracks && !this.audioTracksOnly) {
                    this.peers.addTrack(this.localVideoTrack,this.localStream);
                }
            }
            if (this.localAudioTrack != null) {
                //console.log(this.localAudioTrack.label);
                this.devices.selectMicrophoneByName(this.localAudioTrack.label);
                this.localStream.addTrack(this.localAudioTrack);
                if (this.connectTracks) {
                    this.peers.addTrack(this.localAudioTrack,this.localStream);
                }
                this.devices.audioMeter.attach(this.localAudioTrack);
            }
            if (this.connectTracks) {
                this.peers.negotiate();
            }
                                        
            this.ui.addLocalTrack(this.localStream);
            this.devices.devicesAllowed();
            this.updateMediaState();
        })
        .catch(this.handleUserMediaError);
    }

    stopVideo(negotiate) {
        console.log("stopVideo() negotiate:" + negotiate);
        if (this.localVideoTrack != null) {
            this.localVideoTrack.stop();
            this.ui.removeLocalVideo();
            this.localStream.removeTrack(this.localVideoTrack);
            this.peers.removeTrack(this.localVideoTrack);
            this.localVideoTrack = null;                   
        }
        if (negotiate) {
            this.peers.negotiate();
        }
        this.updateMediaState();
    }

    stopAudio(negotiate) {
        console.log("stopAudio() negotiate:" + negotiate);
        if (this.localAudioTrack != null) {
            this.localAudioTrack.stop();
            this.localStream.removeTrack(this.localAudioTrack);
            this.peers.removeTrack(this.localAudioTrack);
            this.devices.audioMeter.detach();
            this.localAudioTrack = null;
        }
        if (negotiate) {
            this.peers.negotiate();
        }
        this.updateMediaState();
    }

    stopAudioVideo() {
        console.log("stopAudioVideo()");
        // this is annoying that i have to do this
        // but I need to force the removal of the video srcObjects
        // as in firefox and safari the track.onended doesnt seem reliable

        this.ui.removeAllStreams();

        // stop my outgoing streams
        this.stopAudio(false);
        this.stopVideo(false);
        
        this.peers.negotiate();

        this.updateMediaState();
    }

    selectCamera(deviceId) {
        if (!this.devices) return;

        // check the settings are not the same as the previous settings
        var newCameraDevice = this.devices.getCameraByDeviceId(deviceId);
        if (newCameraDevice != null) {
            if (this.devices.selectedCamera && newCameraDevice.deviceId === this.devices.selectedCamera.deviceId) {
                console.log("Camera:" + newCameraDevice.label + " is the same as what we have set so leave alone");
                return;
            }
        }

        this.localStream.getVideoTracks().forEach(track => {
            this.localStream.removeTrack(track);
            track.stop();
        });

        let negotiate = false;

        if (this.localVideoTrack != null) {
            this.ui.removeLocalVideo();
            this.localVideoTrack.stop();
            this.localVideoTrack = null;
        }
        else {
            negotiate = true;
        }

        this.devices.selectCamera(deviceId);

        this.devices.getUserMedia({ video : this.devices.getVideoSettings() })
        .then(stream => {   
            
            this.devices.devicesAllowed()
            .then(() => {
                this.localStream.getVideoTracks().forEach(track => {
                    this.localStream.removeTrack(track);
                    track.stop();
                });
    
                this.localVideoTrack = stream && stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                if (this.localVideoTrack != null) {
                    this.localStream.addTrack(this.localVideoTrack);
                    if (this.connectTracks && !this.audioTracksOnly) {
                        this.peers.addTrack(this.localVideoTrack,this.localStream);   
                        if (negotiate) {
                            this.peers.negotiate();
                        }
                    }                    
                }                
                this.ui.addLocalTrack(this.localStream);    
                this.updateMediaState();
            });            
        })
        .catch(this.handleUserMediaError); 
    }

    selectMicrophone(deviceId) {
        // check the settings are not the same as the previous settings
        var newMicrophoneDevice = this.devices.getMicrophoneByDeviceId(deviceId);
        if (newMicrophoneDevice != null) {
            if (this.devices.selectedMicrophone && newMicrophoneDevice.deviceId === this.devices.selectedMicrophone.deviceId) {
                console.log("Microphone:" + newMicrophoneDevice.label + " is the same as what we have set so leave alone");
                return;
            }
        }

        this.devices.audioMeter.detach();

        let negotiate = false;

        if (this.localAudioTrack != null) {
            this.localAudioTrack.stop();
            this.localStream.removeTrack(this.localAudioTrack);
            this.localAudioTrack = null;
        }
        else {
            negotiate = true;
        }

        this.devices.selectMicrophone(deviceId);

        this.devices.getUserMedia({ audio : this.devices.getAudioSettings() })
        .then(stream => {            

            this.devices.devicesAllowed()
            .then(() => {

                this.localStream.getAudioTracks().forEach(track => {
                    this.localStream.removeTrack(track);
                    track.stop();
                });

                this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
                if (this.localAudioTrack != null) {
                    this.localStream.addTrack(this.localAudioTrack);
                    if (this.connectTracks) {
                        this.peers.addTrack(this.localAudioTrack,this.localStream);
                        if (negotiate) {
                            this.peers.negotiate();
                        } 
                    }
                    this.devices.audioMeter.attach(this.localAudioTrack);
                }

                this.updateMediaState();
            });
        })
        .catch(this.handleUserMediaError);
    }

    selectSpeaker(deviceId) {
        this.devices.selectSpeaker(deviceId);
        if (this.devices.selectedSpeaker != null) {
            this.ui.setAudioOutput(this.devices.selectedSpeaker.deviceId);
        }
        else {
            console.log("cannot selectSpeaker: " + deviceId);
        }
    }

}