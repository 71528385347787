import {Component, Input} from '@angular/core';
import {TextMessage, TextMessageTypes} from '../../../classes/TextMessage';
import {ClipboardService} from '../../../services/clipboard.service';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {AlertService, AlertType} from "../../../services/alert-service/alert.service";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-chat-transcript',
  templateUrl: './chat-transcript.component.html',
  styleUrls: ['./chat-transcript.component.scss'],
  providers:[TranslatePipe]
})
export class ChatTranscriptComponent {
  @Input() messages: TextMessage[] = [];

  initialTitleContent = this.translate.transform("CHAT_TRANSCRIPT_TOOLTIP_COPY", 'Copy');
  titleContent = this.initialTitleContent;

  constructor(
    public clipboard: ClipboardService,
    private translate: TranslatePipe,
    private alertService: AlertService
  ) { }

  public copyToClipboard() {
    const text = this.createTranscript();
    this.clipboard.copy(text);
    this.alertService.addAlert(this.translate.transform("CHAT_TRANSCRIPT_TOOLTIP_COPIED", 'Copied!'), AlertType.Info);
  }

  public createTranscript(): string {
    let transcript = this.translate.transform("CALLLIST_LABEL_CHATTRANSCRIPT",`Chat transcript\r\n---------------------------------\r\n`);

    for (const message of this.messages) {
      transcript += this.formatMessageForTranscript(message);
      transcript += '\r\n';
    }

    return transcript;
  }

  private formatMessageForTranscript(message: TextMessage): string {
    return `${message.timestamp.toLocaleTimeString()} - ${this.getSenderName(message)} : ${message.message}`;
  }

  private getSenderName(message: TextMessage): string {
    if (message.senderType === TextMessageTypes.OTHER_AGENT) {
      return message.senderName;
    } else if (message.senderName === '') {
      return 'Customer';
    } else {
      return message.senderName;
    }
  }

  protected readonly faCopy = faCopy;
}
