

  <!-- code to be show on desktop start-->
  <div class="d-flex w-100 h-100" [class.paused]="(callPaused | async) || (engagement?.backgrounded | async)">

    <app-sharing-notification *ngIf="(isSharing | async)" [isPrimaryAgent]="(isPrimaryAgent | async)" (stopSharing)="onSwitchSharingMode(false)"></app-sharing-notification>

    <div class="d-flex flex-column mr-2">
      <ng-container *ngTemplateOutlet="controlBar"></ng-container>
    </div>

    <div class="d-flex flex-row flex-grow-1 engagementMainContainer gap-2"
         [class.post-engagement]="(currentState$ | async)?.type === EngagementState.Post">
      <!-- this will switch between the engagement-controls/video and the post engagement -->

      <app-engagement-center-console class="h-100 flex-grow-1 main-layout"
                                     [engagement]="engagement"
                                     [currentState]="(currentState$ | async)"
                                     [currentUrl]="(urlObs | async)"
                                     [isPresentingAgent]="isPresentingAgent | async"
                                     [visitorCrmData]="visitorCrmData$ | async"
                                     [isPrimaryAgent]="isPrimaryAgent | async"
                                     [emailState]="emailState | async"
                                     [visitor]="visitor$ | async"
                                     [checklistfields]="checklistfields | async"
                                     [visitorSessionHistory]="visitorSessionHistory$ | async"
                                     [visitorBrowsingHistory]="visitorBrowsingHistory$ | async"
                                     [currentSection]="currentSection"
                                     [engagementId]="engagement?.engagementId.toString()"
                                     [userGuid]="engagement?.visitor?.userGuid"
                                     [privateChatAvailable]="privateChatAvailable"
                                     [privateChatEnabled]="(privateChatEnabled | async)"
                                     [unreadPrivateMessages]="(unreadPrivateMessages | async)"
                                     [privateChatMessages]="(privateChatMessages | async)"
                                     [isElectron]="isElectron"
                                     [isSharing]="(isSharing | async)"
                                     [callPaused]="(callPaused | async)"
                                     [showBrowserBar]="true"
                                     [showBrowserBarControls]="useCoBrowsing"
                                     [username]="username"
                                     [fromCustomerCommand]="(domSyncCommand$)"
                                     [domSyncOn]="(domSyncOn$ | async)"
                                     [domSyncActive]="isDomSync$ | async"
                                     [currentPanelPosition]="currentPanelPosition"
                                     [showingModal]="(showingModal$ | async)"
                                     (saveOpportunity)="saveOpportunity($event)"
                                     (saveCrmData)="saveCrmData()"
                                     (loadCustomer)="loadCustomer($event)"
                                     (sendPrivateMessage)="sendPrivateChatMessage($event)"
                                     (domSyncReady)="onDomSyncAgentReady()"
                                     (domSyncCommandMessage)="onDomSyncToCustomerCommand($event)"
                                     [displayBrowseToggle]="engagement?.visitor?.isMobileSdk"
                                     [cobrowseAdded]="isBrowserVisible"
                                     (toggleCobrowseBrowser)="toggleCobrowseBrowser()"
                                     [inBackground]="isInBackground"
                                     [useOverlayPanel]="!engagement?.visitor?.isMobileSdk"
                                     [appViewSize]="screenSize"
                                     [hideBrowserWhenSharing]="hideBrowserWhenSharing"
                                     (crmSelected)="switchedToCrm()"
                                     [useSharing]="(isSharing | async)"
                                     [useSecureSharing]="(domSyncOn$ | async) && !(isSharing | async)"
                                     [useCoBrowse]="(allowBrowsing | async) && !((domSyncOn$ | async) || (isSharing | async))"
                                     [canCoBrowse]="useCoBrowsing"
                                     [canShare]="canShare"
                                     [canCoFormFillShare]="canCoFormFillShare"
                                     [canSecureShare]="canSecureShare"
                                     [appViewOn]="(isAppView | async)"
                                     [canAppView]="canAppView"
                                     [secureSharePreventUserInteraction] = "engagement?.secureSharePreventUserInteraction | async"
                                     (toggleSecureShareUserInteraction) = onToggleSecureShareUserInteraction($event)
                                     (switchAppView)="onSwitchAppView($event)"
                                     (switchSharing)="onSwitchSharingMode($event)"
                                     (switchSecureSharing)="onSwitchDomSyncingOn($event)"
                                     (switchCobrowse)="onSwitchCoBrowseOn($event)">

      </app-engagement-center-console>

      <ng-container *ngIf="(currentState$ | async)?.type === EngagementState.Post; then post else engaged">
      </ng-container>
      <ng-template #engaged>
        <div [ngSwitch]="(currentState$ | async)?.type" class="sidebar-panel">
          <div class="d-flex flex-column h-100 w-100" *ngSwitchCase="EngagementState.Join">
            <app-engagement-join class="w-100 engagement-controls"
                                 [engagement]="engagement"
                                 [presentingAgent]="(presentingAgent$ | async)"
                                 [primaryAgent]="(primaryAgent$ | async)"
                                 [currentState]="(currentState$ | async)"
                                 (invitationRequest)="joinRequest($event)"
                                 (customerInvitationRequest)="customerJoinRequest($event)"
                                 (cancelJoinInvite)="cancelJoin()"
                                 (makePresenter)="changePresenter($event)"
                                 (kickAgent)="kickAgent($event)"
            >
            </app-engagement-join>
          </div>

          <div class="d-flex flex-column h-100 w-100" *ngSwitchCase="EngagementState.Transfer">
            <app-engagement-transfer class="h-100 w-100 engagement-controls flex-grow-1"
                                     [currentState]="(currentState$ | async)"
                                     [textMessages]="engagement?.transferChatMessages"
                                     [roomId]="engagementId"
                                     (inviteRequest)="transferRequest($event)"
                                     (cancelInvite)="cancelTransfer()">
            </app-engagement-transfer>
          </div>

          <div *ngSwitchCase="EngagementState.Idle">
          </div>

          <div class="d-flex flex-column h-100 w-100" *ngSwitchDefault >
            <ng-container *ngTemplateOutlet="textChat"></ng-container>
          </div>
        </div>
      </ng-template>
    </div>

      <ng-container *ngTemplateOutlet="bottomControls"></ng-container>
  </div>


<ng-template #controlBar>
  <app-engagement-control-bar class="w-100 flex-grow-1 sidebar-panel overflow-hidden p-0"
                              [engagement]="engagement"
                              [isMobile]="isMobile"
                              [isMobileOrTablet]="(isMobileOrTablet$ | async)"
                              [useCoBrowse]="(allowBrowsing | async) && !((domSyncOn$ | async) || (isSharing | async))"
                              [useCoView]="(domSyncOn$ | async) && !(isSharing | async)"
                              [useSharing]="(isSharing | async)"
                              [useAppView]="(isAppView | async)"
                              [canShare]="canShare"
                              [canSecureShare]="canSecureShare"
                              [canAppView]="canAppView"
                              [canCoFormFillShare]="canCoFormFillShare"
                              (switchCoBrowse)="onSwitchCoBrowseOn($event)"
                              (switchCoView)="onSwitchDomSyncingOn($event)"
                              (switchSharing)="onSwitchSharingMode($event)"
                              (switchAppView)="onSwitchAppView($event)"
                              (switchCoFormFillSharing)="onSwitchSharingMode($event, false)"
                              (selectedAsset)="selectAsset($event)"
                              (changeCameraDirection)="engagement.changeCameraDirection($event)"
  >
  </app-engagement-control-bar>
</ng-template>

<ng-template #textChat>
  <app-engagement-chat-tabset
      class="flex-grow-1 h-100 w-100"
      [engagement]="engagement"
      [messages]="engagement?.messages"
      [previewMessage]="(visitorMessageObs | async)"
      [files]="(this.fileTransfers$ | async)"
      (uploadFiles)="uploadFiles($event)"
      (cancelFilesTransfer)="cancelFileTransfer($event)"
      [translationOn]="(translationOn$ | async)"
      (setTranslation)="setTranslation($event)"
      [getSuggestionOn]="(getSuggestionOn$ | async)"
      (setGetSuggestion)="setGetSuggestion($event)"
      [autoReplyOn]="(autoReplyOn$ | async)"
      (setAutoReply)="setAutoReply($event)"
      [agentText]="engagement.agentText"
      (agentTextChange)="agentTextChange($event)"
      [privateChatMessages]="(privateChatMessages | async)"
      (sendPrivateMessage)="sendPrivateChatMessage($event)"
      [unreadPrivateMessages]="(unreadPrivateMessages | async)"
      [privateChatAvailable]="privateChatAvailable"
      [privateChatEnabled]="(privateChatEnabled | async)"
      [isClosed]='(currentState$ | async).type === EngagementState.Post'
      [unreadMessages]="(unreadMessages | async)"
      (sendMessage)="onSendMessage($event)"
      [sendButtonDisabled]="!(engagement?.isConnected | async)"
      (checkListName)="getCheckListFields($event)"
      (savePost)="savePost($event)">
  </app-engagement-chat-tabset>
</ng-template>

<ng-template #bottomControls>
  <app-engagement-bottom-controls class="engagement-controls"
                                  [verifyStatus]="(engagement.primaryVisitor$ | async)?.verifyStatus"
                                  [currentState]="(currentState$ | async).type"
                                  [isPrimaryAgent]="(isPrimaryAgent | async)"
                                  [roomAgentsSize]="agents?.size"
                                  [roomVisitorsSize]="visitors?.size"
                                  [callPaused]="(callPaused | async)"
                                  [canPause]="engagement?.visitor?.callType !== CallType.TextDowngrade"
                                  [isRecording]="(isRecording | async)"
                                  [requestingHelp]="(requestingHelp$ | async)"
                                  [privateChatAvailable]="privateChatAvailable"
                                  [isMobileSDK]="engagement?.visitor?.isMobileSdk"
                                  (toggleHelpRequest)="toggleHelpRequest()"
                                  (pauseCall)="onPauseCall()"
                                  (toggleTransferCall)="selectTransferringOperator()"
                                  (toggleJoinCall)="selectJoiningOperator()"
                                  (toggleBlockCustomer)="showBlockCustomerModal()"
                                  (endChat)="showEndChatModal()"
                                  (toggleRecording)="onRecordingToggle()"
                                  [isBlockedOn]="(isBlockedOn | async)"
                                  (toggleVerifyCustomer)="showVerifyCustomerModal()"
  >
  </app-engagement-bottom-controls>

</ng-template>

<ng-template #post>
  <app-engagement-post
    class="h-100 sidebar-panel "
    [engagement]="engagement"
    (submitPost)="postFinished($event)"
    (checkListName)="getCheckListFields($event)"
  >
  </app-engagement-post>
</ng-template>
