import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {CrmField} from '../../../../services/crm-service/crm-field';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorBase} from '../../control-value-accessor.base';

@Component({
  selector: 'app-crm-field-checkbox',
  templateUrl: './crm-field-checkbox.component.html',
  styleUrl: './crm-field-checkbox.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CrmFieldCheckboxComponent),
    multi: true
  }]
})
export class CrmFieldCheckboxComponent extends ControlValueAccessorBase<boolean> implements OnInit {
  private _field: CrmField;

  @Input()
  set field(field: CrmField) {
    this._field = field;
    if (field) {
      this.writeValue(field.value === 'true');
    }
  }


  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<void> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public onFieldValueChange({checked, event}): void {
    const value = checked;
    this.onInputChange(value);
    if (this._field) {
      this._field.value = value;
      this.saveField.emit();
    }
  }
}
