import CallP2PVisitor from "../CallP2PVisitor";
import Constants from "../Constants";

export default class EdgeCallP2PVisitor extends CallP2PVisitor {
    constructor({ 
        engagementId = null,
        peerId ='itsthetaste',
        iceServer = null,
        connectPeers = false,
        connectTracks = false,
        peerType = Constants.PeerType.Agent,
        mainPeerId = 'itsthetaste',
        speakerVolume = 1.0,
        mainVideoContainer = null,
        connectingOverlay = null,
        reConnectingOverlay = null,
        onHoldOverlay = null,
        peersContainer = null
    } = {}) {        

        super({ 
            engagementId,
            peerId,
            iceServer,
            connectPeers,
            connectTracks,
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        console.log("Created EdgeCallP2PVisitor for:" + peerId + " of Type:" + peerType);
    }      

    startVideo(options) {
        console.log("startVideo()");
        if (this.localVideoTrack) {
            if (this.localVideoTrack.enabled === false) {
                console.log("Enabling Video");
                this.localVideoTrack.enabled = true;
                this.localStream.addTrack(this.localVideoTrack);
                this.ui.addLocalTrack(this.localStream);
                if (this.connectTracks) {
                    this.peers.addTrack(this.localVideoTrack,this.localStream);   
                }
            }
        }
        else {
            if (!this.devices) return;
            this.devices.getUserMedia({ video : this.devices.getVideoSettings(options) })
            .then(stream => {                    

                this.devices.devicesAllowed()
                .then(() => {

                    this.localStream.getVideoTracks().forEach(track => {
                        this.peers.removeTrack(track);
                        this.localStream.removeTrack(track);
                        track.stop();
                    });

                    this.localVideoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                    if (this.localVideoTrack != null) {
                        console.log("Adding Video");
                        this.devices.selectCameraByName(this.localVideoTrack.label);
                        this.localStream.addTrack(this.localVideoTrack);
                        if (this.connectTracks) {
                            this.peers.addTrack(this.localVideoTrack,this.localStream);   
                        }
                        this.peers.negotiate();
                    }
                    
                    this.ui.addLocalTrack(this.localStream);
                    this.devices.devicesAllowed();
                    this.updateMediaState();
                });
            })
            .catch(this.handleUserMediaError);

        }
    }

    stopVideo(negotiate) {
        console.log("stopVideo() negotiate:" + negotiate);
        if (this.localVideoTrack != null) {
            console.log("Disabling Video");
            this.localVideoTrack.enabled = false;
            this.ui.removeLocalVideo();
            this.localStream.removeTrack(this.localVideoTrack);
            this.peers.removeTrack(this.localVideoTrack);
        }
        this.updateMediaState();
    }

    startAudio() {
        console.log("startAudio()");
        if (this.localAudioTrack) {
            if (this.localAudioTrack.enabled === false) {
                console.log("Enabling Audio");
                this.localAudioTrack.enabled = true;
                this.localStream.addTrack(this.localAudioTrack);
                this.devices.audioMeter.attach(this.localAudioTrack);
                if (this.connectTracks) {
                    this.peers.addTrack(this.localAudioTrack,this.localStream); 
                }
            }
        }
        else {
         
            this.devices.getUserMedia({ audio : this.devices.getAudioSettings() })
            .then(stream => {                    

                this.devices.devicesAllowed()
                .then(() => {

                    this.localStream.getAudioTracks().forEach(track => {
                        this.peers.removeTrack(track);
                        this.localStream.removeTrack(track);
                        track.stop();
                    });

                    this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
                    if (this.localAudioTrack != null) {
                        console.log("Adding Audio");
                        this.devices.selectMicrophoneByName(this.localAudioTrack.label);
                        this.localStream.addTrack(this.localAudioTrack);
                        this.devices.audioMeter.attach(this.localAudioTrack);
                        if (this.connectTracks) {
                            this.peers.addTrack(this.localAudioTrack,this.localStream); 
                        }
                        this.peers.negotiate();
                    }

                    this.devices.devicesAllowed();
                    this.updateMediaState();
                });
            })
            .catch(this.handleUserMediaError);
        }
    }
    
    stopAudio(negotiate) {
        console.log("stopAudio() negotiate:" + negotiate);
        if (this.localAudioTrack != null) {
            console.log("Disabling Audio");
            this.localAudioTrack.enabled = false;

            this.localStream.removeTrack(this.localAudioTrack);
            this.peers.removeTrack(this.localAudioTrack);
            this.devices.audioMeter.detach();
        }
        this.updateMediaState();
    }

    startAudioVideo(options) {
        console.log("startAudioVideo() connectTracks:" + this.connectTracks);
        if (!this.devices) return false;
        
        var needsAudio = false;
        var needsVideo = false;

        if (this.localAudioTrack) {
            if (this.localAudioTrack.enabled === false) {
                console.log("Enabling Audio");
                this.localAudioTrack.enabled = true;
                this.localStream.addTrack(this.localAudioTrack);
                this.devices.audioMeter.attach(this.localAudioTrack);
                if (this.connectTracks) {
                    this.peers.addTrack(this.localAudioTrack,this.localStream); 
                }
            }
        }
        else {
            needsAudio = true;
        }
        
        if (this.localVideoTrack) {
            if (this.localVideoTrack.enabled === false) {
                console.log("Enabling Video");
                this.localVideoTrack.enabled = true;
                this.localStream.addTrack(this.localVideoTrack);
                this.ui.addLocalTrack(this.localStream);
                if (this.connectTracks) {
                    this.peers.addTrack(this.localVideoTrack,this.localStream);   
                }
            }
        }
        else {
            needsVideo = true;
        }

        var constraints = {};
        if (needsVideo) {
            constraints.video = this.devices.getVideoSettings(options);
        }
        if (needsAudio) {
            constraints.audio = this.devices.getAudioSettings();
        }

        // check if we have set any constraints
        if (!!Object.keys(constraints).length) {

            // because this is a promise, lots of calls to this could happen at the same time
            // would be nice to have a way to cancel it
            this.devices.getUserMedia(constraints)
            .then( stream => {       
                
                this.devices.devicesAllowed()
                .then(() => {

                    if (needsVideo) {
                        this.localStream.getVideoTracks().forEach(track => {
                            this.peers.removeTrack(track);
                            this.localStream.removeTrack(track);
                            track.stop();
                        });
                        this.localVideoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                        if (this.localVideoTrack != null) {
                            console.log("Adding Video");
                            this.devices.selectCameraByName(this.localVideoTrack.label);
                            this.localStream.addTrack(this.localVideoTrack);
                            if (this.connectTracks) {
                                this.peers.addTrack(this.localVideoTrack,this.localStream);
                            }
                        }
                    }
                    
                    if (needsAudio) {
                        this.localStream.getAudioTracks().forEach(track => {
                            this.peers.removeTrack(track);
                            this.localStream.removeTrack(track);
                            track.stop();
                        });
                        this.localAudioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;
                        if (this.localAudioTrack != null) {
                            console.log("Adding Audio");
                            this.devices.selectMicrophoneByName(this.localAudioTrack.label);
                            this.localStream.addTrack(this.localAudioTrack);
                            this.devices.audioMeter.attach(this.localAudioTrack);
                            if (this.connectTracks) {
                                this.peers.addTrack(this.localAudioTrack,this.localStream);
                            }
                        }
                    }
                    if (this.connectTracks) {
                        this.peers.negotiate();                
                    }
                                            
                    this.ui.addLocalTrack(this.localStream);
                    this.devices.devicesAllowed();
                    this.updateMediaState();
                });
            })
            .catch(this.handleUserMediaError);

        }
        else {
            console.log("Audio and Video is already started");
        }
    }

    stopAudioVideo() {
        console.log("stopAudioVideo()");
        // stop my outgoing streams
        this.stopAudio(false);
        this.stopVideo(false);
    }

}