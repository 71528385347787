<p-button [text]="true"

          [severity]="(browsingTab && !browsingTab.closed) ? 'primary' : 'secondary'"
          pTooltip='{{ "SHARINGCONTROL_LABEL_NEWTAB" |Translate : "New Tab" }}'
          tooltipPosition="top" id="new-tab-btn"
          (onClick)="openNewTab()"
>
<fa-icon [icon]="faWindowRestore" size="lg"></fa-icon>

</p-button>
