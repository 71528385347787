<div class="bottom-ctrls d-flex flex-row justify-content-end gap-1">
  <p-button
    appDebounceClick
    severity="danger"
    [disabled]="(privateChatEnabled || publicChatEnabled)"
    styleClass="btn-bottom-control"
    pTooltip='{{ "SUPERVISORSNOOPING_TOOLTIP_EXITMONITORING" | Translate : "Exit Monitoring" }}'
    (clicked)="endMonitoring()">
      <fa-icon [icon]="faUserSecret"/>
  </p-button>
  <p-button
    appDebounceClick
    styleClass="btn-bottom-control"
    [severity]="privateChatEnabled ? 'danger' : 'success'"
    *ngIf="privateChatAvailable"
    [pTooltip]="privateChatTitle()"
    (clicked)="togglePrivateChat()">
    <fa-layers>
      <fa-icon [icon]="faComment"></fa-icon>
      <fa-icon size="2xs" [icon]="faLock" transform="up-1 right-5" [class]="privateChatEnabled ? 'surface-red-text' : 'surface-green-text'"></fa-icon>
    </fa-layers>
  </p-button>
  <p-button
    appDebounceClick
    styleClass="btn-bottom-control"
    [severity]="publicChatEnabled ? 'danger' : 'success'"
    *ngIf="publicChatAvailable"
    [pTooltip]="publicChatTitle()"
    (clicked)="togglePublicChat()">
      <fa-icon [icon]="faRightToBracket"/>
  </p-button>
</div>
