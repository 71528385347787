import {Component, Input, OnInit} from '@angular/core';
import {faCopy, faLink} from "@fortawesome/free-solid-svg-icons";
import {ClipboardService} from "../../services/clipboard.service";
import {AlertService, AlertType} from "../../services/alert-service/alert.service";

@Component({
  selector: 'app-engagement-invite-link',
  templateUrl: './engagement-invite-link.component.html',
  styleUrl: './engagement-invite-link.component.scss'
})
export class EngagementInviteLinkComponent {

  protected readonly faCopy = faCopy;

  @Input() inviteUrl: string = "";

  constructor(
    private readonly clipboard: ClipboardService,
    private readonly alertService: AlertService
  ) {
  }


  copyToClipboard() {
    this.clipboard.copy(this.inviteUrl);
    this.alertService.addResourceAlert("INVITE_LINK_COPIED", "Invite Link Copied", AlertType.Info);
  }

  protected readonly faLink = faLink;
}
