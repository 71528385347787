import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggingService } from '../../services/logging.service';
import { TextMessage } from '../../classes/TextMessage';
import { VisitorService } from '../../services/visitor-service/visitor.service';
import { OnlineState } from '../../enums/online-state.enum';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {isSubscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'app-engagement-private-chat-tab',
  templateUrl: './engagement-private-chat-tab.component.html',
  styleUrls: ['./engagement-private-chat-tab.component.scss']
})
export class EngagementPrivateChatTabComponent implements OnInit {
  @Input() privateChatMessages: TextMessage[];
  @Input() supervisorsAvailable: boolean;

  @Output() sendPrivateMessage = new EventEmitter<string>();


  public agentText = '';

  constructor(
    private logger: LoggingService,
    private visitorService: VisitorService
  ) { }

  ngOnInit() {
    if (!this.supervisorsAvailable) {
      this.visitorService.agentStatus.subscribe(agents => {
        if ([...agents].some(([k, v]) => v.status === OnlineState.Supervisor)) {
          this.supervisorsAvailable = true;
        } else {
          this.supervisorsAvailable = false;
        }
      });
    }
  }

  public sendPrivateTextMessage() {
    const message = this.agentText.trim();

    if (message.length === 0) {
      this.logger.debug('Trying to send a 0 length agent message, ignoring.');
      return;
    }

    this.sendPrivateMessage.emit(message);
    this.agentText = '';
  }

  protected readonly faPaperPlane = faPaperPlane;
  protected readonly isSubscription = isSubscription;
}
