<table class="table table-borderless ">
  <thead>
  <tr class="d-flex text-center">
    <th class="col-4">{{ "SUPERVISOR_HEADER_AGENT"  | Translate : 'Agent' }}</th>
    <th class="col-4">{{ "SUPERVISOR_HEADER_TIMELOGIN"  | Translate : 'Time Logged In' }}</th>
    <th class="col-4">{{ "SUPERVISOR_HEADER_TIMELASTSESSION"  | Translate : 'Time Since Last Session' }}</th>
  </tr>
  </thead>
  <tbody *ngIf="show">
  <ng-container *ngFor="let group of selectedGroups | keyvalue">
    <tr *ngIf="(selectedAgents | agentDashboardFilter: 'Available' : group.key).size > 0" class="op-group-header">
      <td>
        <h3 class="op-group-name">{{group.value}}</h3>
      </td>
    </tr>

    <tr *ngFor="let agent of (selectedAgents | agentDashboardFilter: 'Available' : group.key | keyvalue)"
        class="d-flex text-center ml-3">
      <td class="col-4"><span class="text-truncate">{{agent.value.firstname}} {{agent.value.lastname}}</span>
      </td>
      <td class="col-4">{{TimeUtil.CreateTimeDifferenceForDisplay(agent.value.loggedInTimestamp)}}</td>
      <td class="col-4">{{TimeUtil.CreateTimeDifferenceForDisplay(agent.value.lastEngagementTimestamp)}}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
