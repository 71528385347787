<p-tabView class="justified">
  <p-tabPanel class="h-100" header='{{ "ENGAGEMENTJOIN_HEADER_USERS" | Translate : "Users" }}'>

    <div id="room-agents-container"
         class="h-100 d-flex flex-column justify-content-start overflow-hidden user-select-none">

      <section class="flex-1 overflow-auto my-2 px-2">
        <div class="d-flex flex-column gap-2 my-2 mb-4">
          <strong>{{ "ENGAGEMENTJOIN_HEADER_AGENTS" | Translate : "Agents" }}</strong>
          <app-engagement-join-row [engagement]="engagement" [agent]="agent.value"
                                   (kick)="kick($event)" (changePresenter)="changePresenter($event)"
                                   *ngFor="let agent of engagement?.roomAgents | keyvalue">
          </app-engagement-join-row>
        </div>

        <div class="d-flex flex-column gap-2 my-2">
          <strong>{{ "ENGAGEMENTJOIN_HEADER_CUSTOMERS" | Translate : "Customers" }}</strong>

          <app-engagement-join-visitor-row [engagement]="engagement" [visitor]="visitor.value" (kick)="kick($event)"
                                           *ngFor="let visitor of engagement?.roomVisitors | keyvalue | orderBy: 'index'">
          </app-engagement-join-visitor-row>
        </div>

      </section>
      <div class="join-panel-buttons">
        <p-button id="close-join-btn" class="flex-grow-1" styleClass="h-100 w-100 center-icon" severity="secondary"
                  pTooltip='{{ "ENGAGEMENTTRANSFER_TOOLTIP_CLOSEPANEL" | Translate : "Close Panel" }}'
                  (onClick)="cancelJoin()">
          <fa-icon [icon]="faChevronLeft" size="xl"/>
        </p-button>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel class="h-100 engagement-join-agent-header"
              header='{{ "ENGAGEMENTJOIN_HEADER_ADDUSER" | Translate : "Add User" }}'>
    <div class="d-flex flex-column gap-4 my-2 px-2 h-100 w-100">
      <div class="d-flex flex-column gap-2 my-2 mb-4">
        <strong>{{ "ENGAGEMENTJOIN_HEADER_INVITE_CUSTOMER" | Translate : "Invite Customer" }}</strong>
        <app-engagement-invite-link tooltipPosition="top" [pTooltip]="engagement?.engagementUrl$ | async"
                                    class="d-block"
                                    [inviteUrl]="engagement?.engagementUrl$ | async"></app-engagement-invite-link>
      </div>
      <div class="d-flex flex-column gap-2 my-2 flex-grow-1 overflow-hidden">
        <strong>{{ "ENGAGEMENTJOIN_HEADER_INVITE_AGENTS" | Translate : "Invite Agents" }}</strong>
        <app-engagement-transfer (cancelInvite)="cancelJoin()"
                                 (inviteRequest)="inviteRequest($event)"
                                 [currentState]="currentState"
                                 [roomAgents]="engagement?.roomAgents"
                                 class="engagement-controls flex-grow-1 overflow-hidden">
        </app-engagement-transfer>
      </div>
    </div>

  </p-tabPanel>
</p-tabView>
