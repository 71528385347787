import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textMessageDate'
})
export class TextMessageDatePipe implements PipeTransform {
  transform(value: Date, args?: any): string {
    if (value) {
      const now = new Date().getTime();
      if ((now - value.getTime()) >= (24 * 3600 * 1000)) {
        return value.toLocaleString()
      } else {
        return value.toLocaleTimeString();
      }
    } else {
      return '';
    }
  }
}
