import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-verify-customer-modal',
  templateUrl: './verify-customer-modal.component.html',
  styleUrls: ['./verify-customer-modal.component.scss'] // corrected styleUrl to styleUrls
})
export class VerifyCustomerModalComponent implements OnInit {

  form: FormGroup;
  smsOnlyVerification = true; // removed unnecessary comment

  onVerifyCustomer: (data: string) => void;
  onCancel: () => void;

  agentCulture = 'en-us';
  customerCulture = 'en-us';

  constructor(private dialogConfig: DynamicDialogConfig<VerifyCustomerModalComponent>) {
    this.onVerifyCustomer = dialogConfig.data?.onVerifyCustomer;
    this.onCancel = dialogConfig.data?.onCancel;
    this.agentCulture = dialogConfig.data?.agentCulture || this.agentCulture;
    this.customerCulture = dialogConfig.data?.customerCulture || this.customerCulture;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      inputTxt: new FormControl(undefined, [Validators.required])
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const value = this.form.get('inputTxt').value;
      this.onVerifyCustomer(value);
    }
  }

  getInitialCountry() {
    return this.getCustomerRegion();
  }

  getCountryOrder() {
    return [this.getAgentRegion(), this.getCustomerRegion()];
  }

  private getAgentRegion(): string {
    return this.getRegion(this.dialogConfig.data?.agentCulture);
  }

  private getCustomerRegion(): string {
    return this.getRegion(this.dialogConfig.data?.customerCulture);
  }

  private getRegion(culture: string | undefined): string {
    try {
      return culture?.split('-')[1] || 'us';
    } catch (e) {
      return 'us';
    }
  }
}
