import { BrowserTypes } from "../browser-service/browser-types";

export class BrowserIPCMessage {
  constructor(public data: any, public type: BrowserTypes, public readonly guid: string) {
  }
  public serialize(): string {
    return JSON.stringify(this);
  }
  public static deserialize(jsonStr: string): BrowserIPCMessage {
    return JSON.parse(jsonStr);
  }
}