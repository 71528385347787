import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {AlertService, AlertType} from '../../../services/alert-service/alert.service';
import {CrmService} from '../../../services/crm-service/crm.service';
import {Subscription} from 'rxjs';
import {StatusEnum} from '../../../enums/appointment-status';
import {Features, FeatureService} from '../../../services/feature-service/feature.service';
import {AuthService} from '../../../services/auth-service/auth.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {AppointmentTransferModalComponent} from '../../../components/appointment-transfer-modal/appointment-transfer-modal.component';
import {Appointment} from '../../../services/crm-service/appointment';
import {CrmTabs} from '../../../enums/CrmTabs';
import {ModalService} from '../../../services/modal.service';
import {LoadingService} from '../../../services/loading.service';
import {faCalendarCheck, faCalendarXmark, faRightLeft, faUserTimes} from '@fortawesome/free-solid-svg-icons';
import {AppointmentService} from '../../../services/appointment/appointment.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-appointment-row-detail',
  templateUrl: './appointment-row-detail.component.html',
  styleUrls: ['./appointment-row-detail.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentRowDetailComponent implements OnInit {
  constructor(
    private crmService: CrmService,
    private appointmentService: AppointmentService,
    private featureService: FeatureService,
    private translate: TranslatePipe,
    private modalService: ModalService,
    private alertService: AlertService,
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {
  }

  @Input() appointment: Appointment = new Appointment();
  @Input() inSupervisorMode = false;
  @Input() isOpen = false;
  @Output() showCrmInformationView: EventEmitter<CrmTabs> = new EventEmitter<CrmTabs>();
  @Output() filterData = new EventEmitter();
  private subscriptions: Subscription[] = [];

  public launchAppointmentTransferModal: DynamicDialogRef;

  protected readonly faUserTimes = faUserTimes;
  protected readonly faCalendarXmark = faCalendarXmark;
  protected readonly faCalendarCheck = faCalendarCheck;
  protected readonly faRightLeft = faRightLeft;
  protected readonly StatusEnum = StatusEnum;


  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  onCloseAppointment() {
    this.appointmentService.updateAppointmentStatus(this.appointment.appointmentGuid, StatusEnum.CLOSED).pipe(first()).subscribe(success => {
      const successMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_CLOSED', 'Appointment Closed.');
      const errorMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_FAILED_TO_UPDATE', 'Failed to update appointment');
      this.onUpdate(success, successMsg, errorMsg);
    });
  }


  onCancelAppointment() {
    this.appointmentService.cancelAppointment(this.appointment.appointmentGuid).pipe(first()).subscribe(success => {
      const successMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_CANCELLED', 'Appointment Cancelled.');
      const errorMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_FAILED_TO_UPDATE', 'Failed to update appointment');
      this.onUpdate(success, successMsg, errorMsg);
    });
  }

  onNoShowAppointment() {
    this.appointmentService.updateAppointmentStatus(this.appointment.appointmentGuid, StatusEnum.NOSHOW).pipe(first()).subscribe(success => {
      const successMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_NOSHOW', 'Appointment Marked as No Show.');
      const errorMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_FAILED_TO_UPDATE', 'Failed to update appointment');
      this.onUpdate(success, successMsg, errorMsg);
    });
  }

  private onUpdate(success: boolean, successMsg: string, errorMsg: string) {
    if (success) {
      this.alertService.addAlert(successMsg, AlertType.Success);
      this.filterData.emit();
    } else {
      this.alertService.addAlert(successMsg, AlertType.Danger);
    }
  }

  onNoteFieldValueChange(event) {
    this.appointmentService.updateAppointment(
      this.appointment.appointmentGuid,
      {
        notes: event.target.value
      }).pipe(first()).subscribe(success => {
      const successMsg = this.translate.transform('APPOINTMENTSEARCH_NOTES_UPDATED', 'Appointment Notes Updated.');
      const errorMsg = this.translate.transform('APPOINTMENTSEARCH_LABEL_FAILED_TO_UPDATE', 'Failed to update appointment');
      this.onUpdate(success, successMsg, errorMsg);
    });
  }

  public onShowCRMData() {
    this.showCrmInformationView.emit(CrmTabs.CONTACT);
  }

  public onShowSessionHistory() {
    this.showCrmInformationView.emit(CrmTabs.SESSIONHISTORY);
  }

  public onTransferAppointment() {
    const data = {
      appointmentGuid: this.appointment.appointmentGuid,
      startDate: this.appointment.startDate,
      endDate: this.appointment.endDate,
      onCancelTransfer: () => {
        this.closeAppointmentTransferModalPopup();
      },
      onAttemptTransfer: (selectedAgent: string) => {
        this.handleTransferAttempted(selectedAgent);
      },
    };
    this.launchAppointmentTransferModal = this.modalService.openModal(AppointmentTransferModalComponent, {
      data,
      header: this.translate.transform('APPOINTMENTTRANSFER_LABEL_TITLE', 'Transfer Appointment?'),
      contentStyle: {overflow: 'visible', maxWidth: '500px'},
      showHeader: true
    });
  }

  private handleTransferAttempted(selectedAgent: string) {
    this.closeAppointmentTransferModalPopup();
    if (selectedAgent == null || selectedAgent === '') {
      this.alertService.addAlert(this.translate.transform('APPOINTMENTTRANSFER_ERROR_SELECTAGENT', 'Please select an Agent.'), AlertType.Danger);
    } else {
      this.loadingService.isLoading.next(true);
      this.subscriptions.push(
        this.appointmentService.reassignAppointmentAgent(this.appointment.appointmentGuid, selectedAgent).subscribe(success => {
          if (success) {
            this.alertService.addAlert(this.translate.transform('APPOINTMENTTRANSFER_ALERT_REASSIGNED', 'Appointment Reassigned Successfully.'), AlertType.Success);
            this.filterData.emit();
          } else {
            this.alertService.addAlert(this.translate.transform(
              'APPOINTMENTTRANSFER_ALERT_FAILED',
              'Failed to transfer appointment.'), AlertType.Danger);
          }
          this.loadingService.isLoading.next(false);
        })
      );
    }
  }


  public closeAppointmentTransferModalPopup() {
    if (this.launchAppointmentTransferModal) {
      this.modalService.closeModal(this.launchAppointmentTransferModal);
      this.launchAppointmentTransferModal = null;
    }
  }


  public canTransferOrChangeDisposition(): boolean {

    if (this.featureService.has(Features.SUPERVISOR_TRANSFER_APPOINTMENT)) {
      return true;
    }

    if (this.isOpen && this.authService.currentAgent.value.username === this.appointment.agentUserName) {
      return true;
    }

    return false;
  }

}
