<div class="container-fluid">
  <div class="card-deck user-select-none">
    <ng-container *ngFor="let card of this.selectedCards">
      <div class="card text-center" role="button"
           [class.selected]="selectedCard === card"
           [ngClass]="getColorClass(card)"
           (click)="pullDetails(card)">
        <img src='{{card.imgPath}}' class="card-img-top h-25" alt="{{card.name}}" />
        <div class="card-body flex-1 d-flex flex-column align-items-center justify-content-center p-0 m-0">
          <h4 class="card-title display-3 p-0 m-0">{{text(card)}}</h4>
          <p class="card-text p-0 m-0 w-100">{{card.name}}</p>
          <fa-icon pTooltip="{{'DASHBOARD_CARD_VEECHATSESSIONSPOSSIBLE_INFO_TOOLTIP' | Translate: 'The number of sessions available is affected by the VeeChat call limit.' }}"
            [icon]="faInfoCircle" size="lg"
            class="veechat-warning"
            [style.visibility]="card.type === DashboardCardType.veeChatSessionsPossible ? 'auto' : 'hidden'"></fa-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
