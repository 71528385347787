<div id="main_video_container" class="mainVideoContainer"></div>
<div id="peers_container" class="peersContainer"></div>

<div *ngIf="dragResizeEnabled" class="dock-button" 
  [ngClass]="undocked ? 'dock-button-undocked' : 'dock-button-docked'" 
  [ngStyle]="{'display' : mouseover ? 'inline-block' : 'none' }" 
  (click)="undocked ? dockVideo() : unDockVideo()">
  <fa-icon [icon]="faExpand" *ngIf="!undocked"></fa-icon>
  <fa-icon [icon]="faCompress" *ngIf="undocked"></fa-icon>
</div>

<div *ngIf="virtualBackground" class="virtualbackground-button" 
  [ngClass]="virtualBackgroundOn ? 'virtualbackground-button-on' : 'virtualbackground-button-off'"
  (click)="virtualBackgroundOn ? turnVirtualBackgroundOff() : turnVirtualBackgroundOn()">      
</div>