import {Component, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {VisitorService} from '../../services/visitor-service/visitor.service';
import {Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {OnlineService} from '../../services/online.service';
import {EngagementService} from '../../services/engagement.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import 'webrtc-adapter';
import {CrmService} from '../../services/crm-service/crm.service';
import { EmailService } from '../../services/email-service/email.service';
import { FulfillmentService } from '../../services/fulfillment-service/fulfillment.service';
import { first } from 'rxjs/operators';
import { EngagementEventType, EngagementEventTypes } from '../../services/engagement';
import { OnlineState } from '../../enums/online-state.enum';
import { Title } from '@angular/platform-browser';
import { LicenceType } from '../../enums/licence-type.enum';
import {WorkStatus} from '../../classes/work-status';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {MediaPermissionService} from '../../services/media-permission.service';

@Component({
  selector: 'app-veestudio',
  templateUrl: './veestudio.component.html',
  styleUrls: ['./veestudio.component.scss'],
  providers: [ VisitorService ]
})
export class VeestudioComponent implements OnInit, OnDestroy {

  public static USE_WINDOW_BEFOREUNLOAD = true;


  @ViewChild("container", {static: true}) container: ElementRef;

  private subscriptions: Subscription[] = [];
  private readonly unloadMethod = (e: Event) => this.onBeforeUnload(e);

  private connectionType: LicenceType;
  private engagementEventType: EngagementEventType;
  private availableState: OnlineState;

  constructor(
    private visitorService: VisitorService,
    private onlineService: OnlineService,
    private engagementService: EngagementService,
    private router: Router,
    private settingsService: SettingsService,
    private crmService: CrmService,
    private emailService: EmailService,
    private fulfillmentService: FulfillmentService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private featureService: FeatureService,
    private mediaPermissionService: MediaPermissionService) {

    // set vh unit variable, both initially and on resize of window
    this.setVh();
    window.addEventListener('resize', () => {
      this.setVh();
    });
  }

  ngOnInit() {
      this.activatedRoute.data
      .pipe(first())
      .subscribe(v => {
        this.connectionType = v.connectionType || LicenceType.VeeStudio;
        this.engagementEventType = v.engagementEventType || EngagementEventTypes.VeeStudio;
        this.availableState = v.availableState || OnlineState.Available;
        this.init();
    });
  }

  private init() {
    if (this.connectionType === LicenceType.VeeStudio) {
      this.titleService.setTitle('VeeStudio');
    }

    this.mediaPermissionService.checkAndRequestPermissions().catch((error) => {
      console.error('Error during media permission check/request:', error);
    });

    this.container.nativeElement.classList.add(this.engagementEventType.toLowerCase());

    const showPreviousEngagements = this.featureService.has(Features.REJOIN_ENGAGEMENT);

    this.visitorService.startConnection(this.connectionType, showPreviousEngagements).subscribe(success => {
      if (!success) {
        this.router.navigateByUrl('/login');
      } else {
        if (VeestudioComponent.USE_WINDOW_BEFOREUNLOAD) {
          // Prevent page reloading if use has interacted with it
          window.addEventListener('beforeunload', this.unloadMethod);
        }

        this.onlineService.setCurrentState(OnlineState.OnBreak, WorkStatus.VeeStudio);
        this.settingsService.loadAll();
        this.crmService.loadAll();
        this.emailService.loadEmailTemplateList(this.engagementEventType).subscribe();
        this.fulfillmentService.getMarketingMaterial().subscribe();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private onBeforeUnload(e: Event) {

    if (this.engagementService.activeEngagements.value === 0) {
      return;
    }

    e.preventDefault();
    e.returnValue = true;
  }

  onEndBreak() {
    this.onlineService.setCurrentState(this.availableState, WorkStatus.VeeStudio);
  }

  // Since mobile browsers interperet vh units differently, we must account for them differently
  // courtesy of https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setVh() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
