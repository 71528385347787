import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from '../../services/alert-service/alert.service';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { Router } from '@angular/router';
import { OnlineService } from '../../services/online.service';
import { OnlineState } from '../../enums/online-state.enum';
import { FeatureService, Features } from '../../services/feature-service/feature.service';
import { SettingsService } from '../../services/settings-service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ElectronService } from '../../services/electron-service/electron.service';
import { VersionService } from '../../services/version-service/version.service';
import {LogoutService} from '../../services/logout.service';
import {WorkStatus} from '../../classes/work-status';
import {
  faComment,
  faComments,
  faHand,
  faLock,
  faMicrophone, faUserSecret,
  faVideo,
  faVideoSlash
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  private returnUrl: string;
  private _loggingIn = false;
  public get loggingIn(): boolean {
    return this._loggingIn;
  }
  public showAutoLogoutMessage = this.logoutService.showAutoLogoutMessage;

  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router,
    private onlineService: OnlineService,
    private featureService: FeatureService,
    private settingsService: SettingsService,
    public translate: TranslateService,
    private electronService: ElectronService,
    private versionService: VersionService,
    private logoutService: LogoutService,

  ) {
    this.createForm();
    translate.addLangs(["en-US", "fr-FR","de-DE","ja"]);
    translate.setDefaultLang('en-US'); // this language will be used as a fallback when a translation isn't found in the current language

    let browserLang = navigator.language;//Get current Browser language;
    translate.use(browserLang.match(/en-US|fr-FR|de-DE|ja/) ? browserLang : 'en-US');
  }

  ngOnInit() {
    this.onlineService.setCurrentState(OnlineState.OffLine, WorkStatus.None);

    this.electronService.licenceKey$.subscribe(key => {
      if (key) {
        this.onlineService.deskLicenceKey = key;
      }
    });
    this.electronService.getAppVersion();
    this.electronService.getLicenceKey();
  }

  private createForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  public submit(): void {
    if (this.loginForm.valid) {


      if (this._loggingIn) {
        return;
      }

      this._loggingIn = true;
      this.loadingService.isLoading.next(true);

      const {email, password} = this.loginForm.value;
      this.authService.login(email, password, this.onlineService.deskLicenceKey,this.versionService.version, this.versionService.electronVersion).subscribe(success => {
        this._loggingIn = false;

        if (success) {
          //subcribe to Resource Data
          this.settingsService.loadResources().subscribe(response => {
            if (response != null) {
              const agent = this.authService.currentAgent.value;
              if (agent.isPresentationDevice) {
                this.returnUrl = '/presenter';
              } else {
                this.returnUrl = '/home';
              }

              this.electronService.sendCulture(agent.culture);
              this.alertService.addAlert(this.translate.instant('LOGIN_WELCOME'), AlertType.Success);
              this.router.navigateByUrl(this.returnUrl);

              try {
                this.alertService.initSound();
                // TO DO: do something in case of an error
              } catch (_) {
              }
            }
          });
        } else {
           this.alertService.addAlert(this.translate.instant('LOGIN_ERROR_LOGIN'), AlertType.Danger);
        }
        this.loadingService.isLoading.next(false);
      });
    } else {
      this._loggingIn = false;
      this.loadingService.isLoading.next(false);
      this.alertService.addAlert(this.translate.instant('LOGIN_ERROR_INVALID_CREDENTIALS'), AlertType.Danger);
    }
  }

  protected readonly faVideo = faVideo;
  protected readonly faVideoSlash = faVideoSlash;
  protected readonly faMicrophone = faMicrophone;
  protected readonly faComment = faComment;
  protected readonly faLock = faLock;
  protected readonly faHand = faHand;
  protected readonly faComments = faComments;
  protected readonly faUserSecret = faUserSecret;
}
