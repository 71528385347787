<div class="file-transfer border-top">
  <span class="text-danger">{{ "FILETRANSFER_LABEL_FILESIZELIMIT" | Translate : "File size limit: "}} {{maxFileSizeLimit}}</span>
  <table class="table table-borderless  w-100 mt-2">
    <tbody class="d-flex flex-column h-100 w-100">
      <tr *ngFor="let file of files">
        <td><fa-icon [icon]="faFile" class="ml-2 mr-2"></fa-icon></td>
        <td><span class="text-truncate text-left">{{file.filename}}</span></td>
        <td>
          <div class="progress mr-2" *ngIf="(file.state | async) !== TransferState.ERRORED">
            <div class="progress-bar bg-success progress-bar-striped" role="progressbar"
                  [ngStyle]="{ 'width': (file.progress | async) * 100 + '%' }"
                  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
            ></div>
          </div>
        </td>
        <td *ngIf="(file.state | async) !== TransferState.DONE">
          <fa-icon [icon]="faTrash" class="cancel-file-transfer" title="Cancel" (click)="cancelTransfer(file)"></fa-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>
