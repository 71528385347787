import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JoinStateRejected, TransferStateRejected} from '../../services/engagement';
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-transfer-rejected',
  templateUrl: './engagement-transfer-rejected.component.html',
  styleUrls: ['./engagement-transfer-rejected.component.scss']
})
export class EngagementTransferRejectedComponent {
  @Input() currentEngagementStateText: string = '';
  @Input() inviteState: JoinStateRejected | TransferStateRejected;

  @Output() chooseAnotherAgent = new EventEmitter<void>();
  protected readonly faChevronLeft = faChevronLeft;
}
