import {SiteVisitor} from './visitor-service/SiteVisitor';
import {DeliveryStatus, TextMessage, TextMessages} from '../classes/TextMessage';
import {BehaviorSubject, mergeMap, Observable, of} from 'rxjs';
import {VisitorService} from './visitor-service/visitor.service';
import {CrmService} from './crm-service/crm.service';
import {AuthService} from './auth-service/auth.service';
import {IFileUpload} from './file-transfer-service/file-upload-interface';
import {TransferState} from './file-transfer-service/file-upload';
import {LoggingService} from './logging.service';
import {EngagementEvent} from './crm-service/engagement-event';
import * as moment from 'moment';
import {SettingsService} from './settings-service/settings.service';
import {
  AcceptConversation,
  AgentChatMessage,
  AsyncMessageHandler,
  ConversationId,
  ConversationState,
  CustomerChatMessage,
  FileUploadMessage,
  HubConversation,
  Site,
  SmsSpecialMessage,
  StartConversation,
  StatusMessage,
  SurveyMessage,
  ThreadId
} from './async-message-handler';
import {Channels} from './visitor-service/Channel';
import {CallInviteState, EngagementState, TransferStateType} from './engagement';
import {InviteRequest} from '../components/engagement-transfer-select/engagement-transfer-select.component';
import {EventEmitter} from '@angular/core';

import {PostEngagementStatus} from '../classes/post-engagement.status';
import {CustomerChatHistory} from './crm-service/customer-chat-history';
import Guid from '../classes/Guid';

export enum AsyncConversationState {
  ENGAGED = 'engaged',
  POST = 'post',
  COLD_TRANSFER = 'cold_transfer',
  WARM_TRANSFER = 'warm_transfer',
}

export class AsyncConversation extends AsyncMessageHandler {
  private chatHistory: CustomerChatHistory;
  public get site(): Site {
    return this.hubConversation.Site;
  }

  public get siteSection(): string {
    return this.hubConversation.SiteSection;
  }

  public get userGuid(): string {
    return this.visitor.userGuid;
  }

  public get id(): ConversationId {
    return this.hubConversation.Id;
  }

  public get threadId(): ThreadId {
    return this.hubConversation.ThreadId;
  }

  public get channel(): Channels {
    return this.hubConversation.Channel.Type;
  }

  public unreadMessages: number = 0;

  public visitor: SiteVisitor;

  public Messages: TextMessages;
  public get messages(): TextMessages {
    return this.Messages;
  }

  public chatName: string = '';

  public uploadingFiles: IFileUpload[] = [];

  public lastCustomerMessageTimestamp: Date = new Date();

  public agentText: string  = '';

  private lastAgentMessageId: number = -1;
  public lastAgentMessageFailed: boolean = false;

  public state: AsyncConversationState = AsyncConversationState.ENGAGED;

  public transferChatMessages: TextMessage[] = [];

  private _transferState: TransferStateType;
  public get transferState(): TransferStateType {
    return this._transferState;
  }
  private set transferState(transferState: TransferStateType) {
    this._transferState = transferState;
  }

  private transferReason: string;

  public reassigned = new EventEmitter<void>();

  public get isEnded(): boolean {
    return this.state === AsyncConversationState.POST;
  }

  public get canReply(): boolean {
    if (this.channel === Channels.Sms) {
      return this.channelReplyEnabled;
    } else {
      // Messenger and WhatsApp have a 24-hour window
      return this.lastCustomerMessageTimestamp.getTime() > (new Date().getTime() - 1000 * 3600 * 24);
    }
  }

  private channelReplyEnabled: boolean = true;

  private blocked_: boolean = false;
  public get blocked(): boolean {
    return this.blocked_;
  }

  public primaryAgent = new BehaviorSubject(true);

  public postEngagementStatus = new PostEngagementStatus();

  constructor(
    private readonly visitorService: VisitorService,
    authService: AuthService,
    private readonly hubConversation: HubConversation,
    private settingsService: SettingsService,
    crmService: CrmService,
    private readonly loggingService: LoggingService,
  ) {
    super(loggingService, settingsService, authService.currentAgent.value.username, hubConversation.Id.Id);
    this.chatHistory = new CustomerChatHistory(crmService, settingsService, new Guid(hubConversation.Visitor.UserGuid), new Guid(hubConversation.Id.Id), authService.currentAgent.value.username);

    this.visitor = new SiteVisitor(this.hubConversation.Visitor);

    if (this.hubConversation.State === ConversationState.Transfer) {
      this.visitor.beingTransferred = true;
    }

    this.state = this.hubConversation.State == ConversationState.Post ? AsyncConversationState.POST : AsyncConversationState.ENGAGED;

    let useragent: string;
    switch (this.channel) {
      case Channels.Messenger:
        useragent = 'Messenger';
        break;
      case Channels.Sms:
        useragent = 'SMS';
        break;
      case Channels.WhatsApp:
        useragent = 'WhatsApp';
        break;
      default:
        useragent = '';
        break;
    }

    this.visitor.lastSection = this.siteSection;
    this.visitor.browser = useragent;


    this.chatName = this.hubConversation.FriendlyName;

    this.visitorService.getVisitorCrmData(this.userGuid).subscribe(data => {
      this.visitor.setCrmData(data, crmService);

      if (!this.chatName) {
        const crmData  = crmService.extractCustomerData(data);
        const firstName = crmService.getCrmFieldValue(crmData, 'Customer Information', 'Firstname');
        const lastName = crmService.getCrmFieldValue(crmData, 'Customer Information', 'Lastname');
        const phoneNumber = crmService.getCrmFieldValue(crmData, 'Customer Information', 'Phone Number');

        if (firstName || lastName) {
          if (firstName && lastName) {
            this.renameChat(`${firstName} ${lastName}`);
          } else if (firstName) {
            this.renameChat(firstName);
          } else {
            this.renameChat(lastName);
          }
        } else if (phoneNumber) {
          this.renameChat(phoneNumber);
        }
      }
    });

    this.visitorService.getVisitorSessionHistory(this.userGuid).subscribe(data => {
      this.visitor.sessionHistory.next(data);

      if (data) {
        data.sort((e1: EngagementEvent, e2: EngagementEvent) => {
          const e1Time = moment.utc(e1.StartTime, 'DD-MM-YYYY HH:mm:ss').local();
          const e2Time = moment.utc(e2.StartTime, 'DD-MM-YYYY HH:mm:ss').local();

          return e2Time.valueOf() - e1Time.valueOf();
        });

        const lastEvent = data.find(d => d.Closed);
        if (lastEvent) {
          this.visitor.previousOperator = lastEvent.Operator;
        }

        this.chatHistory.updateSessionHistory(data);
      }
    });

    this.chatHistory.updateSessionHistory(this.visitor.sessionHistory.value);
    this.chatHistory.loadMore().then(items => {
      this.Messages.addHistory(this.chatHistory.history);
      this.loggingService.debug(`Loaded ${items.length} messages.`);
    });

    const ended = this.state === AsyncConversationState.POST;
    this.visitorService.getAsyncMessages(this.id, this.threadId, !ended).subscribe(ms => {
      this.handleMessages(ms);
    });
  }

  setBlocked() {
    this.blocked_ = true;
  }

  public sendMessage(message: string): Observable<boolean> {
    this.setAgentText('');
    return new Observable<boolean>(subscriber => {
      this.visitorService.sendConversationMessage(this.id, this.threadId, message).subscribe(() => {
        subscriber.next(true);
        this.unreadMessages = 0;
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  sendTemplateMessage(template: string): Observable<boolean> {
    this.setAgentText('');
    return new Observable<boolean>(subscriber => {
      // Template format 1 is the Twilio format
      this.visitorService.sendConversationTemplate(this.id, this.threadId, 1, template).subscribe(() => {
        subscriber.next(true);
        this.unreadMessages = 0;
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  private sendFileUploadMessage(uploadUrl: string) {
    return new Observable<boolean>(subscriber => {
      this.visitorService.sendFileUploadMessage(this.id, this.threadId, uploadUrl).subscribe(() => {
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  public coldTransferAsyncChat(siteSection: string, message: string) {
    return new Observable<boolean>(subscriber => {
      this.visitorService.coldTransferAsyncChat(this.id, siteSection, message).subscribe(() => {
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  public end(sendSurvey: boolean): Observable<boolean> {
    const endObs = new Observable<boolean>(subscriber => {
      this.visitorService.endAsyncConversation(this.id, this.threadId).subscribe(() => {
        this.state = AsyncConversationState.POST;
        this.unreadMessages = 0;

        if (sendSurvey) {
          this.visitorService.sendSurveyMessage(this.id, this.threadId).subscribe(() => {
            this.loggingService.debug(`Survey sent for conversations ${this.id.Id}`);
          });
        }

        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });


    return endObs;
  }

  public qualify(status: string, subStatus: string, notes: string): Observable<boolean> {
    if (this.state !== AsyncConversationState.POST) {
      throw Error("Attempting to quality not closed conversation");
    } else {
      return this.visitorService
        .qualifyConversation(this.id, this.visitor.userGuid, status, subStatus, notes)
        .pipe(mergeMap(r => {
          if (r.success) {
            return this.close();
          } else {
            return of(false);
          }
        }));
    }
  }

  private close(): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      this.visitorService.closeConversation(this.id, this.threadId).subscribe(() => {
        this.state = AsyncConversationState.POST;
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  protected onCustomerChat(msg: CustomerChatMessage, textMessage: TextMessage) {
    // Need to update agentText here to empty otherwise it gets stuck on
    // "Can't reply to customer after 24 hours"
    if (!this.canReply) {
      this.agentText = '';
    }
    this.lastCustomerMessageTimestamp = textMessage.timestamp;

    if (this.state === AsyncConversationState.POST) {
      this.unreadMessages = 0;
    } else {
      this.unreadMessages++;
    }
  }

  protected onAgentChat(msg: AgentChatMessage, textMessage: TextMessage) {
    this.lastAgentMessageId = msg.MessageId;
    this.unreadMessages = 0;
  }

  protected onSurvey(msg: SurveyMessage, textMessage: TextMessage) {
  }

  protected onFileUpload(msg: FileUploadMessage, textMessage: TextMessage) {
    this.lastAgentMessageId = msg.MessageId;
  }

  protected onStartConversation(msg: StartConversation, textMessage: TextMessage) {
  }

  protected onAcceptConversation(msg: AcceptConversation, textMessage: TextMessage) {
  }

  protected onStatus(msg: StatusMessage, textMessage: TextMessage) {
    if (this.lastAgentMessageId === textMessage.id) {
      this.lastAgentMessageFailed = textMessage.deliveryStatus === DeliveryStatus.Failed;
    }
  }

  protected onSmsSpecialMessage(msg: SmsSpecialMessage) {
    this.channelReplyEnabled = msg.MessagingEnabled;
  }

  renameChat(newName: string) {
    this.chatName = newName;

    this.visitorService.changeNameOfConversation(this.id, newName)
      .subscribe();
  }

  addFileTransfer(upload: IFileUpload): Promise<void> {
    this.uploadingFiles.push(upload);
    return new Promise<void>((resolve, reject) => {
      upload.state.subscribe((state: TransferState) => {
        switch (state) {
          case TransferState.IDLE:
            break;
          case TransferState.INPROGRESS:
            break;
          case TransferState.CANCELLED:
            this.loggingService.debug("File upload cancelled");
            this.uploadingFiles = this.uploadingFiles.filter(t => t !== upload);
            resolve();
            break;
          case TransferState.ERRORED:
            this.uploadingFiles = this.uploadingFiles.filter(t => t !== upload);
            this.loggingService.error("An error occurred while attempting to upload the file");
            reject();
            break;
          case TransferState.DONE:
            this.uploadingFiles = this.uploadingFiles.filter(t => t !== upload);
            this.sendFileUploadMessage(upload.uploadUrl).subscribe();
            resolve();
            break;
        }
      })
    });
  }

  public setAgentText($event: any) {
    this.agentText = $event ? $event : '';
  }

  startColdTransfer() {
    if (this.state === AsyncConversationState.ENGAGED) {
      this.loggingService.info(`Moving conversation '${this.id.Id}' to the cold transfer state`);
      this.state = AsyncConversationState.COLD_TRANSFER;
    } else {
      this.loggingService.error(`Attempting to move a non-engaged conversation '${this.id.Id}' to cold transfer`);
    }
  }

  startWarmTransfer() {
    if (this.state === AsyncConversationState.ENGAGED) {
      this.loggingService.info(`Moving conversation '${this.id.Id}' to the warm transfer state`);
      this.state = AsyncConversationState.WARM_TRANSFER;
      this.transferState = {
        type: EngagementState.Transfer,
        inviteState: CallInviteState.InviteSelecting,
      };
    } else {
      this.loggingService.error(`Attempting to move a non-engaged conversation '${this.id.Id}' to warm transfer`);
    }
  }

  cancelTransfer() {
    this.transferChatMessages = [];

    if (this.state === AsyncConversationState.COLD_TRANSFER) {
      this.state = AsyncConversationState.ENGAGED;
      this.loggingService.info(`Conversation '${this.id.Id}' no longer in cold transfer state`);
    } else if(this.state === AsyncConversationState.WARM_TRANSFER) {
      if (this.transferState?.inviteState === CallInviteState.InviteWaiting) {
        const operatorUsername = this.transferState.operatorUsername;
        this.loggingService.info(`Conversation '${this.id.Id}' cancelling warm transfer with '${operatorUsername}'`);
        this.transferState = {
          type: EngagementState.Transfer,
          inviteState: CallInviteState.InviteSelecting,
        };
        this.visitorService.sendWarmTransferCancel(this.id, operatorUsername)
          .subscribe(response => {
            if (response.success) {
              this.loggingService.info(`Sent transfer cancel message to '${operatorUsername}' for conversation '${this.id.Id}'`);
            } else {
              this.loggingService.error(`Failed to send transfer cancel message to '${operatorUsername}' for conversation '${this.id.Id}'`);
            }
          });
      } else if (this.transferState?.inviteState === CallInviteState.InviteRejected) {
        this.loggingService.info(`Conversation '${this.id.Id}' cancelling already rejected warm transfer with '${this.transferState.operatorUsername}'`);
        this.transferState = {
          type: EngagementState.Transfer,
          inviteState: CallInviteState.InviteSelecting,
        };
      } else {
        this.loggingService.info(`Conversation '${this.id.Id}' no longer in warm transfer state`);
        this.state = AsyncConversationState.ENGAGED;
      }
    } else {
      this.loggingService.error(`Attempting to cancel a non-transferring conversation '${this.id.Id}'`);
    }
  }

  sendWarmTransfer(inviteRequest: InviteRequest): Promise<void> {
    if (this.state === AsyncConversationState.WARM_TRANSFER && this.transferState?.inviteState === CallInviteState.InviteSelecting) {
      this.loggingService.info(`Sending warm transfer for conversation '${this.id.Id}' to '${inviteRequest.username}'`);
      this.transferState = {
        type: EngagementState.Transfer,
        inviteState: CallInviteState.InviteWaiting,
        operatorUsername: inviteRequest.username,
        operatorFullname: inviteRequest.fullname
      };
      this.transferReason = inviteRequest.message;
      return new Promise((resolve, reject) => {
        this.visitorService.sendWarmTransferRequest(this.id, inviteRequest.username, inviteRequest.message)
          .subscribe(message => {
            if (message.success) {
              this.transferState = {
                type: EngagementState.Transfer,
                inviteState: CallInviteState.InviteWaiting,
                operatorUsername: inviteRequest.username,
                operatorFullname: inviteRequest.fullname
              };
              this.loggingService.info(`Sent warm transfer for conversation ${this.id.Id} to '${inviteRequest.username}'`);
              resolve();
            } else {
              this.loggingService.error(`Failed to send warm transfer for conversation ${this.id.Id} to '${inviteRequest.username}'`);
              this.transferState = {
                type: EngagementState.Transfer,
                inviteState: CallInviteState.InviteSelecting,
              };
              reject("Failed to send warm transfer");
            }
          });
      });
    } else {
      this.loggingService.error(`Attempting to move a non-engaged conversation ${this.id.Id} to warm transfer`);
      return Promise.reject("Not in a valid state to send warm transfer");
    }
  }

  transferRejected(rejectedBy: string, reason: string) {
    if (this.state === AsyncConversationState.WARM_TRANSFER && this.transferState?.inviteState === CallInviteState.InviteWaiting) {
      if (this.transferState.operatorUsername == rejectedBy) {
        this.loggingService.info(`Warm transfer for conversation ${this.id.Id} rejected by '${rejectedBy}'`);
        this.transferState = {
          type: EngagementState.Transfer,
          inviteState: CallInviteState.InviteRejected,
          operatorFullname: this.transferState.operatorFullname,
          operatorUsername: this.transferState.operatorUsername,
          reason
        };
      } else {
        this.loggingService.error(`Warm transfer for conversation ${this.id.Id} rejected by '${rejectedBy}' which is wrong agent, expected '${this.transferState.operatorUsername}'`);
      }
    } else {
      this.loggingService.error(`Warm transfer for conversation ${this.id.Id} rejected by '${rejectedBy}' when not in warm transfer waiting state.`);
    }
  }

  transferAccepted(acceptingAgentUsername: string) {
    if (this.state === AsyncConversationState.WARM_TRANSFER && this.transferState?.inviteState === CallInviteState.InviteWaiting) {
      if (this.transferState.operatorUsername == acceptingAgentUsername) {
        this.loggingService.info(`Warm transfer accepted for conversation ${this.id.Id} by '${acceptingAgentUsername}', relinquishing the conversation to them.`);
        this.visitorService.warmTransferAsyncChat(this.id, acceptingAgentUsername, this.transferReason).subscribe();
        this.reassigned.emit();
      } else {
        this.loggingService.error(`Warm transfer accepted by wrong agent for conversation '${this.id.Id}' by '${acceptingAgentUsername}', expected '${this.transferState.operatorUsername}'.`);
      }
    } else {
      this.loggingService.error(`Got warm transfer accepted for conversation '${this.id.Id}' by '${acceptingAgentUsername}' when there is no outstanding transfer.`);
    }
  }

  onTransferChatMessage(textMessage: TextMessage) {
    this.transferChatMessages.push(textMessage)
    this.transferChatMessages = [...this.transferChatMessages];
  }

  public loadMoreHistory(): void {
    if (this.chatHistory.loading) {
      this.loggingService.debug(`Already loading a transcript.`);
    } else if (this.chatHistory.done) {
      this.loggingService.debug(`Finished loading transcript.`);
    }
    else {
      this.chatHistory.loadMore().then(items => {
        this.addHistory(this.chatHistory.history);
        this.loggingService.debug(`Loaded ${items.length} messages.`);
      });
    }
  }


  public isPrimaryAgent() {
    return this.primaryAgent.value;
  }
}
