
<ng-container class="d-flex flex-column w-100 camera-controls" *ngIf="useEnhancedAvControls">

    <p-accordion class="camera-controls-accordion w-100" (onOpen)="onAccordionGrpOpen()" styleClass="no-theme">

      <p-accordionTab  #enhancedAvControls *ngIf="zoom != null && pan != null && tilt != null">
        <ng-template pTemplate="header">
          <p>
            {{"CUSTOMERAVCONTROL_LABEL_ENHANCEDCAMERACONTROLS" | Translate : "Enhanced Controls"}}
          </p>
        </ng-template>

        <app-pan-tilt-control id="pan-tilt-control" *ngIf="zoom" [zoom]="zoom" [tilt]="tilt" [pan]="pan"
          (onPropertyChange)="onPropertyChange($event)"></app-pan-tilt-control>

        <app-camera-control *ngIf="zoom" [showSliderButtons]="true" [property]="zoom"
          (onPropertyChange)="onPropertyChange($event)"></app-camera-control>

        <p-button id="reset-btn" appDebounceClick   (onClick)="onReset($event)">{{ "CAMERACONTROLS_RESET" |Translate : 'Reset' }}</p-button>


      </p-accordionTab>

      <p-accordionTab #additionalAvControls>
        <ng-template pTemplate="header">
          <p>{{"CUSTOMERAVCONTROL_LABEL_ADDITIONALCONTROLS" | Translate : "Additional Video Controls"}}</p>
        </ng-template>

        <p class="d-flex justify-content-end w-100">{{"CUSTOMERAVCONTROL_LABEL_AUTO" | Translate : "Auto"}}</p>

        <div *ngFor="let prop of genericProperties">
          <app-camera-control class="my-2" [id]="prop.name.toLowerCase()" [property]="prop" (onPropertyChange)="onPropertyChange($event)">
          </app-camera-control>
        </div>


        <p-button  id="reset-additional-btn" appDebounceClick   (onClick)="onReset($event)">{{ "CAMERACONTROLS_RESET" |Translate : 'Reset' }}</p-button>

      </p-accordionTab>
    </p-accordion>

</ng-container>
