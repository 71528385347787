<section class="d-flex">
  <div class="flex-1">
    <input type="range" min="0" max="3" class="slider-input" id="myRange" [value]="currentPanelPosition?.size"
           (change)="onPanelSizeChange($event)">

    <div class="d-flex justify-content-between mt-2">
      <p>{{ "ENGAGEMENTCONTROL_LABEL_SMALL"  | Translate : 'Small' }}</p>
      <p>{{ "ENGAGEMENTCONTROL_LABEL_NORMAL" | Translate : 'Normal' }}</p>
      <p>{{ "ENGAGEMENTCONTROL_LABEL_BIG"    | Translate : 'Big' }}</p>
      <p>{{ "ENGAGEMENTCONTROL_LABEL_HD"    | Translate : 'HD' }}</p>
    </div>
  </div>
</section>
