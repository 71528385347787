import { EventEmitter } from "@angular/core";
import { ReplaySubject, BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { BrowserConfiguration } from "./browser-configuration";
import { ScreenshotBackendService } from "../screenshot.backend.service";
import { IBrowser } from "./IBrowser";
import { BrowserServiceStatus, IBrowserService } from "./IBrowserService";
import { IFrameBrowserProxy } from "./iframe-browser-proxy";
import { IAuthService } from "../auth-service/auth.service.interface";
import { AddressStack } from "./address-stack";
import { StringUtils } from "../../utils/string-utils";

export class ImageBrowser implements IBrowser {
    public onBrowserCreated: ReplaySubject<void> = new ReplaySubject<void>();
    public onResize: EventEmitter<void>;
    public onMessage: EventEmitter<any> = new EventEmitter<any>();

    public currentPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private iframeBrowser: IFrameBrowserProxy;

    private readonly addresses: AddressStack = new AddressStack();
    public get backEnabled() {
        return this.addresses.backEnabled;
    }

    public get forwardEnabled(): boolean {
        return this.addresses.forwardEnabled;
    }

    private currentPageImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private addressPageSub: Subscription;

    constructor(identifier: string,
            private readonly browserService: IBrowserService,
            private readonly authService: IAuthService,
            private readonly screenshotBackendService: ScreenshotBackendService)
    {
        this.iframeBrowser = new IFrameBrowserProxy(identifier, browserService);
        this.onResize = this.iframeBrowser.onResize;
        this.currentPageImage.subscribe(url => {
            const html = `<html><head></head><body><img style="width:100%;" src="${url}"></body></html>`;
            const dataUrl = `data:text/html;charset=utf-8,${encodeURI(html)}`
            this.iframeBrowser.changeUrl(dataUrl)
        });

        this.addressPageSub = this.addresses.changePage.subscribe(url => this.navigateToUrl(url));
    }

    create(options: BrowserConfiguration): void {
        const url = options.initialUrl;
        this.iframeBrowser.onBrowserCreated.pipe(first()).subscribe(() => {
            this.changeUrl(url);
            this.onBrowserCreated.next();
        });
        this.iframeBrowser.create(options);
    }

    dispose(): void {
        this.iframeBrowser.dispose();

        this.onBrowserCreated.complete();
        this.onMessage.complete();
        this.currentPage.complete();

        this.currentPageImage.complete();
        this.addressPageSub.unsubscribe();
    }

    public changeUrl(url: string): void {
        if (url && !StringUtils.urlEqual(this.currentPage.value, url)) {
            this.addresses.addPage(url);
            this.navigateToUrl(url);
        }
    }

    private navigateToUrl(url: string) {
        this.browserService.setStatus(BrowserServiceStatus.LOADING);

        this.screenshotBackendService.getScreenshot(url, 1024, 768, this.authService.currentAgent.value.authToken).subscribe(
          (imageUrl) => {
            this.currentPageImage.next(imageUrl);
            this.currentPage.next(url);
          },
          (error) => {
            this.onImageError();
          }
        );
    }

    onImageError() {
        this.browserService.setStatus(BrowserServiceStatus.FAILED);
    }

    onImageLoad() {
        this.browserService.setStatus(BrowserServiceStatus.LOADED);
    }

    show(): void {
        this.iframeBrowser.show();
    }

    hide(): void {
        this.iframeBrowser.hide();
    }

    setClientDimensions(width: number, height: number): void {
        this.iframeBrowser.setClientDimensions(width, height);
    }

    setPanelDimensions(top: number, left: number, width: number, height: number): void {
        this.iframeBrowser.setPanelDimensions(top, left, width, height);
    }

    enableEmulation(width: number, height: number, deviceScaleFactor: number): void {
        this.iframeBrowser.enableEmulation(width, height, deviceScaleFactor);
    }

    disableEmulation(): void {
        this.iframeBrowser.disableEmulation();
    }

    enable(): void {
        this.iframeBrowser.enable();
    }

    disable(): void {
        this.iframeBrowser.disable();
    }

    postMessage(msg: string, origin: string): void {
        // not implemented
    }

    sendBrowserDimensions(boundingRect: ClientRect): void {
        this.iframeBrowser.sendBrowserDimensions(boundingRect);
    }

    reload(): void {
        this.iframeBrowser.reload();
    }

    public back(): void {
        this.addresses.back();
    }

    public forward(): void {
        this.addresses.forward();
    }
}
