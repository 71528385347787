<div class="main-layout h-100 font-size-2">

  <header class="d-flex flex-row align-items-center justify-content-between gap-2">

    <p-button *ngIf="feedIsFullScreen" id="back-btn" [plain]="true" [text]="true" icon="pi pi-chevron-left" class="flex-grow-1"
              label="Back to Camera Groups" (onClick)="exitFullScreenVideo()">
    </p-button>

    <div class="pl-2 d-flex flex-column justify-content-center" *ngIf="!feedIsFullScreen">
      <p class="primary-color-text">Current Camera</p>
      <p class="lead text-truncate">{{ selectedFeed.friendlyName | titlecase }}</p>
    </div>
    <div class="d-flex flex-row gap-2">
      <p-button *ngIf="selectedFeed.feedType !== FeedType.Agent" size="large"
                (onClick)="feedIsFullScreen ? exitFullScreenVideo() : showFeedFullScreen()">
        <fa-icon [icon]="feedIsFullScreen ? faCompressArrowsAlt : faExpandArrowsAlt"></fa-icon>
      </p-button>

      <p-button appDebounceClick (onClick)="pauseFeed(!feedIsPaused)" size="large">
        <fa-icon [icon]="feedIsPaused ? faPlayCircle : faPauseCircle"></fa-icon>
      </p-button>
    </div>


  </header>
  <p-divider></p-divider>
  <div *ngIf="!feedIsFullScreen" class="d-flex flex-column w-100 flex-grow-1 align-items-center overflow-auto">
    <div *ngFor="let feed of MobilePresenterCallComponent.IMPLIED_FEEDS"
         class="d-flex flex-grow gap-2 align-items-center justify-content-between w-100 border-bottom">
      <div role="button" appDebounceClick
           class="text-truncate font-size-2 flex-1 p-4"
           [class.selected]="selectedFeed === feed"
           (click)="chooseFeed(feed.feedType, feed.feedID, feed.isBackCamera)">
        {{ feed.friendlyName | titlecase }}
      </div>
    </div>

    <div *ngFor="let feed of feeds" class="d-flex flex-grow gap-2 align-items-center justify-content-between w-100 border-bottom">
      <div role="button" appDebounceClick
           class="text-truncate font-size-2 p-4"
           [class.selected]="selectedFeed.feedID === feed.feedID"
           (click)="chooseFeed(FeedType.Fixed, feed.feedID, false)">
        {{ feed.friendlyName | titlecase }}
      </div>
    </div>
  </div>


  <div [class.d-none]="!feedIsFullScreen" class="full-screen-video-container">
    <div class="position-relative video-container">
      <div id="mainVideoContainer" class="mainVideoContainer">
      </div>

      <div id="peersContainer" class="peersContainer mb-0 position-absolute d-block">
      </div>
    </div>
  </div>

</div>

