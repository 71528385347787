import Constants from "./Constants";
import CallP2P from "./CallP2P";

export default class CallP2PAgent extends CallP2P {
    constructor({ 
            engagementId = null,
            peerId ='itsthetaste',
            iceServer = null,
            connectPeers = false,
            connectTracks = false,
            peerType = Constants.PeerType.Agent,
            mainPeerId = 'itsthetaste',
            speakerVolume = 1.0,
            mainVideoContainer = null,
            connectingOverlay = null,
            reConnectingOverlay = null,
            onHoldOverlay = null,
            peersContainer = null
        } = {}) { 

        super({ 
            engagementId,
            peerId,
            iceServer,
            connectPeers,
            connectTracks,
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });
        
        this.detachTracks = peerType => {
            
            if (this.localAudioTrack != null) {
                this.peers.removeTrack(this.localAudioTrack, peerType);
            }
            if (this.localVideoTrack != null) {
                this.peers.removeTrack(this.localVideoTrack, peerType);
            }
            
            this.peers.negotiate();
        };

        this.reAttachTracks = peerType => {
            if (this.localVideoTrack != null) {
                if (this.connectTracks && !this.audioTracksOnly) {
                    this.peers.addTrack(this.localVideoTrack,this.localStream, peerType);
                }
            }
            if (this.localAudioTrack != null) {
                if (this.connectTracks) {
                    this.peers.addTrack(this.localAudioTrack,this.localStream, peerType);
                }
            }

            this.peers.negotiate();
        };

        this.removeLocalVideoTrack = () => {
            this.localStream.getVideoTracks().forEach(track => {
                //this.peers.removeTrack(track);
                this.localStream.removeTrack(track);
                track.stop();
            });
            
            this.ui.removeLocalVideo();

            if (this.localVideoTrack != null) {
                this.localVideoTrack.stop();
                this.localVideoTrack = null;  
            }
        };

        this.addLocalVideoTrack = (options) => {
            if (!this.devices) return;            

            this.devices.getUserMedia({ video : this.devices.getVideoSettings(options) })
            .then(stream => {   
                
                this.devices.devicesAllowed()
                .then(() => {

                    this.localStream.getVideoTracks().forEach(track => {
                        this.localStream.removeTrack(track);
                        track.stop();
                    });

                    this.localVideoTrack = stream && stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                    if (this.localVideoTrack != null) {
                        this.localStream.addTrack(this.localVideoTrack);
                        if (this.connectTracks && !this.audioTracksOnly) {
                            this.peers.addTrack(this.localVideoTrack,this.localStream);   
                        }
                    }
                    
                    this.ui.addLocalTrack(this.localStream);
                    this.devices.devicesAllowed();
                });
            })
            .catch(this.handleUserMediaError); 
        };

        //console.log("Created CallAgent for:" + peerId + " of Type:" + peerType);
    }  

    // agent call this so we want to only detach the visitors
    pauseCall() {
        if (!this.connectTracks) return;
        console.log("pauseCall()");
        this.connectTracks = false;
        this.detachTracks(Constants.PeerType.Visitor);
    }

    resumeCall() {
        if (this.connectTracks) return;
        console.log("resumeCall()");
        this.connectTracks = true;
        this.reAttachTracks(Constants.PeerType.Visitor);
    }

    textChat() {
        if (!this.connectTracks) return;
        console.log("textChat()");
        this.connectTracks = false;
        this.audioTracksOnly = false;
        
        this.detachTracks(Constants.PeerType.Visitor);
    }

    audioChat() {
        console.log(`Connect tracks: ${this.connectTracks}    audio tracks only: ${this.audioTracksOnly}`);

        // If we have connected tracks and they include video just stop the video
        if (this.connectTracks && !this.audioTracksOnly) {
            this.audioTracksOnly = true;
            this.connectTracks = true;

            if (this.localVideoTrack != null) {
                this.peers.removeTrack(this.localVideoTrack, Constants.PeerType.Visitor);
            }
            
            this.peers.negotiate();
        } else {
            this.audioTracksOnly = true;
            this.connectTracks = true;
            if (this.localAudioTrack != null) {
                this.reAttachTracks(Constants.PeerType.Visitor);
            }
            else {
                this.startAudioVideo();
            }    
        }
    }

    videoChat() {
        console.log(`Connect tracks: ${this.connectTracks}    audio tracks only: ${this.audioTracksOnly}`);

        if (this.connectTracks && !this.audioTracksOnly) return;        

        if (this.connectTracks && this.audioTracksOnly && this.localVideoTrack != null) {
            // Only start the video stream up in this case
            this.connectTracks = true;
            this.audioTracksOnly = false;

            this.peers.addTrack(this.localVideoTrack, this.localStream, Constants.PeerType.Visitor);
            this.peers.negotiate();
        } else {
            this.connectTracks = true;
            this.audioTracksOnly = false;
            if (this.localVideoTrack != null && this.localAudioTrack != null) {
                this.reAttachTracks(Constants.PeerType.Visitor);
            }
            else {
                this.startAudioVideo();
            }            
        }
    }

    changeCamera(newCamera, newCameraWidth, newCameraHeight) {
        
        // check the settings are not the same as the previous settings
        var newCameraDevice = this.devices.getCamera(newCamera);
        if (newCameraDevice != null) {
            var newCameraDeviceId = newCameraDevice.deviceId;
            if (this.devices.selectedCamera 
                && newCameraDeviceId === this.devices.selectedCamera.deviceId 
                && (newCameraWidth-1) === this.devices.cameraWidth 
                && (newCameraHeight-1) === this.devices.cameraHeight) {
                console.log("Camera:" + newCamera + " width:" + newCameraWidth + " height:" + newCameraHeight + " is the same as what we have set so leave alone");
                return;
            }    
        }        

        this.removeLocalVideoTrack();

        this.devices.changeCamera(newCamera, newCameraWidth, newCameraHeight);

        this.addLocalVideoTrack();
    }

    flipCamera(options) {      
        this.removeLocalVideoTrack();
        this.addLocalVideoTrack(options);
    }
      
}