<div class="d-flex flex-column h-100 pt-3">

  <h3 class="px-2">{{ "CONVERSATION_TRANSFER_SELECT_SECTION" | Translate : 'Choose a site section:' }}</h3>

  <div class="h-100 mt-3 px-2 overflow-auto">
    <p-listbox
      styleClass="h-100 border-0"
      [listStyle]="{'max-height': '100%'}"
      [options]="(sections | async)"
      [(ngModel)]="selectedSection"
    ></p-listbox>
  </div>

  <app-engagement-transfer-message
    [transfer]="true"
    [enableSend]="selectedSection != ''"
    (sendInviteRequest)="sendTransferRequest($event)"
    (cancelInvite)="transferClose.emit()">
  </app-engagement-transfer-message>
</div>
