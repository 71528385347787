
  <div class="d-flex flex-column gap-4 h-100">
    <div class="flex-grow-1">
      <textarea
        pInputTextarea
        id="block-customer-reason"
        [(ngModel)]="reasonText"
        rows="10"
        placeholder="{{
          'BLOCKCUSTOMERMODAL_LABEL_NOTES'
            | Translate: 'Type a reason for blocking the customer here'
        }}"
        class="w-100"
        cols="100"
      ></textarea>
    </div>
    <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
      <p-button
        id="cancel-btn"
        type="button"
        [text]="true"
        severity="secondary"
        (onClick)="cancelBlockCustomer()"
      >
        {{ "BLOCKCUSTOMERMODAL_BUTTON_KEEPCHAT" | Translate: "Cancel" }}
      </p-button>
      <p-button
        id="block-btn"
        type="button"
         severity="danger"
        (onClick)="onBlockCustomer(reasonText)"
      >
        {{
          "BLOCKCUSTOMERMODAL_BUTTON_YESENDCHAT" | Translate: "Yes, block customer"
        }}
      </p-button>
    </div>
  </div>
