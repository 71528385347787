import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AlertService} from '../../services/alert-service/alert.service';
import {
  faMugHot,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-onbreak',
  templateUrl: './onbreak.component.html',
  styleUrls: ['./onbreak.component.scss']
})
export class OnbreakComponent implements OnInit {
  @Output() endBreak = new EventEmitter();

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
  }

  onEndBreak() {
    try {
      this.alertService.initSound();
      // TODO: do something in case of an error
    } catch (_) {
    }

    this.endBreak.emit();
  }

  protected readonly faMugHot = faMugHot;
}
