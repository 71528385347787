<div class="h-100 w-100 ">
  <p-tabView #vmCenterTabset
             (onChange)="onSelect($event.index)" styleClass="justified w-100">
    <p-tabPanel>
      <ng-template pTemplate="header">
          <span class="mr-1">{{ "CHAT_TAB" | Translate : "Chat" }}</span>
          <p-badge *ngIf="selectedTab !== 0 && unreadMessages > 0" [value]="unreadMessages"  severity="info"></p-badge>
      </ng-template>

      <app-engagement-text-chat
        (agentTextChange)="onAgentTextChange($event)"
        (cancelFilesTransfer)="onCancelFilesTransfer($event)"
        (loadMoreHistory)="engagement?.loadMoreHistory()"
        (sendMessage)="onSendMessage($event)"
        (setAutoReply)="handleSetAutoReply($event)"
        (setGetSuggestion)="handleSetGetSuggestion($event)"
        (setTranslation)="handleSetTranslation($event)"
        (uploadFiles)="onUploadFiles($event)"
        [agentText]=(agentText)
        [autoReplyOn]="(autoReplyOn)"
        [files]="(files)"
        [getSuggestionOn]="(getSuggestionOn)"
        [messages]="messages"
        [previewMessage]="(previewMessage)"
        [richTextContent]="richTextContent"
        [sendButtonDisabled]="sendButtonDisabled"
        [textEntryDisabled]="textEntryDisabled"
        [translationOn]="(translationOn)"
        [useLinkUnfurling]="(useLinkUnfurling)"
        [maxMessageLength]="(maxMessageLength)"
        [engagement]="engagement"
        class="engagement-chat"
      >
      </app-engagement-text-chat>
    </p-tabPanel>
    <p-tabPanel
      *ngIf="privateChatEnabled && !isClosed">
      <ng-template pTemplate="header">
        <span class="mr-1">{{ "SUPERVISOR_TAB" | Translate : "Supervisor" }}</span>
        <p-badge *ngIf="selectedTab !== 1 && unreadPrivateMessages > 0" [value]="unreadPrivateMessages"
                 severity="info"></p-badge>

      </ng-template>
      <app-engagement-private-chat-tab
        (sendPrivateMessage)="onSendPrivateChatMessage($event)"
        [privateChatMessages]="(privateChatMessages)">
      </app-engagement-private-chat-tab>
    </p-tabPanel>
    <p-tabPanel
      [disabled]="isClosed || !engagement?.isPrimaryAgent()">
      <ng-template pTemplate="header">
        <span>{{ "ENGAGEMENTPOST_HEADER_STATUS" | Translate : "Status" }}</span>
      </ng-template>
      <app-engagement-status
        (checkListName)="checkListName.emit($event)"
        (submitPost)="savePost.emit($event)"
        [(postEngagementStatus)]="engagement.postEngagementStatus"
        class="flex-grow-1 h-100 engagement-chat">
      </app-engagement-status>
    </p-tabPanel>
  </p-tabView>
</div>
