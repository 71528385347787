<div class="d-flex flex-column veestudio-opp-container px-2">
  <div class="d-flex flex-row justify-content-end position-relative">
    <p-button (onClick)="addOpportunity()"
              [disabled]="emailState?.type !== CrmEmailState.Valid"
              [ngStyle]="{'display' : isEditable ? 'block' : 'none' }"
              styleClass="btn-rectangle p-button-success center-icon">
        <fa-icon [icon]="faPlus"></fa-icon>
    </p-button>
  </div>
  <p-accordion>
    <ng-container *ngFor="let opp of opps">
      <p-accordionTab #group [selected]="true" class="mb-3">
        <ng-template pTemplate="header">
          <span>{{ opp.opportunityTitle }}</span>
        </ng-template>

        <form class="p-2">
          <p class="d-flex flex-row mb-3">
            <label class="w-25" for="leadId">{{ "ENGAGEMENTOPPORTUNITY_LABEL_LEADID" | Translate : 'Lead ID' }}</label>
            <span class="w-75" name="leadId">{{ leadId }}</span>
          </p>
          <p class="d-flex flex-row mb-3">
            <label class="w-25"
                   for="oppTitle">{{ "ENGAGEMENTOPPORTUNITY_LABEL_TITLE" | Translate : 'Opportunity Title' }}</label>
            <input [(ngModel)]="opp.opportunityTitle" [disabled]="!isEditable" class="w-75" name="oppTitle"
                   type="text" pInputText>
          </p>
          <p class="d-flex flex-row mb-3">
            <label class="w-25"
                   for="product">{{ "ENGAGEMENTOPPORTUNITY_LABEL_PRODUCT" | Translate : 'Product' }}</label>
            <input [(ngModel)]="opp.opportunityProduct" [disabled]="!isEditable" class="w-75" name="product"
                   type="text" pInputText>
          </p>
          <p class="d-flex flex-row mb-3">
            <label class="w-25"
                   for="oppStatus">{{ "ENGAGEMENTOPPORTUNITY_LABEL_STATUS" | Translate : 'Status' }}</label>
            <p-dropdown [(ngModel)]="opp.opportunityStatus" [disabled]="!isEditable" [options]="(opportunitystatus)"
                        [resetFilterOnHide]="true" [showClear]="false" class="dropdown w-75"
                        name="oppStatus"
                        optionLabel="StatusTitle"
                        optionValue="StatusId"
                        placeholder='{{ "ENGAGEMENTOPPORTUNITY_LABEL_SELECT" | Translate : "Select" }}'>
            </p-dropdown>
          </p>
          <p class="d-flex flex-row mb-3">
            <label class="w-25" for="value">{{ "ENGAGEMENTOPPORTUNITY_LABEL_VALUE" | Translate : 'Value' }}</label>
            <input pInputText [(ngModel)]="opp.opportunityValue" [disabled]="!isEditable" class="w-75" name="value"
                   type="text">
          </p>
          <p class="d-flex flex-row mb-3">
            <label class="w-25" for="notes">{{ "ENGAGEMENTOPPORTUNITY_LABEL_NOTES" | Translate : 'Notes' }}</label>
            <textarea pInputTextarea [(ngModel)]="opp.opportunityNotes" [disabled]="!isEditable" class="w-75" name="notes"
                      rows="3">
                </textarea>
          </p>
          <div class="d-flex flex-row justify-content-end gap-1">
            <p-button (onClick)="clearOppForm(opp)"
                      *ngIf="opp.state === OpportunityState.New"
                      styleClass="btn-rectangle p-button-danger center-icon"
                      type="button">
                <fa-icon [icon]="faTrash"></fa-icon>
            </p-button>
            <p-button (onClick)="saveOpportunity(opp)"
                    *ngIf="isEditable"
                    styleClass="btn-rectangle p-button-success center-icon"
                    type="button">
                <fa-icon [icon]="faSave"></fa-icon>
            </p-button>
          </div>
        </form>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</div>
