import {Component, Input} from '@angular/core';
import {faMicrophone, faShield, faStar, faVolumeUp} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faSpeakap} from "@fortawesome/free-brands-svg-icons";
import {TranslatePipe} from "../../../filters/Translate.pipe";

@Component({
  selector: 'app-engagement-join-row-status',
  templateUrl: './engagement-join-row-status.component.html',
  styleUrl: './engagement-join-row-status.component.scss'
})
export class EngagementJoinRowStatusComponent {

  protected readonly faStar = faStar;
  protected readonly faEye = faEye;
  protected readonly faShield = faShield;
  protected readonly faSpeakap = faSpeakap;
  protected readonly faMicrophone = faMicrophone;
  protected readonly faVolumeUp = faVolumeUp;

  @Input() isAgent: boolean;
  @Input() isPrimary: boolean;
  @Input() isPresenter: boolean;
  @Input() userCanSeeMe: boolean;
  @Input() userCanHearMe: boolean;
  @Input() iCanHearUser: boolean;
  @Input() canAV: boolean;

  constructor(public translate: TranslatePipe) {
  }

  getIconClass(condition: boolean): { [key: string]: boolean } {
    return {
      'surface-grey-text': condition,
      'surface-light-grey-text': !condition
    };
  }



}
