import {Component, Input, EventEmitter, Output} from '@angular/core';
import {
  faEllipsisVertical,
  faGlobe,
  faLightbulb,
  faReply,
  faVideo,
  faVideoSlash
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent {
  @Input() translationOn: boolean = false;
  @Output() setTranslation: EventEmitter<boolean> = new EventEmitter();
  @Input() translationAvailable = false;

  @Input() getSuggestionOn: boolean = false;
  @Output() setGetSuggestion: EventEmitter<boolean> = new EventEmitter();
  @Input() getSuggestionAvailable = false;

  @Input() autoReplyOn: boolean = false;
  @Output() setAutoReply: EventEmitter<boolean> = new EventEmitter();
  @Input() autoReplyAvailable = false;


  get isAvailable() {
    return this.translationAvailable || this.getSuggestionAvailable || this.autoReplyAvailable;
  }

  public toggleTranslation() {
    this.setTranslation.emit(!this.translationOn);
  }

  public toggleGetSuggestion() {
    if(this.getSuggestionOn) {
      this.setAutoReply.emit(false);
    }
    this.setGetSuggestion.emit(!this.getSuggestionOn);
  }

  public toggleAutoReply() {
    this.setAutoReply.emit(!this.autoReplyOn);
  }

  protected readonly faVideoSlash = faVideoSlash;
  protected readonly faVideo = faVideo;
  protected readonly faGlobe = faGlobe;
  protected readonly faReply = faReply;
  protected readonly faLightbulb = faLightbulb;
  protected readonly faEllipsisVertical = faEllipsisVertical;
}
