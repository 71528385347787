import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  faCircleCheck,
  faDesktop,
  faMobileScreenButton, faSpinner,
  faTabletScreenButton,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {TranslatePipe} from "../../filters/Translate.pipe";
import {VisitorDetails} from "../../classes/visitor-details";
import {OverlayPanel} from "primeng/overlaypanel";
import {EngagementVisitor, UserDeviceType, UserHeartbeatStates} from "../../services/engagement-visitor";
import {Subscription} from "rxjs";

export type IconShape = 'circle' | 'square';

@Component({
  selector: 'app-user-heartbeat',
  templateUrl: './user-heartbeat.component.html',
  styleUrl: './user-heartbeat.component.scss'
})
export class UserHeartbeatComponent implements OnInit, OnDestroy {

  @ViewChild('icon') icon: ElementRef;
  @ViewChild('op') overlayPanel: OverlayPanel;

  @Input() currentPage: string;
  @Input() engagementVisitor: EngagementVisitor;
  @Input() iconShape: IconShape = 'square';

  DEFAULT_ICON = faSpinner;

  overlayPositionLeft: number = 0;
  overlayPositionTop: number = 0;
  visitorDetails: VisitorDetails;

  private subs: Subscription[] = [];

  get visitorState() {
    return this.engagementVisitor?.state;
  }

  constructor(private translate: TranslatePipe) {
  }

  ngOnInit() {
    this.subs.push(this.engagementVisitor?.visitorDetails$.subscribe(visitorDetails => {
      this.visitorDetails = visitorDetails;
    }));
  }

  ngOnDestroy() {
    this.subs?.forEach(sub => sub?.unsubscribe());
    this.subs = [];
  }

  private updateOverlayPosition() {
    const iconRect = this.icon?.nativeElement.getBoundingClientRect();
    this.overlayPositionLeft = iconRect.left;
    this.overlayPositionTop = iconRect.top;
  }

  showOverlay($event) {
    this.updateOverlayPosition();
    this.overlayPanel.show($event);
  }

  toggleOverlay($event) {
    this.updateOverlayPosition();
    this.overlayPanel.toggle($event);
  }

  getIcon(deviceType: UserDeviceType) {
    switch (deviceType) {
      case UserDeviceType.DESKTOP:
        return faDesktop;
      case UserDeviceType.TABLET:
        return faTabletScreenButton;
      case UserDeviceType.MOBILE:
        return faMobileScreenButton;
      default:
        return this.DEFAULT_ICON;
    }
  }

  getColorClass() {
    if (this.engagementVisitor?.heartbeatState === UserHeartbeatStates.ONLINE) {
      return 'surface-green';
    } else if (this.engagementVisitor?.heartbeatState === UserHeartbeatStates.BACKGROUND) {
      return 'surface-grey';
    }

    return 'surface-red';
  }

  getTooltipText() {
    if (this.engagementVisitor?.heartbeatState === UserHeartbeatStates.ONLINE) {
      return this.translate.transform('ENGAGEMENTTOOLBAR_TITLE_USERPRESENT', 'User is there');
    } else if (this.engagementVisitor?.heartbeatState === UserHeartbeatStates.BACKGROUND) {
      return this.translate.transform('ENGAGEMENTTOOLBAR_TITLE_USERBG', 'The user has backgrounded the browser');
    }

    return this.translate.transform('ENGAGEMENTTOOLBAR_TITLE_USERNOTPRESENT', 'User is not there');
  }

  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faTimesCircle = faTimesCircle;
}
