import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Conference } from '../../classes/conference';

@Injectable({
  providedIn: 'root'
})
export class FeedBackendService {

  constructor(private http: HttpClient) { }

  public checkLocationForConference(feedLocation: string, authToken: string): Observable<Conference> {
    const url = `${environment.webinarRoot}/locationsconferencecheck`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<Conference>(
      url,
      {
        feedLocation: feedLocation
      },
      httpOptions
    );
  }
}
