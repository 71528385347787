import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { Conference } from '../../classes/conference';
import {FeedBackendService} from "./feed.backend.service";

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  public conference:Conference = null;

  constructor(
    private feedBackendService: FeedBackendService,
    private authService: AuthService
  ) { }

  public checkFeedLocation(): Observable<boolean> {
    return new Observable<boolean> (
      observer => {
        this.feedBackendService.checkLocationForConference(this.authService.currentAgent.value.feedLocation, this.authService.currentAgent.value.authToken).subscribe(
          conference => {
            this.conference = conference;
            if (conference !== null) {
              observer.next(true);
              observer.complete();
            }
            else {
              observer.next(false);
              observer.complete();
            }
          },
          err => {
            this.conference = null;
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  };

}
