export default class EventEmitter {
    constructor() {
        this.events = {};       
    }

    on(event, listener) {
        if (typeof this.events[event] !== 'object') {
            this.events[event] = [];
        }
    
        this.events[event].push(listener);
    }

    off(event, listener) {
        var idx;

        if (listener === undefined) {
            delete this.events[event];
        } else if (typeof this.events[event] === 'object') {
            idx = this.events[event].indexOf(listener);    
            if (idx > -1) {
                this.events[event].splice(idx, 1);
            }
        }
    }

    emit(event) {
        var i, listeners, length, args = [].slice.call(arguments, 1);
    
        if (typeof this.events[event] === 'object') {
            listeners = this.events[event].slice();
            length = listeners.length;
    
            for (i = 0; i < length; i++) {
                listeners[i].apply(this, args);
            }
        }
    }

}
