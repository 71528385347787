<p-tabView class="justified h-100">
  <p-tabPanel header='{{ "ENGAGEMENTPOST_HEADER_STATUS" | Translate : "Status"}}'>
    <app-engagement-status
      (checkListName)="checkListName.emit($event)"
      (submitPost)="submit($event)"
      [(postEngagementStatus)]="engagement.postEngagementStatus">
    </app-engagement-status>
  </p-tabPanel>
  <p-tabPanel class="transcript" header='{{ "ENGAGEMENTPOST_HEADER_TRANSCRIPT" | Translate : "Transcript" }}'>
    <app-chat-transcript [messages]="transcript"></app-chat-transcript>
  </p-tabPanel>
</p-tabView>
