export class AgentCalendar {
      id:number;
      agent:string;
      userName:string;
      day:string;
      startTime:string;
      endTime:string;
      closed:boolean;    
      siteOpHours: string;
      siteClosingHours: string;
      constructor(ac? : AgentCalendar) {                                
            this.id = ac && ac.id || 0,
            this.agent = ac && ac.agent || '',
            this.closed = ac && ac.closed || false,
            this.day = ac && ac.day || '' ,
            this.startTime = ac && ac.startTime,
            this.endTime = ac && ac.endTime,
            this.siteOpHours = ac && ac.siteOpHours,
            this.siteClosingHours = ac && ac.siteClosingHours,
            this.userName = ac && ac.userName
      }      
}