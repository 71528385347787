import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth-service/auth.service';
import {AlertService, AlertType} from '../services/alert-service/alert.service';
import {FeatureService, Features} from '../services/feature-service/feature.service';
import { LicenceType } from '../enums/licence-type.enum';
import { TranslatePipe } from '../filters/Translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class VeechatAuthGuard  {
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private featureService: FeatureService,
    private translate: TranslatePipe
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      return new Observable<boolean> (observer => {        
        if (!!!this.authService.currentAgent.value || !this.featureService.has(Features.VEECHAT)) {
          this.alertService.addAlert(this.translate.transform("AUTHGUARD_VEECHAT_NOT_LOGGED_IN", 'You must be logged into access that page.'), AlertType.Danger);
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          observer.next(false);
          observer.complete();
        }
        else {
          this.authService.checkLicenceType(LicenceType.VeeChat).subscribe(available => {
            if (available) {
              observer.next(true);
              observer.complete();          
            }
            else {
              this.alertService.addAlert(this.translate.transform("AUTHGUARD_VEECHAT_TOO_MANY_LICENCES_IN_USE", 'There are too many VeeChat licences already in use.'), AlertType.Danger);
              observer.next(false);
              observer.complete();
            }          
          });
        }  
      });      
  }
}
