import {CrmField} from '../../../services/crm-service/crm-field';
import { Component, Input } from '@angular/core';
import {FormGroup } from '@angular/forms';
import {StringUtils} from '../../../utils/string-utils';

@Component({
  selector: 'app-appointment-type-form',
  templateUrl: './appointment-type-form.component.html',
  styleUrl: './appointment-type-form.component.scss'
})
export class AppointmentTypeFormComponent {
  @Input() public formFields: CrmField[] = [];
  @Input() public dynamicFormGroup: FormGroup;

  protected readonly CrmField = CrmField;
  protected readonly StringUtils = StringUtils;
}
