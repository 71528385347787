
export default class CandidateFilter {
    constructor() {}

    static noFilter(){
        return true;
    }
    
    static isRelay(candidate) {
        return candidate.type === "relay";
    }
    
    static isNotHostCandidate(candidate) {
        return candidate.type !== "host";
    }
    
    static isReflexive(candidate) {
        return candidate.type === "srflx";
    }
    
    static isHost(candidate) {
        return candidate.type === "host";
    }
    
    static isIpv6(candidate) {
        return candidate.address.indexOf(":") !== -1;
    }
}