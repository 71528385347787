<div class="d-flex flex-column gap-4 px-2">
  <div>
    <table class="table table-borderless table-striped table-hover text-center" id="agentCalendar">
      <thead>
      <th></th>
      <th>{{ "AGENTCALENDAR_COLUMN_STARTTIME" | Translate : 'Start Time' }}</th>
      <th>{{ "AGENTCALENDAR_COLUMN_ENDTIME" | Translate : 'End Time' }}</th>
      <th>{{ "AGENTCALENDAR_COLUMN_LABEL_UNAVAILABLE" | Translate : 'Unavailable' }}</th>
      <th></th>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>

  <div>
    <h3 class="primary-color-text">
      {{ "AGENTCALENDAR_LABEL_OTHERCALENDARS" | Translate : 'Other Calendars' }}
    </h3>
    <div *ngFor="let element of otherAgentsCalendar.value | keyvalue;" class="text-left pt-4 px-2">
      <p class="text-truncate lead">
        {{ element.key }}
      </p>
      <div *ngFor="let slots of element.value; let i = index;" class="text-left ml-3 mt-1">
        <div class="d-flex">
        <span class="m-1 col-lg-2 day-{{slots.day}}">
           {{ this.createReadOnlyDayElementContent(slots.day, element.value[i - 1]) }}
        </span>
          <span class='m-1 col-lg-2'>
          {{ this.createReadOnlyTimeElementContent(slots.startTime, slots.endTime, slots.closed) }}
        </span>
        </div>
      </div>
      <hr/>
    </div>
  </div>

</div>
