import EventEmitter from "../EventEmitter";

const KEY_INPUT_EVENT = 'MOBILE_KEY';
const TEXT_INPUT_EVENT = 'MOBILE_TEXT';

const DEFAULT_ENTRY_VAL = '_';

const SHOW_KEYBOARD_TEXT = 'Show Keyboard';

const SHOW_KEYBOARD_CSS_CLASS_NAME = "webrtc-sharing-show-keyboard";
const HIDDEN_INPUT_FIELD_CLASS_NAME = "webrtc-sharing-hidden-text-input";
const HIDE_CSS_CLASS_NAME = "hidden";

const STATES = Object.freeze({
    Disabled: 0,
    Invisible: 1,
    Focused: 2,
    KeyboardOpen: 3,
    BlurWait: 4
});

export default class MobileKeyboardCapturer extends EventEmitter {
    constructor(containerElement, browser) {
        super();

        if (containerElement == null) {
            throw new Error("Container element given is falsy");
        }

        this.element = containerElement;

        this.isAndroid = browser.browser == "chrome android";
        
        this.keyboardButton = document.createElement('div');
        this.keyboardButton.appendChild(document.createTextNode(SHOW_KEYBOARD_TEXT));
        this.keyboardButton.addEventListener('click', (ev) => this.openTextEntry());
        this.keyboardButton.classList.add(SHOW_KEYBOARD_CSS_CLASS_NAME);
        this.keyboardButton.classList.add(HIDE_CSS_CLASS_NAME);

        this.element.appendChild(this.keyboardButton);

        this.textEntry = document.createElement('input');
        this.textEntry.classList.add(HIDDEN_INPUT_FIELD_CLASS_NAME);
        this.textEntry.type = 'text';

        document.body.appendChild(this.textEntry);

        this.debug = false;
        this.disposed = false;

        this.blurCancelled = false;

        this.state = STATES.Invisible;

        this.textEntry.value = DEFAULT_ENTRY_VAL;

        this.keyHandler = (ev) => this.handleKeyPress(ev);
        this.textEntry.addEventListener("input", this.keyHandler);

        this.handleKeyPress = (ev) => {
            try {
                const text = this.textEntry.value;
        
                /* Either text has been removed or it has been added.
                 * We trigger every input event so in theory we should only ever see one element,
                 * we just send all the text so not to have worry about multibyte utf8 chars or 
                 * any other possible problems.
                 */
                if (0 == text.length) {
                    this.emitBackspaceEvent();
                } else {
                    this.emitTextEvent(text.substr(1));
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.textEntry.value = DEFAULT_ENTRY_VAL;
                 // Android doesn't like having the selection set in the input handler
                 // Technically, this could cause a problem (I'd have to look up how microtasks work again) but I haven't seen it.
                setTimeout(() => this.textEntry.setSelectionRange(1,1), 0);
            }
        }

        this.emitBackspaceEvent = () => {
            if (this.state != STATES.Disabled)
                this.emit(KEY_INPUT_EVENT, { specialKey: false, key: "Backspace", flags: 0 });
        };
        
        this.emitTextEvent = (text) => {
            if (this.state != STATES.Disabled)
                this.emit(TEXT_INPUT_EVENT, { text: text });
        };
    }

    disabled() {
        this.hideKeyboardEntry();
        this.closeTextEntry();
        this.state = STATES.Disabled;
    }

    invisible() {
        this.hideKeyboardEntry();
        this.closeTextEntry();
        
        this.state = STATES.Invisible;
    }

    focused() {
        this.showKeyboardEntry();
        this.state = STATES.Focused;
    }

    keyboardOpen() {
        this.hideKeyboardEntry();
        this.openTextEntry();
        this.state = STATES.KeyboardOpen;
    }

    blurWait() {
        // Wait a small period before bluring. This is due to an implementation difference
        // between using CEF node changes and the raw blur/focus events the browser gives (in electron).
        // In the case of the blur/focus events selecting a new item gives blur -> focus, adding in a small
        // delay eases that transition.
        const SHARING_FOCUS_WAIT_TIME = 100;
        setTimeout(() => {
            if (!this.blurCancelled) {
                this.invisible();
            } else {
                this.keyboardOpen();
            }
        }, SHARING_FOCUS_WAIT_TIME);

        this.blurCancelled = false;
        this.state = STATES.BlurWait;
    }

    enable() {
        this.invisible();
    }

    disable() {
        this.disabled();
    }
    
    focus() {
        switch (this.state) {
            case STATES.Invisible:
                if (this.isAndroid) {
                    this.keyboardOpen();
                } else {
                    this.focused();
                }
                break;
            case STATES.BlurWait:
                this.blurCancelled = true;
                break;
        }
    }

    touched() {
        switch (this.state) {
            case STATES.Focused:
                this.keyboardOpen();
                break;
            case STATES.KeyboardOpen:
                if (this.isAndroid) {
                    // Android needs a small delay otherwise where the focus ends up can be a bit random
                    // Either on the input field or the video display. Adding a small non-zero delay seems to fix it.
                    setTimeout(() => {
                        this.keyboardOpen();
                    }, 10);
                } else {
                    this.keyboardOpen();
                }
                break;
        }
    }

    blur() {
        switch (this.state) {
            case STATES.Focused:
                this.invisible();
                break;
            case STATES.KeyboardOpen:
                this.blurWait();
                break;
        }
    }

    showKeyboardEntry() {
        this.keyboardButton.classList.remove(HIDE_CSS_CLASS_NAME);
    }

    hideKeyboardEntry() {
        this.keyboardButton.classList.add(HIDE_CSS_CLASS_NAME);
    }

    openTextEntry() {
        if (this.state == STATES.Disabled)
            return;
            
        // Set focus to input field so that we get the keyboard popup
        this.textEntry.focus();
        this.textEntry.setSelectionRange(1,1);
    }

    closeTextEntry() {
        // Remove focus from input field
        this.textEntry.blur();
    }

    dispose() {
        this.off(KEY_INPUT_EVENT);
        this.off(TEXT_INPUT_EVENT);

        if (this.textEntry.parentNode) {
            this.textEntry.parentNode.removeChild(this.textEntry);
        }

        this.disposed = true;
    }

    setDebug(enabled) {
        this.debug = enabled;
    }
};
