import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EngagementAssetPlaylist, EngagementAsset, EngagementAssetType } from '../../classes/EngagementAssets';
import { SettingsService } from '../../services/settings-service/settings.service';
import { UntypedFormControl } from '@angular/forms';
import {Dropdown} from "primeng/dropdown";

export interface AssetDropDownEntry {
  id: number;
  title: string;
  engagementAsset: EngagementAsset;
}

export interface EngagementAssetPlaylistDropDown {
  id: number;
  title: string;
  items: AssetDropDownEntry[];
}

@Component({
  selector: 'app-engagement-assets',
  templateUrl: './engagement-assets.component.html',
  styleUrls: ['./engagement-assets.component.scss']
})
export class EngagementAssetsComponent implements OnInit {
  private static readonly EMPTY_PLAYLIST: EngagementAssetPlaylist = {
    Title: '',
    Assets: [],
  };
  @ViewChild('assetsDropdown', {static: false}) assetsDropdown: Dropdown;

  public get disabled(): boolean {
    return this.formControl.disabled;
  }
  @Input() public set disabled(value: boolean) {
    if (value) {
      this.formControl.disable({emitEvent: false});
    } else {
      this.formControl.enable({emitEvent: false});
    }
  }

  @Output() selectedAsset = new EventEmitter<EngagementAsset>();

  public formControl = new UntypedFormControl();
  private engagementAssetPlaylists: EngagementAssetPlaylist[] = [];
  public engagementAssetPlaylistDropDown: EngagementAssetPlaylistDropDown[] = [];
  public selectedPlaylist: EngagementAssetPlaylist = EngagementAssetsComponent.EMPTY_PLAYLIST;

  constructor(
    private settingsService: SettingsService
  ) { }

  ngOnInit() {

    this.settingsService.engagementAssets.subscribe(engagementAssetPlaylists => {
      this.engagementAssetPlaylists = engagementAssetPlaylists;

      this.engagementAssetPlaylistDropDown = this.engagementAssetPlaylists.map((engagementAsset, parentid) => {
        const asset: AssetDropDownEntry[] = engagementAsset.Assets.map((asset, childid) => {
          return {
            id: (parentid << 8) + childid,
            title: asset.Title,
            engagementAsset: asset
          };
        });

        const engagementAssetPlaylist: EngagementAssetPlaylistDropDown = {
          id: parentid,
          title: engagementAsset.Title,
          items: asset
        };
        return engagementAssetPlaylist;
      });
    });
  }

  selectPlaylist(newPlaylist: EngagementAssetPlaylist) {
    this.selectedPlaylist = newPlaylist;
  }

  selectAsset($event) {
      const parentId = $event.value >> 8;
      const childId = $event.value & 0xff;

      const parent = this.engagementAssetPlaylistDropDown[parentId];
      const child = parent.items[childId];
      this.selectedAsset.emit(child.engagementAsset);
      this.reset();
  }

  // prevent selected asset value from remaining.  This needs to be done properly so that
  // state is maintained between engagements
  // page is tracked via browser back / forward buttons
  // page is tracked in multiparty
  reset() {
    this.formControl.setValue(null);
  }
}
