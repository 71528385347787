import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { TranslatePipe } from '../../../filters/Translate.pipe';
import { UntypedFormControl } from '@angular/forms';


interface TypeaheadItem {
  id: number,
  text: string,
}


@Component({
  selector: 'app-crm-field-typeahead',
  templateUrl: './crm-field-typeahead.component.html',
  styleUrls: ['./crm-field-typeahead.component.scss'],
  providers: [TranslatePipe]
})
export class CrmFieldTypeaheadComponent {

  public name: string;
  private _field: CrmField;
  public choices: Array<any> = [];
  public filteredChoices: Array<any> = [];

  get value() {
    return this._field?.value;
  }

  @Input() set field(field: CrmField) {
    this._field = field;

    const choices = [ this.createFirstChoice()];
    field.choices.forEach((text, id) => choices.push({id, text}))
    this.choices = choices;
    this.name = CrmFieldTypeaheadComponent.getName(field.name);
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  public selectControl = new UntypedFormControl();

  constructor(private translate: TranslatePipe) {
    this.choices = [ this.createFirstChoice() ];
  }

  public createFirstChoice(): any {
    return {
      id: -1,
      text: this.translate.transform("CRMSEARCH_PLACEHOLDER_SELECT", 'Select...')
    };
  }



  onSelect(event:TypeaheadItem): void {
    if (event?.id > -1) {
      this.field.value = event.text;
    } else {
      this.field.value = '';
    }
    this.saveField.emit();
  }


  public getFieldPlaceholder(fldValue: string) {
    if (fldValue && fldValue.length > 0) {
      return fldValue;
    }
    else {
      return this.translate.transform("CRMSEARCH_PLACEHOLDER_SELECT", 'Select...');
    }
  }

  private static getName(fieldName: string): string {
    return fieldName.replace(/([A-Z]+)/g, ' $1').trim();
  }


  onComplete(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.choices.length; i++) {
      let choice = this.choices[i];
      if (choice.text.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(choice);
      }
    }

    this.filteredChoices = filtered;
  }
}
