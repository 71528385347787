import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation } from './translation';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationBackendService {

  constructor(private http: HttpClient) { }

  public translate(text :string, from: string, to: string, authToken :string) : Observable<Translation>{
    const url = `${environment.translationRoot}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<Translation>(
      url,
      { from, to, text, version: '0.0.0'},
      httpOptions
    );

  }

}
