import { Injectable } from '@angular/core';
import { InviteBackendService } from './invite-backend.service';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { LoggingService } from '../logging.service';
import { EmailTemplate } from '../../classes/email/emailtemplate';
import { first, map } from 'rxjs/operators';

export interface IInviteService {
  sendSMS(to: string, body: string):Observable<boolean>;
  sendEmail(recipient: string, message: string, subject: string):Observable<boolean>;
  loadInviteTemplate(): Observable<EmailTemplate>;
}

@Injectable({
  providedIn: 'root'
})
export class InviteService implements IInviteService {

  constructor(
    private inviteBackendService: InviteBackendService,
    private authService: AuthService,
    private logger: LoggingService,
  ) { }

  public sendSMS(to: string, body: string):Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.inviteBackendService.sendSMS(this.authService.currentAgent.value.authToken, to, body).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error sending sms', err);
            observer.next(false);
            observer.complete();
          }

        );
      }
    );
  }


  public sendEmail(recipient: string, message: string, subject: string):Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        const sitename = this.authService.currentAgent.value.sitename;
        const token = this.authService.currentAgent.value.authToken;
        this.inviteBackendService.sendEmail(sitename, recipient, message, subject, token).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error sending email', err);
            observer.next(false);
            observer.complete();
          }

        );
      }
    );
  }

  public loadInviteTemplate(): Observable<EmailTemplate> {
    return this.inviteBackendService
               .loadInviteTemplates(this.authService.currentAgent.value.authToken)
               .pipe(map(invites => (invites && invites.length) ? invites[0] : null), first());
  }

}
