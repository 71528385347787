
import Connection from "../Connection";
import Constants from "../Constants";

export default class EdgeConnection extends Connection {
    constructor(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, direction, candidateFilter = CandidateFilter.noFilter, signalType = Constants.SignalType.AV) {
        super(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, direction, candidateFilter, signalType);

        console.log('Created ' + direction + ' EDGE RTCPeerConnnection to:' + this.peerId); 
    } 

    addTrack(track, stream){
        
        //console.log("EdgeConnection addTrack");

        var transceiver = null;
        // check if we have a real transceiver
        if (typeof this.rtc.transceivers !== 'undefined') { // check if we can use auto added transceiver
            this.rtc.transceivers.forEach(t => {
                if (t.track.kind === track.kind) {
                    transceiver = t;
                }
            });
        }
        
        if (transceiver) {
            transceiver.direction = "sendonly";
            transceiver.rtpSender.replaceTrack(track);
        }
        else {
            // otherwise add the track to the peer connection
            this.rtc.addTrack(track, stream);
        }
        
        // this.emit("Connection:signal", {
        //     from: this.myPeerId,
        //     to: this.peerId,
        //     direction: this.direction,
        //     type: "avEvent",
        //     avType: track.kind,
        //     enabled: true
        // });
    }

    removeTrack(track) {

        //console.log("EdgeConnection removeTrack");
       
        var transceiver = null;
        // check if we have a real transceiver
        if (typeof this.rtc.transceivers !== 'undefined') { // check if we can use auto added transceiver
            this.rtc.transceivers.forEach(t => {
                if (t.track === track) {
                    transceiver = t;
                }
            });
        }

        if (transceiver) {
            transceiver.direction = "inactive";
            //transceiver.rtpSender.replaceTrack(null);
        }
        else {
            var senders = this.rtc.getSenders();
            for (var j = 0; j < senders.length; ++j) {
                var sender = senders[j];
                if (sender.track === track) {
                    this.rtc.removeTrack(sender);
                }
            }
        }
    
        // this.emit("Connection:signal", {
        //     from: this.myPeerId,
        //     to: this.peerId,
        //     direction: this.direction,
        //     type: "avEvent",
        //     avType: track.kind,
        //     enabled: false
        // });

    }
    
}
    