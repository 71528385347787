<ng-container  *ngIf="(currentState$ | async) === OnlineState.OnBreak; then onbreak else crm">
</ng-container>

<ng-template #onbreak>
  <app-onbreak (endBreak)="onEndBreak()" class="h-100 w-100"></app-onbreak>
</ng-template>

<ng-template #crm>
  <div class="d-flex flex-column flex-grow-1 main-layout">
    <header class="d-flex flex-row w-100 align-items-start justify-content-between">
      <h2 class="primary-color-text pt-2 pl-2">{{ "CRMSEARCH_LABEL_SEARCHDB" | Translate :  'Search' }}</h2>
      <p-button styleClass="p-button-text p-button-lg p-button-plain"
              *ngIf="canCloseCrm"
              (onClick)="closeCrm()">
          <fa-icon [icon]="faTimes"></fa-icon>
      </p-button>
    </header>

    <app-crm class="h-100 overflow-hidden" (crmStateChange)="onCrmStateChange($event)"></app-crm>
  </div>
</ng-template>
