<div class="d-flex flex-row gap-1 align-items-center h-100 bottom-ctrls">
  <p-button appDebounceClick severity="secondary"
    styleClass="btn-bottom-control center-icon" id="supervisor-button" *ngIf="supervisorModeAvailable"
    pTooltip='{{ "CHAT_TOOLTIP_MODE"  | Translate : "Supervisor Mode" }}'
    (clicked)="supervisorMode()">
      <fa-icon [icon]="faUserTie"></fa-icon>
  </p-button>
  <p-button appDebounceClick id="crm-button" severity="secondary"
    styleClass="btn-bottom-control center-icon" [ngClass]="{'d-none': !crmAvailable}"
    pTooltip='{{ "CHAT_TOOLTIP_CRM"  | Translate : "CRM" }}'
    (clicked)="goToCrm()">
      <fa-icon [icon]="faAddressBook"></fa-icon>
  </p-button>
</div>
