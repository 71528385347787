export enum StatusFlag {
    None = 0,
    MouseMoving = 1,
    RequiresAssistance = 2,
    IsWaiting = 4,
    OnCallback = 8,
    ViewingANudge = 16,
    IsEngaged = 32,
    IsMultichat = 64,
    Unused7 = 128,
    unused8 = 256,
    Unused9 = 512,
    Bot = 1024,
    BotEscalation = 2048,
    Unused12 = 4096,
    PageInvisible = 8192,
    Unused14 = 16384,
    Unused15 = 32768,
    AsyncChat = 1 << 20,
}
