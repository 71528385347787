import {Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {OnlineState} from '../../enums/online-state.enum';
import {CallInviteState, EngagementState, InviteStateType, EngagementAgent} from '../../services/engagement';
import {AgentStatus} from '../../classes/visitor/AgentStatus';
import { TranslatePipe } from '../../filters/Translate.pipe'
import {InviteRequest} from '../engagement-transfer-select/engagement-transfer-select.component';
import {TextMessage} from '../../classes/TextMessage';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: Array<any>, field: string): any {

    if (value == null || value.length <= 1) {
      return value;
    }

    if (field.startsWith('-')) {
      field = field.substring(1);
      if (typeof this.getValue(value[0])[field] === 'string' || this.getValue(value[0])[field] instanceof String) {
        return [...value].sort((a, b) => this.getValue(b)[field].localeCompare(this.getValue(a)[field]));
      }
      return [...value].sort((a, b) => this.getValue(b)[field] - this.getValue(a)[field]);
    }
    else {
      if (typeof this.getValue(value[0])[field] === 'string' || this.getValue(value[0])[field] instanceof String) {
        return [...value].sort((a, b) => -this.getValue(b)[field].localeCompare(this.getValue(a)[field]));
      }
      return [...value].sort((a, b) => this.getValue(a)[field] - this.getValue(b)[field]);
    }
  }

  private getValue(val) {
    return (val.value) ? val.value : val;
  }
}

@Pipe({
  name: 'agentFilter',
  pure: false
})
export class AgentFilterPipe implements PipeTransform {
  transform(agents: Map<string, AgentStatus>, isVeestudio: boolean, selectedDept: string, roomAgents: ReadonlyMap<string, EngagementAgent>):  Map<string, AgentStatus> {
    const filtered: Map<string, AgentStatus> = new Map();
    const requiredStatus = isVeestudio ? OnlineState.Available : OnlineState.MultiChat;

    if (selectedDept?.length > 0) {
      for (const [username, info] of agents.entries()) {
        if (info.status === requiredStatus && !roomAgents.has(username) && info.groups.some(group => group.Name === selectedDept)) {
            filtered.set(username, info);
        }
      }
    } else {
      for (const [username, info] of agents.entries()) {
        if (info.status === requiredStatus && !roomAgents.has(username)) {
          filtered.set(username, info);
        }
      }
    }

    return filtered;
  }

}

@Component({
  selector: 'app-engagement-transfer',
  templateUrl: './engagement-transfer.component.html',
  styleUrls: ['./engagement-transfer.component.scss'],
  providers:[TranslatePipe]
})
export class EngagementTransferComponent implements OnInit {
  @Input() currentState: InviteStateType;
  @Input() transfer: boolean;
  @Input() crossSessionTransfer: boolean = true;
  @Input() roomAgents: ReadonlyMap<string, EngagementAgent> = new Map([]);
  @Input() roomId: string;
  @Input() textMessages: TextMessage[] = [];

  @Output() cancelInvite = new EventEmitter<void>();
  @Output() inviteRequest = new EventEmitter<InviteRequest>();

  CallInviteState = CallInviteState; // export state for UI template
  EngagementState = EngagementState; // export state for UI template

  public currentEngagementStateText = '';

  constructor(
    private translate: TranslatePipe
  ) {
  }

  ngOnInit() {
    if (this.currentState.type === EngagementState.Join) {
      this.currentEngagementStateText = this.translate.transform("ENGAGEMENTTRANSFER_STATETEXT_JOIN",'Join') ;
    } else if (this.currentState.type === EngagementState.Transfer) {
      this.currentEngagementStateText = this.translate.transform("ENGAGEMENTTRANSFER_HEADER_TRANSFER",'Transfer');
    }
  }
}
