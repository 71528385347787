<nav *ngIf="currentAgent" class="navbar navbar-expand ">
  <app-info-bar [title]="title"></app-info-bar>
  <div class="navbar-nav ml-auto mr-1">
    <div class="d-flex flex-row align-items-center mr-2">
      <p>{{ currentAgent.nickname }}</p>
      <span
        [class.surface-green]=" (currentState$ | async) === OnlineState.MultiChat || (currentState$ | async) === OnlineState.Available"
        [class.surface-red]="(currentState$ | async) !== OnlineState.MultiChat && (currentState$ | async) !== OnlineState.Available"
        [class.surface-grey]="(noInternet | async)"
        class="agent-status rounded-circle m-2"
        pTooltip="{{convertStatusIconLabel(currentState$ | async, noInternet | async)}}"></span>
      <p-button (onClick)="menu.toggle($event)" size="small"
                [disabled]="(isActiveEngagement | async) || (isActiveTransfer | async) || (isActiveJoin | async) || (isActiveHelpRequest | async)"
                styleClass="agent-menu-toggle p-button-secondary ml-auto" [text]="true"
                type="button">
        <fa-icon [icon]="faBars"></fa-icon>
      </p-button>
    </div>


    <p-menu #menu [model]="items" [popup]="true"></p-menu>
  </div>
</nav>

