import {BehaviorSubject, Subscription} from 'rxjs';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';

export enum FileTransferState {
  IDLE,
  STARTING,
  UPLOADING,
  CANCELLED,
  ERRORED,
  DONE,
}

export interface AzureResponse {
  name: string;
  path: string;
  success: boolean;
}

export class TransferFile {
  private _currentState = FileTransferState.IDLE;
  private set currentState(newState: FileTransferState) {
    this._currentState = newState;
    this.state$.next(newState);

    switch (newState) {
      case FileTransferState.DONE:
      case FileTransferState.ERRORED:
      case FileTransferState.CANCELLED:
        this.state$.complete();
        break;
      default:
        break;
    }
  }
  private get currentState(): FileTransferState {
    return this._currentState;
  }

  public state$ = new BehaviorSubject<FileTransferState>(this.currentState);
  public progress$ = new BehaviorSubject<number>(0);

  private _uploadUrl: string = null;
  public get uploadUrl(): string {
    return this._uploadUrl;
  }

  public get filename(): string {
    return this.file.name;
  }

  private request: Subscription;

  constructor(protected http: HttpClient,
              protected endpointUrl: string,
              protected file: File) {
  }

  public start(): boolean {
    switch (this.currentState) {
      case FileTransferState.IDLE:
        this.currentState = FileTransferState.STARTING;
        if (this.startFileTransfer()) {
          return true;
        } else {
          this.setStateErrored();
          return false;
        }
        break;
      default:
        return false;
    }
  }

  public abort(): boolean {
    switch (this.currentState) {
      case FileTransferState.UPLOADING:
        this.request.unsubscribe();
        this.deleteCancelledUpload();
        this.currentState = FileTransferState.CANCELLED;
        return true;
      default:
        return false;
    }
  }

  protected startFileTransfer(): boolean {
    throw new Error('The startFileTransfer method is defined by the implementation');
  }

  private onStart() {
    this.currentState = FileTransferState.UPLOADING;
  }

  private onError(err?: string) {
    switch (this.currentState) {
      case FileTransferState.DONE:
      case FileTransferState.ERRORED:
      case FileTransferState.CANCELLED:
        return;
    }

    this.request.unsubscribe();
    this.setStateErrored();
  }

  private setStateErrored() {
    this.currentState = FileTransferState.ERRORED;
  }

  protected deleteCancelledUpload() {
    throw new Error('The deleteCancelledUpload method is defined by the implementation');
  }

  private onProgress(lengthComputable: boolean, loaded: number, total: number) {
    if (FileTransferState.UPLOADING) {
      if (lengthComputable) {
        const ratio = loaded / total;
        this.progress$.next(ratio);
      }
    }
  }

  private onComplete(response: AzureResponse) {
    switch (this.currentState) {
      case FileTransferState.DONE:
      case FileTransferState.ERRORED:
      case FileTransferState.CANCELLED:
        return;
    }

    if (response) {
      try {
        if (response.success) {
          this._uploadUrl = response.path;
          this.currentState = FileTransferState.DONE;
        } else {
          this.currentState = FileTransferState.ERRORED;
        }
      } catch (err) {
        this.currentState = FileTransferState.ERRORED;
      }
    } else {
      this.currentState = FileTransferState.ERRORED;
    }

    this.progress$.complete();
  }

  protected sendData(headers: Map<string, string>, url: string, formData: FormData) {
    let httpHeaders = new HttpHeaders(); // HttpHeaders is immutable
    for (const [header, value] of headers) {
      httpHeaders = httpHeaders.append(header, value);
    }

    const req: HttpRequest<FormData> = new HttpRequest<FormData>('POST', url, formData, {
      headers: httpHeaders,
      reportProgress: true,
    });

    this.request = this.http.request<AzureResponse>(req)
      .subscribe(
        ev => this.handleHttpEvent(ev),
        err => this.onError(err),
      );
  }

  private handleHttpEvent(ev: HttpEvent<AzureResponse>) {
    switch (ev.type) {
      /**
       * The request was sent out over the wire.
       */
      case HttpEventType.Sent:
        this.onStart();
        break;
      /**
       * An upload progress event was received.
       */
      case HttpEventType.UploadProgress:
        this.onProgress(ev.total !== 0, ev.loaded, ev.total);
        /**
         * The response status code and headers were received.
         */
        break;
      case HttpEventType.ResponseHeader:
        /**
         * The full response including the body was received.
         */
        break;
      case HttpEventType.Response:
        if (ev.status === 200) {
          const response = ev as HttpResponse<AzureResponse>;
          this.onComplete(response.body);
        }
        break;
      default:
        break;
    }
  }
}
