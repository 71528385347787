
import EventEmitter from "./EventEmitter";
import Peer from "./Peer";
import ShareUIManager from "./ShareUIManager";
import CandidateFilter from "./CandidateFilter";
import Constants from "./Constants";
import { TrackCropper } from "./screensharing/TrackCropper";

export default class ShareManager extends EventEmitter {
    constructor({
            engagementId = null,
            peerId ='itsthetaste',
            iceServer = null,
            peerType = Constants.PeerType.Agent,
            connectingOverlay = null,
            reConnectingOverlay = null,
            onHoldOverlay = null,
            shareContainer = null
        } = {}) {

            super();

            this.engagementId = engagementId;
            this.iceServer = iceServer;
            this.myPeerType = peerType;
            this.myPeerId = peerId;

            this.shareContainer     = shareContainer;
            this.peers               = [];
            this.localShareTrack    = null;                                // MediaStreamTrack
            this.localShareStream   = new MediaStream();                   // MediaStream

            this.ui                 = new ShareUIManager(peerId, peerType, shareContainer, connectingOverlay, reConnectingOverlay, onHoldOverlay);

            this.isPaused = false;

            this.SHAREMANAGER_SHARING_CHANNEL_MESSAGE = "ShareManager:sharingChannelMessage";

            this.detachTracks = peerType => {
                if(this.localShareTrack){
                    this.removeTracks(this.localShareTrack, peerType);
                    this.negotiate();
                }
            };

            this.negotiate = () => {
                for(var i=0; i < this.peers.length; i++){
                    var peer = this.peers[i];
                    if(peer.outgoingConnection){
                        peer.outgoingConnection.negotiate();
                    }
                }
            }

            this.reAttachTracks = peerType => {
                if(this.localShareTrack){
                    this.addTracks(this.localShareTrack, this.localShareStream, peerType);
                }
            };

            this.outgoingShareConnectionReadyEvent = (connection) => {
                // add all the current tracks to this connection
                if (this.localShareStream != null) {
                    var localTracks = this.localShareStream.getTracks();
                    if (localTracks.length > 0) {
                        // remove any previous attached tracks
                        for (var i = 0; i < localTracks.length; ++i) {
                            connection.removeTrack(localTracks[i]);
                        }
                        // then readd them
                        for (var i = 0; i < localTracks.length; ++i) {
                            connection.addTrack(localTracks[i], this.localShareStream);

                        }
                        connection.negotiate();
                    }
                }
            };

            this.shareSignal = s => {
                this.emit("ShareManager:signal", s);
            }

            this.peerShareTrackEvent = message => {
                this.ui.addPeerTracks(message);
            };

            this.peerShareStateChangeEvent = message => {
                if(this.ui){
                    if (message.connectionState === "failed" || message.connectionState === "disconnected" || message.connectionState === "closed") {
                        if (message.direction === Constants.Direction.In) {
                            this.ui.failed();
                            console.error("share failed");
                        }
                    }
                    else if (message.connectionState === "new" || message.connectionState === "checking") {
                        if (message.direction === Constants.Direction.In) {
                            this.ui.connecting();
                        }
                    }
                    else if (message.connectionState === "completed" || message.connectionState === "connected") {
                        if (message.direction === Constants.Direction.In) {
                            this.ui.connected();
                        }
                    }else{
                        console.log("PEER STATE CHANGE EVENT", message);
                    }
                }

            };

            this.screensharingMessageEvent = message => this.emit(this.SHAREMANAGER_SHARING_CHANNEL_MESSAGE, message);

            this.handleGetDisplayMediaError = e => {
                this.emit("ShareManager:onError", e);
            };

            this.connectOutgoing = (peerId) => {
                var peer = this.get(peerId);

                if (peer) {
                    // detach the event listeners
                    if (peer.outgoingConnection) {
                        peer.outgoingConnection.off("Connection:signal", this.shareSignal);
                        peer.outgoingConnection.off("Connection:stateChange", this.peerShareStateChangeEvent);
                        peer.outgoingConnection.off(peer.outgoingConnection.SHARING_CHANNEL_MESSAGE, this.screensharingMessageEvent);

                        // close the connection
                        clearInterval(peer.outgoingConnection.incomingConnectionStateCheckInterval);
                        if (peer.outgoingConnection.rtc.signalingState !== "closed") {
                            peer.outgoingConnection.rtc.close();
                            peer.outgoingConnection.rtc = null;
                        }
                        peer.outgoingConnection = null;

                    }
                    // make a new connection
                    peer.connectOutgoing(this.engagementId, this.myPeerId, this.iceServer);
                    // add the event listeners
                    peer.outgoingConnection.on("Connection:signal", this.shareSignal);
                    peer.outgoingConnection.on("Connection:stateChange", this.peerShareStateChangeEvent);
                    peer.outgoingConnection.on(peer.outgoingConnection.SHARING_CHANNEL_MESSAGE, this.screensharingMessageEvent);
                }
                else {
                    console.warn('Peer NOT Found:' + peerId);
                }
            };

            this.addTracks = (track, stream, peerType) => {

                for(var i=0; i < this.peers.length; i++){
                    var peer = this.peers[i];
                    if(
                        peer
                        && (typeof peerType === 'undefined' || peer.type === peerType)
                        && peer.outgoingConnection)
                        {
                            peer.outgoingConnection.addTrack(track, stream);
                            peer.outgoingConnection.negotiate();
                    }
                }
            };

            this.removeTracks = (track, peerType) =>{

                for(var i=0; i < this.peers.length; i++){
                    var peer = this.peers[i];
                    if(
                        peer
                        && (typeof peerType === 'undefined' || peer.type === peerType)
                        && peer.outgoingConnection)
                        {
                            peer.outgoingConnection.removeTrack(track);
                    }
                }

            };

            this.delete = (peerId) => {
                for (var i = 0; i < this.peers.length; ++i) {
                    if (this.peers[i].id === peerId) {
                        //console.log('########### Removing Peer Index:' + i);
                        this.peers.splice(i,1);
                        break;
                    }
                }
            };


            this.get = (peerId) => {
                var peer = null;
                for (var i = 0; i < this.peers.length; ++i) {
                    if (this.peers[i].id === peerId) {
                        peer = this.peers[i];
                        break;
                    }
                }
                return peer;
            }
    }

    addPeer(peerId, peerType, candidateFilter = CandidateFilter.noFilter) {
        var peer = this.get(peerId);
        if(peer){
            //console.warn("PEER ALREADY EXISTS", peerId, peer);
            return;
        }

        this.ui.createSharePeerVideo(peerId);

        peer = new Peer(this.myPeerType, peerId, peerType, candidateFilter, Constants.SignalType.SCREENSHARE);
        peer.on("Peer:outgoingConnectionReady", this.outgoingShareConnectionReadyEvent);
        peer.connectIncoming(this.engagementId, this.myPeerId, this.iceServer);
        peer.on("Peer:incomingTrack", this.peerShareTrackEvent);
        peer.incomingConnection.on("Connection:signal", this.shareSignal);
        peer.incomingConnection.on("Connection:stateChange", this.peerShareStateChangeEvent);

        this.peers.push(peer);

        if (this.ui.inputCapturer) {
            this.ui.inputCapturer.on("INPUT_EVENT", (ev) => {
                try {
                    peer.incomingConnection.send({msg: ev});
                } catch(e) {
                    console.log(e);
                }
            });

            peer.incomingConnection.on(peer.incomingConnection.SHARING_CHANNEL_MESSAGE, (msg) => this.ui.inputCapturer.onIncomingMessage(msg));
        }
    }

    removePeer(peerId) {
        //console.log("removePeer " + peerId);
        var peer = this.get(peerId);
        if(peer){
            peer.off("Peer:outgoingConnectionReady", this.outgoingShareConnectionReadyEvent);
            peer.off("Peer:incomingTrack", this.peerShareTrackEvent);

            if(peer.incomingConnection){
                peer.incomingConnection.off("Connection:signal", this.shareSignal);
                peer.incomingConnection.off("Connection:stateChange", this.peerShareStateChangeEvent);

                clearInterval(peer.incomingConnection.incomingConnectionStateCheckInterval);
                if (peer.incomingConnection.rtc.signalingState !== "closed") {
                    peer.incomingConnection.rtc.close();
                    peer.incomingConnection.rtc = null;
                }
                peer.incomingConnection = null;
            }

            if (peer.outgoingConnection) {
                peer.outgoingConnection.off("Connection:signal", this.shareSignal);
                peer.outgoingConnection.off("Connection:stateChange", this.peerShareStateChangeEvent);

                clearInterval(peer.outgoingConnection.incomingConnectionStateCheckInterval);
                if (peer.outgoingConnection.rtc.signalingState !== "closed") {
                    peer.outgoingConnection.rtc.close();
                    peer.outgoingConnection.rtc = null;
                }

                peer.outgoingConnection = null;
            }

            this.delete(peerId);

        }

        this.ui.resetVideoSrc(peerId);
    }


    processMessage(peerId, message) {

        if (message.signalType && message.signalType === Constants.SignalType.SCREENSHARE) {

            var peer = this.get(peerId);

                if (peer) {
                    if (message.type === 'state') { // then we should handle here
                        if (message.direction === Constants.Direction.In) {
                            if (peer.theirIncomingConnectionState !== message.connectionState) {
                                peer.theirIncomingConnectionState = message.connectionState;
                                if (message.connectionState === 'new') { // they are ready and we should create our outbound
                                    this.connectOutgoing(peerId);
                                }
                            }
                        }
                    } else if (message.type === 'avEvent') {
                        //console.log("####### avEvent ########");
                        if (message.direction === Constants.Direction.Out) {
                            this.ui.handleAVEvent(message);
                        }
                    } else { // otherwise pass it through to the appropriate connection
                        peer.processMessage(message);
                    }
                } else {
                    console.log("Peer not found to process message");
                }

        }
    }

    startSharing(options) {
        //console.log("startSharing " + JSON.stringify(options));
        this.isPaused = false;
        this.localShareStream.getVideoTracks().forEach(track => {
            this.localShareStream.removeTrack(track);
            track.stop();
        });

        let constraints = {
            audio: options.audio,
            video: options.video
        };

        if (options.cropTarget) {
            constraints.preferCurrentTab = true;
        }

        this.getDisplayMedia(constraints)
            .then(stream => options.crop ? this.createTrackProcessor(options, stream) : Promise.resolve(stream))
            .then(stream => this.onGetDisplayMedia(stream, null, options.cropTarget))
            .catch(error => {
                console.log(error);
                this.handleGetDisplayMediaError(error);
            });
    }

    createTrackProcessor(options, stream) {
        const cropper = new TrackCropper(options.crop);
        this.cropper = cropper;
        return cropper.init(stream);
    }
    
    onGetDisplayMedia(stream, track, cropTarget) {
        //console.log("onGetDisplayMedia");
        if (!stream && !track) {
            console.warn("onGetDisplayMedia: No stream or track, skipping");
            return;
        }

        this.localShareTrack = track ? track : stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;

        // cropTo is not defined for when you select a non-tab to share.
        if (cropTarget && this.localShareTrack.cropTo) {
            this.localShareTrack.cropTo(cropTarget);
        }

        if (this.localShareTrack != null) {
            this.localShareStream.addTrack(this.localShareTrack);
            this.addTracks(this.localShareTrack, this.localShareStream);
        }

        this.addLocalTrack(this.localShareStream);
        this.emit("ShareManager:onSharingState", true);
    }

    addLocalTrack(stream){
        var track = stream.getVideoTracks()[0];
        if(track){
            this.ui.addLocalTrack(track);
            track.onended = e => {
                this.stopSharing();
            }
        }
    }

    getDisplayMedia(displayMediaOptions = {
        video: true,
        audio: false
    }) {
        if (navigator.getDisplayMedia) {
            //console.log("using navigator.getDisplayMedia");
            return navigator.getDisplayMedia(displayMediaOptions);
        } else if (navigator.mediaDevices.getDisplayMedia && displayMediaOptions.video === true) {
            //console.log("using navigator.mediaDevices.getDisplayMedia");
            return navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        } else {
            //console.log("using navigator.mediaDevices.getUserMedia");
            return navigator.mediaDevices.getUserMedia(displayMediaOptions);
        }
    }

    stopSharing(){
        //console.log("stopSharing isPaused:" + this.isPaused + " localShareTrack:" + this.localShareTrack);
        this.isPaused = false;
        if(this.localShareTrack){
             this.localShareTrack.stop();
             this.removeTracks(this.localShareTrack);
             this.localShareTrack = null;
             this.ui.dispose();

             this.emit("ShareManager:onSharingState", false);
        }
        
        if (this.cropper) {
            this.cropper.dispose();
            this.cropper = null;
        }
    }

    pauseSharing(){
        if (this.isPaused) return;
        this.isPaused = true;
        this.detachTracks();
    }

    resumeSharing(){
        if (!this.isPaused) return;
        this.isPaused = false;
        this.reAttachTracks();
    }

    updateRegion(region) {
        if (this.cropper) {
            this.cropper.updateRegion(region);
        }
    }

    enableCustomerInput(peerId) {
        const msg = { type: 'enable' };
        this.sendMessageToOutgoingConnections(msg, peerId);
    }

    disableCustomerInput(peerId) {
        const msg = { type: 'disable' };
        this.sendMessageToOutgoingConnections(msg, peerId);
    }

    updateCustomerPage(url) {
        const msg = { type: 'pageChanged', url: url };
        this.sendMessageToOutgoingConnections(msg);
    }

    focus(peerId) {
        const msg = { type: 'focusSharing' };
        this.sendMessageToOutgoingConnections(msg, peerId);
    }

    blur(peerId) {
        const msg = { type: 'blurSharing' };
        this.sendMessageToOutgoingConnections(msg, peerId);
    }

    /* Send message to connected peers.
     * If user is not given the message is sent to all peers.
     * If user is given then the message is sent to peers with that id only.
     */
    sendMessageToOutgoingConnections(message, peerId) {
        this.peers
            .filter(peer => peer.outgoingConnection != null)
            .filter(peer => (peerId === undefined) || (peerId === peer.id))
            .forEach(peer => peer.outgoingConnection.send(message));
    }

    dispose() {
        this.stopSharing();

        for(var i=0; i < this.peers.length; i++){
            var peer = this.peers[i];
            this.removePeer(peer.id);
        }

        this.isPaused = false;
        this.peers = [];

        this.ui.dispose();
        this.ui = null;
    }

}