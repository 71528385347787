<div class="h-100 d-flex flex-column">
  <h3 class="pt-3 pb-3 text-center">{{currentEngagementStateText}} {{ "ENGAGEMENTTRANSFER_LABEL_REJECTED" | Translate : "Rejected"}} </h3>
  <div class="d-flex flex-column flex-grow-1">
    <div class="row pl-3 pr-3">
      <div class="col">
        <p>{{ "ENGAGEMENTTRANSFER_LABEL_FROM" | Translate : "From:" }} </p>
      </div>
      <div class="col-7">
        <p>{{inviteState?.operatorFullname}}</p>
      </div>
    </div>
    <div class="row pl-3 pr-3">
      <div class="col">
        <p>{{ "ENGAGEMENTTRANSFER_LABEL_REJECTIONREASON" | Translate : "Rejection Reason:"}}</p>
      </div>
      <div class="col-7">
        <p>{{inviteState?.reason}}</p>
      </div>
    </div>
  </div>
  <div class="surface-hover d-flex flex-column">
    <p-button class="flex-grow-1" styleClass="center-icon p-button-secondary w-100 h-100"
            title='{{ "ENGAGEMENTTRANSFER_TOOLTIP_CHOOSEAGENT" | Translate : "Choose Another Agent"}}'
            (onClick)="chooseAnotherAgent.emit()">
        <fa-icon [icon]="faChevronLeft" size="xl" />
    </p-button>
  </div>
</div>
