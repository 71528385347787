import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2, Output } from '@angular/core';
import { CustomerSizes, Engagement } from '../../services/engagement';
import { Subject, Subscription } from 'rxjs';
import { BrowserServiceStatus } from '../../services/browser-service/IBrowserService';
import { debounceTime, first } from 'rxjs/operators';
import { BrowserService } from '../../services/browser-service/browser.service';
import { IBrowser } from '../../services/browser-service/IBrowser';


@Component({
  selector: 'app-engagement-browser',
  templateUrl: './engagement-browser.component.html',
  styleUrls: ['./engagement-browser.component.scss']
})
export class EngagementBrowserComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isSharing: boolean;

  @ViewChild('browserContainer', {static: true}) browserContainerRef: ElementRef;

  private browser: IBrowser;
  private subs: Subscription[] = [];
  private container: any;
  private browserContainer: any;
  private resize$: Subject<any> = new Subject<any>();
  private currentCustomerSize:CustomerSizes;
  private _showBrowser: boolean = false;

  private _showUserPanel: boolean = true;
  @Input() set showUserPanel(showUserPanel: boolean) {
    this._showUserPanel = showUserPanel;
    this.handleBrowserAndPanelDimensions();
  }
  get showUserPanel(): boolean {
    return this._showUserPanel;
  }

  private _browserId: string;
  @Input() set browserId(browserId: string) {
    if (browserId != this._browserId) {
      this._browserId = browserId;

      if (this.browser) {
        this.browser.hide();
      }

      this.browser = this.browserService.getBrowser(this.browserId);

      this.showBrowser = this._showBrowser;
    }
  };

  get browserId(): string {
    return this._browserId;
  }

  private _engagement: Engagement;
  @Input() public set engagement(engagement: Engagement) {
    this._engagement = engagement;

    if (engagement) {
      this.browserId = engagement.engagementId.toString();
    } else {
      this.browserId = null;
    }
  }

  public get engagement(): Engagement {
    return this._engagement;
  }

  @Input() set currentPanelPosition(currentPanelPosition:CustomerSizes) {
    this.currentCustomerSize = currentPanelPosition;

    if (this.showBrowser) {
      this.handleBrowserAndPanelDimensions();
    }
  }

  @Input() public set isPresenter(val: boolean) {
    this.updateCanCoBrowse(val);
  }

  private updateCanCoBrowse(isPresenter: boolean) {
    if (this.browser) {
      if (isPresenter) {
        this.browser.enable();
      }
      else {
        this.browser.disable();
      }
    }
  }


  @Input() public set showBrowser(val: boolean) {
    this._showBrowser = val;

    if (this.browser) {

      if (this._showBrowser) {

        this.browser.show();
        setTimeout(() => this.handleBrowserAndPanelDimensions(), 0);

      }
      else {
        this.browser.hide();
      }
    }
  }

  public get showBrowser(): boolean {
    return this._showBrowser;
  }

  private windowResizeListener = () => this.handleBrowserAndPanelDimensions();
  private unlistenToLoad: (() => void) = null;

  constructor(private browserService: BrowserService,
              private containerRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.container = this.containerRef.nativeElement;
    this.browserContainer = this.browserContainerRef.nativeElement;

    if (!this.container || !this.browserContainer) {
      return;
    }

    this.unlistenToLoad = this.renderer.listen(window, "load", () => {
      this.resize();
    });

    window.addEventListener('resize', this.windowResizeListener);

    this.browser = this.browserService.getBrowser(this.browserId);

    this.subs.push(this.resize$
      .pipe(debounceTime(100))
      .subscribe(bounds => this.browser && this.browser.sendBrowserDimensions(bounds)));

  }

  ngAfterViewInit() {
    if (this.browser) {
      this.browser.onBrowserCreated.pipe(first()).subscribe(() => this.onBrowserCreated());
      this.browser.onResize.subscribe(() => this.resize());
    }
  }

  private onBrowserCreated() {
    this.browserService.setStatus(BrowserServiceStatus.CREATED);
    setTimeout(() => this.handleBrowserAndPanelDimensions(), 1000);
  }


  private handleBrowserAndPanelDimensions() {
    if (this.currentCustomerSize) {
      this.handleBrowserDimensions(this.currentCustomerSize);
      this.handlePanelDimensions(this.currentCustomerSize.panelDimensions);

      if (this.browser) {
        this.browser.setClientDimensions(this.currentCustomerSize.browserWidth, this.currentCustomerSize.browserHeight);
      }
    }
  }

  private handlePanelDimensions(panelDims: string) {
    if (!this._showUserPanel) {
      panelDims = "0,0,0,0";
    }

    if (!panelDims) {
      return;
    }

    const split = panelDims.split(',');
    if (split == null || split.length < 4) {
      return;
    }

    const currentPanelTop = parseInt(split[0], 10);
    const currentPanelLeft = parseInt(split[1], 10);
    const panelWidth = parseInt(split[2], 10);
    const panelHeight = parseInt(split[3], 10);

    const validDimensions = (isNaN(currentPanelTop) || isNaN(currentPanelLeft) || isNaN(panelWidth) || isNaN(panelHeight)) === false;
    if (validDimensions) {
      setTimeout(() => {
        if (this.browser) {
          this.browser.setPanelDimensions(currentPanelTop, currentPanelLeft, panelWidth, panelHeight);
        }
      }, 200);
    }
  }

  private handleBrowserDimensions(v: CustomerSizes) {

    if (!v || !this.container) {
      return;
    }

    this.updateBrowserContainer();
  }

  private updateBrowserContainer() {
    if (this.browserContainer) {
      setTimeout(() => this.resize(), 0);
    }
  }

  private resize() {
    if (this.browserContainer) {
      const bounds = this.browserContainer.getBoundingClientRect();
      this.resize$.next(bounds);
    }
  }

  ngOnDestroy() {
    if (this.browserService) {
      this.browserService.setStatus(BrowserServiceStatus.NONE);
    }

    if (this.browser) {
      this.browser.hide();
    }

    if (this.unlistenToLoad != null) {
      this.unlistenToLoad();
      this.unlistenToLoad = null;
    }

    window.removeEventListener('resize', this.windowResizeListener);

    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }
}
