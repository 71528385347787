import {Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild} from '@angular/core';
import { CameraProperties, CameraControlProperty, CameraControlTypes } from '../../services/camera-controls-service/camera-properties';
import { Observable, Subscription } from 'rxjs';
import { CameraControlsService } from '../../services/camera-controls-service/camera-controls.service';
import { OnlineService } from '../../services/online.service';
import { faRedo, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { first } from 'rxjs/operators';
import {AccordionTab} from "primeng/accordion";

@Component({
  selector: 'app-camera-controls',
  templateUrl: './camera-controls.component.html',
  styleUrls: ['./camera-controls.component.scss'],
})
export class CameraControlsComponent implements OnInit, OnDestroy {

  @ViewChild('enhancedAvControls') enhancedAvControls: AccordionTab;

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faRedo = faRedo;

  private subs: Subscription[] = [];
  private enhancedControls$: Observable<CameraProperties>;

  public genericProperties: Array<CameraControlProperty>;
  public zoom: CameraControlProperty;
  public pan: CameraControlProperty;
  public tilt: CameraControlProperty;

  public useEnhancedAvControls: boolean = false;

  @Output() showHeadOverlay = new EventEmitter<boolean>();

  constructor(
    private cameraControlsService: CameraControlsService,
    private onlineService: OnlineService,
  ) { }

  ngOnInit() {

    this.cameraControlsService.setup().pipe(first()).subscribe(success => {

      this.useEnhancedAvControls = success;

      if (this.useEnhancedAvControls) {

        this.subs.push(this.onlineService.selectedCamera.asObservable().subscribe(v => {
          this.showHeadOverlay.emit(false);
          this.cameraControlsService.init(v.label);
        }));

        this.enhancedControls$ = this.cameraControlsService.properties$.asObservable();

        this.subs.push(this.enhancedControls$.subscribe(v => {
          this.genericProperties = v.properties.filter(v => v.type === CameraControlTypes.Generic);
          this.zoom = v.properties.find(v => v.type === CameraControlTypes.Zoom);
          this.pan = v.properties.find(v => v.type === CameraControlTypes.Pan);
          this.tilt = v.properties.find(v => v.type === CameraControlTypes.Tilt);
        }));

      }

    });


  }

  onReset($event) {
    this.cameraControlsService.reset();
  }

  onPropertyChange($event) {
    this.cameraControlsService.setProperty($event.name, $event.value.toString(), $event.flag);
  }

  ngOnDestroy() {
    this.cameraControlsService.dispose();
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  onAccordionGrpOpen() {
    this.showHeadOverlay.emit(this.enhancedAvControls?.selected);
  }

}
