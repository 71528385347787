import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Agent } from '../../classes/agent';
import { EmailTemplate } from '../../classes/email/emailtemplate';
import { Observable } from 'rxjs';

export interface IInviteBackendService {
  sendSMS(authToken: string, to: string, body: string);
  sendEmail(sitename:string, recipient: string, message: string, subject: string, authToken:string);
}

@Injectable({
  providedIn: 'root'
})
export class InviteBackendService implements IInviteBackendService{
  

  constructor(private http: HttpClient) { }


  sendSMS(authToken: string, to: string, body: string) {
    const url = `${environment.inviteRoot}/sms`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };


    return this.http.post(
      url,
      { to, body },
      httpOptions
    );

  }


  sendEmail(sitename:string, recipient: string, message: string, subject: string, authToken:string) {
    const url = `${environment.inviteRoot}/email`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post(
      url,
      { sitename, recipient, message, subject },
      httpOptions
    );

  }

  loadInviteTemplates(authToken:string): Observable<EmailTemplate[]> {
    const url = `${environment.inviteRoot}/email`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.get<EmailTemplate[]>(
      url,
      httpOptions
    );
  }
  
}
