import {Component, EventEmitter, Output} from '@angular/core';
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-transfer-chat-box',
  templateUrl: './engagement-transfer-chat-box.component.html',
  styleUrls: ['./engagement-transfer-chat-box.component.scss']
})
export class EngagementTransferChatBoxComponent {
  @Output() sendMessage = new EventEmitter<string>();

  message: string = '';

  onKeyDown($event: KeyboardEvent) {
    if ($event.code === "Enter" && !$event.shiftKey) {
      this.sendTextMessage();
      $event.preventDefault();
    }
  }

  sendTextMessage() {
    this.message = this.message.trim();
    if (this.message != '') {
      this.sendMessage.emit(this.message);
    }
    this.message = '';
  }

  protected readonly faChevronLeft = faChevronLeft;
}
