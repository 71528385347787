import {Component, Input, Output, EventEmitter} from '@angular/core';
import {TextMessage, TextMessages} from '../../classes/TextMessage';
import {IFileUpload} from '../../services/file-transfer-service/file-upload-interface';
import {OutboundMessage} from '../engagement-text-chat/engagement-text-chat.component';
import {Engagement, EngagementState} from '../../services/engagement';
import {PostEngagementStatus} from '../../classes/post-engagement.status';


@Component({
  selector: 'app-engagement-chat-tabset',
  templateUrl: './engagement-chat-tabset.component.html',
  styleUrls: ['./engagement-chat-tabset.component.scss']
})
export class EngagementChatTabsetComponent {
  EngagementState = EngagementState; // export the engagement state for use in the UI

  @Input() engagement: Engagement;

  @Input() privateChatMessages: TextMessage[];
  @Input() previewMessage: TextMessage;

  @Input() messages: TextMessages;
  @Input() files: IFileUpload[] = [];

  @Input() privateChatAvailable: boolean;
  @Input() privateChatEnabled: boolean;
  @Input() isClosed: boolean;
  @Input() richTextContent: any;
  @Input() useLinkUnfurling: boolean;

  @Input() unreadMessages: number;
  @Input() unreadPrivateMessages: number;

  @Output() sendMessage = new EventEmitter<OutboundMessage>();
  @Output() uploadFiles = new EventEmitter<FileList>();
  @Output() cancelFilesTransfer = new EventEmitter<IFileUpload>();

  @Input() translationOn: boolean;
  @Output() setTranslation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() getSuggestionOn: boolean;
  @Output() setGetSuggestion: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() autoReplyOn: boolean;
  @Output() setAutoReply: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() agentText: string;
  @Output() agentTextChange = new EventEmitter();

  @Output() sendPrivateMessage = new EventEmitter<string>();

  @Input() maxMessageLength: number;

  @Input() textEntryDisabled = false;
  @Input() sendButtonDisabled = false;

  @Output() checkListName = new EventEmitter<string>;
  @Output() savePost = new EventEmitter<PostEngagementStatus>();

  public selectedTab: number = 0;

  onSelect(newIndex: number): void {
    this.selectedTab = newIndex;

    if (this.selectedTab === 0) {
      this.resetCountMessages();
    } else if (this.selectedTab === 2) {
      this.resetCountPrivateMessages();
    }

  }

  onUploadFiles(files: FileList) {
    this.uploadFiles.emit(files);
  }

  onCancelFilesTransfer(file: IFileUpload) {
    this.cancelFilesTransfer.emit(file);
  }

  handleSetTranslation(enabled: boolean) {
    this.setTranslation.emit(enabled);
  }

  handleSetGetSuggestion(enabled: boolean) {
    this.setGetSuggestion.emit(enabled);
  }

  handleSetAutoReply(enabled: boolean) {
    this.setAutoReply.emit(enabled);
  }

  onSendPrivateChatMessage(privateMessage: string) {
    this.sendPrivateMessage.emit(privateMessage);
  }

  onSendMessage(message: OutboundMessage) {
    this.sendMessage.emit(message);
  }

  onAgentTextChange(text: any) {
    this.agentTextChange.emit(text);
  }

  resetCountMessages() {
    if (this.unreadMessages > 0) {
      this.engagement?.resetUnreadMessages();
    }
  }

  resetCountPrivateMessages() {
    if (this.unreadPrivateMessages > 0) {
      this.engagement?.resetUnreadPrivateMessages();
    }
  }
}
