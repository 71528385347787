import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Agent } from '../../classes/agent';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthBackendService {
  constructor(
    private http: HttpClient
    ) {}

  public login(email: string, password: string, version: string = "0.0.0", licenceKey: string = "", electronAppVersion:string = ""): Observable<Agent> {
    const url = `${environment.authApiRoot}/login`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      withCredentials: true
    };

    return this.http.post<Agent>(
      url,
      { email: email, password: password, version: version, licenceKey: licenceKey,  electronAppVersion:electronAppVersion },
      httpOptions
    );
  }

  public changePassword(currentPassword: string, newPassword: string, authToken: string): Observable<{}> {
    const url = `${environment.authApiRoot}/changepassword`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post(
      url,
      { currentPassword: currentPassword, newPassword: newPassword },
      httpOptions
    );
  }

  public forgottenPassword(email: string): Observable<{}> {
    const url = `${environment.authApiRoot}/forgottenpassword`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.post(
      url,
      { email: email },
      httpOptions
    );
  }

  public changePasswordFromLink(newPassword: string, resetToken: string): Observable<{}> {
    const url = `${environment.authApiRoot}/resetpassword`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'reset-token': resetToken
      })
    };

    return this.http.post(
      url,
      { newPassword: newPassword },
      httpOptions
    );
  }

  public logout(authToken: string): Observable<{}> {
    const url = `${environment.authApiRoot}/logout`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post(
      url,
      {},
      httpOptions,
    );
  }

  public checkLicenceType(authToken: string, licenceType: number = 0): Observable<boolean> {
    const url = `${environment.authApiRoot}/checklicence`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<boolean>(
      url,
      { licenceType: licenceType },
      httpOptions
    );
  }

}
