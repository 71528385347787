<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-grow-1 overflow-auto"  [class.bg-grey]="!supervisorsAvailable">
    <div class="d-block text-center p-2 pt-4 agent-text" *ngIf="!supervisorsAvailable">
      <p class="lead mt-2">{{ "PRIVATECHAT_LABEL_NOSUPERVISOR" | Translate : 'No supervisors are currently available.' }}</p>
      <p>{{ "PRIVATECHAT_LABEL_MESSAGEINQUEUE" | Translate : 'Your message will be placed in a queue.' }}</p>
    </div>
    <div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="d-flex flex-column flex-grow-1 messages-container">
      <ng-container *ngFor="let message of privateChatMessages">
        <app-text-chat [message]="message" class="text-message"></app-text-chat>
      </ng-container>
    </div>

    <div class="d-flex flex-row w-100 message-editor gap-1">
      <textarea pInputTextarea  [(ngModel)]="agentText" class="flex-1" rows="5"
        placeholder='{{ "PRIVATECHAT_LABEL_TYPEPRIVATEMESSAGE" | Translate : "Type private message here..." }}'
        (keyup.enter)="sendPrivateTextMessage()">
      </textarea>
      <p-button id="send-message-btn" type="button" styleClass="btn-square center-icon  h-100" (click)="sendPrivateTextMessage()">
          <fa-icon [icon]="faPaperPlane"/>
      </p-button>
    </div>
  </div>

</div>
