
import Connection from "../Connection";
import Constants from "../Constants";

export default class SafariConnection extends Connection {
    constructor(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, direction, candidateFilter = CandidateFilter.noFilter, signalType = Constants.SignalType.AV) {
        super(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, direction, candidateFilter, signalType);

        console.log('Created ' + direction + ' Safari RTCPeerConnnection to:' + this.peerId); 
    } 

    addTrack(track, stream){
        
        //console.log("SafariConnection addTrack");
       
        // safari is weird now, audio works differently too video
        if (track.kind === "audio") {

            var found = false;
            for (var i = 0; i < this.rtc.getTransceivers().length; i++) {
                var transceiver = this.rtc.getTransceivers()[i];
                if (transceiver.sender != null) {
                    if (transceiver.sender.track != null && transceiver.sender.track.kind === track.kind) {
                        transceiver.setDirection ? transceiver.setDirection("sendonly") : transceiver.direction = "sendonly";
                        transceiver.sender.replaceTrack(track);
                        track.enabled = true;
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                // otherwise add the track to the peer connection
                this.rtc.addTrack(track, stream);
            }
        }
        else if (track.kind === "video") {
            this.rtc.addTrack(track, stream);
        }        
    }

    removeTrack(track) {

        //console.log("SafariConnection removeTrack");       
        
        // safari is weird now, audio works differently from video
        // - if we do what we are doing to the audio to the video, then it gets removed, but 
        //   keeps firing the onunmute -> onmute -> onunmute every 5 seconds
        // - if we do what we are doing to the video to the audio (like before), then the
        //   track gets added muted (readonly) and never unmutes at the receivers end
        if (track.kind === "audio") {

            for (var i = 0; i < this.rtc.getTransceivers().length; i++) {
                var transceiver = this.rtc.getTransceivers()[i];
                if (transceiver.sender != null) {
                    if (transceiver.sender.track != null && transceiver.sender.track.kind === track.kind) {
                        transceiver.setDirection ? transceiver.setDirection("inactive") : transceiver.direction = "inactive";
                        track.enabled = false;
                        break;
                    }
                }
            }
        }
        else if (track.kind === "video") {

            var senders = this.rtc.getSenders();
            for (var j = 0; j < senders.length; ++j) {
                var sender = senders[j];
                if (sender.track === track) {
                    this.rtc.removeTrack(sender);
                }
            }

        }

    }
    
}