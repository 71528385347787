export enum EngagementAssetType {
  WebPage,
  Static,
}

export interface EngagementAsset {
  Title: string;
  Url: string;
  IsShared: boolean;
  IsEnabled: boolean;
  Type: EngagementAssetType;
}

export interface EngagementAssetPlaylist {
  Title: string;
  Assets: EngagementAsset[];
}
