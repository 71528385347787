import { Subscription, ReplaySubject, Observable, Subject } from "rxjs";
import { WebrtRTCMessagingService } from "../webrtc-messaging-service/webrtcmessaging.service";
import { WebrtcMessageType, Engagement } from "../engagement";
import * as MultiPeerWebRTC from 'multi-peer-webrtc';
import { EngagementService } from "../engagement.service";
import {distinctUntilChanged, first, tap} from 'rxjs/operators';
import { ElectronService } from "../electron-service/electron.service";
import { ElectronWebrtcSharingProxy } from "../electron-service/electron-webrtc-sharing-proxy";
import { SharingMessage } from "../sharing-controller-service/SharingMessage";
import { LoggingService } from "../logging.service";

enum PeerType {
  Agent = 'agent',
  Visitor = 'visitor',
}

enum ShareEvents {
  Signal = 'ShareManager:signal',
  State = 'ShareManager:onSharingState',
  Error = 'ShareManager:onError',
  Message = 'ShareManager:sharingChannelMessage',
}

declare var CropTarget: any;

export interface ISharingService {
  sharingStateChange$: Observable<SharingMessage>;
  init(engagementId: string);
  switchSharingOn(on: boolean, typeFilter: string[], viewOnly: boolean);
  pauseSharing(on: boolean);
  dispose();
}

export class WebRTCSharingService implements ISharingService {

  public sharingStateChange$: Observable<SharingMessage>;
  private _sharingStateChange$: Subject<SharingMessage> = new Subject();

  private shareManager: MultiPeerWebRTC.ShareManager;
  private subscriptions: Subscription[] = [];
  private engagement: Engagement;

  private callPaused: Observable<boolean>;
  private callPausedSub: Subscription;

  private electronSharingProxy: ElectronWebrtcSharingProxy;
  private electronSourceSelectedSub: Subscription;
  private pageChangedSub: Subscription;
  private inputInjectorEventSub: Subscription;
  private focusedSub: Subscription;

  private viewOnly: boolean;
  private selectedSourceName: string = "";

  private readonly shareMessageListener = (ev) => this.onShareMessage(ev);
  private boundsSub?: Subscription;
  private showingSub?: Subscription;

  constructor(
    private readonly engagementService: EngagementService,
    private readonly webrtcMessagingService: WebrtRTCMessagingService,
    private readonly logging: LoggingService,
    private electronService?: ElectronService
  ) {
    this.sharingStateChange$ = this._sharingStateChange$
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  public init(engagementId: string) {
    this.engagement = this.engagementService.getEngagement(engagementId);

    this.engagement.authenticatedIceUrl$.pipe(first(), tap((webrtcIceServer) => {
      const options = {
        engagementId: this.engagement.engagementId, //turn server credentials
        peerId: this.engagement.username,
        iceServer: webrtcIceServer,
        peerType: PeerType.Agent,
        mainPeerId: this.engagement.username,
        shareContainer: document.getElementById("shareContainer")
      };

      if (this.electronService) {
        this.electronSharingProxy = this.electronService.createWebrtcSharingProxy();

        this.electronSourceSelectedSub = this.electronSharingProxy.sourceSelected$.subscribe(source => {
          if (this.shareManager) {
            this.selectedSourceName = source.name;

            const region = source.region;

            const constraints = {
              audio: false,
              video: {
                mandatory: {
                  chromeMediaSource: 'desktop',
                  chromeMediaSourceId: source.id
                }
              },
              crop: region
            };

            if (region) {
              this.boundsSub = this.electronSharingProxy.boundsUpdated.subscribe((region) => {
                this.shareManager.updateRegion({
                  x: Math.floor(region.x),
                  y: Math.floor(region.y),
                  width: Math.floor(region.width),
                  height: Math.floor(region.height),
                });
              });
              this.showingSub = this.electronSharingProxy.browserShowing.subscribe(onScreen => this.pauseSharing(!onScreen));
            }

            this.shareManager.startSharing(constraints);

            const injectorConstraints = {
              audio: false,
              video: {
                mandatory: {
                  chromeMediaSource: 'desktop',
                  chromeMediaSourceId: source.inputId
                }
              }
            };
            this.electronSharingProxy.createInjector(injectorConstraints);
          }
        });

        this.pageChangedSub = this.electronSharingProxy.urlLoaded$.subscribe(newUrl => this.onPageChanged(newUrl));
        this.inputInjectorEventSub = this.electronSharingProxy.inputInjectorEvent$.subscribe(ev => this.onInputInjectorEvent(ev));
        this.focusedSub = this.electronSharingProxy.focused$.subscribe(isFocused => this.onFocusChange(isFocused));
      }

      this.shareManager = new MultiPeerWebRTC.ShareManager(options);
      this.shareManager.on(ShareEvents.Signal, this.sendMessage.bind(this));
      this.shareManager.on(ShareEvents.State, this.setSharingOn.bind(this));
      this.shareManager.on(ShareEvents.Error, this.onSharingError.bind(this));
      this.shareManager.on(ShareEvents.Message, this.shareMessageListener);

      this.subscriptions.push(
        this.webrtcMessagingService.clientMessages$.subscribe(message => {
          switch (message.type) {
            case WebrtcMessageType.AddStream:
              this.addPeer(message)
              break;
            case WebrtcMessageType.RemoveStream:
              this.removePeer(message);
              break;
            case WebrtcMessageType.SignallingMessage:
              this.processMessage(message.message);
              break;
          }
        })
      );

      this.callPaused = this.engagement.callPaused.pipe(distinctUntilChanged());
    })).subscribe();
  }

  public getViewOnly(): boolean {
    return this.viewOnly;
  }

  public setViewOnly(viewOnly: boolean) {
    this.viewOnly = viewOnly;

    if (this.viewOnly) {
      this.suspendInput();
    } else {
      this.resumeInput();
    }
  }

  private setSharingOn(on: boolean) {
    if (on) {
      const relayServer: string = "";
      const shareMode: number = 3;
      const dimensions: string = `0x0`;
      const username: string = escape(this.engagement.username);

      this._sharingStateChange$.next(new SharingMessage(true, `${relayServer}:${username}:${dimensions}:${shareMode}:1`, this.selectedSourceName));

      this.callPausedSub = this.callPaused.subscribe(paused => this.pauseSharing(paused));
    }
    else {
      this._sharingStateChange$.next(new SharingMessage(false));

      if (this.callPausedSub) {
        this.callPausedSub.unsubscribe();
      }
    }

  }

  public switchSharingOn(on: boolean, typeFilter: string[], viewOnly: boolean) {
    this.viewOnly = viewOnly;
    if (this.shareManager) {
      if (on) {
        if (this.electronSharingProxy) {
          this.electronSharingProxy.startSharing(typeFilter);
        }
        else {
          let options: any = { video: true, audio: false };

          if (typeFilter.includes("CropTarget") && this.supportsCropTarget) {
            const selector = '[name="vee24AgentFrame"]';
            const mainContentArea = document.querySelector(selector);
            // @ts-ignore Only supported in modern Chrome browsers
            CropTarget.fromElement(mainContentArea).then(ct => {
              options.cropTarget = ct;
              this.shareManager.startSharing(options);
            }).catch(err => {
              this.logging.error(`Unable to get crop target for ${selector}`, err);
              this.shareManager.startSharing(options);
            });
          } else {
            this.shareManager.startSharing(options);
          }
        }
      }
      else {
        if (this.boundsSub) {
          this.boundsSub.unsubscribe();
          this.boundsSub = null;
        }

        if (this.showingSub) {
          this.showingSub.unsubscribe();
          this.showingSub = null;
        }

        this.shareManager.stopSharing();
      }
    }
  }

  public pauseSharing(on: boolean) {
    if (this.shareManager) {
      if (on) {
        this.shareManager.pauseSharing();
      }
      else {
        this.shareManager.resumeSharing();
      }
    }
  }

  private onSharingError(err: any) {
    this.setSharingOn(false);
    if (this.shareManager) {
      this.shareManager.stopSharing();
    }
  }

  public onPageChanged(url: string) {
    if (this.shareManager) {
      this.shareManager.updateCustomerPage(url);
    }
  }

  private onShareMessage(message) {
    try {
      if (this.shareManager && this.electronSharingProxy) {
        switch (message.type) {
          case 'channel':
            if (this.viewOnly) {
              this.suspendInput();
            } else {
              this.resumeInput();
            }
            break;
          case 'text':
            this.electronSharingProxy.sendText(message.event.text);
            break;
          case 'mouse':
            this.electronSharingProxy.sendMouseEvent(message.event.x, message.event.y, message.event.flags);
          break;
          case 'key':
            this.electronSharingProxy.sendKeyboardEvent(message.event.key, message.event.flags);
            break;
          default:
            this.logging.warn("Unknown message type: " + message.type);
        }
      }
    } catch (err) {
      this.logging.error("Error processing webrtc sharing message", err);
    }
  }

  private onInputInjectorEvent(ev) {
    if (this.shareManager) {
      switch (ev.type) {
        case "suspended-input":
          this.shareManager.disableCustomerInput(this.engagement.visitor.sessionGuid);
          this.viewOnly = true;
          break;
        case "resumed-input":
          this.shareManager.enableCustomerInput(this.engagement.visitor.sessionGuid);
          this.viewOnly = false;
          break;
        default:
          this.logging.warn(`Unknown input injector event type ${ev.type}`);
      }
    }
  }

  private onFocusChange(isFocused: boolean): void {
    if (this.shareManager) {
      if (isFocused) {
        this.shareManager.focus(this.engagement.visitor.sessionGuid);
      } else {
        this.shareManager.blur(this.engagement.visitor.sessionGuid);
      }
    }
  }

  private resumeInput() {
    if (this.electronSharingProxy) {
      this.electronSharingProxy.resumeInput();
    }
  }

  private suspendInput() {
    if (this.electronSharingProxy) {
      this.electronSharingProxy.suspendInput();
    }
  }

  private addPeer(message: any) {
    if (this.shareManager) {
      this.shareManager.addPeer(message.streamName, message.peerType);
    }
  }

  private removePeer(message: any) {
    if (this.shareManager) {
      this.shareManager.removePeer(message.streamName);
    }
  }

  private processMessage(message: any) {
    if (this.shareManager){
      this.shareManager.processMessage(message.from, message);
    }
  }

  private sendMessage(message: any) {
    this.webrtcMessagingService.sendMessageToServer(JSON.stringify(message));
  }

  private get supportsCropTarget(): boolean {
    return "CropTarget" in self && "fromElement" in CropTarget;
  }

  public dispose() {
    if (this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
      this.subscriptions = [];
    }

    if (this.callPausedSub) {
      this.callPausedSub.unsubscribe();
      this.callPausedSub = null;
    }

    if (this.electronSourceSelectedSub) {
      this.electronSourceSelectedSub.unsubscribe();
      this.electronSourceSelectedSub = null;
    }

    if (this.inputInjectorEventSub) {
      this.inputInjectorEventSub.unsubscribe();
      this.inputInjectorEventSub = null;
    }

    if (this.pageChangedSub) {
      this.pageChangedSub.unsubscribe();
      this.pageChangedSub = null;
    }

    if (this.focusedSub) {
      this.focusedSub.unsubscribe();
      this.focusedSub = null;
    }

    if (this.boundsSub) {
      this.boundsSub.unsubscribe();
      this.boundsSub = null;
    }

    if (this.showingSub) {
      this.showingSub.unsubscribe();
      this.showingSub = null;
    }

    if (this.shareManager) {
      this.shareManager.off(ShareEvents.Signal, this.sendMessage.bind(this));
      this.shareManager.off(ShareEvents.State, this.setSharingOn.bind(this));
      this.shareManager.off(ShareEvents.Error, this.onSharingError.bind(this));
      this.shareManager.off(ShareEvents.Message, this.shareMessageListener);

      this.shareManager.stopSharing();
      this.shareManager.dispose();
    }

    this.shareManager = undefined;

    if (this.electronSharingProxy) {
      this.electronSharingProxy.dispose();
      this.electronSharingProxy = null;
    }
  }

}
