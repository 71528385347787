<div>
  <p-autoComplete
    [ngModel]="value"
    [dropdown]="true"
    styleClass="text-field w-100"
    [formControl]="selectControl"
    [suggestions]="filteredChoices"
    field="text"
    placeholder="{{getFieldPlaceholder(field.value)}}"
    (onSelect)="onSelect($event)"
    [class.disabled]="disableCrm"
    [disabled]="disableCrm"
    [readonly]="field.readOnly"
    (completeMethod)="onComplete($event)"></p-autoComplete>
</div>
