import { EventEmitter } from "@angular/core";
import { StringUtils } from "../../utils/string-utils";

export class AddressStack {
    private isCallByNavigaton: boolean = false;
    private readonly visitedURLs: string[] = [];
    private currentURLIndex: number = 0;

    public readonly changePage: EventEmitter<string> = new EventEmitter<string>();

    private _backEnabled: boolean = false;
    public get backEnabled(): boolean {
        return this._backEnabled;
    }

    private _forwardEnabled: boolean = false; 
    public get forwardEnabled(): boolean {
        return this._forwardEnabled;
    }

    private get currentUrl(): string {
      if (this.visitedURLs.length > 0) {
        return this.visitedURLs[this.currentURLIndex];
      } else {
        return undefined;
      }
    }

    constructor() {}
 
    public addPage($event: string): void {
      if (StringUtils.urlEqual(this.currentUrl, $event)) {
        // Ignore the request to add a url if the current url is equal to this url.
      } else if (!this.isCallByNavigaton) {
          if (this.currentURLIndex != this.visitedURLs.length - 1) {
              this.visitedURLs.splice(this.currentURLIndex + 1, this.visitedURLs.length - this.currentURLIndex - 1);
          }

          this.visitedURLs.push($event);
          this.currentURLIndex = this.visitedURLs.length - 1;
          this.updateNavButtonStates();
      }
      this.isCallByNavigaton = false;
    }
  
    public back(): void {
      if (this.visitedURLs.length > 1 && this.currentURLIndex > 0) {
        this.isCallByNavigaton = true;
        this.currentURLIndex = this.currentURLIndex - 1;
        const url = this.visitedURLs[this.currentURLIndex];
        this.changePage.emit(url);
        this.updateNavButtonStates();
      }
    }
  
    public forward(): void {
      if (this.visitedURLs.length > 1 && this.currentURLIndex < this.visitedURLs.length - 1) {
        this.isCallByNavigaton = true;
        this.currentURLIndex = this.currentURLIndex + 1;
        const url = this.visitedURLs[this.currentURLIndex];
        this.changePage.emit(url);
        this.updateNavButtonStates();
      }
    }
  
    private updateNavButtonStates(): void {
      this._backEnabled = this.currentURLIndex > 0 && this.visitedURLs.length > 1;
      this._forwardEnabled = (this.visitedURLs.length > 1 && this.currentURLIndex < this.visitedURLs.length - 1);
    }
}