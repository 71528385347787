<div class="d-flex flex-column h-100">
  <p-editor styleClass="w-100 flex-grow-1"
                [formats]=""
                (onInit)="onEditorCreated($event)"
                [(ngModel)]="inviteReasonText"
                [modules]="quillModules"
                placeholder='{{ "ENGAGEMENTTRANSFER_BUTTON_OPERATORGROUP" | Translate : "Type your message here..." }}'>
    <ng-template pTemplate="header">
      <div class="py-2">
        <p-dropdown #invitereasons
                            class="drop-up"
                            [filter]="(inviteReasons | async)?.length > 10"
                            [options]="(inviteReasons | async)"
                            (onChange)="onInviteReasonSelected($event)"
                            optionValue="Translation"
                            optionLabel="Title"
                            placeholder="{{quillPlaceholder}}"
                            [resetFilterOnHide]="true"
                            [showClear]="false">

          <ng-template let-option let-text="text">
            <span [innerHTML]="text"></span>
          </ng-template>

        </p-dropdown>

      </div>
    </ng-template>

  </p-editor>
  <div class="d-flex flex-row w-100 pt-2 gap-2">
    <p-button class="flex-grow-1" styleClass="center-icon p-button-secondary h-100 w-100 align-items-center justify-content-center"
              pTooltip='{{ "ENGAGEMENTTRANSFER_TOOLTIP_CLOSEPANEL" | Translate : "Close Panel" }}'
            (onClick)="cancelInvite.emit()">
        <fa-icon [icon]="faChevronLeft" size="xl" />
    </p-button>
    <p-button class="flex-grow-1" styleClass="center-icon p-button-success h-100 w-100 align-items-center justify-content-center"  [disabled]="!this.enableSend"
              pTooltip='{{ "ENGAGEMENTTRANSFER_TOOLTIP_SENDINVITATION" | Translate : "Send Invitation" }}'
            (onClick)="sendInvite()">
        <fa-icon [icon]="faChevronRight" size="xl" />
    </p-button>
  </div>
</div>
