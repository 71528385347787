<table class="mt-4 table table-borderless w-100 text-center text-truncate user-select-none">
  <thead>
  <tr>
    <th class="text-left">{{ "SUPERVISOR_HEADER_AGENT"  | Translate : 'Agent' }}</th>
    <th>{{ "SUPERVISOR_HEADER_TIMEINSESSION"  | Translate : 'Time in Session' }}</th>
    <th>{{ "SUPERVISOR_HEADER_TIMERESPONSE"  | Translate : 'Time Since Response' }}</th>
    <th>
      {{ "SUPERVISOR_HEADER_LASTPAGE"  | Translate : 'Last Page' }}
    </th>
    <th>
      {{ "SUPERVISOR_HEADER_AGENTASSIST"  | Translate : 'Agent Assist' }}
    </th>
    <th class="text-center">{{ "SUPERVISOR_HEADER_ENTERSESSION"  | Translate : 'Enter Session' }}</th>
  </tr>
  </thead>
  <tbody *ngIf="show">
  <ng-container *ngFor="let group of selectedGroups | keyvalue">
    <tr *ngIf="(selectedVisitors | visitorFilter : group.value).length > 0" class="op-group-header">
      <td colspan="6">
        <p class="lead text-left p-0 m-0 op-group-name">{{ group.value }}</p>
      </td>
    </tr>

    <tr *ngFor="let visitor of (selectedVisitors | visitorFilter : group.value)">
      <td class="text-left">{{ visitor.currentOperator }}</td>
      <td>{{ TimeUtil.PrettyRelativeTime(visitor.getAssistTime()) }}</td>
      <td>{{ TimeUtil.PrettyRelativeTime(visitor.getTimeSinceResponse()) }}</td>
      <td>{{ visitor.lastPage }}</td>
      <td>
        <img *ngIf="visitor.agentRequestingAssistance"
             src="./assets/call-status-icons/handraise_btn.svg">
      </td>
      <td>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <p-button severity="success"
                    [ngClass]="{'d-none': !supervisingVisitors.has(visitor.engagementGuid) && !supervisingEngagements.has(visitor.engagementGuid)}"
                    (onClick)="onEnterSession(visitor)">
            <fa-icon [icon]="faPhone"></fa-icon>
          </p-button>
          <p-button severity="info"
                    [ngClass]="{'d-none': supervisingVisitors.has(visitor.engagementGuid) || supervisingEngagements.has(visitor.engagementGuid)}"
                    (onClick)="onStartSnooping(visitor)">
            <fa-icon [icon]="faUserSecret"></fa-icon>
          </p-button>
        </div>

      </td>
    </tr>
    <!-- TODO: Convert to p-table -->
    <tr *ngIf="(selectedAgents | agentDashboardFilter: 'Veechat Post' : group.key : selectedVisitors).size > 0"
        class="op-group-header">
      <td colspan="4">
        <p class="lead text-left p-0 m-0 op-group-name">{{ group.value }}</p>
      </td>
    </tr>

    <ng-container
      *ngFor="let agent of (selectedAgents | agentDashboardFilter: 'Veechat Post' : group.key : selectedVisitors | keyvalue)">
      <tr *ngFor="let session of [].constructor(agent.value.currentSessionNumber - myVisitorsCount(agent.value))">
        <td>
          <p class="text-left">{{ agent.value.firstname }} {{ agent.value.lastname }}</p>
        </td>
        <td class="text-center" colspan="3">{{ "SUPERVISOR_HEADER_POSTENGAGEMENT"  | Translate : 'Post Engagement' }}</td>
      </tr>
    </ng-container>
  </ng-container>
  </tbody>
</table>
