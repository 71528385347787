import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EngagementAgent} from '../../services/engagement';
import {OnlineState} from '../../enums/online-state.enum';

import {AgentStatus} from '../../classes/visitor/AgentStatus';

export interface InviteRequest {
  message: string;
  username: string;
  fullname: string;
}

@Component({
  selector: 'app-engagement-transfer-select',
  templateUrl: './engagement-transfer-select.component.html',
  styleUrls: ['./engagement-transfer-select.component.scss']
})
export class EngagementTransferSelectComponent {
  @Input() crossSessionTransfer: boolean;
  @Input() transfer: boolean;
  @Input() roomAgents: ReadonlyMap<string, EngagementAgent> = new Map([]);

  @Output() cancelInvite = new EventEmitter<void>();
  @Output() inviteRequest = new EventEmitter<InviteRequest>();

  public showVeestudioAgents = false;

  public selectedAgent: AgentStatus;

  constructor() { }

  public sendInviteRequest(message) {
    if (this.selectedAgent) {
      const requiredStatus = this.showVeestudioAgents ? OnlineState.Available : OnlineState.MultiChat;

      if (this.selectedAgent.status === requiredStatus) {
        const inviteRequest: InviteRequest = {
          message,
          username: this.selectedAgent.username,
          fullname: `${this.selectedAgent.firstname} ${this.selectedAgent.lastname}`,
        };

        this.inviteRequest.emit(inviteRequest);
      } else {
        this.selectedAgent = null;
      }
    }
  }
}
