<div class="container-fluid border-bottom px-0 py-2">
  <div class="row d-flex align-items-center">
    <div class="col-2">
      <p-avatar size="normal" shape="square" [styleClass]="'agent-avatar ' + getStyleClassForOnlineState()" label="{{ photo ? undefined : StringUtils.getInitials(name) }}" image="{{ photo }}"> </p-avatar>
    </div>
    <div class="col d-flex flex-column">
      <p class="text-capitalize">{{ name }}</p>
    </div>
    <div class="col-4">
      <div class="d-flex flex-row flex-grow-1 justify-content-end">
        <p-button severity="secondary" (onClick)="updateMenuItems();menu.toggle($event)" [rounded]="true" *ngIf="hasVisibleMenuItems()"
                  type="button">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </p-button>

        <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="d-flex flex-row align-items-center gap-2 py-2 px-1" role="button">
              <i [class]="item.icon + ' font-size-2'"></i>
              <p class="text-truncate flex-grow-1 font-size-1">{{item.label}}</p>
              <p-inputSwitch  *ngIf="item.state && item.state?.toggled !== undefined" [ngModel]="item.state?.toggled" (onChange)="item.command($event)" [disabled]="item.disabled"></p-inputSwitch>
            </div>
          </ng-template>
        </p-menu>
         </div>
    </div>
  </div>
</div>
