import Call from "./Call";
import { Janus } from 'janus-gateway';
import Connection from './Connection';
import Constants from "./Constants";
import adapter from 'webrtc-adapter';

export default class CallStreaming extends Call {
    constructor({ 
        engagementId = null,
        peerId ='itsthetaste',
        iceServer = null,
        peerType = Constants.PeerType.Agent,
        mainPeerId = 'itsthetaste',
        speakerVolume = 1.0,
        mainVideoContainer = null,
        connectingOverlay = null,
        reConnectingOverlay = null,
        onHoldOverlay = null,
        peersContainer = null
    } = {}) {
        super({ 
            peerId,
            peerType,
            mainPeerId,
            speakerVolume,
            mainVideoContainer,
            connectingOverlay,
            reConnectingOverlay,
            onHoldOverlay,
            peersContainer
        });

        this.engagementId = engagementId;
        this.iceServer = iceServer;

        this.janusServer = null;
        this.connecting = false;
        this.reconnecting = false;
        
        this.currentFeedId = 0;
        this.currentPeerId = "";
        this.currentGatewayServer = "";

        this.streams = [];
        
        Janus.init({
            debug: false,
            dependencies: Janus.useDefaultDependencies({
                adapter,
            }),
            callback: function() {
                console.log("GATEWAY initialised");
            }
        });

        this.connectToJanus = (callback) => {
            this.connecting = true;
            this.janusServer = new Janus({
                server: 'wss://'+this.currentGatewayServer +'/janus',
                iceServers: Connection.getPeerConnectionConfig(this.engagementId, this.iceServer).iceServers,
                success: () => {
                    //console.log("janus connected");
                    this.connecting = false;

                    if (!this.reconnecting) {
                        if (callback) {
                            callback();
                        }
                    }
                    else {
                        this.reconnecting = false;
                        this.reconnectedToJanus();
                    }
                },
                error: (cause) => {
                    // Error, can't go on...
                    console.log("Gateway error: " + cause);
                    if (cause == "Lost connection to the server (is it down?)") {
                        this.connecting = false;
                        this.emit("Gateway:disconnected");
                        setTimeout(() => {
                            console.log("Retrying to Connect to Gateway")
                            this.reconnecting = true;
                            this.connectToJanus(callback);
                        }, 1000);
                    }
                },
                destroyed: () => {
                    console.log("Gateway connection destroyed");
                    this.janusServer = null;
                    this.connecting = false;
                }
            });
        }

        this.reconnectedToJanus = () => {
            for (var i = this.streams.length - 1; i >= 0; i -= 1) {
                let stream = this.streams[i];
                if (stream && stream.peerId && stream.feedId) {
                    this.streams.splice(i, 1);
                    this.attachToFeed(stream.feedId, stream.peerId);
                }                
            }

            this.emit("Gateway:reconnected");
        }

        this.disconnectFromJanus = () => {
            
            this.detachFromFeed(this.currentFeedId);

            if (this.janusServer) {
                this.janusServer.destroy();
            }
        }

        this.getStream = (feedId) => {
            var stream = null;
            for (var i = 0; i < this.streams.length; ++i) {
                if (this.streams[i].feedId === feedId) {
                    stream = this.streams[i];
                    break;
                }
            }
            return stream;
        };

        this.deleteStream = (feedId) => {
            for (var i = 0; i < this.streams.length; ++i) {
                if (this.streams[i].feedId === feedId) {
                    this.streams.splice(i,1);
                    break;
                }
            }
        };

        this.attachToFeed = (feedId, peerId) => {
            var streamingHandle = null;
            this.janusServer.attach({
                plugin: "janus.plugin.streaming",
                success: (pluginHandle) => {
                    streamingHandle = pluginHandle;
                    streamingHandle.feedId = feedId;
                    streamingHandle.peerId = peerId;
                    
                    this.streams.push(streamingHandle);
                    
                    var body = { "request": "watch", id: parseInt(feedId) };
                    streamingHandle.send({"message": body});
                },
                onmessage: (msg, jsep) => {
                    // Handle msg, if needed, and check jsep
                    if (jsep !== undefined && jsep !== null) {
                        // We have an OFFER from the plugin
                        streamingHandle.createAnswer({
                            // We attach the remote OFFER
                            jsep: jsep,
                            // We want recvonly audio/video
                            media: { audioSend: false, videoSend: false },
                            success: (ourjsep) => {
                                // Got our SDP! Send our ANSWER to the plugin
                                var body = { "request": "start" };
                                streamingHandle.send({"message": body, "jsep": ourjsep});
                            },
                            error: (error) => {
                                // An error occurred...
                            }
                        });
                    }
                },
                onlocalstream: (stream) => {
                    // This will NOT be invoked, we chose recvonly
                },
                onremotestream: (stream) => {
                    // Invoked after send has got us a PeerConnection
                    // This is the remote video
                    //console.log("we have a video" + stream);                        
                    var gatewayVideoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
                    if (gatewayVideoTrack) {
                        this.ui.addPeerTracks({
                            peerId : "FEED_" + peerId,
                            track  : gatewayVideoTrack,
                            speakerId: this.devices.selectedSpeaker ? this.devices.selectedSpeaker.deviceId : null
                        }); 
                    }                
                },
                oncleanup: () => {
                    
                }               
            });
        }

        this.detachFromFeed = (feedId) => {
            console.log(`DetachFromFeed ${feedId}`);
            const stream = this.getStream(feedId);
            if (stream) {
                //console.log(`Stream Exists Stop and detach ${feedId}`);
                var body = { "request": "stop" };
                stream.send({"message": body});
                stream.detach({
                    success: () => {
                        console.log(`Feed Detached ${feedId}`);
                        this.deleteStream(feedId);
                    },
                    error: () => {
                        console.log(`Error detaching from Feed ${feedId}`);
                        this.deleteStream(feedId);
                    }
                });
                
            }
        }
    }

    dispose() {
        // add a detach all
        
        this.streams = [];

        this.disconnectFromJanus();
        this.currentFeedId = 0;
        this.currentPeerId = "";
        this.currentGatewayServer = "";

        super.dispose();
    }

    getFeed(peerId, feedId, gatewayServer) {
        this.currentFeedId = feedId;
        this.currentPeerId = peerId;
        this.currentGatewayServer = gatewayServer;

        if (this.janusServer != null && this.janusServer.isConnected()) {
            this.attachToFeed(feedId, peerId);
        }
        else {
            this.connectToJanus(() => {
                this.attachToFeed(feedId, peerId);
            });
        }       
    }

    stopFeed() {
        this.detachFromFeed(this.currentFeedId);
        this.currentFeedId = 0;
        this.currentPeerId = "";
    }

    pauseFeed() {
        this.detachFromFeed(this.currentFeedId);
    }

    resumeFeed() {
        if (this.currentFeedId !== 0 && this.currentPeerId !== "" && this.currentGatewayServer !== "") {
            this.getFeed(this.currentPeerId, this.currentFeedId, this.currentGatewayServer);
        }
    }

}