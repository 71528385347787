import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Features, FeatureService} from '../../../../services/feature-service/feature.service';
import {faBan, faCircleCheck, faCircleXmark, faRightLeft, faTimesCircle, faUsers, faXmark} from '@fortawesome/free-solid-svg-icons';
import {VerifyStatuses} from '../../../../services/engagementhub.service';

@Component({
  selector: 'app-veechat-conversation-controls',
  templateUrl: './veechat-conversation-controls.component.html',
  styleUrls: ['./veechat-conversation-controls.component.scss']
})
export class VeechatConversationControlsComponent implements OnInit {

  constructor(private readonly featureService: FeatureService) {
  }
  @Input() ended = true;
  @Input() isBlocked: boolean;

  @Output() end = new EventEmitter<void>();
  @Output() blockCustomer = new EventEmitter<any>();
  @Output() transferCold = new EventEmitter<void>();
  @Output() transferWarm = new EventEmitter<void>();

  @Input() hasCustomerVerification: boolean;
  @Input() verifyStatus: VerifyStatuses = VerifyStatuses.Pending;
  @Output() toggleVerifyCustomer = new EventEmitter();

  protected hasTransfer: boolean;

  protected readonly faTimesCircle = faTimesCircle;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faBan = faBan;
  protected readonly faRightLeft = faRightLeft;
  protected readonly faUsers = faUsers;
  protected readonly faXmark = faXmark;

  ngOnInit() {
    this.hasTransfer = true;
    this.hasCustomerVerification = false; //this.featureService.has(Features.VERIFY_ASYNC_CUSTOMER);
  }

  verifyCustomer() {
    this.toggleVerifyCustomer.emit();
  }
}
