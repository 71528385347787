import { Component, OnInit } from '@angular/core';
import { VersionService } from '../../services/version-service/version.service';
import { first, tap } from 'rxjs/operators';
import {Themes, ThemeService} from "../../services/theme/theme.service";

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss']
})
export class AboutModalComponent implements OnInit {

  public readonly DEFAULT_VERSION: string = "";
  public version: string = this.DEFAULT_VERSION;
  public appVersion: string = this.DEFAULT_VERSION;

  public darkMode: boolean = false;


  constructor(private versionService: VersionService,
              private themeService: ThemeService) { }

  ngOnInit() {
    this.version = this.versionService.fullVersion;
    this.appVersion = this.versionService.electronVersion;
    this.darkMode = this.themeService.getTheme() === 'dark';
  }

  onThemeChange(darkMode: boolean) {
    this.themeService.setTheme(darkMode ? Themes.DARK : Themes.LIGHT);
    this.darkMode = darkMode;
  }

}
