import { Component, OnInit, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { SiteVisitor } from '../../../services/visitor-service/SiteVisitor';
import { AgentStatus } from '../../../classes/visitor/AgentStatus';
import { faSignInAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { TimeUtil } from '../../../utils/time-util';
import { OnlineState } from '../../../enums/online-state.enum';
import { DashboardData } from '../../../services/visitor-service/dashboard-data';
import { DashboardCardType } from './dash-panel/dash-panel.component';
import { KpiSetting } from '../../../services/dashboard-service/kpisetting';
import { ProductMode } from '../supervisor.component';
import {Joined, Snooping, SnoopingConfiguration } from '../supervisor.component';

@Pipe({
  name: 'agentDashboardFilter',
  pure: false
})
export class AgentDashboardFilterPipe implements PipeTransform {
  transform(agents: Map<string, AgentStatus>, status: string, groupId: number, visitors?: SiteVisitor[]):  Map<string, AgentStatus> {
    const filtered: Map<string, AgentStatus> = new Map();

    const groupAgents = new Map([...agents].filter(([_, v]) => v.groups.some(g => g.Id === groupId)));

    switch (status) {
      case 'On Break':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status === OnlineState.OnBreak) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Not On Break':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status !== OnlineState.OnBreak && info.status !== OnlineState.Supervisor) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Available':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status === OnlineState.Available) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Not Available':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status !== OnlineState.Available && info.status !== OnlineState.Supervisor) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Post Engagement':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status === OnlineState.PostEngagement) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Multichat Available':
        for (const [username, info] of groupAgents.entries()) {
          if (info.status === OnlineState.MultiChat) {
              filtered.set(username, info);
          }
        }
      return filtered;

      case 'Veechat Post':
        for (const [username, info] of groupAgents.entries()) {
          const myVisitorsCount = visitors.filter(v => v.currentOperator === info.firstname + ' ' + info.lastname).length;
          if (info.status === OnlineState.MultiChat && info.currentSessionNumber > myVisitorsCount) {
            filtered.set(username, info);
          }
        }
      return filtered;
    }
  }
}

@Component({
  selector: 'app-supervisor-dashboard-view',
  templateUrl: './supervisor-dashboard-view.component.html',
  styleUrls: ['./supervisor-dashboard-view.component.scss'],
  providers: []
})
export class SupervisorDashboardViewComponent implements OnInit {
  @Input() supervisingVisitors: Map<string, Snooping> = new Map([]);
  @Input() supervisingEngagements: Map<string, Joined> = new Map([]);
  @Input() selectedVisitors: SiteVisitor[];
  @Input() notEngagedVisitors: SiteVisitor[];
  @Input() selectedAgents: Map<string, AgentStatus>;

  // selectedGroups is not the selected groups in the hovis UI (that drop down in veesupervisor)
  // but the groups the supervisor belongs to (i.e. select in my.vee24.com)
  @Input() selectedGroups: Map<number, string>;
  @Input() mode: ProductMode;
  @Input() privateChatAvailable: boolean;

  @Input() kpiData: KpiSetting[];
  @Input() dashboardData: DashboardData;

  @Output() enterSession: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();
  @Output() startSnooping: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();

  public currentCardSelection: DashboardCardType;
  DashboardCardType = DashboardCardType;

  public TimeUtil = TimeUtil;
  public faSignIn = faSignInAlt;
  public faPhone = faPhone;

  constructor() {
   }

  ngOnInit() {
  }

  pullCardDetails(type: DashboardCardType) {
    this.currentCardSelection = type;
  }
}
