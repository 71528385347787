import {TransferFile} from './transfer-file';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export class TokenTransferFile extends TransferFile {
  constructor(protected http: HttpClient,
              protected endpointUrl: string,
              protected file: File,
              private uploadToken: string,
              private site: string,
              private conversationId: string) {
    super(http, endpointUrl, file);
  }

  protected startFileTransfer(): boolean {
    try {
      const headers: Map<string, string> = new Map([
        [ 'vee24UploadToken', this.uploadToken ]
      ]);

      const data: FormData = new FormData();
      data.append('files', this.file);
      data.append('site', this.site);
      data.append('customerGuid', '');
      data.append('engagementGuid', this.conversationId);

      this.sendData(headers, this.endpointUrl, data);
    } catch (err) {
      return false;
    }

    return true;
  }

  protected deleteCancelledUpload() {
    const httpHeaders = new HttpHeaders({
      'token': this.uploadToken
    });

    const cancelUrl = `${this.endpointUrl}/${this.site}?directory=${this.conversationId}&blob=${this.filename}`;
    this.http.delete(cancelUrl, { headers: httpHeaders }).subscribe();
  }
}
