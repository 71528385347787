export enum BrowserServiceStatus {
    NONE = 0,
    LOADING = 1,
    LOADED = 2,
    FAILED = 3,
    CREATED = 4,
}

export interface IBrowserService {
    setStatus(status: BrowserServiceStatus): void;
}