<div *ngIf='!crmInformationView' class="h-100">
  <div class=" d-flex flex-column flex-grow-1 h-100">
    <div class="d-flex flex-column flex-grow-1 overflow-hidden">
      <div class="d-flex flex-row align-items-center justify-content-between pt-2 pl-2">
        <h2 class="primary-color-text">{{ "APPOINTMENTSEARCH_HEADER" | Translate : 'Appointments' }}</h2>

        <div class="d-flex flex-row gap-2">
          <p-button (onClick)="newAvailabilityBlock()" id="availability-block-btn" tooltipPosition="left"
                    [pTooltip]="'APPOINTMENTS_LABEL_AVAILABILITY_BLOCK' | Translate : 'Create Unavailable Block'"
                    severity="danger">
            <fa-icon [icon]="faClockFour"></fa-icon>
          </p-button>
          <p-button *ngIf="canCreateAppointment"
                    id="new-appointment-btn"
            (onClick)="showAppointmentSidebar = true;" tooltipPosition="left"
            [pTooltip]="'APPOINTMENTS_LABEL_CREATE' | Translate : 'Create Appointment'">
            <fa-icon [icon]="faCalendarPlus"></fa-icon>
          </p-button>
        </div>

      </div>
      <p-tabMenu (activeItemChange)="onTabChange($event)" [activeItem]="activeItem" [model]="menu"
                 styleClass="justify-end">
        <ng-template let-item pTemplate="item">
          <p-button (onClick)="onTabChange(item)" [severity]="item === activeItem ? 'primary' : 'secondary'"
                    class="mb-1"
                    label="{{ item.label }}"
                    text="true">
            <fa-icon [icon]="item.faIcon" class="mr-2"></fa-icon>
          </p-button>
        </ng-template>
      </p-tabMenu>
      <p-toolbar *ngIf="!agentsCalendarView"
                 styleClass="flex-nowrap">
        <div class="p-toolbar-group-start gap-4 align-items-center w-100 container-fluid">

          <ng-container *ngIf="activeItem !== menu[2] && !crmInformationView">

            <div class="row w-100">
              <div class="col-2 form-item">
                <label>{{ "APPOINTMENTSEARCH_LABEL_APPOINTMENTTYPE" | Translate : 'Appointment Type' }} </label>
                <p-dropdown (onChange)="onChangeAppointmentType($event)"
                            [(ngModel)]="this.appointmentFilterParameters.appointmentTypeId" [filter]='true'
                            [options]="(appointmentTypes)" [resetFilterOnHide]="true" [showClear]="false"
                            id="appointmenttype" optionLabel="name" styleClass="w-100"
                            optionValue="id"></p-dropdown>


              </div>
              <div class="col-2  form-item">
                <label>{{ "APPOINTMENTSEARCH_LABEL_CALLTYPE" | Translate : 'Call Type' }} </label>
                <p-dropdown (onChange)="onCallTypeChange($event)"
                            [(ngModel)]="this.appointmentFilterParameters.callType"
                            [filter]='true' [options]="(sessionTypes)" [resetFilterOnHide]="true" [showClear]="false"
                            id="calltype" optionLabel="Label" optionValue="Label"></p-dropdown>


              </div>
              <div class="col-2 form-item">
                <label>{{ "APPOINTMENTSEARCH_LABEL_STATUS" | Translate : 'Status' }} </label>
                <p-dropdown (onChange)="onStatusChange($event)" [(ngModel)]="this.appointmentFilterParameters.status"
                            [filter]='true' [options]="(statusMapping)" [resetFilterOnHide]="true"
                            [showClear]="false" id="status" optionLabel="status"
                            optionValue="value"></p-dropdown>


              </div>
              <div class="col-2 form-item">
                <label>{{ "APPOINTMENTSEARCH_LABEL_AGENT" | Translate : 'Agent' }} </label>
                <p-multiSelect (onChange)="onChangeAgent($event)" (onClear)="search()"
                               [(ngModel)]="this.appointmentFilterParameters.agents"
                               [options]="agentLists" [showClear]="true" autocomplete="true"
                               id="agentfilter" optionLabel="realname" optionValue="username"
                               placeholder="Select Agents"
                               resetFilterOnHide="true"></p-multiSelect>
              </div>

              <div class="col-2 form-item">
                <label for="from">{{ "APPOINTMENTSEARCH_LABEL_FROM" | Translate : 'From' }} </label>
                <input (input)="onChangeStartDate($event)" [(ngModel)]="this.appointmentFilterParameters.from"
                       id="from" pInputText
                       type="date" value="cFrom">
              </div>

              <div class="col-2 form-item">
                <label for="to">{{ "APPOINTMENTSEARCH_LABEL_TO" | Translate : 'To' }}</label>
                <input (input)="onChangeEndDate($event)" [(ngModel)]="this.appointmentFilterParameters.to"
                       id="to" pInputText
                       type="date" value="cTo">
              </div>
            </div>


          </ng-container>
        </div>

      </p-toolbar>

      <div *ngIf="!agentsCalendarView && !crmInformationView" class="flex-grow-1 m-2 overflow-hidden">
        <p-table #appointmentTable (onLazyLoad)="onLazyLoad($event)" (onSort)="onSort($event)" [lazy]="true"
                 [loading]="loading" tableStyleClass="overflow-auto"
                 [paginator]="true" rowExpandMode="single"
                 [rows]="30" [scrollable]="true" [showCurrentPageReport]="true"
                 [totalRecords]="(count | async)" [(selection)]="selectedRow"
                 [value]="(rows | async)" styleClass="p-datatable-striped"
                 currentPageReportTemplate="{first} - {last} of {totalRecords}"
                 dataKey="appointmentId" scrollHeight="flex">
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of columns" [class]="col.class ?? ''" [pSortableColumn]="col.prop">
                {{ col.name }}
                <p-sortIcon [field]="col.prop"></p-sortIcon>
              </th>
            </tr>
          </ng-template>

          <ng-template let-expanded="expanded" let-row pTemplate="body">
            <tr (click)="selectRow(row)" [pRowTogglerDisabled]="isUnavailableRow(row)"
                [pRowToggler]="row"
                role="button">
              <td *ngFor="let col of columns" [class]="col.class ?? ''">
                <ng-container [ngSwitch]="col.prop">
                  <ng-container *ngSwitchCase="'callType'">
                    <p-chip [icon]="getCallTypeIconForRow(row)" [label]="getCallTypeLabelForRow(row)"
                            [styleClass]="getCallTypeStyleForRow(row)"></p-chip>
                  </ng-container>
                  <ng-container *ngSwitchCase="'status'">
                    <p-chip [label]="getTranslatedStatus(row[col.prop])" [styleClass]="getColorForRow(row)"></p-chip>
                  </ng-container>
                  <ng-container *ngSwitchCase="'appointmentDate'">
                    <p>{{ getAppointmentDateForRow(row) }}</p>
                  </ng-container>
                  <ng-container *ngSwitchCase="'startTime'">
                    <p>{{ row.startDate | localDateTime : 'hh:mm A' : timezone }}</p>
                  </ng-container>
                  <ng-container *ngSwitchCase="'endTime'">
                    <p>{{ row.endDate | localDateTime : 'hh:mm A' : timezone }}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template let-row pTemplate="rowexpansion">
            <tr class="expanded-row">
              <td [attr.colspan]="columns.length">
                <app-appointment-row-detail (filterData)='search()'
                                            (showCrmInformationView)='showCRMInformation($event)'
                                            [appointment]='row'
                                            [isOpen]="isOpen(row)"
                                            [inSupervisorMode]='inSupervisorMode()'>
                </app-appointment-row-detail>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div *ngIf='agentsCalendarView' class='h-100 mb-2 overflow-auto '>
        <app-agent-calendar></app-agent-calendar>
      </div>
    </div>
  </div>
</div>
<div *ngIf='crmInformationView' class="h-100 overflow-hidden">
  <app-crm (backToForm)='onBackToForm()'
           *ngIf='crmInformationView'
           [selectSessionHistoryTab]='selectSessionHistoryTab'
           [selected]='selectedContact'
           class="h-100">
  </app-crm>
</div>

<div class="d-flex flex-column justify-content-center">

  <p-sidebar [(visible)]="showAppointmentSidebar" position="right" styleClass="w-50">
    <ng-template pTemplate="header">
      <h3 class="primary-color-text">{{ "APPOINTMENTS_LABEL_CREATE" | Translate : 'Create Appointment' }}</h3>
    </ng-template>
    <app-vee24-progress-spinner [loading]="submitting"></app-vee24-progress-spinner>
    <app-appointments [isEditing]="true" (submitAppointment)="createAppointment($event)"
                      (cancelCreateAppointment)="showAppointmentSidebar = false;"
                      [resetContents]="!showAppointmentSidebar"
    ></app-appointments>
  </p-sidebar>


  <p-sidebar (onHide)="hideAvailabilitySidebar()" [visible]="isUnavailableRow(selectedAppointment$ | async)" position="right"
             styleClass="w-25">
    <ng-template pTemplate="header">
      <h3
        class="primary-color-text">{{ "APPOINTMENTS_LABEL_CREATE_UNAVAILABLE_BLOCK" | Translate : 'Create Unavailable Block' }}</h3>
    </ng-template>
    <app-availability-block (onUpdate)="search()" [appointment]="(selectedAppointment$ | async)"
                            [resetContents]="!isUnavailableRow(selectedAppointment$ | async)" [timezoneAbrv]="timezoneAbrv" [timezone]="timezone">
    </app-availability-block>
  </p-sidebar>
</div>

