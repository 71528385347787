import { Component, OnInit } from '@angular/core';
import { Agent } from '../../../classes/agent';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import { EngagementService } from '../../../services/engagement.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AboutModalComponent } from '../../../components/about-modal/about-modal.component';
import {ModalService } from '../../../services/modal.service';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-mobile-presenter-navbar',
  templateUrl: './mobile-presenter-navbar.component.html',
  styleUrls: ['./mobile-presenter-navbar.component.scss']
})
export class MobilePresenterNavbarComponent implements OnInit {
  public currentAgent: Agent = null;
  public isActiveEngagement = false;
  public items: MenuItem[];

  faBars = faBars;


  constructor(
    private authService: AuthService,
    private router: Router,
    private onlineService: OnlineService,
    private engagementService: EngagementService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.authService.currentAgent.subscribe(agent => {
      this.currentAgent = agent;
    });

    this.onlineService.currentState.subscribe(([state, _]) => {
      this.isActiveEngagement = state === OnlineState.MobilePresenterEngaged;
    });

    this.items = [
      {
        label: 'Logout',
        command: () => this.leaveCallAndLogout()
      },
      {
        label: 'About',
        command: () => this.showAbout()
      }
    ];
  }

  showAbout(): void {
    // Uses open method because this doesn't include a close call due to dismissableMask
    this.modalService.open(AboutModalComponent, {
      header: 'About',
      closable: true,
      showHeader: true,
      dismissableMask: true,
    });
  }

  leaveCallAndLogout(): void {
    if (this.engagementService.engagements.value.length > 0) {
      const engagement = this.engagementService.engagements.value.find((eng) => {
        return eng.engagementId.toString() === this.activatedRoute.firstChild.snapshot.params['engagementId'];
      });

      if (engagement) {
        engagement.leaveChat();
      }
    }

    this.authService.logout().subscribe(success => {
      if (success) {
        this.router.navigateByUrl('/login');
      }
    });
  }
}
