<div>
  <div *ngIf="crossSessionTransfer">
      <p-toolbar styleClass="flex-nowrap d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-row justify-content-between w-100 ">
          <div class="d-flex flex-row p-buttonset">
            <p-button id="text-agents-btn" (onClick)="displayAgents(false)" [severity]="!showVeestudioAgents ? 'primary' : 'secondary'">
              {{ "ENGAGEMENTTRANSFER_BUTTON_TEXT" | Translate : 'Text' }}
            </p-button>
            <p-button  id="video-agents-btn" (onClick)="displayAgents(true)" [severity]="showVeestudioAgents ? 'primary' : 'secondary'">
              {{ "ENGAGEMENTTRANSFER_BUTTON_VIDEO" | Translate : 'Video' }}
            </p-button>
          </div>
          <p-button id="sortorder-btn" (onClick)="toggleSortField()" [severity]="orderByDesc ? 'primary' : 'secondary'"   [disabled]="(this.totalAgentStatus | async | agentFilter : showVeestudioAgents : selectedDepartment : roomAgents | keyvalue).length <= 1">
            <fa-icon size="lg" [icon]="orderByDesc ? faArrowDownAZ : faArrowUpAZ"></fa-icon>
          </p-button>
        </div>

        <p-dropdown [filter]="(departments$ | async)?.length > 10" class="w-100" [showClear]="true" [options]="departments$ | async" [(ngModel)]="selectedDepartment" placeholder="{{'ENGAGEMENTTRANSFER_BUTTON_OPERATORGROUP' | Translate : 'All Operator Groups'}}" (onChange)="selectDepartment($event.value)" appendTo="body"></p-dropdown>
    </p-toolbar>
  </div>
</div>

<p-listbox scrollHeight="auto" class="flex-grow-1 overflow-auto" optionValue="value" [options]="(this.totalAgentStatus | async | agentFilter : showVeestudioAgents : selectedDepartment : roomAgents | keyvalue) | orderBy : orderByField" [(ngModel)]="selectedAgent" optionLabel="lastname + ', ' + firstname" [style]="{'height': '100%'}">
  <ng-template let-agent pTemplate="item">
    <div class="d-flex flex-row align-items-center justify-content-between w-100 py-2 gap-2">
      <p class="flex-grow-1">{{agent.value.lastname}}, {{agent.value.firstname}}</p>
      <p-badge [value]="timeSinceLastStatusChange(agent.value.timestamp, (currentDate | async))"></p-badge>
    </div>
  </ng-template>
</p-listbox>
