export interface Channel {
  Type: Channels;
}

export enum Channels {
    Vee24 = 1,
    WhatsApp = 2,
    Sms = 3,
    Messenger = 4,
}
