import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class WebrtRTCMessagingService {
  private clientMessages: Subject<any> = new Subject();
  private serverMessages: Subject<any> = new Subject();

  public clientMessages$: Observable<any> = this.clientMessages.asObservable();
  public serverMessages$: Observable<any> = this.serverMessages.asObservable();

  constructor() {
  }

  public sendMessageToClient(message: any) {
    this.clientMessages.next(message);
  }

  public sendMessageToServer(message) {
    this.serverMessages.next(message);
  }
}
