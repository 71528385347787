<ng-container [ngSwitch]="transferViewState">
  <ng-container *ngSwitchCase="ModalViewState.Accepting">
    <div id="accepting" class="d-flex flex-column gap-4 h-100">
      <div class="flex-grow-1 container-fluid m-0 p-1">
        <div class="row ">
          <div class="col-3">
            <p id="from" class="text-capitalize">{{ textResources.from }}</p>
          </div>
          <div class="col-9">
            <p id="fullname">{{ transferringOperator }}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-3">
            <p id="reason" class="text-capitalize">{{ textResources.reason }}</p>
          </div>
          <div class="col-9">
            <p>{{ transferringReason }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngIf="hasTransferChat" class="chat-transcript-container">
              <div #scrollMe [scrollTop]="scrollMe.scrollHeight"
                   class="d-flex flex-column flex-grow-1 messages-container">
                <app-text-chat
                  *ngFor="let message of invitationRequest.messages"
                  [message]="message">
                </app-text-chat>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-engagement-transfer-chat-box
              (sendMessage)="sendMessage($event)"
              *ngIf="hasTransferChat" class="d-block p-2"
              style="padding: 1rem 1rem;">
            </app-engagement-transfer-chat-box>
          </div>
        </div>

        <div class="row">
          <div class="col-12 no-gutters d-flex flex-column gap-2 ">
            <p id="sub-title" class="lead mt-4">{{ textResources.transcriptTitle }}</p>
            <div class="chat-transcript-container">
              <div class="d-flex flex-column flex-grow-1 messages-container border">

                <ng-container *ngFor="let message of textMessages">
                  <app-text-chat [message]="message"></app-text-chat>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row gap-2 justify-content-end align-items-center">
        <p-button (clicked)="rejectTransferringInConfirmation()" appDebounceClick
                  id="reject-btn" severity="secondary" [text]="true"> {{
            "MODAL_BUTTON_REJECT" | Translate : "Reject"
          }}
        </p-button>
        <p-button (clicked)="acceptCallback()" severity="primary" appDebounceClick id="accept-btn"  >{{
            "MODAL_BUTTON_ACCEPT" |
              Translate : "Accept"
          }}
        </p-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ModalViewState.Rejecting">

    <div id="rejecting" class="d-flex flex-column gap-4 h-100">
      <div class="flex-grow-1 container-fluid m-0 p-1">
        <div class="row ">
          <div class="col-3">
            <p id="from" class="text-capitalize">{{ textResources.from }}</p>
          </div>
          <div class="col-9">
            <p>{{ transferringOperator }}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-3">
            <p id="reason" class="text-capitalize">{{ textResources.reason }}</p>
          </div>
          <div class="col-9">
            <p>{{ transferringReason }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12  no-gutters d-flex flex-column gap-2">
            <p id="sub-title" class="lead mt-4">{{ textResources.rejectionReasonTitle }}</p>
            <div class="mb-2" id="reject-reason-editor">
              <p-editor (onInit)="onEditorCreated($event)" [(ngModel)]="rejectionText" [formats]=""
                        [modules]="quillModules"
                        placeholder='{{ "ENGAGEMENTTEXTCHAT_PLACEHOLDER_TYPEMESSAGE" | Translate : "Type your message here..." }}'
                        styleClass="w-100">
                <ng-template class="p-2" pTemplate="header">
                  <div *ngIf="(rejectionReasons | async).length > 0">
                    <p-dropdown #rejectionReasonSelector (onChange)="onRejectionReasonSelected($event?.value)"
                                [options]="(rejectionReasons | async)"
                                [resetFilterOnHide]="true" [showClear]="false" class="drop-up"
                                optionGroupLabel="text"
                                optionLabel="Title"
                                optionValue="Title"
                                placeholder='{{ "INVITATIONMODAL_PLACEHOLDER_SELECTREASON" | Translate : "Select a Rejection Reason..." }}'>

                      <ng-template let-option let-text="text">
                        <span [innerHTML]="text" class="rejection-reason-text"></span>
                      </ng-template>

                    </p-dropdown>
                  </div>
                </ng-template>

              </p-editor>
            </div>
          </div>

        </div>
      </div>

      <div class="d-flex flex-row gap-2 justify-content-end align-items-center">
        <p-button (clicked)="cancelCallback()" appDebounceClick id="cancel-reject-btn"
                  severity="secondary" [text]="true" >{{
            "MODAL_BUTTON_CANCEL" | Translate : "Cancel"
          }}
        </p-button>
        <p-button (clicked)="onReject()" appDebounceClick id="confirm-reject-btn" severity="danger">{{
            "MODAL_BUTTON_REJECT" |
              Translate : "Reject"
          }}
        </p-button>
      </div>
    </div>
  </ng-container>
</ng-container>
