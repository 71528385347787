import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '../../../../filters/Translate.pipe';
import {faComment, faLock, faRightToBracket, faUserSecret} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-supervisor-engagement-controls',
  templateUrl: './supervisor-engagement-controls.component.html',
  styleUrls: ['./supervisor-engagement-controls.component.scss'],
  providers: [TranslatePipe]
})
export class SupervisorEngagementControlsComponent implements OnInit {
  @Input() privateChatEnabled: boolean;
  @Input() publicChatEnabled: boolean;

  @Input() publicChatAvailable: boolean = true;
  @Input() privateChatAvailable: boolean;

  @Output() stopSnooping: EventEmitter<void> = new EventEmitter<void>();
  @Output() startPrivateChat: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() startPublicChat: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translate: TranslatePipe) { }

  ngOnInit() {
  }

  endMonitoring() {
    this.stopSnooping.emit();
  }

  togglePrivateChat() {
    if (this.privateChatEnabled) {
      this.startPrivateChat.emit(false);
    } else {
      this.startPrivateChat.emit(true);
    }
  }

  togglePublicChat() {
    if (this.publicChatEnabled) {
      this.startPublicChat.emit(false);
    } else {
      this.startPublicChat.emit(true);
    }
  }

  privateChatTitle() {
    if (this.privateChatEnabled) {
      return this.translate.transform("SUPERVISORSNOOPING_TOOLTIP_LEAVEPRIVATECHAT", 'Leave Private Chat');
    } else {
      return this.translate.transform("SUPERVISORSNOOPING_LABEL_JOINPRIVATECHAT", 'Join Private Chat');
    }
  }

  publicChatTitle() {
    if (this.publicChatEnabled) {
      return this.translate.transform("SUPERVISORSNOOPING_TOOLTIP_LEAVEPUBLICCHAT", 'Leave Public Chat');
    } else {
      return this.translate.transform("SUPERVISORSNOOPING_LABEL_PUBLICCHAT", 'Join Public Chat');
    }
  }

  protected readonly faRightToBracket = faRightToBracket;
  protected readonly faUserSecret = faUserSecret;
  protected readonly faLock = faLock;
  protected readonly faComment = faComment;
}
