<section class="d-flex justify-content-center align-items-center my-2 mr-1"
>
  <p class="control-label">{{ controlName }}</p>

  <div class="control d-flex flex-grow-1 justify-content-center align-items-center ml-1"
       [class.disabled]="property?.flag === CameraControlFlags.AUTO">

    <p-button id="step-down-btn" size="small" [text]="true" class="slider-btn"
       *ngIf="showSliderButtons"
       [disabled]="property.value === property.minimum" (click)="decreaseVal()">
      <fa-icon [icon]="faMinus"></fa-icon>
    </p-button>

    <input #slider type="range" class="slider" id="slider" [step]="property?.stepInterval" [min]="property?.minimum"
           [max]="property?.maximum" [value]="property?.value" [title]="property?.value"
           [disabled]="property?.flag === CameraControlFlags.AUTO" (change)="onChange($event)">

    <p-button id="step-up-btn" size="small" class="slider-btn"
       *ngIf="showSliderButtons" [text]="true"
       [disabled]="property.value === property.maximum" (click)="increaseVal()">
      <fa-icon [icon]="faPlus"></fa-icon>
    </p-button>
  </div>
  <input class="checkbox ml-2" id="checkbox" *ngIf="property?.flags.length > 1"
         type="checkbox"
         [checked]="property?.flag === CameraControlFlags.AUTO" (change)="onFlagChange()"
         pTooltip='{{ "CAMERACONTROLS_CAMERACONTROL_MODE"  | Translate : "Mode (Auto/Manual)" }}' tooltipStyleClass="v24-checkbox-tooltip" positionLeft="-40" tooltipPosition="top"/>
</section>
