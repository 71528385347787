import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerSizes, PanelSize } from '../../../../../services/engagement';
import { OnlineService } from '../../../../../services/online.service';

@Component({
  selector: 'app-panel-size-slider',
  templateUrl: './panel-size-slider.component.html',
  styleUrls: ['./panel-size-slider.component.scss']
})
export class PanelSizeSliderComponent implements OnInit {
  @Output() changePanelSize = new EventEmitter<PanelSize>();
  @Input() currentPanelPosition: CustomerSizes;


  constructor() { }

  ngOnInit() {
  }

  onPanelSizeChange(event: Event) {
    // @ts-ignore
    const pos: number = parseInt(event.target.value, 10);
    switch (pos) {
      case 0:
        this.changePanelSize.emit(PanelSize.Small);
        break;
      case 2:
        this.changePanelSize.emit(PanelSize.Big);
        break;
      case 3:
        this.changePanelSize.emit(PanelSize.HD);
        break;
      case 1:
      default:
        this.changePanelSize.emit(PanelSize.Normal);
        break;
    }
  }
}
