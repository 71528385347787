<div [ngSwitch]="message.senderType">
  <div *ngSwitchCase="TextMessageSenders.ME"
       class="message agentMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"
         [class.failed]="message.deliveryStatus === DeliveryStatus.Failed"
         [class.sending]="message.deliveryStatus === DeliveryStatus.Sending">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span>
    </div>
    <div class="chatContent translatedMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
    <app-delivery-status [deliveryStatus]="message.deliveryStatus" [deliveryTimestamp]="message.deliveryTimestamp">
    </app-delivery-status>
  </div>

  <div *ngSwitchCase="TextMessageSenders.OTHER_AGENT"
       class="message agentMessage otherAgentMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"><span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span></div>
    <div class="chatContent translatedMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.ME_OLD_PRIVATE_CHAT"
       class="message oldAgentMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"><span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span></div>
    <div class="chatContent translatedMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.OTHER_AGENT_OLD_PRIVATE_CHAT"
       class="message oldAgentMessage oldOtherAgentMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"><span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span></div>
    <div class="chatContent translatedMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.CUSTOMER"
       class="message customerMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName?.length > 0 ? senderName : 'CUSTOMER_LABEL' | Translate : 'Customer'}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"  [class.verified]="message.verified === true" [class.unverified]="message.verified === false"><span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span></div>
    <div class="chatContent originalMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.OTHER_CUSTOMER"
       class="message customerMessage otherCustomerMessage">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <span class="sender-name font-size-0">{{senderName?.length > 0 ? senderName : 'PARTICIPANT_LABEL' | Translate : 'Participant'}}</span>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div class="chatContent"  [class.verified]="message.verified === true" [class.unverified]="message.verified === false"><span class="mw-100 d-block reset-wrap" [innerHTML]="message.message"></span></div>
    <div class="chatContent originalMessage" *ngIf="message.originalMessage">
      <span class="mw-100 d-block reset-wrap" [innerHTML]="message.originalMessage"></span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.TRANSFER_MESSAGE"
       class="message px-0 my-2">
    <div class="d-flex flex-row align-items-center justify-content-between gap-2 pb-1">
      <fa-icon [class]="CallStatuses.getStatusFontIcon(VisitorStatus.Transferring).css" [icon]="CallStatuses.getStatusFontIcon(VisitorStatus.Transferring).icon"></fa-icon>
      <div class="flex-grow-1">
        <span class="transfer-header">{{ "TEXT_CHAT_CUSTOMER_TRANSFERRED"  | Translate : 'Customer Transferred' }}</span>
        <div><span class="mw-100 d-block reset-wrap transfer-content" [innerHTML]="message.message"></span></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.INTERNAL_MESSAGE"
       class="message">
    <div class="d-flex flex-row">
      <div class="w-100 text-center"><span class="mw-100 d-block reset-wrap font-italic">{{message.message}}</span></div>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.SESSION_START" class="divider-message my-2">
    <div class="d-flex flex-row align-items-center justify-content-between">
      <fa-icon [class]="CallStatuses.getStatusFontIcon(VisitorStatus.Engaged).css" [icon]="CallStatuses.getStatusFontIcon(VisitorStatus.Engaged).icon"></fa-icon>
      <div class="text-left mt-auto mb-auto flex-grow-1">
        <p class="mw-100 d-block reset-wrap font-weight-bold font-italic font-size-0">{{message.message}}</p>
      </div>
      <span class="sender-time font-size-0">{{message.timestamp | textMessageDate}}</span>
    </div>
  </div>

  <div *ngSwitchCase="TextMessageSenders.DATE_DIVIDER" class="d-flex flex-row w-100 my-2">
    <p-divider class="w-100" align="center" styleClass="font-size-0">
    <p class="px-2 py-1 border border-radius-2">{{message.message}}</p>
    </p-divider>
  </div>

  <div *ngSwitchCase="TextMessageSenders.DIVIDER" class="d-flex flex-row w-100 my-2">
    <p-divider class="w-100" align="center" styleClass="font-size-0">
    <p class="px-2 py-1 border border-radius-2">{{message.message}}</p>
    </p-divider>
  </div>

  <div *ngSwitchCase="TextMessageSenders.SESSION_END" class="divider-message my-2">
    <div class="d-flex flex-row align-items-center justify-content-between mb-2 gap-2">
      <fa-icon [class]="CallStatuses.getStatusFontIcon(VisitorStatus.Ended).css" [icon]="CallStatuses.getStatusFontIcon(VisitorStatus.Ended).icon"></fa-icon>
      <span class="sender-time font-size-0 flex-grow-1" style="text-align: right;">{{message.timestamp | textMessageDate}}</span>
    </div>
    <div><div class="chatContent session-end">{{message.message}}</div></div>
  </div>
</div>
