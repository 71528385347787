import { Component, OnInit, Input } from '@angular/core';
import { BrowsingHistory } from '../../services/crm-service/browsing-history';
import * as moment from 'moment';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-browsing-history',
  templateUrl: './browsing-history.component.html',
  styleUrls: ['./browsing-history.component.scss']
})
export class BrowsingHistoryComponent implements OnInit {
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;

  public browsingHistory: BrowsingHistory[];

  @Input() isControlBarView: boolean = false;

  @Input() set visitorBrowsingHistory(visitorBrowsingHistory: BrowsingHistory[]) {
    if (visitorBrowsingHistory) {
      this.browsingHistory = visitorBrowsingHistory.sort((e1: BrowsingHistory, e2: BrowsingHistory) => {
        const e1Time = moment.utc(e1.TimeStamp, 'DD-MM-YYYY HH:mm:ss').local();
        const e2Time = moment.utc(e2.TimeStamp, 'DD-MM-YYYY HH:mm:ss').local();
  
        return e2Time.valueOf() - e1Time.valueOf();
      });
    }
    
  }

  constructor() { }

  ngOnInit() {
  }
}
