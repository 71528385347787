import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Features, FeatureService } from '../../services/feature-service/feature.service';
import { TranslatePipe } from '../../filters/Translate.pipe';
import { map, takeUntil, distinctUntilChanged,  } from 'rxjs/operators';
import { Subject, Observable, combineLatest } from 'rxjs';
import {Message, PrimeIcons} from 'primeng/api';
import {MediaPermissionService} from '../../services/media-permission.service';
import {Browsers} from '../../utils/browsers';

@Component({
  selector: 'app-media-permission',
  templateUrl: './media-permission.component.html',
  styleUrls: ['./media-permission.component.scss']
})
export class MediaPermissionComponent implements OnInit, OnDestroy {

  public videoUnavailable: boolean;
  public cameraAllowed$: Observable<boolean>;
  public micAllowed$: Observable<boolean>;
  public speakerSupported$: Observable<boolean>;
  public errorMessages: Message[] = [];

  private cameraPermissionDenied = false;
  private micPermissionDenied = false;
  private cameraUnsupported = false;
  private micUnsupported = false;

  @Output() cameraAllowedChange = new EventEmitter<boolean>();
  @Output() micAllowedChange = new EventEmitter<boolean>();
  @Output() speakerSupportedChange = new EventEmitter<boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    private featureService: FeatureService,
    private mediaPermissionService: MediaPermissionService,
    private translate: TranslatePipe
  ) {}

  ngOnInit() {
    this.videoUnavailable = this.featureService.has(Features.NO_VIDEO);

    this.speakerSupported$ = this.mediaPermissionService.getPermissionsStatus().pipe(
      map(status => status.speaker === 'granted')
    );

    this.mediaPermissionService.getPermissionsStatus().pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => {
      this.cameraPermissionDenied = status.camera === 'denied';
      this.micPermissionDenied = status.microphone === 'denied';
      this.cameraUnsupported = status.camera === 'unsupported';
      this.micUnsupported = status.microphone === 'unsupported';
    });

    this.cameraAllowed$ = this.mediaPermissionService.getPermissionsStatus().pipe(
      map(status => status.camera === 'granted' && !this.cameraUnsupported)
    );

    this.micAllowed$ = this.mediaPermissionService.getPermissionsStatus().pipe(
      map(status => status.microphone === 'granted' && !this.micUnsupported)
    );

    this.cameraAllowed$.pipe(takeUntil(this.destroy$))
      .subscribe(allowed => this.cameraAllowedChange.emit(allowed));

    this.micAllowed$.pipe(takeUntil(this.destroy$))
      .subscribe(allowed => this.micAllowedChange.emit(allowed));

    this.speakerSupported$.pipe(takeUntil(this.destroy$))
      .subscribe(supported => this.speakerSupportedChange.emit(supported));

    combineLatest([this.cameraAllowed$, this.micAllowed$, this.speakerSupported$])
      .pipe(
        map(([cameraAllowed, micAllowed, speakerSupported]) =>
          this.getErrorMessages(cameraAllowed, micAllowed, speakerSupported)
        ),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(errorMessages => {
        this.errorMessages = errorMessages;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getErrorMessages(cameraAllowed: boolean, micAllowed: boolean, speakerSupported: boolean) {
    const messages: Message[] = [];

    const addMessage = (
      summaryKey: string, summaryDefault: string,
      detailKey: string, detailDefault: string,
      icon: string = PrimeIcons.EXCLAMATION_TRIANGLE
    ) => {
      messages.push({
        severity: 'error',
        summary: this.translate.transform(summaryKey, summaryDefault),
        detail: this.translate.transform(detailKey, detailDefault),
        closable: false,
        icon
      });
    };

    if (!this.videoUnavailable) {
      if (this.cameraUnsupported) {
        addMessage('CAMERA_UNSUPPORTED', 'Camera Unsupported', 'CAMERA_ISSUES_DETAIL', 'Others won’t see you.', PrimeIcons.CAMERA);
      } else if (this.cameraPermissionDenied) {
        addMessage('CAMERA_PERMISSION_REQUIRED', 'Camera Permission Required', 'CAMERA_ISSUES_DETAIL', 'Others won’t see you.', PrimeIcons.CAMERA);
      } else if (!cameraAllowed) {
        addMessage('CAMERA_NOT_FOUND', 'No Camera Found', 'CAMERA_ISSUES_DETAIL', 'Others won’t see you.', PrimeIcons.CAMERA);
      }
    }

    if (this.micUnsupported) {
      addMessage('MIC_UNSUPPORTED', 'Mic Unsupported', 'MIC_ISSUES_DETAIL', 'Others won’t hear you.', PrimeIcons.MICROPHONE);
    } else if (this.micPermissionDenied) {
      addMessage('MIC_PERMISSION_REQUIRED', 'Mic Permission Required', 'MIC_ISSUES_DETAIL', 'Others won’t hear you.', PrimeIcons.MICROPHONE);
    } else if (!micAllowed) {
      addMessage('MIC_NOT_FOUND', 'No Mic Found', 'MIC_ISSUES_DETAIL', 'Others won’t hear you.', PrimeIcons.MICROPHONE);
    }

    // Safari still doesn't support returning device output, so ignore error on safari
    if (!speakerSupported && !Browsers.isSafari()) {
      addMessage('SPEAKER_ISSUES', 'No Speaker Found', 'SPEAKER_ISSUES_DETAIL', 'You won’t hear others.', PrimeIcons.VOLUME_UP);
    }

    return messages;
  }

}
