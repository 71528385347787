<section class="user-select-none">
  <div class="container my-4">
    <div class="row card-container card-container-custom">
      <div
        *ngFor="let source of sources; let i = index"
        class="col-lg-6 mb-4 d-flex align-items-stretch"
      >
        <div
          tabindex="{{ i + 1 }}"
          class="card card-custom w-100"
          [class.selected]="this.selectedSource == source"
          (click)="selectSource(source)"
        >
          <img
            src="{{ source.dataURL }}"
            alt="{{ source.name }}"
            class="card-img-top"
          />
          <div class="card-body text-center p-2">
            <h6 class="card-title card-title-small">{{ source.name }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-4 mb-4" />
  <div class="container mb-4">
    <h6 class="row mb-3">{{ "DISPLAYMEDIA_SCREENSHARE_TYPE" | Translate:  "How would you like to screen share?" }}</h6>
    <div class="row">
      <div class="col-sm-4 d-flex align-items-center gap-3">
        <p-inputSwitch [(ngModel)]="isTwoWay" (onChange)="updateScreenShareMode()"></p-inputSwitch>
        <span>{{ screenShareMode }}</span>
      </div>
      <p class="col-sm-8">{{ screenShareDescription }}</p>
    </div>

  </div>

  <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
    <p-button id="cancel-button" type="button" styleClass="p-button-text text-secondary" (click)="onCancel()">{{"DISPLAYMEDIA_BUTTON_CANCEL" | Translate : "Cancel" }}</p-button>
    <p-button
      id="share-button"
      type="button"

      (onClick)="onConfirm()"
      [disabled]="!selectedSource
      "
    >
      {{"DISPLAYMEDIA_BUTTON_SHARE" | Translate : "Share" }}
    </p-button>
  </div>

</section>
