<div>
  <p><strong>{{ "ABOUT_LABEL_VERSIONNUMBER" | Translate : 'Version number:' }}</strong> </p>
    <p class="version-number small">{{version}}</p> <!-- this is used for tc to insert a build time -->

    <div id="version-extra" *ngIf="appVersion !== DEFAULT_VERSION" class="mt-4">
      <p><strong>{{ "ABOUT_LABEL_APPVERSIONNUMBER" | Translate : 'App Version number:' }}</strong> {{appVersion}}</p>
    </div>

    <p-divider></p-divider>
    <p class="my-1"><strong>{{ "DARK_MODE_LABEL" | Translate : 'Dark Mode:' }}</strong><p-tag class="ml-2" size="small" severity="warning">{{ "BETA_LABEL" | Translate : 'BETA' }}</p-tag> </p>
    <div class="d-flex flex-row gap-2">
      <p-inputSwitch [(ngModel)]="darkMode" (ngModelChange)="onThemeChange($event)" ></p-inputSwitch>
    </div>
</div>
