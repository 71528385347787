export class ContactFilterData {
  total: number;
  contactlist: CrmContact[];
}

export class CrmContact {
  customerId: number;
  vee24Guid: string;
  sitename: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerNotes: string;
  customerOperator: string;
  modified?: Date;
  modifiedBy: string;
  created?: Date;
  createdBy: string;

  constructor(contact: CrmContact) {
    this.customerId = contact.customerId;
    this.vee24Guid = contact.vee24Guid;
    this.sitename = contact.sitename;
    this.customerName = contact.customerName;
    this.customerEmail = contact.customerEmail;
    this.customerPhone = contact.customerPhone ? contact.customerPhone : '0';
    this.customerNotes = contact.customerNotes;
    this.customerOperator = contact.customerOperator;

    this.modified = contact.modified ? new Date(contact.modified) : undefined;
    this.modifiedBy = contact.modifiedBy ?? '';
    this.created = contact.created ? new Date(contact.created) : undefined;
    this.createdBy = contact.createdBy ?? '';
  }
}
