


export class CameraControlProperty {
  name: string;
  enabled: boolean;
  minimum: number;
  maximum: number;
  stepInterval: number;
  defaultValue: number;
  flags: Array<string>;
  value: number;
  flag: string;
  type: CameraControlType;

  constructor(name:string, value:number, defaultValue:number) {
    this.name = name;
    this.value = value;
    this.defaultValue = defaultValue;
  }
}

export type CameraControlType = CameraControlTypes.Zoom | CameraControlTypes.Pan | CameraControlTypes.Tilt | CameraControlTypes.Generic;

export enum CameraControlTypes {
  Zoom = "Zoom",
  Pan = "Pan",
  Tilt = "Tilt",
  Generic = "Generic"
}

export class CameraProperties {

  properties: Array<CameraControlProperty> = [];

  constructor() {
  }

  add(control: any) {
    const cameraControl: CameraControlProperty = new CameraControlProperty(control.name, control.value, control.default);
    cameraControl.enabled = (control.enabled == "True");
    cameraControl.minimum = control.min;
    cameraControl.maximum = control.max;
    cameraControl.stepInterval = control.step;

    const strflags: string = control.flags;
    cameraControl.flags = strflags.split(",");
    cameraControl.flag = control.flag;


    if (cameraControl.enabled) {

      switch (cameraControl.name) {
        case CameraControlTypes.Pan:
          cameraControl.type = CameraControlTypes.Pan;
          break;
        case CameraControlTypes.Tilt:
          cameraControl.type = CameraControlTypes.Tilt;
          break;
        case CameraControlTypes.Zoom:
          cameraControl.type = CameraControlTypes.Zoom;
          break;

        default:
          cameraControl.type = CameraControlTypes.Generic;
      }

      this.properties.push(cameraControl);
    }

  }
}