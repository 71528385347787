import PeerAudio from "./PeerAudio";

export default class PeerAudioManager {
    constructor() {
        // an array of peerAudios to which we are going to be controlling
        this.peerAudios = []; 
        
        this.delete = (peerId) => {
            for (var i = 0; i < this.peerAudios.length; ++i) {
                if (this.peerAudios[i].peerId === peerId) {
                    //console.log('########### Removing Peer Audio Index:' + i); 
                    this.peerAudios.splice(i,1);
                    break;
                }
            }
        };
    }

    dispose() {
        console.log("Dispose of the PeerAudioManager");
        this.removeAll();
        this.peerAudios = [];
    }

    get(peerId) {
        var peerAudio = null;
        for (var i = 0; i < this.peerAudios.length; ++i) {
            if (this.peerAudios[i].peerId === peerId) {
                peerAudio = this.peerAudios[i];
                break;
            }
        }
        return peerAudio;
    }

    add(peerId) {
        //console.log('############ add PeerAudio:' + peerId);
        var peerAudio = this.get(peerId);
        if (peerAudio != null) {
            //console.log('############ PeerAudio already exists:' + peerId);
            return;
        }
        peerAudio = new PeerAudio(peerId);        
        this.peerAudios.push(peerAudio);
        //console.log('############ Added PeerAudio:' + peerId);
    }

    remove(peerId) {
        this.removeSource(peerId);
        this.delete(peerId);
    }

    removeSource(peerId) {
        //console.log('############ removeSource PeerAudio:' + peerId);
        var peerAudio = this.get(peerId);
        if (peerAudio) {
            peerAudio.removeSource();            
            //console.log('########### Removed PeerAudio Source:' + peerId); 
        }
    }

    removeAllSources() {
        for (let i = this.peerAudios.length - 1; i >= 0; i--) {
            this.removeSource(this.peerAudios[i].peerId);
        }
    }

    removeAll() {
        for (let i = this.peerAudios.length - 1; i >= 0; i--) {
            this.removeSource(this.peerAudios[i].peerId);
            this.delete(this.peerAudios[i].peerId);
        }
    }

    setVolume(volume) {
        for (var i = 0; i < this.peerAudios.length; ++i) {            
            this.peerAudios[i].setVolume(volume);
        }
    }

    resume() {
        for (var i = 0; i < this.peerAudios.length; ++i) {
            this.peerAudios[i].resume();
        }
    }

    setOutput(speaker) {
        for (var i = 0; i < this.peerAudios.length; ++i) {
            this.peerAudios[i].setOutput(speaker);
        }
    }

}