import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InvitationInitialState} from '../invitation-modal/invitation-in-modal.component';
import {MenuItem} from "primeng/api";
import {TranslatePipe} from "../../filters/Translate.pipe";
export enum EndType {
  END_CHAT,
  LEAVE_CHAT,
  END_ON_CHECKLIST_FAIL,
  END_MONITORING,
  JOIN_PRIVATE_CHAT,
  JOIN_PUBLIC_CHAT,
  LEAVE_PUBLIC_CHAT,
  LEAVE_SUP_MODE,
  LEAVE_SUP_SESSIONS,
  END_CONVERSATION
}

export interface EndChatAgent {
  username: string;
  nickname: string;
}

enum EndChatViewType {
  END,
  SELECT
}

@Component({
  selector: 'app-end-chat-modal',
  templateUrl: './end-chat-modal.component.html',
  styleUrls: ['./end-chat-modal.component.scss']
})
export class EndChatModalComponent {
  EndType = EndType; // Export type to ui

  public endChatType: EndType;
  public agents: EndChatAgent[];
  public veestudioAvailable: boolean;
  public veechatAvailable: boolean;
  public onEndChat: (newPrimary: string) => void;
  public cancelEndChat: () => void;
  public onEndMonitoring: () => void;
  public onJoinPrivateChat: () => void;
  public onJoinPublicChat: () => void;
  public onLeavePublicChat: () => void;
  public goToVeestudio: () => void;
  public goToVeechat: () => void;
  public proceedToNewMode: () => void;

  public get data(): any {
    return this.dialogConfig.data;
  }

  EndChatViewType = EndChatViewType;
  endChatView: EndChatViewType = EndChatViewType.END;

  newPrimaryUsername = '';
  newPrimaryNickname = '';


  constructor(private dialogConfig: DynamicDialogConfig<EndChatModalComponent>, private translate: TranslatePipe) {
    this.endChatType = this.data.endChatType;
    this.agents = this.data.agents;
    this.veestudioAvailable = this.data.veestudioAvailable;
    this.veechatAvailable = this.data.veechatAvailable;
    this.onEndChat = this.data.onEndChat;
    this.cancelEndChat = this.data.cancelEndChat;
    this.onEndMonitoring = this.data.onEndMonitoring;
    this.onJoinPrivateChat = this.data.onJoinPrivateChat;
    this.onJoinPublicChat = this.data.onJoinPublicChat;
    this.onLeavePublicChat = this.data.onLeavePublicChat;
    this.goToVeestudio = this.data.goToVeestudio;
    this.goToVeechat = this.data.goToVeechat;
    this.proceedToNewMode = this.data.proceedToNewMode;
  }

  onPrimaryAgentChange($event) {
    this.newPrimaryUsername = $event?.value?.username;
    this.newPrimaryNickname = $event?.value?.nickname;
  }

  selectNewHost() {
    this.endChatView = EndChatViewType.SELECT;
    this.newPrimaryUsername = '';
    this.newPrimaryNickname = '';
  }

  cancelSelectNewHost() {
    this.endChatView = EndChatViewType.END;
    this.newPrimaryUsername = '';
    this.newPrimaryNickname = '';
  }
}
