import {Pipe, PipeTransform} from '@angular/core';
import {SiteVisitor} from '../../../services/visitor-service/SiteVisitor';
import {Snooping, SnoopingEngagement, SupervisingEngagementTypes} from '../supervisor.component';
import {DefaultGroup} from "../../veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
  name: 'supervisorVisitorFilter'
})
export class SupervisorVisitorFilterPipe implements PipeTransform {
  transform(snooping: Snooping[], selectedDept: string): SiteVisitor[] {
    const snoopingEngagements = <SnoopingEngagement[]>snooping.filter(s => s.type === SupervisingEngagementTypes.Snooping);

    if (selectedDept !== DefaultGroup.NoSuperVisor) {
      const filtered: SiteVisitor[] = [];

      for (const snoop of snoopingEngagements) {
        if (snoop.visitor.agentGroup.some(group => group.Name === selectedDept)) {
          filtered.push(snoop.visitor);
        }
      }

      return filtered;
    } else {
      return snoopingEngagements.map(s => s.visitor);
    }
  }
}
