<td>

  <div *ngIf="currentState.type !== EngagementState.Engaged"  id="status-icon" [title]="getStatusText(currentState)" [pTooltip]="getStatusText(currentState)" [class]="'p-overlay-badge ' +  getStatusFontIcon(currentState).css">
    <fa-icon [fixedWidth]="true" [icon]="getStatusFontIcon(currentState).icon"></fa-icon>
  </div>
   <app-user-heartbeat *ngIf="visitor && currentState.type === EngagementState.Engaged"  pBadge #heartbeat [value]="unreadMessages" [badgeDisabled]="unreadMessages === 0" severity="danger" iconShape="circle" [engagementVisitor]="visitor" ></app-user-heartbeat>
</td>
<td>
  <p-badge [value]="currentState.type === EngagementState.Engaged ? timeSinceLastMessage : '0'" severity="info" />
</td>
<td colspan="3">
  <app-call-list-rename-chat
    [chatName]="chatName"
    (renameChat)="this.renameChat.emit($event)">
  </app-call-list-rename-chat>
</td>
<td class="has-data-icon" pTooltip="{{currentPage}}">
    <fa-icon size="lg" [icon]="faInfoCircle"  ></fa-icon>
</td>
<!--TODO: should be single component between veechat and async (veechat-call-list-conversation-row-->
