import { Subscription, Subject } from "rxjs";
import { WindowMessageService } from "../window-message-service/window-message.service";
import { ELECTRON_CAMERA_CONTROLLER_EVENTS } from "./electron-constants";

export interface ICameraController {
    init(cameraName: string): void;
    quit(): void;
    reset(): void;
    getProperties(): void;
    setProperty(property: string, value: string, flag: string): void;
}

export class ElectronCameraProxy implements ICameraController {
    private subs: Subscription[] = [];

    public properties$: Subject<string> = new Subject();
    public onQuit$: Subject<boolean> = new Subject();

    constructor(private windowMessageService: WindowMessageService) {
        this.subs.push(this.windowMessageService.ipcMessage$.subscribe(message => {
            switch (message.action) {
                case ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_PROPERTIES:

                    if (this.isJSONLoaded(message.data)) {
                        this.properties$.next(message.data);
                    }

                    break;

                case ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_QUIT:
                    this.onQuit$.next(true);
                    break;
            }
        }));
    }

    public init(cameraName: string): void {
        this.send(ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_INIT, { cameraName });
    }

    public quit(): void {
        this.send(ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_DETACH);
    }

    public reset(): void {
        this.send(ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_RESET);
    }

    public getProperties(): void {
        this.send(ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_GET_PROPERTIES);
    }

    public setProperty(property: string, value: string, flag: string): void {
        this.send(ELECTRON_CAMERA_CONTROLLER_EVENTS.CAMERA_SET_PROPERTY, { property, value, flag });
    }

    public dispose(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        this.subs = [];
    }

    private send(action, data?) {
        this.windowMessageService.postMessage({
            type: "vee24-ipc",
            command: "send",
            action,
            data
        }, "*");
    }


    private isJSONLoaded(data: string): boolean {
        var noWS: String = data.trim().replace(/\s+/g, '');

        if (noWS.substring(noWS.length - 3, noWS.length) == "]}}") {
            return true;
        }
        return false;
    }

}
