import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export type PermissionStatusState = 'granted' | 'denied' | 'prompt' | 'unsupported';

export interface MediaPermissions {
  camera: PermissionStatusState;
  microphone: PermissionStatusState;
  speaker: PermissionStatusState;
}

@Injectable({
  providedIn: 'root',
})
export class MediaPermissionService {
  private permissionsStatusSubject = new BehaviorSubject<MediaPermissions>({
    camera: 'prompt',
    microphone: 'prompt',
    speaker: 'unsupported',
  });

  constructor(private ngZone: NgZone) {
    this.initializePermissions();
  }

  private async initializePermissions() {
    const devices = await this.checkAvailableDevices();

    const permissions = await this.checkPermissions(['camera', 'microphone'], devices);
    this.permissionsStatusSubject.next({
      ...permissions,
      speaker: devices.speaker ? 'granted' : 'unsupported',
    });

    this.observePermissionChange('camera');
    this.observePermissionChange('microphone');
  }

  private async checkAvailableDevices(): Promise<{ camera: boolean; microphone: boolean; speaker: boolean }> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return {
      camera: devices.some(device => device.kind === 'videoinput'),
      microphone: devices.some(device => device.kind === 'audioinput'),
      speaker: devices.some(device => device.kind === 'audiooutput'),
    };
  }

  private async checkPermissions(
    permissionNames: Array<'camera' | 'microphone'>,
    devices: { camera: boolean; microphone: boolean; speaker: boolean }
  ): Promise<MediaPermissions> {
    const permissions: MediaPermissions = {
      camera: devices.camera ? 'prompt' : 'unsupported',
      microphone: devices.microphone ? 'prompt' : 'unsupported',
      speaker: 'unsupported',
    };

    for (const name of permissionNames) {
      if (permissions[name] !== 'unsupported') {
        try {
          const result = await navigator.permissions.query({
            name: name as PermissionName,
          });
          permissions[name] = result.state as PermissionStatusState;
        } catch (error) {
          console.error(`Error querying ${name} permission:`, error);
          permissions[name] = 'prompt';
        }
      }
    }

    return permissions;
  }

  private observePermissionChange(permissionName: 'camera' | 'microphone') {
    navigator.permissions
      .query({ name: permissionName as PermissionName })
      .then(permissionStatus => {
        permissionStatus.onchange = () => {
          this.ngZone.run(() => {
            const currentStatus = this.permissionsStatusSubject.value;
            currentStatus[permissionName] = permissionStatus.state as PermissionStatusState;
            this.permissionsStatusSubject.next({ ...currentStatus });
          });
        };
      })
      .catch(error => {
        console.error(`Error observing ${permissionName} permission:`, error);
      });
  }

  public async checkAndRequestPermissions(): Promise<MediaPermissions> {
    const devices = await this.checkAvailableDevices();
    const currentPermissions = await this.checkPermissions(['camera', 'microphone'], devices);

    const mediaConstraints: MediaStreamConstraints = {};

    if (devices.camera && currentPermissions.camera !== 'granted' && currentPermissions.camera !== 'unsupported') {
      mediaConstraints.video = true;
    }

    if (devices.microphone && currentPermissions.microphone !== 'granted' && currentPermissions.microphone !== 'unsupported') {
      mediaConstraints.audio = true;
    }

    if (Object.keys(mediaConstraints).length > 0) {
      try {
        await navigator.mediaDevices.getUserMedia(mediaConstraints);
      } catch (error) {
        console.error('Media access denied or device not available:', error);
      }
    }

    const updatedPermissions = await this.checkPermissions(['camera', 'microphone'], devices);
    this.permissionsStatusSubject.next({
      ...updatedPermissions,
      speaker: devices.speaker ? 'granted' : 'unsupported',
    });

    return {
      ...updatedPermissions,
      speaker: devices.speaker ? 'granted' : 'unsupported',
    };
  }

  public getPermissionsStatus(): Observable<MediaPermissions> {
    return this.permissionsStatusSubject.asObservable();
  }
}
