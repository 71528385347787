export const ELECTRON_BROWSER_EVENTS = {
  BROWSER_GET_BOUNDS: "get-browser-bounds",
  BROWSER_BOUNDS_UPDATED: "updated-browser-bounds",
  BROWSER_SET_BOUNDS: "set-browser-bounds",
  BROWSER_SET_MAXBOUNDS: "set-browser-maxbounds",
  BROWSER_CREATE: "create-browser",
  BROWSER_CREATED: "browser-created",
  BROWSER_DESTROY: "destroy-browser",
  BROWSER_SET_URL: "change-url",
  BROWSER_GET_URL: "get-url",
  BROWSER_URL_LOADING: "url-loading",
  BROWSER_URL_LOADED: "url-loaded",
  BROWSER_SHOW: "show-browser",
  BROWSER_HIDE: "hide-browser",
  BROWSER_ENABLE: "enable-browser",
  BROWSER_DISABLE: "disable-browser",
  ENABLE_EMULATION: "app-enable-emulation",
  DISABLE_EMULATION: "app-disable-emulation",
  CLIENT_DIMENSIONS: "client-dimensions",
  PANEL_DIMENSIONS: "panel-dimensions",
  UPDATE_PANEL_POSITION: "update-panel-position",
  ONMESSAGE: "browser-onmessage",
  POST_MESSAGE: "browser-post-message",
  BROWSER_RELOAD: "browser-reload",
  BROWSER_INPUT_FOCUS: "browser-input-focus",
  INPUT_INJECTOR_EVENT: "input-injector-event",
  WEBRTC_SHARING_RESUME: "webrtc-sharing-resume",
  WEBRTC_SHARING_PAUSE: "webrtc-sharing-pause",
};

export const ELECTRON_APP_EVENTS = {
  FOCUS: "app-focus",
  MINIMIZE: "app-minimize",
  CLOSE: "app-close",
  AGENT_STATUS: "app-agent-status",
  RING_INIT: "app-ring-init",
  RING_START: "app-ring-start",
  RING_STOP: "app-ring-stop",
  RING_DETACH: "app-ring-detach",
  GET_LOCAL_VARIABLE: "app-get-local-variable",
  SET_LOCAL_VARIABLE: "app-set-local-variable",
  EXTERNAL_URL: "app-external-url",
  CULTURE_LOADED: "app-culture-loaded",
  RECORDING_SETUP: "app-recording-setup",
  RECORDING_START: "app-recording-start",
  RECORDING_STOP: "app-recording-stop",
  RECORDING_PAUSE: "app-recording-pause",
  RECORDING_RESUME: "app-recording-resume",
  ALERT_MESSAGE: "app-alert-message",
  GET_LICENCE_KEY: "app-get-licence-key",
  SET_LICENCE_KEY: "app-set-licence-key",
  GET_APP_VERSION: "app-get-version",
  SET_APP_VERSION: "app-set-version",
};

export const ELECTRON_WEBRTC_EVENTS = {
  GET_SOURCES: "get-sources",
  POPULATE_SOURCES: "populate-sources",
};

export const ELECTRON_CAMERA_CONTROLLER_EVENTS = {
  CAMERA_INIT: "camera-init",
  CAMERA_DETACH: "camera-detach",
  CAMERA_SET_PROPERTY: "camera-set-property",
  CAMERA_GET_PROPERTIES: "camera-get-properties",
  CAMERA_RESET: "camera-reset",
  CAMERA_PROPERTIES: "camera-properties",
  CAMERA_QUIT: "camera-quit",
  CAMERA_ERROR: "camera-error",
};

export const ELECTRON_ALERT_CODES = {
  SPELLCHECK_DICTIONARY_DOWNLOAD_FAIL: "app-spellcheck-dictionary-download-fail",
  UNCAUGHT_ERROR: "app-uncaught-error",
};
