import {Component, Input} from '@angular/core';
import { faFile, faPuzzlePiece, faMapMarker, faGlobe, faBuilding, faUser, faLocationArrow, faAddressCard, faLink, faMobile } from '@fortawesome/free-solid-svg-icons';
import {Browsers} from '../../utils/browsers';
import {StringUtils} from '../../utils/string-utils';
import {VisitorDetails} from "../../classes/visitor-details";

@Component({
  selector: 'app-visitor-details',
  templateUrl: './visitor-details.component.html',
  styleUrls: ['./visitor-details.component.scss']
})
export class VisitorDetailsComponent  {

  faFile = faFile;
  faPuzzlePiece = faPuzzlePiece;
  faGlobe = faGlobe;
  faBuilding = faBuilding;
  faMapMarker = faMapMarker;
  faUser = faUser;
  faLocationArrow = faLocationArrow;
  faAddressCard = faAddressCard;
  faLink = faLink;
  faMobile = faMobile;

  @Input() showLabels: boolean = true;
  @Input() fixedTable: boolean = true;
  @Input() location: string;
  @Input() prevOp: string;
  @Input() useragent: string;
  @Input() section: string;
  @Input() page: string;
  @Input() ip: string;
  @Input() referrer: string;
  @Input() organisation: string;

  get browser() {
    if (this.useragent == null) {
      return '';
    }
    const browser = Browsers.getBrowserInfo(this.useragent);
    return `${StringUtils.capitalise(browser.browser)} ${browser.version}`;
  }

  get deviceType() {
    if (this.useragent == null) {
      return '';
    }
    const browser = Browsers.getBrowserInfo(this.useragent);
    return StringUtils.capitalise(browser.deviceType);
  }

  constructor() {
  }

}
