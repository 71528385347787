<app-navbar></app-navbar>
<div class="container-wrapper">
  <div class="d-flex h-100 overflow-hidden">

    <div class="d-flex flex-column justify-content-center align-items-center h-100 w-100 gap-2 main-layout">
      <img src="../../../assets/images/logo.png" class="logo mb-3"  />

      <h2> {{ "HOME_WELCOME"  | Translate : 'Welcome to Vee24' }} </h2>
      <p class="lead" *ngIf="enabledFeatures.length > 0"> {{ "HOME_INSTRUCTION" | Translate : 'Select your user type' }} </p>
      <p class="lead" *ngIf="enabledFeatures.length == 0"> {{ "HOME_NOLICENCED_FEATURES"  | Translate : 'Sorry, you do not appear to have any user types assigned.  Please speak to your supervisor or vee24.' }} </p>


      <div class="d-flex flex-row mt-3">

        <a *ngFor="let feature of enabledFeatures"
            routerLink="{{feature.route}}"
            routerLinkActive="active"
            class="d-flex flex-column align-items-center licence-type-container">
            <img src="../../../assets/licence-types/{{feature.image}}" class="m-3 icon" width="125" />
            <div class="label">{{feature.label}}</div>
        </a>

      </div>

    </div>

  </div>
</div>


