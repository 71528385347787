import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Engagement, EngagementState, WebrtcMessageType } from '../../../services/engagement';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth-service/auth.service';
import { WebrtRTCMessagingService } from '../../../services/webrtc-messaging-service/webrtcmessaging.service';
import { EngagementService } from '../../../services/engagement.service';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import { AlertService, AlertType } from '../../../services/alert-service/alert.service';
import { FeedType } from '../../../enums/multipeer/feed-type.enum';
import { HubFeed } from '../../../classes/multipeer/HubFeed';
import { faExpandArrowsAlt, faChevronLeft, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import * as MultiPeerWebRTC from 'multi-peer-webrtc';
import { Feed } from '../../../classes/multipeer/Feed';
import { LoggingService } from '../../../services/logging.service';
import { distinctUntilChanged, map, take, skipWhile } from 'rxjs/operators';
import { Agent } from '../../../classes/agent';
import { PeerType } from '../../../enums/multipeer/PeerType';
import { PeerEvents } from '../../../enums/multipeer/PeerEvents';
import { FacingMode } from '../../../enums/multipeer/FacingMode';

@Component({
  selector: 'app-mobile-presenter-call',
  templateUrl: './mobile-presenter-call.component.html',
  styleUrls: ['./mobile-presenter-call.component.scss']
})
export class MobilePresenterCallComponent implements OnInit, OnDestroy {

  public static IMPLIED_FEEDS: Feed[] = [
    {
      feedID: 0,
      feedType: FeedType.Agent,
      friendlyName: 'Desktop Camera',
      isBackCamera: false
    },
    {
      feedID: 0,
      feedType: FeedType.Mobile,
      friendlyName: 'Mobile Camera (Back)',
      isBackCamera: true
    },
    {
      feedID: 0,
      feedType: FeedType.Mobile,
      friendlyName: 'Mobile Camera (Front)',
      isBackCamera: false
    }
  ];

  public feeds: HubFeed[] = [];
  public selectedFeed: Feed = MobilePresenterCallComponent.IMPLIED_FEEDS[0];
  public feedIsPaused = false;
  public feedIsFullScreen = false;
  public isBackCamera = false;
  public FeedType = FeedType;
  public MobilePresenterCallComponent = MobilePresenterCallComponent;

  private engagementId: string;
  private webrtcIceServer: string;
  private readonly engagement: Engagement;

  private currentAgent: Agent = null;

  private peersContainer: HTMLDivElement;
  private call: MultiPeerWebRTC.CallP2PAgent;
  private _connectTracks = false;

  private subscriptions: Subscription[] = [];

  public faExpandArrowsAlt = faExpandArrowsAlt;
  public faPauseCircle = faPauseCircle;
  public faPlayCircle = faPlayCircle;
  public faChevronLeft = faChevronLeft;
  public faCompressArrowsAlt = faCompressArrowsAlt;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private engagementService: EngagementService,
    private webrtcMessagingService: WebrtRTCMessagingService,
    private onlineService: OnlineService,
    private logger: LoggingService
  ) {
    this.onlineService.setCurrentState(OnlineState.MobilePresenterEngaged);
    this.engagementId = this.route.snapshot.params['engagementId'];
    this.engagement = this.engagementService.getEngagement(this.engagementId);
    this.currentAgent = this.authService.currentAgent.value;
    this.feeds = this.currentAgent.feeds;

    if (!this.engagement) {
      this.alertService.addAlert(`Don't do that!! ${this.engagementId}`, AlertType.Danger);
      this.router.navigateByUrl('/presenter');
      return;
    }

    this.engagement.currentState.subscribe(state => {
      if (EngagementState.Ended === state.type) {
        this.engagementService.endEngagement(this.engagementId);
        this.router.navigateByUrl('/presenter');
      }
    });

    this.engagement.initialise().subscribe(success => {
      if (!success) {
        this.alertService.addAlert(`Unable to connect to engagement. Please check your internet connection.`, AlertType.Danger);
        this.router.navigateByUrl('/presenter');
      } else {
        this.onConnected();
      }
    });
  }

  ngOnInit() {
    const clientSub = this.engagement.webrtcMessages.subscribe((msg) => {
      this.webrtcMessagingService.sendMessageToClient(msg);
    });
    this.subscriptions.push(clientSub);

    const serverSub = this.webrtcMessagingService.serverMessages$.subscribe((msg) => {
      this.engagement.sendWebrtcMessage(msg);
    });
    this.subscriptions.push(serverSub);
  }

  ngOnDestroy() {
    this.tearDown();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private onConnected() {
    this.subscriptions.push(this.engagement.authenticatedIceUrl$.pipe(
        skipWhile(val => val.length === 0),
        take(1)
      )
      .subscribe((turnUrl) => {
      this.webrtcIceServer = turnUrl;
      this.init(this.engagementId, this.webrtcIceServer);
    }));

    this.subscriptions.push(this.engagement.currentFeedId$.pipe(distinctUntilChanged()).subscribe((id) => {
      this.feeds.map((feed) => {
        if (feed.feedID === id) {
          this.selectedFeed = {
            feedID: id,
            feedType: FeedType.Fixed,
            friendlyName: feed.friendlyName,
            isBackCamera: false
          };
          return;
        }
      });
      if (this.selectedFeed.feedID !== 0) {
        if (this.feedIsPaused) {
          this.pauseFeed(false);
        }
        if (this.currentAgent != null && this.currentAgent.servers != null) {
          this.displayFeed(this.selectedFeed.feedID, this.currentAgent.servers.WEBRTCGATEWAYURL);
        }
      }
    }));

    this.subscriptions.push(this.engagement.callPaused.pipe(distinctUntilChanged()).subscribe((paused) => {
      this.feedIsPaused = paused;
      if (this.call) {
        if (paused) {
          this.selectedFeed.feedType === FeedType.Fixed ? this.pauseFixedFeed() : this.pauseCall();
        } else {
          this.selectedFeed.feedType === FeedType.Fixed ? this.resumeFixedFeed() : this.resumeCall();
        }
      }
    }));

    this.subscriptions.push(this.engagement.presentingAgent.pipe(distinctUntilChanged()).subscribe((newPresenter) => {
      this.logger.debug(`presentingAgent: ${newPresenter}`);
      if (this.call) {
        this.call.ui.switchMainPeer(newPresenter);
      }
    }));
  }

  init(engagementId: string, webrtcIceServer: string) {

    this.logger.debug(`Presenter Init: ${engagementId} ${webrtcIceServer}`);

    const vidDiv = document.getElementById('mainVideoContainer') as HTMLDivElement;
    this.peersContainer = document.getElementById('peersContainer') as HTMLDivElement;

    const username = this.currentAgent.username;
    const engagement = this.engagement;

    const options = {
      engagementId: engagementId,
      peerId: username,
      iceServer: webrtcIceServer,
      connectPeers: true,
      connectTracks: this._connectTracks,
      peerType: PeerType.Agent,
      mainPeerId: username,
      speakerVolume: 1.0,
      mainVideoContainer: vidDiv,
      peersContainer: this.peersContainer
    };

    this.call = new MultiPeerWebRTC.CallFactory(MultiPeerWebRTC.CallFactory.CallType.P2P, MultiPeerWebRTC.CallFactory.ParticipantType.AGENT, options);

    this.subscriptions.push(
      this.webrtcMessagingService.clientMessages$.subscribe(message => {
        switch (message.type) {
          case WebrtcMessageType.AddStream:
            //this.logger.debug(`Adding stream for: ${message.streamName}`);
            this.addStream(message.streamName, message.peerType);
            break;
          case WebrtcMessageType.RemoveStream:
            //this.logger.debug(`Removing stream for: ${message.streamName}`);
            this.removeStream(message.streamName);
            break;
          case WebrtcMessageType.SignallingMessage:
            this.processMessage(message.message);
            break;
        }
      })
    );

    this.call.peers.on(PeerEvents.Signal, this.sendMessage.bind(this));
    this.call.ui.hideLocalPeers();
    this.call.initialise({cameraWidth: 1280, cameraHeight: 720})
      .then(() => {
        if (this.call) {
          this.call.videoChat();
        }
      }).catch((err) => {
        this.logger.error('Unable to startAudioVideo.', err);
        this.alertService.addAlert('Unable to start camera', AlertService.DANGER);
    });
  }

  private tearDown() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];

    if (this.call) {
      this.call.peers.off(PeerEvents.Signal, this.sendMessage.bind(this));
      this.call.dispose();
    }

    this.call = undefined;
  }

  private processMessage(message: any) {
    this.call.peers.processMessage(message.from, message);
  }

  private addStream(streamName: string, peerType: any) {
    this.call.addPeer(streamName, peerType);
  }

  private removeStream(streamName: string) {
    this.call.removePeer(streamName);
  }

  private sendMessage(message: any) {
    this.webrtcMessagingService.sendMessageToServer(JSON.stringify(message));
  }

  private displayFeed(feedId: number, gatewayServer: string) {
    if (this.call) {
      this.logger.debug(`Getting fixed feed with ID: ${feedId}`);
      this.call.getFeed(this.currentAgent.username, feedId, gatewayServer);
    }
  }

  private pauseCall() {
    if (this.call) {
      this.logger.debug('Pausing call');
      this.call.pauseCall();
    }
  }

  private resumeCall() {
    if (this.call) {
      this.logger.debug('Resuming call');
      this.call.resumeCall();
    }
  }

  private flipCamera(facingMode) {
    if (this.call) {
      this.logger.debug(`Flipping camera with facingmode: ${facingMode.facingMode}`);
      this.call.resumeCall();
      this.call.flipCamera(facingMode);
    }
  }

  private pauseFixedFeed() {
    if (this.call) {
      this.logger.debug('Pausing feed');
      this.call.pauseFeed();
    }
  }

  private resumeFixedFeed() {
    if (this.call) {
      this.logger.debug('Resuming feed');
      this.call.resumeFeed();
    }
  }

  public chooseFeed(feedType: FeedType, feedID: number, isBackCamera: boolean) {
    if (
        this.selectedFeed.feedID === feedID
        && this.selectedFeed.feedType === feedType
        && this.selectedFeed.isBackCamera === isBackCamera
      ) {
        return;
    }

    this.call.stopFeed();

    switch (feedType) {
      case FeedType.Mobile:
        this.selectedFeed = MobilePresenterCallComponent.IMPLIED_FEEDS.find((feed) => {
          return feed.feedType === FeedType.Mobile && feed.isBackCamera === isBackCamera;
        });

        this.flipCamera({facingMode: this.selectedFeed.isBackCamera ? FacingMode.Environment : FacingMode.User});

        if (this.feedIsPaused) {
          this.pauseFeed(false);
        }

        break;

      case FeedType.Agent:
        this.selectedFeed = MobilePresenterCallComponent.IMPLIED_FEEDS.find((feed) => {
          return feed.feedType === FeedType.Agent;
        });

        if (!this.feedIsPaused) {
          this.pauseFeed(true);
        }

        break;

      case FeedType.Fixed:
        if (this.feedIsPaused) {
          this.pauseFeed(false);
        }

        break;
    }

    this.engagement.chooseFeed(feedType, feedID);
  }

  public pauseFeed(pause: boolean) {
    this.engagement.pauseCall(pause);
  }

  public showFeedFullScreen() {
    this.feedIsFullScreen = true;
  }

  public exitFullScreenVideo() {
    this.feedIsFullScreen = false;
  }
}
