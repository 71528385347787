import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LoggingService} from '../../services/logging.service';
import {faMicrophone, faVideo, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import {MediaPermissionService} from '../../services/media-permission.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

interface SelectElement {
  id: string;
  text: string;
}

@Component({
  selector: 'app-avselection',
  templateUrl: './avselection.component.html',
  styleUrls: ['./avselection.component.scss']
})
export class AVSelectionComponent implements OnInit, OnDestroy {

  faMicrophone = faMicrophone;
  faVideo = faVideo;
  faVolumeUp = faVolumeUp;

  selectedCameraItem: SelectElement | undefined;
  selectedSpeakerItem: SelectElement | undefined;
  selectedMicItem: SelectElement | undefined;

  @Input() videoUnavailable: boolean;
  @Input() showSpeakerSelection: boolean;
  @Input() showMicSelection: boolean;

  @Output() selectCamera: EventEmitter<MediaDeviceInfo> = new EventEmitter<MediaDeviceInfo>();
  @Output() selectMicrophone: EventEmitter<MediaDeviceInfo> = new EventEmitter<MediaDeviceInfo>();
  @Output() selectSpeaker: EventEmitter<MediaDeviceInfo> = new EventEmitter<MediaDeviceInfo>();

  public selectVidDevices: SelectElement[] = [];
  public selectMicDevices: SelectElement[] = [];
  public selectSpeakerDevices: SelectElement[] = [];
  public showSwapCamBtn = false; // true if the swap camera button should be shown, false otherwise

  private _videoDevices: MediaDeviceInfo[] = [];
  private _micDevices: MediaDeviceInfo[] = [];
  private _speakerDevices: MediaDeviceInfo[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private logging: LoggingService,
    private mediaPermissionService: MediaPermissionService
  ) {
    this.mediaPermissionService.getPermissionsStatus().pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.getDevices();
    });
  }

  private _selectedCamera: MediaDeviceInfo;

  get selectedCamera() {
    return this._selectedCamera;
  }

  @Input() set selectedCamera(camera: MediaDeviceInfo) {
    if (camera) {
      this._selectedCamera = camera;
      this.selectedCameraItem = this.toSelectElement(camera);
    }
  }

  private _selectedSpeaker: MediaDeviceInfo;

  public set selectedSpeaker(speakerId: string) {
    if (!speakerId || speakerId === '') {
      return;
    }

    for (const speaker of this._speakerDevices) {
      if (speaker.deviceId === speakerId && speaker.deviceId !== this.selectedSpeakerOutput?.deviceId) {
        this.selectSpeaker.emit(speaker);
        return;
      }
    }
  }

  private _selectedMic: MediaDeviceInfo;

  public set selectedMic(micId: string) {
    if (!micId || micId === '') {
      return;
    }

    for (const mic of this._micDevices) {
      if (mic.deviceId === micId && mic.deviceId !== this.selectedMicrophone?.deviceId) {
        this.selectMicrophone.emit(mic);
        return;
      }
    }
  }

  get selectedMicrophone() {
    return this._selectedMic;
  }

  @Input() set selectedMicrophone(mic: MediaDeviceInfo) {
    if (mic) {
      this._selectedMic = mic;
      this.selectedMicItem = this.toSelectElement(mic);
    }
  }

  get selectedSpeakerOutput() {
    return this._selectedSpeaker;
  }

  @Input() set selectedSpeakerOutput(speaker: MediaDeviceInfo) {
    if (speaker) {
      this._selectedSpeaker = speaker;
      this.selectedSpeakerItem = this.toSelectElement(speaker);
    }
  }

  public set selectedCam(cameraId: string) {
    if (!cameraId || cameraId === '') {
      return;
    }

    for (const camera of this._videoDevices) {
      if (camera.deviceId === cameraId && camera.deviceId !== this.selectedCamera?.deviceId) {
        this.selectCamera.emit(camera);

        return;
      }
    }
  }

  ngOnInit() {
    navigator.mediaDevices.ondevicechange = () => {
      this.logging.debug('navigator.mediaDevices.ondevicechange');
      this.getDevices();
    };

    this.getDevices();
  }

  ngOnDestroy(): void {
    navigator.mediaDevices.ondevicechange = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDevices() {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          this.populateDevices(devices);
        });
  }

  populateDevices(devices) {
    const videoDevices: MediaDeviceInfo[] = devices
      .filter(device => device.kind === 'videoinput');     // get the cams
    const micDevices: MediaDeviceInfo[] = devices
      .filter(device => device.kind === 'audioinput');     // get the mics
    // .filter(device => !device.deviceId.match(/(default|communications)/gi)); // remove those "Default - " and "Communication - " duplicates but leave the 1 - Device devices
    const speakerDevices: MediaDeviceInfo[] = devices
      .filter(device => device.kind === 'audiooutput');    // get the speakers
    // .filter(device => !device.deviceId.match(/(default|communications)/gi)); // remove those "Default - " and "Communication - " duplicates but leave the 1 - Device devices

    this._videoDevices = videoDevices;
    this._micDevices = micDevices;
    this._speakerDevices = speakerDevices;

    this.selectMicDevices = micDevices.map(device => this.toSelectElement(device));
    this.selectVidDevices = videoDevices.map(device => this.toSelectElement(device));
    this.selectSpeakerDevices = speakerDevices.map(device => this.toSelectElement(device));

    const currentCam = this.selectedCamera;
    if (currentCam && currentCam.deviceId !== '' && videoDevices.find(d => d.deviceId === currentCam.deviceId)) {
      this.selectedCam = currentCam.deviceId;
      this.logging.debug('Setting camera to selectedCam ' + currentCam.label);
    } else {
      if (videoDevices.length > 0 && videoDevices[0].deviceId !== '') {
        this.logging.debug('Setting camera to new cam ');
        this.selectedCam = videoDevices[0].deviceId;
      }
    }

    const currentMic = this.selectedMicrophone;
    if (currentMic && currentMic.deviceId !== '' && micDevices.find(d => d.deviceId === currentMic.deviceId)) {
      this.selectedMic = currentMic.deviceId;
      this.logging.debug('Setting mic to selectedMic ' + currentMic.label);
    } else {
      if (micDevices.length > 0 && micDevices[0].deviceId !== '') {
        this.logging.debug('Setting mic to new mic ');
        this.selectedMic = micDevices[0].deviceId;
      }
    }

    const currentSpeaker = this.selectedSpeakerOutput;
    if (currentSpeaker && currentSpeaker.deviceId !== '' && speakerDevices.find(d => d.deviceId === currentSpeaker.deviceId)) {
      this.selectedSpeaker = currentSpeaker.deviceId;
      this.logging.debug('Setting speaker to selectedSpeaker ' + currentSpeaker.label);
    } else {
      if (speakerDevices.length > 0 && speakerDevices[0].deviceId !== '') {
        this.logging.debug('Setting speaker to new speaker ');
        this.selectedSpeaker = speakerDevices[0].deviceId;
      }
    }

    // only show the swap camera button if there are two cameras
    const numCams = this.selectVidDevices.length;
    this.showSwapCamBtn = numCams === 2;
  }

  onCamSelect($event) {
    this.selectedCam = $event?.value.id;
  }

  onSpeakerSelect($event) {
    this.selectedSpeaker = $event?.value.id;
  }

  onMicSelect($event) {
    this.selectedMic = $event?.value.id;
  }

  // also, hide the swap camera button for a period of time after pressing it
  public swapCamera() {
    const currCamId = this.selectedCamera.deviceId;
    const currCamIdx = this.selectVidDevices.map(function (e) {
      return e.id;
    }).indexOf(currCamId);
    const otherCamIdx = currCamIdx === 0 ? 1 : 0;
    this.selectedCam = this.selectVidDevices[otherCamIdx].id;

    this.showSwapCamBtn = false;
    setTimeout(() => {
      this.showSwapCamBtn = true;
    }, 1000);
  }

  // swap the camera between front and back

  private toSelectElement(val: MediaDeviceInfo) {
    return {id: val.deviceId, text: val.label};
  }
}
