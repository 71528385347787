import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Window_Injection } from '../../classes/window-injection';
import { ReplaySubject, Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class MockWindowMessageService {

  public get ipcMessage$():Observable<any> {
    return of<any>();
  }
    
  public readonly browserIframeMessage$:Observable<any> = of<any>();

  public postMessage(message: any, origin: string = "*") {
  }

  public openWindow(url: string, name: string) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class WindowMessageService implements OnDestroy {

  public get ipcMessage$():Observable<any> {
    return this.ipcMessage.pipe(distinctUntilChanged());
  }

  public browserIframeMessage$:Observable<any>;

  private ipcMessage: Subject<any> = new Subject<any>();
  private browserIframeMessage: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(@Inject(Window_Injection) private window) {

    this.browserIframeMessage$ = this.browserIframeMessage.pipe(distinctUntilChanged());
    this.window.addEventListener("message", this.onMessage.bind(this), false);

  }

  private onMessage(e){

    if (e.data && e.data.type) {

      switch (e.data.type) {
        case "vee24-ipc":
          if (e.source === window) {
            this.ipcMessage.next(e.data);
          }
          break;

        case "v24page":
        case "v24Init":
          this.browserIframeMessage.next(e.data);
          break;
      }

    }

  }

  public postMessage(message: any, origin: string = "*") {
    this.window.postMessage(message, origin);
  }

  public openWindow(url: string, name: string) {
    return this.window.open(url, name);
  }

  ngOnDestroy(): void {
    this.window = null;
  }
}
