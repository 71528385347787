<section class="d-flex flex-column w-100" pTooltip='{{ "ENGAGEMENTCONTROL_TITLE_PANORTILT" | Translate : "Pan / Tilt" }}' tooltipPosition="top">
  <div class="button-grid-container d-flex flex-row justify-content-center align-items-center m-auto w-100" >
    <div class="button-grid border rounded">
      <!-- Top left -->
      <p-button id="top-left" styleClass="direction-button"  (onClick)="tiltUpLeft()" [disabled]="zoom?.value <= 0 || tilt?.value >= tilt?.maximum || pan?.value <= pan?.minimum"
                [text]="true">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp" transform="rotate--45"></fa-icon>
      </p-button>

      <!-- Top center -->
      <p-button id="top-center" styleClass="direction-button" (onClick)="tiltUp()" [disabled]="zoom?.value <= 0 || tilt?.value >= tilt?.maximum"
                [text]="true">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp"></fa-icon>
      </p-button>

      <!-- Top right -->
      <p-button id="top-right" styleClass="direction-button" (onClick)="tiltUpRight()" [disabled]="zoom?.value <= 0  || tilt?.value >= tilt?.maximum || pan?.value >= pan?.maximum"
                [text]="true" >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp" transform="rotate-45"></fa-icon>
      </p-button>

      <!-- Middle left -->
      <p-button id="middle-left" styleClass="direction-button" (onClick)="panLeft()" [disabled]="zoom?.value <= 0  || pan?.value <= pan?.minimum"
                [text]="true" >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronLeft"></fa-icon>
      </p-button>

      <!-- Center middle -->
      <p-button id="middle-center" styleClass="direction-button" (onClick)="reset()" [disabled]="zoom?.value == 0 || pan?.value == pan?.defaultValue || tilt?.value == tilt?.defaultValue"
                [text]="true" >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faCompress"></fa-icon>
      </p-button>

      <!-- Middle right -->
      <p-button id="middle-right" styleClass="direction-button" (onClick)="panRight()" [disabled]="zoom?.value <= 0 || pan?.value >= pan?.maximum"
                [text]="true" >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronRight"></fa-icon>
      </p-button>

      <!-- Bottom left -->
      <p-button id="bottom-left" styleClass="direction-button" (onClick)="tiltDownLeft()" [disabled]="zoom?.value <= 0 || tilt?.value <= tilt?.minimum || pan?.value >= pan?.maximum"
                [text]="true"  >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown" transform="rotate-45"></fa-icon>
      </p-button>

      <!-- Bottom center -->
      <p-button id="bottom-center" styleClass="direction-button" (onClick)="tiltDown()" [disabled]="zoom?.value <= 0 || tilt?.value <= tilt?.minimum"
                [text]="true" >
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown"></fa-icon>
      </p-button>

      <!-- Bottom right -->
      <p-button id="bottom-right" styleClass="direction-button" (onClick)="tiltDownRight()" [disabled]="zoom?.value <= 0 || tilt?.value <= tilt?.minimum || pan?.value >= pan?.maximum"
                [text]="true">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown" transform="rotate--45"></fa-icon>
      </p-button>
    </div>
  </div>
</section>











<!--<section class="mt-2 mb-2">-->
<!--  <p>{{"ENGAGEMENTCONTROL_TITLE_PANORTILT" | Translate : "Pan / Tilt"}}</p>-->



<!--      <div class="btn btnBottomRight direction-button" (click)="tiltDownRight()"-->
<!--      title='{{ "CAMERACONTROLS_TILT_DOWN"  | Translate : "Tilt Down"  }} {{ "CAMERACONTROLS_PAN_RIGHT"  | Translate : "Pan Right"  }}'-->
<!--      [class.disabled]="zoom.value <= 0 || tilt.value <= tilt.minimum || pan.value >= pan.maximum">-->

<!--      </div>-->
<!--    </div>-->

<!--  </div>-->

<!--</section>-->
