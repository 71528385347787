// This file contains the environment for the prod build in the dev environment
import { version } from "./version";

export const environment = {
  production: true,
  version: version,
  environmentName: "dev",
  rollbarToken: "92d4589b134745a8a5bd0d017c7f76f4",
  visitoRHub: "https://visitor-dev.vee24.com",
  engageHub: "https://engage-dev.vee24.com",
  customerEngageRHub: "engage-dev.vee24.com",
  authApiRoot: "https://visitor-dev.vee24.com/auth",
  configApiRoot: "https://visitor-dev.vee24.com/settings",
  staticRoot: "https://static-dev.vee24.com",
  turnAuthRoot: "https://visitor-dev.vee24.com/turn",
  screenshotRoot: "https://visitor-dev.vee24.com/screenshot",
  engagementApiRoot: "https://visitor-dev.vee24.com/engagement",
  linkpreviewRoot: "https://visitor-dev.vee24.com/linkpreview",
  agentsRoot: "https://visitor-dev.vee24.com/agents",
  assetProxy: "https://static-dev.vee24.com//assets",
  crmRoot: "https://visitor-dev.vee24.com/crm",
  emailRoot: "https://visitor-dev.vee24.com/email",
  fulfillmentRoot: "https://visitor-dev.vee24.com/fulfillment",
  appointmentRoot: "https://visitor-dev.vee24.com/appointment",
  translationRoot: "https://visitor-dev.vee24.com/translate",
  webinarRoot: "https://visitor-dev.vee24.com/webinar",
  webinarHub: "https://webinar-dev.vee24.com",
  inviteRoot: "https://visitor-dev.vee24.com/invite",
  visitorRoot: "https://visitor-dev.vee24.com/visitor",
  uploadRoot: "https://visitor-dev.vee24.com/upload",
  restApiRoot: "https://api-dev.vee24.com",
};
