import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslatePipe } from '../../filters/Translate.pipe';
import { MessageService, Message } from 'primeng/api';
import {SettingsService} from "../settings-service/settings.service";

export class MockAlertService {
  public addAlert(text: string, type: AlertType): void {}
  public clearAlerts() {}
}

export class MockMessageService {
  public readonly alerts$: Subject<Message> = new Subject<Message>();

  public add(message: Message): void {
    this.alerts$.next(message);
  }

  public clear(): void {
    this.alerts$.next({});
  }
}

export enum AlertType {
  Success = 'success',
  Danger = 'error',
  Info = 'info',
  Warning = 'warning',
}

export enum Audio {
  NewMessage,
  NewChat,
  NewCall,
  NewInactiveConversation,
  NewVisitor,
  NewPrivateChatRequest,
}

export enum Sound {
  NewMessage,
  NewChat,
  NewCall,
  NewInactiveConversation,
  NewVisitor,
  NewPrivateChat,
  NewPrivateMessage,
}

@Injectable()
export class AlertService {
  public static readonly SUCCESS = AlertType.Success;
  public static readonly DANGER = AlertType.Danger;

  private context: AudioContext = undefined;

  private readonly soundUrls: ReadonlyMap<Audio, string> = new Map([
    [Audio.NewMessage, 'assets/audio/message_sound.mp3'],
    [Audio.NewChat, 'assets/audio/veechat_eng_request.mp3'],
    [Audio.NewCall, 'assets/audio/veestudio_call_sound.mp3'],
    [Audio.NewInactiveConversation, 'assets/audio/inactive_pop_sound.mp3'],
    [Audio.NewVisitor, 'assets/audio/visitor_joins_chat.mp3'],
    [Audio.NewPrivateChatRequest, 'assets/audio/new_private_chat_request.mp3'],
  ]);

  private readonly sounds: Map<Audio, AudioBuffer> = new Map([]);

  private soundsLookup: Map<Sound, Audio> = new Map<Sound, Audio>([
    [Sound.NewMessage, Audio.NewMessage],
    [Sound.NewChat, Audio.NewChat],
    [Sound.NewCall, Audio.NewCall],
    [Sound.NewInactiveConversation, Audio.NewInactiveConversation],
    [Sound.NewVisitor, Audio.NewVisitor],
    [Sound.NewPrivateChat, Audio.NewPrivateChatRequest],
    [Sound.NewPrivateMessage, Audio.NewPrivateChatRequest],
  ]);

  public soundVolumes: Map<Sound, number> = new Map([
    [Sound.NewMessage, 1.0],
    [Sound.NewChat, 1.0],
    [Sound.NewCall, 1.0],
    [Sound.NewInactiveConversation, 1.0],
    [Sound.NewVisitor, 1.0],
    [Sound.NewPrivateChat, 1.0],
    [Sound.NewPrivateMessage, 1.0],
  ]);

  private playingSound: AudioBufferSourceNode | null = null;

  private loadCalled = false;

  constructor(private readonly translate: TranslatePipe, private readonly messageService: MessageService, private readonly settingsService: SettingsService) {
    // @ts-ignore
    window.clearAllAlerts = () => this.clearAlerts();
  }




  public initSound() {

    this.initVolumes();

    if (this.loadCalled) {
      return;
    }

    this.loadCalled = true;


    if (window.AudioContext) {
      this.context = new AudioContext();
    }

    if (this.context) {
      this.loadAllSounds().catch(_ => {});
    }
  }


  public initVolumes() {
    this.soundVolumes.set(Sound.NewMessage, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWMESSAGE', '1.0')));
    // Do not allow new chat / call alert to be configured
    // this.soundVolumes.set(Sound.NewChat, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWCHAT', '1.0')));
    // this.soundVolumes.set(Sound.NewCall, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWCALL', '1.0')));
    this.soundVolumes.set(Sound.NewInactiveConversation, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWINACTIVECONVERSATION', '1.0')));
    this.soundVolumes.set(Sound.NewVisitor, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWVISITOR', '1.0')));
    this.soundVolumes.set(Sound.NewPrivateChat, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWPRIVATECHAT', '1.0')));
    this.soundVolumes.set(Sound.NewPrivateMessage, parseFloat(this.settingsService.getResourceOrDefault('ALERT_VOLUME_NEWPRIVATEMESSAGE', '1.0')));
  }

  public setSoundVolume(sound: Sound, volume: number) {
    this.soundVolumes.set(sound, volume);
  }

  private loadAllSounds() {
    this.sounds.clear();
    const promises: Promise<void>[] = [];
    for (const [type, url] of this.soundUrls) {
      promises.push(this.loadSound(type, url));
    }
    return Promise.all(promises);
  }

  private loadSound(type: Audio, url: string): Promise<void> {
    return fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => this.context.decodeAudioData(buffer))
      .then(audioBuffer => {
        this.sounds.set(type, audioBuffer);
        return Promise.resolve();
      })
      .catch(err => Promise.resolve(err));
  }

  public playSound(type: Sound) {
    const finalType = this.soundsLookup.get(type);
    const sound = this.getSound(finalType);
    if (sound) {
      const volume = this.soundVolumes.get(type) ?? 1.0;
      this.playingSound = this.playWithVolume(sound, volume);
    }
  }

  private playWithVolume(buffer: AudioBuffer, volume: number): AudioBufferSourceNode {
    const sound = this.context.createBufferSource();
    sound.buffer = buffer;

    const gainNode = this.context.createGain();
    gainNode.gain.value = volume;

    sound.connect(gainNode);
    gainNode.connect(this.context.destination);

    sound.start(0);
    sound.addEventListener('ended', () => {
      this.playingSound = null;
    });

    return sound;
  }

  public stopSound() {
    if (this.playingSound) {
      this.playingSound.stop();
      this.playingSound = null;
    }
  }

  public playNewMessageSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewMessage);
    }
  }

  public playNewPrivateMessageSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewPrivateMessage);
    }
  }

  public playNewChatSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewChat);
    }
  }

  public playNewPrivateChatSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewPrivateChat);
    }
  }

  public playPhoneRingSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewCall);
    }
  }

  public playNewInactiveConversationSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewInactiveConversation);
    }
  }

  public playVisitorJoinedSound() {
    if (!this.playingSound) {
      this.playSound(Sound.NewVisitor);
    }
  }

  public playNewInactiveConversationSoundInterrupt() {
    const buffer = this.sounds.get(Audio.NewInactiveConversation);
    if (buffer) {
      const volume = this.soundVolumes.get(Sound.NewInactiveConversation) || 1.0;
      this.playingSound = this.playWithVolume(buffer, volume);
    }
  }

  public setSound(type: Sound, audio: Audio) {
    this.stopSound();
    this.soundsLookup.set(type, audio);
  }

  public addAlert(text: string, type: AlertType = AlertType.Danger, timeout: number | null = null) {
    if (timeout === null) {
      timeout = type === AlertType.Danger ? 5000 : 3000;
    }
    this.messageService.add({ severity: type, detail: text, life: timeout });
  }

  public addResourceAlert(resource: string, defaultText: string, type: AlertType = AlertType.Danger, timeout: number | null = null) {
    const text = this.translate.transform(resource, defaultText);
    if (text && text.length > 0) {
      this.addAlert(text, type, timeout);
    }
  }

  public clearAlerts() {
    this.messageService.clear();
  }

  private getSound(type: Audio): AudioBuffer | null {
    return this.sounds.get(type) || null;
  }
}
