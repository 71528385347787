import {Component, Output, EventEmitter, Input} from '@angular/core';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import { TranslatePipe } from '../../filters/Translate.pipe';
import {Engagement} from '../../services/engagement';
import {PostEngagementStatus} from '../../classes/post-engagement.status';
import {TextMessage} from '../../classes/TextMessage';

@Component({
  selector: 'app-engagement-post',
  templateUrl: './engagement-post.component.html',
  styleUrls: ['./engagement-post.component.scss'],
  providers : [TranslatePipe]
})
export class EngagementPostComponent {
  private currentEngagement: Engagement;
  protected transcript: TextMessage[] = [];

  @Input() set engagement(engagement: Engagement) {
    if (this.currentEngagement != engagement) {
      this.currentEngagement = engagement;
      this.transcript = engagement?.messages?.currentTranscript();
    }
  }

  get engagement(): Engagement {
    return this.currentEngagement;
  }

  @Output() submitPost = new EventEmitter<PostEngagementStatus>();
  @Output() checkListName = new EventEmitter<string>();


  constructor(
    private alertService: AlertService,
    private settingsService: SettingsService,
    private translate: TranslatePipe
  ) {
  }

  ngOnInit() {
  }

  submit(postStatus: PostEngagementStatus) {
    if (this.isValid(postStatus)) {
      this.submitPost.emit(postStatus);
    } else {
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTPOST_ALERT_FILLFORM", 'Please fill out the post engagement form, try again.'), AlertType.Danger);
    }
  }

  private isValid(postStatus: PostEngagementStatus): boolean {
    return postStatus.status != '' && postStatus.substatus != '';
  }
}
