import { ELECTRON_APP_EVENTS } from "./electron-constants";
import { WindowMessageService } from '../window-message-service/window-message.service';
export class ElectronRingerProxy {
  
  constructor(private windowMessageService:WindowMessageService) {}

  initRinger(ringOnAllSpeakers: boolean = false) {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action: ELECTRON_APP_EVENTS.RING_INIT,
      data: ringOnAllSpeakers
    }, "*");
  }
  ringerStart() {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action: ELECTRON_APP_EVENTS.RING_START,
      data: {}
    }, "*");
  }
  ringerStop() {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action: ELECTRON_APP_EVENTS.RING_STOP,
      data: {}
    }, "*");
  }
  ringerDetach() {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action: ELECTRON_APP_EVENTS.RING_DETACH,
      data: {}
    }, "*");
  }
  dispose() {
    this.ringerDetach();
  }
}
