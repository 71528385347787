const Direction = Object.freeze({
    In:             "in",
    Out:            "out"
});

const PeerType = Object.freeze({
    Agent:          "agent",
    Visitor:        "visitor",
    Publisher:      "publisher",
    Subscriber:     "subscriber"
});

const SignalType = Object.freeze({
    AV:             "av",
    SCREENSHARE:    "share"
});

const Orientation = Object.freeze({
    Landscape:      "landscape",
    Portrait:       "portrait"
});

export default class Constants {
    constructor() {}    

    static get Direction() {
        return Direction;
    }

    static get PeerType() {
        return PeerType;
    }

    static get SignalType(){
        return SignalType;
    }

    static get Orientation() {
        return Orientation;
    }
}