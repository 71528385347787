import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AsyncConversation} from '../../../services/async-conversation';
import {CrmEmailState} from '../../../services/crm-service/crm-email-state';
import {Section} from "../../../components/visitor-information/visitor-information.component";

@Component({
  selector: 'app-supervisor-async-chat',
  templateUrl: './supervisor-async-chat.component.html',
  styleUrls: ['./supervisor-async-chat.component.scss']
})
export class SupervisorAsyncChatComponent implements OnInit {
  @Input()
  public conversation: AsyncConversation;
  public currentSection: Section = Section.Contact;

  @Output()
  public stopSnooping: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  doNothing($event: any) {
  }

  endMonitoring() {
    this.stopSnooping.emit();
  }

  protected readonly CrmEmailState = CrmEmailState;
}
