import { Injectable } from '@angular/core';
import { AlertService } from '../alert-service/alert.service';
import { ElectronService } from '../electron-service/electron.service';
import { ElectronRingerProxy } from "../electron-service/electron-ringer-proxy";
import { VersionService } from '../version-service/version.service';

@Injectable()
export class RingerService {
  private ringerProxy: ElectronRingerProxy;

  constructor(public alertService: AlertService, public electronService: ElectronService, private versionService: VersionService) {
    if (this.versionService.isElectron) {
      this.ringerProxy = this.electronService.createElectronRinger();
      this.ringerProxy.initRinger(true);
    }
  }

  play() {
    if (this.ringerProxy) {
      this.ringerProxy.ringerStart();
    }
    else {
      this.alertService.playPhoneRingSound();
    }
  }

  stop() {
    if (this.ringerProxy) {
      this.ringerProxy.ringerStop();
    }
    else {
      this.alertService.stopSound();
    }
  }

  dispose() {
    if (this.ringerProxy) {
      this.ringerProxy.dispose();
      this.ringerProxy = null;
    }
  }
}
