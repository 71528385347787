import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  AfterViewInit,
  forwardRef,
  HostListener
} from '@angular/core';
// @ts-ignore
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appIntlTelInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntlTelInputDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IntlTelInputDirective),
      multi: true
    }
  ]
})
export class IntlTelInputDirective implements AfterViewInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() initialCountry: string;
  @Input() countryOrder: string[] = null;
  private telInput: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.telInput = intlTelInput(this.el.nativeElement, {
      initialCountry: this.initialCountry || 'us',
      countryOrder: this.countryOrder,
      strictMode: true,
      containerClass: 'intl-input-dropdown-container',
    });

    this.el.nativeElement.addEventListener('countrychange', () => {
      this.onChange(this.telInput.getNumber());
      this.onTouched();
    });
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.onChange(this.telInput.getNumber());
  }

  ngOnDestroy() {
    this.telInput.destroy();
  }

  writeValue(value: any): void {
    if (value) {
      this.telInput.setNumber(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isValid = this.telInput?.isValidNumber();
    return isValid ? null : { invalidPhoneNumber: true };
  }

  getFormattedNumber() {
    return this.telInput?.getNumber();
  }

  private onChange = (_: any) => {};
  private onTouched = () => {};
}
