import { Component, OnInit, OnDestroy } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import { LoadingService } from '../../services/loading.service';
import { PasswordValidation } from '../../validators/password-change.validator';
import { AuthService } from '../../services/auth-service/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {Subscription} from 'rxjs';
import { TranslatePipe } from '../../filters/Translate.pipe'

interface FormGroupAbstractControl {
  [key: string]: AbstractControl;
}

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  providers: [TranslatePipe]
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private static readonly ERROR_LOOKUP: Map<string, string> = new Map<string, string>([
    [ 'minlength', 'Your password is too short.' ],
    [ 'MatchPassword', 'Your new passwords do not match.' ]
  ]);
  private static readonly TRYAGAIN_ERRORMESSAGE: string = 'An unknown error occurred when trying to change your passwords.';

  public passwordChangeForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
      private fb: UntypedFormBuilder,
      private alertService: AlertService,
      private loadingService: LoadingService,
      private authService: AuthService,
      private router: Router,
      private location: Location,
      private translate: TranslatePipe
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): void {
    this.passwordChangeForm = this.fb.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  public submit(): void {
    if (this.passwordChangeForm.valid) {
      this.loadingService.isLoading.next(true);

      // TODO call the auth service
      const {currentPassword, newPassword, confirmPassword} = this.passwordChangeForm.value;

      this.subscriptions.push(
        this.authService.changePassword(currentPassword, newPassword).subscribe(success => {
          if (success) {
            this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_PLEASELOGIN", 'Your password has been changed, please login.'), AlertType.Success);
            this.router.navigateByUrl('/login');
          } else {
            this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_ERROR", 'Error changing your password, make sure your current password is correct and try again.'), AlertType.Danger);
          }
          this.loadingService.isLoading.next(false);
        })
      );
    } else {
      this.loadingService.isLoading.next(false);

      const errors: ValidationErrors[] = [];
      this.getAllErrors(this.passwordChangeForm.controls, errors);
      this.displayErrors(errors);
    }
  }

  private displayErrors(errors: ValidationErrors | null) {
    if (errors === null) {
      this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_TRYAGAIN", PasswordChangeComponent.TRYAGAIN_ERRORMESSAGE), AlertType.Danger);
    } else {
      for (let i = 0; i < errors.length; ++i) {
        const error = errors[i];
        const errValue = error['error_name'];
        const errorString = PasswordChangeComponent.ERROR_LOOKUP.get(errValue);
        if (errorString) {
          this.alertService.addAlert(errorString, AlertType.Danger);
        } else {
          this.alertService.addAlert(this.translate.transform("PASSWORDCHANGE_TRYAGAIN", PasswordChangeComponent.TRYAGAIN_ERRORMESSAGE), AlertType.Danger);
        }
      }
    }
  }

  private getAllErrors(controls: FormGroupAbstractControl, errors: ValidationErrors[]) {
    if (controls) {
      Object.keys(controls).forEach((key) => {
        const control = controls[key];
        if (control instanceof UntypedFormGroup) {
          const nestedControls: FormGroupAbstractControl = control.controls;
          this.getAllErrors(nestedControls, errors);
        }

        const controlErrors = control.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(errorKey => {
            const err = controlErrors[key];
            errors.push({
              control_name: key,
              error_name: errorKey,
              error_value: err
            });
          });
        }
      });
    }
  }

  public cancel(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
