import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth-service/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {EngagementService} from '../../services/engagement.service';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {OnlineState} from '../../enums/online-state.enum';
import {OnlineService} from '../../services/online.service';
import {Agent} from '../../classes/agent';
import {VisitorService} from '../../services/visitor-service/visitor.service';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {AboutModalComponent} from '../about-modal/about-modal.component';
import {AlertService} from '../../services/alert-service/alert.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {LogoutService} from '../../services/logout.service';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {SettingsService} from '../../services/settings-service/settings.service';
import {ModalService} from '../../services/modal.service';
import {MenuItem} from "primeng/api";


const enum Screens {
  Home = 0,
  VeeStudio = 1,
  VeeChat = 2,
  VeeSupervisor = 3,
  VeeScheduler = 5,
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [TranslatePipe]
})
export class NavbarComponent implements OnInit, OnDestroy {


  faBars = faBars;
  @Output() endBreak = new EventEmitter;

  public currentAgent: Agent = null;

  public isActiveEngagement: Observable<boolean>;
  public isActiveTransfer: Observable<boolean>;
  public isActiveJoin: Observable<boolean>;
  public isActiveHelpRequest: Observable<boolean>;

  public OnlineState = OnlineState;
  public currentState$: BehaviorSubject<OnlineState> = new BehaviorSubject<OnlineState>(OnlineState.OffLine);
  public title = '';
  public noInternet: BehaviorSubject<boolean>;
  public connectivityErrorMessage: string;
  public onBreakMessage: string;
  public availableMessage: string;
  items: MenuItem[] = [];
  private subscriptions: Array<Subscription> = [];
  private hasVeestudio: boolean = false;
  private hasVeeChat: boolean = false;
  private hasVeeSupervisor: boolean = false;
  private hasVeeScheduler: boolean = false;
  private currentScreen: Screens = Screens.Home;

  constructor(
    private alertService: AlertService,
    private engagementService: EngagementService,
    private visitorService: VisitorService,
    private auth: AuthService,
    private router: Router,
    private onlineService: OnlineService,
    private modalService: ModalService,
    private featureService: FeatureService,
    private logoutService: LogoutService,
    private cdr: ChangeDetectorRef,
    private translate: TranslatePipe,
    private settingsService: SettingsService,
  ) {
    this.subscriptions.push(this.settingsService.resources.subscribe(() => {
      this.connectivityErrorMessage = this.translate.transform('NAVBAR_STATUSICON_CONNECTIVITY_ERROR', 'Connectivity Error');
      this.onBreakMessage = this.translate.transform('NAVBAR_STATUSICON_ONBREAK', 'On Break');
      this.availableMessage = this.translate.transform('NAVBAR_STATUSICON_Available', 'Available');
    }));
  }

  get isOnBreak() {
    return this.currentState$.getValue() === OnlineState.OnBreak;
  }

  get showOnBreakMenuItem() {
    return this.currentState$.getValue() !== OnlineState.Home && this.currentState$.getValue() !== OnlineState.Appointment;
  }

  get displayVeeStudioOption(): boolean {
    return this.hasVeestudio && this.currentScreen != Screens.VeeStudio;
  }

  get displayVeeChatOption(): boolean {
    return this.hasVeeChat && this.currentScreen != Screens.VeeChat;
  }

  get displayVeeSupervisorOption(): boolean {
    return this.hasVeeSupervisor && this.currentScreen != Screens.VeeSupervisor;
  }

  get displayVeeSchedulerOption(): boolean {
    return this.hasVeeScheduler && this.currentScreen != Screens.VeeScheduler;
  }

  ngOnInit() {
    this.isActiveEngagement = this.engagementService.activeEngagements.pipe(map(engagements => engagements !== 0));
    this.isActiveTransfer = this.visitorService.transfers.pipe(map(transfers => transfers.length !== 0));
    this.isActiveJoin = this.visitorService.invites.pipe(map(invites => invites.length !== 0));
    this.isActiveHelpRequest = this.featureService.has(Features.PRIVATE_CHAT) &&
      this.visitorService.visitors.pipe(map(visitors => visitors.some(v => v.agentRequestingAssistance)));

    this.subscriptions.push(
      this.auth.currentAgent.subscribe(agent => {
        this.currentAgent = agent;
        this.recomputePage();
      }));

    this.subscriptions.push(
      this.featureService.featuresChanged.subscribe(() => {
        this.recomputeFeatures();
      }));

    const stateSub = this.onlineService.currentState.pipe(distinctUntilChanged())
      .subscribe(([state, _]) => {
        this.currentState$.next(state);
        this.updateMenuItems();
      });
    this.subscriptions.push(stateSub);

    this.recomputePage();
    this.recomputeFeatures();

    this.noInternet = this.visitorService.noInternet;

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.updateMenuItems();
      }));

    this.updateMenuItems();

  }


  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  logout(): void {
    this.logoutService.logout();
  }

  onEndBreak() {
    try {
      this.alertService.initSound();
      // TO DO: do something in case of an error
    } catch (_) {
    }

    this.endBreak.emit();
  }

  goOnBreak() {
    this.onlineService.setCurrentState(OnlineState.OnBreak);
  }

  showAbout() {
    // Uses open method because this doesn't include a close call due to dismissableMask
    this.modalService.open(AboutModalComponent, {
      header: this.translate.transform('ABOUT_HEADER_TEXTABOUT', 'About'),
      closable: true,
      showHeader: true,
      dismissableMask: true,
    });
  }

  // Determine what label to display based on internet connectivity/current state
  convertStatusIconLabel(currentState: OnlineState, noInternet: boolean): string {
    if (noInternet) {
      return this.connectivityErrorMessage;
    } else {
      switch (currentState) {
        case OnlineState.OnBreak:
          return this.onBreakMessage;

        case OnlineState.Available:
          return this.availableMessage;

        default:
          return '';
      }
    }
  }

  private updateMenuItems(): MenuItem[] {
    if (this.currentAgent) {
      const about: string = this.translate.transform('ABOUT_HEADER_TEXTABOUT', 'About');
      const changePassword: string = this.translate.transform('NAVBAR_MENU_CHANGEPASSWORD', 'Change Password');
      const logout: string = this.translate.transform('NAVBAR_MENU_LOGOUT', 'Logout');
      const endBreak: string = this.translate.transform('NAVBAR_MENU_ENDBREAK', 'End Break');
      const goOnBreak: string = this.translate.transform('NAVBAR_MENU_GOONBREAK', 'Go on Break');

      this.items = [
        {
          id: 'veechat-btn',
          label: 'VeeChat',
          icon: 'pi pi-fw pi-comments',
          routerLink: '/veechat',
          visible: this.displayVeeChatOption
        },
        {
          id: 'veestudio-btn',
          label: 'VeeStudio',
          icon: 'pi pi-fw pi-video',
          routerLink: '/veestudio',
          visible: this.displayVeeStudioOption
        },
        {
          id: 'supervisor-btn',
          label: 'VeeSupervisor',
          icon: 'pi pi-fw pi-user',
          routerLink: '/supervisor',
          visible: this.displayVeeSupervisorOption
        },
        {
          id: 'scheduler-btn',
          label: 'VeeScheduler',
          icon: 'pi pi-fw pi-calendar',
          routerLink: '/veescheduler',
          visible: this.displayVeeSchedulerOption
        },
        {separator: true},
        {
          id: 'break-btn',
          label: this.isOnBreak ? endBreak : goOnBreak,
          command: () => this.toggleOnBreak(),
          visible: this.showOnBreakMenuItem
        },
        {id: 'about-btn', label: about, command: () => this.showAbout()},
        {id: 'password-btn', label: changePassword, routerLink: '/password-change'},
        {separator: true},
        {id: 'logout-btn', label: logout, icon: 'pi pi-fw pi-power-off', command: () => this.logout()},
      ];
    }
    return this.items;
  }

  private toggleOnBreak() {
    if (this.isOnBreak) {
      this.onEndBreak();
    } else {
      this.goOnBreak();
    }
  }

  private recomputePage(): void {
    const url = this.router.url;

    if (url.startsWith('/home')) {
      this.title = 'Vee24';
      this.currentScreen = Screens.Home;
    } else if (url.startsWith('/veestudio')) {
      this.title = 'VeeStudio';
      this.currentScreen = Screens.VeeStudio;
    } else if (url.startsWith('/veechat')) {
      this.title = 'VeeChat';
      this.currentScreen = Screens.VeeChat;
    } else if (url.startsWith('/supervisor')) {
      this.title = 'Supervisor Mode';
      this.currentScreen = Screens.VeeSupervisor;
    } else if (url.startsWith('/veescheduler')) {
      this.title = 'VeeScheduler';
      this.currentScreen = Screens.VeeScheduler;
    }
  }

  private recomputeFeatures(): void {
    this.hasVeestudio = this.featureService.has(Features.VEESTUDIO);
    this.hasVeeChat = this.featureService.has(Features.VEECHAT);
    this.hasVeeSupervisor = this.featureService.has(Features.SUPERVISOR_MODE);
    this.hasVeeScheduler = this.featureService.has(Features.SCHEDULED_APPOINTMENT);
  }
}
