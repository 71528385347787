<form [formGroup]="appointmentFormGroup" class="form-container mt-4">
  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_APPOINTMENT_TYPE" | Translate : 'Appointment Type' }}</label>
      <p-dropdown class="dropdown" formControlName="appointmentType"
                  optionLabel="name"
                  [options]="appointmentTypes"
                  [filter]="true"
                  [placeholder]="'APPOINTMENT_LABEL_APPOINTMENT_SELECT_TYPE' | Translate : 'Select Appointment Type'"
                  [resetFilterOnHide]="true"
                  [showClear]="false"></p-dropdown>
    </div>
  </div>

  <div *ngIf="(appointmentLocations$ | async)?.length > 0" class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_APPOINTMENT_LOCATION" | Translate : 'Location' }}</label>
      <p-dropdown [filter]="true" [options]="(appointmentLocations$ | async)"
                  [placeholder]="'APPOINTMENT_LABEL_APPOINTMENT_SELECT_LOCATION' | Translate : 'Select Location'"
                  [resetFilterOnHide]="true"
                  [showClear]="true"
                  class="dropdown"
                  formControlName="locationId"
                  optionLabel="name" optionValue="id"></p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_START_DATE" | Translate : 'Appointment Date' }}</label>
      <input class="form-control flex-1" formControlName="startDate" pInputText type="date">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_TIMESLOT" | Translate : 'Appointment Time' }} ({{ timeZone$ | async }}
        - {{ Timezones.getIanaTimeZone((timeZone$ | async)) }})</label>
      <p-dropdown [options]="(availabilitySlots$ | async)"
                  [placeholder]="'APPOINTMENT_LABEL_SELECT_TIMESLOT' | Translate : 'Select Appointment Time'"
                  [showClear]="false"
                  class="dropdown"
                  formControlName="timeslot"
                  optionLabel="label">
      </p-dropdown>
      <small *ngIf="appointmentTypeId !== -1 && (availabilitySlots$ | async)?.length === 0"
             class="text-danger">{{ "APPOINTMENT_LABEL_TIMESLOT_NOT_AVAILABLE" | Translate : 'No Timeslots Available' }}</small>
    </div>
  </div>

  <p-divider *ngIf="formFields?.length > 0" layout="horizontal"></p-divider>

  <app-appointment-type-form [formFields]="formFields"
                             [dynamicFormGroup]="dynamicFormGroup"></app-appointment-type-form>
  <p-divider *ngIf="formFields?.length > 0" layout="horizontal"></p-divider>


  <p-accordion *ngIf="guestForms.size > 0" multiple="false" [style]="{'margin-top': '1rem'}">
    <p-accordionTab *ngFor="let guestId of guestKeys(); let i = index;" [selected]="isTabOpen(i)">
      <ng-template pTemplate="header">
        <div class="d-flex flex-row align-items-center justify-content-between w-100 gap-4">
          <p class="text-truncate">{{ 'APPOINTMENT_LABEL_GUEST' | Translate: 'Guest' + ' ' + (i + 1) }}</p>

        </div>

      </ng-template>
      <div class="d-flex flex-column align-items-end gap-4 py-2">
        <p-button icon="pi pi-minus" (onClick)="removeGuest(guestId)"
                  [label]="'APPOINTMENT_LABEL_REMOVE_GUEST' | Translate: 'Remove Guest'" severity="danger"></p-button>
        <app-appointment-type-form class="w-100" [formFields]="guestForms.get(guestId)?.fields"
                                   [dynamicFormGroup]="guestForms.get(guestId).formGroup"></app-appointment-type-form>
      </div>
    </p-accordionTab>
  </p-accordion>

  <div class="d-flex flex-row align-items-center justify-content-between gap-1 mt-4">
    <p-button [disabled]="formFields === null || formFields.length === 0" (onClick)="addGuest()" icon="pi pi-plus"
              type="button"
              [label]="'APPOINTMENT_LABEL_ADD_GUEST' | Translate: 'Add Guest'" severity="secondary"></p-button>
    <p-button (onClick)="createAppointment()" [disabled]="!appointmentFormGroup.valid || !dynamicFormGroup.valid"
              [pTooltip]="'APPOINTMENT_LABEL_SAVE' | Translate: 'Save'" severity="success">
      <fa-icon [icon]="faSave"></fa-icon>
    </p-button>

  </div>
</form>
