import { Component, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CameraControlProperty, CameraControlTypes } from '../../../services/camera-controls-service/camera-properties';
import { SettingsService } from '../../../services/settings-service/settings.service';
import { CameraControlFlags } from '../../../services/camera-controls-service/camera-control-flags';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-camera-control',
  templateUrl: './camera-control.component.html',
  styleUrls: ['./camera-control.component.scss']
})
export class CameraControlComponent {


  @ViewChild('slider', {static: true}) sliderRef: ElementRef;

  public CameraControlFlags = CameraControlFlags;
  public CameraControlTypes = CameraControlTypes;

  public controlName:string = "";

  private _property: CameraControlProperty;

  @Input() set property(v: CameraControlProperty) {
    this._property = v;
    this.controlName = this.settingsService.getResourceOrDefault(`CAMERACONTROLS_${this._property.name.toUpperCase()}`, this._property.name);

  }
  get property():CameraControlProperty {
    return this._property;
  }

  @Input() showSliderButtons: boolean;

  @Output() onPropertyChange = new EventEmitter<CameraControlProperty>();

  constructor(private settingsService: SettingsService) { }

  onChange($event) {
    const newValue = parseInt($event.target.value, 10);
    this.property.value = newValue;
    this.onPropertyChange.emit(this.property);
  }

  onFlagChange() {
    if (this.property.flag === CameraControlFlags.MANUAL) {
      this.property.flag = CameraControlFlags.AUTO;
      this.property.value = this.property.defaultValue;
    }
    else if (this.property.flag === CameraControlFlags.AUTO) {
      this.property.flag = CameraControlFlags.MANUAL;
    }
    this.onPropertyChange.emit(this.property);
  }

  decreaseVal() {
    this.sliderRef.nativeElement.stepDown();
    this.property.value = parseInt(this.sliderRef.nativeElement.value);
    this.onPropertyChange.emit(this.property);
  }

  increaseVal() {
    this.sliderRef.nativeElement.stepUp();
    this.property.value = parseInt(this.sliderRef.nativeElement.value);
    this.onPropertyChange.emit(this.property);
  }

  protected readonly faPlus = faPlus;
  protected readonly faMinus = faMinus;
}
