import { Pipe, PipeTransform } from "@angular/core";
import { InvitationRequest } from "../classes/transfer/invitationRequest";
import {DefaultGroup} from "../pages/veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
  name: 'invitesFilter',
  pure: false
})
export class InvitesFilterPipe implements PipeTransform {
  transform(invites: InvitationRequest[], selectedDept: string): InvitationRequest[] {
    const filtered: InvitationRequest[] = [];

    if (selectedDept != DefaultGroup.NoSuperVisor) {
      for (const info of invites) {
        if (info.visitor.agentGroup.some(group => group.Name === selectedDept)) {
          filtered.push(info);
        }
      }
    }
    else {
      return invites;
    }

    return filtered;
  }
}
