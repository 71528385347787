import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

export default class Segmenter {
    constructor(resourcesUrl) {
        this.listeners = [];  
        
        this.segmenter = new SelfieSegmentation({locateFile: (file) => {
            return `${resourcesUrl}/${file}`;
        }});

        this.segmenter.setOptions({
            selfieMode: false, // mirrors the video
            modelSelection: 1, // chooses the Landscape model (meant to be faster)
        });

        this.segmenter.onResults((results) => {
            for (let listener of this.listeners) {
                listener(results);
            }
        });      
    }

    static async getInstance(resourcesUrl) {
        if (!this.instance) {
            this.instance = new Segmenter(resourcesUrl);                          
            await this.instance.segmenter.initialize();
        }
        return this.instance;
    }

    addListener(listener) {       
        if (this.segmenter) {
            this.listeners.push(listener);
        } else {
            console.log("Attempting to add listener before setting up segmenter");
        }
    };
    
    removeListener(listener) {
        if (this.segmenter) {
            this.listeners = this.listeners.filter(l => l != listener);
        } else {
            console.log("Attempting to remove a listener before setting up segmenter");
        }
    };

    countListeners() {
        return this.listeners.length;
    }

    setOptions(opts) {
        if (this.segmenter) {
            this.segmenter.setOptions(opts);
        } else {
            console.log("Attempting to set options before setting up segmenter");
        }
    };

    async send(arg) {
        if (this.segmenter) {
            await this.segmenter.send(arg);
        } else {
            console.log("Attempting to send data before setting up segmenter");
        }
    };    
}