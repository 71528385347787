import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-sharing-notification',
  templateUrl: './sharing-notification.component.html',
  styleUrls: ['./sharing-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingNotificationComponent implements OnInit {

  @Input() isPrimaryAgent: boolean = false;
  @Output() stopSharing: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onStopSharingClick() {
    this.stopSharing.emit();
  }
}
