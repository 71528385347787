<div class="d-flex flex-column gap-2 h-100">
  <h3 class="pt-3 pb-3 text-center">{{currentEngagementStateText}} {{ "ENGAGEMENTTRANSFER_LABEL_CALL" | Translate : "Call"}}</h3>
  <div class="row pl-3 pr-3">
    <div class="col">
      <p>{{ "ENGAGEMENTTRANSFER_LABEL_TO" | Translate : "To:" }}</p>
    </div>
    <div class="col-7">
      <p>{{inviteState?.operatorFullname}}</p>
    </div>
  </div>
  <p class="pl-3 pr-3 flex-1">
    {{ inviteState?.type === EngagementState.Join ? ("ENGAGEMENTTRANSFER_LABEL_WAITINGTOJOIN" | Translate : 'Waiting for this join to be accepted.') :
    ("ENGAGEMENTTRANSFER_LABEL_WAITINGTOTRANSFER" | Translate : 'Waiting for this Transfer to be accepted.') }}
  </p>
  <div class="d-flex flex-row w-100 pt-2 gap-2">
    <p-button class="flex-grow-1" styleClass="center-icon p-button-secondary h-100 w-100 align-items-center justify-content-center"
              pTooltip='{{ "LABEL_CANCEL" | Translate : "Cancel" }}  {{currentEngagementStateText}}' tooltipPosition="top"
              (onClick)="closeInviteRequest.emit()">
      <fa-icon [icon]="faChevronLeft" size="xl" />
    </p-button>
  </div>

</div>
