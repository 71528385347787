import {TransferFile} from './transfer-file';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export class EngagementTransferFile extends TransferFile {
  constructor(protected http: HttpClient,
              protected endpointUrl: string,
              protected file: File,
              private site: string,
              private userGuid: string,
              private sessionGuid: string,
              private engagementId: string) {
    super(http, endpointUrl, file);
  }

  protected startFileTransfer(): boolean {
    try {
      const headers: Map<string, string> = new Map([
        [ 'vee24UserGuid', this.userGuid ],
        [ 'vee24SessionGuid', this.sessionGuid ],
      ]);

      const data: FormData = new FormData();
      data.append('files', this.file);
      data.append('site', this.site);
      data.append('customerGuid', this.userGuid);
      data.append('engagementGuid', this.engagementId);

      this.sendData(headers, this.endpointUrl, data);
    } catch (err) {
      return false;
    }

    return true;
  }

  protected deleteCancelledUpload() {
    const httpHeaders = new HttpHeaders({
      'vee24UserGuid': this.userGuid,
      'vee24SessionGuid': this.sessionGuid,
    });

    const cancelUrl = `${this.endpointUrl}/${this.site}?directory=${this.engagementId}&blob=${this.filename}`;
    this.http.delete(cancelUrl, { headers: httpHeaders}).subscribe();
  }
}
