import EventEmitter from "./EventEmitter";
import Browser from "./Browser";
import CandidateFilter from "./CandidateFilter";
import Constants from "./Constants";

export default class Connection extends EventEmitter {
    constructor(engagementId, myPeerId, myPeerType, iceServer, theirPeerId, theirPeerType, direction, candidateFilter = CandidateFilter.noFilter, signalType = Constants.SignalType.AV) {
        super();

        this.peerId = theirPeerId;
        this.myPeerId = myPeerId;
        this.myPeerType = myPeerType;
        this.direction = direction;
        this.signalType = signalType;
        this.theirPeerType = theirPeerType;

        this.rtc = new RTCPeerConnection(Connection.getPeerConnectionConfig(engagementId, iceServer));
        
        this.videoTransceiver = null;
        this.audioTransceiver = null;
        
        this.iceCandidateFilter = candidateFilter;

        this.rtc.onicecandidate = event => {
            if (event.candidate) {
                this.emit("Connection:signal", {   
                    signalType: this.signalType,
                    from: this.myPeerId,
                    to: this.peerId,
                    direction: this.direction,
                    type: 'candidate',
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate
                });
            } else {
                //console.log('End of candidates.');
                this.emit("Connection:signal", {  
                    signalType: this.signalType,
                    from: this.myPeerId,
                    to: this.peerId,
                    direction: this.direction,
                    type: 'candidate',
                    end: true
                });
            }
        };
        
        this.rtc.ontrack = event => {
            // only add a track if this peers direction is inbound
            if (this.direction === Constants.Direction.In) {
                //console.log('Remote track added. kind:' + event.track.kind + ' id:' + event.track.id);
                this.emit("Connection:track", {
                    peerId : this.peerId,
                    track  : event.track,
                    peerType : this.theirPeerType
                });
            }
        };

        this.rtc.onnegotiationneeded = () => {
            //console.log('NegotiationNeeded');           
        };

        this.rtc.onsignalingstatechange = () => {
            //this.negotiating = this.signalingState != "stable";         
        };

        this.rtc.oniceconnectionstatechange = event => {
            if (!this.rtc) { return; }
            //console.log("oniceconnectionstatechange:" + this.rtc.iceConnectionState + " direction:" + this.direction);
            // this is internal so we can update our view
            this.emit("Connection:stateChange", {
                peerId : this.peerId,
                direction : this.direction,
                connectionState : this.rtc.iceConnectionState,
                signalingState : this.rtc.signalingState
            });
            // this is to send to the other end so they can respond
            if (this.direction === Constants.Direction.In) {
                if (!this.rtc) { return; } 
                this.emit("Connection:signal", {  
                    signalType: this.signalType,
                    from: this.myPeerId,
                    to: this.peerId,
                    direction: this.direction,
                    type: 'state',
                    connectionState : this.rtc.iceConnectionState,
                    signalingState : this.rtc.signalingState
                });
            }
        };                

        this.incomingConnectionStateCheck = () => {
            if (!this.rtc) { return; }
            // only check the inbound connections
            if (this.direction === Constants.Direction.In) { 
                //console.log("incomingConnectionStateCheck connectionState:" + this.rtc.iceConnectionState + " signalingState:" + this.rtc.signalingState);
                this.emit("Connection:signal", {
                    signalType: this.signalType,  
                    from: this.myPeerId,
                    to: this.peerId,
                    direction: this.direction,
                    type: 'state',
                    connectionState : this.rtc.iceConnectionState,
                    signalingState : this.rtc.signalingState
                });
            }
        };

        this.incomingConnectionStateCheckInterval = setInterval(this.incomingConnectionStateCheck, 1000);

        if (this.direction === Constants.Direction.Out) { 
            // if we support transceivers
            // based upon our direction
            // then lets create one
            if (Browser.supportsTransceivers) {
                this.videoTransceiver = this.rtc.addTransceiver("video", { direction : 'inactive'});
                this.audioTransceiver = this.rtc.addTransceiver("audio", { direction : 'inactive'});
            }
        }
        
        console.log('Created ' + direction + ' RTCPeerConnnection to:' + this.peerId); 
    } 

    negotiate() {
        //console.log('negotiate');
        if (this.direction === Constants.Direction.Out) {
            //console.log('starting negotiation');

            // check that we have tracks before negotiating
            //this.rtc.createOffer({voiceActivityDetection:false})
            this.rtc.createOffer()
            .then(offer => {
                //console.log(JSON.stringify(Connection.mungeTheOffer(offer)));
                return this.rtc.setLocalDescription(Connection.mungeTheOffer(offer, this.myPeerType));
            })
            .then(() => {
               
                this.emit("Connection:signal", {
                    signalType: this.signalType,
                    from: this.myPeerId,
                    to: this.peerId,
                    direction: this.direction,
                    type: "offer",
                    desc: this.rtc.localDescription
                });
            })
            .catch(Connection.createOfferError);
        }
    }

    processMessage(message) {
        if (message.type === 'offer') {
            // if this is the in connection then handle Offers
            if (this.direction === Constants.Direction.In) {
               
                this.rtc.setRemoteDescription(message.desc)
                .then(() => {
                    return this.rtc.createAnswer();
                })
                .then(answer => {
                    return this.rtc.setLocalDescription(answer);
                })
                .then(() => {
                    //console.log("send answer to peer", this.rtc.localDescription);

                    this.emit("Connection:signal", {
                        signalType: this.signalType,
                        from: this.myPeerId,
                        to: this.peerId,
                        direction: this.direction,
                        type: "answer",
                        desc: this.rtc.localDescription
                    });
                })
                .catch(Connection.receiveOfferError);
            }
        }
        else if (message.type === 'answer') {
            // if this is the out connection then handle answers
            if (this.direction === Constants.Direction.Out) {
                this.rtc.setRemoteDescription(new RTCSessionDescription(message.desc));
            }
        }
        else if (message.type === 'candidate') {                
            if (message.end === true) {
                this.rtc.addIceCandidate(null);
            }
            else {
                //console.log("Candidate received: " + JSON.stringify(message));
               
                const parsedCandidate = Connection.parseCandidate(message.candidate);
                if(this.iceCandidateFilter(parsedCandidate)){
                    
                    //console.log("filtering candidate", parsedCandidate);

                    var candidate = new RTCIceCandidate({
                        sdpMLineIndex: message.label,
                        candidate: message.candidate
                    });
                    this.rtc.addIceCandidate(candidate).catch(msg => console.log("Unable to set candidate: " + msg));
                }

            }            
        }                              
    }


    addTrack(track, stream) {
        // if we support transceivers then
        // replace the track in the transceiver
        // make sure we are set to sendonly
        if (Browser.supportsTransceivers) {
            // this assumes there will only be either video or audio transceivers
            var transceiver = (track.kind === "video") ? this.videoTransceiver : this.audioTransceiver;
            if (transceiver) {
                transceiver.direction = "sendonly";
                transceiver.sender.replaceTrack(track);
            }
        }
        else {
            // otherwise add the track to the peer connection
            this.rtc.addTrack(track, stream);
        }
        this.emit("Connection:signal", {
            signalType: this.signalType,
            from: this.myPeerId,
            to: this.peerId,
            direction: this.direction,
            type: "avEvent",
            avType: track.kind,
            enabled: true
        });
    }

    removeTrack(track) {
        // if we support transceivers then
        // replace the track in the transceiver
        // make sure we are set to inactive
        if (Browser.supportsTransceivers) {
            // this assumes there will only be either video or audio transceivers
            var transceiver = (track.kind === "video") ? this.videoTransceiver : this.audioTransceiver;
            if (transceiver) {
                transceiver.direction = "inactive";
                transceiver.sender.replaceTrack(null);
            }
            else {
                console.log("outbound transceiver of type: " + track.kind + " does not exist");
            }
        }
        else {
            var senders = this.rtc.getSenders();
            for (var j = 0; j < senders.length; ++j) {
                var sender = senders[j];
                if (sender.track === track) {
                    this.rtc.removeTrack(sender);
                }
            }
        }
        this.emit("Connection:signal", {
            signalType: this.signalType,
            from: this.myPeerId,
            to: this.peerId,
            direction: this.direction,
            type: "avEvent",
            avType: track.kind,
            enabled: false
        });
    }

    // make it be able to handle either just the server domain
    // or a json object containing the server
    // or twilio tokens response
    // 1. eu-webrtc-turn01.vee24.com
    // 2. {"turnServer":"https://eu-webrtc-turn01.vee24.com/"}
    // 3. twilio response
    // {
    //     "username": "51f98499f02f0f074771c37361ce4f0a4f097083f6ef89b0c702ac02b1689756",
    //     "ice_servers": [
    //         {
    //             "url": "stun:global.stun.twilio.com:3478",
    //             "urls": "stun:global.stun.twilio.com:3478"
    //         },
    //         {
    //             "url": "turn:global.turn.twilio.com:3478?transport=udp",
    //             "username": "51f98499f02f0f074771c37361ce4f0a4f097083f6ef89b0c702ac02b1689756",
    //             "urls": "turn:global.turn.twilio.com:3478?transport=udp",
    //             "credential": "c8uxikQw1wLVc7HWMMs0LlN/dY2ylmqqGf0LLgKZCBI="
    //         },
    //         {
    //             "url": "turn:global.turn.twilio.com:3478?transport=tcp",
    //             "username": "51f98499f02f0f074771c37361ce4f0a4f097083f6ef89b0c702ac02b1689756",
    //             "urls": "turn:global.turn.twilio.com:3478?transport=tcp",
    //             "credential": "c8uxikQw1wLVc7HWMMs0LlN/dY2ylmqqGf0LLgKZCBI="
    //         },
    //         {
    //             "url": "turn:global.turn.twilio.com:443?transport=tcp",
    //             "username": "51f98499f02f0f074771c37361ce4f0a4f097083f6ef89b0c702ac02b1689756",
    //             "urls": "turn:global.turn.twilio.com:443?transport=tcp",
    //             "credential": "c8uxikQw1wLVc7HWMMs0LlN/dY2ylmqqGf0LLgKZCBI="
    //         }
    //     ],
    //     "date_updated": "Mon, 04 Sep 2023 14:57:20 +0000",
    //     "account_sid": "AC3e4789fd812d8a3cae0016689a33765f",
    //     "ttl": "3600",
    //     "date_created": "Mon, 04 Sep 2023 14:57:20 +0000",
    //     "password": "c8uxikQw1wLVc7HWMMs0LlN/dY2ylmqqGf0LLgKZCBI="
    // }

    static getPeerConnectionConfig(engagementId, iceServer) {
                        
        var turnConfig = Connection.extractTurnConfig(engagementId, atob(iceServer));            

        var config = {
            sdpSemantics: 'unified-plan',
            iceServers: [
                {
                    urls: `turn:${turnConfig.hostname}:${turnConfig.usesTwilio ? '3478' : '5349'}?transport=udp`,
                    credential: turnConfig.password,
                    username: turnConfig.username
                },
                {
                    urls: `turn:${turnConfig.hostname}:443?transport=udp`,
                    credential: turnConfig.password,
                    username: turnConfig.username
                },
                {
                    urls: `turn:${turnConfig.hostname}:${turnConfig.usesTwilio ? '3478' : '5349'}?transport=tcp`,
                    credential: turnConfig.password,
                    username: turnConfig.username
                },
                {
                    urls: `turn:${turnConfig.hostname}:443?transport=tcp`,
                    credential: turnConfig.password,
                    username: turnConfig.username
                },
                {
                    urls: `turns:${turnConfig.hostname}:443?transport=tcp`,
                    credential: turnConfig.password,
                    username: turnConfig.username
                }                    
            ],
            optional : [{
                googCpuOveruseDetection : false
            }],
            iceTransportPolicy: turnConfig.forceRelay ? "relay" : "all",
        };        

        return config;
    }

    static extractTurnConfig(engagementId, iceServer) {        
        var config = {
            hostname: iceServer,
            username: engagementId,
            password: engagementId,
            usesTwilio: false,
            forceRelay: false,
        };      

        try {
            var o = JSON.parse(iceServer);                
            if (o && typeof o === "object") {
                
                if (o.hasOwnProperty("servers")) {

                    var servers = JSON.parse(o.servers);
                    if (servers && typeof servers === "object") {
                        // now handle the vee24 turnserver format
                        if (servers.hasOwnProperty("turnServer")) {
                            config.hostname = Connection.getUrl(servers.turnServer);
                        }
                        else { // it must be a twilio object
                            config.usesTwilio = true;

                            if (servers.hasOwnProperty("username")) {
                                config.username = servers.username;
                            }
                            if (servers.hasOwnProperty("password")) {
                                config.password = servers.password;
                            }
                            if (servers.hasOwnProperty("ice_servers")) {
                                var ice_servers = servers.ice_servers;
                                if (ice_servers.length > 0) {
                                    // replace the stun protocol with https:// so that we can parse the hostname from the string
                                    // also replace the stun within the domain with turn
                                    var serverURL = new URL(ice_servers[0].url.replaceAll("stun:","https://").replace("stun","turn"));                                    
                                    config.hostname = serverURL.hostname;
                                }
                            }                    
                        } 
                    }
                }

                if (o.hasOwnProperty("forceRelay") && o.forceRelay || !!window.vee24ForceTurnRelay) {
                    config.forceRelay = true;
                }

            }
        } 
        catch (err) {
            console.log(err);
        }       
        
        return config;
    }    

    static getUrl(url) {
        // trim trailing slashes
        if (url[url.length - 1] === '/') {
            url = url.substring(0, url.length - 1);
        }
        //trim 'https'
        if (url.substring(0, 5) === 'https') {
            url = url.substring(5);
        }
        //trim ':' if present
        if (url.substring(0, 1) === ':') {
            url = url.substring(1);
        }
        //trim '//'
        if (url.substring(0, 2) === '//') {
            url = url.substring(2);
        }

        return url;
    }

    static createOfferError(error) {
        console.log("CreateOfferError: " + error.name + " " + error.message);
    }

    static receiveOfferError(error) {
        console.log("ReceiveOfferError: " + error.name + " " + error.message);
    }

    // we might pass options into this class in the future
    // and use them in here to set things like 
    // - bandwidth restrictions
    // - codec choice if necessary
    static mungeTheOffer(offer, peerType) {
        var munged = offer;
        // these 2 are for edge, because ortc create an transceiver
        // but right now we have 2 connections, so we dont want to ever
        // receive anything

        munged.sdp = munged.sdp.replace('a=sendrecv\r\n','a=sendonly\r\n');
        munged.sdp = munged.sdp.replace('a=recvonly\r\n','a=inactive\r\n');
        
        // edge seems to mess up here and ends up replacing the 99 with a 96 so switch back
        munged.sdp = munged.sdp.replace('100 96 96','100 99 96');
        munged.sdp = munged.sdp.replace('a=rtpmap:96 rtx/90000\r\na=fmtp:96 apt=107;rtx-time=3000\r\n','a=rtpmap:99 rtx/90000\r\na=fmtp:99 apt=107;rtx-time=3000\r\n');

        // if we are the chrome android, then remove VP8 and VP9, forcing H264 as the CPU can't keep up        
        if ((Browser.details().browser === 'chrome android' && peerType === Constants.PeerType.Agent)) {
            munged.sdp = Connection.removeCodec(munged.sdp, 'VP8');
            munged.sdp = Connection.removeCodec(munged.sdp, 'VP9');
        }        

        return munged;
    }
    
    static removeCodec(orgsdp, codec) {
        
        var internalFunc = function(sdp) {
            var codecre = new RegExp('(a=rtpmap:(\\d*) ' + codec + '\/90000\\r\\n)');
            var rtpmaps = sdp.match(codecre);
            if(rtpmaps == null || rtpmaps.length <= 2) {
                return sdp;
            }
            var rtpmap = rtpmaps[2];
            var modsdp = sdp.replace(codecre, "");

            var rtcpre = new RegExp('(a=rtcp-fb:' + rtpmap + '.*\r\n)', 'g');
            modsdp = modsdp.replace(rtcpre, "");

            var fmtpre = new RegExp('(a=fmtp:' + rtpmap + '.*\r\n)', 'g');
            modsdp = modsdp.replace(fmtpre, "");

            var aptpre = new RegExp('(a=fmtp:(\\d*) apt=' + rtpmap + '.*\\r\\n)');
            var aptmaps = modsdp.match(aptpre);
            var fmtpmap = "";
            if(aptmaps != null && aptmaps.length >= 3) {
                fmtpmap = aptmaps[2];
                modsdp = modsdp.replace(aptpre, "");

                var rtppre = new RegExp('(a=rtpmap:' + fmtpmap + '.*\r\n)', 'g');
                modsdp = modsdp.replace(rtppre, "");
            }

            var videore = /(m=video.*\r\n)/;
            var videolines = modsdp.match(videore);
            if(videolines != null) {
                //If many m=video are found in SDP, this program doesn't work.
                var videoline = videolines[0].substring(0, videolines[0].length - 2);
                var videoelem = videoline.split(" ");
                var modvideoline = videoelem[0];
                for(var i = 1; i < videoelem.length; i++) {
                    if(videoelem[i] == rtpmap || videoelem[i] == fmtpmap) {
                        continue;
                    }
                    modvideoline += " " + videoelem[i];
                }
                modvideoline += "\r\n";
                modsdp = modsdp.replace(videore, modvideoline);
            }
            return internalFunc(modsdp);
        };
        return internalFunc(orgsdp);
    }

    static parseCandidate(text){
        var candidateStr = "candidate:";
        var pos = text.indexOf(candidateStr) + candidateStr.length;
        var fields = text.substr(pos).split(" ");
        return {
            type: fields[7],
            protocol: fields[2],
            address: fields[4]
        };
    }    

}
    