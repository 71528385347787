import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-call-list-rename-chat',
  templateUrl: './call-list-rename-chat.component.html',
  styleUrls: ['./call-list-rename-chat.component.scss']
})
export class CallListRenameChatComponent implements OnInit {
  public faPencilAlt = faPencilAlt;

  @Input() chatName = '';
  @Output() renameChat = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onRenameChat($event) {
    const newChatName = $event.target.value;
    this.renameChat.emit(newChatName);
    $event.target.blur();
  }
}
