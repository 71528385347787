
<div class="login">
  <div class="login-wrapper">
    <img class="logo" src="./assets/images/logo.png" alt="Vee24"/>
    <h2>{{ "PASSWORDCHANGE_CHANGE"  | Translate : "Change your Password" }}</h2>
    <form [formGroup]="passwordChangeForm" (submit)="submit()" novalidate>
      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-lock"></p-inputIcon>
          <input type="password" id="current-password-input" pInputText
                 placeholder="{{ 'PASSWORDCHANGE_CURRENTPASSWORD' | Translate : 'Enter your current Password' }}"
                 formControlName="currentPassword">
        </p-iconField>
      </div>

      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-lock"></p-inputIcon>
          <input type="password" id="new-password-input" pInputText
                 placeholder="{{ 'PASSWORDCHANGE_NEWPASSWORD' | Translate : 'Enter your new Password' }}"
                 formControlName="newPassword">
        </p-iconField>
      </div>

      <div>
        <p-iconField>
          <p-inputIcon styleClass="pi pi-lock"></p-inputIcon>
          <input type="password" id="confirm-password-input" pInputText
                 placeholder="{{ 'PASSWORDCHANGE_REENTERNEWPASSWORD' | Translate : 'Re-enter your new Password' }}"
                 formControlName="confirmPassword">
        </p-iconField>
      </div>

      <div class="buttons">
        <p-button severity="secondary" [text]="true"
                  (onClick)="cancel()">{{ "PASSWORDCHANGE_BUTTON_CANCEL" | Translate : "Cancel" }}
        </p-button>
        <p-button type="submit" severity="success">{{ "PASSWORD_BUTTON_SUBMIT" | Translate : 'Submit' }}
        </p-button>
      </div>

    </form>
  </div>
  <app-info-bar></app-info-bar>
</div>
