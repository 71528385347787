<td>
  <div [title]="CallStatuses.getStatusText(visitor?.visitorStatus)" [pTooltip]="CallStatuses.getStatusText(visitor?.visitorStatus)" [class]="'engagement-status-icon ' +  CallStatuses.getStatusFontIcon(visitor?.visitorStatus).css">
    <fa-icon [fixedWidth]="true" [icon]="CallStatuses.getStatusFontIcon(visitor?.visitorStatus).icon"></fa-icon>
  </div>
</td>
<td [class.text-warning]="visitor.getAssistTime() > 15"
    [class.text-danger]="visitor.getAssistTime() > 30">
  <p-badge [value]="assistTime" [severity]="visitor.getAssistTime() > 30 ? 'danger' : (visitor.getAssistTime() > 15 ? 'warning' : '')"></p-badge>
</td>
<td>
  <img width="30" height="20" class="device-icon mt-2"
       [src]="'/assets/call-list-icons/' + browserInfo.deviceTypeIcon"
       [alt]="browserInfo.deviceType"
       pTooltip="{{browserInfo.deviceType}}">
</td>
<td>
  <img  class="align-bottom country-flag-icon" width="30" height="20"
       [src]="CountryFlags.getIcon(visitor?.countryCode)"
       [alt]="visitor?.countryCode?.toLowerCase()"
       title="{{visitor?.location}}, {{visitor?.culture?.toLowerCase()}}">
</td>
<td>
  <fa-icon class="info" size="lg" [icon]="faInfoCircle"
           pTooltip="{{visitor.lastPage}}, {{visitor.lastSection}}, {{visitor.previousOperator}}"
  ></fa-icon>
</td>
<td>
  <p-button id="accept-chat-button" *ngIf="!visitor?.isInactive || visitor?.isEngaged" (clicked)="accept()" appDebounceClick severity="success" size="small" >
      <fa-icon [icon]="faPhone"></fa-icon>
  </p-button>
</td>
