import { WebGLHelper, glsl } from './WebglHelper'
import BackgroundBlurStage from './shaders/BackgroundBlurStage'
import BackgroundImageStage from './shaders/BackgroundImageStage'
import BackgroundNoneStage from './shaders/BackgroundNoneStage'
import BackgroundVideoStage from './shaders/BackgroundVideoStage'
import JointBilateralFilter from './shaders/JointBilateralFilterStage'
import BackgroundConfig from './BackgroundConfig'

export default class WebGL2Pipeline {

    constructor(   
        backgroundConfig,
        canvas
    ) {
        
        this.backgroundConfig = backgroundConfig;
        this.canvas = canvas;

        this.gl = this.canvas.getContext('webgl2');

        this.outputWidth = this.canvas.width;
        this.outputHeight = this.canvas.height;

        //console.log(`canvas width ${this.outputWidth} height ${this.outputHeight}`);
        
        const vertexShaderSource = glsl`#version 300 es

            in vec2 a_position;
            in vec2 a_texCoord;

            out vec2 v_texCoord;

            void main() {
                gl_Position = vec4(a_position, 0.0, 1.0);
                v_texCoord = a_texCoord;
            }
        `;

        this.vertexShader = WebGLHelper.compileShader(this.gl, this.gl.VERTEX_SHADER, vertexShaderSource);

        this.vertexArray = this.gl.createVertexArray();
        this.gl.bindVertexArray(this.vertexArray);

        this.positionBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            new Float32Array([-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0]),
            this.gl.STATIC_DRAW
        );

        this.texCoordBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 1.0]),
            this.gl.STATIC_DRAW
        );

        this.inputFrameTexture = this.gl.createTexture();
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.inputFrameTexture);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);

        this.segmentationTexture = WebGLHelper.createTexture(
            this.gl,
            this.gl.RGBA8,
            this.outputWidth,
            this.outputHeight
        );
        this.personMaskTexture = WebGLHelper.createTexture(
            this.gl,
            this.gl.RGBA8,
            this.outputWidth,
            this.outputHeight
        );
        
        this.jointBilateralFilterStage = new JointBilateralFilter(
            this.gl,
            this.vertexShader,
            this.positionBuffer,
            this.texCoordBuffer,
            this.segmentationTexture,
            this.personMaskTexture,
            this.canvas
        );
        
        this.getBackgroundStage = () => {
            switch (this.backgroundConfig.type) {
                case BackgroundConfig.Type.None:
                    return new BackgroundNoneStage(
                        this.gl,
                        this.positionBuffer,
                        this.texCoordBuffer,
                        this.canvas
                    );
                case BackgroundConfig.Type.Blur:
                    return new BackgroundBlurStage(
                        this.gl,
                        this.vertexShader,
                        this.positionBuffer,
                        this.texCoordBuffer,
                        this.personMaskTexture,
                        this.canvas
                    );
                case BackgroundConfig.Type.Image:
                    return new BackgroundImageStage(
                        this.gl,
                        this.positionBuffer,
                        this.texCoordBuffer,
                        this.personMaskTexture,
                        this.backgroundConfig.url,
                        this.canvas
                    );
                case BackgroundConfig.Type.Video:
                    return new BackgroundVideoStage(
                        this.gl,
                        this.positionBuffer,
                        this.texCoordBuffer,
                        this.personMaskTexture,
                        this.backgroundConfig.url,
                        this.canvas
                    );
                default:
                    break;            
            }
        };
        
        this.backgroundStage = this.getBackgroundStage();      
    }

    render (
        sourceImage,
        sourceImageSegmentationMask
    ) {
        
        this.gl.clearColor(0, 0, 0, 0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT);

        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.inputFrameTexture);

        // texImage2D seems faster than texSubImage2D to upload
        // video texture
        this.gl.texImage2D(
            this.gl.TEXTURE_2D,
            0,
            this.gl.RGBA,
            this.gl.RGBA,
            this.gl.UNSIGNED_BYTE,
            sourceImage
        );

        this.gl.bindVertexArray(this.vertexArray);
        
        this.gl.activeTexture(this.gl.TEXTURE1);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.segmentationTexture);
        
        this.gl.texSubImage2D(
            this.gl.TEXTURE_2D,
            0,
            0,
            0,
            this.outputWidth,
            this.outputHeight,
            this.gl.RGBA,
            this.gl.UNSIGNED_BYTE,
            sourceImageSegmentationMask
        );     

        this.jointBilateralFilterStage.render();
        this.backgroundStage.render();
    }

    updatePostProcessingConfig(
        postProcessingConfig
    ) {
        this.jointBilateralFilterStage.updateSigmaSpace(
            postProcessingConfig.jointBilateralFilter.sigmaSpace
        );
        this.jointBilateralFilterStage.updateSigmaColor(
            postProcessingConfig.jointBilateralFilter.sigmaColor
        );

        if (this.backgroundConfig.type === 'image') {
            const backgroundImageStage = this.backgroundStage;
            backgroundImageStage.updateCoverage(postProcessingConfig.coverage);
            backgroundImageStage.updateLightWrapping(
                postProcessingConfig.lightWrapping
            );
            backgroundImageStage.updateBlendMode(postProcessingConfig.blendMode);
        }
        else if (this.backgroundConfig.type === 'video') {
            const backgroundVideoStage = this.backgroundStage;
            backgroundVideoStage.updateCoverage(postProcessingConfig.coverage);
            backgroundVideoStage.updateLightWrapping(
                postProcessingConfig.lightWrapping
            );
            backgroundVideoStage.updateBlendMode(postProcessingConfig.blendMode);
        }
        else if (this.backgroundConfig.type === 'blur') {
            const backgroundBlurStage = this.backgroundStage;
            backgroundBlurStage.updateCoverage(postProcessingConfig.coverage);
        }
    }

    cleanUp() {
        if (this.backgroundConfig.type !== BackgroundConfig.Type.None) {
            this.backgroundStage.cleanUp();
            this.jointBilateralFilterStage.cleanUp();
            
            this.gl.deleteTexture(this.personMaskTexture);
            this.gl.deleteTexture(this.segmentationTexture);
            this.gl.deleteTexture(this.inputFrameTexture);
            this.gl.deleteBuffer(this.texCoordBuffer);
            this.gl.deleteBuffer(this.positionBuffer);
            this.gl.deleteVertexArray(this.vertexArray);
            this.gl.deleteShader(this.vertexShader);
        }
    }
}
