import { Component, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowMessageService } from '../../../services/window-message-service/window-message.service';
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-cobrowse-window',
  templateUrl: './engagement-cobrowse-window.component.html',
  styleUrls: ['./engagement-cobrowse-window.component.scss']
})
export class EngagementCoBrowseWindowComponent implements OnDestroy {

  private currentPage   :string = "";

  @Input() public set currentUrl(url:string){
    this.onPageChange(url);
  }

  public get currentUrl():string{
    return this.currentPage;
  }

  @Output() pageChanged = new EventEmitter<string>();
  public browsingTab   :any;
  private sub: Subscription;

  constructor(private windowMessagingService: WindowMessageService) {
    this.sub = this.windowMessagingService.browserIframeMessage$.subscribe( message => {
      if (message.type === "v24page") {

        if(message.parent === "coBrowsingTab"){
          const url = message.message;

          if(url !== this.currentPage){
            this.currentPage = url;
            this.pageChanged.emit(url);
          }
        }
      }
    });
  }

  onPageChange(url: string){
    this.currentPage = url;
    if(this.browsingTab){
      this.browsingTab.postMessage({type:"v24page", message: url},"*");
    }
  }

  openNewTab(){
    if((this.browsingTab == null || this.browsingTab.closed)){
      this.browsingTab = this.windowMessagingService.openWindow(this.currentPage, 'coBrowsingTab');
    }
    else {
      this.browsingTab.focus();
    }
  }

  ngOnDestroy(){
    if(this.browsingTab)
    {
      this.browsingTab.close();
    }

    this.browsingTab = null;
    this.sub.unsubscribe();
    this.currentPage = "";
  }

  protected readonly faWindowRestore = faWindowRestore;
}
