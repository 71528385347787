import {Injectable} from '@angular/core';
import {OnlineState} from '../enums/online-state.enum';
import {OnlineService} from './online.service';
import {AuthService} from './auth-service/auth.service';
import {Router} from '@angular/router';
import {SettingsService} from './settings-service/settings.service';
import {timer} from 'rxjs';
import {AlertService, AlertType} from './alert-service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  public showAutoLogoutMessage = false; // true if a message about autologout should be shown

  private authTokenExpiredMessage: string = '';

  constructor(
    private readonly onlineService: OnlineService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly alertService: AlertService,
  ) {
    this.authTokenExpiredMessage = this.settingsService.getResourceOrDefault('AUTHTOKEN_EXPIRED_MESSAGE', 'Your authentication token has expired and you have been logged out.');
    if (!window["__karma__"]) {
      this.auth.currentAgent.subscribe(v => {
        this.agentCheck();
      });
      const intervalMS = 60 * 1000;
      timer(0, intervalMS).subscribe(() => this.agentCheck());
    }
  }

  // log the agent out after being on break for a period of time
  autologoutListener() {
    let timer;

    this.onlineService.currentState.subscribe(([val, _]) => {
      this.showAutoLogoutMessage = false;
      if (val === OnlineState.OnBreak) {
        const timePeriod = parseFloat(this.settingsService.getResourceOrDefault('AUTOLOGOUT_TIME_HOURS', '8'));
        const timePeriodMS = timePeriod * 3600000;

        timer = setTimeout( () =>  {
          this.logout();
          this.showAutoLogoutMessage = true;
        }, timePeriodMS);
      } else if (timer !== undefined) {
        clearTimeout(timer);
      }
    });
  }

  // log the user out, redirect them to the login page, and remove resources
  logout(): void {
    this.auth.logout().subscribe((success) => {
      if (success) {
        this.navigateToLogin();
      }
    });
  }

  private navigateToLogin() {
    sessionStorage.removeItem('resources');//remove resource on logout
    this.router.navigateByUrl('/login');
  }

  private agentCheck() {
    const currentAgent = this.auth.currentAgent.value;
    if (currentAgent) {
      const tokenLengthMS = ((24 * 60) - 5) * 60 * 1000; // Log out with 5 minutes to spare if possible, so they can log back in straight away.
      if ((new Date().getTime() - this.auth.getAgentLoginTime()) >= tokenLengthMS) {
        this.alertService.addAlert(this.authTokenExpiredMessage, AlertType.Danger, 1e99);
        this.auth.logout(true).subscribe(() => {
          this.navigateToLogin();
        });
      }
    }
  }
}
