import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotBackendService {

  constructor(
    private http: HttpClient
  ) {
  }

  public getScreenshot(forUrl: string, width: number, height: number, authToken: string): Observable<string> {
    const url = `${environment.screenshotRoot}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    return this.http.post<string>(
      url,
      {
        url: forUrl,
        // viewportWidth: width,
        // viewportHeight: height
        format: 'jpeg',
        full: true
      },
      httpOptions
    );
  }

}
