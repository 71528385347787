import {DeliveryStatus, TextMessage, TextMessages, TextMessageTypes} from '../classes/TextMessage';
import {LoggingService} from './logging.service';
import {SettingsService} from './settings-service/settings.service';
import {HubVisitor} from './visitor-service/HubVisitor';
import {Channel} from './visitor-service/Channel';

export class AsyncMessageHandler {
  public Messages: TextMessages = new TextMessages(this.logging);

  private asyncEvents: Message[] = [];
  private currentEngagement: boolean = false;
  private otherMessages: TextMessage[] = [];

  constructor(
    private readonly logging: LoggingService,
    private readonly settings: SettingsService,
    private readonly username: string,
    private readonly _conversationId: string) {
  }

  public handleMessage(msg: Message) {
    this.asyncEvents.push(msg);

    switch (msg.Type) {
      case ThreadMessageType.CustomerChat:
        this.handleCustomerChat(msg);
        break;
      case ThreadMessageType.AgentChat:
        this.handleAgentChat(msg);
        break;
      case ThreadMessageType.StartConversation:
        if (msg.Conversation.Id.Id.toLowerCase() === this._conversationId) {
          this.currentEngagement = true;
        }
        this.handleStartConversation(msg);
        break;
      case ThreadMessageType.AcceptConversation:
        this.handleAcceptConversation(msg);
        break;
      case ThreadMessageType.StatusMessage:
        this.handleStatusMessage(msg);
        break;
      case ThreadMessageType.FileUploadMessage:
        this.handleFileUploadMessage(msg);
        break;
      case ThreadMessageType.SurveyMessage:
        this.handleSurveyMessage(msg);
        break;
      case ThreadMessageType.SmsSpecialMessage:
        this.handleSmsSpecialMessage(msg);
        break;
      case ThreadMessageType.UnassignMessage:
        this.handleUnassignMessage(msg);
        break;
      case ThreadMessageType.ReassignMessage:
        this.handleReassignMessage(msg);
        break;
      case ThreadMessageType.CustomerMedia:
        this.handleCustomerMedia(msg);
        break;
      case ThreadMessageType.Template:
        this.handleTemplateMessage(msg);
        break;
      case ThreadMessageType.MessageUpdate:
        this.handleMessageUpdate(msg);
        break;
      default:
        this.handleUnknownMessage(msg);
        break;
    }
  }

  public handleMessages(msgs: Message[]) {
    this.Messages.disableDateDividers();
    msgs.sort((a,b) => new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime());
    for (const m of msgs) {
      this.handleMessage(m);
    }
    this.Messages.enableDateDividers();

    // At this point we have loaded all messages.
    const allMessages = this.Messages.messages;
    const currentMessages = allMessages.filter(x => x.currentEngagement);

    this.otherMessages = allMessages.filter(x => !x.currentEngagement &&
      (x.senderType == TextMessageTypes.INTERNAL_MESSAGE || x.senderType == TextMessageTypes.TRANSFER_MESSAGE));

    this.Messages.clear();
    this.Messages.addMessages(currentMessages);
  }

  private handleCustomerChat(msg: CustomerChatMessage) {
    const customerTextMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: TextMessageTypes.CUSTOMER,
      message: msg.Message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(customerTextMessage);

    this.onCustomerChat(msg, customerTextMessage);
  }

  protected onCustomerChat(msg: CustomerChatMessage, textMessage: TextMessage) {
  }

  private handleCustomerMedia(msg: CustomerMediaMessage) {
    const customerTextMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: TextMessageTypes.CUSTOMER,
      message: msg.Message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(customerTextMessage);

    this.onCustomerMedia(msg, customerTextMessage);
  }

  protected onCustomerMedia(msg: CustomerMediaMessage, textMessage: TextMessage) {
  }

  private handleTemplateMessage(msg: TemplateMessage) {
    // Template messages can only be sent from bots
    const agentTextMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: TextMessageTypes.OTHER_AGENT,
      message: msg.TemplateText,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(agentTextMessage);

    this.onTemplateMessage(msg, agentTextMessage);
  }

  protected onTemplateMessage(msg: TemplateMessage, textMessage: TextMessage) {
  }

  private handleMessageUpdate(msg: UpdateMessage) {
    const lastMessage = this.Messages.findLastMessage(m => m.id === msg.RefMessageId);

    if (lastMessage) {
      const newMessage = this.Messages.updateMessage(lastMessage, { message: msg.Message});
      this.onMessageUpdate(msg, newMessage);
    } else {
      this.logging.warn(`Unable to find message ${msg.RefMessageId} to update.`);
    }
  }

  protected onMessageUpdate(msg: UpdateMessage, textMessage: TextMessage) {
  }

  private handleAgentChat(msg: AgentChatMessage) {
    const agentTextMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: msg.Sender === this.username ? TextMessageTypes.ME : TextMessageTypes.OTHER_AGENT,
      message: msg.Message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(agentTextMessage);

    this.onAgentChat(msg, agentTextMessage);
  }

  protected onAgentChat(msg: AgentChatMessage, textMessage: TextMessage) {
  }

  private handleSurveyMessage(msg: SurveyMessage) {
    const surveyMessage: TextMessage = {
      id: msg.MessageId,
      senderName: '',
      senderId: msg.Sender,
      senderType: TextMessageTypes.INTERNAL_MESSAGE,
      message: 'Customer survey sent',
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(surveyMessage);

    this.onSurvey(msg, surveyMessage);
  }

  protected onSurvey(msg: SurveyMessage, textMessage: TextMessage) {
  }

  private handleFileUploadMessage(msg: FileUploadMessage) {
    const text = this.settings.getResourceOrDefault('WHATSAPP_FILEUPLOAD_TEXT', msg.FileUploadUrl);
    const message = `<a href='${msg.FileUploadUrl}' target='_blank'>${text}</a>`;

    const agentTextMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: msg.Sender === this.username ? TextMessageTypes.ME : TextMessageTypes.OTHER_AGENT,
      message: message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(agentTextMessage);

    this.onFileUpload(msg, agentTextMessage);
  }

  protected onFileUpload(msg: FileUploadMessage, textMessage: TextMessage) {
  }

  private handleStartConversation(msg: StartConversation) {
    const startingMessage = this.Messages.findLastMessage(m => m.id === msg.Conversation.StartingMessageId);
    if (startingMessage) {
      const startMessage: TextMessage = {
        id: msg.MessageId,
        senderName: '',
        senderId: msg.Sender,
        senderType: TextMessageTypes.SESSION_START,
        message: 'Async Conversation',
        timestamp: new Date(startingMessage.timestamp.getTime() - 1000),
        originalMessage: '',
        currentEngagement: this.currentEngagement,
        verified: null
      };
      this.Messages.addMessage(startMessage);

      // As the initial text chat for a conversation may come before the start message
      // need to update its current engagement flag.
      this.Messages.updateMessage(startingMessage, {currentEngagement: this.currentEngagement});

      this.onStartConversation(msg, startMessage);
    }
  }

  protected onStartConversation(msg: StartConversation, textMessage: TextMessage) {
  }

  private handleAcceptConversation(msg: AcceptConversation) {
    this.onAcceptConversation(msg, null);
  }

  protected onAcceptConversation(msg: AcceptConversation, textMessage: TextMessage) {
  }

  private handleStatusMessage(msg: StatusMessage) {
    const lastMessage = this.Messages.findLastMessage(m => m.id === msg.RefMessageId);

    if (lastMessage) {
      let newDeliveryTimestamp: Date;
      if (lastMessage.deliveryTimestamp) {
        const newTs = new Date(msg.Timestamp);
        newDeliveryTimestamp = newTs > lastMessage.deliveryTimestamp ? newTs : lastMessage.deliveryTimestamp;
      } else {
        newDeliveryTimestamp = new Date(msg.Timestamp);
      }

      const status = this.messageStatus(msg.MessageStatus);
      const deliveryStatus = Math.max(lastMessage.deliveryStatus || 0, status);
      const newMessage = this.Messages.updateMessage(lastMessage, {
        deliveryStatus,
        deliveryTimestamp: newDeliveryTimestamp
      });
      this.onStatus(msg, newMessage);
    } else {
      this.logging.warn(`Unable to find message id ${msg.RefMessageId}`);
    }
  }

  protected onStatus(msg: StatusMessage, textMessage: TextMessage) {
  }

  private handleSmsSpecialMessage(msg: SmsSpecialMessage) {
    this.onSmsSpecialMessage(msg);
  }

  protected onSmsSpecialMessage(msg: SmsSpecialMessage) {
  }

  private handleUnassignMessage(msg: UnassignMessage) {
    const textMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: TextMessageTypes.TRANSFER_MESSAGE,
      message: msg.Message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(textMessage);

    this.onUnassignMessage(msg, textMessage);
  }

  protected onUnassignMessage(msg: UnassignMessage, textMessage: TextMessage) {
  }

  private handleReassignMessage(msg: ReassignMessage) {
    const textMessage: TextMessage = {
      id: msg.MessageId,
      senderName: msg.Name,
      senderId: msg.Sender,
      senderType: TextMessageTypes.TRANSFER_MESSAGE,
      message: msg.Message,
      timestamp: new Date(msg.Timestamp),
      originalMessage: '',
      currentEngagement: this.currentEngagement,
      verified: null
    };
    this.Messages.addMessage(textMessage);

    this.onReassignMessage(msg, textMessage);
  }

  protected onReassignMessage(msg: ReassignMessage, textMessage: TextMessage) {
  }

  private handleUnknownMessage(msg: Message) {
    this.logging.warn(`Unknown async message type '${msg.Type}' in message id: ${msg.MessageId}`);
    this.onUnknownMessage(msg);
  }

  protected onUnknownMessage(msg: Message) {
  }

  private messageStatus(messageStatus: string): DeliveryStatus {
    switch (messageStatus) {
      case 'sending':
        return DeliveryStatus.Sending;
      case 'sent':
        return DeliveryStatus.Sent;
      case 'delivered':
        return DeliveryStatus.Delivered;
      case 'read':
        return DeliveryStatus.Read;
      case 'error':
      case 'failed':
        return DeliveryStatus.Failed;
      default:
        return DeliveryStatus.Unknown;
    }
  }

  protected addHistory(history: TextMessage[]) {
    this.Messages.disableDateDividers();
    if (history.length > 0) {
      const first = history[0].timestamp;
      const extraMessages = this.otherMessages.filter(x => x.timestamp >= first);
      this.Messages.addHistory([...history, ...extraMessages]);
    } else {
      this.Messages.addHistory([...history, ...this.otherMessages]);
    }
    this.Messages.enableDateDividers();
  }
}

export class ThreadMessage {
  public MessageId: number;

  public ThreadId: ThreadId;

  public Sender: string;

  public Timestamp: string;

  public Sitename: Site;

  public Type: ThreadMessageType;
}

export class CustomerChatMessage extends ThreadMessage {
  public Message: string;
  public Name: string;
  public Type: ThreadMessageType.CustomerChat = ThreadMessageType.CustomerChat;
}

export class CustomerMediaMessage extends ThreadMessage {
  public Message: string;
  public MediaUrl: string;
  public Name: string;
  public Type: ThreadMessageType.CustomerMedia = ThreadMessageType.CustomerMedia;
}

export class AgentChatMessage extends ThreadMessage {
  public Message: string;
  public Name: string;
  public Type: ThreadMessageType.AgentChat = ThreadMessageType.AgentChat;
}

export class FileUploadMessage extends ThreadMessage {
  public FileUploadUrl: string;
  public Name: string;
  public Type: ThreadMessageType.FileUploadMessage = ThreadMessageType.FileUploadMessage;
}

export class StartConversation extends ThreadMessage {
  public Conversation: HubConversation;
  public Type: ThreadMessageType.StartConversation = ThreadMessageType.StartConversation;
}

export class AcceptConversation extends ThreadMessage {
  public Conversation: HubConversation;
  public Type: ThreadMessageType.AcceptConversation = ThreadMessageType.AcceptConversation;
}

export class SurveyMessage extends ThreadMessage {
  public Conversation: HubConversation;
  public Type: ThreadMessageType.SurveyMessage = ThreadMessageType.SurveyMessage;
}

export class StatusMessage extends ThreadMessage {
  public Conversation: HubConversation;
  public Type: ThreadMessageType.StatusMessage = ThreadMessageType.StatusMessage;

  public RefMessageId: number;
  public AccountSid: string;
  public MessageSid: string;
  public MessageStatus: string;
}

export class SmsSpecialMessage extends ThreadMessage {
  public Message: string;
  public Name: string;
  public MessagingEnabled: boolean;
  public Type: ThreadMessageType.SmsSpecialMessage = ThreadMessageType.SmsSpecialMessage;
}

export class UnassignMessage extends ThreadMessage {
  public Message: string;
  public Name: string;
  public Type: ThreadMessageType.UnassignMessage = ThreadMessageType.UnassignMessage;
}

export class ReassignMessage extends ThreadMessage {
  public Message: string;
  public Name: string;
  public ConversationId: ConversationId;
  public Type: ThreadMessageType.ReassignMessage = ThreadMessageType.ReassignMessage;
}

export class UpdateMessage extends ThreadMessage {
  public RefMessageId: number;
  public MessageSid: string;
  public Message: string;
  public Type: ThreadMessageType.MessageUpdate = ThreadMessageType.MessageUpdate;
}

export class TemplateMessage extends ThreadMessage {
  public Format: number;
  public TemplateText: string;
  public Name: string;
  public Type: ThreadMessageType.Template = ThreadMessageType.Template;
}

export interface Site {
  Sitename: string;
}

export interface ConversationId {
  Id: string;
}

export interface ThreadId {
  Id: string;
}

export const enum ConversationState {
  Unassigned = 0,
  Post = 1,
  Engaged = 2,
  Bot = 3,
  Transfer = 4,
}

export interface HubConversation {
  State: ConversationState;
  Site: Site;

  Id: ConversationId;

  ThreadId: ThreadId;

  Visitor: HubVisitor;
  FriendlyName: string;
  SiteSection: string;
  StartingMessageId: number;
  AgentUsername: string;

  Channel: Channel;
}

export enum ThreadMessageType {
  CustomerChat = 0,
  AgentChat = 1,
  StartConversation = 2,
  AcceptConversation = 3,
  CloseConversation = 4,
  EndConversation = 5,
  StatusMessage = 6,
  SurveyMessage = 8,
  FileUploadMessage = 9,
  SmsSpecialMessage = 10,
  UnassignMessage = 12,
  ReassignMessage = 13,
  CustomerMedia = 14,
  MessageUpdate = 15,
  Template = 16,
}

export type Message =
  CustomerChatMessage
  | AgentChatMessage
  | StartConversation
  | AcceptConversation
  | StatusMessage
  | FileUploadMessage
  | SurveyMessage
  | SmsSpecialMessage
  | UnassignMessage
  | ReassignMessage
  | CustomerMediaMessage
  | UpdateMessage
  | TemplateMessage;
