import { Pipe, PipeTransform } from '@angular/core';
import {Snooping, SnoopingAsyncChat, SupervisingEngagementTypes} from '../supervisor.component';
import {DefaultGroup} from "../../veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
  name: 'supervisorAsyncConversationGroup'
})
export class SupervisorAsyncConversationGroupPipe implements PipeTransform {
  transform(joined: Snooping[], selectedDept: string): SnoopingAsyncChat[] {
    const joinedConversations: SnoopingAsyncChat[] = <SnoopingAsyncChat[]>joined.filter(j => j.type === SupervisingEngagementTypes.SnoopingOnAsyncChat);

    if (selectedDept !== DefaultGroup.NoSuperVisor) {
      const filtered: SnoopingAsyncChat[] = [];

      for (const joinedEngagement of joinedConversations) {
        if (joinedEngagement.visitor.agentGroup.some(group => group.Name === selectedDept)) {
          filtered.push(joinedEngagement);
        }
      }

      return filtered;
    } else {
      return joinedConversations;
    }
  }
}
