import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {DeliveryStatus, TextMessage, TextMessageTypes} from '../../../classes/TextMessage';
import {CallStatuses} from '../../../utils/call-statuses';
import {VisitorStatus} from '../../../enums/EngagementStatus.enum';

@Component({
  selector: 'app-text-chat',
  templateUrl: './text-chat.component.html',
  styleUrls: ['./text-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextChatComponent {
  DeliveryStatus = DeliveryStatus;
  VisitorStatus = VisitorStatus;

  private _message: TextMessage;

  @Input() public set message(v: TextMessage) {
    this._message = v;
    switch (this._message.senderType) {
      case TextMessageTypes.CUSTOMER:
        this.senderName = (this._message.senderName?.length > 0) ? this._message.senderName : '';
        break;
      case TextMessageTypes.OTHER_CUSTOMER:
        this.senderName = '';
        break;
      default:
        this.senderName = this._message.senderName;
    }

    this.changeRef.detectChanges();
  }

  public get message(): TextMessage {
    return this._message;
  }

  TextMessageSenders = TextMessageTypes;

  public senderName = '';

  constructor(private readonly changeRef: ChangeDetectorRef) {
  }

  protected readonly CallStatuses = CallStatuses;
}
