import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
  selector: "app-block-user-modal",
  templateUrl: "./block-user-modal.component.html",
  styleUrls: ["./block-user-modal.component.scss"],
})
export class BlockCustomerModalComponent {

  public cancelBlockCustomer: () => void;

  reasonText = "";

  public onBlockCustomer: (reasonText: string) => void;

  constructor(private dialogConfig: DynamicDialogConfig<BlockCustomerModalComponent>) {
    this.cancelBlockCustomer = this.dialogConfig.data.cancelBlockCustomer;
    this.onBlockCustomer = this.dialogConfig.data.onBlockCustomer;
  }
}
