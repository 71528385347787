import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {EngagementEvent} from '../../services/crm-service/engagement-event';
import * as moment from 'moment';
import {faCaretDown, faCaretRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {CrmService} from '../../services/crm-service/crm.service';
import {EngagementChatHistory} from '../../services/crm-service/EngagementChatHistory';
import {EngagementPurchaseHistory} from '../../services/crm-service/engagement-purchase-history';

@Component({
  selector: 'app-session-history',
  templateUrl: './session-history.component.html',
  styleUrls: ['./session-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionHistoryComponent {

  @Input() rows: number = 5;

  public sessionHistory: EngagementEvent[];
  public engagementTranscript: Map<string, string> = new Map<string, string>();
  public engagementPurchases: Map<string, EngagementPurchaseHistory[]> = new Map<string, EngagementPurchaseHistory[]>();

  @Input() isControlBarView: boolean = false;

  @Input() set visitorSessionHistory(visitorSessionHistory: EngagementEvent[]) {
    if (visitorSessionHistory == null) {
      this.sessionHistory = [];
    } else {
      this.sessionHistory = visitorSessionHistory.sort((e1: EngagementEvent, e2: EngagementEvent) => {
        const e1Time = moment.utc(e1.StartTime, 'DD-MM-YYYY HH:mm:ss').local();
        const e2Time = moment.utc(e2.StartTime, 'DD-MM-YYYY HH:mm:ss').local();

        return e2Time.valueOf() - e1Time.valueOf();
      });
    }
    this.changeRef.detectChanges();
  }

  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;

  constructor(private crmService: CrmService, private readonly changeRef: ChangeDetectorRef) {
  }


  public getDate(timestamp: string) {
    const date = moment.utc(timestamp, 'DD-MM-YYYY HH:mm:ss').local();
    return date;
  }

  public getTime(timestamp: string) {
    const date = this.getDate(timestamp);
    return date.format("HH:mm A");
  }

  public loadDetails(engagementGuid: string) {
    this.loadTranscript(engagementGuid);
    this.loadPurchases(engagementGuid);
    this.changeRef.detectChanges();
  }

  private loadTranscript(engagementGuid: string) {

    if (!this.engagementTranscript.has(engagementGuid)) {

      this.crmService.getChatTranscript(engagementGuid).subscribe(
        transcript => {
          if (transcript) {
            this.engagementTranscript.set(engagementGuid, this.formatTranscript(transcript));
          }
          this.changeRef.detectChanges();
        });

    }
  }

  private formatTranscript(transcript: EngagementChatHistory[]) {
    return transcript.reduce((acc, message) => acc + this.createChatHistoryMessage(message), '');
  }

  private createChatHistoryMessage(message: EngagementChatHistory) {
    const name = this.getMessageName(message);
    return `<div class="d-flex flex-column mb-2">
          <span>
            <b>${name} ${ this.getTime(message.TimeStamp) }</b>
          </span>
          <span>${atob(message.Message)}
          </span>
        </div>`;
  }

  private getMessageName(message: EngagementChatHistory): string {
    const isAgent: boolean = message.MessageType === "14";

    if (isAgent) {
      return message.AgentName;
    } else if(message.CustomerName) {
      return message.CustomerName;
    } else {
      return "Customer";
    }
  }

  private loadPurchases(engagementGuid: string) {

    if (!this.engagementPurchases.has(engagementGuid)) {

      this.crmService.getPurchaseHistory(engagementGuid).subscribe(
        purchases => {
          if (purchases && purchases.length > 0) {
            this.engagementPurchases.set(engagementGuid, purchases);
          }
          this.changeRef.detectChanges();
        });
    }
  }

  public isNullOrEmpty(v) : boolean {
    return !v || v === "";
  }

  protected readonly faChevronLeft = faChevronLeft;
}
