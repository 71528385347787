import EventEmitter from "./EventEmitter";
import Constants from "./Constants";

export default class TrackMeter extends EventEmitter {
    constructor(peerType) {        
        super();

        this.peerType = peerType;

        // Retrieve AudioContext with all the prefixes of the browsers
        window.AudioContext = window.AudioContext || window.webkitAudioContext;

        this.audioContext = null;
        this.processor = null;
        this.mediaStreamSource = null;

        this.volumeAudioProcess = (event) => {
            //console.log("processing");
            var buf = event.inputBuffer.getChannelData(0);
            var bufLength = buf.length;
            
            let max = -1;
            let sum = 0;
            for (let i = 0; i < bufLength; ++i) {
                sum += buf[i] * buf[i];    
                //max = Math.max(max, buf[i]);
            }

            max = Math.sqrt(sum / bufLength);

            if (max !== -1) {
                this.emit("TrackMeter:Level", max);
                //console.log("Level:" + max);
            }                
        };
    }

    attach(track) {
        // dont attach as agent, as that has its own mechanism 
        if (this.peerType === Constants.PeerType.Agent) {
            return; 
        }
        
        // Get an audio context
        this.audioContext = new AudioContext();
        
        //console.log("TrackMeter attach track:" + track);
        this.detach();
        // Create an AudioNode from the stream.
        this.mediaStreamSource = this.audioContext.createMediaStreamSource(new MediaStream([track]));
        
        this.processor = this.audioContext.createScriptProcessor(1024);
        this.processor.onaudioprocess = this.volumeAudioProcess;
    
        this.mediaStreamSource.connect(this.processor);
        this.processor.connect(this.audioContext.destination);
        
        this.audioContext.resume();    
    }

    detach() {
        //console.log("TrackMeter detach");
        if (this.mediaStreamSource) {
            this.mediaStreamSource.disconnect();
            this.mediaStreamSource = null;
        }
        if (this.processor) {
            this.processor.disconnect();
            this.processor.onaudioprocess = null;
            this.processor = null;
        }
        this.emit("TrackMeter:Level", 0);
    }

}
