export enum VirtualBackgroundType {
    None = 'none',
    Blur = 'blur',
    Image = 'image',
    Video = 'video'
}

export enum VirtualBackgroundNumericType {
    None = 0,
    Blur = 1,
    Image = 2,
    Video = 3
}

export class VirtualBackground {
    public onByDefault : boolean;
    public type : VirtualBackgroundType;
    public url : string;
    public jointBilateralFilterSigmaSpace : number;
    public jointBilateralFilterSigmaColor : number;
    public backgroundCoverageMin : number;
    public backgroundCoverageMax : number;
    public backgroundLightWrapping : number;
    public backgroundBlendMode : string;

    public static convert(type: any) {
        return VirtualBackgroundType[VirtualBackgroundNumericType[type]];
    }
}

