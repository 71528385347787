import EventEmitter from "./EventEmitter"

export default class PeerAudio extends EventEmitter{
    constructor(theirPeerId) {
        
        super();
        
        this.peerId = theirPeerId;
        this.mediaStream = null;
        this.audioObj = null;
    }

    addSource(mediaStream) {
        //console.log("######## addSource:" + this.peerId);
        this.audioObj = document.createElement("AUDIO");
        this.audioObj.autoplay = true;
        this.audioObj.srcObject = mediaStream;
        this.audioObj.onloadedmetadata = e => {
            console.log("Loaded metadata for peer audio:" + this.peerId);  
            var playPromise = this.audioObj.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log("PeerAudio playing:" + this.peerId);
                    this.emit("PeerAudio:AudioMediaReady", this);
                }).catch(error => {
                    console.log("PeerAudio unable to play: " + this.peerId + " error: " + error);
                    this.emit("PeerAudio:AutoPlayPrevented");
                });
            }
        };    
    }

    removeSource() {
        //console.log("######## removeSource:" + this.peerId);
        if (this.audioObj) {
            this.audioObj.srcObject = null;
        }
        this.audioObj = null;
        this.mediaStream = null;        
    }

    setVolume(volume) {
        //console.log("######## setVolume:" + this.peerId + " volume:" + volume);
        if (this.audioObj) {
            this.audioObj.volume = volume;
        }       
    }

    resume() {
        if (this.audioObj) {
            var playPromise = this.audioObj.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log("PeerAudio resumed playing:" + this.peerId);
                    this.emit("PeerAudio:AudioMediaReady", this);
                }).catch(error => {
                    console.log("PeerAudio unable to resume play: " + this.peerId + " error: " + error);
                });
            }
        }
    }

    setOutput(speaker) {
        if (this.audioObj && typeof this.audioObj.sinkId !== 'undefined') {   
            if (speaker != null) {
                console.log('Changing Audio output device from: ' + this.audioObj.sinkId + ' to: ' + speaker);
                if (this.audioObj.sinkId !== speaker) {
                    this.audioObj.setSinkId(speaker)
                    .then(function() {
                        console.log('Audio output device attached: ' + speaker);
                    })
                    .catch(function(error) {
                        console.log("Unable to set output device to: " + speaker + " error: " + error);
                    });
                }
                else {    
                    console.log("Output is already set to: " + speaker);
                }            
            }
            else {
                console.log("Selected speaker is null, so using default");
            }
        }
        else {
            console.log("No audio for this peer or this browser doesnt support setting the audio output");
        }
    }

}
