import * as Rollbar from 'rollbar';
import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthService} from './auth-service/auth.service';
import {version} from '../../environments/version';
import {HttpErrorResponse} from '@angular/common/http';

export enum Severity {
  Info,
  Debug,
  Warning,
  Error,
  Fatal
}

const rollbarConfig = {
  accessToken: environment.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: true,
  scrubTelemetryInputs: true,
  environment: environment.environmentName,
  payload: { env: environment },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements ErrorHandler {
  public static readonly SEVERITY = Severity;
  private static readonly USE_ROLLBAR = environment.production;

  private printError = true;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private readonly authService: AuthService) {

    authService.currentAgent.subscribe(agent => {
      rollbar.configure({
        payload: {
          agent: agent
        }
      });
    });

    rollbar.configure({
      code_version: version + '___' + environment.environmentName
    });
  }

  public info(message: string) {
    this.log(message, Severity.Info);
  }

  public debug(message: string) {
    this.log(message, Severity.Debug);
  }

  public warn(message: string, error?: Error) {
    this.log(message, Severity.Warning, error);
  }

  public error(message: string, error?: Error) {
    this.log(message, Severity.Error, error);
  }

  public fatal(message: string, error?: Error) {
    this.log(message, Severity.Fatal, error);
  }

  // Exposed for testing, not for use.
  public log(message: string, severity: Severity = Severity.Debug, error?: any) {
    switch (severity) {
      case Severity.Fatal:
      case Severity.Error:
        console.error(message, error);
        break;
      case Severity.Warning:
        console.warn(message, error);
        break;
      case Severity.Debug:
        console.debug(message, error);
        break;
      case Severity.Info:
        console.info(message, error);
        break;
      default:
        console.log(message, error);
        break;
    }

    if (error && error.stack && this.printError) {
      console.log(error.stack.toString());
    }

    if (LoggingService.USE_ROLLBAR && severity === Severity.Error) {
      if (error) {
        this.rollbar.error(...this.sanitizedError(error.originalError || error));
      } else {
        this.rollbar.error(...this.sanitizedError(error));
      }
    }
  }

  handleError(error: any): void {
    this.error('Fatal error.', error);
  }

  private sanitizedError(error: any) {
    // Taken from https://github.com/rollbar/rollbar.js/issues/975
    if (error instanceof Error || error instanceof String) {
      return [error];
    } else if (error instanceof HttpErrorResponse) {
      return [error.message, error];
    } else {
      return ['Error', error];
    }
  }
}
