<section class="d-flex flex-column w-100">
  <div class="button-grid-container d-flex flex-row justify-content-center align-items-center m-auto w-100" >
    <div class="button-grid border rounded">
      <!-- Top left -->
      <p-button id="top-left" styleClass="direction-button"  (onClick)="setPanelPosition([HAlign.LEFT, VAlign.TOP])" [disabled]="!enabled"
         [text]="true" [plain]="!(pos[0] === HAlign.LEFT && pos[1] === VAlign.TOP)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp" transform="rotate--45"></fa-icon>
      </p-button>

      <!-- Top center -->
      <p-button id="top-center" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.CENTER, VAlign.TOP])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.CENTER && pos[1] === VAlign.TOP)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp"></fa-icon>
      </p-button>

      <!-- Top right -->
    <p-button id="top-right" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.RIGHT, VAlign.TOP])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.RIGHT && pos[1] === VAlign.TOP)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronUp" transform="rotate-45"></fa-icon>
    </p-button>

      <!-- Middle left -->
  <p-button id="middle-left" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.LEFT, VAlign.MIDDLE])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.LEFT && pos[1] === VAlign.MIDDLE)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronLeft"></fa-icon>
  </p-button>

      <!-- Center middle -->
    <p-button id="middle-center" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.CENTER, VAlign.MIDDLE])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.CENTER && pos[1] === VAlign.MIDDLE)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faCompress"></fa-icon>
    </p-button>

      <!-- Middle right -->
      <p-button id="middle-right" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.RIGHT, VAlign.MIDDLE])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.RIGHT && pos[1] === VAlign.MIDDLE)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronRight"></fa-icon>
      </p-button>

      <!-- Bottom left -->
      <p-button id="bottom-left" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.LEFT, VAlign.BOTTOM])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.LEFT && pos[1] === VAlign.BOTTOM)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown" transform="rotate-45"></fa-icon>
      </p-button>

      <!-- Bottom center -->
        <p-button id="bottom-center" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.CENTER, VAlign.BOTTOM])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.CENTER && pos[1] === VAlign.BOTTOM)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown"></fa-icon>
        </p-button>

      <!-- Bottom right -->
          <p-button id="bottom-right" styleClass="direction-button" (onClick)="setPanelPosition([HAlign.RIGHT, VAlign.BOTTOM])" [disabled]="!enabled"
           [text]="true"  [plain]="!(pos[0] === HAlign.RIGHT && pos[1] === VAlign.BOTTOM)">
        <fa-icon  [fixedWidth]="true" size="lg"  [icon]="faChevronDown" transform="rotate--45"></fa-icon>
          </p-button>
    </div>
  </div>
</section>
