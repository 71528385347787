import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {MicrosoftTimeZone, Timezones} from './timezones';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {
  transform(timestamp: string, format: string = 'DD-MM-YYYY HH:mm:ss', timezone: MicrosoftTimeZone = undefined): string {
    if (timezone) {
      return Timezones.convertTime(timestamp, timezone, format);
    }
    return moment.utc(timestamp, 'DD-MM-YYYY HH:mm:ss').local().toString();
  }

}

