import {Component, Input} from '@angular/core';
import {SiteVisitor} from '../../../../../services/visitor-service/SiteVisitor';
import {TimeUtil} from '../../../../../utils/time-util';

@Component({
  selector: 'app-dashboard-card-visitors',
  templateUrl: './dashboard-card-visitors.component.html',
  styleUrls: ['./dashboard-card-visitors.component.scss',
    '../../supervisor-dashboard-view.component.scss']
})
export class DashboardCardVisitorsComponent {
  @Input() notEngagedVisitors: SiteVisitor[];
    protected readonly TimeUtil = TimeUtil;
}
