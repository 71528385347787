import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EngagementState, JoinStateWaiting, TransferStateWaiting} from '../../services/engagement';
import {TextMessage} from '../../classes/TextMessage';
import {VisitorService} from '../../services/visitor-service/visitor.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-transfer-waiting',
  templateUrl: './engagement-transfer-waiting.component.html',
  styleUrls: ['./engagement-transfer-waiting.component.scss']
})
export class EngagementTransferWaitingComponent {
  @Input() currentEngagementStateText: string = '';
  @Input() inviteState: JoinStateWaiting | TransferStateWaiting;
  @Input() roomId: string;
  @Input() textMessages: TextMessage[];

  @Output() closeInviteRequest = new EventEmitter<void>();

  protected readonly EngagementState = EngagementState;

  hasTransferChat: boolean = false;

  constructor(
    private readonly visitorService: VisitorService,
    private readonly featureService: FeatureService) {
  }

  ngOnInit() {
    this.hasTransferChat = false; //TODO: Remove logic related to this unused feature.  This is for agents to chat to each other while transferring but does not work
  }

  sendMessage(message: string) {
    this.visitorService.sendTransferChatMessage(this.roomId, this.inviteState.operatorUsername, message)
      .subscribe((response) => {
      });
  }

  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
