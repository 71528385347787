export default class PostProcessingConfig {
    constructor({ 
        sigmaSpace = 1, 
        sigmaColor = 1, 
        coverageMin = 0.5, 
        coverageMax = 0.75, 
        lightWrapping = 0.3, 
        blendMode = 'screen' 
    } = {}) {
        this.jointBilateralFilter = new JointBilateralFilterConfig(sigmaSpace, sigmaColor);
        this.coverage = [coverageMin, coverageMax];
        this.lightWrapping = lightWrapping;
        this.blendMode = blendMode
    }
}

export class JointBilateralFilterConfig {
    constructor(sigmaSpace, sigmaColor) {
        this.sigmaSpace = sigmaSpace;
        this.sigmaColor = sigmaColor;
    }
}
