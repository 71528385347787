import {Component, OnInit, Input, Output, EventEmitter, forwardRef} from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {ControlValueAccessorBase} from "../control-value-accessor.base";

@Component({
  selector: 'app-crm-field-datetime',
  templateUrl: './crm-field-datetime.component.html',
  styleUrls: ['./crm-field-datetime.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CrmFieldDatetimeComponent),
    multi: true
  }]
})
export class CrmFieldDatetimeComponent extends ControlValueAccessorBase<string> implements OnInit {
  faCalendarAlt = faCalendarAlt;

  private _field: CrmField;

  @Input()
  set field(field: CrmField) {
    this._field = field;
    if (field) {
      this.writeValue(field.value);
    }
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getDate(date: string) {
    const dateValue = moment(date, 'DD/MM/YYYY HH:mm:ss');
    const res = moment(dateValue).format('YYYY-MM-DD');
    return res;
  }



  public onFieldValueChange(event): void {
    let value = moment(event.target.value).format('DD/MM/YYYY');
    value = value.toString() + ' ' + '00:00:00';
    this.onInputChange(value);
    if (this._field) {
      this._field.value = value;
      this.saveField.emit();
    }
  }
}
