import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';

import {CustomerSizes} from '../../services/engagement';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCompress,
  faSquareArrowUpRight
} from "@fortawesome/free-solid-svg-icons";

export enum HAlign {
  LEFT = 'left',
  CENTER = 'centre', // Note spelling of centre
  RIGHT = 'right'
}

export enum VAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

export type PanelPosition = [HAlign, VAlign];

@Component({
  selector: 'app-engagement-controls-direction-buttons',
  templateUrl: './engagement-controls-direction-buttons.component.html',
  styleUrls: ['./engagement-controls-direction-buttons.component.scss']
})
export class EngagementControlsDirectionButtonsComponent implements OnInit, OnDestroy {
  private _pos: PanelPosition = [HAlign.LEFT, VAlign.TOP];
  @Input() set panelPosition(pos: CustomerSizes) {
    const newPos = this.computePanelPosition(pos);
    if (newPos) {
      this._pos = newPos;
    }
  }
  public get pos(): PanelPosition {
    return this._pos;
  }

  @Input() enabled: boolean = true;

  @Output() panelPositionChanged = new EventEmitter<[string, string]>();

  public faCircle = faCircle;

  public HAlign = HAlign;
  public VAlign = VAlign;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  setPanelPosition(newPosition: PanelPosition) {
    if (newPosition && newPosition.length == 2) {
      const hAlign = newPosition[0].toString();
      const vAlign = newPosition[1].toString();
      this.panelPositionChanged.emit([hAlign, vAlign]);
    }

  }

  private computePanelPosition(customerDims: CustomerSizes): PanelPosition {

    if (!customerDims.panelDimensions) {
      return [HAlign.LEFT, VAlign.TOP];
    }

    const browserWidth = customerDims.browserWidth;
    const browserHeight = customerDims.browserHeight;

    // Input string
    // currentPanelTop + "," + currentPanelLeft + "," + panelWidth + "," + panelHeight;
    const split = customerDims.panelDimensions.split(',');

    if (split == null || split.length !== 4) {
      return;
    }

    const currentPanelTop = parseInt(split[0], 10);
    const currentPanelLeft = parseInt(split[1], 10);
    const panelWidth = parseInt(split[2], 10);
    const panelHeight = parseInt(split[3], 10);

    if (isNaN(currentPanelTop) || isNaN(currentPanelLeft) || isNaN(panelWidth) || isNaN(panelHeight)) {
      return;
    }

    const xCentre = currentPanelLeft + panelWidth / 2;
    const yCentre = currentPanelTop + panelHeight / 2;

    const pos: PanelPosition = [HAlign.CENTER, VAlign.MIDDLE];

    if (xCentre < browserWidth / 3) {
      pos[0] = HAlign.LEFT;
    } else if (xCentre < 2 * browserWidth / 3) {
      pos[0] = HAlign.CENTER;
    } else {
      pos[0] = HAlign.RIGHT;
    }

    if (yCentre < browserHeight / 3) {
      pos[1] = VAlign.TOP;
    } else if (yCentre < 2 * browserHeight / 3) {
      pos[1] = VAlign.MIDDLE;
    } else {
      pos[1] = VAlign.BOTTOM;
    }

    return pos;
  }

  protected readonly faArrowRight = faArrowRight;
  protected readonly faArrowsAlt = faArrowsAlt;
  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faSquareArrowUpRight = faSquareArrowUpRight;
  protected readonly faChevronUpfaArrowUp = faArrowUp;
  protected readonly faCompress = faCompress;
  protected readonly faArrowDown = faArrowDown;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronDown = faChevronDown;
}
