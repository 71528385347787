<td>
  <!--workaround for p-badge directive bug: https://github.com/primefaces/primeng/issues/12736-->

  <div id="status-icon" [pTooltip]="getStatusText(channel)" [title]="getStatusText(channel)" [class]="'p-overlay-badge ' +  CallStatuses.getStatusFontIconForChannel(channel).css">
    <fa-icon [fixedWidth]="true"
             [icon]="CallStatuses.getStatusFontIconForChannel(channel).icon"></fa-icon>

    <p-badge *ngIf="showBadge" [value]="lastMessageFailed ? '!' : unreadMessages"
             [severity]="lastMessageFailed ? 'danger' : 'info'"/>
  </div>
</td>
<td id="message-timestamps">
  <p-badge [value]="!ended ? timeSinceLastMessage : '0'" severity="info"></p-badge>
</td>
<td colspan="3">
  <app-call-list-rename-chat
    (renameChat)="renameChat.emit($event)"
    [chatName]="chatName">
  </app-call-list-rename-chat>
</td>
<td>
  <fa-icon class="info" size="lg" [icon]="faInfoCircle" pTooltip="{{currentPage}}"></fa-icon>
</td>
