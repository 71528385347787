<div class="d-flex flex-column h-100 transfer-panel">
  <app-engagement-transfer-agent-select
    class="flex-grow-1 d-flex flex-column gap-2"
    [crossSessionTransfer]="crossSessionTransfer"
    [roomAgents]="roomAgents"
    [(selectedAgent)]="selectedAgent"
    [(showVeestudioAgents)]="showVeestudioAgents"
  >
  </app-engagement-transfer-agent-select>

  <app-engagement-transfer-message
    [enableSend]="selectedAgent != null"
    [transfer]="transfer"
    (cancelInvite)="cancelInvite.emit()"
    (sendInviteRequest)="sendInviteRequest($event)">
  </app-engagement-transfer-message>
</div>
