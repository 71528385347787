import moment from 'moment-timezone';

export const DEFAULT_MS_TIMEZONE: MicrosoftTimeZone = "GMT Standard Time";
export type MicrosoftTimeZone =
  "Central Europe Standard Time"
  | "Central Standard Time"
  | "China Standard Time"
  | "Eastern Standard Time"
  | "GMT Standard Time"
  | "India Standard Time"
  | "Tokyo Standard Time"
  | "Mountain Standard Time"
  | "Pacific Standard Time"
  | "Russian Standard Time"
  | "AUS Eastern Standard Time"
  | "Arab Standard Time"
  | "E. Europe Standard Time"
  | "US Mountain Standard Time"
  | "Sri Lanka Standard Time"
  | "Central Brazilian Standard Time"
  | "SE Asia Standard Time"
  | "North Asia East Standard Time"
  | "GMT Summer Time"
  | "GMT Daylight Time";

export type IanaTimeZone = "Europe/Berlin"
  | "America/Chicago"
  | "Asia/Shanghai"
  | "America/New_York"
  | "Europe/London"
  | "Asia/Kolkata"
  | "Asia/Tokyo"
  | "America/Denver"
  | "America/Los_Angeles"
  | "Europe/Moscow"
  | "Australia/Sydney"
  | "Asia/Riyadh"
  | "Europe/Helsinki"
  | "America/Phoenix"
  | "Asia/Colombo"
  | "America/Cuiaba"
  | "Asia/Bangkok"
  | "Asia/Ulaanbaatar";

export class Timezones {

  /**
   * Mapping of Microsoft time zone IDs to IANA time zones.
   * This map is used to convert Microsoft time zone identifiers to their
   * corresponding IANA time zones.
   */
  private static msTimeZoneToIanaMap: { [key in MicrosoftTimeZone]: IanaTimeZone } = {
    "Central Europe Standard Time": "Europe/Berlin",
    "Central Standard Time": "America/Chicago",
    "China Standard Time": "Asia/Shanghai",
    "Eastern Standard Time": "America/New_York",
    "GMT Standard Time": "Europe/London",
    "India Standard Time": "Asia/Kolkata",
    "Tokyo Standard Time": "Asia/Tokyo",
    "Mountain Standard Time": "America/Denver",
    "Pacific Standard Time": "America/Los_Angeles",
    "Russian Standard Time": "Europe/Moscow",
    "AUS Eastern Standard Time": "Australia/Sydney",
    "Arab Standard Time": "Asia/Riyadh",
    "E. Europe Standard Time": "Europe/Helsinki",
    "US Mountain Standard Time": "America/Phoenix",
    "Sri Lanka Standard Time": "Asia/Colombo",
    "Central Brazilian Standard Time": "America/Cuiaba",
    "SE Asia Standard Time": "Asia/Bangkok",
    "North Asia East Standard Time": "Asia/Ulaanbaatar",
    "GMT Summer Time": "Europe/London", // BST is handled as part of Europe/London
    "GMT Daylight Time": "Europe/London" // BST is handled as part of Europe/London
  };

  /**
   * Converts a given date and time from a specified Microsoft time zone to UTC.
   *
   * @param dateTime - The date and time string to be converted.
   * @param msTimeZone - The Microsoft time zone identifier.
   * @returns The converted UTC time as a string.
   */
  public static convertTimeToUtc(dateTime: string, msTimeZone: MicrosoftTimeZone): string {
    const ianaTimeZone = this.getIanaTimeZone(msTimeZone);
    const localTime = moment.tz(dateTime, ianaTimeZone);
    return localTime.utc().format();
  }

  public static convertTime(timeStr: string, msTimezone: MicrosoftTimeZone, format?: string): string {
    return this.convertTimeMoment(timeStr, msTimezone).format(format);
  }

  public static convertTimeMoment(timeStr: string, msTimezone: MicrosoftTimeZone) {
    const ianaTimeZone = this.getIanaTimeZone(msTimezone);
    const res = moment.utc(timeStr).tz(ianaTimeZone);
    return res;
  }

  /**
   * Returns the abbreviation for the given time zone ID.
   *
   * @param timezoneId The time zone ID.
   * @returns The abbreviation of the time zone, or an empty string if the time zone ID is not recognized.
   */
  public static getAbbreviation(timezoneId: MicrosoftTimeZone): string {
    const abbreviationMap = new Map<MicrosoftTimeZone, string>([
      ["Central Europe Standard Time", "CET"],
      ["Central Standard Time", "CST"],
      ["China Standard Time", "CST"],
      ["Eastern Standard Time", "EST"],
      ["GMT Standard Time", "GMT"],
      ["India Standard Time", "IST"],
      ["Tokyo Standard Time", "JST"],
      ["Mountain Standard Time", "MST"],
      ["Pacific Standard Time", "PST"],
      ["Russian Standard Time", "RST"],
      ["AUS Eastern Standard Time", "AES"],
      ["Arab Standard Time", "AST"],
      ["E. Europe Standard Time", "EET"],
      ["US Mountain Standard Time", "MST"],
      ["Sri Lanka Standard Time", "SLST"],
      ["Central Brazilian Standard Time", "BRT"],
      ["SE Asia Standard Time", "ICT"],
      ["North Asia East Standard Time", "WIT"],
      ["GMT Summer Time", "BST"],
      ["GMT Daylight Time", "BST"]
    ]);

    return abbreviationMap.get(timezoneId) || abbreviationMap.get(DEFAULT_MS_TIMEZONE);
  }

  /**
   * Returns the IANA time zone corresponding to the given Microsoft time zone ID.
   *
   * @param msTimeZone The Microsoft time zone ID.
   * @returns The IANA time zone ID, or 'Europe/London' if the Microsoft time zone ID is not recognized.
   */
  public static getIanaTimeZone(msTimeZone: string): string {
    return this.msTimeZoneToIanaMap[msTimeZone] || 'Europe/London';
  }
}
