export enum CrmEmailState {
  Valid,
  Empty,
  Duplicate,
  Incorrect
}

export interface CrmEmailStateValid {
  type: CrmEmailState.Valid;
}

export interface CrmEmailStateEmpty {
  type: CrmEmailState.Empty;
}

export interface CrmEmailStateDuplicate {
  type: CrmEmailState.Duplicate;
}

export interface CrmEmailStateIncorrect {
  type: CrmEmailState.Incorrect;
}

export type CrmEmailStateType = CrmEmailStateValid | CrmEmailStateEmpty | CrmEmailStateDuplicate | CrmEmailStateIncorrect;
