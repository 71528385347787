import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { VirtualBackground } from '../../../../../classes/virtualBackground';
import { LoggingService } from '../../../../../services/logging.service';
import { WindowMessageService } from '../../../../../services/window-message-service/window-message.service';
import { AddPeerCommand, AudioChatCommand, CallErroredEvent, ChangeCameraCommand, ChangeMicrophoneCommand, ChangeSpeakerCommand, CreateWindowCommand, DestroyWindowCommand, DockChangeEvent, DragResizeEnabledCommand, InitialiseCallCommand, IVideoComponent, PauseCallCommand, PositionWindowCommand, RemovePeerCommand, ResumeCallCommand, SetPrimaryVisitorCommand, SignallingMessageCommand, SignallingMessageEvent, TeardownCallCommand, TextChatCommand, VideoChatCommand, VideoWindowCommand, VideoWindowEvent, VIDEO_WINDOW_COMMAND, VIDEO_WINDOW_COMMANDS, VIDEO_WINDOW_EVENT, VIDEO_WINDOW_EVENTS, WindowErroredEvent, WindowUndockedCommand } from '../IVideoComponent';

@Component({
  selector: 'app-engagement-video-electron',
  templateUrl: './engagement-video-electron.component.html',
  styleUrls: ['./engagement-video-electron.component.scss']
})
export class EngagementVideoElectronComponent implements IVideoComponent, OnInit, OnDestroy {

  @ViewChild('videoContainer', {static: true}) videoContainer: ElementRef;

  private subscriptions: Subscription[] = [];

  private _undocked:boolean;
  public set undocked(undocked:boolean) {
    this._undocked = undocked;
    const command: WindowUndockedCommand = {
      command: VIDEO_WINDOW_COMMANDS.WINDOW_UNDOCKED,
      undocked: undocked
    };
    this.sendVideoWindowCommand(command);
  }
  public get undocked() : boolean {
    return this._undocked;
  }

  private _dragResizeEnabled:boolean;
  public set dragResizeEnabled(enabled:boolean) {
    this._dragResizeEnabled = enabled;
    const dragResizeCommand: DragResizeEnabledCommand = {
      command: VIDEO_WINDOW_COMMANDS.DRAGRESIZE_ENABLED,
      enabled: enabled
    };
    this.sendVideoWindowCommand(dragResizeCommand);
    setTimeout(() => {
      if (!this._undocked) {
        this.positionWindow();
      }
    },0);
  }
  public get dragResizeEnabled() : boolean {
    return this._dragResizeEnabled;
  }


  public readonly oncreated: EventEmitter<any> = new EventEmitter();
  public readonly oninitialised: EventEmitter<void> = new EventEmitter();
  public readonly onerror: EventEmitter<string> = new EventEmitter();
  public readonly ondockchange: EventEmitter<boolean> = new EventEmitter();
  public readonly onsignallingmessage: EventEmitter<any> = new EventEmitter();

  constructor(private windowMessageService: WindowMessageService, private logger: LoggingService) {
    this.subscriptions.push(this.windowMessageService.ipcMessage$.subscribe(message => {
      if (message.action === VIDEO_WINDOW_EVENT) {
        const event = message.data as VideoWindowEvent;
        switch (event.type) {
          case VIDEO_WINDOW_EVENTS.ONWINDOWCREATED:
            this.oncreated.emit();
            break;
          case VIDEO_WINDOW_EVENTS.ONWINDOWERRORED:
            const windowErrorEvent = event as WindowErroredEvent;
            this.onerror.emit(windowErrorEvent.error);
            break;
          case VIDEO_WINDOW_EVENTS.ONCALLINITIALISED:
            this.oninitialised.emit();
            break;
          case VIDEO_WINDOW_EVENTS.ONCALLERRORED:
            const callErrorEvent = event as CallErroredEvent;
            this.onerror.emit(callErrorEvent.error);
            break;
          case VIDEO_WINDOW_EVENTS.ONDOCKCHANGED:
            const dockChangeEvent = event as DockChangeEvent;
            this.ondockchange.emit(dockChangeEvent.undocked);
            // hide the videoContainer if undocked
            if (dockChangeEvent.undocked) {
              this.videoContainer.nativeElement.style.display = 'none';
            }
            else { // show if docked
              this.videoContainer.nativeElement.style.display = 'block';
              this.positionWindow();
            }
            break;
          case VIDEO_WINDOW_EVENTS.ONSIGNALLINGMESSAGE:
            const signallingMessageEvent = event as SignallingMessageEvent;
            this.onsignallingmessage.emit(signallingMessageEvent.message);
            break;
        }
      }
    }));
  }

  ngOnInit(): void {
    // CREATE THE VIDEO WINDOW
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ OnInit");
    const currentUrl = new URL(window.location.href);
    const windowUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port?':'+currentUrl.port:''}/video-window`;
    const {x, y, width, height} = EngagementVideoElectronComponent.GetBoundingRectFor(this.videoContainer.nativeElement);

    const command: CreateWindowCommand = {
      command: VIDEO_WINDOW_COMMANDS.CREATE_WINDOW,
      x: x,
      y: y,
      width: width,
      height: height,
      url: windowUrl
    };
    this.sendVideoWindowCommand(command);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    // DESTROY THE VIDEO WINDOW
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ OnDestroy");
    const command: DestroyWindowCommand = {
      command: VIDEO_WINDOW_COMMANDS.DESTROY_WINDOW
    };
    this.sendVideoWindowCommand(command);
  }

  init(engagementId: string, iceServer: string, connectTracks: boolean, myUsername: string, cameraId: string, virtualBackground: VirtualBackground, virtualBackgroundOn: boolean) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ init");
    const command: InitialiseCallCommand = {
      command: VIDEO_WINDOW_COMMANDS.INITIALISE_CALL,
      engagementId: engagementId,
      iceServer: iceServer,
      connectTracks: connectTracks,
      peerId: myUsername,
      cameraId: cameraId,
      virtualBackground: virtualBackground,
      virtualBackgroundOn: virtualBackgroundOn
    };
    this.sendVideoWindowCommand(command);
  }

  tearDown() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ teardown");
    const command: TeardownCallCommand = {
      command: VIDEO_WINDOW_COMMANDS.TEARDOWN_CALL,
    };
    this.sendVideoWindowCommand(command);
  }

  processMessage(message: any) {
    //this.logger.debug(`@@@@@@@@@@@@@@@@@@@@@@ processmessage message:${message}`);
    const command: SignallingMessageCommand = {
      command: VIDEO_WINDOW_COMMANDS.SIGNALLING_MESSAGE,
      message: message
    };
    this.sendVideoWindowCommand(command);
  }
  addStream(peerId: string, peerType: any) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ addstream");
    const command: AddPeerCommand = {
      command: VIDEO_WINDOW_COMMANDS.ADD_PEER,
      peerId: peerId,
      peerType: peerType
    };
    this.sendVideoWindowCommand(command);
  }
  removeStream(peerId: string) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ remove stream");
    const command: RemovePeerCommand = {
      command: VIDEO_WINDOW_COMMANDS.REMOVE_PEER,
      peerId: peerId
    };
    this.sendVideoWindowCommand(command);
  }
  setPrimaryVisitor(visitor: string) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ setprimaryvisitor");
    const command: SetPrimaryVisitorCommand = {
      command: VIDEO_WINDOW_COMMANDS.SET_PRIMARY_VISITOR,
      visitor: visitor
    };
    this.sendVideoWindowCommand(command);
  }
  audioChat() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ audiochat");
    const command: AudioChatCommand = {
      command: VIDEO_WINDOW_COMMANDS.AUDIO_CHAT,
    };
    this.sendVideoWindowCommand(command);
  }
  videoChat() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ videochat");
    const command: VideoChatCommand = {
      command: VIDEO_WINDOW_COMMANDS.VIDEO_CHAT,
    };
    this.sendVideoWindowCommand(command);
  }
  textChat() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ textchat");
    const command: TextChatCommand = {
      command: VIDEO_WINDOW_COMMANDS.TEXT_CHAT,
    };
    this.sendVideoWindowCommand(command);
  }
  changeCamera(cameraId: string, width: number, height: number) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ changecamera");
    const command: ChangeCameraCommand = {
      command: VIDEO_WINDOW_COMMANDS.CHANGE_CAMERA,
      cameraId: cameraId,
      width: width,
      height: height
    };
    this.sendVideoWindowCommand(command);
  }
  changeMic(micId: string) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ changemic");
    const command: ChangeMicrophoneCommand = {
      command: VIDEO_WINDOW_COMMANDS.CHANGE_MICROPHONE,
      micId: micId
    };
    this.sendVideoWindowCommand(command);
  }
  changeSpeaker(speakerId: string) {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ changespeaker");
    const command: ChangeSpeakerCommand = {
      command: VIDEO_WINDOW_COMMANDS.CHANGE_SPEAKER,
      speakerId: speakerId
    };
    this.sendVideoWindowCommand(command);
  }
  pauseCall() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ pausecall");
    const command: PauseCallCommand = {
      command: VIDEO_WINDOW_COMMANDS.PAUSE_CALL,
    };
    this.sendVideoWindowCommand(command);
  }
  resumeCall() {
    //this.logger.debug("@@@@@@@@@@@@@@@@@@@@@@ resumecall");
    const command: ResumeCallCommand = {
      command: VIDEO_WINDOW_COMMANDS.RESUME_CALL,
    };
    this.sendVideoWindowCommand(command);
  }



  positionWindow() {
    const {x, y, width, height} = EngagementVideoElectronComponent.GetBoundingRectFor(this.videoContainer.nativeElement);
    const positionCommand: PositionWindowCommand = {
      command: VIDEO_WINDOW_COMMANDS.POSITION_WINDOW,
      x: x,
      y: y,
      width: width,
      height: height
    };
    this.sendVideoWindowCommand(positionCommand);
  }

  private sendVideoWindowCommand(command: VideoWindowCommand) {
    this.send(VIDEO_WINDOW_COMMAND, JSON.stringify(command));
  }

  private send(action, data?) {
    this.windowMessageService.postMessage({
      type: "vee24-ipc",
      command: "send",
      action,
      data
    }, "*");
  }

  static GetBoundingRectFor(element: HTMLElement) {
    const {x, y, width, height} = element.getBoundingClientRect();
    return {
      x: Math.round(x),
      y: Math.round(y),
      width: Math.round(width),
      height: Math.round(height),
    };
  }

}
