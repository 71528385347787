import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AgentCalendar } from './agent-calendar';

export interface IAgentCalendarBackendService {
}

@Injectable({
  providedIn: 'root'
})
export class AgentCalendarBackendService implements IAgentCalendarBackendService {

  constructor(
    private http: HttpClient
  ) { }

  public getAgentCalendarData(authToken: string): Observable<string> {
    const url = `${environment.appointmentRoot}/getAgentCalendar`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {      
    };

    return this.http.post<string>(
      url,
      body,
      httpOptions
    );
  }

  public getOtherAgentsCalendarData(authToken: string): Observable<string> {
    const url = `${environment.appointmentRoot}/getOtherAgentsCalendar`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };

    const body = {      
    };

    return this.http.post<string>(
      url,
      body,
      httpOptions
    );
  }

  public submitCalendarSlots(authToken: string, calendarSlots: Array<AgentCalendar>): Observable<boolean> {
    const url = `${environment.appointmentRoot}/submitCalendarSlots`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };
    const body = {
      calendarSlots:calendarSlots
    };
    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }

  public deleteCalendarSlotsByDay(authToken: string, day: string): Observable<boolean> {
    const url = `${environment.appointmentRoot}/deleteCalendarSlotsByDay`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'auth-token': authToken
      })
    };
    const body = {
      day:day
    };
    return this.http.post<boolean>(
      url,
      body,
      httpOptions
    );
  }
}
