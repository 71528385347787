import { Directive, Input, ElementRef, HostListener, Renderer2, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Overlay } from './overlay';
import { ResizeHandle } from './resize-handle';

export const enum DragResizeStatus {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}


@Directive({
  selector: '[appDragResize]'
})
export class DragResizeDirective implements OnDestroy {
  
  @Output('ondockchange') public readonly ondockchange: EventEmitter<boolean> = new EventEmitter();
  
  private _status: DragResizeStatus = DragResizeStatus.OFF;
  private _aspectRatio: number = 0.0;

  private _boxPosition: { left: number, top: number };
  private _boxSize: { width: number, height: number };    
  private _containerPos: { left: number, top: number, right: number, bottom: number };

  private _mouse: { x: number, y: number };  
  private _mouseClick: { x: number, y: number, left: number, top: number };

  private _handles: { [key: string]: ResizeHandle } = {};  
  private _resizingHandle: ResizeHandle = null;
  private _handleTypes:string[] = ['e', 's', 'se'];
  private _direction: { 's': boolean, 'e': boolean } = null;
  private _overlay: Overlay = null;
  
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnDestroy() {
    if (this.dragResizeEnabled) {
      this.removeHandles();
      this._overlay.dispose();
      this._overlay = null;
      this.removeUnDockableClass();
    }    
  }

  private updateAspectRatio() {
    if (this._boxSize.height) {
      this._aspectRatio = this._boxSize.width / this._boxSize.height;
    }
  }

  private createHandles() {    
    for (let type of this._handleTypes) {
      let handle = this.createHandleByType(type, `ng-resizable-${type}`);
      if (handle) {
        this._handles[type] = handle;
      }
    }
  }

  private createHandleByType(type: string, css: string): ResizeHandle {
    const _el = this.el.nativeElement;
    return new ResizeHandle(_el, this.renderer, type, css, this.onMouseDown.bind(this));
  }

  private removeHandles() {
    for (let type of this._handleTypes) {
      if (this._handles[type]) {
        this._handles[type].dispose();
      }      
    }
    this._handles = {};
  }

  private addUnDockableClass() {
    this.el.nativeElement.classList.add('undockable');
  }

  private removeUnDockableClass() {
    this.el.nativeElement.classList.remove('undockable');
  }

  private loadBox(){
    const {left, top, width, height} = this.el.nativeElement.getBoundingClientRect();
    this._boxPosition = {left, top};
    this._boxSize = {width, height};
  }

  private loadContainer(){
    const left = 0;
    const top = 0;
    const right = window.innerWidth;
    const bottom = window.innerHeight;    

    this._containerPos = { left, top, right, bottom };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.dragResizeEnabled) return;
    
    this.loadContainer();
    this.keepInBounds();
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: any, handle: ResizeHandle) {
    if (!this.dragResizeEnabled) return;

    event.stopPropagation();
    event.preventDefault();
    
    this.loadContainer();
    this.loadBox();

    const clientX = (event.clientX || (event.touches && event.touches[0].clientX)) || 0;
    const clientY = (event.clientY || (event.touches && event.touches[0].clientY)) || 0;

    this._mouseClick = { x: clientX, y: clientY, left: this._boxPosition.left, top: this._boxPosition.top };

    if (handle) {
      this._status = DragResizeStatus.RESIZE;
      this.startResizing(handle);
    }
    else {
      this._status = DragResizeStatus.MOVE;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onMouseUp(event:MouseEvent | TouchEvent) {
    if (!this.dragResizeEnabled) return;
    
    if (this._resizingHandle) {
      this.stopResizing();
    }
    this.loadBox();
    this._overlay.remove();
    this._status = DragResizeStatus.OFF;    
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMouseMove(event: any){
    if (!this.dragResizeEnabled) return;
    
    const clientX = (event.clientX || (event.touches && event.touches[0].clientX)) || 0;
    const clientY = (event.clientY || (event.touches && event.touches[0].clientY)) || 0;

    this._mouse = { x: clientX, y: clientY };
    
    if(this._status === DragResizeStatus.RESIZE) this.doResize();
    else if(this._status === DragResizeStatus.MOVE) this.doMove();
  }

  private updateDirection() {
    this._direction = {
      s: !!this._resizingHandle.type.match(/s/),
      e: !!this._resizingHandle.type.match(/e/)
    };
  }

  private startResizing(handle: ResizeHandle) {
    this._overlay.add();
    this._resizingHandle = handle;
    this.updateDirection();
  }

  private stopResizing() {
    this._overlay.remove();
    this._resizingHandle = null;
  }

  private doResize() {

    const minWidth = this.portrait ? 198 : 352;
    const minHeight = this.portrait ? 352 : 198;
    const maxWidth = this.portrait ? 720 : 1280;
    const maxHeight = this.portrait ? 1280 : 720;

    this.loadBox();

    let width = Number(this._mouse.x > this._boxPosition.left) ? this._mouse.x - this._boxPosition.left : this._boxSize.width;
    let height = Number(this._mouse.y > this._boxPosition.top) ? this._mouse.y - this._boxPosition.top : this._boxSize.height;

    // constrain to min and max
    if (width < minWidth || width > maxWidth) {
      width = this._boxSize.width;      
    }
    if (height < minHeight || height > maxHeight) {
      height = this._boxSize.height;
    }

    // maintain aspect ratio
    if (this._direction.e && !this._direction.s) {
      height = Math.floor(width / this._aspectRatio);      
    } else {
      width = Math.floor(this._aspectRatio * height);
    }
    // constrain to container
    if ((height + this._boxPosition.top > this._containerPos.bottom) || (width + this._boxPosition.left > this._containerPos.right)) {
      height = this._boxSize.height;
      width = this._boxSize.width;
    }

    this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
    this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
  }  

  private doMove() {
    this._overlay.add();

    // work out where in the box im clicking relative to the screen
    const offsetLeft = this._mouseClick.x - this._boxPosition.left; 
    const offsetRight = this.el.nativeElement.offsetWidth - offsetLeft; 
    const offsetTop = this._mouseClick.y - this._boxPosition.top;
    const offsetBottom = this.el.nativeElement.offsetHeight - offsetTop;

    // constrain to the container
    const left = (
      (this._mouse.x > this._containerPos.left + offsetLeft) 
      && (this._mouse.x < this._containerPos.right - offsetRight)
    ) ? this._mouseClick.left + (this._mouse.x - this._mouseClick.x) : this.el.nativeElement.style.left;
    const top = (
      (this._mouse.y > this._containerPos.top + offsetTop)
        && (this._mouse.y < this._containerPos.bottom - offsetBottom)
     ) ? this._mouseClick.top + (this._mouse.y - this._mouseClick.y) : this.el.nativeElement.style.top;
  
    if (this.docked) {
      this.unDock();
    }        

    this.renderer.setStyle(this.el.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.el.nativeElement, 'top', `${top}px`);    
  }  

  private _dragResizeEnabled:boolean;
  @Input() set dragResizeEnabled(value: boolean) {
    this._dragResizeEnabled = value;

    if (this._dragResizeEnabled) {
      this._overlay = new Overlay(this.renderer);

      this.loadBox();
      this.loadContainer();
      this.updateAspectRatio();
      this.addUnDockableClass();
    }
    else {
      this.removeUnDockableClass();
    }
  }
  get dragResizeEnabled(): boolean {
    return this._dragResizeEnabled;
  }

  private _docked: boolean = true;
  @Input() set docked(value: boolean) {
    let originalValue = this._docked;
    this._docked = value;
    if (originalValue !== this._docked) { // if its changed
      if (this._docked) {
        this.dock();
      }
      else {
        this.unDock();
      }
    }    
  }
  get docked(): boolean {
    return this._docked;
  }

  private _unDocked:boolean = false;
  get unDocked(): boolean {
    return this._unDocked;
  }
  private dock() {
    if (this._unDocked) {
      this._unDocked = false;
      this.removeHandles();
      this.el.nativeElement.classList.remove('undocked');
      this.renderer.removeStyle(this.el.nativeElement, 'position');
      this.renderer.removeStyle(this.el.nativeElement, 'width');
      this.renderer.removeStyle(this.el.nativeElement, 'height');
      this.renderer.removeStyle(this.el.nativeElement, 'left');
      this.renderer.removeStyle(this.el.nativeElement, 'top');
      this.ondockchange.emit(false);
    }
  }

  public unDock() {
    if (!this.dragResizeEnabled) return;
    if (!this._unDocked) {
      this._unDocked = true;
      this.el.nativeElement.classList.add('undocked');
      this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.el.nativeElement, 'width', this.portrait ? '360px' : '640px');
      this.renderer.setStyle(this.el.nativeElement, 'height', this.portrait ? '640px' : '360px');
      this.ondockchange.emit(true);
      this.createHandles();
      this.loadBox();
      this.updateAspectRatio();
    }    
  }

  private _portrait: boolean;
  @Input() set portrait(value: boolean) {
    let originalValue = this._portrait;
    this._portrait = value;
    if (originalValue !== this._portrait) { // if its changed
      if (this._unDocked) {
        // swap the dimensions
        this.renderer.setStyle(this.el.nativeElement, 'width', `${this._boxSize.height}px`);
        this.renderer.setStyle(this.el.nativeElement, 'height', `${this._boxSize.width}px`);

        this.loadBox();
        this.updateAspectRatio();
        this.keepInBounds();
      }
    }    
  }
  get portrait(): boolean {
    return this._portrait;
  }

  private keepInBounds() {

    this.loadBox();

    if (this._boxSize.height + this._boxPosition.top > this._containerPos.bottom) {
      const top = (this._containerPos.bottom - this._boxSize.height) >= 0 ? this._containerPos.bottom - this._boxSize.height : 0;      
      this.renderer.setStyle(this.el.nativeElement, 'top', `${top}px`);
    } 
    
    if (this._boxSize.width + this._boxPosition.left > this._containerPos.right) {
      const left = (this._containerPos.right - this._boxSize.width) >= 0 ? this._containerPos.right - this._boxSize.width : 0;
      this.renderer.setStyle(this.el.nativeElement, 'left', `${left}px`);
    }

    // if the width is too wide for the screen
    // make it the same as the container
    if (this._boxSize.width > this._containerPos.right) {
      const width = this._containerPos.right;
      const height = Math.floor(width / this._aspectRatio);
      this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
      this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
    }

    if (this._boxSize.height > this._containerPos.bottom) {
      const height = this._containerPos.bottom;
      const width = Math.floor(this._aspectRatio * height);
      this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
      this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
    }    

  }
  

}
