<ng-container class="h-100 transfer-panel"
  [ngSwitch]="currentState.inviteState">

  <app-engagement-transfer-select
    *ngSwitchCase="CallInviteState.InviteSelecting"
    [transfer]="currentState.type === EngagementState.Transfer"
    [crossSessionTransfer]="crossSessionTransfer"
    [roomAgents]="roomAgents"
    (cancelInvite)="cancelInvite.emit()"
    (inviteRequest)="inviteRequest.emit($event)">
  </app-engagement-transfer-select>

  <app-engagement-transfer-waiting
    *ngSwitchCase="CallInviteState.InviteWaiting"
    [currentEngagementStateText]="currentEngagementStateText"
    [inviteState]="currentState"
    [roomId]="roomId"
    [textMessages]="textMessages"
    (closeInviteRequest)="cancelInvite.emit()">
  </app-engagement-transfer-waiting>

  <app-engagement-transfer-rejected
    *ngSwitchCase="CallInviteState.InviteRejected"
    [currentEngagementStateText]="currentEngagementStateText"
    [inviteState]="currentState"
    (chooseAnotherAgent)="cancelInvite.emit()">
  </app-engagement-transfer-rejected>
</ng-container>
