import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { CheckListField } from '../../../classes/checklist/CheckListField';
import { ElectronService } from '../../../services/electron-service/electron.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-crm-field-localcommand',
  templateUrl: './crm-field-localcommand.component.html',
  styleUrls: ['./crm-field-localcommand.component.scss']
})
export class CrmFieldLocalcommandComponent implements OnInit {

  private commandName: string = "";
  private _field: CrmField;

  @Input() set field(v: CrmField) {
    this._field = v;
    this.getData();
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<void> = new EventEmitter();


  constructor(private electronService: ElectronService) {}

  ngOnInit() {
    this.electronService.localVariable$
      .pipe(filter(response => response.name === this.commandName && response.value !== ""), first())
      .subscribe(response => {
        this.field.value = response.value;
        this.saveField.emit();
      });
  }

  getData() {
    this.commandName = this.field.value;
    this.electronService.getLocalVariable(this.commandName);
  }
}
