import Constants from "./Constants";
import DeviceManager from "./DeviceManager";
import UIManager from "./UIManager";
import EventEmitter from "./EventEmitter";

export default class Call extends EventEmitter {
    constructor({ 
            peerId ='itsthetaste',
            peerType = Constants.PeerType.Agent,
            mainPeerId = 'itsthetaste',
            speakerVolume = 1.0,
            mainVideoContainer = null,
            connectingOverlay = null,
            reConnectingOverlay = null,
            onHoldOverlay = null,
            peersContainer = null
        } = {}) {        

        super();
        
        this.localVideoTrack    = null;                                // MediaStreamTrack
        this.localAudioTrack    = null;                                // MediaStreamTrack
        this.localStream        = new MediaStream();                   // MediaStream

        this.devices            = new DeviceManager(peerType);
        this.ui                 = new UIManager(peerId, peerType, mainPeerId, speakerVolume, mainVideoContainer, connectingOverlay, reConnectingOverlay, onHoldOverlay, peersContainer);

        // TODO. look at removing this as its also in the CallP2P
        this.handleUserMediaError = e => {
            console.log("getUserMedia error: " + e.name + " message: " + e.message);
            this.emit("Call:handleGetUserMediaError", e);
        };       

        console.log("Created Call for:" + peerId + " of Type:" + peerType);
    }      

    initialise({
        cameraName='default', 
        cameraWidth=352, 
        cameraHeight=198, 
        microphoneName='default', 
        speakerName='default', 
        backgroundConfig=null,
        postProcessingConfig=null
    } = {}) {
        this.ui.initialise();
        return this.devices.initialise(cameraName, cameraWidth, cameraHeight, microphoneName, speakerName, backgroundConfig, postProcessingConfig)
        .then(() => {
            console.log("DeviceManager Initialised, Cameras:" + this.devices.cameras.length + " Microphones:" + this.devices.microphones.length + " Speakers " + this.devices.speakers.length);        
        })
        .catch(err => {
            console.log("DeviceManager Initialised Failed, Err:" + err);
        })
    }

    dispose() {
        console.log("Dispose of the Call Manager");
        // this is a one time connection killer
        // probably wont be able to reuse this callManager afterwards because of the negotiation conflict

        if (this.localVideoTrack) {
            this.localVideoTrack.stop();
            this.localVideoTrack = null;
        }
        if (this.localAudioTrack) {
            this.localAudioTrack.stop();
            this.localAudioTrack = null;
        }

        this.localStream = null;

        this.devices.dispose();
        this.devices = null;
        
        this.ui.dispose();
        this.ui = null;        
    }

    addPeer(peerId) {
        //console.log('*************** addPeer');
        this.ui.addPeer(peerId);
    }

    removePeer(peerId) {
        this.ui.removePeer(peerId);
    }

    canSeeMainPeer() {
        var result = false;
        if (!this.ui) return;
        var consumer = this.ui.getMainPeersVideoConsumer();
        result = consumer != null && consumer.srcObject != null && consumer.srcObject.getVideoTracks().some(function(track) {
            return track.enabled && track.readyState === 'live';
        });
        return result;
    }

    canHearMainPeer() {
        var result = false;
        if (!this.ui) return;
        var consumer = this.ui.getMainPeersAudioConsumer();
        if (consumer != null) {
            if (consumer.audioObj != null) {
                result = consumer.audioObj.volume > 0 
                    && consumer.audioObj.srcObject != null
                    && consumer.audioObj.srcObject.getAudioTracks().some(function(track) {
                        return track.enabled && track.readyState === 'live';
                    });
            }
        }

        return result;
    }

    setPrimaryVisitor(peerId) {
        this.ui.primaryVisitorPeerId = peerId;
    }

    updateBackgroundConfig(backgroundConfig) {
        this.devices.updateBackgroundConfig(backgroundConfig);
    }

    updatePostProcessing(postProcessingConfig) {
        this.devices.updatePostProcessing(postProcessingConfig);
    }

}