import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-message-template-viewer',
  templateUrl: './message-template-viewer.component.html',
  styleUrls: ['./message-template-viewer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class MessageTemplateViewerComponent {

  htmlContent: string = "";
  @Output() contentChanged = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {}

  setContent(v) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(v) as string;
  }

  onContentChanged($event) {
    this.contentChanged.emit($event);
  }

}

