/* tslint:disable:variable-name */

import {Group} from '../../classes/visitor/Group';
import {HubJsonTextMessage} from '../engagementhub.service';
import {Channels} from './Channel';

export class HubVisitor {
  UserGuid: string;
  SessionGuid: string;

  Site: string;
  flag: number; // dont change this case, needs changing in visitorR first
  SecondsOnPage: number;
  SecondsOnSite: number;

  TimeSinceLastNudge: number;
  LastPage: string;
  LastSection: string;

  LastSession: string;
  TimeSinceLastEngagement: number;

  LockedBy: string; // todo: remove this !!!

  AssistTime: number;

  Culture: string;
  PreviousOperator: string;

  Browser: string;
  CountryCode: string;
  Location: string;
  HasGetUserMedia: number;
  HasCrmData: boolean;

  AgentRequestingAssistance: boolean;
  Transcript: HubJsonTextMessage[];
  AgentGroup: Group[];
  TargettedSection: string;

  TimeStamp: string;

  CurrentOperator: string;
  CurrentUsername: string;
  EngagementGuid: string;
  CallDuration: number;

  TimeSinceLastHeartbeat: number;

  LastResponseTime: string;

  IsAsync: boolean = false;
  IsMaybeLater: boolean;

  ChannelType: Channels;
  BeingTransferred: boolean;
}

// Keeping the static methods away from the data
export class HubVisitorUtils {
  public static CreateKey(hubVisitor: HubVisitor) {
    return `${hubVisitor.UserGuid}+${hubVisitor.SessionGuid}`;
  }
}
