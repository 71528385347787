<app-navbar (endBreak)="onEndBreak()"></app-navbar>

<div class="container-wrapper">
  <div class="d-flex flex-row h-100" [ngSwitch]="(currentState$ | async)" *ngIf="!(noInternet | async)">

      <app-onbreak *ngSwitchCase="OnlineState.OnBreak"
                   (endBreak)="onEndBreak()"
                   class="w-100">
      </app-onbreak>

      <ng-container *ngSwitchCase="OnlineState.Supervisor">
        <div class="d-flex flex-column sidebar-panel flex-grow-1 px-0 mr-2">
          <app-supervisor-chat-list class="w-100 h-100"
                                    [supervisorVisitors]="getVisitorsArray()"
                                    [supervisorEngagements]="getEngagementsArray()"
                                    [helpRequestEngagements]="helpRequestEngagements"
                                    [(supervisingEngagement)]="supervisingEngagement"
                                    [supervisingGroups]="supervisingGroups"
                                    [privateChatAvailable]="privateChatAvailable"
                                    (startMonitoring)="startSnooping($event)"
                                    (joinPrivateChat)="helpAgent($event)"
          ></app-supervisor-chat-list>
        </div>

        <div class="d-flex flex-column flex-grow-1 overflow-hidden main-layout">
          <ng-container [ngSwitch]="supervisingEngagement.type">
            <div class="h-100 " *ngSwitchCase="SupervisingEngagementTypes.Snooping">
              <app-supervisor-snooping (joinPrivateChat)="joinEngagement(supervisingEngagement.visitor, true)"
                                      (joinPublicChat)="joinEngagement(supervisingEngagement.visitor, false)"
                                      (stopSnooping)="stopSnooping(supervisingEngagement)"
                                      [visitor]="supervisingEngagement.visitor"
                                      [privateChatAvailable]="privateChatAvailable">
              </app-supervisor-snooping>
            </div>

            <div class="h-100 " *ngSwitchCase="SupervisingEngagementTypes.Supervising">
              <app-supervisor-engagement [engagementId]="supervisingEngagement?.engagementId"
                                        (engagementEnded)="engagementEnded(supervisingEngagement?.engagement)"
                                        [privateChatAvailable]="privateChatAvailable">
              </app-supervisor-engagement>
            </div>

            <div class="h-100 " *ngSwitchCase="SupervisingEngagementTypes.SnoopingOnAsyncChat">
              <app-supervisor-async-chat [conversation]="supervisingEngagement.conversation"
                                         (stopSnooping)="stopSnooping(supervisingEngagement)">
              </app-supervisor-async-chat>
            </div>

            <div class="h-100 d-flex flex-column " *ngSwitchCase="SupervisingEngagementTypes.None">
              <h2 class="primary-color-text pt-2 pl-2">{{ "SUPERVISOR_HEADER_DASHBOARD" | Translate : "Dashboard" }}</h2>
              <p-tabMenu styleClass="justify-end" [model]="productModes" [activeItem]="activeItem"></p-tabMenu>
                    <p-toolbar styleClass="mb-2">
                      <div class="p-toolbar-group-start d-flex justify-content-center">
                        <p-multiSelect styleClass="w-100" [placeholder]="'SUPERVISOR_MULTI_SELECT_PLACEHOLDER' | Translate : 'Groups'" (onChange)="onChange($event)" [options]="currentAgentGroups | keyvalue" display="chip" optionLabel="value" optionValue="key"></p-multiSelect>
                      </div>
                      <div class="p-toolbar-group-end p-buttonset">
                        <p-button [severity]="cardMode ? 'primary' : 'secondary'"  (onClick)="showCardView()">
                            <i class="pi pi-list"></i>
                        </p-button>
                        <p-button  [severity]="!cardMode ? 'primary' : 'secondary'" (onClick)="showTableView()">
                            <i class="pi pi-table"></i>
                        </p-button>
                      </div>
                    </p-toolbar>

              <div *ngIf="!cardMode; else cardModeView" class="overflow-y-auto p-4 ">
                <app-supervisor-table-view [supervisingVisitors]="(snoopingChats | async)"
                                            [supervisingEngagements]="(supervisingEngagements | async)"
                                            [selectedVisitors]="(selectedVisitors$ | async)"
                                            [selectedAgents]="(selectedAgents$ | async)"
                                            [privateChatAvailable]="privateChatAvailable"
                                            (enterSession)="enterSession($event)"
                                            (startSnooping)="startSnooping($event)">
                </app-supervisor-table-view>
              </div>
              <ng-template #cardModeView>
                <div class="overflow-y-hidden p-4 ">
                  <app-supervisor-dashboard-view [supervisingVisitors]="(snoopingChats | async)"
                                                [supervisingEngagements]="(supervisingEngagements | async)"
                                                [selectedVisitors]="(selectedVisitors$ | async)"
                                                [selectedAgents]="(selectedAgents$ | async)"
                                                [notEngagedVisitors]="(notEngagedVisitors$ | async)"
                                                [selectedGroups]="agentGroups"
                                                [mode]="activeItem.label"
                                                [kpiData]="kpiSet"
                                                [dashboardData]="(dashboardData$ | async)"
                                                [privateChatAvailable]="privateChatAvailable"
                                                (enterSession)="enterSession($event)"
                                                (startSnooping)="startSnooping($event)">
                  </app-supervisor-dashboard-view>
                </div>
              </ng-template>
            </div>
          </ng-container>


        </div>
      </ng-container>

      <app-crm-opportunity class="main-layout" *ngSwitchCase="OnlineState.CrmOpportunity"></app-crm-opportunity>

      <app-appointment-search class="main-layout" *ngSwitchCase="OnlineState.Appointment"></app-appointment-search>
  </div>
  <app-no-internet id="no-internet-container" *ngIf="(noInternet | async)" class="d-flex flex-row h-100 p-2"></app-no-internet>
</div>

<app-supervisor-controls class="fixed-bottom w-50"
                         [dashboardIsVisible]="supervisingEngagement.type === SupervisingEngagementTypes.None"
                         (endBreak)="onEndBreak()"
                         (goToDashboard)="showDashboard()">
</app-supervisor-controls>
