<div class="toolbox" *ngIf="isAvailable">
    <p-overlayPanel styleClass="p-1" #options>
      <div *ngIf="getSuggestionAvailable" class="d-flex flex-row no-wrap align-items-center justify-content-between gap-4">
         <p>{{ "ENGAGEMENTTEXTCHAT_TOOLTIP_GETSUGGESTION" | Translate : "Get Suggestion" }}</p>
        <p-inputSwitch [ngModel]="getSuggestionOn" (ngModelChange)="toggleGetSuggestion()" />
      </div>
      <div *ngIf="autoReplyAvailable" class="d-flex flex-row no-wrap align-items-center justify-content-between gap-4">
        <p>{{ "ENGAGEMENTTEXTCHAT_TOOLTIP_AUTOREPLY" | Translate : "Auto-reply" }}</p>
        <p-inputSwitch [ngModel]="autoReplyOn" (ngModelChange)="toggleAutoReply()" />
      </div>
      <p-divider *ngIf="getSuggestionAvailable || autoReplyAvailable"></p-divider>
      <div *ngIf="translationAvailable" class="d-flex flex-row no-wrap align-items-center justify-content-between gap-4">
        <p>{{ "ENGAGEMENTTEXTCHAT_TOOLTIP_TRANSLATION" | Translate : "Translation" }}</p>
        <p-inputSwitch [ngModel]="translationOn" (ngModelChange)="toggleTranslation()"  />
      </div>
  </p-overlayPanel>
    <p-button [plain]="true" [text]="true" (onClick)="options.toggle($event)">
      <fa-icon [icon]="faEllipsisVertical"></fa-icon>
    </p-button>
</div>

