import {EngagementService} from '../engagement.service';
import {WebrtRTCMessagingService} from '../webrtc-messaging-service/webrtcmessaging.service';
import {ElectronService} from '../electron-service/electron.service';
import {WebRTCSharingService} from '../webrtc-sharing-service/webrtc-sharing.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {SharingMessage} from './SharingMessage';
import {LoggingService} from '../logging.service';

export class SharingConfig {
  shareWebpage: boolean;
  shareApplication: boolean;
  shareDesktop: boolean;
  isElectron: boolean;
  shareWebpageFullscreen: boolean;
  cropTarget: boolean;
}

export class SharingControllerService {

  public isWebRTCSharing: boolean = false;

  public get isSharingViewOnly(): boolean {
    if (this.webrtcSharingService) {
      return this.webrtcSharingService.getViewOnly();
    } else {
      return false;
    }
  }

  private stateSub: Subscription;

  private sharingStateChange$: Observable<SharingMessage>;
  private webrtcSharingService: WebRTCSharingService;


  private typesFilter: string[] = [];

  constructor(
    private readonly engagementService: EngagementService,
    private readonly webrtcMessagingService: WebrtRTCMessagingService,
    private readonly logging: LoggingService,
    private readonly electronService: ElectronService) {
  }


  public setup(engagementId: string, config: SharingConfig): Observable<SharingMessage> {

    this.setupTypesFilter(config);

    if (config.isElectron) {
      this.webrtcSharingService = new WebRTCSharingService(this.engagementService, this.webrtcMessagingService, this.logging, this.electronService);
      this.webrtcSharingService.init(engagementId);

      this.sharingStateChange$ = this.webrtcSharingService.sharingStateChange$.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    } else {
      this.webrtcSharingService = new WebRTCSharingService(this.engagementService, this.webrtcMessagingService, this.logging);
      this.webrtcSharingService.init(engagementId);

      this.sharingStateChange$ = this.webrtcSharingService.sharingStateChange$.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    }

    this.stateSub = this.sharingStateChange$.subscribe(message => {
      this.isWebRTCSharing = message.on;
    })

    return this.sharingStateChange$;
  }

  private setupTypesFilter(config: SharingConfig) {
    if (config.isElectron) {
      if (config.shareWebpageFullscreen) {
        this.typesFilter.push("webpage-fullscreen");
      } else if (config.shareWebpage) {
        this.typesFilter.push("webpage");
      }

      if (config.shareApplication) {
        this.typesFilter.push("window");
      }

      if (config.shareDesktop) {
        this.typesFilter.push("screen");
      }
    } else {
      // pointless passing custom in since browser vendors do not allow us to filter what gets displayed in the display sources selection
      this.typesFilter = ["application", "window"];

      if (config.cropTarget) {
        this.typesFilter.push("CropTarget");
      }
    }
  }


  public switchSharingOn(val: boolean, viewOnly: boolean) {
    this.switchWebRTCSharingOn(val, this.typesFilter, viewOnly);
  }

  private switchWebRTCSharingOn(val: boolean, typesFilter, viewOnly: boolean) {
      if (val && this.isWebRTCSharing && (this.webrtcSharingService.getViewOnly() != viewOnly)) {
        this.webrtcSharingService.setViewOnly(viewOnly);
      } else {
        this.webrtcSharingService.switchSharingOn(val, typesFilter, viewOnly);
      }
  }

  public pauseSharing(val: boolean) {
    this.webrtcSharingService.pauseSharing(val);
  }

  public removeSharing() {
    this.webrtcSharingService.dispose();
  }

  public shareAsset(val: boolean) {
    const viewOnly = true;
    if (!this.isWebRTCSharing) {
      this.switchSharingOn(val, viewOnly);
    }

  }


  public dispose() {

    if (this.stateSub) {
      this.stateSub.unsubscribe();
    }
    this.stateSub = null;
    this.isWebRTCSharing = false;
    this.removeSharing();
    this.webrtcSharingService = null;
  }

}
