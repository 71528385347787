import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { EngagementService } from '../../../services/engagement.service';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import { ConferenceType } from '../../../classes/conference';
import { LoggingService } from '../../../services/logging.service';
import { LicenceType } from '../../../enums/licence-type.enum';
import {FeedService} from "../../../services/feed-service/feed.service";

@Component({
  selector: 'app-mobile-presenter-waiting',
  templateUrl: './mobile-presenter-waiting.component.html',
  styleUrls: ['./mobile-presenter-waiting.component.scss']
})
export class MobilePresenterWaitingComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private feedService: FeedService,
    private engagementService: EngagementService,
    private onlineService: OnlineService,
    private loggingService: LoggingService
    ) { }

  ngOnInit() {
    this.onlineService.setCurrentState(OnlineState.MobilePresenterWaiting);

    const timerSub = timer(2000, 1000).subscribe(() => {
      this.feedService.checkFeedLocation().subscribe(
        conferenceExists => {
            if (conferenceExists) {
              if (this.feedService.conference.Type === ConferenceType.Engagement) {
                this.engagementService.createMobilePresenterEngagement(this.feedService.conference.Id, LicenceType.VeeStudio);
                this.router.navigateByUrl(`/presenter/engagement/${this.feedService.conference.Id}`);
              }
            }
        }
      );
    });
    this.subscriptions.push(timerSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
