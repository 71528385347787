  <p-table [value]="sessionHistory" dataKey="EngagementGuid" class="p-datatable-gridlines"   [paginator]="true"
           [rows]="rows" >
    <ng-template let-columns pTemplate="header">
      <tr>
        <th></th>
        <th>{{ "SESSIONHISTORY_HEADER_EVENTDATE" | Translate : 'Event Date' }}</th>
        <th>{{ "SESSIONHISTORY_HEADER_TYPE" | Translate : 'Type' }}</th>
        <th>{{ "SESSIONHISTORY_HEADER_AGENT" | Translate : 'Agent' }}</th>
        <th>{{ "SESSIONHISTORY_HEADER_STATUS" | Translate : 'Status' }}</th>
        <th>{{ "SESSIONHISTORY_HEADER_SUBSTATUS" | Translate : 'Sub Status' }}</th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-event let-expanded="expanded" pTemplate="body">
      <tr (click)="loadDetails(event.EngagementGuid)" [pRowToggler]="event" class=" user-select-none" role="button">
        <td>
          <fa-icon [icon]="expanded ? faCaretDown : faCaretRight"></fa-icon>
        </td>
        <td>
          {{ event.StartTime | localDateTime }}
        </td>
        <td>{{ event.EngagementType }}</td>
        <td>{{ event.Operator }}</td>
        <td>{{ event.Status }}</td>
        <td>{{ event.LOI }}</td>
      </tr>
    </ng-template>
    <ng-template let-event pTemplate="rowexpansion">
      <tr class="expanded-row">
        <td [attr.colspan]="6">
          <div class="row py-2">
            <div class="col-4">
              <div class="d-flex flex-column">
                <strong>{{ "SESSIONHISTORY_HEADER_SESSIONNOTES" | Translate : 'Session Notes' }}</strong>

                <div class="mt-4 multiline-text-container">
                  <ng-container
                    *ngIf="isNullOrEmpty(event.OperatorNotes); then noNotes else notes">

                  </ng-container>
                  <ng-template #notes>
                    {{ event.OperatorNotes }}
                  </ng-template>
                  <ng-template #noNotes>
                    <i>{{ "SESSIONHISTORY_HEADER_NOSESSIONNOTES" | Translate : 'No Session Notes' }} </i>
                  </ng-template>

                </div>
              </div>
            </div>

            <div class="col-8">
              <div class="d-flex flex-column ">
                <strong>{{ "ENGAGEMENTPOST_HEADER_TRANSCRIPT" | Translate : 'Transcript' }}</strong>
                <div class="mt-4 multiline-text-container">
                  <ng-container
                    *ngIf="isNullOrEmpty(engagementTranscript.get(event.EngagementGuid)); then noTranscript else transcript"></ng-container>
                  <ng-template #transcript>
                    <span [innerHTML]="engagementTranscript.get(event.EngagementGuid)"></span>
                  </ng-template>
                  <ng-template #noTranscript>
                    <span>
                      <i>{{ "SESSIONHISTORY_HEADER_NOMESSAGE" | Translate : 'No Messages' }} </i>
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 py-2">
            <div class="col-12">
              <div class="d-flex flex-column">
                <strong>{{ "SESSIONHISTORY_HEADER_PURCHASES" | Translate : 'Purchases' }}</strong>
                <div class="mt-4">
                  <ng-container
                    *ngIf="isNullOrEmpty(engagementPurchases.get(event.EngagementGuid)); then noPurchases else purchases">

                  </ng-container>
                  <ng-template #purchases>
                    <p-table size="small" [value]="engagementPurchases.get(event.EngagementGuid)">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>{{ "SESSIONHISTORYPURCHASES_HEADER_PURCHASEDATE" | Translate : 'Purchase Date' }}</th>
                          <th>{{ "SESSIONHISTORYPURCHASES_HEADER_NUMBEROFITEMS" | Translate : 'Number of Items' }}</th>
                          <th>{{ "SESSIONHISTORYPURCHASES_HEADER_CURRENCY" | Translate : 'Currency' }}</th>
                          <th>{{ "SESSIONHISTORYPURCHASES_HEADER_COST" | Translate : 'Cost' }}</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-purchase>
                        <tr>
                          <td class="text-truncate">{{ this.getDate(purchase.timestamp) }}</td>
                          <td class="text-truncate">{{ purchase.numberOfItems }}</td>
                          <td class="text-truncate">{{ purchase.currency }}</td>
                          <td class="text-truncate">{{ purchase.cost }}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </ng-template>
                  <ng-template #noPurchases>
                    <div>
                      <i>{{ "SESSIONHISTORY_HEADER_NOPURCHASES" | Translate : 'No Purchases' }} </i>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>

