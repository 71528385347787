import {HubJsonTextMessage} from '../services/engagementhub.service';
import {TextMessage, TextMessageTypes} from '../classes/TextMessage';
import {HubTransferChatMessage} from '../services/visitor-service/HubTransferRejection';

export class TextMessageUtils {
  constructor() {
    throw new Error('Not for instantiation');
  }

  public static CreateTextMessages(hubTextMessage: HubJsonTextMessage[], username: string): TextMessage[] {
    const textMessages: TextMessage[] = [];
    if (null == hubTextMessage) {
      return textMessages;
    } else {
      return hubTextMessage.map((msg) => this.CreateTextMessage(msg, username));
    }
  }

  public static CreateTextMessage(hubTextMessage: HubJsonTextMessage, username: string): TextMessage {
    let senderType: TextMessageTypes;

    if (!hubTextMessage.SenderIsAgent) {
      senderType = TextMessageTypes.CUSTOMER;
    } else if (hubTextMessage.SenderId === username) {
      senderType = TextMessageTypes.ME;
    } else {
      senderType = TextMessageTypes.OTHER_AGENT;
    }

    // noinspection UnnecessaryLocalVariableJS
    const textMessage: TextMessage = {
      id: hubTextMessage.Id,
      senderName: hubTextMessage.SenderName,
      senderId: hubTextMessage.SenderId,
      senderType: senderType,
      message: hubTextMessage.Message,
      timestamp: new Date(hubTextMessage.TimeStamp),
      originalMessage: hubTextMessage.OriginalMessage,
      currentEngagement: true,
      verified: null
    };

    return textMessage;
  }

  public static CreateTransferTextMessage(hubTransferMessage: HubTransferChatMessage, username: string): TextMessage {
    let senderType: TextMessageTypes;

    if (hubTransferMessage.SourceAgentUsername === username) {
      senderType = TextMessageTypes.ME;
    } else {
      senderType = TextMessageTypes.OTHER_AGENT;
    }

    // noinspection UnnecessaryLocalVariableJS
    const textMessage: TextMessage = {
      id: 0,
      senderName: hubTransferMessage.AgentNickname,
      senderId: hubTransferMessage.SourceAgentUsername,
      senderType: senderType,
      message: hubTransferMessage.Message,
      timestamp: new Date(),
      originalMessage: '',
      currentEngagement: true,
      verified: null
    };

    return textMessage;
  }
}
