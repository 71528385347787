export enum VisitorStatus {
  Engaged,
  Joining,
  Assist,
  Callback,
  Waiting,
  MaybeLater,
  Ended,
  Blocking,
  Timeout,
  WhatsApp,
  Sms,
  Messenger,
  AgentRequestingHelp,
  Transferring,
  Transferred,
  IdleTransfer,
}
