import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InvitationRequest } from '../../../classes/transfer/invitationRequest';
import { SiteVisitor } from '../../../services/visitor-service/SiteVisitor';
import {OnlineService} from '../../../services/online.service';

@Component({
  selector: 'app-call-list-table',
  templateUrl: './call-list-table.component.html',
  styleUrls: ['./call-list-table.component.scss']
})
export class CallListTableComponent implements OnInit {

  @Input() selectedSessionGuid: string = null;
  @Input() visitors: SiteVisitor[] = null;
  @Input() transfers: InvitationRequest[] = null;
  @Input() invites: InvitationRequest[] = null;


  @Output() acceptCall = new EventEmitter<SiteVisitor>();
  @Output() selectTransferInvite = new EventEmitter<InvitationRequest>();
  @Output() selectJoinInvite = new EventEmitter<InvitationRequest>();
  @Output() selectCall = new EventEmitter<SiteVisitor>();


  constructor() { }

  ngOnInit() {
  }

  accept(visitor) {
    this.acceptCall.emit(visitor);
  }

  selectVisitor(visitor) {
    if (!visitor.isInactive) {
      this.selectCall.emit(visitor);
    }
  }

  showTransferAccept(transfer) {
    this.selectTransferInvite.emit(transfer);
  }

  showJoinAccept(invite) {
    this.selectJoinInvite.emit(invite);
  }

}
