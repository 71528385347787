import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { CustomerSizes } from '../../../services/engagement';
import { debounceTime } from 'rxjs/operators';
import { SettingsService } from '../../../services/settings-service/settings.service';
import { PanelPositionMethod } from "../../../services/electron-service/panel-position-method";
import { BrowserConfiguration } from "../../../services/browser-service/browser-configuration";
import Guid from '../../../classes/Guid';
import { BrowserService } from '../../../services/browser-service/browser.service';
import { IBrowser } from '../../../services/browser-service/IBrowser';
import { BrowserTypes } from '../../../services/browser-service/browser-types';
import {LoggingService} from '../../../services/logging.service';

@Component({
  selector: 'app-engagement-electron-domsync-coviewer',
  templateUrl: './electron-domsync-coviewer.component.html',
  styleUrls: ['./electron-domsync-coviewer.component.scss']
})
export class EngagementElectronDomsyncCoviewerComponent implements OnDestroy, AfterViewInit {

  @ViewChild('browserContainer', {static: true}) browserContainer: ElementRef;

  private container: any;
  private sub: Subscription;
  private subs: Subscription[] = [];
  private resize$: Subject<any> = new Subject<any>();
  private currentCustomerSize:CustomerSizes;

  public browserProxy: IBrowser = null;
  public hasDimensions: boolean = false;

  @Input() emulateDevice: boolean;
  @Input() deviceScaleFactor: number;

  private _useOverlayPanel: boolean = true;
  @Input() public set useOverlayPanel(useOverlayPanel: boolean) {
    this._useOverlayPanel = useOverlayPanel;
  }

  public get useOverlayPanel(): boolean {
    return this._useOverlayPanel;
  }

  @Input() currentPanelPosition: Observable<CustomerSizes>;
  @Input() url: string;

  private _showBrowser: boolean = false;
  @Input() public set showBrowser(val: boolean) {

    this._showBrowser = val;

    if (this.browserProxy) {

      if (this._showBrowser) {

        this.browserProxy.show();
        setTimeout(() => this.handleBrowserAndPanelDimensions(), 0);
      }
      else {
        this.browserProxy.hide();
      }
    }
  }

  public get showBrowser(): boolean {
    return this._showBrowser;
  }

  @Input() set fromCustomerCommand(obs: Observable<string>) {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
    if (obs) {
      this.sub = obs.subscribe(n => this.handleCustomerCommand(n));
    }
  }

  private handleCustomerCommand(msg: string) {

    if (!this.browserProxy) {
      return;
    }

    if (msg === 'restart') {
      this.browserProxy.reload();
    }
    else {
      this.browserProxy.postMessage(msg, '*');
    }

  }

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDomSyncCommandMessage = new EventEmitter<string>();

  private config:BrowserConfiguration = new BrowserConfiguration();

  constructor(
    private readonly browserService: BrowserService,
    private readonly settingService: SettingsService,
    private readonly logger: LoggingService
  ) {
  }

  ngAfterViewInit() {
    this.addDomSync();
  }

  addDomSync() {

    this.container = this.browserContainer.nativeElement.parentElement;

    if (!this.container || !this.browserContainer) {
      return;
    }

    if (!this.browserProxy) {
      this.browserProxy = this.browserService.createDomSyncBrowser();
    }

    if (!this.browserProxy) {
      return;
    }

    const s = this.browserProxy.onBrowserCreated.subscribe(() => {
      s.unsubscribe();
      this.onBrowserCreated();
    });

    const overlayPanelPositionMethod = this.settingService.getResourceOrDefault('ELECTRON_USERPANEL_POSITION_METHOD', PanelPositionMethod.OffsetFromCenter) as PanelPositionMethod;

    this.config.guid = new Guid().toString();
    this.config.title = "domsync-window";
    this.config.type = BrowserTypes.domsync;
    this.config.maxWidth = this.container.clientWidth;
    this.config.maxHeight = this.container.clientHeight;
    this.config.deviceScaleFactor = window.devicePixelRatio;
    this.config.emulateDevice = true;
    this.config.useTouchEmulation = false;
    this.config.showPanelOverlay = this.useOverlayPanel;
    this.config.overlayPanelPositionMethod = overlayPanelPositionMethod;
    this.config.enableCustomerResize = true;

    this.config.initialUrl = this.url;

    setTimeout(() => this.browserProxy.create(this.config) , 1000);
  }

  private onBrowserCreated() {
    // Take the first page load as the trigger for starting domsync at the customers end.
    // The domsync library internally takes care of restarts and reloads of itself.
    // This is just to trigger the customer loading the domsync javascript and
    // starting the messaging frame.
    let created = 0;
    this.browserProxy.currentPage.subscribe(data => {
      if (this.url === data) {
        if (created++ > 0) {
          this.logger.debug("Multiple dom sync browser init events fired.");
        } else {
          this.onReady.emit(true);
        }
      }
    });

    this.browserProxy.onMessage.subscribe(msg => this.onDomSyncCommandMessage.emit(msg));
    this.browserProxy.onResize.subscribe(() => this.resize());

    this.subs.push(
      this.currentPanelPosition.subscribe(v => {

        this.currentCustomerSize = v;

        if (!this.showBrowser) {
          return;
        }

        this.handleBrowserAndPanelDimensions();
      }));

      this.subs.push(this.resize$
        .pipe(debounceTime(100))
        .subscribe(bounds => this.browserProxy.sendBrowserDimensions(bounds)));

      this.browserProxy.changeUrl(this.url);
      this.resize();
  }

  private handleBrowserAndPanelDimensions() {

    if(!this.currentCustomerSize) {
      return;
    }

    this.handleBrowserDimensions(this.currentCustomerSize);
    this.handlePanelDimensions(this.currentCustomerSize.panelDimensions);

    if (this.browserProxy) {
      this.browserProxy.setClientDimensions(this.currentCustomerSize.browserWidth, this.currentCustomerSize.browserHeight);
    }
  }

  private handleBrowserDimensions(v: CustomerSizes) {

    const width = v.browserWidth > this.container.clientWidth ? this.container.clientWidth : v.browserWidth;
    const height = v.browserHeight > this.container.clientHeight ? this.container.clientHeight : v.browserHeight;

    this.hasDimensions = width > 0;
    this.updateBrowserContainer(width, height);

  }

  private updateBrowserContainer(width: number, height: number) {
    this.browserContainer.nativeElement.style.width = `${width}px`;
    this.browserContainer.nativeElement.style.height = `${height}px`;

    setTimeout(() => this.resize(), 0);
  }

  private handlePanelDimensions(v) {
    const split = v.split(',');
    if (split == null || split.length < 4) {
      return;
    }

    const currentPanelTop = parseInt(split[0], 10);
    const currentPanelLeft = parseInt(split[1], 10);
    const panelWidth = parseInt(split[2], 10);
    const panelHeight = parseInt(split[3], 10);

    const validDimensions = (isNaN(currentPanelTop) || isNaN(currentPanelLeft) || isNaN(panelWidth) || isNaN(panelHeight)) === false;
    if (validDimensions) {
      setTimeout(() => {
        if (this.browserProxy) {
          this.browserProxy.setPanelDimensions(currentPanelTop, currentPanelLeft, panelWidth, panelHeight);
        }
      }, 200);
    }
  }

  private resize() {
    const bounds = this.browserContainer.nativeElement.getBoundingClientRect();
    this.resize$.next(bounds);
  }

  ngOnDestroy() {
    this.removeDomSync();
  }

  removeDomSync() {
    if (this.browserProxy) {
      this.browserProxy.dispose();
    }

    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }

    if (this.subs) {
      this.subs.forEach(s => s.unsubscribe());
      this.subs = [];
    }

  }

}
