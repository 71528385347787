import { CrmStructure } from '../services/crm-service/crm-category';
import { CrmEmailStateType, CrmEmailStateValid, CrmEmailStateEmpty, CrmEmailStateDuplicate, CrmEmailStateIncorrect, CrmEmailState } from '../services/crm-service/crm-email-state';

export class CrmEmail {
  public static readonly EMAIL_STATE_VALID: CrmEmailStateValid = {type: CrmEmailState.Valid};
  public static readonly EMAIL_STATE_EMPTY: CrmEmailStateEmpty = {type: CrmEmailState.Empty};
  public static readonly EMAIL_STATE_DUPLICATE: CrmEmailStateDuplicate = {type: CrmEmailState.Duplicate};
  public static readonly EMAIL_STATE_INCORRECT: CrmEmailStateIncorrect = {type: CrmEmailState.Incorrect};

  public static isValidEmail(data: CrmStructure): CrmEmailStateType {
    for (const category of data.list) {
      if (category.name === 'Customer Information') {
        for (const field of category.fields) {
          if (field.name === 'Email') {
            if (!field.value || field.value.length === 0) {
              return CrmEmail.EMAIL_STATE_EMPTY;
            } else if (!CrmEmail.isEmailAddressValid(field.value)) {
              return CrmEmail.EMAIL_STATE_INCORRECT;
             } else {
               return CrmEmail.EMAIL_STATE_VALID;
             }
          }
        }
      }
    }

    return CrmEmail.EMAIL_STATE_EMPTY;
  }

  public static isEmailAddressValid(address: string): boolean {
    const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(address);
  }
}

