<div class="d-flex flex-row">
    <textarea pInputTextarea
      class="flex-grow-1 w-100"
      style="resize: none;" rows="3"
      [(ngModel)]="message"
      (keydown)="onKeyDown($event)"
    ></textarea>
  <p-button
    styleClass="btn btn-sendMessage"
    [disabled]="message.length == 0"
    (onClick)="sendTextMessage()"
  >
      <fa-icon [icon]="faChevronLeft"></fa-icon>
  </p-button>
</div>
