export class DashboardData {
  veeStudioOperators: number;
  veeStudioOperatorsAvailable: number;
  veeStudioOperatorsBusy: number;
  veeStudioRequest: number;
  veeStudioLongestWaitTime: number;

  veeChatOperators: number;
  veeChatSessionsPossible: number;
  veeChatEngaged: number;
  veeChatRequest: number;
  veeChatLongestWaitTime: number;
  veeChatAdditionalCapacity: number;

  operatorsOnBreak: number;
  referenceDateTime: Date;

  conversationAssigned: number;
  conversationUnassigned: number;
  conversationsUpdatedByCustomer: number;
}
