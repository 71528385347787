import {Injectable, OnDestroy} from '@angular/core';
import {AgentServerInfo} from '../../classes/visitor/AgentServerInfo';
import {InvitationRequest} from '../../classes/transfer/invitationRequest';
import {EngagementService} from '../engagement.service';
import {Connection, hubConnection, Options, Proxy} from '../../../third-party/signalr-no-jquery';
import {AgentStatus} from '../../classes/visitor/AgentStatus';
import {TurnBackendService} from '../turn.backend.service';
import {HubInviteCancelled} from './HubInviteCancelled';
import {AuthService} from '../auth-service/auth.service';
import {VisitorToAgentMessage} from './VisitorToAgentMessage';
import {OnlineService} from '../online.service';
import {BehaviorSubject, Observable, of, Subscriber, Subscription, throwError, timer} from 'rxjs';
import {AgentGroupVisitors} from './AgentGroupVisitors';
import {EngagementState} from '../engagement';
import {MessageResponse} from '../../classes/messageResponse';
import {HubTransferCancellation} from './hubTransferCancellation';
import {AgentServerStatus} from '../../classes/visitor/AgentServerStatus';
import {VisitorDetails} from '../../classes/visitor-details';
import {OnlineState} from '../../enums/online-state.enum';
import {catchError, concatMap, filter, finalize, first, map, take, tap, timeout} from 'rxjs/operators';
import {SiteVisitor} from './SiteVisitor';
import {Agent} from '../../classes/agent';
import {environment} from '../../../environments/environment';
import {HubVisitor, HubVisitorUtils} from './HubVisitor';
import {HubInvitationRequest} from './HubInvitationRequest';
import {StatusFlag} from '../../enums/status-flag.enum';
import {HubInviteToJoin} from './HubInviteToJoin';
import {CallType} from '../../enums/call-type.enum';
import {CrmService} from '../crm-service/crm.service';
import {CrmStructure} from '../crm-service/crm-category';
import {BrowsingHistory} from '../crm-service/browsing-history';
import {EngagementEvent} from '../crm-service/engagement-event';
import {LoggingService} from '../logging.service';
import {TextMessageUtils} from '../../utils/text-message-utils';
import {DashboardData} from './dashboard-data';
import {KpiSetting} from '../dashboard-service/kpisetting';
import {AgentStatistics} from '../../classes/visitor/AgentStatistics';
import {VisitorBackendService} from './visitor.backend.service';
import {LicenceType} from '../../enums/licence-type.enum';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {AcceptEngagementResult} from '../../enums/accept-engagement-result.enum';
import {LogoutService} from '../logout.service';
import {SettingsService} from '../settings-service/settings.service';
import {AsyncConversation} from '../async-conversation';
import {AsyncMessageHandler, ConversationId, HubConversation, Message, ReassignMessage, ThreadId} from '../async-message-handler';
import {WorkStatus} from '../../classes/work-status';
import {HubTransferAccept, HubTransferChatMessage, HubTransferRejection} from './HubTransferRejection';
import {PostEngagementStatus} from '../../classes/post-engagement.status';
import { VirtualBackground } from '../../classes/virtualBackground';

// Todo:
// 2019-03-13: Have a look at these first()s after the timeout, I don't think they are required. -- R.Scott

@Injectable()
export class VisitorService implements OnDestroy {
  // Variable to disable window 'unload' event handlers for purposes of testing
  public static USE_WINDOW_UNLOAD = true;

  // Number of heartbeat cycles before cleaning up agents dead from the agent status list
  private static readonly AGENT_HEARTBEAT_CLEANUP_COUNT = 4;

  public isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _visitors$: BehaviorSubject<SiteVisitor[]> = new BehaviorSubject([]);
  public visitors: Observable<SiteVisitor[]> = this._visitors$.asObservable();
  private _visitors: Map<string, SiteVisitor> = new Map([]);

  private _proxy: Proxy;
  private readonly _proxyName = 'visitors';
  private _connection: Connection;

  private _transfers$: BehaviorSubject<InvitationRequest[]> = new BehaviorSubject<InvitationRequest[]>([]);
  public transfers: Observable<InvitationRequest[]> = this._transfers$.asObservable();
  private _transferRequests: InvitationRequest[] = [];

  private _invites$: BehaviorSubject<InvitationRequest[]> = new BehaviorSubject<InvitationRequest[]>([]);
  public invites: Observable<InvitationRequest[]> = this._invites$.asObservable();
  private _invites: InvitationRequest[] = [];

  private updateStatusSub?: Subscription;

  private _agentStatus: Map<string, AgentStatus> = new Map();
  public agentStatus: BehaviorSubject<Map<string, AgentStatus>> = new BehaviorSubject(this._agentStatus);

  public noInternet = new BehaviorSubject(false);

  public conversations: BehaviorSubject<AsyncConversation[]> = new BehaviorSubject<AsyncConversation[]>([]);

  // This handler is to disconnect from the hub if the window is closed
  // as the default signalr behaviour calls stop but that does not set the
  // status of the operator to offline and since it calls stop does not
  // trigger the !stopCalled path in signalr.
  private readonly unloadHandler: () => void = () => this.stopConnection();

  // Set when the stopConnection() method is called to prevent restarting
  // connections to the visitor hub. This is just defensive and should never
  // occur.
  private connectionAborted: boolean = false;

  private get agent(): Agent {
    return this.authService.currentAgent.value;
  }

  // Holds the list of all agents from the last screen update,
  // The idea is to just remove visitors that are still here
  private _visitorsToDelete: string[] = [];

  // Filter to apply to the visitors received, this is done prior to displaying on screen to cut down
  // on the amount of processing
  private updateFilter: (hubVisitor: HubVisitor) => boolean;

  private _agentLicenceType: LicenceType;

  public get agentLicenceType() {
    return this._agentLicenceType;
  }

  private agentCleanupCounter: number = 0;

  constructor(
    private authService: AuthService,
    private onlineService: OnlineService,
    private turnBackendService: TurnBackendService,
    private engagementService: EngagementService,
    private logoutService: LogoutService,
    private crmService: CrmService,
    private logging: LoggingService,
    private visitorBackendService: VisitorBackendService,
    private translate: TranslatePipe,
    private readonly settingsService: SettingsService
  ) {
    if (VisitorService.USE_WINDOW_UNLOAD) {
      window.addEventListener('unload', this.unloadHandler);
    }
  }

  ngOnDestroy(): void {
    if (VisitorService.USE_WINDOW_UNLOAD) {
      window.removeEventListener('unload', this.unloadHandler);
    }

    this.rejectAllTransfers('Gone offline');
    this.rejectAllJoins('Gone offline');
    this.stopConnection();
  }

  public startConnection(type: LicenceType, showCurrentEngagements: boolean = false): Observable<boolean> {
    this.agentCleanupCounter = 0;
    this._agentLicenceType = type;

    const canBeSupervisor = (type === LicenceType.VeeChat && this.agent.isSupervisor) || type === LicenceType.Supervisor;
    const options: Options = {
      qs: `sitename=${this.agent.sitename}&username=${this.agent.username}&listtype=${type}&supervisor=${canBeSupervisor}&desklicencekey=${this.onlineService.deskLicenceKey}`
    };

    if (canBeSupervisor) {
      if (type === LicenceType.Supervisor) {
        const requestOrEngagedFlags = StatusFlag.RequiresAssistance | StatusFlag.IsEngaged;
        this.updateFilter = (hubVisitor: HubVisitor) => !!(hubVisitor.flag & requestOrEngagedFlags);
      } else {
        // if veechat supervisor
        this.updateFilter = (_hubVisitor: HubVisitor) => true;
      }
    } else {
      if (showCurrentEngagements) {
        // Either visitors requesting assistance or visitors that are currently engaged with this agent
        this.updateFilter = (hubVisitor: HubVisitor) => !!(hubVisitor.flag & StatusFlag.RequiresAssistance) ||
          (!!(hubVisitor.flag & StatusFlag.IsEngaged) && hubVisitor.CurrentUsername === this.agent.username);
      } else {
        // Only show visitors requesting assistance
        this.updateFilter = (hubVisitor: HubVisitor) => !!(hubVisitor.flag & StatusFlag.RequiresAssistance);
      }
    }

    if (this.connectionAborted) {
      this.logging.error("Attempting to restart the visitor hub connection after it has been stopped through stopConnection().");
      return of(false);
    }

    this._connection = hubConnection(environment.visitoRHub, options);
    this._proxy = this._connection.createHubProxy(this._proxyName);
    this.registerOnServerEvents();

    return this.startConnectionInternal();
  }

  // check in the browser console for either signalr connected or not
  private startConnectionInternal(): Observable<boolean> {
    this._proxy.logging = true;

    this._connection.starting(() => {
      this.logging.debug('visitoR connection starting');
      this.noInternet.next(false);
    });
    // this._connection.received((a) => {
      // this.logging.debug('visitoR connection received message');
    // });
    this._connection.connectionSlow(() => {
      this.logging.debug('visitoR connection slow');
    });
    this._connection.reconnecting(() => {
      this.logging.debug('visitoR connection reconnecting');
      this.noInternet.next(true);
    });
    this._connection.reconnected(() => {
      this.logging.debug('visitoR connection reconnected');
      this.noInternet.next(false);
    });
    this._connection.stateChanged(() => {
      this.logging.debug('visitoR connection state changed');
    });
    this._connection.disconnected(() => {
      this.logging.debug('visitoR connection disconnected');
      this.isConnected.next(false);
      this.noInternet.next(true);
    });

    return new Observable<boolean>(obs => {
      this._connection.start({withCredentials: true, transport: 'webSockets'})
        .done((data: any) => {
          this.logging.debug('Now connected ' + data.transport.name + ', connection ID= ' + data.id);

          this.updateStatusSub =
            this.onlineService.currentState
              .subscribe(
                ([newState, workingMode]) => this.updateStatus(newState, workingMode).catch(() => {}));

          this.isConnected.next(true);
          this.noInternet.next(false);
          obs.next(true);
          obs.complete();
        }).fail((error: any) => {
          this.isConnected.next(false);
          this.noInternet.next(true);
          obs.next(false);
          obs.complete();
      });
    });
  }

  public getAsyncChats() {
    this.conversations.next([]);
    this.listAsyncChats().subscribe((convs: HubConversation[]) => {
      const conversations = [];
      for (let conv of convs) {
        conversations.push(new AsyncConversation(this, this.authService, conv, this.settingsService, this.crmService, this.logging));
      }

      this.conversations.next(conversations);
    });
  }

  public getAsyncChat(conversationId: ConversationId): Observable<AsyncConversation> {
    const conversation = this.conversations.value.find(c => c.id.Id == conversationId.Id);
    if (conversation) {
      return of(conversation);
    } else {
      return new Observable<AsyncConversation>(s => {
        this._proxy.invoke('GetAsyncConversation', conversationId.Id)
          .done(result => {
            // todo: need to guard against duplication.
            const conversation = new AsyncConversation(this, this.authService, result, this.settingsService, this.crmService, this.logging)
            this.conversations.value.push(conversation);
            this.conversations.next(this.conversations.value);
            s.next(conversation);
            s.complete();
          }).fail((err) => {
            this.logging.error(`Unable to get conversation ${conversationId.Id}`, err);
            s.error(`Unable to get conversation ${conversationId.Id}`);
            s.complete();
        });
      });
    }
  }

  public leaveAsyncChat(conversationId: ConversationId, threadId: ThreadId) {
    const conversation = this.conversations.value.find(c => c.id.Id == conversationId.Id);
    if (conversation) {
      const newConversations = this.conversations.value.filter(c => c != conversation);
      this.conversations.next(newConversations);
      this._proxy.invoke('LeaveAsyncConversation', conversationId.Id, threadId.Id)
        .done(_ => {
          this.logging.debug(`Left conversation ${conversationId.Id}`);
        }).fail((err) => {
          this.logging.error(`Unable to leave conversation ${conversationId.Id}`, err);
      });
    } else {
      this.logging.error(`Unable to leave conversation ${conversationId.Id}. Could not find id in array.`);
    }
  }

  public stopConnection(): void {
    this.connectionAborted = true;

    if (this.updateStatusSub) {
      this.updateStatusSub.unsubscribe();
    }

    if (this._proxy) {
      this.unRegisterOnServerEvents();
    }

    this.updateStatus(OnlineState.OffLine, WorkStatus.None)
        .then(() => this.logging.debug('Set status to offline on disconnection'))
        .catch(() => this.logging.error('Failed to set status to offline when disconnecting!'))
        .finally(() => {
          if (this._proxy) {
            this._proxy.connection.stop();
          }
          this.isConnected.next(false);
        });
  }

  private registerOnServerEvents(): void {
    this._proxy.on('UpdateGroupVisitors', this.handleUpdateGroupVisitors.bind(this));
    this._proxy.on('UpdateAgentVisitors', this.handleUpdateAgentVisitors.bind(this));
    this._proxy.on('RequestForHelp', (hubVisitor: HubVisitor, groupId: number) => this.handleRequestForHelp(hubVisitor, groupId));

    this._proxy.on('TransferRequest', (invite: HubInvitationRequest) => this.handleTransferRequest(invite));
    this._proxy.on('TransferCancelled', (cancellation: HubTransferCancellation) => this.handleTransferCancelled(cancellation));
    this._proxy.on('TransferRejected', (rejection: HubTransferRejection) => this.handleTransferRejection(rejection));
    this._proxy.on('TransferAccepted', (rejection: HubTransferAccept) => this.handleTransferAccept(rejection));
    this._proxy.on("TransferChatMessage", (message: HubTransferChatMessage) => this.handleTransferChatMessage(message));

    this._proxy.on('InviteToJoin', (invite: HubInviteToJoin) => this.handleInviteToJoin(invite));
    this._proxy.on('InviteCancelled', (invite: HubInviteCancelled) => this.handleInviteCancelled(invite));

    // this is actually an object with keys are the operator usernames, not a map!!!
    this._proxy.on('AgentList', (agentList: any) => this.handleUpdateAgentList(agentList));

    this._proxy.on('ForceLogout', () => this.handleForceLogout());

    this._proxy.on('AgentStatus', (newStatuses: AgentServerStatus[]) => this.handleUpdateAgentStatus(newStatuses));
    this._proxy.on('NewAgent', (newAgent: AgentServerInfo) => this.handleNewAgent(newAgent));
    this._proxy.on('UpdateAgentStats', (agentStats: AgentStatistics) => this.handleUpdateAgentStats(agentStats));
    this._proxy.on('UpdateAgentStatsList', (agentStats: AgentStatistics[]) => this.handleUpdateAgentStatsList(agentStats));

    this._proxy.on('ConversationUpdate', (msg: any) => this.onConversationUpdate(msg));
    this._proxy.on('ConversationTransfer', (msg: any) => this.onConversationTransfer(msg));
  }

  private unRegisterOnServerEvents(): void {
    this._proxy.off('UpdateGroupVisitors');
    this._proxy.off('UpdateAgentVisitors');
    this._proxy.off('RequestForHelp');

    this._proxy.off('TransferRequest');
    this._proxy.off('TransferCancelled');
    this._proxy.off('TransferRejected');
    this._proxy.off('TransferAccepted');
    this._proxy.off('TransferChatMessage');

    this._proxy.off('InviteToJoin');
    this._proxy.off('InviteCancelled');

    this._proxy.off('AgentList');

    this._proxy.off('ForceLogout');

    this._proxy.off('AgentStatus');
    this._proxy.off('NewAgent');
    this._proxy.off('UpdateAgentStats');
    this._proxy.off('UpdateAgentStatsList');

    this._proxy.off('ConversationUpdate');
    this._proxy.off('ConversationTransfer');
  }

  private handleNewAgent(newAgent: AgentServerInfo) {
    const tmp = {};
    tmp[newAgent.Username] = newAgent;
    this.handleUpdateAgentList(tmp);
  }

  private handleForceLogout() {
    this.logoutService.logout();
  }

  private handleUpdateAgentList(agentList: any) {
    const keys = Object.keys(agentList);
    for (const k of keys) {
      // Skip over the current user
      if (k === this.authService.currentAgent.value.username) {

        this.authService.currentAgent.value.groups = agentList[k].Groups;
        this.authService.currentAgent.next(this.authService.currentAgent.value);
        continue;
      }

      const info = agentList[k];
      const storedInfo = this._agentStatus.get(info.Username);

      const agent: AgentStatus = {
        username: info.Username,
        status: storedInfo ? storedInfo.status : OnlineState.OffLine,
        timestamp: storedInfo ? new Date(storedInfo.timestamp) : (new Date(new Date().getTime() - (1000 * 3600 * 24))),
        heartbeat: storedInfo ? new Date(storedInfo.heartbeat) : new Date(),
        updated: true,
        groups: info.Groups,
        firstname: info.Firstname,
        lastname: info.Lastname,
        nickname: info.Nickname,
        photo: `${environment.assetProxy}${info.Photo}`,
        widePhoto: `${environment.assetProxy}${info.WidePhoto}`,
        nudgeImage: `${environment.assetProxy}${info.NudgeImage}`,
        culture: info.Culture,
        isSupervisor: info.IsSupervisor,
        multichatLimit: info.MultichatLimit,
        loggedInTimestamp: storedInfo ? storedInfo.loggedInTimestamp : null,
        currentSessionNumber: storedInfo ? storedInfo.currentSessionNumber : 0,
        totalSessionNumber: storedInfo ? storedInfo.totalSessionNumber : 0,
        lastEngagementTimestamp: storedInfo?.lastEngagementTimestamp ?? null,
        totalAsyncMessagesSent: storedInfo?.totalAsyncMessagesSent ?? 0,
      };

      this._agentStatus.set(info.Username, agent);
    }
    this.agentStatus.next(this._agentStatus);
  }

  private handleUpdateAgentStatus(statuses: AgentServerStatus[]) {
    for (const status of statuses) {
      // Skip over the current user
      if (status.username === this.authService.currentAgent?.getValue()?.username) {
        continue;
      }

      const storedInfo = this._agentStatus.get(status.username);

      if (storedInfo) {
        const ts = new Date(status.heartbeat);
        if (storedInfo.heartbeat < ts) {
          storedInfo.status = status.status;
          storedInfo.workStatus = status.workStatus;
          storedInfo.timestamp = new Date(status.timestamp);
          storedInfo.heartbeat = new Date(status.heartbeat);
          storedInfo.updated = true;
        }
      } else {
        const agent: AgentStatus = {
          username: status.username,
          isSupervisor: false,
          firstname: status.username,
          workStatus: status.workStatus,
          lastname: '',
          nickname: status.username,
          photo: '',
          widePhoto: '',
          nudgeImage: '',
          culture: 'en-gb',
          groups: [],
          timestamp: new Date(status.timestamp),
          heartbeat: new Date(status.heartbeat),
          status: status.status,
          multichatLimit: null,
          loggedInTimestamp: null,
          currentSessionNumber: 0,
          totalSessionNumber: 0,
          lastEngagementTimestamp: null,
          totalAsyncMessagesSent: 0,
          updated: true,
        };

        this._agentStatus.set(status.username, agent);
      }
    }

    this.agentStatus.next(this._agentStatus);
  }

  private handleUpdateAgentStats(stats: AgentStatistics) {
    const tmp: AgentStatistics[] = [];
    tmp.push(stats);
    this.handleUpdateAgentStatsList(tmp);
  }

  private handleUpdateAgentStatsList(stats: AgentStatistics[]) {
    for (const stat of stats) {
      if (stat.username === this.authService.currentAgent.value.username) {
        continue;
      }

      const storedInfo = this._agentStatus.get(stat.username);

      if (storedInfo) {
        storedInfo.loggedInTimestamp = new Date(stat.loggedInTimestamp);
        storedInfo.currentSessionNumber = stat.currentSessionNumber;
        storedInfo.totalSessionNumber = stat.totalSessionNumber;
        storedInfo.totalAsyncMessagesSent = stat.totalAsyncMessagesSent;

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (new Date(stat.lastEngagementTimestamp) > today) {
          storedInfo.lastEngagementTimestamp = new Date(stat.lastEngagementTimestamp);
        } else {
          storedInfo.lastEngagementTimestamp = null;
        }
      } else {
        const agent: AgentStatus = {
          username: stat.username,
          isSupervisor: false,
          firstname: '',
          lastname: '',
          nickname: '',
          photo: '',
          widePhoto: '',
          nudgeImage: '',
          culture: 'en-gb',
          groups: [],
          timestamp: null,
          status: OnlineState.OffLine,
          multichatLimit: null,
          loggedInTimestamp: new Date(stat.loggedInTimestamp),
          currentSessionNumber: stat.currentSessionNumber,
          totalSessionNumber: stat.totalSessionNumber,
          lastEngagementTimestamp: new Date(stat.loggedInTimestamp),
          totalAsyncMessagesSent: stat.totalAsyncMessagesSent,
          updated: false,
          heartbeat: null,
        };

        this._agentStatus.set(stat.username, agent);
      }
    }
    this.agentStatus.next(this._agentStatus);
  }

  private handleUpdateGroupVisitors(groupVisitors: AgentGroupVisitors): void {
    this.updateVisitors(groupVisitors.GroupId, groupVisitors.Visitors.filter(v => this.updateFilter(v)));
  }

  private handleUpdateAgentVisitors(groupVisitors: AgentGroupVisitors): void {
    this.updateVisitors(groupVisitors.GroupId, groupVisitors.Visitors.filter(v => this.updateFilter(v)));
    // ping the agents heartbeat
    this._proxy.invoke('AgentHeartbeat', true, this.onlineService.currentState.value[0]);

    // Periodically clean up agents that have not had their status updated.
    // The status update is sent out as part of the 'AgentHeartbeat' invocation.
    // All agents are marked as not updated as part of this so they can be
    // set back to true when the updated come in over the next AgentHeartbeats
    if (++this.agentCleanupCounter > VisitorService.AGENT_HEARTBEAT_CLEANUP_COUNT) {
      this.agentCleanupCounter = 0;
      for (const [_, s] of this._agentStatus) {
        if (!s.updated) {
          if (s.status != OnlineState.OffLine) {
            s.status = OnlineState.OffLine;
            s.timestamp = new Date();
            s.heartbeat = null;
            s.workStatus = null;
          }
        }
        s.updated = false;
      }
      this.agentStatus.next(this._agentStatus);
    }

    // Signalr can combine messages together for a single connection id. When the server isn't busy
    // it bundles the updateGroup and updateAgent invocations together. The js handler triggers the
    // call backs in whatever order the server bundled them together in. So we can get situations where it
    // will call [updateGroup, updateAgent, updateGroup]. The overall ordering is correct but the bundle is wrong.
    // To get around this we call setTimeout with the clear routine (this bound via a lambda). As the
    // handlers fire synchronously the clear routine will run after all the handlers have fired and
    // any associate promises have resolved.
    setTimeout(() => {
      // Now we remove all old
      for (const key of this._visitorsToDelete) {
        this._visitors.delete(key);
      }

      this._visitorsToDelete = Array.from(this._visitors.keys());
      this._visitors$.next(Array.from(this._visitors.values()));
    }, 0);
  }

  private handleRequestForHelp(hubVisitor: HubVisitor, groupId: number): void {
    const key = HubVisitorUtils.CreateKey(hubVisitor);
    const visitor = this._visitors.get(key);

    // If we do not already have this visitor then create a new one and insert it
    // If we do have it then the request for help comes before the poll in theory
    // so we ignore it as arriving late and do not update the visitor
    if (!visitor) {
      const newVisitor = new SiteVisitor(hubVisitor, groupId || -1);

      this.getVirtualBackground(newVisitor.site, newVisitor.lastSection).subscribe(data => {
        newVisitor.virtualBackground.next(data);
      });

      this.getVisitorDetails(newVisitor.userGuid, newVisitor.sessionGuid).subscribe(details => {
        newVisitor.details.next(details);
      });

      this.getVisitorCrmData(newVisitor.userGuid).subscribe(data => {
        newVisitor.setCrmData(data, this.crmService);
      });

      this.getVisitorBrowsingHistory(newVisitor.userGuid, newVisitor.sessionGuid).subscribe(history => {
        newVisitor.browsingHistory.next(history);
      });

      this.getVisitorSessionHistory(newVisitor.userGuid).subscribe(data => {
        newVisitor.sessionHistory.next(data);
      });

      this._visitors.set(key, newVisitor);

      // todo: find a better way to do this, maybe pass the whole thing through and use keyvalues pipe
      this._visitors$.next(Array.from(this._visitors.values()));
    }
  }

  /**
   * Invoked when invocation request i.e. InvitationRequest received from server hub
   *
   * Invoked when call transfer from other operator
   * @param invite InvitationRequest object received from server
   */
  private handleTransferRequest(invite: HubInvitationRequest): void {
    // Add InvitationRequest object in to the transferRequest array
    const visitor = new SiteVisitor(invite.Visitor);

    const agent = this._agentStatus.get(invite.SourceAgentUsername);
    let agentName = '';
    if (agent) {
      agentName = agent.firstname + ' ' + agent.lastname;
    }

    const transferInvitation: InvitationRequest = {
      roomId: invite.RoomId,
      reason: invite.Reason,
      sourceAgentUsername: invite.SourceAgentUsername,
      visitor: visitor,
      chatHistory: TextMessageUtils.CreateTextMessages(invite.ChatHistory, this.authService.currentAgent.value.username),
      sourceAgentFullname: agentName,
      isConversation: invite.IsConversation,
      messages: []
    };

    if (transferInvitation.isConversation) {
      this.getAsyncMessages({ Id: transferInvitation.roomId }, { Id: transferInvitation.visitor.userGuid}, false)
        .subscribe(m => {
          const handler = new AsyncMessageHandler(this.logging, this.settingsService, this.agent.username, transferInvitation.roomId);
          handler.handleMessages(m);
          transferInvitation.chatHistory = handler.Messages.messages;
      });
    }

    transferInvitation.visitor.beingTransferred = true;

    this.getVirtualBackground(transferInvitation.visitor.site, transferInvitation.visitor.lastSection).subscribe(data => {
      transferInvitation.visitor.virtualBackground.next(data);
    });

    this.getVisitorDetails(transferInvitation.visitor.userGuid, transferInvitation.visitor.sessionGuid).subscribe(details => {
      transferInvitation.visitor.details.next(details);
    });

    this.getVisitorCrmData(transferInvitation.visitor.userGuid).subscribe(data => {
      transferInvitation.visitor.setCrmData(data, this.crmService);
    });

    this.getVisitorSessionHistory(transferInvitation.visitor.userGuid).subscribe(data => {
      transferInvitation.visitor.sessionHistory.next(data);
    });

    this.getVisitorBrowsingHistory(transferInvitation.visitor.userGuid, transferInvitation.visitor.sessionGuid).subscribe(history => {
      transferInvitation.visitor.browsingHistory.next(history);
    });

    this._transferRequests.push(transferInvitation);
    this._transfers$.next(this._transferRequests);
  }

  private handleInviteToJoin(invite: HubInviteToJoin): void {
    // Add InvitationRequest object in to the transferRequest array
    const visitor = new SiteVisitor(invite.Visitor);

    const agent = this._agentStatus.get(invite.SourceAgentUsername);
    let agentName = '';
    if (agent) {
      agentName = agent.firstname + ' ' + agent.lastname;
    }

    const joinInvitation: InvitationRequest = {
      roomId: invite.RoomId,
      reason: invite.Reason,
      sourceAgentUsername: invite.SourceAgentUsername,
      visitor: visitor,
      chatHistory: TextMessageUtils.CreateTextMessages(invite.ChatHistory, this.authService.currentAgent.value.username),
      sourceAgentFullname: agentName,
      isConversation: false,
      messages: []
    };

    joinInvitation.visitor.isMultiparty = true;

    this.getVirtualBackground(joinInvitation.visitor.site, joinInvitation.visitor.lastSection).subscribe(data => {
      joinInvitation.visitor.virtualBackground.next(data);
    });

    this.getVisitorDetails(joinInvitation.visitor.userGuid, joinInvitation.visitor.sessionGuid).subscribe(details => {
      joinInvitation.visitor.details.next(details);
    });

    this.getVisitorCrmData(joinInvitation.visitor.userGuid).subscribe(data => {
      joinInvitation.visitor.setCrmData(data, this.crmService);
    });

    this.getVisitorSessionHistory(joinInvitation.visitor.userGuid).subscribe(data => {
      joinInvitation.visitor.sessionHistory.next(data);
    });

    this.getVisitorBrowsingHistory(joinInvitation.visitor.userGuid, joinInvitation.visitor.sessionGuid).subscribe(history => {
      joinInvitation.visitor.browsingHistory.next(history);
    });

    this._invites.push(joinInvitation);
    this._invites$.next(this._invites);
  }

  private handleInviteCancelled(data: HubInviteCancelled): void {
    this.removeInviteToJoin(data.RoomId);
  }

  private removeInviteToJoin(roomId: string): void {
    this._invites = this._invites.filter(jr => jr.roomId !== roomId);
    this._invites$.next(this._invites);
  }

  /**
   * Invoked when invocation request i.e. HubTransferCancellation received from server hub
   *
   * Invoked when call transfer cancel by other operator
   * @param data HubTransferCancellation object received from server. contains roomId
   */
  private handleTransferCancelled(data: HubTransferCancellation): void {
    this.removeTransferRequest(data.RoomId);
  }

  private handleTransferRejection(data: HubTransferRejection): void {
    this.conversations.value
      .filter(c => c.id.Id === data.RoomId)
      .map(c => c.transferRejected(data.RejectingAgentUsername, atob(data.Reason)));
  }

  private handleTransferAccept(data: HubTransferAccept): void {
    this.conversations.value
      .filter(c => c.id.Id === data.RoomId)
      .map(c => c.transferAccepted(data.AcceptingAgentUsername));
  }

  private handleTransferChatMessage(message: HubTransferChatMessage) {
    const textMessage = TextMessageUtils.CreateTransferTextMessage(message, this.agent.username);

    const engagement = this.engagementService.getEngagement(message.RoomId);
    if (engagement) {
      engagement.onTransferChatMessage(textMessage);
    }

    this.conversations.value
      .filter(c => c.id.Id === message.RoomId)
      .map(c => c.onTransferChatMessage(textMessage));

    this._transferRequests
      .filter(x => x.roomId === message.RoomId)
      .map(x => {
        x.messages.push(textMessage);
        x.messages = [...x.messages];
      });
  }

  private updateVisitors(groupId: number, visitors: HubVisitor[]): void {
    for (const hubVisitor of visitors) {
      const key = HubVisitorUtils.CreateKey(hubVisitor);
      const oldVisitor = this._visitors.get(key);

      if (oldVisitor) {
        // Update the old visitor
        oldVisitor.updateVisitor(hubVisitor, groupId);
        this._visitorsToDelete = this._visitorsToDelete.filter(k => k !== key);
      } else {
        // Create a new visitor and add it to the map
        // Set the updated map flag
        const newVisitor = new SiteVisitor(hubVisitor, groupId);

        // Set new visitors transfer start time to be the last update time
        // when dealing with async visitors.
        if (newVisitor.isAsync) {
          newVisitor.waitingSince = newVisitor.lastResponseTime;
        }

        this.getVirtualBackground(newVisitor.site, newVisitor.lastSection).subscribe(data => {
          newVisitor.virtualBackground.next(data);
        });

        this.getVisitorDetails(newVisitor.userGuid, newVisitor.sessionGuid).subscribe(details => {
          newVisitor.details.next(details);
        });

        this.getVisitorCrmData(newVisitor.userGuid).subscribe(data => {
          newVisitor.setCrmData(data, this.crmService);
        });

        this.getVisitorBrowsingHistory(newVisitor.userGuid, newVisitor.sessionGuid).subscribe(history => {
          newVisitor.browsingHistory.next(history);
        });

        this.getVisitorSessionHistory(newVisitor.userGuid).subscribe(data => {
          newVisitor.sessionHistory.next(data);
        });

        // Set visitor in both so that it is not removed in the merge later
        this._visitors.set(key, newVisitor);
        // this._visitorsChanged = true; <- data binding probably takes care of this
      }
    }
  }

  /**
   * Remove transfer request object & visitor object from array on TransferRequest accept, reject or cancel
   * @param roomId EngagementId/roomId to filter & remove
   */
  private removeTransferRequest(roomId: string) {
    const index = this._transferRequests.findIndex(tr => {
      return tr.roomId === roomId;
    });

    if (index > -1) {
      this._transferRequests.splice(index, 1);
    }

    this._transfers$.next(this._transferRequests);
  }

  private removeJoinRequest(roomId: string) {
    this._invites = this._invites.filter(jr => jr.roomId !== roomId);
    this._invites$.next(this._invites);
  }

  private createEngagementAcceptFunction(visitor: SiteVisitor, currentAgent: Agent, subscribe: Subscriber<MessageResponse<string | { engagementId: string}>>, startState: any) {
    return (data: VisitorToAgentMessage) => {
      if (startState.hasFailed) {
        // The observable has been completed by the timeout error here
        this.logging.error('Took too long to accept call, in customer handshake loop. Aborting.');
        return;
      }

      // make sure its from the customer lol
      if (data.UserGuid === visitor.userGuid && data.SessionGuid === visitor.sessionGuid) {
        switch (data.MessageType) {
          case 101:
            this.logging.debug(`Starting call for visitor ${visitor.userGuid} session ${visitor.sessionGuid}`);

            // 'engagementId:widthxheight:base64Encoded(lastPage):connectionMode'
            const params: string[] = data.Message.split(':');
            let engagementId: string;
            if (params.length === -1) { // TODO: check params[0] is a GUID
              subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_NOENGGUID",'Engagement guid not returned')});
              subscribe.complete();
              return;
            } else {
              engagementId = params[0];
            }

            this.logging.debug(`Using engagement guid ${engagementId}`);

            // setup the turn auth token
            this.turnBackendService.addEngagement(engagementId, currentAgent.servers.WEBRTCICEURL, currentAgent.authToken).subscribe(
              (turnUri) => {

                if (startState.hasFailed) {
                  // We are leaking webrtc turn server instances here can't just remove it though
                  // since another agent might pick up the call and we kill their turn session.
                  this.logging.error('Took too long to accept call, in turn backend setup. Aborting.');
                  // The observable has been completed by the timeout error here
                  return;
                }

                let actualCallType = visitor.actualCallType(this._agentLicenceType);

                const startSessionRequestMessage = engagementId + ':' +
                  currentAgent.widePhoto + ':' +
                  currentAgent.nickname + ':' +
                  actualCallType + ':' + // Call Type
                  btoa(turnUri) + ':' +
                  environment.customerEngageRHub;

                const startReq = {
                  user: visitor.userGuid,
                  session: visitor.sessionGuid,
                  message: startSessionRequestMessage,
                  callType: visitor.engagementType,
                  site: visitor.site,
                  siteSection: visitor.targettedSection,
                  groupId: visitor.groupId,
                  botEscalation: visitor.isBotEscalation
                };

                // now create start session request
                this._proxy.invoke('StartEngagement', startReq)
                  .done((result) => {
                    if (!result) {
                      // something went horribly wrong
                      subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_USERINACTIVE", 'Customer is inactive')});
                      subscribe.complete();
                      return;
                    }

                    // since the actual sending is asynchronous and we've sent the start message so this
                    // agent has the call now.
                    startState.startedFlag = true;

                    // return the engagementId
                    const newEngagement = this.engagementService.createEngagement(engagementId, visitor, turnUri, this._agentLicenceType);

                    if (null == newEngagement) {
                      subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_CANNOTCREATE",'Unable to create engagement')});
                      subscribe.complete();
                      return;
                    }

                    this.getVisitorCrmData(visitor.userGuid).subscribe(crmdata => {
                      visitor.setCrmData(crmdata, this.crmService);
                    });

                    // Wire up the listener to close the engagement when it enters the post state
                    // the post part will then call the qualify when it's all over
                    const endSub = newEngagement.currentState.subscribe(newState => {
                      if (newState.type === EngagementState.Post) {
                        endSub.unsubscribe();
                        this.endEngagement(engagementId).subscribe();
                      }
                    });

                    subscribe.next({success: true, message: {engagementId: engagementId}});
                    subscribe.complete();
                    return;
                  })
                  .fail(() => {
                    subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_STARTFAIL", 'StartEngagement called fail')});
                    subscribe.complete();
                    return;
                  });
              },
              error => {
                subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_TURNFAIL", 'Failed authorising AV Server')});
                subscribe.complete();
                return;
              }
            );

            break;
          case 151:
            subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_ALREADYENGAGED", 'Another agent has picked up the call')});
            subscribe.complete();
            break;
          case 104:
            subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_ENDCALL", 'Customer has hungup')});
            subscribe.complete();
            break;
          default:
          // observer.next(false);
        }
      }
    };
  }

  private createUserPageVisibilityFunction(subscribe: Subscriber<MessageResponse<string>>, startState: any) {
    return (pageVisible:boolean) => {
      if (!pageVisible) {
        subscribe.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_USERINACTIVE", 'Customer is inactive')});
        subscribe.complete();
        return;
      }
    };
  };

  public listAsyncChats(): Observable<HubConversation[]> {
    return new Observable<HubConversation[]>(s => {
      this._proxy.invoke('ListAsyncConversations')
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
          s.complete();
      });
    });
  }

  public getAsyncMessages(conversationId: ConversationId, threadId: ThreadId, subscribe: boolean): Observable<Message[]> {
    return new Observable<Message[]>(s => {
      this._proxy.invoke('GetMessagesForConversation', conversationId.Id, threadId.Id, subscribe)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  private onConversationUpdate(msg: any) {
    this.conversations.value
      .filter(c => c.threadId.Id == msg.ThreadId.Id)
      .forEach(c => c.handleMessage(msg));
  }

  private onConversationTransfer(msg: ReassignMessage) {
    this.getAsyncChat(msg.ConversationId).subscribe();
  }

  public sendConversationMessage(conversationId: ConversationId, threadId: ThreadId, plainText: string) {
    return new Observable<Message>(s => {
      this._proxy.invoke('SendAsyncMessage', conversationId.Id, threadId.Id, this.agent.nickname, plainText)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  public sendConversationTemplate(conversationId: ConversationId, threadId: ThreadId, format: number, template: string) {
    return new Observable<Message>(s => {
      this._proxy.invoke('SendAsyncTemplate', conversationId.Id, threadId.Id, this.agent.nickname, format, template)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  public sendFileUploadMessage(conversationId: ConversationId, threadId: ThreadId, fileUploadUrl: string) {
    return new Observable<Message>(s => {
      this._proxy.invoke('SendAsyncFileUpload', conversationId.Id, threadId.Id, this.agent.nickname, fileUploadUrl)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  public sendSurveyMessage(conversationId: ConversationId, threadId: ThreadId) {
    return new Observable<Message>(s => {
      this._proxy.invoke('SendAsyncSurvey', conversationId.Id, threadId.Id)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
          s.complete();
      });
    });
  }

  public endAsyncConversation(conversationId: ConversationId, threadId: ThreadId) {
    return new Observable<HubConversation>(s => {
      this._proxy.invoke('EndAsyncConversation', conversationId.Id, threadId.Id)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
          s.complete();
      });
    });
  }

  public coldTransferAsyncChat(conversationId: ConversationId, siteSection: string, message: string = '') {
    return new Observable<HubConversation>(s => {
      this._proxy.invoke('ColdTransferAsyncChat', conversationId.Id, siteSection, message)
        .done(result => {
          s.next(result);
          this.conversations.next(this.conversations.value.filter(c => c.id.Id != conversationId.Id));
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  public warmTransferAsyncChat(conversationId: ConversationId, newAgent: string, message: string = '') {
    return new Observable<void>(s => {
      this._proxy.invoke('WarmTransferAsyncChat', conversationId.Id, newAgent, message)
        .done(result => {
          s.next(result);
          this.conversations.next(this.conversations.value.filter(c => c.id.Id != conversationId.Id));
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }

  public closeConversation(conversationId: ConversationId, threadId: ThreadId) {
    return new Observable<AsyncConversation>(s => {
      this._proxy.invoke('CloseAsyncConversation', conversationId.Id, threadId.Id)
        .done(result => {
          s.next(result);
          s.complete();
        }).fail(() => {
        s.complete();
      });
    });
  }


  public sendWarmTransferRequest(conversationId: ConversationId, newAgent: string, message: string): Observable<MessageResponse<string>> {
    return new Observable<MessageResponse<string>>(s => {
      this._proxy.invoke('WarmTransferRequest', conversationId.Id, newAgent, message)
        .done(result => {
          s.next({success: true, message: ''});
          s.complete();
        }).fail(() => {
          s.next({success: false, message: ''});
          s.complete();
      });
    });
  }

  public sendWarmTransferCancel(conversationId: ConversationId, newAgent: string): Observable<MessageResponse<string>> {
    return new Observable<MessageResponse<string>>(s => {
      this._proxy.invoke('WarmTransferCancel', conversationId.Id, newAgent)
        .done(result => {
          s.next({success: true, message: ''});
          s.complete();
        }).fail(() => {
        s.next({success: false, message: ''});
        s.complete();
      });
    });
  }

  public sendWarmTransferAccept(transferRequest: InvitationRequest): Observable<MessageResponse<string>> {
    return new Observable<MessageResponse<string>>(s => {
      this._proxy.invoke('AcceptTransfer', transferRequest.roomId, transferRequest.sourceAgentUsername, this.authService.currentAgent.value.username)
        .done(result => {
          s.next({success: true, message: ''});
          this.removeTransferRequest(transferRequest.roomId);
          s.complete();
        }).fail(() => {
        s.next({success: false, message: ''});
        s.complete();
      });
    });
  }

  public acceptEngagement(visitor: SiteVisitor): Observable<MessageResponse<any>> {
    if (visitor.isAsync) {
      return new Observable<MessageResponse<AsyncConversation>>(s => {
        this._proxy.invoke('AcceptAsyncConversation', visitor.sessionGuid, visitor.userGuid)
          .done(result => {
            if (result) {
              const newConversation = new AsyncConversation(this, this.authService, result, this.settingsService, this.crmService, this.logging);
              this.conversations.value.push(newConversation);
              this.conversations.next(this.conversations.value);
              s.next({success: true, message: newConversation});
            } else {
              // There are no failure states currently defined, so an empty message is returned.
              s.next({success: false, message: null});
            }
            s.complete();
          }).fail(() => {
            s.complete();
          });
      });
    }

    const startStateFlags = {
      hasFailed: false,
      startedFlag: false
    };

    let visitorAcceptFunction;
    let userPageVisibilityFunction;

    return new Observable<MessageResponse<string>>(
      observer => {
        this.logging.debug(`Starting call acceptance for visitor ${visitor.userGuid} session ${visitor.sessionGuid}`);

        const currentAgent = this.authService.currentAgent.value;

        // stash the current visitor away for later

        // this 20 message is a hidden engagement request
        // it has been sent and things are good
        // the reason we put it in was so that it followed the same process as the manual nudge process (yuck)
        // but it does give us a hook to check if another agent has already accepted the call from the server and from the js
        // so, lets listen for the customers answer
        visitorAcceptFunction = this.createEngagementAcceptFunction(visitor, currentAgent, observer, startStateFlags);
        this._proxy.on('VisitorToAgentMessage', visitorAcceptFunction);

        userPageVisibilityFunction = this.createUserPageVisibilityFunction(observer, startStateFlags);
        this._proxy.on('UserPageVisibilityChange', userPageVisibilityFunction);

        this._proxy.invoke('AcceptEngagement', visitor.userGuid, visitor.sessionGuid)
          .done((result) => {
            if (result != AcceptEngagementResult.OK) {

              switch (result) {
                case AcceptEngagementResult.TotalFailure:
                  observer.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_DBFAIL", 'AcceptEngagement DB insert fail')});
                  break;
                case AcceptEngagementResult.UsersPageIsInvisible:
                  observer.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_USERINACTIVE", 'Customer is inactive')});
                  break;
                case AcceptEngagementResult.AnotherAgentHasAnswered:
                  observer.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_ALREADYENGAGED", 'Another agent has picked up the call')});
                  break;
              }

              observer.complete();

              if (visitorAcceptFunction) {
                this._proxy.off('VisitorToAgentMessage', visitorAcceptFunction);
                visitorAcceptFunction = null;
              }
              if (userPageVisibilityFunction) {
                this._proxy.off('UserPageVisibilityChange', userPageVisibilityFunction);
                userPageVisibilityFunction = null;
              }

            }
            // taken care of in the visitorAcceptFunction
          })
          .fail(() => {
            // something went horribly wrong
            observer.next({success: false, message: this.translate.transform("CALLLIST_ALERT_ERROR_ACCEPTFAIL", 'Please check your network connection')});
            observer.complete();
          });
      }
    ).pipe(
      finalize(
        () => {
          if (visitorAcceptFunction) {
            this._proxy.off('VisitorToAgentMessage', visitorAcceptFunction);
            visitorAcceptFunction = null;
          }
          if (userPageVisibilityFunction) {
            this._proxy.off('UserPageVisibilityChange', userPageVisibilityFunction);
            userPageVisibilityFunction = null;
          }
        }),
        timeout(15000),
        catchError((err, obs) => {
          // Error from timeout, if the call hasn't started then set the
          // the failed flag to abort the chained handlers otherwise
          // ignore it because we have sent back out 3 message which is the
          // engagement start.
          if (startStateFlags.startedFlag) {
            return obs;
          } else {
            startStateFlags.hasFailed = true;
            return throwError(err);
          }
        }),
        first(),
        tap(response => {
          if (!response.success) {
            this.logUnableToAccept(visitor, response.message, false);
          }
        }, error => {
          this.logUnableToAccept(visitor, JSON.stringify(error), true);
        })
    );
  }

  private logUnableToAccept(visitor: SiteVisitor, errorMessage: string, dueToTimeout: boolean) {
    try {
      this._proxy.invoke('LogUnableToAcceptEngagement', visitor.userGuid, visitor.sessionGuid, errorMessage, dueToTimeout)
                 .done((result) => {})
                 .fail(err => this.logging.error(`Unable to log accept request failure for visitor ${visitor.userGuid} session ${visitor.sessionGuid}`, err));
    } catch (err) {
      this.logging.error(`Unable to log accept request failure for visitor ${visitor.userGuid} session ${visitor.sessionGuid}`, err);
    }
  }

  public endEngagement(engagementId: string): Observable<MessageResponse<string>> {
    const engagement = this.engagementService.getEngagement(engagementId);

    if (engagement == null) {
      // unable to end the engagement since it doesn't exit
      return new Observable(n => {
        n.next({success: false, message: 'Event Close Failed'});
        n.complete();
      });
    }

    const visitorGuid = engagement.visitor.userGuid;
    const sessionGuid = engagement.visitor.sessionGuid;

    return new Observable<MessageResponse<string>>(
      observer => {
        this._proxy.invoke('EndEngagement', visitorGuid, sessionGuid, engagementId)
          .done(() => {

            this.turnBackendService.removeEngagement(engagementId, this.authService.currentAgent.value.authToken).subscribe(
              () => {
              },
              error => {
              }
            );

            observer.next({success: true, message: 'Event Closed'});
            observer.complete();
          })
          .fail(() => {
            observer.next({success: false, message: 'Event Close Failed'});
            observer.complete();
          });
      }
    ).pipe(timeout(5000), first());
  }

  public qualifyConversation(id: ConversationId, visitorGuid: string, status: string, subStatus: string, notes: string): Observable<MessageResponse<string>> {
    return new Observable<MessageResponse<string>>(
      observer => {
        this._proxy.invoke('QualifyEngagement', visitorGuid, id.Id, id.Id, status, subStatus, notes)
          .done(() => {
            observer.next({success: true, message: 'Event Updated'});
            this.conversations.next(this.conversations.value.filter(c => c.id.Id != id.Id));
            observer.complete();
          })
          .fail(() => {
            observer.next({success: false, message: 'Event Update Failed'});
            observer.complete();
          });
      }
    ).pipe(timeout(5000), first());
  }

  public changeNameOfConversation(id: ConversationId, newName: string) {
    return new Observable<void>(
      observer => {
        this._proxy.invoke('ChangeNameOfConversation', id.Id, newName)
          .done(() => {
            this.logging.debug(`Changed name of conversation ${id.Id} to "${newName}"`);
            observer.complete();
          })
          .fail((err) => {
            this.logging.error(`Failed to change name of conversation ${id.Id} to "${newName}"`, err);
            observer.complete();
          });
      }
    );
  }

  public qualifyEngagement(engagementId: string, status: string, subStatus: string, notes: string): Observable<MessageResponse<string>> {
    const engagement = this.engagementService.getEngagement(engagementId);

    if (engagement == null) {
      // unable to end the engagement since it doesn't exit
      return new Observable(n => {
        n.next({success: false, message: 'Event Close Failed'});
        n.complete();
      });
    }
    const visitorGuid = engagement.visitor.userGuid;
    const sessionGuid = engagement.visitor.sessionGuid;

    return new Observable<MessageResponse<string>>(
      observer => {

        this._proxy.invoke('QualifyEngagement', visitorGuid, sessionGuid, engagementId, status, subStatus, notes)
          .done(() => {
            observer.next({success: true, message: 'Event Updated'});
            engagement.postCompleted();
            observer.complete();
          })
          .fail(() => {
            observer.next({success: false, message: 'Event Update Failed'});
            observer.complete();
          });
      }
    ).pipe(timeout(5000), first());
  }


  public acceptTransferringIn(transferRequest: InvitationRequest): Observable<MessageResponse<string | { engagementId: string}>> {
    return new Observable<MessageResponse<string | { engagementId: string}>>(
      observer => {
        this._proxy.invoke('AcceptTransfer', transferRequest.roomId, transferRequest.sourceAgentUsername, this.authService.currentAgent.value.username)
          .done(() => {
            const currentAgent = this.authService.currentAgent.value;
            const engagementId = transferRequest.roomId;
            // stash the current visitor away for later

            this.turnBackendService.addEngagement(engagementId, currentAgent.servers.WEBRTCICEURL, currentAgent.authToken).subscribe(
              (turnUri) => {
                // Default call type to text for non-veestudio agents
                let newCallType: CallType = CallType.Text;

                if (this._agentLicenceType === LicenceType.VeeStudio) {
                  // 2021-01-18 R.Scott - Fix the below.
                  // Hack to fix the fact that we switch the multichat flag on the user _after_ the call is accepted
                  // but we never update the visitor. This is pointing to an incorrect abstraction with regards to the
                  // callType: it needs to be pulled out of the visitor. Going to leave that change for a future release.
                  if (transferRequest.visitor.isMultichat) {
                    transferRequest.visitor.removeMultichatFlag();
                  }

                  if (transferRequest.visitor.isMobileSdk) {
                    newCallType = CallType.MobileSDKWebrtc;
                  } else if (!transferRequest.visitor.hasGetUserMedia) {
                    newCallType = CallType.TextDowngrade;
                  } else {
                    newCallType = CallType.WebRTC;
                  }
                }

                const newEngagement = this.engagementService.createTransferEngagement(engagementId, newCallType, transferRequest.visitor, turnUri, this._agentLicenceType);

                if (newEngagement == null) {
                  observer.next({success: false, message: 'Failed creating transfer engagement.'});
                  observer.complete();
                  return;
                }

                this.getVisitorCrmData(transferRequest.visitor.userGuid).subscribe(data => {
                  transferRequest.visitor.setCrmData(data, this.crmService);
                });

                // Wire up the listener to close the engagement when it enters the post state
                // the post part will then call the qualify when it's all over
                const endSub = newEngagement.currentState.subscribe(newState => {
                  if (newState.type === EngagementState.Post) {
                    endSub.unsubscribe();
                    this.endEngagement(engagementId).subscribe();
                  }
                });

                observer.next({success: true, message: {engagementId: engagementId}});
                observer.complete();
              },
              error => {
                observer.next({success: false, message: 'startEngagement called failed authorising turn'});
                observer.complete();
              }
            );
            this.removeTransferRequest(transferRequest.roomId);
          })
          .fail(() => {
            observer.next({success: false, message: 'Failed'});
            observer.complete();
          });
      }
    ).pipe(timeout(5000), first());
  }

  public rejectTransferringIn(engagementId: string, transferringOperator: string, reason: string): Observable<MessageResponse<string>> {
    return new Observable<MessageResponse<string>>(
      observer => {
        this._proxy.invoke('RejectTransfer', engagementId, transferringOperator, this.authService.currentAgent.value.username, btoa(reason))
          .done(() => {
            this.removeTransferRequest(engagementId);
            observer.next({success: true, message: 'Success'});
            observer.complete();
          })
          .fail(() => {
            observer.next({success: false, message: 'Failed'});
            observer.complete();
          });
      }
    );
  }

  public rejectAllTransfers(reason: string) {
    for (const req of this._transferRequests) {
      this._proxy.invoke('RejectTransfer',
        req.roomId,
        req.sourceAgentUsername,
        this.agent.username,
        btoa(reason));
    }
  }

  public rejectAllJoins(reason: string) {
    for (const req of this._invites) {
      this._proxy.invoke('RejectInvitation',
        req.roomId,
        req.sourceAgentUsername,
        this.agent.username,
        btoa(reason));
    }
  }

  public acceptJoin(invite: InvitationRequest): Observable<MessageResponse<string | {engagementId: string}>> {
    const currentAgent = this.authService.currentAgent.value;

    return new Observable<MessageResponse<string | {engagementId: string}>>(
      observer => {
        this._proxy.invoke('AcceptInvitation', invite.roomId, invite.sourceAgentUsername, currentAgent.username)
          .done(() => {
            const engagementId = invite.roomId;
            const newEngagement = this.engagementService.createJoinEngagement(engagementId, invite.visitor, this._agentLicenceType);

            if (newEngagement == null) {
              observer.next({success: false, message: 'Failed creating engagement for joining.'});
              observer.complete();
              return;
            }

            this.getVisitorCrmData(invite.visitor.userGuid).subscribe(data => {
              invite.visitor.setCrmData(data, this.crmService);
            });

            const endSub = newEngagement.currentState.subscribe(newState => {
              // If we transition to post then we have ended due to being the primary agent
              // otherwise if we are in the ended state the visitor/main agent has ended the call
              if (EngagementState.Post === newState.type) {
                endSub.unsubscribe();
                this.endEngagement(engagementId).subscribe();
              } else if (EngagementState.Ended === newState.type) {
                endSub.unsubscribe();
              }
            });

            this.removeJoinRequest(invite.roomId);

            observer.next({success: true, message: {engagementId: engagementId}});
          })
          .fail(() => {
            observer.next({success: false, message: 'Failed to join the engagement'});
            observer.complete();
          });
      }
    ).pipe(timeout(5000), first());
  }

  public joinCall(visitor: SiteVisitor): Observable<MessageResponse<{engagementId: string}>> {
    if (visitor.currentUsername != null && visitor.currentUsername.length > 0 && visitor.isEngaged) {
      return new Observable<MessageResponse<{engagementId: string}>>(s => {
        try {
          const newEngagement = this.engagementService.createExistingEngagement(visitor.engagementGuid, visitor, this._agentLicenceType);
          if (newEngagement != null) {
            const endSub = newEngagement.currentState.subscribe(newState => {
              if (newState.type === EngagementState.Post) {
                endSub.unsubscribe();
                this.endEngagement(visitor.engagementGuid).subscribe();
              }
            });
            s.next({success: true, message: { engagementId: visitor.engagementGuid } });
          } else {
            s.next({success: false, message: null});
          }
        } catch (err) {
          s.next({success: false, message: null});
        } finally {
          s.complete();
        }
      });
    }

    return new Observable(observer => {
      const engagementId = visitor.engagementGuid;
      const newEngagement = this.engagementService.createJoinEngagement(engagementId, visitor, this._agentLicenceType);

      const endSub = newEngagement.currentState.subscribe(newState => {
        // If we transition to post then we have ended due to being the primary agent
        // otherwise if we are in the ended state the visitor/main agent has ended the call
        if (EngagementState.Post === newState.type) {
          endSub.unsubscribe();
          this.endEngagement(engagementId).subscribe();
        } else if (EngagementState.Ended === newState.type) {
          endSub.unsubscribe();
        }
      });

      observer.next({success: true, message: {engagementId: engagementId}});
    });
  }

  public rejectInvitation(engagementId: string, invitingOperator: string, reason: string): Observable<MessageResponse<string>> {
    return new Observable(
      observer => {
        this._proxy.invoke('RejectInvitation', engagementId, invitingOperator, this.authService.currentAgent.value.username, btoa(reason))
          .done(() => {
            this.removeInviteToJoin(engagementId);
            observer.next({success: true, message: 'Success'});
            observer.complete();
          })
          .fail(() => {
            observer.next({success: false, message: 'Failed'});
            observer.complete();
          });
      }
    );
  }

  private updateStatus(status: OnlineState, workingMode: WorkStatus): Promise<void> {
    if (this._proxy) {
        return new Promise((resolve, reject) => {
          try {
            this._proxy.invoke('UpdateAgentStatus', status, this.onlineService.deskLicenceKey, workingMode)
              .done(() => resolve())
              .fail((err) => {
                this.logging.error('Failed to invoke update status', err);
                reject('Failed to invoke update status');
              });
          } catch (err) {
            this.logging.error('Failed to update status', err);
            reject(err);
          }
        });
    } else {
      this.logging.debug('Attempting to set status when not connected to the hub');
      return Promise.reject('Not connected to hub');
    }
  }

  public getVisitorDetails(userGuid: string, sessionGuid: string): Observable<VisitorDetails> {

    // poll every second up to 5 seconds when it times out
    // OR we have received valid data
    return timer(0, 1000)
    .pipe(
      concatMap(() => this.getSiteUserData(userGuid, sessionGuid)),
      filter(result => this.validVisitorDetails(result)),
      timeout(5000),
      take(1),
      catchError(err => of(<VisitorDetails>{}))
    );
  }

  private getSiteUserData(userGuid: string, sessionGuid: string): Observable<VisitorDetails> {
    return new Observable(n => {

      if (this._proxy) {
        this._proxy.invoke('GetUserSiteData', userGuid, sessionGuid)
        .done((result: VisitorDetails) => {
          n.next(result);
          n.complete();
        })
        .fail(_ => {
          n.error("invalid visitor data");
          n.complete();
        });
      }
      else {
        this.visitorBackendService.loadUserSiteData(this.authService.currentAgent.value.authToken, userGuid, sessionGuid).subscribe(result => {
          n.next(result);
          n.complete();
        },
        err => {
          n.next(null);
          n.complete();
        });
      }
    });
  }

  public getVirtualBackground(siteName: string, siteSection: string): Observable<VirtualBackground> {
    return new Observable(n => {
      this._proxy.invoke('GetVirtualBackground', siteName, siteSection)
        .done((result) => {
          n.next(result);
          n.complete();
        })
        .fail(_ => {
          n.next(null);
          n.complete();
        });
    });
  }

  // check if property has been populated
  // if so, we have received data
  private validVisitorDetails(details: VisitorDetails): boolean {
    return details && details.isMobile && details.isMobile.length > 0;
  }

  public getVisitorCrmData(userGuid: string): Observable<CrmStructure> {
    return new Observable(n => {
      this.crmService.loadCustomerData(userGuid).subscribe(result => {
        n.next(result);
        n.complete();
      },
      err => {
        n.next(null);
        n.complete();
      });
    });
  }

  public getVisitorSessionHistory(userGuid: string): Observable<EngagementEvent[]> {
    return new Observable(n => {
      this.crmService.getSessionHistory(userGuid).subscribe(result => {
        n.next(result);
        n.complete();
      },
      err => {
        n.next(null);
        n.complete();
      });
    });
  }

  public getVisitorBrowsingHistory(userGuid: string, sessionGuid: string): Observable<BrowsingHistory[]> {
    return new Observable(n => {
      this.crmService.getBrowsingHistory(userGuid, sessionGuid).subscribe(result => {
        n.next(result);
        n.complete();
      },
      err => {
        n.next(null);
        n.complete();
      });
    });
  }

  public acceptAgentHelpRequest(engagementGuid: string) {
    this._proxy.invoke('AcceptAgentHelpRequest', engagementGuid);
  }

  public getKPIData(sitename: string): Observable<KpiSetting[]> {
    return new Observable(n => {
      this._proxy.invoke('GetKPIData', sitename)
        .done((result) => {
          n.next(result);
          n.complete();
        })
        .fail(_ => {
          n.next(null);
          n.complete();
        });
    });
  }

  public sendFilterData(idList: string): Observable<number[]> {
    return new Observable(n => {
      this._proxy.invoke('FilterUpdate', idList, this.agent.sitename)
        .done((result: number[]) => {
          n.next(result);
          n.complete();
        })
        .fail(_ => {
          n.next(null);
          n.complete();
        });
    });
  }

  public sendFilterDataForOpGroups(idList: string) {
    this._proxy.invoke('FilterUpdateForOpGroups', idList);
  }

  public getDashboardData(): Observable<DashboardData> {
    return new Observable<DashboardData>(subscriber => {
      this._proxy.invoke('GetDashboardData').done((data) => {
        subscriber.next(data);
        subscriber.complete();
      }).fail(_ => {
        subscriber.complete();
      });
    });
  }

  sendTransferChatMessage(roomId: string, to: string, message: string) {
    this.handleTransferChatMessage({
      SourceAgentUsername: this.agent.username,
      AgentNickname: this.agent.nickname,
      RoomId: roomId,
      DestinationAgentUsername: to,
      Message: message
    });
    return new Observable<MessageResponse<void>>(subscriber => {
      this._proxy.invoke('SendTransferChatMessage', roomId, to, this.agent.nickname, message).done((data) => {
        subscriber.next(data);
        subscriber.complete();
      }).fail(_ => {
        subscriber.complete();
      });
    });
  }

  private engagementStatuses: Promise<void> = Promise.resolve();
  public savePostStatus(engagementGuid: string, userGuid: string, sessionGuid: string, status: PostEngagementStatus) {
    // Serialise all updates
    this.engagementStatuses = this.engagementStatuses.then(() => {
      return new Promise<void>((resolve, _reject) => {
        try {
          this._proxy.invoke('SavePostStatus', engagementGuid, userGuid, sessionGuid, status.status, status.substatus, status.notes)
            .done(() => {
              resolve();
            }).fail(err => {
              this.logging.error(`Failed to save post engagement status for engagement id ${engagementGuid}`, err);
              resolve();
            });
        } catch (e) {
          this.logging.error(`Failed to save post engagement status for engagement id ${engagementGuid}`, e);
          resolve();
        }
      });
    });
  }
}
