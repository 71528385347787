<div class="mt-2 d-flex flex-column gap-2 h-100">
  <div class="d-flex flex-row align-items-center justify-content-around py-4">
    <app-dash-panel class="w-100" [kpiSettings]="kpiData"
                    [mode]="mode"
                    [dashboardData]="dashboardData"
                    [selectedAgents]="selectedAgents"
                    [selectedVisitors]="selectedVisitors"
                    [notEngagedVisitors]="notEngagedVisitors"
                    (showCardInfo)="pullCardDetails($event)">
    </app-dash-panel>
  </div>


  <div class="flex-grow-1 overflow-y-auto" [ngSwitch]="currentCardSelection">

    <app-dashboard-card-visitors
      *ngSwitchCase="DashboardCardType.longestWaitTime"
      [notEngagedVisitors]="notEngagedVisitors">
    </app-dashboard-card-visitors>

    <app-dashboard-card-visitors
      *ngSwitchCase="DashboardCardType.requests"
      [notEngagedVisitors]="notEngagedVisitors">
    </app-dashboard-card-visitors>

    <!-- veechat -->
    <app-dashboard-card-vee-chat-operators
      *ngSwitchCase="DashboardCardType.veeChatOperators"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-chat-operators>

    <app-dashboard-card-vee-chat-sessions-possible
      *ngSwitchCase="DashboardCardType.veeChatSessionsPossible"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-chat-sessions-possible>

    <app-dashboard-card-operators-on-break
      *ngSwitchCase="DashboardCardType.operatorsOnBreak"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups">
    </app-dashboard-card-operators-on-break>

    <app-dashboard-card-vee-chat-engaged
      *ngSwitchCase="DashboardCardType.veeChatEngaged"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups"
      [selectedVisitors]="selectedVisitors"
      [supervisingVisitors]="supervisingVisitors"
      [supervisingEngagements]="supervisingEngagements"
      [privateChatAvailable]="privateChatAvailable"
      (startSnooping)="startSnooping.emit($event)"
      (enterSession)="enterSession.emit($event)">
    </app-dashboard-card-vee-chat-engaged>

    <app-dashboard-card-vee-chat-additional-capacity
      *ngSwitchCase="DashboardCardType.veeChatAdditionalCapacity"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-chat-additional-capacity>

    <!-- veestudio -->
    <app-dashboard-card-vee-studio-operators *ngSwitchCase="DashboardCardType.veeStudioOperators"
                                             [selectedAgents]="selectedAgents"
                                             [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-studio-operators>

    <app-dashboard-card-vee-studio-operators-available *ngSwitchCase="DashboardCardType.veeStudioOperatorsAvailable"
                                                       [selectedAgents]="selectedAgents"
                                                       [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-studio-operators-available>

    <app-dashboard-card-vee-studio-operators-not-available *ngSwitchCase="DashboardCardType.veeStudioOperatorsNotAvailable"
                                                           [selectedAgents]="selectedAgents"
                                                           [selectedGroups]="selectedGroups">
    </app-dashboard-card-vee-studio-operators-not-available>

    <app-dashboard-card-vee-studio-operators-busy
      *ngSwitchCase="DashboardCardType.veeStudioOperatorsBusy"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups"
      [selectedVisitors]="selectedVisitors"
      [supervisingVisitors]="supervisingVisitors"
      [supervisingEngagements]="supervisingEngagements"
      [privateChatAvailable]="privateChatAvailable"
      (startSnooping)="startSnooping.emit($event)"
      (enterSession)="enterSession.emit($event)">
    </app-dashboard-card-vee-studio-operators-busy>

    <app-dashboard-card-vee-chat-engaged
      *ngSwitchCase="DashboardCardType.asyncChatEngaged"
      [selectedAgents]="selectedAgents"
      [selectedGroups]="selectedGroups"
      [selectedVisitors]="selectedVisitors"
      [supervisingVisitors]="supervisingVisitors"
      [supervisingEngagements]="supervisingEngagements"
      [privateChatAvailable]="false"
      (startSnooping)="startSnooping.emit($event)"
      (enterSession)="enterSession.emit($event)">
    </app-dashboard-card-vee-chat-engaged>
  </div>
</div>
