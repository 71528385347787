<div [ngSwitch]="deliveryStatus " class="status text-right d-flex align-items-center justify-content-end gap-1 pb-1 pr-1">
  <ng-container *ngSwitchCase="DeliveryStatus.Sending">
  </ng-container>

  <ng-container *ngSwitchCase="DeliveryStatus.Sent">
    <fa-icon [icon]="faCheck" class="sent status-icon">
    </fa-icon>
    <small class="font-italic" id="delivery-timestamp">{{deliveryTimestamp | textMessageDate}}</small>
  </ng-container>

  <ng-container *ngSwitchCase="DeliveryStatus.Delivered">
    <span>
      <fa-icon [icon]="faCheck" class="delivered status-icon">
      </fa-icon>
      <fa-icon [icon]="faCheck" class="delivered status-icon ">
      </fa-icon>
    </span>

    <small class="font-italic" id="delivery-timestamp" >{{deliveryTimestamp | textMessageDate}}</small>
  </ng-container>

  <ng-container *ngSwitchCase="DeliveryStatus.Read">
    <span>
      <fa-icon [icon]="faCheck" class="read status-icon">
      </fa-icon>
      <fa-icon [icon]="faCheck" class="read status-icon">
      </fa-icon>
    </span>

    <small class="font-italic" id="delivery-timestamp" >{{deliveryTimestamp | textMessageDate}}</small>
  </ng-container>

  <ng-container *ngSwitchCase="DeliveryStatus.Failed">
    <fa-icon  [icon]="faExclamationCircle" class="text-danger status-icon">
    </fa-icon>
    <small id="failed-text" class="font-italic" id="failed-text">{{'CHAT_TRANSCRIPT_DELIVERY_FAILED' | Translate: 'Message couldn\'t be sent.'}}</small>
  </ng-container>

  <ng-container *ngSwitchDefault>
  </ng-container>
</div>
