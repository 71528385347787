<table class="table table-borderless table-hover w-100" [class.table-fixed]="fixedTable">
  <tbody class="table-body">
  <tr *ngIf="page?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_CURRENTPAGE" | Translate : "Current Page" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faFile" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_CURRENTPAGE" | Translate : 'Current Page' }}</span>
    </td>
    <td class="visitor-current-page text-right text-break"><p>{{ page }}</p></td>
  </tr>

  <tr *ngIf="section?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_SECTION" | Translate : "Section" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faPuzzlePiece" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_SECTION" | Translate : 'Section' }}</span>
    </td>
    <td class="visitor-current-section text-right text-break"><p>{{ section }}</p></td>
  </tr>

  <tr *ngIf="browser?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_BROWSER" | Translate : "Browser" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faGlobe" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_BROWSER" | Translate : 'Browser' }}</span>
    </td>
    <td class="visitor-browser text-right text-break"><p>{{ browser }}</p></td>
  </tr>

  <tr *ngIf="deviceType?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_DEVICETYPE" | Translate : "Device Type" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faMobile" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_DEVICETYPE" | Translate : 'Device Type' }}</span>
    </td>
    <td class="visitor-device-type text-right text-break"><p>{{ deviceType }}</p></td>
  </tr>

  <tr *ngIf="organisation?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_ORGANISATION" | Translate : "Organisation" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faBuilding" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_ORGANISATION" | Translate : 'Organisation' }}</span>
    </td>
    <td class="visitor-org text-right text-break "><p>{{ organisation }}</p></td>
  </tr>

  <tr *ngIf="location?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_LOCATION" | Translate : "Location" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faLocationArrow" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_LOCATION" | Translate : 'Location' }}</span>
    </td>
    <td class="visitor-location text-right text-break"><p>{{ location }}</p></td>
  </tr>

  <tr *ngIf="prevOp?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_PREVIOUSOPERATOR" | Translate : "Previous Operator" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faAddressCard" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_PREVIOUSOPERATOR" | Translate : 'Previous Operator' }}</span>
    </td>
    <td class="visitor-prev-op text-right text-break"><p>{{ prevOp }}</p></td>
  </tr>

  <tr *ngIf="ip?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_IDENTITY" | Translate : "IP/Identity" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faMapMarker" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_IDENTITY" | Translate : 'IP/Identity' }}</span>
    </td>
    <td class="visitor-ip text-right text-break"><p>{{ ip }}</p></td>
  </tr>

  <tr *ngIf="referrer?.length > 0" tooltipPosition="bottom" pTooltip='{{ "VISITORDETAILS_ROWNAME_REFERRER" | Translate : "Referrer" }}'>
    <td class="font-weight-bold">
      <fa-icon [icon]="faLink" class="pr-2"></fa-icon>
      <span *ngIf="showLabels">{{ "VISITORDETAILS_ROWNAME_REFERRER" | Translate : 'Referrer' }}</span>
    </td>
    <td class="visitor-referrer text-right text-break"><p>{{ referrer }}</p></td>
  </tr>
  </tbody>

</table>
