import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FulfillmentMarketingMaterial } from '../../classes/fulfillment/FulfillmentMarketingMaterial';
import { LoggingService } from '../logging.service';
import { AuthService } from '../auth-service/auth.service';
import { FulfillmentBackendService } from './fulfillment.backend.service';
import { InsertFulfillmentRequest } from '../../classes/fulfillment/InsertFulfillmentRequest';

export interface IFulfillmentService {
  fulfillmentMarketingMaterial: BehaviorSubject<FulfillmentMarketingMaterial[]>;
  getMarketingMaterial(): Observable<boolean>;
  insertFulfillment(fulfillmentMarketingMaterial: FulfillmentMarketingMaterial[], engagementId: string, sessionGuid: string): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService implements IFulfillmentService {

  public fulfillmentMarketingMaterial: BehaviorSubject<FulfillmentMarketingMaterial[]> = new BehaviorSubject([]);

  constructor(
    private logger: LoggingService,
    private authService: AuthService,
    private fulfillmentBackendService: FulfillmentBackendService
  ) { }

  public getMarketingMaterial(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.fulfillmentBackendService.getMarketingMaterial(this.authService.currentAgent.value.authToken).subscribe(
          fulfillmentMrkMat => {
            this.fulfillmentMarketingMaterial.next(fulfillmentMrkMat);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting fulfillment marketing material', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public insertFulfillment(fulfillmentMarketingMaterial: FulfillmentMarketingMaterial[], engagementId: string, userguid: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        const InsertFulfillmentRequest: InsertFulfillmentRequest = {
          fulfillmentMarketingMaterial: fulfillmentMarketingMaterial,
          engagementId: engagementId,
          vee24guid: userguid
        }

        this.fulfillmentBackendService.insertFulfillment(this.authService.currentAgent.value.authToken, InsertFulfillmentRequest).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (err) => {
            this.logger.error('Error in insert fulfillment', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }
}
