// TODO: this is inefficient as it could do called once and stored
export default class Browser {
    constructor () {
    }

    static extractVersion(uastring, expr, pos) {
        var match = uastring.match(expr);
        return match && match.length >= pos && parseInt(match[pos], 10);
    }

    static details() {
        var navigator = window && window.navigator;

        // Returned result object.
        var result = {};
        result.browser = null;
        result.version = null;

        // Fail early if it's not a browser
        if (typeof window === 'undefined' || !window.navigator) {
            result.browser = 'Not a browser.';
            return result;
        }

        if (navigator.mozGetUserMedia) { // Firefox.
            result.browser = 'firefox';
            result.version = Browser.extractVersion(navigator.userAgent, /Firefox\/(\d+)\./, 1);
        } 
        else if (navigator.webkitGetUserMedia) {
            // Chrome, Chromium, Webview, Opera.
            // Version matches Chrome/WebRTC version.
            result.browser = 'chrome';
            if (navigator.userAgent.match(/Android/)) { // Android.
                result.browser = 'chrome android';
            }
            result.version = Browser.extractVersion(navigator.userAgent, /Chrom(e|ium)\/(\d+)\./, 2);
        } 
        else if (navigator.mediaDevices && navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)) { // Edge.
            result.browser = 'edge';
            result.version = Browser.extractVersion(navigator.userAgent, /Edge\/(\d+).(\d+)$/, 2);
        } 
        else if (navigator.mediaDevices && navigator.userAgent.match(/AppleWebKit\/(\d+)\./)) { // Safari.
            result.browser = 'safari';
            if (navigator.userAgent.match(/iPhone/)) { // iphone Safari.
                result.browser = 'iphone';
            }
            if (navigator.userAgent.match(/iPad/)) { // iphone Safari.
                result.browser = 'ipad';
            }
            result.version = Browser.extractVersion(navigator.userAgent, /Version\/(\d+)\./, 1);
        } 
        else { // Default fallthrough: not supported.
            result.browser = 'Not a supported browser.';
            return result;
        }

        return result;
    }

    static get supportsTransceivers() {
        return (Browser.details().browser === 'firefox' && Browser.details().version >= 59) 
            || (Browser.details().browser.indexOf('chrome') > -1 && Browser.details().version >= 72)
            || ((Browser.details().browser === 'safari' || Browser.details().browser === "ipad" || Browser.details().browser === "iphone") && Browser.details().version >= 13);
    }
}
