import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoggingService} from '../logging.service';
import {Maybe, Nothing} from '../../classes/maybe';
import {environment} from '../../../environments/environment';
import {AuthService} from '../auth-service/auth.service';

export interface LinkPreviewDetails {
  title: string;
  secureImageUrl: string;
  imageUrl: string;
  description: string;
  canonicalUrl: string;
}

class CachedOpenGraphDetails {
  details: Observable<Maybe<LinkPreviewDetails>>;
  timestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class LinkPreviewService {
  private static readonly CACHE_TIMEOUT: number = 30 * 60 * 1000; // 30 minutes cache timeout
  private static readonly CLEANUP_TIMEOUT_MS: number = 60 * 1000;

  private readonly cachedResults: Map<string, CachedOpenGraphDetails> = new Map<string, CachedOpenGraphDetails>([]);
  private cleanupTaskTimeout = -1;

  constructor(private http: HttpClient, private logging: LoggingService, private authService: AuthService) { }

  getDetails(url: string): Observable<Maybe<LinkPreviewDetails>> {
    const cachedValue = this.cachedResults.get(url);
    this.maybeCleanupCache();

    if (cachedValue) {
      return cachedValue.details;
    } else {
      return this.makeDetailsRequest(url);
    }
  }

  isCached(url: string): boolean {
    const cacheData = this.cachedResults.get(url);

    if (cacheData === undefined) {
      return false;
    } else if (this.hasExpired(cacheData)) {
      this.cachedResults.delete(url);
      return false;
    } else {
      return true;
    }
  }

  private hasExpired(cacheData: CachedOpenGraphDetails) {
    return (new Date().getTime() - cacheData.timestamp.getTime()) > LinkPreviewService.CACHE_TIMEOUT;
  }

  private maybeCleanupCache() {
    if (this.cleanupTaskTimeout === -1) {
      this.cleanupTaskTimeout = window.setTimeout(() => this.cleanupCache(), LinkPreviewService.CLEANUP_TIMEOUT_MS);
    } else {
      this.logging.debug('Not cleaning up map this time');
    }
  }

  private cleanupCache() {
    const now = new Date().getTime();

    for (const [url, cacheData] of this.cachedResults) {
      if ((now - cacheData.timestamp.getTime()) > LinkPreviewService.CACHE_TIMEOUT) {
        this.cachedResults.delete(url);
      }
    }

    this.cleanupTaskTimeout = -1;
  }

  private makeDetailsRequest(url: string): Observable<Maybe<LinkPreviewDetails>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'auth-token': this.authService.currentAgent.value.authToken
      })
    };

    const obs = this.http.get<LinkPreviewDetails>(`${environment.linkpreviewRoot}?url=${url}`, httpOptions)
      .pipe(
        catchError(err => {
          this.logging.error(`Error getting link preview details for ${url}`, err);
          return of(null);
        }),
        map(this.handleResponse),
        shareReplay(1));

    const dummySub = obs.subscribe();

    this.cachedResults.set(url, {
      details: obs,
      timestamp: new Date()
    });

    return obs;
  }

  private handleResponse(data?: LinkPreviewDetails): Maybe<LinkPreviewDetails> {
    if (data && data.title && data.description && (data.secureImageUrl || data.imageUrl)) {
      return data;
    } else {
      return Nothing;
    }
  }
}
