import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-veescheduler',
  templateUrl: './veescheduler.component.html',
  styleUrls: ['./veescheduler.component.scss']
})
export class VeeschedulerComponent implements OnInit {

  constructor(
    ) { }

  ngOnInit() {    
  }

}
