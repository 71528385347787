<p-dropdown [formControl]="selectControl"
            class="form-dropdown"
            [options]="choices"
            optionValue="id"
            optionLabel="text"
            placeholder="{{'CRMSEARCH_PLACEHOLDER_SELECT' | Translate : 'Select...'}}"
            [resetFilterOnHide]="true"
            [showClear]="false"
            [filter] = "false">
</p-dropdown>
